import { FileAddOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { PageHeader, Radio, Row, Spin, Table, message } from 'antd'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import ButtonSave from 'common/components/Button/ButtonSave'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useDebounce } from 'common/hooks/Debounce'
import Container from 'container/Container'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { renderRefundStatus } from 'utils/Configs'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'
import AssignOrderForShipper from '../components/AssignOrderForShipper'
import EditNoteModal from '../components/EditNoteModal'
import Filter from '../components/Fiilter'
import OrderDetail from '../components/OrderDetail'
import {
    deleteRefundOrder,
    exportExcelRefundOrder,
    getRefundOrderDetail,
    getRefundOrderList,
    updaterefundOrderNote,
} from '../services'
import {
    resetRefOrders,
    setMultipleRefOrderData,
    setMultipleRefOrderIds,
    setOneRefOrderData,
    setOneRefOrderId,
} from '../services/RefundOrderSlice'
import { ADMIN_ROLE } from 'utils/constants'

const RefundOrder = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN

    const selectedRefOrderIds = useSelector((state: any) => state.refundOrderReducer.orderIds)
    const selectedRefOrderRows = useSelector((state: any) => state.refundOrderReducer.orderList)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const [isAssignModalOpen, setIsAssignModalOpen] = React.useState<boolean>(false)
    const [search, setSearch] = React.useState<string>('')
    const [fromDate, setFromDate] = React.useState<string>('')
    const [toDate, setToDate] = React.useState<string>('')
    const [data, setData] = React.useState<any[]>([])
    const [orderStatus, setOrderStatus] = React.useState<number>()
    const [listKiotviet, setlistKiotviet] = React.useState<IKiotviet[]>()
    const [selectedKiotVietId, setSelectedKiotVietId] = React.useState<any>(
        isShopAdmin ? userInfor?.admin?.kiotviet_id : ''
    )
    const [orderIdSelected, setOrderIdSelected] = React.useState<number>()
    const [detailOrder, setDetailOrder] = React.useState<any>()
    const [listRowKeysSelected, setListRowKeysSelected] = React.useState<any[]>(selectedRefOrderIds)
    const [selectedRows, setSelectedRows] = React.useState<any[]>(selectedRefOrderRows)
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<number[]>([])
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const searchDebounce = useDebounce(search, 300)

    const columns = [
        Table.SELECTION_COLUMN,
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Mã đơn</b>,
            dataIndex: 'code',
        },
        {
            title: <b>Khách hàng</b>,
            dataIndex: 'customer',
            render: (_: any, record: any) => record?.shipping_name,
        },
        {
            title: <b>SĐT</b>,
            dataIndex: 'phone',
            render: (_: any, record: any) => record?.phone_number,
        },
        {
            title: <b>Số sản phẩm</b>,
            dataIndex: 'quantity_product',
        },
        {
            title: <b>Trạng thái đơn</b>,
            dataIndex: 'status',
            render: (_: any, record: any) => renderRefundStatus(record?.order_delivery_status),
        },
        {
            title: <b>Shipper</b>,
            dataIndex: 'shipper_name',
            render: (value: any) => value || '---',
        },
        {
            title: <b>Người giao đơn</b>,
            dataIndex: 'admin_name',
            render: (value: any) => value || '---',
        },
        {
            title: <b>Ngày tạo</b>,
            dataIndex: 'date',
            render: (_: any, record: any) => momentToStringDate(record?.created_at),
        },
    ]

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
                setlistKiotviet(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getListData = async () => {
        try {
            setLoading(true)
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                search: searchDebounce.trim(),
                status: orderStatus || orderStatus === 0 ? `${orderStatus}` : undefined,
                kiotviet_id: selectedKiotVietId,
                create_from: fromDate,
                create_to: toDate,
            }
            const res = await getRefundOrderList(payload)
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, key: item?.id }))
                setData(data)
                const newPaging = {
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const exportExcel = async () => {
        try {
            setLoading(true)
            const payload = {
                page: undefined,
                limit: undefined,
                search: searchDebounce.trim(),
                status: orderStatus || orderStatus === 0 ? `${orderStatus}` : undefined,
                kiotviet_id: selectedKiotVietId,
                create_from: fromDate,
                create_to: toDate,
            }
            const res = await exportExcelRefundOrder(payload)
            if (res?.status) {
                message.success('Xuất file excel thành công!')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getOrderDetail = async () => {
        try {
            setLoading(true)
            const res = await getRefundOrderDetail(orderIdSelected)
            if (res?.status) {
                setDetailOrder(res?.data)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const deleteOrder = async () => {
        try {
            setLoading(true)
            const res = await deleteRefundOrder(orderIdSelected)
            if (res?.status) {
                message.success('Xoá đơn hàng thành công!')
                getListData()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const updateOrderNote = async (payload: any) => {
        try {
            setLoading(true)
            const res: any = await updaterefundOrderNote(orderIdSelected, payload)
            if (res?.status) {
                message.success('Cập nhật ghi chú thành công!')
                setIsModalOpen(false)
                getListData()
                getOrderDetail()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleChangeStock = (idStock: any) => {
        setSelectedKiotVietId(idStock)
        setExpandedRowKeys([])
    }

    React.useEffect(() => {
        getDataKiotviet()
    }, [])

    React.useEffect(() => {
        if (expandedRowKeys?.length === 0) {
            setOrderIdSelected(undefined)
        }
    }, [expandedRowKeys])

    React.useEffect(() => {
        if (orderIdSelected) {
            getOrderDetail()
        }
    }, [orderIdSelected])

    React.useEffect(() => {
        getListData()
    }, [paging.current])

    React.useEffect(() => {
        if (paging.current === 1) {
            getListData()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [searchDebounce, orderStatus, selectedKiotVietId, fromDate, toDate])

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        style={{ borderRadius: 8 }}
                        title="Danh sách đơn hoàn trả"
                        extra={[
                            <ButtonSave
                                onClickButton={exportExcel}
                                text="Xuất excel"
                                icon={<VerticalAlignBottomOutlined />}
                            />,
                        ]}
                    ></PageHeader>
                }
                filterComponent={
                    <Filter
                        setOrderStatus={setOrderStatus}
                        search={search}
                        setFromDate={setFromDate}
                        setToDate={setToDate}
                        setSearch={setSearch}
                    />
                }
                contentComponent={[
                    <>
                        <CustomRow>
                            <Radio.Group
                                value={selectedKiotVietId}
                                onChange={(e: any) => {
                                    handleChangeStock(e.target.value)
                                    dispatch(resetRefOrders())
                                    setListRowKeysSelected([])
                                    setSelectedRows([])
                                }}
                            >
                                <Radio.Button disabled={isShopAdmin} value={''}>
                                    Tất cả
                                </Radio.Button>
                                {listKiotviet &&
                                    listKiotviet.map((region, index) => (
                                        <Radio.Button
                                            key={index}
                                            value={region.id}
                                            disabled={isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id}
                                        >
                                            {region.name ? region.name : '--'}
                                        </Radio.Button>
                                    ))}
                            </Radio.Group>
                            <div>
                                <ButtonAdd
                                    text="Thêm đơn"
                                    styleButton={{ marginRight: 10 }}
                                    onClickButton={() => {
                                        history.push({
                                            pathname: ADMIN_ROUTER_PATH.ADD_EDIT_REFUND_ORDER,
                                            state: {},
                                        })
                                    }}
                                />
                                {selectedKiotVietId !== '' && (
                                    <ButtonAdd
                                        text="Giao đơn cho shipper"
                                        background="#e76767"
                                        icon={<FileAddOutlined />}
                                        onClickButton={() => {
                                            if (!selectedKiotVietId) {
                                                message.error('Vui lòng chọn khu vực!')
                                            } else if (selectedRows.length === 0) {
                                                message.error('Vui lòng chọn ít nhất một đơn hàng!')
                                            } else {
                                                setIsAssignModalOpen(true)
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        </CustomRow>
                        <CustomRow>
                            <p>
                                Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                            </p>
                        </CustomRow>
                        {isModalOpen && (
                            <EditNoteModal
                                setIsModalOpen={setIsModalOpen}
                                isModalOpen={isModalOpen}
                                data={detailOrder}
                                updateOrderNote={updateOrderNote}
                            />
                        )}
                        {isAssignModalOpen && (
                            <AssignOrderForShipper
                                setIsModalOpen={setIsAssignModalOpen}
                                isModalOpen={isAssignModalOpen}
                                listOrders={selectedRows}
                                isRefundOrder={true}
                                setisLoading={setLoading}
                                isLoading={loading}
                                params={{
                                    page: paging.current,
                                    limit: paging.pageSize,
                                    search: searchDebounce.trim(),
                                    status: orderStatus || orderStatus === 0 ? `${orderStatus}` : undefined,
                                    kiotviet_id: selectedKiotVietId,
                                    create_from: fromDate,
                                    create_to: toDate,
                                }}
                                getListData={getListData}
                                setSelectedRows={setSelectedRows}
                                setListRowKeysSelected={setListRowKeysSelected}
                            />
                        )}
                        <Table
                            id="top-table"
                            bordered
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: 'Chưa có bản ghi nào!',
                            }}
                            rowSelection={{
                                selectedRowKeys: listRowKeysSelected,

                                onSelect: (record: any, selected: any) => {
                                    if (selected) {
                                        setSelectedRows((prev: any) => [...prev, record])
                                        setListRowKeysSelected((prev: any) => [...prev, record?.id])
                                    } else {
                                        const newSelectedRows = selectedRows?.filter(
                                            (item: any) => item?.id !== record?.id
                                        )
                                        setSelectedRows(newSelectedRows)

                                        const newListKeys = listRowKeysSelected?.filter(
                                            (item: any) => item !== record?.id
                                        )
                                        setListRowKeysSelected(newListKeys)
                                    }
                                    dispatch(setOneRefOrderId({ record, selected }))
                                    dispatch(setOneRefOrderData({ record, selected }))
                                },

                                onSelectAll: (selected: boolean, selectedRowList: any[], changeRows: any[]) => {
                                    if (selected) {
                                        setSelectedRows((prev: any) => [...prev, ...changeRows])
                                        const newListKeys = changeRows?.map((item: any) => item?.id)
                                        setListRowKeysSelected((prev: any) => [...prev, ...newListKeys])
                                        dispatch(setMultipleRefOrderIds({ newListKeys, selected }))
                                        dispatch(setMultipleRefOrderData({ changeRows, selected }))
                                    } else {
                                        const deselectedRowKeys = changeRows?.map((item: any) => item?.id)

                                        const newListKeys = listRowKeysSelected?.filter(
                                            (item: any) => !deselectedRowKeys.includes(item)
                                        )
                                        const newListRows = selectedRows?.filter((item: any) =>
                                            newListKeys.includes(item?.id)
                                        )

                                        setSelectedRows(newListRows)
                                        dispatch(setMultipleRefOrderData({ changeRows: newListRows, selected }))

                                        setListRowKeysSelected(newListKeys)
                                        dispatch(setMultipleRefOrderIds({ newListKeys, selected }))
                                    }
                                },
                                getCheckboxProps: (record: any) => ({
                                    disabled: record.order_delivery_status === 1 || record.order_delivery_status === 2,
                                    name: record.name,
                                }),
                            }}
                            expandable={{
                                expandedRowRender: record => (
                                    <OrderDetail
                                        data={detailOrder}
                                        deleteOrder={deleteOrder}
                                        setIsModalOpen={setIsModalOpen}
                                    />
                                ),
                            }}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(expanded: boolean, record: any) => {
                                let keys = []
                                if (expanded) {
                                    keys.push(record.id)
                                }
                                setExpandedRowKeys(keys)
                                setOrderIdSelected(record?.id)
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: page => {
                                    setPaging({ ...paging, current: page })
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    )
}

const CustomRow = styled(Row)`
    margin: 28px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text {
        font-size: 12px;
        margin: 0;
    }
`

export default RefundOrder
