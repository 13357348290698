import './css/bootstrap.css'
import { useState, useEffect } from 'react'
import { Spin, Skeleton } from 'antd'
import { requestGetListMessage } from '../ChatService'
import MessageItem from './MessageItem'
import useWsListMessage from '../hooks/useWsListMessage'
import { MessageItem as IMessageItem, UserInfoState } from '../types'
import reactotron from 'ReactotronConfig'
import { useSelector } from 'react-redux'

interface ChatBodyProps {
    selfIsUser: boolean
    selfInfo: UserInfoState | null
    otherInfo: UserInfoState | null
    id?: string
    isLoading: boolean
}

function ChatBody({ id, selfIsUser: seltIsUser, selfInfo, otherInfo, isLoading }: ChatBodyProps) {
    const { listMessage, setListMessage, pagingMessage, setPagingMessage } = useWsListMessage(id)
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)
    const [canLoading, setCanLoading] = useState<boolean>(false)

    const { userInfo } = useSelector((state: any) => state.authReducer)

    const getMoreMessage = async () => {
        if (id && !isLoadingMore && listMessage.length < pagingMessage.total) {
            try {
                setIsLoadingMore(true)
                const res = await requestGetListMessage(id, {
                    page: pagingMessage.current + 1,
                })
                const formattedPaging = {
                    total: res.paging.totalItemCount,
                    current: res.paging.page,
                    pageSize: res.paging.limit,
                }
                setPagingMessage(formattedPaging)
                setListMessage([...listMessage, ...res.data])
                if (formattedPaging.total > listMessage.length + res.data.length) {
                    setCanLoading(false)
                } else {
                    setCanLoading(true)
                }
            } catch (error) {
                console.log('err', error)
                setCanLoading(false)
            } finally {
                setIsLoadingMore(false)
            }
        }
    }

    useEffect(() => {
        if (id !== 'default_id') {
            const infiniteListBody: any = document.getElementById('infiniteListBody')
            infiniteListBody.addEventListener('scroll', (e: any) => {
                const el = e.target
                if (el.clientHeight - el.scrollHeight > el.scrollTop - 50 && !isLoadingMore) {
                    setCanLoading(true)
                }
            })
            setPagingMessage({
                total: 0,
                current: 1,
                pageSize: 0,
            })
            setCanLoading(false)
        } else {
        }
    }, [id])

    useEffect(() => {
        if (canLoading) {
            getMoreMessage()
        }
    }, [canLoading])

    return (
        <>
            <ol className="chat-body-scroll" id="infiniteListBody">
                {isLoading ? (
                    <Spin
                        style={{
                            marginBottom: '20%',
                            width: '100%',
                            textAlign: 'center',
                        }}
                        tip="Đang tải..."
                        size="large"
                    />
                ) : (
                    listMessage.map((item: any, index: number) => {
                        return (
                            <MessageItem
                                key={index}
                                is_self={
                                    item?.user_role === 'admin' || item?.account_id === userInfo?.admin?.account_id
                                }
                                avatar={item.Account?.User ? item.Account?.User?.avatar : item.Account?.Admin?.avatar}
                                content={item.content}
                                message_media_url={item.message_media_url}
                                create_at={item.create_at}
                                type_message_media={item.type_message_media}
                                user={{
                                    name: item?.user_full_name,
                                    kiotviet_name: item?.kiotviet_name,
                                    branch_name: item?.branch_name,
                                    role: item?.role,
                                    user_role: item?.user_role,
                                }}
                            />
                        )
                    })
                )}
                {isLoadingMore && (
                    <li style={{ width: '90%', marginLeft: '5%', marginTop: 0 }}>
                        <Skeleton
                            loading={true}
                            active
                            paragraph={{ rows: 1, width: '100%' }}
                            title={{ width: '100%' }}
                        />
                    </li>
                )}
            </ol>
        </>
    )
}
export default ChatBody
