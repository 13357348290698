import {
  Button,
  Descriptions,
  Image,
  message,
  PageHeader,
  Popconfirm,
  Select,
  Spin,
} from 'antd'
import { IDataDetailproduct } from '../ProductInterfaces'
import { detailProducts, editProduct } from '../ProductAPI'
import { useState, useEffect, memo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toString } from 'utils/Configs'
import { useDispatch, useSelector } from 'react-redux'
import './css/DetailStyle.css'
import { IS_ACTIVE, PRODUCT_CUSTOM_TYPE } from 'utils/constants'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import ShowMoreText from 'react-show-more-text'
import { notificationError } from 'utils/notification'
import { changeStatus } from '../../StatusSlice'
import { formatPrice } from 'utils/ruleForm'
const { Option } = Select

const WapperHeader = styled.div`
  background-color: white;
  margin: 5px;
  border-radius: 7px;
`

const WapperContent = styled.div`
  background-color: white;
  margin: 5px;
  border-radius: 7px;
  padding: 0 20px;
`
const DescText = styled.div``

function EditProduct({ location }: any) {
  const history = useHistory()
  const [isLoading, setisLoading] = useState(false)
  const [currentSelectTypePro, setcurrentSelectTypePro] = useState<string>()
  const [currentStatus, setcurrentStatus] = useState<number>()
  const dispatch = useDispatch()
  const [productDetail, setproductDetail] = useState<IDataDetailproduct>()
  const param: { id: string } = useParams()

  const heightWeb = useSelector(
    (state: any) => state.configReducer.dimension.height
  )

  const handleChangeStatus = (status: number) => {
    dispatch(changeStatus(status))
    setcurrentStatus(status)
  }

  const getProductDetail = async (id: string) => {
    let productInfo = await detailProducts(id)
    if (productInfo.data) {
      setcurrentSelectTypePro(productInfo?.data.custom_type)
      setcurrentStatus(productInfo?.data.status)
      setproductDetail(productInfo.data)
    }
  }

  useEffect(() => {
    if (param) getProductDetail(param.id)
  }, [])

  const handleSaveChange = async () => {
    try {
      const res = await editProduct({
        id: productDetail?.id?.toString(),
        body: {
          status: currentStatus,
          custom_type: currentSelectTypePro,
        },
      })
      message.success('Lưu thành công')
      history.goBack()
    } catch (err) {
      console.log(err)
      notificationError('Có lỗi xảy ra')
    }
  }

  const handleConfirm = () => {
    setcurrentSelectTypePro(productDetail?.custom_type)
    setcurrentStatus(productDetail?.status)
    history.goBack()
  }

  return (
    <>
      <WapperHeader>
        <PageHeader
          className="site-page-header"
          onBack={() => history.goBack()}
          title="Chỉnh sửa sản phẩm"
          extra={[
            <Popconfirm
              placement="bottom"
              title={'Bạn chắc chắn muốn huỷ sự thay đổi này?'}
              onConfirm={() => handleConfirm()}
              okText="Đồng ý"
              okButtonProps={{ danger: true, type: 'primary' }}
              cancelText="Huỷ"
            >
              <Button danger>
                <CloseCircleOutlined />
                Huỷ
              </Button>
            </Popconfirm>,
            <Button type="primary" onClick={() => handleSaveChange()}>
              <CheckCircleOutlined />
              Lưu
            </Button>,
          ]}
        />
      </WapperHeader>
      <WapperContent>
        <Spin spinning={isLoading}>
          <Descriptions title={<p className="title-info">THÔNG TIN CHUNG</p>}>
            <Descriptions.Item label="Mã sản phẩm">
              {toString(productDetail?.code)}
            </Descriptions.Item>
            <Descriptions.Item label="Danh mục" span={5}>
              {toString(productDetail?.category_tree?.name)}
            </Descriptions.Item>
            <Descriptions.Item label="Tên sản phẩm">
              {toString(productDetail?.name)}
            </Descriptions.Item>
            <Descriptions.Item label="Trạng thái sản phẩm" span={3}>
              <Select
                value={currentStatus}
                style={{ width: 170 }}
                onChange={(status: number) => handleChangeStatus(status)}
              >
                <Option value={IS_ACTIVE.ACTIVE}>Đang hoạt động</Option>
                <Option value={IS_ACTIVE.INACTIVE}>Ngừng hoạt động</Option>
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label="Giá bán">
              {toString(formatPrice(productDetail?.price) + ' đ')}
            </Descriptions.Item>
            <Descriptions.Item label="Đơn vị tính (Mặc định)" span={3}>
              {toString(productDetail?.master_unit)}
            </Descriptions.Item>
            <Descriptions.Item label="Loại hàng">
              <Select
                value={currentSelectTypePro}
                style={{ width: 150 }}
                onChange={(type: string) => setcurrentSelectTypePro(type)}
              >
                <Option value={PRODUCT_CUSTOM_TYPE.IS_BEST_SELLING}>
                  Hàng bán chạy
                </Option>
                <Option value={PRODUCT_CUSTOM_TYPE.IS_NEW}>Hàng mới</Option>
                <Option value={PRODUCT_CUSTOM_TYPE.IS_SALE_OFF}>
                  Hàng giảm giá
                </Option>
              </Select>
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Đơn vị tính (Quy đổi)">
              {toString(productDetail?.unit)}
            </Descriptions.Item>
            <Descriptions.Item label="Số khách quan tâm">
              {toString(formatPrice(productDetail?.unit))}
            </Descriptions.Item>
            <Descriptions.Item label="Tổng tồn" span={3}>
              {toString(productDetail?.stock)}
            </Descriptions.Item>
            <Descriptions.Item label="Mô tả" span={3}>
              {productDetail?.description ? (
                <ShowMoreText
                  lines={3}
                  more="Xem thêm"
                  less="Ẩn bớt"
                  className="content-css"
                  anchorClass="my-anchor-css-class"
                  onClick={() => {}}
                  expanded={false}
                  width={300}
                  truncatedEndingComponent={'... '}
                >
                  <DescText
                    dangerouslySetInnerHTML={{
                      __html: `${productDetail?.description}`,
                    }}
                  />
                </ShowMoreText>
              ) : (
                'Sản phẩm không có mô tả'
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            title={<p className="title-info">THÔNG TIN HÌNH ẢNH</p>}
          >
            <Descriptions.Item
              span={3}
              label="Hình ảnh sản phẩm"
              labelStyle={{ fontWeight: 'bold' }}
            >
              {productDetail?.images.map((image, index) => (
                <div style={{ marginLeft: '10px' }}>
                  <Image
                    key={index}
                    style={{
                      padding: '10px',
                      backgroundColor: 'white',
                      border: '1px solid #d3d3d3',
                    }}
                    height={130}
                    width={130}
                    src={image.src}
                  />
                </div>
              ))}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              label="Video sản phẩm"
              labelStyle={{ fontWeight: 'bold' }}
            >
              {productDetail?.videos.length !== 0 ? (
                <>
                  {productDetail?.videos.map((video, index) => (
                    <video
                      key={index}
                      controls
                      className="uploaded-pic img-thumbnail "
                      style={{ marginLeft: '14px' }}
                      width={310}
                      height={217.5}
                      src={video}
                    />
                  ))}
                </>
              ) : (
                'Sản phẩm không có video'
              )}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </WapperContent>
    </>
  )
}

export default memo(EditProduct)
