import { Baservices, IApiResponse } from './Basevices'
import {
  IResCheckHasAccountAPI,
  IResJoinLiveAPI,
  IResLoginAPI,
} from './Interfaces'
import { environment } from 'config'

const BASE_URL_SERVER: string = environment.api_host as string

export const checkHasAccountAPI = (
  phone: string
): Promise<IApiResponse<IResCheckHasAccountAPI>> => {
  return Baservices.postMethod(`/client/user/check-exist`, { phone: phone })
}

export const loginAPI = (
  userName: string,
  password: string
): Promise<IApiResponse<any>> => {
  return Baservices.putMethod(`/users/login`, {
    user_name: userName,
    password: password,
    device_id: '',
  })
}

export const registerAPI = (
  formData: any
): Promise<IApiResponse<IResLoginAPI>> => {
  return Baservices.postMethod(
    `/client/user/register`,
    formData,
    undefined,
    undefined,
    true
  )
}

export const joinLiveAPI = (
  livestreamId: number,
  token: string
): Promise<IApiResponse<IResJoinLiveAPI>> => {
  return Baservices.postMethod(
    `/livestream/${livestreamId}/join`,
    undefined,
    undefined,
    token,
    undefined
  )
}

export const commentAPI = (
  livestreamId: number,
  comment: string,
  token: string
): Promise<any> => {
  return Baservices.postMethod(
    `/livestream/${livestreamId}/comment`,
    { content: comment },
    undefined,
    token,
    undefined,
    BASE_URL_SERVER
  )
}

export const reactionAPI = (
  livestreamId: number,
  df_reaction_id: number,
  token: string
): Promise<any> => {
  return Baservices.postMethod(
    `/livestream/${livestreamId}/reaction`,
    { df_reaction_id: df_reaction_id },
    undefined,
    token,
    undefined,
    BASE_URL_SERVER
  )
}
