import React, { useEffect, useState } from 'react'
import { getBlogPostDetail } from '../CommunityBlogsApi'
import { useHistory, useParams } from 'react-router-dom'
import { HeartFilled, MessageOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Divider, List, Spin } from 'antd'
import moment from 'moment'
import images from 'utils/images'
import { type } from 'os'

const BlogsDetail = () => {
  const [user, setUser] = useState<any>({})
  const [postContent, setPostConent] = useState<Array<string>>([])
  const [topic, setTopic] = useState<string>('')
  const [post, setPost] = useState<any>({})
  const [comment, setcomment] = useState<Array<any>>([])
  const [loading, setLoading] = useState<boolean>(false)

  const params: { id: string } = useParams()
  const fomatContent = (data: string) => {
    return data.split('\n')
  }
  type Props = {
    like: number
    comment: number
  }
  type PropsMedia = {
    media: Array<any>
  }
  type PropsCommentChil = {}
  const CustomeIcon = ({ like, comment }: Props) => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', float: 'right' }}
      >
        <HeartFilled style={{ marginLeft: 10, fontSize: 20, color: 'red' }} />
        <span style={{ marginLeft: 10 }}>{like}</span>
        <MessageOutlined style={{ marginLeft: 10, fontSize: 20 }} />
        <span style={{ marginLeft: 10, fontSize: 15, color: 'black' }}>
          {comment}
        </span>
      </div>
    )
  }
  const getuser = async () => {
    setLoading(true)
    try {
      const res = await getBlogPostDetail(params.id)
      console.log('post', res.data)
      setUser(res.data.post.User)
      setTopic(res.data.post.Topic.description)
      setPostConent(fomatContent(res.data.post.content))
      setPost(res.data.post)
      setcomment(res.data.comment)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getuser()
  }, [params.id])

  return (
    <div>
      <Spin spinning={loading}>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            className="blog-user"
          >
            <Avatar
              src={images.img_ogo}
              style={{ fontSize: 30, marginLeft: 20 }}
            />
            <div>
              <div style={{ fontSize: 20, color: 'black', fontWeight: 600 }}>
                {user.name}
              </div>
              <div style={{ display: 'inline-flex' }}>
                <div>{moment(post.create_at).format('DD-MM-YYYY -  h:mm')}</div>
                <div
                  style={{
                    marginLeft: '20px',
                    backgroundColor: '#b47ede',
                    paddingLeft: 3,
                    paddingRight: 3,
                    borderRadius: 2,
                  }}
                >
                  {topic}
                </div>
              </div>
            </div>
          </div>
          <div className="post-content">
            <Divider plain></Divider>
            {postContent.map(item => {
              return (
                <>
                  <span key={item}>{item}</span>
                  <br />
                </>
              )
            })}
          </div>
          <div>{/* {
              media.map()
            } */}</div>
          <Divider plain></Divider>
          <div>
            <HeartFilled
              style={{
                marginLeft: 20,
                marginRight: 10,
                fontSize: 20,
                color: 'red',
              }}
            />
            <span>{post.count_like}</span>
            <MessageOutlined
              style={{ marginLeft: 20, marginRight: 10, fontSize: 20 }}
            />
            <span>{post.count_comment}</span>
          </div>
          <Divider orientation="left" style={{ fontSize: 20 }} plain>
            Bình luận:
          </Divider>
          <div>
            <List
              itemLayout="horizontal"
              dataSource={comment}
              renderItem={item => (
                <List.Item
                  extra={
                    <CustomeIcon
                      like={item.count_like}
                      comment={item.count_comment}
                    />
                  }
                >
                  <List.Item.Meta
                    style={{ marginLeft: 20 }}
                    avatar={
                      <Avatar
                        size="large"
                        src={item.User.profile_picture_url}
                      />
                    }
                    description={
                      <div>
                        <div
                          className="post-list-comment"
                          style={{
                            backgroundColor: '#eeeeee',
                            padding: 20,
                            borderRadius: 5,
                            fontSize: 15,
                            color: 'black',
                          }}
                        >
                          {item.content}
                          <CustomeIcon
                            like={item.count_like}
                            comment={item.count_comment}
                          />
                          {/* <CustomeImage media={item.PostMedia} /> */}
                        </div>
                        {/* <CusTomeMessageChil> */}
                        {/* <List
                          itemLayout="horizontal"
                          dataSource={item.Comments}
                          renderItem={itemChild => (
                            <List.Item>
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    size="large"
                                    src={itemChild?.User.profile_picture_url}
                                  />
                                }
                                description={
                                  <div
                                    style={{
                                      backgroundColor: '#eeeeee',
                                      padding: 20,
                                      borderRadius: 5,
                                    }}
                                  >
                                    commentchil
                                  </div>
                                }
                              ></List.Item.Meta>
                            </List.Item>
                          )}
                        /> */}
                        {/* </CusTomeMessageChil> */}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default BlogsDetail
