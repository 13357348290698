import { message } from 'antd'
import { Rule } from 'antd/lib/form'
import { error } from 'console'
import R from './R'

export function formatTime(totalSeconds: number) {
    const secondsInMinute = 60
    const secondsInHour = 60 * secondsInMinute
    const secondsInDay = 24 * secondsInHour

    const days = Math.floor(totalSeconds / secondsInDay)
    const hours = Math.floor((totalSeconds % secondsInDay) / secondsInHour)
    const minutes = Math.floor((totalSeconds % secondsInHour) / secondsInMinute)
    const seconds = totalSeconds % secondsInMinute

    if (days > 0) {
        return `${days} ngày ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
            seconds
        ).padStart(2, '0')}`
    } else {
        return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    }
}

export function dateDiffInSeconds(date1: string, date2: string) {
    const startDate = new Date(date1)
    const endDate = new Date(date2)
    const diffInSeconds = (endDate.getTime() - startDate.getTime()) / 1000
    return formatTime(diffInSeconds)
}

export const renderInventoryStatus = (status: number) => {
    switch (status) {
        case 0:
            return 'Chờ nhận đơn'
        case 1:
            return 'Đang đóng hàng'
        case 2:
            return 'Hoàn thành'

        default:
            return '---'
    }
}
export const renderDeliveryStatus = (status: number) => {
    switch (status) {
        case 0:
            return 'Chờ nhận đơn'
        case 1:
            return 'Đang giao đơn'
        case 2:
            return 'Đã giao'

        default:
            return '---'
    }
}

export const renderOrderStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'Chờ xác nhận'
        case 2:
            return 'Đang giao hàng'
        case 3:
            return 'Hoàn thành'
        case 4:
            return 'Huỷ'

        default:
            return '---'
    }
}

export const renderDepartmentName = (id: number) => {
    switch (id) {
        case 2:
            return 'Shipper'
        case 3:
            return 'Nhân viên kho'
        case 4:
            return 'Nhân viên kinh doanh'
        case 5:
            return 'Thu ngân'
        case 6:
            return 'Kế toán'

        default:
            return '---'
    }
}

export function jsonToArray(jsonData: any) {
    var result = []
    for (var i in jsonData) result.push(jsonData[i])
    return result
}
export function calculatorDistance(
    lat1: number | undefined,
    lon1: number | undefined,
    lat2: number | undefined,
    lon2: number | undefined,
    unit = 'K'
) {
    if (!lat1 || !lon1 || !lat2 || !lon2) return 0
    if (lat1 == lat2 && lon1 == lon2) {
        return 0
    } else {
        var radLat1 = (Math.PI * lat1) / 180
        var radLat2 = (Math.PI * lat2) / 180
        var theta = lon1 - lon2
        var radTheta = (Math.PI * theta) / 180
        var dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta)
        if (dist > 1) {
            dist = 1
        }
        dist = Math.acos(dist)
        dist = (dist * 180) / Math.PI
        dist = dist * 60 * 1.1515
        if (unit == 'K') {
            dist = dist * 1.609344
        }
        if (unit == 'N') {
            dist = dist * 0.8684
        }
        // return Math.round((dist + Number.EPSILON) * 100) / 100;
        return dist.toFixed(2)
    }
}

export function sortArrayByDistance(array: any) {
    let result = [...array]
    result.sort((a, b) => {
        return parseFloat(a.distance) - parseFloat(b.distance)
    })
    return result
}

export const replaceSpecialCharacter = (inputString: string) => {
    if (inputString === null || inputString === undefined) return inputString
    return inputString
        .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
        .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
        .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
        .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
        .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
        .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
        .replace(/đ/g, 'd')
        .replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
        .replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
        .replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
        .replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
        .replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
        .replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
        .replace(/Đ/g, 'D')
        .replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '')
        .replace(/\u02C6|\u0306|\u031B/g, '')
        .replace(/\u02C6|\u0306|\u031B/g, '')
        .trim()
        .replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ')
}

export const getPlaceHolder = (label: string) => {
    return `${R.strings().enter} ${label.toLocaleLowerCase()}`
}

interface ISomeObject {
    [key: string]: object
}

export function splitTextEndLine(text: string): Array<string> {
    if (!text) return []
    const splited = text.split('\\n')
    return splited
}
export function lineBreakPost(text: string): Array<string> {
    if (!text) return []
    const splited = text.split('\n')
    return splited
}

export const msToTime = (d: number) => {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)
    const s = Math.floor((d % 3600) % 60)
    const hDisplay = h > 0 ? ('0' + h).slice(-2) : '00'
    const mDisplay = m > 0 ? ('0' + m).slice(-2) : '00'
    const sDisplay = s > 0 ? ('0' + s).slice(-2) : '00'
    return hDisplay + ':' + mDisplay + ':' + sDisplay
}

export const validHeadPhoneNumber = (value: any) => {
    const reg0 = /^[\+]?[(]?[0]{1}[)]?[-\s\.]?[3]{1}[-\s\.]?[0-9]{2,10}$/im
    const reg1 = /^[\+]?[(]?[0]{1}[)]?[-\s\.]?[5]{1}[-\s\.]?[0-9]{2,10}$/im
    const reg2 = /^[\+]?[(]?[0]{1}[)]?[-\s\.]?[7]{1}[-\s\.]?[0-9]{2,10}$/im
    const reg3 = /^[\+]?[(]?[0]{1}[)]?[-\s\.]?[8]{1}[-\s\.]?[0-9]{2,10}$/im
    const reg4 = /^[\+]?[(]?[0]{1}[)]?[-\s\.]?[9]{1}[-\s\.]?[0-9]{2,10}$/im
    const reg5 = /^[\+]?[(]?[8]{1}[)]?[-\s\.]?[4]{1}[-\s\.]?[0-9]{2,10}$/im
    const reg6 = /^[\+]?[(]?[+]{1}[)]?[-\s\.]?[8]{1}[-\s\.]?[4]{1}[-\s\.]?[0-9]{3,9}$/im
    return (
        reg0.test(value) ||
        reg1.test(value) ||
        reg2.test(value) ||
        reg3.test(value) ||
        reg4.test(value) ||
        reg5.test(value) ||
        reg6.test(value)
    )
}

export const errorText = () => ({
    validator(_: Rule, value: string) {
        const reg = /^\d+$/
        if (reg.test(value)) {
            return Promise.resolve()
        }
        return Promise.reject(new Error('Vui lòng chỉ nhập số'))
    },
})

export const errorWhiteSpace = () => ({
    validator(_: Rule, value: string) {
        if (/\s/g.test(value)) {
            return Promise.reject(new Error('Vui lòng không nhập khoảng trắng'))
        }
        return Promise.resolve()
    },
})
