import R from './R'

export const REG_PHONE = /(84|0[3|5|7|8|9])+([0-9]{8})\b/

export const IS_ACTIVE = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const IS_BANNER_HOME = {
    BANNER: 1,
    HOME: 2,
    BANNERHOME: 3,
}
export const IS_BANNER = {}
export const IS_CHECKED = {
    HIDE: 0,
    SHOW: 1,
}
export const IS_LIVESTREAM = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const IS_GIFT_ACTIVE = {
    ACTIVE: 1,
    INACTIVE: 0,
}

export const IS_DEFAULT = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const IS_TYPE_NEWS = {
    DRAF: 1,
    INDRAF: 2,
}
export const CONFIG_STATUS = {
    ACTIVE: 1,
    INACTIVE: 2,
}
export const CONFIG_TYPE = {
    ATTENDANCE: 1,
    ORDER_PROMOTION: 2,
    DAILY_TURN: 3,
    REFERRAL_APP: 4,
    REFERRAL_CODE: 5,
    REFERRAL_MEMBER: 6,
}
export const GIFT_STATUS = {
    PENDING: 0, // đang đợi xác nhận gửi quà
    CONFIRMED: 1, // đã xác nhận
    SENT_GIFT: 2, // đã gửi quà cho người nhận
    USED: 3, // trạng thái quà đã sử dụng
    CANCEL: 4, // từ chối
}

export const FILTER_TYPE = {
    PRICE_MIN_MAX: 2, // Giá từ thấp đến cao
    PRICE_MAX_MIN: 1, // Giá từ cao đến thấp
    TIME_NEW_OLD: 1, // Tin đăng mới nhất
    TIME_OLE_NEW: 2, // Tin đăng cũ nhất
}
export const BUY_TYPE = {
    ALL: 0,
    SELL: 1,
    NOTSELL: 2,
}
export const IS_PUSH = {
    PUSHED: 1,
    UN_PUSHED: 0,
}
export const DF_NOTIFICATION = {
    FLOWER_DELIVERY: 19,
    ORDER: 2,
    COMMENT: 3,
    ORDER_RATE: 25,
    GIFT_CHANGE_REQUEST: 9,
    GOOD_COMMENT: 33,
    DEBT: 35,
    CANCEL_REPORT: 32,

    // ALL: 1, // thông báo tất cả
    // ORDER_SHOP: 2, // thông báo trạng thái đơn hàng
    // COMMENT_POST: 3, // thông báo có người bình luận bài viết
    // LIKE_POST: 4, // thông báo có người thích bài viết
    // SEND_COMMENT: 5, // thông báo shop trả lời bình luận
    // LIKE_COMMENT: 6, // thông báo shop thích bình luận
    // SHOP_CREATE_LIVESTREAM: 7, // thông báo shop tạo livestream
    // REGISTER_USER: 8, // thông báo đăng kí tài khoản thành công được cộng điểm
    // PURCHASE_GIFT: 9, // thông báo có yêu cầu đổi quà web admin
    // CONFIRM_PURCHASE_GIFT: 10, // Thông báo trạng thái đổi quà của bạn
    // NEW_ORDER: 11, // Thông báo shop có đơn hàng cần duyệt
    // GIFT_EXCHANGE: 12, // Thông báo trừ điểm
}
export const SOCKET = {
    ADMIN: 'admin',
    SHOP: 'shop',
}
export const STOKE_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
}

export const FLOWER_DELIVERY_STATUS = {
    REQUESTED: 1,
    APPROVED: 2,
    REJECTED: 3,
}

export const ORDER_STATUS = {
    PENDING: 'wait_confirmation', // chờ xác nhận
    INPROGRESS: 'inprogress', // đã xác nhận
    SUCCCESS: 'completed', // hoàn thành
    CANCELED: 'cancelled', // huỷ
}

export const TYPE_POINT_TRANSACTION_HISTORY = {
    ADD: 1,
    SUB: 0,
}
export const PRODUCT_STATUS = {
    AVAILABLE: 1,
    UNAVAILABLE: 0,
}
export const PRODUCT_STOCK_STATUS = {
    AVAILABLE: 1,
    UNAVAILABLE: 0,
}
export const PRODUCT_PRICE_STATUS = {
    AVAILABLE: 1,
    UNAVAILABLE: 0,
}
export const MEDIA_TYPE = {
    IMAGE: 1,
    VIDEO: 0,
}
export const GENDER = {
    MALE: 0,
    FEMALE: 1,
}
export const CUSTOMER = {
    GENERAL: 1,
    ORDER: 2,
    HISTORY: 3,
}
export const CART_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const USER_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const CATEGORY_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const TOPIC_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const DAILY_ATTENDANCE_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const IS_READ = {
    READ: 1,
    NOT_READ: 0,
}
export const USER_TOPIC_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const TAB = {
    PRODUCT: 0,
    CATEGORY: 1,
    LIVESTREAM: 2,
    POST: 3,
}
export const USER_TOPIC_MESSAGE_STATUS = {
    UNBLOCK: 1,
    BLOCK: 0,
}

export const MODE_MESSAGE = {
    DEFAULT: 1,
    SEARCH: 2,
    NOT_READ: 3,
}

export const LIVESTREAM_ENABLE = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const REACTION_TYPE = {
    LIKE: 1,
    HEART: 2,
    LOVE: 3,
    HAHA: 4,
    SURPRISE: 5,
    SAD: 6,
    ANGRY: 7,
}
export const LIVESTREAM_STATUS = {
    INITIAL: 0,
    STREAMING: 1,
    FINISHED: 2,
}
export const SHOP_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const POST_STATUS = {
    PRIVATE: 0, // chỉ riếng mình tôi thấy
    ALL: 1, // tất cả thấy
    CUSTOMER: 2, // chỉ khách hàng thấy
    SHOP: 3, // chỉ tài khoản gian hàng thấy
    SHOP_INTERNAL: 4, // chỉ nội bộ shop thấy
}
export const PAKAGE_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
}
export const STALL = {
    HANOI: 1,
    HCM: 2,
}
export const API_STATUS = {
    UNAUTHORIZED: 401,
    FORBIDEN: 403,
    NOT_FOUND: 404,
}

export const CHANGE_STATUS = {
    ACTIVE: 'Bật hoạt động',
    INACTIVE: 'Tạm dừng hoạt động',
}

export const PRODUCT_TYPE_VIDEO_IMAGE = {
    IMAGE: 0,
    VIDEO: 1,
}

export const STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
}

export const PAKAGE_CATEGORY = {
    ADD_PAKAGE: 1,
    SUB_PAKAGE: 2,
}

export const TAB_SHOP = {
    HISTORY: 0,
    PRODUCT: 1,
    ORDER: 2,
    LIVESTREAM: 3,
}

export const ROLE_NAMES = {
    ADMIN: 'admin',
    ADMIN_EDITOR: 'admin_editor',
    ADMIN_SELL_MANAGER: 'admin_sell_manager',
    ADMIN_SERVICE_MANAGER: 'admin_service_manager',
    SHOP: 'shop',
    SHOP_MEMBER: 'shop_member',
    CUSTOMER: 'customer',
}
export const SHOP_ROLE = {
    SHOP: 2,
    SHOP_MEMBER: 7,
}
export const ADMIN_ROLE = {
    ROOT_ADMIN: 1,
    SHOP_ADMIN: 2,
    CUSTOMER_MANAGER: 3,
    CUSTOMER_CARE: 4,
    REPORT_MANAGER: 5,
    PRODUCT_MANAGER: 6,
    PREFERENTIAL_MANAGER: 7,
    SHIPPER_MANAGER: 8,
    SALE_MANAGER: 11,
    INVERTORY_STAFF: 10,
    ACCOUNTANT: 9,
}

export const IS_ADMIN = {
    ADMIN: 1,
    ADMIN_SHOP: 0,
}

export const TOPIC_TYPE = {
    PROMOTION: 1, // Khuyến mãi
    NOTIFICATION: 2, // Thông báo
}

export const COMUNITY_POST_STATUS = {
    POSTED: 1,
    NOT_POSTED_YET: 0,
}

export const PRODUCT_TYPE = {
    DETAIL: 1, //Thông tin sản phẩm
    SELL: 2, //Khách hàng mua sản phẩm
    ORDER: 3, //Danh sách đơn hàng
}

export const REPORT_TYPE = {
    CATEGORY: 1, //Nhóm sản phẩm
    CUSTOMER: 2, //Theo khách hàng
}

export const PRODUCTS_TYPE = {
    SELLING: 1, //Hàng bán chạy
    NEW_PRODUCTS: 2, //Hàng mới
    DISCOUNT: 3, //Hàng giảm giá
}

export const REGION = {
    HN: '9',
    DL: '10',
    HCM: '11',
}

// export const FLOWER_DELIVERY_STATUS = {
//   REQUESTED: 'requested',
//   APPROVED: 'approved',
//   REJECTED: 'rejected',
// }

export const CREATALE_TYPE = {
    ORDER_BY_CUSTOMER: 'user',
    ORDER_BY_STAFF: 'admin',
}
export const ROLE = {
    ADMIN: 'admin',
    USER: 'user',
}
export const TRANSPORT_STATUS = {
    PENDDING: 1, //Chờ xử lý
    TRANSPORTING: 2, //Đang giao hàng
    TRANSPORT_SUCCESS: 3, //Giao thành công
    IN_TRANSFER: 4, //Đang chuyển hoàn
    COMPLETE_TRANSFER: 5, //Đã chuyển hoàn
    CANCELED_TRANSPORT: 6, //Đã hủy
    PICKING: 7, //Đang lấy hàng
    WAIT_GET: 8, //Chờ lấy lại
    TOOK: 9, //Đã lấy hàng
    WAIT_HANDOVER: 10, //Chờ giao lại
    WAIT_RETURN: 11, //Chờ chuyển hoàn
    WAIT_REFUND: 12, //Chờ chuyển hoàn lại
}

export const PAYMENT_STATUS = {
    PAID: 'completed',
    UNPAID: 'pending',
}

export const ORDER_EXPORT = {
    INDEX: 'STT',
    CODE: 'Mã đơn',
    CUSTOMER: 'Khách hàng',
    SHOP: 'Gian hàng',
    QUANTITY_PRODUCT: 'Số SP',
    TOTAL_MONEY: 'Tổng tiền',
    PAYMENT_STATUS_EXP: 'TT thanh toán',
    ORDER_STATUS: 'TT đơn hàng',
    TRANSPORT_STATUS_EXP: 'TT vận chuyển',
    CREATE_AT: 'Ngày tạo',
}

export const PAYMENT_METHOD = {
    COD: 'cod',
    BANKING: 'banking',
    COIN: 'coin',
    DEBIT: 'debit',
}

export const CUSTOMER_EXPORT = {
    INDEX: 'STT',
    FULL_NAME: 'Tên khách hàng',
    PHONE_NUMBER: 'Số điện thoại',
    PROVINCE: 'Tỉnh thành phố',
    REVENUE_BY_ORDER: 'Doanh thu trên đơn hàng',
    REVENUE_BY_REAL: 'Doanh thu thực tế',
    CREATED_AT: 'Ngày tạo',
}

export const PRODUCT_CUSTOM_TYPE = {
    IS_NEW: 'is_new',
    IS_BEST_SELLING: 'is_best_selling',
    IS_SALE_OFF: 'is_sale_off',
}

export const PRODUCT_EXPORT = {
    INDEX: 'STT',
    CODE: 'Mã sảm phẩm',
    FULL_NAME: 'Tên sảm phẩm',
    CATEGORY: 'Danh mục',
    STATUS: 'Trạng thái',
    TOTAL: 'Tổng tồn',
}

export const TEXT_ACTIVE = {
    ACTIVE: 'Đang hoạt động',
    INACTIVE: 'Ngừng hoạt động',
}
export const TEXT_FLOWER_ACTIVE = {
    FLOWERCONFIRML: 'Đã xác nhận',
    FLOWERCANCEL: 'Từ chối',
    FLOWERWAIT: 'Chờ xác nhận',
}
export const DEBIT_STATUS = {
    PAID: 'paid',
    UNPAID: 'unpaid',
    OUT_OF_DATE: 'out_of_date',
}

export const ACTIONS = {
    ORDER_COMPLETE: 'order_completed', //hoàn thành đơn hàng
    ORDER_PAYMENT: 'payment_order', //thanh toán đơn hàng
    DEPOSIT: 'deposit', // nạp tiền thành công
    JOIN_WITH_INVATE: 'join_with_invite', // đăng ký với mã giới thiệu
    INVATE: 'invite', // giới thiệu ứng dụng
    GIFT_EXCHANGE: 'gift_exchange', //đổi quà
}
export const COIN = {
    ADDCOIN: 'add',
    REDUCECOIN: 'subtract',
}
export const STATUSLIVE = {
    SUCCESS: 'success',
    ERROR: 'expired',
}
