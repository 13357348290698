import { DeleteFilled, EditOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, Card, Popconfirm, Switch, Tabs } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { IS_ACTIVE } from 'utils/constants'
import StaffGeneralInfo from './StaffGeneralInfo'
import WrapOrderReport from './WrapOrderReport'

interface IStaffInfo {
    record: any
    currentTab: string
    staffDetail: any
    pagingDetail: any
    searchDetail: string
    orderStatus: any
    wrapStatus: any
    receiveOrderFromDate: any
    receiveOrderToDate: any
    completeOrderFromDate: any
    completeOrderToDate: any
    provinces: any
    onExportExcelByTab: any
    isIventoryManager: boolean
    changeStaffStatus: () => Promise<void>
    setPagingDetail: React.Dispatch<any>
    setReceiveOrderFromDate: React.Dispatch<React.SetStateAction<string | undefined>>
    setReceiveOrderToDate: React.Dispatch<React.SetStateAction<string | undefined>>
    setCompleteOrderFromDate: React.Dispatch<React.SetStateAction<string | undefined>>
    setCompleteOrderToDate: React.Dispatch<React.SetStateAction<string | undefined>>
    setWrapStatus: React.Dispatch<React.SetStateAction<number | undefined>>
    setOrderStatus: React.Dispatch<React.SetStateAction<string | undefined>>
    setSearchDetail: React.Dispatch<React.SetStateAction<string>>
    setCurrentTab: React.Dispatch<React.SetStateAction<string>>
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setIsCPModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    deleteStaff: () => Promise<void>
    setIsAdd: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const StaffInfo = (props: IStaffInfo) => {
    const {
        record,
        staffDetail,
        pagingDetail,
        searchDetail,
        orderStatus,
        wrapStatus,
        receiveOrderFromDate,
        receiveOrderToDate,
        completeOrderFromDate,
        completeOrderToDate,
        currentTab,
        provinces,
        onExportExcelByTab,
        isIventoryManager,
        setIsModalOpen,
        setIsCPModalOpen,
        deleteStaff,
        setCurrentTab,
        setPagingDetail,
        setReceiveOrderFromDate,
        setReceiveOrderToDate,
        setCompleteOrderFromDate,
        setCompleteOrderToDate,
        setWrapStatus,
        setOrderStatus,
        setSearchDetail,
        changeStaffStatus,
        setIsAdd,
    } = props
    return (
        <Card
            actions={
                !isIventoryManager
                    ? [
                          <Button
                              onClick={() => {
                                  setIsModalOpen(true)
                                  setIsAdd(false)
                              }}
                              type="text"
                              size="large"
                              style={{ color: 'green' }}
                              icon={<EditOutlined />}
                              children={'Chỉnh sửa'}
                          />,
                          <SwitchBlock>
                              <Switch checked={record.status == IS_ACTIVE.ACTIVE} onChange={changeStaffStatus} />
                              {record.status ? (
                                  <p style={{ color: 'green', margin: 0, marginLeft: 10 }}>Đang hoạt động</p>
                              ) : (
                                  <p style={{ color: 'red', margin: 0, marginLeft: 10 }}>Ngừng hoạt động</p>
                              )}
                          </SwitchBlock>,
                          <Button
                              type="text"
                              size="large"
                              style={{ color: '#e0a561' }}
                              icon={<RedoOutlined />}
                              children={'Đổi mật khẩu'}
                              onClick={() => setIsCPModalOpen(true)}
                          />,
                          <Popconfirm
                              title={'Bạn có chắc chắn muốn XOÁ nhân viên kho này?'}
                              onConfirm={() => deleteStaff()}
                              okText={'Xoá'}
                              cancelText={'Đóng'}
                          >
                              <Button
                                  type="text"
                                  size="large"
                                  style={{ color: '#cc0000' }}
                                  icon={<DeleteFilled />}
                                  children={'Xoá'}
                              />
                          </Popconfirm>,
                      ]
                    : []
            }
            style={{ width: '100%', backgroundColor: '#f6f9ff' }}
        >
            <Tabs
                activeKey={currentTab}
                style={{ backgroundColor: '#f6f9ff' }}
                onChange={(value: string) => setCurrentTab(value)}
            >
                <CustomTabPane tab={<span style={{ margin: 10 }}>Thông tin chung</span>} key="1">
                    <StaffGeneralInfo staffDetail={staffDetail} provinces={provinces} />
                </CustomTabPane>
                <CustomTabPane tab={<span style={{ margin: 10 }}>Báo cáo đóng đơn hàng</span>} key="2">
                    <WrapOrderReport
                        staffDetail={staffDetail}
                        pagingDetail={pagingDetail}
                        searchDetail={searchDetail}
                        orderStatus={orderStatus}
                        wrapStatus={wrapStatus}
                        receiveOrderFromDate={receiveOrderFromDate}
                        receiveOrderToDate={receiveOrderToDate}
                        completeOrderFromDate={completeOrderFromDate}
                        completeOrderToDate={completeOrderToDate}
                        onExportExcelByTab={onExportExcelByTab}
                        setReceiveOrderFromDate={setReceiveOrderFromDate}
                        setReceiveOrderToDate={setReceiveOrderToDate}
                        setCompleteOrderFromDate={setCompleteOrderFromDate}
                        setCompleteOrderToDate={setCompleteOrderToDate}
                        setSearchDetail={setSearchDetail}
                        setOrderStatus={setOrderStatus}
                        setWrapStatus={setWrapStatus}
                        setPagingDetail={setPagingDetail}
                    />
                </CustomTabPane>
            </Tabs>
        </Card>
    )
}

const CustomTabPane = styled(Tabs.TabPane)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const SwitchBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export default StaffInfo
