import { ApiClient } from 'services/ApiService'
import {
  IListLivePayload,
  ResponseData,
  IListLiveStream,
  ILivestreamDetail,
  IListComment,
  IListCommentPayload,
} from './LivestreamInterfaces'

export const getListLiveStream = (
  payload: IListLivePayload
): ResponseData<IListLiveStream[]> => {
  return ApiClient.get('/admin/livestream', payload)
}

export const getLiveStreamDetail = (
  livestream_id: number
): ResponseData<ILivestreamDetail> => {
  return ApiClient.get(`/admin/livestream/${livestream_id}`, { livestream_id })
}

export const getListComment = (
  payload: IListCommentPayload
): ResponseData<IListComment[]> => {
  return ApiClient.get(
    `/admin/livestream/${payload.livestream_id}/comment`,
    payload
  )
}
