import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { PageHeader, Radio, Row, Spin, Table, message } from 'antd'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import ButtonSave from 'common/components/Button/ButtonSave'
import { useDebounce } from 'common/hooks/Debounce'
import Container from 'container/Container'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import React from 'react'
import styled from 'styled-components'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'
import AddEditShipperModal from '../components/AddEditShipperModal'
import Filter from '../components/Filter'
import ShipperInfo from '../components/ShipperInfo'
import {
    addShipper,
    changePassword,
    changeShipperStatus,
    deleteShipper,
    exportExcel,
    exportExcelCodHistory,
    exportExcelDeliveryOrder,
    exportExcelRefundOrder,
    getShipperCODHistory,
    getShipperDeliveredOrder,
    getShipperOverview,
    getShipperRefundOrder,
    getShippers,
    updateShipper,
} from '../services'
import ChangePasswordModal from '../components/ChangePasswordModal'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

const ShipperManage = () => {
    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Tên shipper</b>,
            dataIndex: 'name',
            render: (_: any, record: any) => record?.full_name,
        },
        {
            title: <b>Số điện thoại</b>,
            dataIndex: 'phone',
            render: (_: any, record: any) => record?.phone_number,
        },
        {
            title: <b>Email</b>,
            dataIndex: 'email',
            render: (_: any, record: any) => record?.email,
        },
        {
            title: <b>Địa chỉ</b>,
            dataIndex: 'address',
            render: (_: any, record: any) => record?.address || '---',
        },
        {
            title: <b>Ngày tạo</b>,
            dataIndex: 'date',
            render: (_: any, record: any) => momentToStringDate(record?.created_at),
        },
    ]

    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN

    const [loading, setLoading] = React.useState<boolean>(false)
    const [currentRecord, setCurrentRecord] = React.useState<any>()
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const [isCPModalOpen, setIsCPModalOpen] = React.useState<boolean>(false)
    const [listKiotviet, setlistKiotviet] = React.useState<IKiotviet[]>()
    const [selectedKiotVietId, setSelectedKiotVietId] = React.useState<any>(
        isShopAdmin ? userInfor?.admin?.kiotviet_id : ''
    )
    const [search, setSearch] = React.useState<string>('')
    const [fromDate, setFromDate] = React.useState<string>('')
    const [toDate, setToDate] = React.useState<string>('')
    const [data, setData] = React.useState<any[]>([])
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<number[]>([])
    const [currentTabOpen, setCurrentTabOpen] = React.useState<string>('1')
    const [shipperDetail, setShipperDetail] = React.useState<any>()
    const [shipperOpenId, setShipperOpenId] = React.useState<any>()
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const searchDebounce = useDebounce(search, 300)

    // Detail filter
    const [searchDetail, setSearchDetail] = React.useState<string>('')
    const searchDetailDebounce = useDebounce(searchDetail, 300)
    const [orderStatus, setOrderStatus] = React.useState<string>()
    const [deliveryStatus, setDeliveryStatus] = React.useState<number>()
    const [refundStatus, setRefundStatus] = React.useState<number>()
    const [fromDateDetail, setFromDateDetail] = React.useState<string>()
    const [toDateDetail, setToDateDetail] = React.useState<string>()
    const [pagingDetail, setPagingDetail] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
                setlistKiotviet(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getListShippers = async () => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: selectedKiotVietId,
                page: paging?.current,
                limit: paging?.limit,
                search: searchDebounce.trim(),
                create_from: fromDate,
                create_to: toDate,
            }
            const res: any = await getShippers(payload)
            if (res?.status) {
                const listData = res?.data?.map((item: any) => ({ ...item, key: item?.id }))
                setData(listData)
                const newPaging = {
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleChangeStock = (idStock: any) => {
        setSelectedKiotVietId(idStock)
        setExpandedRowKeys([])
    }

    const getShipperDetail = async () => {
        try {
            setLoading(true)
            const payload = {
                id: shipperOpenId,
                code: searchDetailDebounce.trim(),
                status: currentTabOpen === '2' ? orderStatus : refundStatus,
                delivery_status: deliveryStatus,
                from_date: fromDateDetail,
                to_date: toDateDetail,
                page: pagingDetail.current,
                limit: pagingDetail.pageSize,
            }
            let res: any
            if (currentTabOpen === '1') {
                res = await getShipperOverview(payload)
            } else if (currentTabOpen === '2') {
                res = await getShipperDeliveredOrder(payload)
            } else if (currentTabOpen === '3') {
                res = await getShipperRefundOrder(payload)
            } else {
                res = await getShipperCODHistory(payload)
            }
            if (res?.status) {
                setShipperDetail(res?.data)
                const newPaging = {
                    ...pagingDetail,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPagingDetail(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const exportExcelFile = async () => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: selectedKiotVietId,
                search: searchDebounce.trim(),
                create_from: fromDate,
                create_to: toDate,
            }
            const res: any = await exportExcel(payload)
            if (res?.status) {
                message.success('Xuất excel thành công!')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onChangeShipperStatus = async () => {
        try {
            setLoading(true)
            const res = await changeShipperStatus(shipperOpenId)
            if (res?.status) {
                message.success('Thay đổi trạng thái shipper thành công!')
                getListShippers()
                getShipperDetail()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onDeleteShipper = async () => {
        try {
            setLoading(true)
            const res = await deleteShipper(shipperOpenId)
            if (res?.status) {
                message.success('Xoá shipper thành công!')
                getListShippers()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onAddUpdateShipper = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: values?.deliveryArea,
                province_id: values?.address,
                gender: values?.gender,
                date_of_birth: values?.dob?.format('YYYY-MM-DD'),
                email: values?.email,
                phone_number: values?.phone,
                full_name: values?.name,
            }
            let res: any
            if (currentRecord) {
                res = await updateShipper(currentRecord?.id, payload)
            } else {
                res = await addShipper(payload)
            }
            if (res?.status) {
                message.success(currentRecord ? 'Cập nhật shipper thành công!' : 'Thêm shipper thành công!')
                setIsModalOpen(false)
                getListShippers()
                if (currentRecord) {
                    getShipperDetail()
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const changePasswordShipper = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                password: values?.password,
            }
            const res: any = await changePassword(currentRecord?.id, payload)
            if (res?.status) {
                message.success('Cập nhật mật khẩu thành công!')
                setIsCPModalOpen(false)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const exportExcelByTab = async (tabKey: string) => {
        try {
            setLoading(true)
            const payload = {
                id: shipperOpenId,
                code: searchDetailDebounce.trim(),
                status: currentTabOpen === '2' ? orderStatus : refundStatus,
                delivery_status: deliveryStatus,
                from_date: fromDateDetail,
                to_date: toDateDetail,
                page: pagingDetail.current,
                limit: pagingDetail.pageSize,
            }

            let res: any
            if (tabKey === '2') {
                res = await exportExcelDeliveryOrder(shipperOpenId, payload)
            } else if (tabKey === '3') {
                res = await exportExcelRefundOrder(shipperOpenId, payload)
            } else {
                res = await exportExcelCodHistory(shipperOpenId, payload)
            }

            if (res?.status) {
                // message.success('Xuất file excel thành công!')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getDataKiotviet()
    }, [])

    React.useEffect(() => {
        if (pagingDetail.current === 1) {
            getShipperDetail()
        } else {
            setPagingDetail((prev: any) => ({ ...pagingDetail, current: 1 }))
        }
    }, [searchDetailDebounce, orderStatus, deliveryStatus, refundStatus, fromDateDetail, toDateDetail])

    React.useEffect(() => {
        setSearchDetail('')
        setOrderStatus(undefined)
        setDeliveryStatus(undefined)
        setRefundStatus(undefined)
        setFromDateDetail('')
        setToDateDetail('')

        if (pagingDetail.current === 1) {
            getShipperDetail()
        } else {
            setPagingDetail((prev: any) => ({ ...pagingDetail, current: 1 }))
        }
    }, [expandedRowKeys, currentTabOpen])

    React.useEffect(() => {
        getShipperDetail()
    }, [pagingDetail.current])

    React.useEffect(() => {
        getListShippers()
    }, [paging.current])

    React.useEffect(() => {
        if (paging.current === 1) {
            getListShippers()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [searchDebounce, fromDate, toDate, selectedKiotVietId])

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        style={{ borderRadius: 8 }}
                        title="Danh sách shipper"
                        extra={[
                            <ButtonSave
                                onClickButton={exportExcelFile}
                                text="Xuất excel"
                                icon={<VerticalAlignBottomOutlined />}
                            />,
                            <ButtonAdd
                                text="Thêm mới"
                                background="#47b2eb"
                                onClickButton={() => {
                                    setCurrentRecord(undefined)
                                    setIsModalOpen(true)
                                }}
                            />,
                        ]}
                    ></PageHeader>
                }
                filterComponent={
                    <Filter search={search} setFromDate={setFromDate} setToDate={setToDate} setSearch={setSearch} />
                }
                contentComponent={[
                    <>
                        {isModalOpen && (
                            <AddEditShipperModal
                                isModalOpen={isModalOpen}
                                listKiotviet={listKiotviet}
                                currentRecord={currentRecord}
                                handleOk={onAddUpdateShipper}
                                setIsModalOpen={setIsModalOpen}
                            />
                        )}
                        {isCPModalOpen && (
                            <ChangePasswordModal
                                visible={isCPModalOpen}
                                handleOk={changePasswordShipper}
                                setIsModalOpen={setIsCPModalOpen}
                            />
                        )}
                        {(selectedKiotVietId || selectedKiotVietId?.length === 0) && (
                            <Radio.Group
                                value={selectedKiotVietId}
                                onChange={(e: any) => handleChangeStock(e.target.value)}
                            >
                                <Radio.Button disabled={isShopAdmin} value={''}>
                                    Tất cả
                                </Radio.Button>
                                {listKiotviet &&
                                    listKiotviet.map((region, index) => (
                                        <Radio.Button
                                            key={index}
                                            value={region.id}
                                            disabled={isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id}
                                        >
                                            {region.name ? region.name : '--'}
                                        </Radio.Button>
                                    ))}
                            </Radio.Group>
                        )}

                        <CustomRow>
                            <p>
                                Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                            </p>
                        </CustomRow>
                        <Table
                            id="top-table"
                            bordered
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: 'Chưa có bản ghi nào!',
                            }}
                            expandable={{
                                expandedRowRender: record => (
                                    <ShipperInfo
                                        record={record}
                                        currentTabOpen={currentTabOpen}
                                        shipperDetail={shipperDetail}
                                        pagingDetail={pagingDetail}
                                        searchDetail={searchDetail}
                                        orderStatus={orderStatus}
                                        deliveryStatus={deliveryStatus}
                                        refundStatus={refundStatus}
                                        fromDateDetail={fromDateDetail}
                                        toDateDetail={toDateDetail}
                                        setIsCPModalOpen={setIsCPModalOpen}
                                        setCurrentRecord={setCurrentRecord}
                                        setIsModalOpen={setIsModalOpen}
                                        onChangeShipperStatus={onChangeShipperStatus}
                                        onDeleteShipper={onDeleteShipper}
                                        setCurrentTabOpen={setCurrentTabOpen}
                                        setSearchDetail={setSearchDetail}
                                        setOrderStatus={setOrderStatus}
                                        setDeliveryStatus={setDeliveryStatus}
                                        setRefundStatus={setRefundStatus}
                                        setFromDateDetail={setFromDateDetail}
                                        setToDateDetail={setToDateDetail}
                                        setPagingDetail={setPagingDetail}
                                        exportExcelByTab={exportExcelByTab}
                                    />
                                ),
                            }}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(expanded: boolean, record: any) => {
                                let keys = []
                                if (expanded) {
                                    keys.push(record.id)
                                }
                                setCurrentRecord(record)
                                setShipperOpenId(record?.id)
                                setExpandedRowKeys(keys)
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: page => {
                                    setPaging({ ...paging, current: page })
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    )
}

const CustomRow = styled(Row)`
    margin: 28px 0 0 0;

    .text {
        font-size: 12px;
        margin: 0;
    }
`

export default ShipperManage
