import { Button, Col, Descriptions, message, Row, Switch } from 'antd'
import { useState, useEffect, memo } from 'react'
import moment from 'moment'
import { IFormatedTableCate } from './CategoryInterfaces'
import { EditOutlined } from '@ant-design/icons'
import { CHANGE_STATUS, STATUS, TEXT_ACTIVE } from 'utils/constants'
import './component/css/Category.css'
import {
  activeCategory,
  inactiveCategory,
  changeIndexOrder,
} from './CategoryAPI'
import EditCate from './EditCate'
import { notificationError, notificationSuccess } from 'utils/notification'
import { convertTimeStampToString } from 'utils/TimerHelper'
import styled from 'styled-components'

const WapperInfoCate = styled.div`
  background-color: '#f1f1f1';
  margin: '10px 0';
  padding: '8px 0';
  border-bottom: '1px solid #e2e2e2';
  text-align: 'center';
`

interface IProp {
  infoCategory: IFormatedTableCate
  getData: any
}

function InfoCategory(props: IProp) {
  const { infoCategory, getData } = props
  const [isShowEditCate, setIsShowEditCate] = useState<boolean>(false)
  const [statusCate, setstatusCate] = useState(
    props.infoCategory.status == STATUS.ACTIVE
  )

  const handleChangeStatus = async (isStatus: boolean) => {
    try {
      if (isStatus) {
        await activeCategory(infoCategory.id.toString())
      } else {
        await inactiveCategory(infoCategory.id.toString())
      }
      getData()
      message.success('Thay đổi trạng thái thành công')
    } catch (err) {
      notificationError('Có lỗi xảy ra')
    }
    setstatusCate(!statusCate)
  }

  const handleSave = async (order: number) => {
    const payload = {
      id: infoCategory.id.toString(),
      data: {
        order: order,
      },
    }
    try {
      await changeIndexOrder(payload)
      getData()
      message.success('Lưu thành công')
    } catch (err) {
      console.log(err)
      notificationError('Có lỗi xảy ra')
    }
    setIsShowEditCate(false)
  }

  return (
    <>
      {isShowEditCate ? (
        <EditCate
          onSave={(order: number) => handleSave(order)}
          onCancel={() => setIsShowEditCate(false)}
          ShowEditCate={isShowEditCate}
          infoCate={infoCategory}
        />
      ) : null}
      <Descriptions column={4} style={{ margin: '20px 0' }}>
        <Descriptions.Item label="Tên danh mục">
          {infoCategory?.name || '---'}
        </Descriptions.Item>

        <Descriptions.Item label="Thứ tự hiển thị" span={3}>
          {infoCategory?.order || '---'}
        </Descriptions.Item>

        <Descriptions.Item label="Trạng thái">
          {infoCategory.status == STATUS.ACTIVE
            ? 'Đang hoạt động'
            : 'Ngừng hoạt động'}
        </Descriptions.Item>

        <Descriptions.Item label="Ngày tạo">
          {convertTimeStampToString(infoCategory?.created_at) || '---'}
        </Descriptions.Item>
      </Descriptions>

      <WapperInfoCate>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Button
              onClick={() => setIsShowEditCate(true)}
              type="text"
              size="large"
              icon={<EditOutlined />}
              style={{ color: '#1890ff' }}
            >
              Chỉnh sửa
            </Button>
          </Col>

          <Col
            span={12}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Switch checked={statusCate} onChange={handleChangeStatus} />
            {!statusCate ? (
              <span className="status-inactive">{TEXT_ACTIVE.INACTIVE}</span>
            ) : (
              <span className="status-active">{TEXT_ACTIVE.ACTIVE}</span>
            )}
          </Col>
        </Row>
      </WapperInfoCate>
    </>
  )
}

export default memo(InfoCategory)
