import { Button, Col, DatePicker, Row, Select } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import React from 'react'
import styled from 'styled-components'
import { ADMIN_ROLE, STATUS } from 'utils/constants'
const { Option } = Select
const { RangePicker } = DatePicker

const Container = styled.div`
    width: 100%;
    padding: 0.5rem;
    background-color: white;
    border-bottom: 1px solid #dddd;
`

type HeaderProps = {
    // setIsCreate: any
    onSearchSubmit: (searchKey: string) => void
    isSearchLoading: boolean
}

export const Filter = ({
    onSearchSubmit,
    isSearchLoading,
}: // checkedTable,
HeaderProps) => {
    return (
        <Row gutter={16}>
            <Col span={12}>
                <TypingAutoSearch
                    onSearchSubmit={(key: string) => {
                        onSearchSubmit(key.trim())
                    }}
                    isSearchLoading={isSearchLoading}
                    placeholder="Tên gian hàng ..."
                />
            </Col>

            {/* <Col span={12}>
        <RangePicker
          placeholder={['Từ ngày', 'Đến ngày']}
          className="rangerpicker-order"
          onChange={(value, dateString) => {
            onDateSubmit(dateString[0], dateString[1])
          }}
        />
      </Col> */}
            {/* </Col> */}
            {/* <Col */}
            {/* xxl={{ span: 4 }} xl={{ span: 6 }} lg={{ span: 12 }}
              span={5}
            > */}
        </Row>
    )
}
