import { Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useDebounce } from 'common/hooks/Debounce'
import React from 'react'

interface ISelectCustomer {
    record: any
    customers: any
    form: any
    updateAssignVoucher: (rowId: number, value: number) => Promise<void>
    getDataCustomer: (search: string) => Promise<void>
}

const SelectCustomer = (props: ISelectCustomer) => {
    const { record, customers, updateAssignVoucher, form, getDataCustomer } = props
    const [search, setSearch] = React.useState<string>('')
    const searchDebounce = useDebounce(search, 300)

    React.useEffect(() => {
        getDataCustomer(searchDebounce)
    }, [searchDebounce])

    return (
        <Select
            disabled={record?.status !== 5}
            placeholder="Chọn khách hàng"
            style={{ width: '100%' }}
            showArrow
            showSearch
            defaultValue={record?.gift_user_code?.length > 0 ? record?.user_full_name : record?.user_id}
            filterOption={false}
            searchValue={search}
            onSearch={(value: any) => setSearch(value)}
            onChange={(value: number) => {
                if (record?.gift_user_code?.length > 0) {
                    updateAssignVoucher(record?.id, value)
                } else {
                    form.setFieldValue(`data_${record?.id}`, value)
                }
            }}
        >
            {customers.map((item: any) => (
                <Select.Option key={item.id} value={item.value}>
                    {item?.label}
                </Select.Option>
            ))}
        </Select>
    )
}

export default SelectCustomer
