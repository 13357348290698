import { CheckCircleOutlined, CloseCircleOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons'
import { Button, Card, Descriptions, Image, Popconfirm, Spin, Tabs, Typography } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { CHANGE_STATUS, STATUS } from 'utils/constants'
import { notificationError, notificationSuccess } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { changeStatusGift, deleteGift, getListAssignGifts } from '../listGiftApi'
import { AddEditGift } from './AddEditGift'
import VoucherForCustomer from './VoucherForCustomer'
import React from 'react'
const { Paragraph } = Typography
const { TabPane } = Tabs

type Props = {
    data?: any
    getData: () => Promise<void>
    isLoadingButton: boolean
}

const ContentView = (data: any, getData: any) => {
    const [listAssignedGift, setListAssignedGift] = React.useState<any[]>([])
    const [currentTab, setCurrentTab] = React.useState<string>()
    const [loading, setLoading] = React.useState<boolean>(false)

    const getListVouchers = async () => {
        try {
            setLoading(true)
            const res = await getListAssignGifts(data?.id)
            if (res?.status) {
                setListAssignedGift(res?.data)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if (currentTab === '2') {
            getListVouchers()
        }
    }, [currentTab])

    return (
        <Spin spinning={loading}>
            <Tabs
                onChange={(value: string) => {
                    setCurrentTab(value)
                }}
            >
                <TabPane tab="Thông tin quà tặng" key="1">
                    <Descriptions size="default" column={3}>
                        <Descriptions.Item label="Tên quà tặng"> {data.name}</Descriptions.Item>
                        <Descriptions.Item label="Loại quà tặng">{data.type_gift_name}</Descriptions.Item>
                        {data.discount_percent ? (
                            <>
                                <Descriptions.Item label="Mã giảm giá">
                                    <Paragraph copyable={{ tooltips: false }}>{data.voucher_code}</Paragraph>
                                </Descriptions.Item>
                            </>
                        ) : (
                            <></>
                        )}

                        <Descriptions.Item label="Số điểm">
                            {data.price === 0 ? 0 : formatPrice(data.price)}
                        </Descriptions.Item>
                        {data.discount_percent ? (
                            <>
                                <Descriptions.Item label="Giảm giá">
                                    {data.discount_percent ? data.discount_percent + '%' : '0%'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Giảm tối đa">
                                    {data.max_discount_money ? formatPrice(data.max_discount_money) + 'đ' : '0đ'}
                                </Descriptions.Item>
                            </>
                        ) : (
                            <></>
                        )}

                        <Descriptions.Item label="Số lượng quà">
                            {data.quantity === 0 ? 0 : formatPrice(data.quantity)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Đã đổi">{data.count_purchased_gift}</Descriptions.Item>
                        <Descriptions.Item label="Ngày tạo">
                            {moment(data.create_at).format('DD-MM-YYYY')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Hình ảnh">
                            <Image
                                src={data.icon_url}
                                style={{ width: '100px', height: '100px', overflow: 'hidden' }}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                </TabPane>
                {data.type_gift_name === 'Mã giảm giá' && (
                    <TabPane tab="Gán cho khách hàng" key="2">
                        <VoucherForCustomer
                            getData={getData}
                            data={data}
                            listAssignedGift={listAssignedGift}
                            getListVouchers={getListVouchers}
                        />
                    </TabPane>
                )}
            </Tabs>
        </Spin>
    )
}

function DetailGift({ data, getData, isLoadingButton }: Props) {
    const [showEditGift, setShowEditGift] = useState(false)

    const onChangeStatus = async () => {
        try {
            const payload = data.id
            await changeStatusGift(payload)
            notificationSuccess(`Cập nhật trạng thái thành công`)
            getData()
        } catch (error) {
            notificationError('Cập nhật trạng thái thất bại')
        }
    }

    const handleDeleteGift = async () => {
        try {
            await deleteGift({ ids: [data.id] })
            notificationSuccess(`Đã xoá`)
            getData()
        } catch (error) {
            notificationError('Đã có lỗi xảy ra, vui lòng thử lại sau')
        }
    }

    return (
        <div>
            <Card
                style={{
                    backgroundColor: '#f6f9ff',
                    borderColor: '#1890ff',
                    borderTop: 'none',
                }}
                actions={[
                    <Popconfirm
                        title={
                            data.status === STATUS.ACTIVE
                                ? 'Bạn chắc chắn muốn ngừng hoạt động gói quà tặng này'
                                : 'Bạn chắc chắn muốn bật hoạt động gói quà tặng này'
                        }
                        onConfirm={() => {
                            onChangeStatus()
                        }}
                        okText={data.status === STATUS.ACTIVE ? CHANGE_STATUS.INACTIVE : CHANGE_STATUS.ACTIVE}
                        cancelText="Quay lại"
                        okButtonProps={
                            data.status === STATUS.ACTIVE
                                ? {
                                      danger: true,
                                      type: 'primary',
                                  }
                                : {
                                      danger: false,
                                      type: 'primary',
                                  }
                        }
                    >
                        <Button
                            type="text"
                            size="large"
                            style={{
                                color: data.status === STATUS.ACTIVE ? 'red' : '#389e0d',
                            }}
                            icon={data.status === STATUS.ACTIVE ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                        >
                            {data.status === 1 ? CHANGE_STATUS.INACTIVE : CHANGE_STATUS.ACTIVE}
                        </Button>
                    </Popconfirm>,

                    <Button
                        onClick={() => {
                            setShowEditGift(true)
                        }}
                        type="text"
                        size="large"
                        icon={<EditOutlined />}
                        style={{ color: '#1890ff' }}
                    >
                        Chỉnh sửa
                    </Button>,

                    <Popconfirm title={'Bạn chắc chắn muốn xoá gói quà tặng này'} onConfirm={handleDeleteGift}>
                        <Button type="text" size="large" icon={<DeleteFilled />} style={{ color: 'red' }}>
                            Xoá
                        </Button>
                    </Popconfirm>,
                ]}
            >
                {ContentView(data, getData)}
            </Card>
            {showEditGift ? (
                <AddEditGift
                    isLoadingButton={isLoadingButton}
                    visible={showEditGift}
                    onCancel={() => {
                        setShowEditGift(false)
                    }}
                    data={data}
                    getData={getData}
                />
            ) : null}
        </div>
    )
}
export default DetailGift
