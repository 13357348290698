import { ApiClient } from 'services/ApiService'

interface PayloadLogin {
  password: string
  phone_number: string
}

export const requestLogin = (payload: PayloadLogin) =>
  ApiClient.post('/admin/session', payload)
export const requestLogout = () => ApiClient.put('/users/logout')
export const requestGetUserInfo = () => ApiClient.get('/admin/session/me')
