import { useState, useEffect, memo } from 'react'
import { Input } from 'antd'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import { useDebounce } from 'common/hooks/Debounce'
const { Search } = Input

const Container = styled.div`
    width: 100%;
    background-color: white;
`
type IProps = {
    onSearchSubmit: (key: string) => void
    isSearchLoading?: boolean
    placeholder?: string
    width?: number
    defaultSearch?: string
}

const AutoSearchInput = ({ onSearchSubmit, placeholder, width, defaultSearch = '' }: IProps) => {
    const [searchKey, setSearchKey] = useState<string>(defaultSearch)
    const debouncedSearchTerm = useDebounce(searchKey, 500)

    useEffect(() => {
        if (debouncedSearchTerm) {
            onSearchSubmit(searchKey)
        } else {
            onSearchSubmit('')
        }
    }, [debouncedSearchTerm])

    useEffect(() => {
        setSearchKey(defaultSearch)
    }, [defaultSearch])

    const handleInputOnchange = (e: any) => {
        const { value } = e.target
        setSearchKey(value)
    }

    return (
        <Container>
            <Search
                style={{ width: `${width}px` }}
                value={searchKey}
                allowClear
                addonAfter={<Icon type="close-circle-o" />}
                placeholder={placeholder}
                onChange={e => {
                    handleInputOnchange(e)
                }}
            />
        </Container>
    )
}

export default memo(AutoSearchInput)
