import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import { ADMIN_ROUTER_PATH } from 'common/config'

export const StyledContainer = styled.div`
    position: relative;
    width: 99%;
    height: 100%;
    /* max-width: 1280px; */
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    /* padding: 0 1rem 0 1rem; */
    /* background-color: aquamarine; */

    /* @media only screen and (max-width: 1024px) {
    max-width: 950px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 700px;
  }

  @media only screen and (max-width: 414px) {
    max-width: 370px;
  } */
`

type Props = {
    filterComponent: any
    contentComponent?: any
    headerComponent: any
    emptyContentComponent: any
    footerComponent?: any
}

const Container = ({
    filterComponent,
    contentComponent,
    emptyContentComponent,
    headerComponent,
    footerComponent,
}: Props) => {
    return (
        <StyledContainer>
            {/* <Row justify="center">
        <Col
          lg={23}
          md={23}
          xs={23}
          style={{
            padding: 10,
            marginTop: 8,
            borderRadius: 5,
            backgroundColor: 'white',
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
          }}
        >
          {headerComponent &&
            (typeof headerComponent == 'function'
              ? headerComponent!()
              : headerComponent)}
        </Col>
      </Row> */}
            <Row
                justify="center"
                style={{
                    width: '100%',
                    height: 'calc(100% - 8px)',
                    backgroundColor: '#dddddd',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Col
                    style={{
                        backgroundColor: 'white',
                        padding: 1,
                        height: '100%',
                        // boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                    }}
                    lg={6}
                    md={6}
                    xs={4}
                >
                    {typeof filterComponent == 'function' ? filterComponent!() : filterComponent}
                </Col>
                <Col
                    style={{
                        backgroundColor: 'white',
                        height: '100%',
                        padding: 2,
                        // boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                    }}
                    lg={18}
                    md={18}
                    xs={20}
                >
                    <Route path={`${ADMIN_ROUTER_PATH.CHAT}/:id`} component={contentComponent} exact />
                    <Route path={`${ADMIN_ROUTER_PATH.CHAT}`} component={emptyContentComponent} exact />
                </Col>
            </Row>
        </StyledContainer>
    )
}
export default Container
