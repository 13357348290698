import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { Route } from 'react-router-dom'

import {} from 'common/config'

export const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  padding: 0 1rem 0 1rem;
`

type Props = {
  contentComponent: any
  headerComponent: any
}

const ContainerDetailPost = ({ contentComponent, headerComponent }: Props) => {
  return (
    <StyledContainer>
      <Row
        style={{
          marginTop: 8,
          borderRadius: 1,
          backgroundColor: 'white',
          boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        }}
      >
        {typeof headerComponent == 'function'
          ? headerComponent!()
          : headerComponent}
      </Row>
      <Row
        style={{
          marginTop: 8,
          backgroundColor: 'white',
          marginRight: 1,
          boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        }}
      >
        {typeof contentComponent == 'function'
          ? contentComponent!()
          : contentComponent}
      </Row>
    </StyledContainer>
  )
}
export default ContainerDetailPost
