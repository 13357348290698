import Icon, { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Col, DatePicker, Input, Row, Select, Table } from 'antd'
import ButtonSave from 'common/components/Button/ButtonSave'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { renderRefundStatus } from 'utils/Configs'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'

interface IShipperReturnedOrder {
    shipperDetail: any
    pagingDetail: any
    searchDetail: string
    deliveryStatus: number | undefined
    refundStatus: number | undefined
    fromDateDetail: string | undefined
    toDateDetail: string | undefined
    setSearchDetail: React.Dispatch<React.SetStateAction<string>>
    setRefundStatus: React.Dispatch<React.SetStateAction<number | undefined>>
    setFromDateDetail: React.Dispatch<React.SetStateAction<string | undefined>>
    setToDateDetail: React.Dispatch<React.SetStateAction<string | undefined>>
    setPagingDetail: React.Dispatch<any>
    exportExcelByTab: (tabKey: string) => Promise<void>
}

const ShipperReturnedOrder = (props: IShipperReturnedOrder) => {
    const {
        shipperDetail,
        pagingDetail,
        searchDetail,
        refundStatus,
        fromDateDetail,
        toDateDetail,
        setSearchDetail,
        setRefundStatus,
        setFromDateDetail,
        setToDateDetail,
        setPagingDetail,
        exportExcelByTab,
    } = props
    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) =>
                (pagingDetail.current - 1) * pagingDetail.pageSize + index + 1,
        },
        {
            title: <b>Mã đơn hàng</b>,
            dataIndex: 'orderCode',
            render: (_: any, record: any) => record?.order_code,
        },
        {
            title: <b>Người giao đơn</b>,
            dataIndex: 'admin_name',
            render: (value: string) => value || '---',
        },
        {
            title: <b>Khách hàng</b>,
            dataIndex: 'user_full_name',
            render: (value: string) => value || '---',
        },
        {
            title: <b>Trạng thái đơn hoàn trả</b>,
            dataIndex: 'orderStatus',
            render: (_: number, record: any) => renderRefundStatus(record?.status),
        },
        {
            title: <b>Ngày giao</b>,
            dataIndex: 'date',
            render: (_: any, record: any) => momentToStringDate(record?.created_at),
        },
    ]

    return (
        <Container>
            <Row>
                <Row gutter={[16, 16]} className="filter_block">
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Input.Search
                            allowClear
                            value={searchDetail}
                            className="search"
                            placeholder="Mã đơn hàng"
                            addonAfter={<Icon type="close-circle-o" />}
                            onChange={(e: any) => setSearchDetail(e.target.value)}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Select
                            allowClear
                            value={refundStatus}
                            style={{ width: '100%' }}
                            placeholder="Trạng thái đơn hoàn trả"
                            onChange={(value: number | undefined) => setRefundStatus(value)}
                        >
                            <Select.Option value={0}>Chờ nhận đơn</Select.Option>
                            <Select.Option value={1}>Đang lấy hàng</Select.Option>
                            <Select.Option value={2}>Đã hoàn trả</Select.Option>
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <DatePicker.RangePicker
                            style={{ width: '100%' }}
                            placeholder={['Từ ngày', 'Đến ngày']}
                            className="rangerpicker-order"
                            format={'DD-MM-YYYY'}
                            value={fromDateDetail ? [moment(fromDateDetail), moment(toDateDetail)] : undefined}
                            onChange={async (value, dateString) => {
                                setFromDateDetail(dateString[0].split('-').reverse().join('-'))
                                setToDateDetail(dateString[1].split('-').reverse().join('-'))
                            }}
                        />
                    </Col>
                </Row>
                <div className="button_block">
                    <ButtonSave
                        onClickButton={() => exportExcelByTab('3')}
                        text="Xuất excel"
                        icon={<VerticalAlignBottomOutlined />}
                    />
                    ,
                </div>
            </Row>
            <Row style={{ marginTop: 20 }}>
                <p>
                    Tổng đơn:{' '}
                    <span>
                        <b>{shipperDetail?.progress}</b>
                    </span>
                </p>
            </Row>
            <Table
                bordered
                columns={columns}
                dataSource={shipperDetail?.data}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: 'Chưa có bản ghi nào!',
                }}
                pagination={{
                    ...pagingDetail,
                    showSizeChanger: false,
                    onChange: page => {
                        setPagingDetail({ ...pagingDetail, current: page })
                    },
                }}
            />
        </Container>
    )
}

const Container = styled.div`
    flex: 1;

    .filter_block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 2;
    }

    .button_block {
        flex: 1;
        text-align: right;
    }
`

export default ShipperReturnedOrder
