import React from 'react'

import ListGiftChange from './components/ListGiftChange'

export default function GiftChange() {
  return (
    <div>
      <ListGiftChange />
    </div>
  )
}
