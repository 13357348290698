import { createSlice } from '@reduxjs/toolkit'

interface IinitialState {
    // Khách hệ thông
    data: number[]
    isUpdateAll: boolean
    disSelecCustomerIds: number[]

    // Khách lẻ
    dataIndividual: number[]
    isUpdateAllIndi: boolean
    disSelecCustomerIndiIds: number[]
}

const initialState: IinitialState = {
    data: [],
    isUpdateAll: false,
    disSelecCustomerIds: [],

    dataIndividual: [],
    isUpdateAllIndi: false,
    disSelecCustomerIndiIds: [],
}

export const customerSlice = createSlice({
    name: 'customerSlice',
    initialState,
    reducers: {
        // -- Khách hệ thống

        // Chọn nhiều
        setSelectedCustomer: (state, action) => {
            const status = action?.payload?.selected
            const targetList = action?.payload?.newListKeys
            const changeRows = action?.payload?.changeRows

            // Tích chọn
            if (status) {
                state.data = [...state.data, ...targetList]
                // Nếu có item đã tồn tại trong list xoá -> Xoá
                if (state.isUpdateAll) {
                    const newListRemove = state.disSelecCustomerIds?.filter((item: any) => !state.data.includes(item))
                    state.disSelecCustomerIds = newListRemove
                }
            } else {
                state.data = targetList

                if (state.isUpdateAll) {
                    const removeList = changeRows?.map((item: any) => item?.id)
                    state.disSelecCustomerIds = [...state.disSelecCustomerIds, ...removeList]
                }
            }
        },

        // Chọn 1
        setOneSelectedCustomer: (state, action) => {
            const status = action?.payload?.selected
            const targetItem = action?.payload?.record

            // Tích chọn
            if (status) {
                state.data = [...state.data, targetItem?.id]
                if (state.isUpdateAll) {
                    const newListRemove = state.disSelecCustomerIds?.filter((item: any) => item !== targetItem?.id)
                    state.disSelecCustomerIds = newListRemove
                }
            } else {
                const newList = state.data?.filter((item: any) => item !== targetItem?.id)
                state.data = newList
                if (state.isUpdateAll) {
                    state.disSelecCustomerIds = [...state.disSelecCustomerIds, targetItem.id]
                }
            }
        },

        setIsUpdateAllCustomer: (state, action) => {
            state.isUpdateAll = action.payload
            if (!action.payload) {
                state.disSelecCustomerIds = []
            }
        },

        resetSelectedCustomer: state => {
            state.data = []
            state.isUpdateAll = false
            state.disSelecCustomerIds = []
        },

        // -- Khách lẻ

        // Chọn nhiều
        setSelectedIndiCustomer: (state, action) => {
            const status = action?.payload?.selected
            const targetList = action?.payload?.newListKeys
            const changeRows = action?.payload?.changeRows

            // Tích chọn
            if (status) {
                state.dataIndividual = [...state.dataIndividual, ...targetList]

                // Nếu có item đã tồn tại trong list xoá -> Xoá
                if (state.isUpdateAllIndi) {
                    const newListRemove = state.disSelecCustomerIndiIds?.filter(
                        (item: any) => !state.data.includes(item)
                    )
                    state.disSelecCustomerIndiIds = newListRemove
                }
            } else {
                state.dataIndividual = targetList

                if (state.isUpdateAllIndi) {
                    const removeList = changeRows?.map((item: any) => item?.id)
                    state.disSelecCustomerIndiIds = [...state.disSelecCustomerIndiIds, ...removeList]
                }
            }
        },
        // Chọn 1
        setOneSelectedIndiCustomer: (state, action) => {
            const status = action?.payload?.selected
            const targetItem = action?.payload?.record

            // Tích chọn
            if (status) {
                state.dataIndividual = [...state.dataIndividual, targetItem?.id]
                if (state.isUpdateAllIndi) {
                    const newListRemove = state.disSelecCustomerIndiIds?.filter((item: any) => item !== targetItem?.id)
                    state.disSelecCustomerIndiIds = newListRemove
                }
            } else {
                const newList = state.dataIndividual?.filter((item: any) => item !== targetItem?.id)
                state.dataIndividual = newList
                if (state.isUpdateAllIndi) {
                    state.disSelecCustomerIndiIds = [...state.disSelecCustomerIndiIds, targetItem.id]
                }
            }
        },

        setIsUpdateAllCustomerIndi: (state, action) => {
            state.isUpdateAllIndi = action.payload
            if (!action.payload) {
                state.disSelecCustomerIndiIds = []
            }
        },

        resetSelectedIndiCustomer: state => {
            state.dataIndividual = []
            state.disSelecCustomerIndiIds = []
            state.isUpdateAllIndi = false
        },
    },
})

export const {
    resetSelectedIndiCustomer,
    setSelectedCustomer,
    resetSelectedCustomer,
    setOneSelectedCustomer,
    setSelectedIndiCustomer,
    setOneSelectedIndiCustomer,
    setIsUpdateAllCustomer,
    setIsUpdateAllCustomerIndi,
} = customerSlice.actions
export default customerSlice.reducer
