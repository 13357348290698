import { Card, Row } from 'antd'
import { getProvinces } from 'features/admin/customer-clone/CustomerAPI'
import React from 'react'
import styled from 'styled-components'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'

interface IShipperGeneralInfo {
    shipperDetail: any
}

const ShipperGeneralInfo = (props: IShipperGeneralInfo) => {
    const { shipperDetail } = props
    const [provinces, setProvinces] = React.useState<any[]>([])

    const getListProvinces = async () => {
        try {
            const res: any = await getProvinces()
            if (res?.status) {
                setProvinces(res?.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        getListProvinces()
    }, [])
    return (
        <>
            <CustomCard title="Thông tin shipper" bordered={false}>
                <Row className="row">
                    <span>
                        Tên: <b>{shipperDetail?.full_name}</b>
                    </span>
                    <span>
                        SĐT: <b>{shipperDetail?.phone_number}</b>
                    </span>
                </Row>
                <Row className="row">
                    <span>
                        Giới tính:{' '}
                        <b>{!shipperDetail?.gender ? '---' : shipperDetail?.gender === 'man' ? 'Nam' : 'Nữ'}</b>
                    </span>
                    <span>
                        Ngày sinh: <b>{momentToStringDate(shipperDetail?.date_of_birth) || '---'}</b>
                    </span>
                </Row>
                <Row className="row">
                    <span>
                        Địa chỉ:{' '}
                        <b>
                            {shipperDetail?.province_id
                                ? provinces.find((item: any) => item?.id === Number(shipperDetail?.province_id))?.name
                                : '---'}
                        </b>
                    </span>
                    <span>
                        Email: <b>{shipperDetail?.email}</b>
                    </span>
                </Row>
            </CustomCard>
            <CustomCard title="Tổng quan" bordered={false}>
                <Row className="row">
                    <div className="block">
                        <p className="money" style={{ color: 'green' }}>
                            {formatPrice(shipperDetail?.num_order_delivered)}
                        </p>
                        <p>Đơn đã giao</p>
                    </div>
                    <div className="block">
                        <p className="money" style={{ color: 'red' }}>
                            {formatPrice(shipperDetail?.num_order_refunded)}
                        </p>
                        <p>Đơn hoàn trả</p>
                    </div>
                </Row>
                <Row className="row">
                    <div className="block">
                        <p className="money" style={{ color: '#5486d6' }}>
                            {formatPrice(shipperDetail?.total_orders)}
                        </p>
                        <p>Tổng đơn hàng</p>
                    </div>
                    <div className="block">
                        <p className="money" style={{ color: '#cd7eb5' }}>
                            {formatPrice(shipperDetail?.total_cod || 0)}
                        </p>
                        <p>Tổng tiền thu COD</p>
                    </div>
                </Row>
            </CustomCard>
        </>
    )
}

const CustomCard = styled(Card)`
    width: 49%;
    border-radius: 10px;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 20px;
        flex-wrap: wrap;

        .block {
            text-align: center;
            flex: 1;

            .money {
                font-size: 24px;
                font-weight: 600;
                margin: 0;
            }
        }
    }
`

export default ShipperGeneralInfo
