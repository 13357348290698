import { ApiClient } from 'services/ApiService'
import { IFlowerDeliveryPayload, ResponseData } from './FlowerInterfaces'

export const getFlowerDelivery = (
  payload: IFlowerDeliveryPayload
): Promise<ResponseData<any>> => {
  return ApiClient.get('/admin/flower_delivery', payload)
}

export const getFlowerDeliveryDetail = (
  payload: string
): Promise<ResponseData<any>> => {
  return ApiClient.get(`admin/flower_delivery/${payload}`)
}

export const changeStatusFlowerDelivery = (
  payload: any
): Promise<ResponseData<any>> => {
  return ApiClient.put(`admin/flower_delivery/${payload.id}`, payload.data)
}
