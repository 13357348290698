import { Col, DatePicker, Divider, Empty, Image, Pagination, Row, Table } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { ADMIN_ROUTER_PATH } from 'common/config'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { TAB_SHOP } from 'utils/constants'
import { msToTime } from 'utils/funcHelper'
import { formatPrice } from 'utils/ruleForm'
import LiveStreamItem from '../components/LiveStreamItem'
import { getDetail } from '../ShopApi'
const { RangePicker } = DatePicker

type Props = {
    id_shop: number
}

export default function LiveStreamShop({ id_shop }: Props) {
    const history = useHistory()
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [listLive, setListLive] = useState<Array<any>>([])
    const [params, setParams] = useState<any>({
        id: id_shop,
        data: {
            type: TAB_SHOP.LIVESTREAM,
            search: '',
            status: '',
            category_id: '',
            stock_id: '',
            from_date: '',
            to_date: '',
            order_status: '',
            page: 1,
        },
    })
    useEffect(() => {
        sessionStorage.setItem('key', '4')
    })
    const [paging, setPaging] = useState({
        total: 0,
        current: 1,
        pageSize: 0,
    })

    const getListOrderShop = async () => {
        setIsLoading(true)
        try {
            console.log(params)
            const res = await getDetail(params)
            setListLive(res.data)
            setPaging({
                total: res.paging.totalItemCount,
                current: res.paging.page,
                pageSize: res.paging.limit,
            })
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
            setIsSearchLoading(false)
        }
    }

    useEffect(() => {
        getListOrderShop()
    }, [params])

    const columns = [
        {
            width: 70,
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text: any, record: any, index: any) => (
                <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
                    {(paging.current - 1) * paging.pageSize + index + 1}
                </td>
            ),
            ellipsis: true,
        },
        {
            title: 'Ảnh',
            dataIndex: 'cover_image_url',
            key: 'cover_image_url',
            width: 70,
            render: (value: any) => {
                return (
                    <td className="icon-eye-antd-custom">
                        <Image
                            style={{ objectFit: 'cover', width: '35px', height: '35px' }}
                            src={value}
                            onClick={e => {
                                e.stopPropagation()
                            }}
                        />
                    </td>
                )
            },
        },
        {
            title: 'Tên gian hàng',
            dataIndex: '',
            render: (value: any) => <td>{value.Shop.name}</td>,
        },
        {
            title: 'Mô tả',
            // width: 500,
            dataIndex: 'title',
            render: (value: string) => <td>{value}</td>,
        },
        {
            title: 'Lượt xem',
            width: 100,
            dataIndex: 'count_viewed',
        },
        {
            title: 'Lượt thích',
            width: 110,
            dataIndex: 'count_reaction',
        },
        {
            title: 'Bình luận',
            width: 100,
            dataIndex: 'count_comment',
        },
        {
            title: 'Thời lượng',
            dataIndex: '',
            width: 130,
            render: (value: any) => <td>{msToTime(moment(value.finish_at).unix() - moment(value.start_at).unix())}</td>,
        },
        {
            title: 'Ngày livestream',
            dataIndex: 'create_at',
            width: 150,
            render: (value: string) => <td>{moment(value).format('DD-MM-YYYY')}</td>,
        },
        // {
        //   title: 'Ngày kết thúc',
        //   dataIndex: 'finish_at',
        //   render: (value: string) => <td>{moment(value).format('DD-MM-YYYY')}</td>,
        // },
    ]

    return (
        <>
            <div>
                <Row justify="end" gutter={16} style={{ paddingRight: '15px' }}>
                    <Col span={12}>
                        <TypingAutoSearch
                            onSearchSubmit={(key: string) => {
                                setIsSearchLoading(true)
                                const data = { ...params.data, search: key.trim(), page: 1 }
                                setParams({ ...params, data })
                            }}
                            isSearchLoading={isSearchLoading}
                            placeholder="Mô tả live stream ..."
                        />
                    </Col>
                    <Col span={6}>
                        <RangePicker
                            placeholder={['Từ ngày', 'Đến ngày']}
                            onChange={(dates: any, dateStrings: any[]) => {
                                const data = {
                                    ...params.data,
                                    from_date: dateStrings[0],
                                    to_date: dateStrings[1],
                                    page: 1,
                                }
                                setParams({ ...params, data })
                            }}
                        />
                    </Col>
                </Row>
                <Divider />
            </div>
            <div
                style={{
                    padding: '5px 10px',
                }}
            >
                <p>
                    Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                </p>
                <Table
                    className="table-expanded-custom"
                    scroll={{
                        x: 800,
                        scrollToFirstRowOnChange: true,
                        y: 'calc(100vh - 460px)',
                    }}
                    bordered
                    dataSource={listLive}
                    loading={isLoading}
                    columns={columns}
                    onRow={(record, rowIndex) => ({
                        onDoubleClick: () => {
                            history.push({
                                pathname: `${ADMIN_ROUTER_PATH.LIVE_STREAM_DETAIL}/${record.id}`,
                                state: record,
                            })
                        },
                    })}
                    pagination={{
                        ...paging,
                        showSizeChanger: false,
                        onChange: async (page, pageSize) => {
                            const data = { ...params.data, page: page }
                            setParams({ ...params, data })
                        },
                    }}
                />
            </div>
        </>
    )
}
