import { Table, Tag, Typography } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import reactotron from 'ReactotronConfig'
import styled from 'styled-components'
import { FLOWER_DELIVERY_STATUS, TEXT_FLOWER_ACTIVE } from 'utils/constants'
import { notificationError } from 'utils/notification'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { getFlowerDelivery } from '../../FlowerDeliveryAPI'
import { IFlowerDeliveryPayload, IFormatedPaging } from '../../FlowerInterfaces'
import Header from './Header'
const { Text } = Typography
interface IWapperHeaderStyle {
  padding?: boolean
}

export const WapperContent = styled.div<IWapperHeaderStyle>`
  background-color: white;
  border-radius: 10px;
  margin: 0 10px;
  padding: ${props => (props.padding ? '15px 10px' : null)};
`

function FlowerDeliveryRequest(location : any) {
  const heightWeb = useSelector(
    (state: any) => state.configReducer.dimension.height
  )
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const [params, setparams] = useState<IFlowerDeliveryPayload>({
    page: 1,
    limit: 12,
    search: '',
    status: undefined,
    create_from: undefined,
    create_to: undefined,
  })
  const [paging, setPaging] = useState<IFormatedPaging>({
    total: 1,
    current: location.state?.paging?.current
      ? location.state?.paging?.current
      : 1,
    pageSize: 1,
  })

  const getDataFlowerDelivery = async () => {
    setIsLoading(true)
    try {
      const res = await getFlowerDelivery(params)
      setData(tableData(res))
      if (res.paging) {
        setPaging({
          total: res.paging.totalItemCount,
          current: res.paging.page,
          pageSize: res.paging.limit,
        })
      }
    } catch (err) {
      notificationError('Có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const tableData = (res: any) =>
    res.data.map((item: any, index: number) => {
      return {
        id: item.id,
        index: (res.paging.page - 1) * params.limit + index + 1,
        name: item.receiver_name,
        region: item.province.name,
        created_at: item.created_at,
        status: item.status,
      }
    })

  useEffect(() => {
    getDataFlowerDelivery()
  }, [params])

  const columns = [
    {
      with: 70,
      title: 'STT',
      dataIndex: 'index',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'name',
    },
    // {
    //   title: 'Tên điện hoa',
    //   dataIndex: 'name_flower',
    // },
    {
      title: 'Khu vực giao hàng',
      dataIndex: 'region',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: number) => renderOrderStatus(status),
    },
    {
      title: 'Ngày yêu cầu',
      dataIndex: 'created_at',
      render: (created_at: string) => {
        return <Text>{convertTimeStampToString(created_at)}</Text>
      },
    },
  ]

  const renderOrderStatus = (value: number) => {
    switch (value) {
      case FLOWER_DELIVERY_STATUS.REQUESTED:
        return <Tag color="warning">{TEXT_FLOWER_ACTIVE.FLOWERWAIT}</Tag>
      case FLOWER_DELIVERY_STATUS.REJECTED:
        return <Tag color="red">{TEXT_FLOWER_ACTIVE.FLOWERCANCEL}</Tag>
      default:
        return <Tag color="blue">{TEXT_FLOWER_ACTIVE.FLOWERCONFIRML}</Tag>
    }
  }

  return (
    <>
      <Header
        onSubmitSearch={(value: string) =>
          setparams({
            ...params,
            search: value,
          })
        }
        onSubmitStatus={(status: number) =>
          setparams({
            ...params,
            status: status,
            page: location.state?.paging?.current
              ? location.state?.paging?.current
              : 1,
          })
        }
        onSubmitDateTime={(dateTime: Array<string>) =>
          setparams({
            ...params,
            create_from: dateTime[0],
            create_to: dateTime[1],
          })
        }
      />
      <WapperContent>
        <Table
          bordered
          loading={isLoading}
          columns={columns}
          dataSource={data}
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            y: `calc(${heightWeb}px - 280px)`,
          }}
          onRow={(record, rowIndex) => ({
            onClick: () => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.FLOWER_DELIVERY_DETAIL}/${record.id}`,
                state: {
                  id: record.id,
                  paging: paging,
                  path: ADMIN_ROUTER_PATH.FLOWER_DELIVERY_REQUEST,
                },
              })
            },
          })}
          pagination={{
            ...paging,
            onChange: async (page, pageSize) => {
              setparams({ ...params, page: page })
            },
          }}
        />
      </WapperContent>
    </>
  )
}

export default FlowerDeliveryRequest
