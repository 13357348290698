import { CheckCircleOutlined, CloseCircleOutlined, DeleteFilled, EditOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, Card, Popconfirm, Switch, Tabs } from 'antd'
import React from 'react'
import styled from 'styled-components'
import CODHistory from './CODHistory'
import ShipperDeliveredOrder from './ShipperDeliveredOrder'
import ShipperGeneralInfo from './ShipperGeneralInfo'
import ShipperReturnedOrder from './ShipperReturnedOrder'
import { IS_ACTIVE } from 'utils/constants'

interface IShipperInfo {
    shipperDetail: any
    currentTabOpen: string
    pagingDetail: any
    searchDetail: string
    orderStatus: string | undefined
    deliveryStatus: number | undefined
    refundStatus: number | undefined
    fromDateDetail: string | undefined
    toDateDetail: string | undefined
    record: any
    exportExcelByTab: (tabKey: string) => Promise<void>
    setIsCPModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setCurrentRecord: React.Dispatch<any>
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    onChangeShipperStatus: () => Promise<void>
    onDeleteShipper: () => Promise<void>
    setCurrentTabOpen: React.Dispatch<React.SetStateAction<string>>
    setSearchDetail: React.Dispatch<React.SetStateAction<string>>
    setOrderStatus: React.Dispatch<React.SetStateAction<string | undefined>>
    setDeliveryStatus: React.Dispatch<React.SetStateAction<number | undefined>>
    setRefundStatus: React.Dispatch<React.SetStateAction<number | undefined>>
    setFromDateDetail: React.Dispatch<React.SetStateAction<string | undefined>>
    setToDateDetail: React.Dispatch<React.SetStateAction<string | undefined>>
    setPagingDetail: React.Dispatch<any>
}

const ShipperInfo = (props: IShipperInfo) => {
    const {
        shipperDetail,
        record,
        currentTabOpen,
        pagingDetail,
        searchDetail,
        orderStatus,
        deliveryStatus,
        refundStatus,
        fromDateDetail,
        toDateDetail,
        setIsCPModalOpen,
        setCurrentRecord,
        setIsModalOpen,
        onChangeShipperStatus,
        onDeleteShipper,
        setCurrentTabOpen,
        setSearchDetail,
        setOrderStatus,
        setDeliveryStatus,
        setRefundStatus,
        setFromDateDetail,
        setToDateDetail,
        setPagingDetail,
        exportExcelByTab,
    } = props
    return (
        <Card
            actions={[
                <Button
                    onClick={() => {
                        setIsModalOpen(true)
                        setCurrentRecord(record)
                    }}
                    type="text"
                    size="large"
                    style={{ color: 'green' }}
                    icon={<EditOutlined />}
                    children={'Chỉnh sửa'}
                />,
                <SwitchBlock>
                    <Switch checked={record.status == IS_ACTIVE.ACTIVE} onChange={onChangeShipperStatus} />
                    {record.status ? (
                        <p style={{ color: 'green', margin: 0, marginLeft: 10 }}>Đang hoạt động</p>
                    ) : (
                        <p style={{ color: 'red', margin: 0, marginLeft: 10 }}>Ngừng hoạt động</p>
                    )}
                </SwitchBlock>,
                <Button
                    type="text"
                    size="large"
                    style={{ color: '#e0a561' }}
                    icon={<RedoOutlined />}
                    children={'Đổi mật khẩu'}
                    onClick={() => setIsCPModalOpen(true)}
                />,
                <Popconfirm
                    title={'Bạn có chắc chắn muốn XOÁ shipper này?'}
                    onConfirm={onDeleteShipper}
                    okText={'Xoá'}
                    cancelText={'Đóng'}
                >
                    <Button
                        type="text"
                        size="large"
                        style={{ color: '#cc0000' }}
                        icon={<DeleteFilled />}
                        children={'Xoá'}
                    />
                </Popconfirm>,
            ]}
            style={{ width: '100%', backgroundColor: '#f6f9ff' }}
        >
            <Tabs
                style={{ backgroundColor: '#f6f9ff' }}
                activeKey={currentTabOpen}
                onChange={(value: string) => {
                    setCurrentTabOpen(value)
                }}
            >
                <CustomTabPane tab={<span style={{ margin: 10 }}>Thông tin chung</span>} key="1">
                    <ShipperGeneralInfo shipperDetail={shipperDetail} />
                </CustomTabPane>
                <CustomTabPane tab={<span style={{ margin: 10 }}>Báo cáo đơn giao hàng</span>} key="2">
                    <ShipperDeliveredOrder
                        shipperDetail={shipperDetail}
                        pagingDetail={pagingDetail}
                        searchDetail={searchDetail}
                        orderStatus={orderStatus}
                        deliveryStatus={deliveryStatus}
                        fromDateDetail={fromDateDetail}
                        toDateDetail={toDateDetail}
                        setSearchDetail={setSearchDetail}
                        setOrderStatus={setOrderStatus}
                        setDeliveryStatus={setDeliveryStatus}
                        setFromDateDetail={setFromDateDetail}
                        setToDateDetail={setToDateDetail}
                        setPagingDetail={setPagingDetail}
                        exportExcelByTab={exportExcelByTab}
                    />
                </CustomTabPane>
                <CustomTabPane tab={<span style={{ margin: 10 }}>Báo cáo đơn hoàn trả</span>} key="3">
                    <ShipperReturnedOrder
                        shipperDetail={shipperDetail}
                        pagingDetail={pagingDetail}
                        searchDetail={searchDetail}
                        refundStatus={refundStatus}
                        deliveryStatus={deliveryStatus}
                        fromDateDetail={fromDateDetail}
                        toDateDetail={toDateDetail}
                        setSearchDetail={setSearchDetail}
                        setRefundStatus={setRefundStatus}
                        setFromDateDetail={setFromDateDetail}
                        setToDateDetail={setToDateDetail}
                        setPagingDetail={setPagingDetail}
                        exportExcelByTab={exportExcelByTab}
                    />
                </CustomTabPane>
                <CustomTabPane tab={<span style={{ margin: 10 }}>Lịch sử COD</span>} key="4">
                    <CODHistory
                        shipperDetail={shipperDetail}
                        pagingDetail={pagingDetail}
                        searchDetail={searchDetail}
                        fromDateDetail={fromDateDetail}
                        toDateDetail={toDateDetail}
                        setSearchDetail={setSearchDetail}
                        setFromDateDetail={setFromDateDetail}
                        setToDateDetail={setToDateDetail}
                        setPagingDetail={setPagingDetail}
                        exportExcelByTab={exportExcelByTab}
                    />
                </CustomTabPane>
            </Tabs>
        </Card>
    )
}

const CustomTabPane = styled(Tabs.TabPane)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const SwitchBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export default ShipperInfo
