import {
    Affix,
    Button,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    message,
    Modal,
    Row,
    Space,
    Spin,
    Collapse,
    Typography,
} from 'antd'
import { RetweetOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { logoutAction } from 'features/auth/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ApiClient } from 'services/ApiService'
import history from 'utils/history'
import HeaderAdmin from './header/HeaderAdmin'
import { IAuthState } from './HeaderInterfaces'
import AdminMenu from './menu/AdminMenu'
import SubAdminMenu from './menu/SubAdminMenu'
import moment from 'moment'
import { convertTimeStampToString, get_ss_hh_dd_mm } from 'utils/TimerHelper'
import styled from 'styled-components'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { resetOrders } from 'features/admin/order-clone/OrderSlice'
import { resetRefOrders } from 'features/admin/refund-order/services/RefundOrderSlice'
import { resetSelectedCustomer, resetSelectedIndiCustomer } from 'features/admin/customer-clone/CustomerSlice'
import { resetProduct } from 'features/admin/product_clone/Products/ProductSlice'
const { Title } = Typography
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
}
interface dataChangePassword {
    new_password: string
    old_password: string
}
const { Panel } = Collapse
export default function Header(props: any) {
    const dispatch = useDispatch()
    const authState: IAuthState = useSelector((state: any) => state.authReducer)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [form] = Form.useForm()
    const [getData, setGetData] = useState<Array<any>>([])

    function handleClick(e: any) {
        if (e.key === 'logout') {
            dispatch(logoutAction())
        } else if (e.key === 'changePassword') {
            setIsModalVisible(true)
        } else {
            if (e.key !== ADMIN_ROUTER_PATH.ORDER) {
                dispatch(resetOrders())
            }
            if (e.key !== ADMIN_ROUTER_PATH.REFUND_ORDER) {
                dispatch(resetRefOrders())
            }
            if (e.key !== ADMIN_ROUTER_PATH.CUSTOMER) {
                dispatch(resetSelectedCustomer())
            }
            if (e.key !== ADMIN_ROUTER_PATH.INDIVIDUAL_CUSTOMER) {
                dispatch(resetSelectedIndiCustomer())
            }
            if (e.key !== ADMIN_ROUTER_PATH.PRODUCT) {
                dispatch(resetProduct())
            }
            history.push(e.key)
        }
    }

    function handleGetCurrentRouter() {
        return window.location.pathname
    }
    const handleRefresh = async () => {
        try {
            setisLoading(true)
            const rest = await ApiClient.get('/admin/configuration/stats')
            setGetData(rest.data)
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }
    const onFinish = async (value: dataChangePassword) => {
        const payload = {
            new_password: value.new_password,
            old_password: value.old_password,
        }
        if (payload.new_password === payload.old_password) {
            message.warn('Mật khẩu mới không được trùng với mật khẩu cũ!')
        } else {
            try {
                setisLoading(true)
                const response = await ApiClient.put('/admin/session/change_password', payload)
                form.resetFields()
                setIsModalVisible(false)
                message.success('Thay đổi mật khẩu thành công!')
            } catch (error) {
                console.log(error)
            } finally {
                setisLoading(false)
            }
        }
    }

    return (
        <>
            <Spin spinning={authState.dialogLoading}>
                <div>
                    <Affix>
                        <Row
                            style={{
                                backgroundColor: 'white',
                                paddingTop: 5,
                                paddingBottom: 5,
                                paddingLeft: 10,
                                paddingRight: 10,
                                width: '100vw',
                            }}
                        >
                            <HeaderAdmin handleClick={handleClick} />
                        </Row>
                    </Affix>

                    <Divider
                        style={{
                            margin: 0,
                        }}
                    />
                    {/* {authState?.userInfo?.admin?.is_root ? (
                        <AdminMenu handleClick={handleClick} handleGetCurrentRouter={handleGetCurrentRouter} />
                    ) : (
                        <SubAdminMenu handleClick={handleClick} handleGetCurrentRouter={handleGetCurrentRouter} />
                    )} */}
                    <AdminMenu handleClick={handleClick} handleGetCurrentRouter={handleGetCurrentRouter} />
                    <hr style={{ borderWidth: '1px' }} />
                    <div>
                        <Collapse accordion bordered={false} style={{ backgroundColor: 'white' }}>
                            <Panel
                                header={
                                    <p style={{ fontWeight: '400', fontSize: '14px' }}>
                                        <Space>Chi tiết cập nhật :</Space>
                                        {<RetweetOutlined onClick={() => handleRefresh()} />}
                                    </p>
                                }
                                key="1"
                            >
                                <Col>
                                    <Row style={{ fontWeight: '600' }}>Hà Nội :</Row>
                                    <Row>Action:{getData[0]?.webhook_stats?.action || '---'}</Row>
                                    <Row>
                                        Type:
                                        {getData[0]?.webhook_stats?.type || '---'}
                                    </Row>
                                    <Row>
                                        Time:
                                        {(getData[0]?.webhook_stats?.updated_at &&
                                            get_ss_hh_dd_mm(getData[0]?.webhook_stats?.updated_at)) ||
                                            '---'}
                                    </Row>
                                    <Row style={{ fontWeight: '600', marginTop: '8px' }}>TP Hồ Chí Minh :</Row>
                                    <Row>Action:{getData[1]?.webhook_stats?.action || '---'}</Row>
                                    <Row>
                                        Type:
                                        {getData[1]?.webhook_stats?.type || '---'}
                                    </Row>
                                    <Row>
                                        Time:
                                        {(getData[1]?.webhook_stats?.updated_at &&
                                            get_ss_hh_dd_mm(getData[1]?.webhook_stats?.updated_at)) ||
                                            '---'}
                                    </Row>
                                </Col>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </Spin>

            {/* Thay đổi mật khẩu */}
            <Modal
                title="Đổi mật khẩu"
                visible={isModalVisible}
                onCancel={() => {
                    form.resetFields()
                    setIsModalVisible(false)
                }}
                footer={null}
            >
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    labelAlign="left"
                    scrollToFirstError
                    onFinish={(values: dataChangePassword) => onFinish(values)}
                >
                    <Form.Item
                        name="old_password"
                        label="Mật khẩu cũ"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập mật khẩu!',
                            },
                            {
                                min: 6,
                                max: 20,
                                message: 'Vui lòng nhập từ 6 đến 20 ký tự!',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Nhập mật khẩu" />
                    </Form.Item>

                    <Form.Item
                        name="new_password"
                        label="Mật khẩu mới"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập mật khẩu!',
                            },
                            {
                                min: 6,
                                max: 20,
                                message: 'Vui lòng nhập từ 6 đến 20 ký tự!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="Nhập mật khẩu" />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Xác nhận mật khẩu"
                        dependencies={['new_password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Nhập lại mật khẩu!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('new_password') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('Mật khẩu không khớp!'))
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Nhập lại mật khẩu" />
                    </Form.Item>

                    <Form.Item>
                        <Row style={{ width: '100%' }}>
                            <Col span={6} offset={10}>
                                <Button
                                    danger
                                    onClick={() => {
                                        form.resetFields()
                                        setIsModalVisible(false)
                                    }}
                                >
                                    Thoát
                                </Button>
                            </Col>
                            <Col span={6} offset={2}>
                                <Button loading={isLoading} type="primary" htmlType="submit">
                                    Lưu
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
    //
}
