import React from 'react'
import { Comment, Avatar, Button } from 'antd'
import { splitTextEndLine } from 'utils/funcHelper'
import { CommentItem } from '../InterfaceComPost'
import moment from 'moment'
import { requestReactionComment } from '../CommunityPostApi'

import { UserOutlined, HeartFilled, MessageOutlined } from '@ant-design/icons'
import { ROLE } from 'utils/constants'

interface PropsCustomCommentItem {
  children?: any
  commentItem: CommentItem
  post_id: number
  handleCallback: () => void
  onCommentFocusInput: (commentItem: CommentItem) => void
}

const CustomCommentItem = ({
  children,
  commentItem,
  post_id,
  onCommentFocusInput,
  handleCallback,
}: PropsCustomCommentItem) => {
  const reactionRequest = async () => {
    if (commentItem?.id) {
      try {
        const res = await requestReactionComment(post_id, commentItem.id)
      } catch (error) {
        console.log(error)
      }
    }
  }
  return (
    <Comment
      style={{ padding: 0, margin: 0 }}
      actions={[
        <span>
          {moment(commentItem?.create_at).format('h:mm DD/MM/YYYY ')}
        </span>,
        <Button
          type="text"
          danger={commentItem?.is_reaction ? true : false}
          icon={<HeartFilled style={{ fontSize: 12 }} />}
          onClick={() => reactionRequest()}
        >
          <span style={{ fontSize: 12 }}>{commentItem?.count_like}</span>
        </Button>,
        <Button
          type="text"
          onClick={() => {
            onCommentFocusInput(commentItem)
            handleCallback()
          }}
          icon={<MessageOutlined style={{ fontSize: 12 }} />}
        >
          <span style={{ fontSize: 12 }}>{commentItem?.count_comment}</span>
        </Button>,
      ]}
      author={
        <a>
          <strong style={{ fontSize: 15 }}>
            {commentItem?.Account?.role === ROLE.ADMIN
              ? commentItem?.Account?.Admin?.full_name
              : commentItem?.Account?.User?.full_name}
          </strong>
        </a>
      }
      avatar={
        <Avatar
          size={42}
          icon={<UserOutlined />}
          src={
            commentItem?.Account?.Admin
              ? commentItem?.Account?.Admin?.avatar
              : commentItem?.Account?.User?.avatar
          }
          alt={
            commentItem?.Account?.Admin
              ? commentItem?.Account?.Admin?.full_name
              : commentItem?.Account?.User?.full_name
          }
        />
      }
      content={
        <>
          {/* {commentItem?.Account?.User?.full_name && (
            <a style={{ color: '#484848' }}>
              <strong>{commentItem?.Account?.User?.full_name} </strong>
            </a>
          )} */}
          {splitTextEndLine(commentItem?.content).map(
            (text: string, index: number) =>
              index === 0 ? (
                <p style={{ display: 'inline-block' }}>{text}</p>
              ) : (
                <p>{text}</p>
              )
          )}
        </>
      }
    >
      {children}
    </Comment>
  )
}

export default CustomCommentItem
