import { Button, Col, Form, Input, PageHeader, Popconfirm, Row, Select, Spin, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import ButtonSave from 'common/components/Button/ButtonSave'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'container/Container'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toNonAccentVietnamese } from 'utils/Configs'
import UploadComponent from 'utils/UploadImage'
import { addNewPolicy, deletePolicy, getPolicyDetail, updatePolicy } from '../service'
import { DeleteOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

const AddEditPolicy = () => {
    const history = useHistory()
    const location: any = useLocation()
    const [form] = useForm()

    const policyId = location?.state?.policyId
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isAccountant = userInfor?.admin?.role === ADMIN_ROLE.ACCOUNTANT

    const [loading, setLoading] = React.useState<boolean>(false)
    const [listKiotviet, setlistKiotviet] = React.useState<any[]>([])
    const [avatar, setAvatar] = React.useState<any>()
    const [isSelectAllArea, setIsSelectAllArea] = React.useState<boolean>(false)
    const [isSelectAllDepartment, setIsSelectAllDepartment] = React.useState<boolean>(false)

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
                setlistKiotviet(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const onFinish = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                is_apply_to_all_department: values?.department?.length > 0 && !values?.department?.includes(-1) ? 0 : 1,
                is_apply_to_all_kiotviet: values?.area?.length > 0 && !values?.area?.includes(-1) ? 0 : 1,
                image_url: avatar || undefined,
                content: values.content,
                title: values.title,
                role_ids: values?.department?.length > 0 && !values?.department?.includes(-1) ? values?.department : [],
                kiotviet_ids: values?.area?.length > 0 && !values?.area?.includes(-1) ? values?.area : [],
            }
            let res: any
            if (!policyId) {
                res = await addNewPolicy(payload)
            } else {
                res = await updatePolicy(policyId, payload)
            }
            if (res?.status) {
                setTimeout(() => {
                    message.success(policyId ? 'Cập nhật chính sách thành công!' : 'Thêm mới chính sách thành công!')
                    history.goBack()
                }, 1000)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getDetail = async () => {
        try {
            setLoading(true)
            const res: any = await getPolicyDetail(policyId)
            if (res?.status) {
                form.setFieldsValue({
                    title: res?.data?.title,
                    content: res?.data?.content,
                    department:
                        res?.data?.department?.length === 0
                            ? [-1]
                            : res?.data?.department?.map((item: any) => item?.user_role),
                    area:
                        res?.data?.kiotviet?.length === 0
                            ? [-1]
                            : res?.data?.kiotviet?.map((item: any) => item?.kiotviet_id),
                })
                setIsSelectAllArea(res?.data?.kiotviet?.length === 0)
                setIsSelectAllDepartment(res?.data?.department?.length === 0)
                setAvatar(res?.data?.image_url)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const deletePolicyItem = async () => {
        try {
            setLoading(true)
            const res: any = await deletePolicy(policyId)
            if (res?.status) {
                message.success('Xoá chính sách thành công!')
                history.goBack()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getDataKiotviet()
    }, [])

    React.useEffect(() => {
        if (policyId) {
            getDetail()
        }
    }, [policyId])

    return (
        <Spin spinning={loading}>
            <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onFinish} autoComplete="off">
                <Container
                    header={
                        <PageHeader
                            style={{ borderRadius: 8 }}
                            title={policyId ? 'Chỉnh sửa quy định' : 'Thêm mới quy định'}
                            onBack={() => history.goBack()}
                            extra={
                                isAccountant
                                    ? []
                                    : policyId
                                    ? [
                                          <Form.Item style={{ marginBottom: 0 }}>
                                              <ButtonSave htmlType={'submit'} text="Lưu" />
                                          </Form.Item>,
                                          <Popconfirm
                                              title={'Bạn có chắc chắn muốn XOÁ chính sách này?'}
                                              onConfirm={deletePolicyItem}
                                              okText={'Xoá'}
                                              cancelText={'Đóng'}
                                              placement="bottomLeft"
                                          >
                                              <Button
                                                  icon={<DeleteOutlined />}
                                                  style={{ background: 'red', borderColor: 'red', color: 'white' }}
                                              >
                                                  Xoá
                                              </Button>
                                          </Popconfirm>,
                                      ]
                                    : [
                                          <Form.Item style={{ marginBottom: 0 }}>
                                              <ButtonSave htmlType={'submit'} text="Lưu" />
                                          </Form.Item>,
                                      ]
                            }
                        ></PageHeader>
                    }
                    contentComponent={[
                        <>
                            <Row gutter={[16, 16]}>
                                <Col span={9}>
                                    <Form.Item
                                        label="Khu vực"
                                        name="area"
                                        rules={[{ required: true, message: 'Vui lòng chọn khu vực!' }]}
                                    >
                                        <Select
                                            placeholder="Chọn khu vực"
                                            showArrow
                                            showSearch
                                            allowClear
                                            mode="multiple"
                                            filterOption={(input, option: any) => {
                                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                                return nonAccent.includes(nonAccentInput)
                                            }}
                                            onSelect={(value: any) => {
                                                if (value === -1) {
                                                    setIsSelectAllArea(true)
                                                    form.setFieldValue('area', [-1])
                                                }
                                            }}
                                            onDeselect={(value: any) => {
                                                if (value === -1) {
                                                    setIsSelectAllArea(false)
                                                }
                                            }}
                                        >
                                            <Select.Option value={-1}>Tất cả</Select.Option>
                                            {listKiotviet?.map((item: any) => (
                                                <Select.Option
                                                    disabled={isSelectAllArea}
                                                    key={item?.id}
                                                    value={item?.value}
                                                >
                                                    {item?.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={14}>
                                    <Form.Item
                                        label="Bộ phận áp dụng"
                                        name="department"
                                        rules={[{ required: true, message: 'Vui lòng chọn bộ phận áp dụng!' }]}
                                    >
                                        <Select
                                            placeholder="Chọn bộ phận áp dụng"
                                            showArrow
                                            showSearch
                                            allowClear
                                            mode="multiple"
                                            filterOption={(input, option: any) => {
                                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                                return nonAccent.includes(nonAccentInput)
                                            }}
                                            onSelect={(value: any) => {
                                                if (value === -1) {
                                                    setIsSelectAllDepartment(true)
                                                    form.setFieldValue('department', [-1])
                                                }
                                            }}
                                            onDeselect={(value: any) => {
                                                if (value === -1) {
                                                    setIsSelectAllDepartment(false)
                                                }
                                            }}
                                        >
                                            <Select.Option value={-1}>Tất cả</Select.Option>
                                            <Select.Option disabled={isSelectAllDepartment} value={2}>
                                                Shipper
                                            </Select.Option>
                                            <Select.Option disabled={isSelectAllDepartment} value={3}>
                                                Nhân viên kho
                                            </Select.Option>
                                            <Select.Option disabled={isSelectAllDepartment} value={4}>
                                                Nhân viên kinh doanh
                                            </Select.Option>
                                            <Select.Option disabled={isSelectAllDepartment} value={6}>
                                                Kế toán
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={9}>
                                    <Form.Item
                                        label="Tiêu đề"
                                        name="title"
                                        rules={[{ required: true, message: 'Vui lòng nhập tiêu đề!' }]}
                                    >
                                        <Input placeholder="Nhập tiêu đề" allowClear />
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={14}>
                                    <Form.Item label="Ảnh" name="avatar">
                                        <UploadComponent
                                            uploadType="single"
                                            maxLength={1}
                                            listType="picture-card"
                                            isUploadServerWhenUploading
                                            onSuccessUpload={(res: any) => {
                                                setAvatar(res?.data?.url)
                                            }}
                                            fileList={
                                                avatar
                                                    ? [
                                                          {
                                                              id: '1',
                                                              url: avatar,
                                                          },
                                                      ]
                                                    : []
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Nội dung"
                                        name="content"
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}
                                        labelCol={{ span: 2 }}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Input.TextArea rows={10} placeholder="Nhập nội dung" allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>,
                    ]}
                />
            </Form>
        </Spin>
    )
}

export default AddEditPolicy
