import { ApiClient } from 'services/ApiService'
// list shop
export const getShopList = (payload: any) => {
  const param: any = {
    page: payload.page,
  }
  if (payload.search) {
    param.search = payload.search
  }
  if (payload.status) {
    param.status = parseInt(payload.status)
  }
  return ApiClient.get('/admin/shop', param)
}
export const createShop = (payload: any) =>
  ApiClient.post('/admin/shop', payload)
export const deleteShop = (payload: any) =>
  ApiClient.delete('/admin/shop', payload)

//shop detail
export const getShopDetail = (payload: number) =>
  ApiClient.get(`/admin/shop/${payload}/detail`)
export const getHistoryPakageList = (payload: any) =>
  ApiClient.get(`/admin/shop/${payload.shop_id}/history-pakage`, payload)
//change status
export const changeStatusShop = (payload: any) =>
  ApiClient.put(`/admin/shop/${payload}/status`)
export const changeStatusLiveStream = (payload: any) =>
  ApiClient.put(`/admin/shop/${payload}/livestream-status`)

  export const ChangeFollowStatus = (payload: any) =>
  ApiClient.put(`/admin/shop/${payload.id}/status-follow`,{status:payload.status})

//upload Image
export const requestUploadImage = (payload: any) =>
  ApiClient.post(`/files/upload/single/${payload.type}`, payload.data)

//get pakage category
export const getPakageCategory = (payload: any) =>
  ApiClient.get('/pakage-category', payload)

//get pakage
export const getPakage = (payload: any) => ApiClient.get('/pakage', payload)

//add pakage shop
export const addPakage = (payload: any) =>
  ApiClient.post('/admin/shop/add-pakage', payload)

//get product, order, live stream of shop
export const getDetail = (payload: any) => {
  const param: any = {}
  param.type = payload.data.type
  param.category_id = payload.data.category_id || 0
  if (param.category_id) {
    param.children_category_id =
      parseInt(payload.data.children_category_id) || 0
  } else {
    param.children_category_id = 0
  }
  if (payload.data.search) {
    param.search = payload.data.search
  }
  param.status = payload.data.status

  // console.log('payload: ', payload)
  // console.log('param: ', param)
  return ApiClient.get(`admin/shop/${payload.id}/statistic-shop`, param)
}

//Category
export const getCategory = (payload: any) => ApiClient.get('/category', payload)
