import { Card, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'

interface IStaffGeneralInfo {
    staffDetail: any
    provinces: any[]
}

const StaffGeneralInfo = (props: IStaffGeneralInfo) => {
    const { staffDetail, provinces } = props
    return (
        <>
            <CustomCard title="Thông tin nhân viên kinh doanh" bordered={false}>
                <Row className="row">
                    <span>
                        Tên: <b>{staffDetail?.full_name}</b>
                    </span>
                    <span>
                        SĐT: <b>{staffDetail?.phone_number}</b>
                    </span>
                </Row>
                <Row className="row">
                    <span>
                        Giới tính: <b>{!staffDetail?.gender ? '---' : staffDetail?.gender === 'man' ? 'Nam' : 'Nữ'}</b>
                    </span>
                    <span>
                        Ngày sinh: <b>{momentToStringDate(staffDetail?.date_of_birth) || '---'}</b>
                    </span>
                </Row>
                <Row className="row">
                    <span>
                        Địa chỉ:{' '}
                        <b>
                            {staffDetail?.province_id
                                ? provinces.find((item: any) => item?.id === Number(staffDetail?.province_id))?.name
                                : '---'}
                        </b>
                    </span>
                    <span>
                        Email: <b>{staffDetail?.email}</b>
                    </span>
                </Row>
                <Row className="row">
                    <span>
                        Số khách hàng phụ trách: <b>{formatPrice(staffDetail?.customer_count)}</b>
                    </span>
                    <span>
                        <p></p>
                    </span>
                </Row>
            </CustomCard>
        </>
    )
}

const CustomCard = styled(Card)`
    width: 100%;
    border-radius: 10px;

    .row {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 200px;
    }
`

export default StaffGeneralInfo
