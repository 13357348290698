import { Skeleton, Spin, Tabs } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { uuid } from 'utils/Configs'
import { MODE_MESSAGE } from 'utils/constants'
import { getDataListMessage, requestReadAllMessage } from '../ChatService'
import { useListMessageNotRead } from '../hooks/useListMessageNotRead'
import useWsListTopicMessage from '../hooks/useWsListTopicMessage'
import AutoSearch from './AutoSearch'
import TopicChatItem from './TopicChatItem'
import './css/styles.css'

interface ISlideBar {
    isStartNewTopic?: boolean
}

function SlideBar(props: ISlideBar) {
    const { isStartNewTopic } = props
    const history = useHistory()
    const dispatch = useDispatch()
    const split_pathname: Array<string> = history?.location?.pathname?.split('/')
    const topic_message_id: string | undefined = split_pathname[split_pathname.length - 1]
    const { listTopicMessageNotRead } = useListMessageNotRead()
    const listNotReadMsgIds = listTopicMessageNotRead?.map((item: any) => item?.topic_message_id)

    const {
        isLoadingFirstTime,
        setIsLoadingFirstTime,
        listTopicMessage,
        setListTopicMessage,
        pagingTopicMessage,
        setPagingTopicMessage,
        selectedTopic,
    } = useWsListTopicMessage()
    const [isSearchMode, setIsSearchMode] = useState<boolean>(false)

    const [listSearchTopicMessage, setListSearchTopicMessage] = useState<Array<any>>([])
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)
    const [canLoading, setCanLoading] = useState<boolean>(false)
    const [currentTab, setCurrentTab] = React.useState<string>('1')

    const refFirstTimeRender = useRef<boolean>(false)

    const getListTopic = async () => {
        setIsLoadingFirstTime(true)
        try {
            const res = await getDataListMessage({ page: 1, is_message_not_read: currentTab === '1' ? undefined : 1 })
            if (res?.status) {
                const formattedPaging = {
                    total: res?.paging?.totalItemCount,
                    current: res?.paging?.page,
                    pageSize: res?.paging?.limit,
                }
                setPagingTopicMessage(formattedPaging)
                setListTopicMessage(res.data)
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoadingFirstTime(false)
        }
    }

    const searchListTopicMessage = async (search: string) => {
        setIsSearchLoading(true)
        try {
            const res = await getDataListMessage({
                page: 1,
                search: search.trim(),
                is_message_not_read: currentTab === '1' ? undefined : 1,
            })
            setListSearchTopicMessage(res?.data)
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsSearchLoading(false)
        }
    }

    const getMoreTopic = async () => {
        if (!isLoadingMore && listTopicMessage.length < pagingTopicMessage.total) {
            try {
                setIsLoadingMore(true)
                const res = await getDataListMessage({
                    page: pagingTopicMessage.current + 1,
                })
                const formattedPaging = {
                    total: res.paging.totalItemCount,
                    current: res.paging.page,
                    pageSize: res.paging.limit,
                }
                setPagingTopicMessage(formattedPaging)
                setListTopicMessage([...listTopicMessage, ...res.data])
                if (formattedPaging.total > listTopicMessage.length + res.data.length) {
                    setCanLoading(false)
                } else {
                    setCanLoading(true)
                }
            } catch (error) {
                console.log('err', error)
                setCanLoading(false)
            } finally {
                setIsLoadingMore(false)
            }
        }
    }

    const onReadMessageNew = async (msgId: number) => {
        const newList = listTopicMessage?.map((item: any) => {
            if (item?.id === msgId) {
                return {
                    ...item,
                    count_message_not_read: 0,
                }
            }
            return item
        })
        console.log('🚀 ~ file: SlideBar.tsx:124 ~ newList ~ newList:', newList)
        setListTopicMessage(newList)
        // dispatch(readATopicMessage({ msgId: topic_message_id }))
        await requestReadAllMessage(msgId)
    }

    const checkIsNotReadMsg = (id: number) => {
        return listNotReadMsgIds.includes(id)
    }

    const onChanegTab = (tab: string) => {
        setCurrentTab(tab)
        setIsSearchMode(false)
        setPagingTopicMessage({
            ...pagingTopicMessage,
            current: 1,
        })
    }

    useEffect(() => {
        if (listTopicMessage.length !== 0 && !topic_message_id && !refFirstTimeRender.current) {
            refFirstTimeRender.current = true
            history.replace(`${ADMIN_ROUTER_PATH.CHAT}/${listTopicMessage?.[0].id}`)
        }
    }, [listTopicMessage])

    useEffect(() => {
        const infiniteListTopic: any = document.getElementById('infiniteListTopic')
        if (infiniteListTopic) {
            infiniteListTopic?.addEventListener('scroll', (e: any) => {
                const el = e.target

                if (el.clientHeight + el.scrollTop + 10 > el.scrollHeight && !isLoadingMore) {
                    setCanLoading(true)
                }
            })
        }
    }, [])

    useEffect(() => {
        getListTopic()
    }, [isStartNewTopic, currentTab])

    useEffect(() => {
        if (canLoading) {
            getMoreTopic()
        }
    }, [canLoading])

    return (
        <CustomTabs defaultActiveKey="1" onChange={onChanegTab}>
            <CustomTabs.TabPane tab="Tất cả" key="1">
                <div className="slidebar-chat">
                    <div
                        style={{
                            width: '100%',
                            height: 50,
                            padding: 5,
                        }}
                    >
                        <AutoSearch
                            onSearchSubmit={(key: string) => {
                                console.log(key)

                                if (key) searchListTopicMessage(key)
                                else setListSearchTopicMessage([])
                            }}
                            setListSearchTopicMessage={setListSearchTopicMessage}
                            isSearchMode={isSearchMode}
                            isSearchLoading={isSearchLoading}
                            placeholder="Tìm kiếm..."
                            onFocus={() => setIsSearchMode(true)}
                            onBlur={() => setIsSearchMode(false)}
                        />
                        <hr style={{ width: '100%', color: 'gainsboro' }} />
                    </div>
                    {isSearchMode ? (
                        // search mode
                        <div className="scroll-list" id="infiniteListTopic">
                            {isSearchLoading ? (
                                <Spin
                                    style={{
                                        marginTop: '50%',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                    tip="Đang tải..."
                                    size="large"
                                />
                            ) : (
                                listSearchTopicMessage.map((item, index: number) => (
                                    <TopicChatItem
                                        item={item}
                                        key={index}
                                        id={item?.id ? item?.id : 'default_id'}
                                        count_message_not_read={item?.count_message_not_read !== 0}
                                        Message={item?.Message?.[0] || null}
                                        Shop={item?.Shop}
                                        User={item?.User}
                                        time_last_send={item?.time_last_send}
                                        index={index}
                                        mode={MODE_MESSAGE.SEARCH}
                                        onReadMessage={() => onReadMessageNew(item?.id)}
                                    />
                                ))
                            )}
                        </div>
                    ) : (
                        // default mode

                        <div className="scroll-list" id="infiniteListTopic">
                            {isLoadingFirstTime ? (
                                <Spin
                                    style={{
                                        marginTop: '50%',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                    tip="Đang tải..."
                                    size="large"
                                />
                            ) : listTopicMessage.length > 0 ? (
                                listTopicMessage.map((item, index: number) => {
                                    if (item) {
                                        return (
                                            <TopicChatItem
                                                key={index}
                                                id={item?.id ? item?.id : uuid()}
                                                Message={item?.Message?.[0] || item?.Messages?.[0] || null}
                                                count_message_not_read={item?.count_message_not_read !== 0}
                                                Shop={item.Shop}
                                                User={item.User}
                                                time_last_send={item.time_last_send}
                                                index={index}
                                                mode={MODE_MESSAGE.DEFAULT}
                                                onReadMessage={() => onReadMessageNew(item?.id)}
                                            />
                                        )
                                    }
                                })
                            ) : (
                                <p
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        marginTop: 25,
                                        color: 'gray',
                                        fontWeight: 'bold',
                                        userSelect: 'none',
                                    }}
                                >
                                    Chưa có cuộc hội thoại nào
                                </p>
                            )}
                            {isLoadingMore && (
                                <div style={{ width: '90%', marginLeft: '5%', marginTop: 0 }}>
                                    <Skeleton
                                        loading={true}
                                        active
                                        avatar
                                        paragraph={{ rows: 1, width: '100%' }}
                                        title={{ width: '100%' }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </CustomTabs.TabPane>
            <CustomTabs.TabPane tab="Chưa đọc" key="2">
                <div className="slidebar-chat">
                    <div
                        style={{
                            width: '100%',
                            height: 50,
                            padding: 5,
                        }}
                    >
                        <AutoSearch
                            onSearchSubmit={(key: string) => {
                                if (key) searchListTopicMessage(key)
                                else setListSearchTopicMessage([])
                            }}
                            setListSearchTopicMessage={setListSearchTopicMessage}
                            isSearchMode={isSearchMode}
                            isSearchLoading={isSearchLoading}
                            placeholder="Tìm kiếm..."
                            onFocus={() => setIsSearchMode(true)}
                            onBlur={() => setIsSearchMode(false)}
                        />
                        <hr style={{ width: '100%', color: 'gainsboro' }} />
                    </div>
                    {isSearchMode ? (
                        // search mode
                        <div className="scroll-list">
                            {isSearchLoading ? (
                                <Spin
                                    style={{
                                        marginTop: '50%',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                    tip="Đang tải..."
                                    size="large"
                                />
                            ) : (
                                listSearchTopicMessage.map((item, index: number) => (
                                    <TopicChatItem
                                        key={index}
                                        id={item?.id ? item?.id : 'default_id'}
                                        count_message_not_read={item?.count_message_not_read !== 0}
                                        Message={item?.Message?.[0] || null}
                                        Shop={item?.Shop}
                                        User={item?.User}
                                        time_last_send={item?.time_last_send}
                                        index={index}
                                        mode={MODE_MESSAGE.SEARCH}
                                        onReadMessage={() => onReadMessageNew(item?.id)}
                                    />
                                ))
                            )}
                        </div>
                    ) : (
                        // default mode

                        <div className="scroll-list" id="infiniteListTopic">
                            {isLoadingFirstTime ? (
                                <Spin
                                    style={{
                                        marginTop: '50%',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                    tip="Đang tải..."
                                    size="large"
                                />
                            ) : listTopicMessage.length > 0 ? (
                                listTopicMessage.map((item, index: number) => {
                                    return (
                                        <TopicChatItem
                                            key={index}
                                            id={item?.id ? item?.id : uuid()}
                                            Message={item?.Message?.[0] || null}
                                            count_message_not_read={checkIsNotReadMsg(item?.id)}
                                            Shop={item.Shop}
                                            User={item.User}
                                            time_last_send={item.time_last_send}
                                            index={index}
                                            mode={MODE_MESSAGE.DEFAULT}
                                            onReadMessage={() => onReadMessageNew(item?.id)}
                                        />
                                    )
                                })
                            ) : (
                                <p
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        marginTop: 25,
                                        color: 'gray',
                                        fontWeight: 'bold',
                                        userSelect: 'none',
                                    }}
                                >
                                    Chưa có cuộc hội thoại nào
                                </p>
                            )}
                            {isLoadingMore && (
                                <div style={{ width: '90%', marginLeft: '5%', marginTop: 0 }}>
                                    <Skeleton
                                        loading={true}
                                        active
                                        avatar
                                        paragraph={{ rows: 1, width: '100%' }}
                                        title={{ width: '100%' }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </CustomTabs.TabPane>
        </CustomTabs>
    )
}

const CustomTabs = styled(Tabs)`
    .ant-tabs-nav-list {
        margin-left: 20px;
    }
`

// export default memo(SlideBar)
export default SlideBar
