import React, { useEffect, useState } from 'react'
import { Divider, Menu } from 'antd'
import { getToppicBlogs } from '../CommunityBlogsApi'
import { useHistory } from 'react-router-dom'

const BlogsMenu = () => {
  let history = useHistory()
  const [data, setData] = useState<Array<any>>([])
  const getTopic = async () => {
    try {
      const res = await getToppicBlogs()
      setData(res.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getTopic()
  }, [])
  const handleClick = (e: any) => {
    history.push({
      pathname: `/blogs/post-list`,
      state: { idTopic: e.key },
    })
  }
  return (
    <Menu
      className="menu"
      onClick={handleClick}
      mode="inline"
      // defaultSelectedKeys={['1']}
      style={{ height: '100%' }}
    >
      <div
        style={{
          marginTop: 30,
          marginBottom: 30,
          fontSize: 30,
          fontWeight: 900,
          marginLeft: 20,
        }}
      >
        Chủ đề
      </div>
      <Divider />
      {data.map(item => {
        return (
          <Menu.Item style={{ fontSize: 20 }} key={item.id}>
            {item.name}
          </Menu.Item>
        )
      })}
      <Divider />
    </Menu>
  )
}

export default BlogsMenu
