import {
  EyeOutlined,
  EyeTwoTone,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Table, Tag, Typography } from 'antd'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { notificationError } from 'utils/notification'
import { getListKiotviet } from '../ConfigAPI'
import { IFormatedKiotviet } from '../ConfigIntefaces'
import { useDispatch, useSelector } from 'react-redux'
import GetDataSlice, { getData } from '../GetDataSlice'
import { useHistory } from 'react-router-dom'
import { ADMIN_ROUTER_PATH } from 'common/config'

const BtnDetail = styled(EyeOutlined)`
  font-size: 20px;
  cursor: pointer;
`

interface IProps {
  currentTab: string
}

function AsyncKioviet(props: IProps) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [listKiotviet, setlistKiotviet] = useState<IFormatedKiotviet[]>()
  const getData = useSelector((state: any) => state.getDataReducer.getData)

  const getListKiotViet = async () => {
    setisLoading(true)
    try {
      const res = await getListKiotviet()
      if (res.data) {
        const dataTable = res.data.map((item, index) => {
          return {
            ...item,
            index: index + 1,
            key: index,
          }
        })
        setlistKiotviet(dataTable)
      }
    } catch (err) {
      console.log(err)
      notificationError('Có lỗi xảy ra!')
    } finally {
      setisLoading(false)
    }
  }

  useEffect(() => {
    getListKiotViet()
  }, [getData])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
    },
    {
      title: 'Tên gian hàng',
      dataIndex: 'name',
    },

    {
      title: 'Client_ID',
      dataIndex: 'client_id',
    },
    {
      title: 'Secret_ID',
      dataIndex: 'client_secret',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value: number) => {
        return (
          <>
            <Tag color={value == 1 ? 'blue' : 'red'}>
              {value == 1 ? 'Đang đồng bộ' : 'Ngừng đồng bộ'}
            </Tag>
          </>
        )
      },
    },
    {
      title: 'Chi tiết',
      dataIndex: '',
      render: (record: any) => {
        return (
          <BtnDetail
            onClick={() =>
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.CONFIG_DETAIL}/${record.id}`,
                state: {
                  keyTab: props.currentTab,
                  data: record,
                },
              })
            }
          />
        )
      },
    },
  ]

  return (
    <>
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={listKiotviet}
      />
    </>
  )
}

export default AsyncKioviet
