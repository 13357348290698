import {
  AndroidOutlined,
  AppleOutlined,
  DownloadOutlined,
  FacebookOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'
import { Avatar, Button, Divider, Layout, List, Menu, Pagination } from 'antd'
import { Route, useLocation } from 'react-router-dom'
import BlogsDetail from './component/BlogsDetail'
import BlogsListPost from './component/BlogsListPost'
import BlogsMenu from './component/BlogsMenu'
import images from 'utils/images'
import './css/Blogs.css'
const { Sider, Content, Footer, Header } = Layout
interface IState {
  id?: string
}
export default function Blogs() {
  return (
    <div className="container">
      <div className="header">
        <div className="header-item">OGOLIVE</div>
        <div className="header-item">
          <a
            href="https://play.google.com/store/apps/details?id=vn.winds.ogolive"
            style={{ color: 'red' }}
          >
            <DownloadOutlined />
          </a>
          Tải ứng dụng
        </div>
      </div>
      <div className="content">
        <div className="content-banner">
          <img
            style={{ display: 'flex', margin: 'auto', width: '100%' }}
            src={images.img_logo_blog}
          />
          <a
            href="https://play.google.com/store/apps/details?id=vn.winds.ogolive"
            className="content-banner-androi-download"
          >
            <img
              style={{ borderRadius: 5 }}
              src={images.img_logo_google_play}
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/ogolive/id1579495454"
            className="content-banner-ios-download"
          >
            <img style={{ borderRadius: 5 }} src={images.img_logo_ios} />
          </a>
          <div className="download">
            <a
              href="https://play.google.com/store/apps/details?id=vn.winds.ogolive"
              style={{ color: 'black' }}
            >
              <span>
                <AndroidOutlined style={{ fontSize: 30, color: 'black' }} />
                Androi
              </span>
            </a>
            <a
              href="https://apps.apple.com/us/app/ogolive/id1579495454"
              style={{ color: 'black' }}
            >
              <span>
                <AppleOutlined
                  style={{ fontSize: 30, marginLeft: 5, color: 'black' }}
                />
                App Store
              </span>
            </a>
          </div>
        </div>
        <div className="content-body">
          <div className="content-body-menu">
            <BlogsMenu></BlogsMenu>
          </div>
          <div className="content-body-item">
            <Route
              path="/blogs/post-list"
              exact
              component={BlogsListPost}
            ></Route>
            <Route
              path="/blogs/detail/:id"
              exact
              component={BlogsDetail}
            ></Route>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer-wraper">
          <div className="footer-logo">OGOLIVE</div>
          <div className="footer-content">
            <div className="footer-address">
              <div>Địa chỉ:</div>
              <div className="footer-address-desc">
                Tầng 13, tòa nhà ICON4, 243A, Đê La Thành, Đống Đa, Hà Nội
              </div>
            </div>

            <div className="footer-contact">
              <div>+84 24 3795 4165</div>
              <div>+84 24 3795 4166</div>
              <div>contact@ogolive.com.vn</div>
            </div>

            <div className="footer-media">
              <div className="media-desc">THEO DÕI CHÚNG TÔI</div>
              <div className="media-icon">
                <FacebookOutlined className="icon-fb" />
                <YoutubeOutlined className="icon-yt" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
