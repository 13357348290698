import { Affix, message, PageHeader, Space, Switch, Table, Empty } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import reactotron from 'ReactotronConfig'
import { formatPrice } from 'utils/ruleForm'
import AddShop from './components/AddShop'
import Header from './components/Header'
import {
  changeStatusLiveStream,
  changeStatusShop,
  createShop,
  getShopList,
  ChangeFollowStatus,
} from './ShopApi'

export default function Shop() {
  const heightWeb = useSelector(
    (state: any) => state.configReducer.dimension.height
  )

  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const [shop_list, setShopList] = useState([
    {
      key: 1,
      id: 0,
      name: '',
      phone: '',
      count_product: 0,
      count_order: 0,
      count_livestream: '0',
      livestream_enable: 1,
      pakage_name: '',
      status_follow: 0,
      status: 0,
    },
  ])
  const [params, setParams] = useState<any>({
    search: '',
    status: null,
    page: 1,
  })

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const [itemSelected, setItemSelected] = useState({
    id: 0,
    rowIndex: 0,
    status_follow: 0,
  })
  const history = useHistory()

  const getShop_List = async () => {
    // setIsLoading(true)
    // try {
    //   let res = await getShopList(params)
    //   const data = res.data.map((item: any, index: any) => {
    //     return { ...item, key: item.id }
    //   })
    //   const formattedPaging = {
    //     total: res.paging.totalItemCount,
    //     current: res.paging.page,
    //     pageSize: res.paging.limit,
    //   }
    //   setShopList(data)
    //   setPaging(formattedPaging)
    // } catch (error) {
    //   message.error('Hệ thống gặp sự cố !!')
    //   console.log(error)
    // } finally {
    //   setIsLoading(false)
    //   setIsSearchLoading(false)
    // }
  }
  useEffect(() => {
    getShop_List()
  }, [params])

  const CreateShop = () => {
    setIsModalVisible(true)
  }

  const addShop = async (values: any, resetFields: any) => {
    setIsLoading(true)
    try {
      const res = await createShop(values)
      setShopList([res.data, ...shop_list])
      message.success(`Tạo gian hàng thành công`)
      resetFields()
      setIsModalVisible(false)
    } catch (error) {
      console.log(error)
      if (error === null)
        message.error('Đã có lỗi kết nối, vui lòng thử lại sau!')
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeShopStatus = async (checked: any, e: any) => {
    e.stopPropagation()
    setIsLoading(true)
    try {
      let data = await changeStatusShop(itemSelected.id)
      shop_list[itemSelected.rowIndex].status = data.data.status
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeLiveStreamStatus = async (checked: any, e: any) => {
    e.stopPropagation()
    setIsLoading(true)
    try {
      let data = await changeStatusLiveStream(itemSelected.id)
      shop_list[itemSelected.rowIndex].livestream_enable =
        data.data.livestream_enable
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }
  const onChangeFollowStatus = async (checked: any, e: any) => {
    e.stopPropagation()
    setIsLoading(true)
    try {
      let data = await ChangeFollowStatus({
        id: itemSelected.id,
        status: itemSelected.status_follow === 0 ? 1 : 0,
      })
      shop_list[itemSelected.rowIndex].status_follow = data.data.status_follow
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const columns = [
    {
      width: 70,
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any, index: any) => (
        <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </td>
      ),
      ellipsis: true,
    },
    {
      title: 'Tên gian hàng',
      dataIndex: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
    },
    {
      title: 'Số sản phẩm',
      dataIndex: 'count_product',
    },
    {
      title: 'Số đơn hàng',
      dataIndex: 'count_order',
    },
    {
      title: 'Số live stream',
      dataIndex: 'count_livestream',
    },
    {
      title: 'Live stream',
      dataIndex: 'livestream_enable',
      render: (value: any) => {
        return (
          <td>
            {value === 1 ? (
              <Switch
                checked
                onChange={onChangeLiveStreamStatus}
                style={{ backgroundColor: '#00abba' }}
              />
            ) : (
              <Switch onChange={onChangeLiveStreamStatus} />
            )}
          </td>
        )
      },
    },
    {
      title: 'Thông báo',
      dataIndex: 'status_follow',
      render: (value: any) => {
        return (
          <td>
            {value === 1 ? (
              <Switch
                checked
                onChange={onChangeFollowStatus}
                style={{ backgroundColor: '#00abba' }}
              />
            ) : (
              <Switch onChange={onChangeFollowStatus} />
            )}
          </td>
        )
      },
    },
    {
      title: 'Gói dịch vụ',
      dataIndex: 'pakage_name',
      render: (value: any) => {
        return <td>{value ? value : '---'}</td>
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value: any) => {
        return (
          <td>
            {value === 1 ? (
              <Switch
                checked
                onChange={onChangeShopStatus}
                style={{ backgroundColor: '#00abba' }}
              />
            ) : (
              <Switch onChange={onChangeShopStatus} />
            )}
          </td>
        )
      },
    },
  ]

  return (
    <>
      <PageHeader
        title="Gian hàng"
        // className="page-header_account"
        style={{ backgroundColor: 'white', margin: '5px 10px 15px' }}
        extra={[
          <Space>
            <Header
              CreateShop={CreateShop}
              isSearchLoading={isSearchLoading}
              onSearchSubmit={(searchKey: string) => {
                setIsSearchLoading(true)
                setParams({ ...params, search: searchKey, page: 1 })
              }}
              onStatusSubmit={(statusKey: string) => {
                setParams({ ...params, status: statusKey, page: 1 })
              }}
            />
          </Space>,
        ]}
      />

      <div
        style={{
          backgroundColor: 'white',
          margin: '0px 10px 15px',
          padding: '10px 20px',
        }}
      >
        <p>
          Kết quả lọc: <b>{formatPrice(paging.total)}</b>
        </p>
        <Table
          className="table-expanded-custom-no-image"
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            y: `calc(${heightWeb}px - 340px)`,
            // y: 'calc(100vh - 340px)',
          }}
          bordered
          dataSource={shop_list}
          loading={isLoading}
          columns={columns}
          onRow={(record, rowIndex) => ({
            onDoubleClick: () => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.SHOP_DETAIL}/${record.id}`,
                state: record,
              })
            },
            onMouseEnter: event => {
              setItemSelected({
                id: record.id,
                rowIndex: Number(rowIndex),
                status_follow: record.status_follow,
              })
            },
          })}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu.</span>}
              />
            ),
          }}
          pagination={{
            ...paging,
            showSizeChanger: false,
            onChange: async (page, pageSize) => {
              setParams({ ...params, page })
            },
          }}
        />
      </div>

      <AddShop
        visible={isModalVisible}
        reloadShopList={getShop_List}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        addShop={(newData: any, resetFields: any) => {
          addShop(newData, resetFields)
        }}
        isLoadingButton={isLoading}
      />
    </>
  )
}
