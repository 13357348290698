import { useState, useEffect, useRef } from 'react'
import { Col, Spin, Row, Button, Upload, Popover } from 'antd'
import { useDispatch } from 'react-redux'
import { SendOutlined, FileImageOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons'
import { requestSendMessage, requestSendMessageToNewUser } from '../ChatService'
import { MEDIA_TYPE } from 'utils/constants'
import Picker from 'emoji-picker-react'
import { UserInfoState } from '../types'
import { getMessageNotReadAction, readATopicMessage } from '../slices/MessageNotReadSlice'
import { useHistory } from 'react-router-dom'
import useWsListTopicMessage from '../hooks/useWsListTopicMessage'

interface ITypingChartArea {
    id?: string
    otherInfo?: any | null
    setIsStartNewTopic: React.Dispatch<React.SetStateAction<boolean>>
}

function TypingChatArea(props: ITypingChartArea) {
    const { id, otherInfo, setIsStartNewTopic } = props
    const dispatch = useDispatch()
    const history = useHistory()
    const inputRef = useRef<any>(null)
    const [imageFile, setImageFile] = useState<any>(null)
    const [videoFile, setVideoFile] = useState<any>(null)
    const [sendLoading, setSendLoading] = useState<boolean>(false)

    useEffect(() => {
        inputRef.current.value = ''
        inputRef.current.focus()
    }, [sendLoading])

    const onSendMessage = async () => {
        const valInput = inputRef.current.value.trim()
        if (id && ((valInput && valInput !== '') || imageFile || videoFile)) {
            try {
                setSendLoading(true)
                const arrText: Array<string> = valInput.split('\n')
                let resText = ''
                arrText.forEach((e, i) => {
                    if (i !== arrText.length - 1) resText += e + '\\n'
                    else resText += e
                })
                const formData = new FormData()
                formData.append('content', resText)
                if (id === 'default_id') {
                    formData.append('user_id', otherInfo?.id)
                    formData.append('kiotviet_id', otherInfo?.kiotviet_id)
                }
                if (imageFile) {
                    formData.append('message_media', imageFile.originFileObj)
                    formData.append('type_message_media', MEDIA_TYPE.IMAGE.toString())
                } else if (videoFile) {
                    formData.append('message_media', videoFile.originFileObj)
                    formData.append('type_message_media', MEDIA_TYPE.VIDEO.toString())
                }
                inputRef.current.value = ''
                setImageFile(null)
                setVideoFile(null)
                let res: any

                if (id !== 'default_id') {
                    res = await requestSendMessage(id, formData)
                } else {
                    res = await requestSendMessageToNewUser(formData)
                    if (res?.status) {
                        history.push(`/chat/${res?.data?.topic_message_id}`)
                        setIsStartNewTopic(prev => !prev)
                    }
                }

                if (res?.status) {
                    inputRef.current.value = ''
                    setImageFile(null)
                    setVideoFile(null)
                }
            } catch (error) {
                console.log('error', error)
            } finally {
                setSendLoading(false)
            }
        }
    }
    const onAppendNewLine = (e: any) => {
        const valInput: string = inputRef.current.value,
            start = e.target.selectionStart,
            end = e.target.selectionEnd
        inputRef.current.value = valInput.substring(0, start) + '\n' + valInput.substring(end)
        inputRef.current.selectionStart = inputRef.current.selectionEnd = start + 1
        e.preventDefault()
    }
    return (
        <Row
            // align="middle"
            style={{
                width: '100%',
                height: '11%',
                boxShadow: '0 -5px 5px -5px #333',
            }}
        >
            <Col style={{ height: '100%', width: '100%' }}>
                <Row
                    align="middle"
                    style={{
                        height: '40%',
                        width: '100%',
                        border: 'none',
                        outline: 'none',
                        paddingLeft: 3,
                    }}
                >
                    <Popover
                        style={{ width: 100 }}
                        content={
                            <Picker
                                onEmojiClick={(event, emojiObj) => {
                                    inputRef.current.value += emojiObj.emoji
                                }}
                                pickerStyle={{ width: '270px', margin: '0px' }}
                            />
                        }
                        title=""
                        trigger="click"
                    >
                        <Button
                            className="button-hover"
                            style={{
                                height: '100%',
                                border: 'none',
                                outline: 'none',
                            }}
                            icon={
                                <SmileOutlined
                                    style={{
                                        fontSize: 'large',
                                        color: 'gray',
                                    }}
                                    onClick={e => {}}
                                />
                            }
                        />
                    </Popover>
                    {!videoFile && (
                        <Upload
                            style={{ height: '75%', backgroundColor: 'green' }}
                            accept="image/*"
                            listType="text"
                            fileList={imageFile ? [imageFile] : []}
                            onChange={({ fileList }) => {
                                if (fileList.length) setImageFile(fileList[0])
                            }}
                            onRemove={file => {
                                setImageFile(null)
                                setVideoFile(null)
                            }}
                            beforeUpload={() => false}
                        >
                            {!imageFile && (
                                <Button
                                    className="button-hover"
                                    style={{
                                        height: '100%',
                                        border: 'none',
                                        outline: 'none',
                                    }}
                                    icon={
                                        <FileImageOutlined
                                            style={{
                                                fontSize: 'large',
                                                color: 'gray',
                                            }}
                                            onClick={e => {}}
                                        />
                                    }
                                />
                            )}
                        </Upload>
                    )}
                    {/* {!imageFile && (
            <Upload
              accept="video/*"
              listType="text"
              fileList={videoFile ? [videoFile] : []}
              onChange={({ fileList }) => {
                if (fileList.length) setVideoFile(fileList[0])
              }}
              onRemove={file => {
                setImageFile(null)
                setVideoFile(null)
              }}
              beforeUpload={() => false}
            >
              {!videoFile && (
                <Button
                  className="button-hover"
                  style={{
                    height: '85%',
                    border: 'none',
                    outline: 'none',
                  }}
                  icon={
                    <PlayCircleOutlined
                      style={{
                        fontSize: 'large',
                        color: 'gray',
                      }}
                      onClick={e => {}}
                    />
                  }
                />
              )}
            </Upload>
          )} */}
                </Row>
                <Row
                    justify="space-between"
                    align="middle"
                    style={{
                        height: '67%',
                        width: '100%',
                    }}
                    className="container-input-outline"
                >
                    <textarea
                        className="input-inside"
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        placeholder={`Gửi tin nhắn đến ${otherInfo?.name || '...'}`}
                        name="msg"
                        required
                        ref={inputRef}
                        onFocus={() => dispatch(readATopicMessage({ topic_message_id: id }))}
                        onKeyPress={e =>
                            !e.shiftKey && e.key === 'Enter'
                                ? onSendMessage()
                                : e.shiftKey && e.key === 'Enter'
                                ? onAppendNewLine(e)
                                : null
                        }
                    />
                    {sendLoading ? (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} className="loading-btn" />
                    ) : (
                        <Button
                            className="button-inside button-hover"
                            icon={
                                <SendOutlined
                                    style={{
                                        fontSize: 'large',
                                        color: 'gray',
                                    }}
                                    onClick={e => onSendMessage()}
                                />
                            }
                        />
                    )}
                </Row>
            </Col>
        </Row>
    )
}
export default TypingChatArea
