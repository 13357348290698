import { PageHeader, DatePicker, Row, Col } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
const { RangePicker } = DatePicker

interface IProps {
  searchKey: (value: string) => void
  dateTime: (value: Array<string>) => void
}

function Filters(props: IProps) {
  return (
    <>
      <PageHeader
        title="Livestream"
        extra={[
          <Row gutter={[16, 16]}>
            <Col>
              <TypingAutoSearch
                width={250}
                onSearchSubmit={(value: string) => props.searchKey(value)}
                placeholder="Mô tả livestream..."
              />
            </Col>
            <Col>
              <RangePicker
                onChange={(selectTime: any, timeString: Array<string>) =>
                  props.dateTime(timeString)
                }
              />
            </Col>
          </Row>,
        ]}
      />
    </>
  )
}

export default Filters
