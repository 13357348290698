import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, PageHeader, Tabs } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import AddShop from './AddShop'
import AsyncKioviet from './AsyncKioviet'
import SystemInfo from './SystemInfo'
import KIndex from './KIndex'
import AppUsingReport from './AppUsingReport'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'
const { TabPane } = Tabs

interface WapperContentStyle {
    padding?: boolean
}

const WapperContent = styled.div<WapperContentStyle>`
    background-color: white;
    border-radius: 5px;
    padding: ${props => (props.padding ? '10px' : null)};
    margin: 10px;
`

function Configs({ data }: any) {
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN

    const [isShowModal, setisShowModal] = useState<boolean>(false)
    const [keyTab, setkeyTab] = useState<string>(data.state?.keyTab)

    return (
        <>
            {isShowModal ? <AddShop isModalVisible={isShowModal} cancel={() => setisShowModal(false)} /> : null}
            <WapperContent>
                <PageHeader
                    className="site-page-header"
                    title="Cấu hình"
                    extra={[
                        <>
                            {keyTab == '2' ? (
                                <Button type="primary" onClick={() => setisShowModal(true)}>
                                    <PlusCircleOutlined />
                                    Thêm mới
                                </Button>
                            ) : null}
                        </>,
                    ]}
                />
            </WapperContent>
            <WapperContent padding>
                <Tabs
                    defaultActiveKey={keyTab}
                    onChange={(value: string) => {
                        setkeyTab(value)
                    }}
                >
                    {!isShopAdmin && (
                        <>
                            <TabPane tab="THÔNG SỐ HỆ THỐNG" key="1">
                                <SystemInfo />
                            </TabPane>
                            <TabPane tab="ĐỒNG BỘ KIOT VIỆT" key="2">
                                <AsyncKioviet currentTab={keyTab} />
                            </TabPane>
                            <TabPane tab="BÁO CÁO SỬ DỤNG APP" key="4">
                                <AppUsingReport />
                            </TabPane>
                        </>
                    )}
                    <TabPane tab="HỆ SỐ K" key="3">
                        <KIndex />
                    </TabPane>
                </Tabs>
            </WapperContent>
        </>
    )
}

export default Configs
