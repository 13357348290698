import { Form, Input, Modal, Spin, message } from 'antd'
import ButtonForm from 'common/components/ModalButton'
import React from 'react'
import { addDebtNote } from '../../CustomerAPI'

interface IAddNoteModal {
    visible: boolean
    setVisible: any
    dataCustomer: any
    getData: any
}

const AddNoteModal = (props: IAddNoteModal) => {
    const { visible, setVisible, dataCustomer, getData } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = React.useState<boolean>(false)

    const onSubmit = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                note: values?.note,
            }
            const res: any = await addDebtNote(dataCustomer?.id, payload)
            if (res?.status) {
                message.success('Thêm mới ghi chú thành công!')
                setVisible(false)
                getData()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal title={'Thêm ghi chú'} visible={visible} onCancel={() => setVisible(false)} footer={null} width={'60%'}>
            <Spin spinning={loading}>
                <Form preserve={false} onFinish={onSubmit} form={form}>
                    <Form.Item name="note" rules={[{ required: true, message: 'Vui lòng nhập ghi chú!' }]}>
                        <Input.TextArea placeholder="Nhập ghi chú" rows={5} allowClear />
                    </Form.Item>

                    <ButtonForm onCancel={() => setVisible(false)} text={'Lưu'} />
                </Form>
            </Spin>
        </Modal>
    )
}

export default AddNoteModal
