import { Button, Modal, Input, Form } from 'antd'
import ButtonForm from 'common/components/ModalButton'
import { useState } from 'react'
import reactotron from 'ReactotronConfig'

const { TextArea } = Input

interface IProps {
  showModal: boolean
  cancel: () => void
  confirm: (data: any) => void
}

function ModalReject(props: IProps) {
  return (
    <>
      <Modal
        destroyOnClose
        title="Lý do từ chối"
        visible={props.showModal}
        footer={null}
        onCancel={() => props.cancel()}
      >
        <Form
          onFinish={value => {
            props.confirm(value.reject_note)
          }}
        >
          <Form.Item
            name="reject_note"
            rules={[
              { required: true, message: 'Lý do không được để trống!!!' },
            ]}
          >
            <TextArea rows={4} placeholder="Nhập lý do ..." />
          </Form.Item>
          <ButtonForm onCancel={() => props.cancel()} text={'Gửi'} />
        </Form>
      </Modal>
    </>
  )
}

export default ModalReject
