import React, { useState } from 'react'
import { Button, Popover, Spin } from 'antd'
import {
  SendOutlined,
  SmileOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import Picker from 'emoji-picker-react'
import CustomCommentItem from './CustomCommentItem'
import { requestComment } from '../CommunityPostApi'
import { CommentItem, DataPostInteface } from '../InterfaceComPost'
import _ from 'lodash'
import useWsListComment from '../hook/useWsListComment'
import { current } from '@reduxjs/toolkit'
import { ROLE } from 'utils/constants'

interface PropsPostListComment {
  parent_id?: number
  post_id: number
  inputRef: any
  setDataPost: any
  dataPost: DataPostInteface | null
  onFocusInput: () => void
  handleCallback: () => void
}
const SpiningIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
function PostListComment({
  parent_id,
  post_id,
  inputRef,
  setDataPost,
  dataPost,
  onFocusInput,
  handleCallback,
}: PropsPostListComment) {
  const {
    isLoadingListComment,
    listComment,
    param,
    setParam,
    paging,
    loadMoreSubComment,
  } = useWsListComment(parent_id, post_id, dataPost, setDataPost)

  const [isLoadingComment, setLoadingComment] = useState<boolean>(false)
  const [replyComment, setReplyComment] = useState<CommentItem | null>(null)
  const [currentClick, setCurrentClick] = useState<number>(0)
  const onCommentFocusInput = (commentItem: CommentItem) => {
    setReplyComment(commentItem)
    onFocusInput()
  }

  const onSendComment = async () => {
    const valInput = inputRef.current.value.trim()
    const comment_id: number | undefined =
      replyComment?.parent_id || replyComment?.id
    if (valInput && !isLoadingComment) {
      const arrText: Array<string> = valInput.split('\n')
      let resText = ''
      arrText.forEach((e, i) => {
        if (i !== arrText.length - 1) resText += e + '\\n'
        else resText += e
      })
      const payload: any = { content: resText, }
      if (comment_id) payload.parent_id = comment_id

      try {
        setLoadingComment(true)
        const res = await requestComment(post_id, payload)
      } catch (error) {
        console.log('error', error)
      } finally {
        inputRef.current.value = ''
        setLoadingComment(false)
        setReplyComment(null)
      }
    }
  }
  const onAppendNewLine = (e: any) => {
    const valInput: string = inputRef.current.value,
      start = e.target.selectionStart,
      end = e.target.selectionEnd
    inputRef.current.value =
      valInput.substring(0, start) + '\n' + valInput.substring(end)
    inputRef.current.selectionStart = inputRef.current.selectionEnd = start + 1
    e.preventDefault()
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', height: 50 }}>
        <textarea
          className="input-inside"
          style={{
            width: '90%',
            height: '100%',
            paddingLeft: 10,
            borderRadius: 4,
            border: 'none',
            borderBottomStyle: 'groove',
            outline: 'none',
          }}
          placeholder={`Nhập bình luận bài đăng.`}
          name="msg"
          ref={inputRef}
          required
          onKeyPress={e =>
            !e.shiftKey && e.key === 'Enter'
              ? onSendComment()
              : e.shiftKey && e.key === 'Enter'
              ? onAppendNewLine(e)
              : null
          }
        />
        <Popover
          content={
            <Picker
              onEmojiClick={(event, emojiObj) => {
                inputRef.current.value += emojiObj.emoji
              }}
              pickerStyle={{ width: '270px', margin: '0px' }}
            />
          }
          title=""
          trigger="click"
        >
          <Button
            className="button-hover"
            style={{
              width: '5%',
              height: '100%',
            }}
            icon={
              <SmileOutlined
                style={{
                  fontSize: 'large',
                  color: '#bbbbbb',
                }}
              />
            }
          />
        </Popover>
        <Button
          loading={isLoadingComment}
          style={{
            width: '5%',
            height: '100%',
          }}
          icon={
            <SendOutlined
              style={{
                fontSize: 'large',
                color: 'silver',
              }}
              onClick={e => onSendComment()}
            />
          }
        />
      </div>
      {replyComment && (
        <div>
          <span style={{ color: 'gray', fontSize: 'x-small' }}>
            Trả lời bình luận:{' '}
            {replyComment?.Account?.role === ROLE.ADMIN
              ? replyComment?.Account?.Admin?.full_name
              : replyComment?.Account?.User?.full_name}
            <Button
              size="small"
              type="text"
              icon={<CloseCircleOutlined style={{ fontSize: 10 }} />}
              onClick={() => setReplyComment(null)}
            />
          </span>
        </div>
      )}

      <div style={{ width: '100%', marginTop: 10 }}>
        {listComment?.length > 0 ? (
          <>
            {listComment.map((item: CommentItem) => {
              return (
                <CustomCommentItem
                  handleCallback={handleCallback}
                  key={item.id}
                  onCommentFocusInput={onCommentFocusInput}
                  post_id={post_id}
                  commentItem={item}
                >
                  <div
                    style={{
                      fontSize: 'small',
                      color: 'gray',
                      display: 'flex',
                    }}
                  >
                    {item?.Comments &&
                      item?.Comments?.length < item.count_comment && (
                        <a
                          style={{
                            color: 'gray',
                            textDecoration: 'underline',
                            marginBottom: 5,
                          }}
                          onClick={() => {
                            if (item?.Comments)
                              loadMoreSubComment(
                                item?.Comments[0]?.post_id,
                                item.id
                              )
                          }}
                        >
                          Xem thêm{' '}
                          {item.count_comment - item?.Comments?.length} bình
                          luận
                        </a>
                      )}
                  </div>
                  {item?.Comments?.map((subItem: CommentItem) => (
                    <CustomCommentItem
                      handleCallback={handleCallback}
                      key={subItem.id}
                      onCommentFocusInput={onCommentFocusInput}
                      post_id={post_id}
                      commentItem={subItem}
                    />
                  ))}
                </CustomCommentItem>
              )
            })}

            {listComment.length === paging.total ? (
              <div
                style={{
                  marginTop: 10,
                  fontSize: 'small',
                  color: 'gray',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <span>Đã xem hết bình luận</span>
              </div>
            ) : (
              listComment.length < paging.total && (
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 'small',
                    color: 'gray',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {isLoadingListComment ? (
                    <Spin indicator={SpiningIcon} />
                  ) : (
                    <a
                      onClick={() => {
                        if (currentClick === 0) {
                          listComment.splice(0, param.limit)
                          setParam({
                            page: param.page,
                            limit: 12,
                          })
                        } else {
                          setParam({
                            page: param.page + 1,
                            limit: 12,
                          })
                        }
                        setCurrentClick(current => current + 1)
                      }}
                    >
                      xem thêm bình luận
                    </a>
                  )}
                </div>
              )
            )}
          </>
        ) : (
          <div
            style={{
              marginTop: 10,
              fontSize: 'small',
              color: 'gray',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span>Chưa có bình luận</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default PostListComment
