import Cookie from 'js-cookie'
import { create } from 'apisauce'
import { API_STATUS } from 'utils/constants'
import { notificationError } from 'utils/notification'
import reactotron from 'ReactotronConfig'
import queryString from 'query-string'
import { SESSION_ID } from 'common/config'
import { message } from 'antd'
import { environment } from 'config'

const createAPI = () => {
    const APIInstant = create({
        baseURL: environment.api_host,
        // baseURL: 'https://httapi.winds.vn/api/v1/',
        timeout: 20000,
        headers: { 'Content-Type': 'application/json' },
    })
    APIInstant.addMonitor((reactotron as any).apisauce)

    APIInstant.setHeader('token', Cookie.get(SESSION_ID) || '')
    APIInstant.axiosInstance.interceptors.request.use(
        async config => {
            config.headers.token = Cookie.get(SESSION_ID)
            config.headers.platform = 'web'
            return config
        },
        error => Promise.reject(error)
    )
    APIInstant.axiosInstance.interceptors.response.use(response => {
        const data = response.data

        if (data && data.code === API_STATUS.FORBIDEN) {
            notificationError('Không có quyền truy cập')
        }

        if ((data && data.code === API_STATUS.UNAUTHORIZED) || data.code === API_STATUS.NOT_FOUND) {
            Cookie.set(SESSION_ID, '')
            localStorage.setItem('token', '')
            // window.location.reload()
            // const store = require('../redux/store').default
            //   store.dispatch({ type: LOGOUT })
            //   NavigationUtil.navigate(SCREEN_ROUTER_APP.HOME)
            //   showMessages(R.strings().notification, R.strings().re_login)
            // }
        } else if (data && data?.status !== 1) {
            message.error(data?.message.split('Validation error:'))
            // swal({
            //   title: R.strings().fail_request,
            //   text: data?.message || R.strings().error_network,
            //   icon: 'error',
            // })
        }
        //   showMessages(R.strings().notification, data.msg)
        return response
    })
    return APIInstant
}
const axiosInstance = createAPI()

/* Support function */
function handleResult(api: any) {
    return api.then((res: { data: { status: number; code: number } }) => {
        if (res?.data?.status !== 1) {
            // message.error(`Đã có lỗi xảy ra, vui lòng thử lại`)
            return Promise.reject(res?.data)
        }
        return Promise.resolve(res?.data)
    })
}

function parseUrl(url: string, query: any) {
    return queryString.stringifyUrl({ url: url, query })
}

export const ApiClient = {
    get: (url: string, payload?: any, token?: any) => handleResult(axiosInstance.get(parseUrl(url, payload))),
    post: (url: string, payload?: any, token?: any) => handleResult(axiosInstance.post(url, payload)),
    put: (url: string, payload?: any, token?: any) => handleResult(axiosInstance.put(url, payload)),
    path: (url: string, payload?: any, token?: any) => handleResult(axiosInstance.patch(url, payload)),
    delete: (url: string, payload?: any, token?: any) => handleResult(axiosInstance.delete(url, {}, { data: payload })),
    postUploadFile: (url: string, payload?: any, config?: any) => {
        return axiosInstance
            .post(url, payload, config)
            .then(res => res.data)
            .catch(err => err)
    },
}

export default axiosInstance
