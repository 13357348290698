import Icon, { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Col, DatePicker, Input, Row, Select, Table, message } from 'antd'
import React from 'react'
import { exportExcel, exportWrapOrderReport, getWrapOrderDetail } from '../service'
import { renderOrderStatus } from 'utils/Configs'
import { formatTime, renderInventoryStatus } from 'utils/funcHelper'
import { momentToStringDate } from 'utils/TimerHelper'
import { creatableDesc } from 'features/admin/order-clone/Orders'
import { useDebounce } from 'common/hooks/Debounce'
import ButtonSave from 'common/components/Button/ButtonSave'

interface IExpandContent {
    record: any
}

const ExpandContent = (props: IExpandContent) => {
    const { record } = props
    const [search, setSearch] = React.useState<string>('')
    const [minute, setMinute] = React.useState<number>()
    const [fromDate, setFromDate] = React.useState<string>()
    const [toDate, setToDate] = React.useState<string>()
    const [reportDetail, setReportDetail] = React.useState<any>()
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })
    const searchDebounce = useDebounce(search, 300)
    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Mã đơn</b>,
            dataIndex: 'order_code',
        },
        {
            title: <b>Khách hàng</b>,
            dataIndex: 'customer_name',
        },
        {
            title: <b>Số sản phẩm</b>,
            dataIndex: 'quantity_count',
        },
        {
            title: <b>TT đơn hàng</b>,
            dataIndex: 'order_status',
            render: (value: any) => renderOrderStatus(value),
        },
        {
            title: <b>TT kho</b>,
            dataIndex: 'status',
            render: (value: any) => renderInventoryStatus(value),
        },
        {
            title: <b>NV kho</b>,
            dataIndex: 'inventory',
            render: (value: any, record: any) => record?.inventory_staff?.name,
        },
        {
            title: <b>Thời gian đóng hàng</b>,
            dataIndex: 'package_time',
            render: (value: any) => formatTime(value),
        },
        {
            title: <b>Tổng thời gian giao đơn</b>,
            dataIndex: 'assign_time',
            render: (value: any) => formatTime(value),
        },
        {
            title: <b>Ghi chú quản lý shipper</b>,
            dataIndex: 'note_shipper_manager',
            render: (value: any, record: any) => {
                if (!record?.order?.ship_manager_note) return '---'
                return record?.order?.ship_manager_note?.split('\n')?.map((item: any) => <p>{item}</p>)
            },
        },
        {
            title: <b>Nguồn đơn</b>,
            dataIndex: 'order_creatable_type',
            render: (value: any) => creatableDesc(value),
        },
        {
            title: <b>Ngày tạo</b>,
            dataIndex: 'createdAt',
            render: (value: any) => momentToStringDate(value),
        },
    ]

    const getDetail = async () => {
        try {
            const payload = {
                kiotviet_id: record?.kiotviet_id,
                branch_id: record?.id,
                search: searchDebounce,
                page: paging.current,
                limit: paging.pageSize,
                minute,
                from_date: fromDate,
                to_date: toDate,
            }
            const res: any = await getWrapOrderDetail(payload)
            setReportDetail(res?.data)
            const newPaging = {
                ...paging,
                current: res?.paging?.page,
                total: res?.paging?.totalItemCount,
            }
            setPaging(newPaging)
        } catch (error) {
            console.error(error)
        }
    }

    const onExportExcel = async () => {
        try {
            const payload = {
                kiotviet_id: record?.kiotviet_id,
                branch_id: record?.id,
                search: searchDebounce,
                page: paging.current,
                limit: paging.pageSize,
                minute,
                from_date: fromDate,
                to_date: toDate,
            }
            const res = await exportWrapOrderReport(payload)
            if (res?.status) {
                message.success('Xuất excel thành công!')
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        if (paging.current === 1) {
            getDetail()
        } else {
            setPaging({ ...paging, current: 1 })
        }
    }, [searchDebounce, fromDate, toDate, minute])

    React.useEffect(() => {
        getDetail()
    }, [paging.current])

    return (
        <div>
            <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                    <Input.Search
                        allowClear
                        className="search"
                        placeholder="Tên nhân viến"
                        addonAfter={<Icon type="close-circle-o" />}
                        value={search}
                        onChange={(e: any) => {
                            setSearch(e.target.value)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                    <Select
                        onChange={(value: any) => {
                            setMinute(value)
                        }}
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Vượt quá"
                    >
                        <Select.Option value={5}>Vượt quá 5 phút</Select.Option>
                        <Select.Option value={10}>Vượt quá 10 phút</Select.Option>
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                    <DatePicker.RangePicker
                        style={{ width: '100%', marginBottom: 14 }}
                        placeholder={['Từ ngày', 'Đến ngày']}
                        className="rangerpicker-order"
                        format={'DD-MM-YYYY'}
                        onChange={(value, dateString) => {
                            setFromDate(dateString[0].split('-').reverse().join('-'))
                            setToDate(dateString[1].split('-').reverse().join('-'))
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                    <ButtonSave
                        onClickButton={onExportExcel}
                        text="Xuất excel"
                        icon={<VerticalAlignBottomOutlined />}
                    />
                </Col>
            </Row>
            <Table
                id="top-table"
                bordered
                columns={columns}
                dataSource={reportDetail}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: 'Chưa có bản ghi nào!',
                }}
                pagination={{
                    ...paging,
                    showSizeChanger: false,
                    onChange: page => {
                        setPaging({ ...paging, current: page })
                    },
                }}
            />
        </div>
    )
}

export default ExpandContent
