import { Button, Form, InputNumber, Modal, Row, Select, Spin, Table, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { assignOrderForInventoryStaff, getInventoryStaffList } from 'features/admin/inventory_staff/service'
import { resetOrders } from 'features/admin/order-clone/OrderSlice'
import { creatableDesc } from 'features/admin/order-clone/Orders'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { toNonAccentVietnamese } from 'utils/Configs'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'

interface IAssignOrderForInventorStaff {
    isModalOpen: boolean
    setIsModalOpen: any
    listOrders: any[]
    isRefundOrder: boolean
    params?: any
    isLoading?: boolean
    setisLoading: React.Dispatch<React.SetStateAction<boolean>>
    getListData: (params?: any) => Promise<void>
    setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>
    setListRowKeysSelected: React.Dispatch<React.SetStateAction<any[]>>
}

const AssignOrderForInventorStaff = (props: IAssignOrderForInventorStaff) => {
    const {
        isModalOpen,
        setIsModalOpen,
        params,
        isLoading,
        getListData,
        setSelectedRows,
        setListRowKeysSelected,
        setisLoading,
    } = props

    const selectedOrderList = useSelector((state: any) => state.orderReducer.orderList)
    const dispatch = useDispatch()
    const [form] = useForm()
    const [listStaff, setListStaff] = React.useState<any>([])
    const [currentPage, setCurrentPage] = React.useState<number>(1)

    const columns = [
        {
            title: <b>STT</b>,
            width: 70,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (currentPage - 1) * 10 + index + 1,
        },
        {
            title: <b>Mã đơn</b>,
            dataIndex: 'code',
        },
        {
            title: <b>Khách hàng</b>,
            dataIndex: 'customer',
            render: (_: any, record: any) => record?.user?.name,
        },
        {
            title: <b>Số SP</b>,
            dataIndex: 'quantity_product',
        },
        {
            title: <b>Tổng tiền</b>,
            dataIndex: 'total_order',
            render: (value: any) => formatPrice(value),
        },
        {
            title: <b>Nguồn đơn</b>,
            dataIndex: 'creatable_type',
            render: (creatable_type: any) => creatableDesc(creatable_type),
        },
        {
            title: <b>Ngày tạo</b>,
            width: 120,
            dataIndex: 'date',
            render: (_: any, record: any) => momentToStringDate(record?.created_at),
        },
        {
            title: <b>Thứ tự giao đơn</b>,
            dataIndex: 'index',
            width: 150,
            render: (_: any, record: any) => (
                <Form.Item
                    name={`index_${record?.id}`}
                    initialValue={
                        record?.order_package_display_order === 1 || !record?.order_package_display_order
                            ? 1
                            : record?.order_package_display_order
                    }
                    rules={[
                        { required: true, message: 'Vui lòng nhập thứ tự giao đơn!' },
                        { min: 1, type: 'number', message: 'Giá trị tối thiểu là 1!' },
                    ]}
                >
                    <InputNumber
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        min={1}
                        style={{ width: '100%' }}
                        placeholder="Nhập thứ tự"
                    />
                </Form.Item>
            ),
        },
    ]

    const onCancel = () => {
        setIsModalOpen(false)
    }

    const getListStaff = async () => {
        try {
            const payload = {
                kiotviet_id: selectedOrderList[0]?.kiotviet_id,
                branch_id: selectedOrderList[0]?.branch_id,
                page: 1,
                limit: 999,
            }
            const res = await getInventoryStaffList(payload)
            if (res?.status) {
                const listData = res?.data?.map((item: any) => ({
                    ...item,
                    value: item?.id,
                    label: `${item?.name} - ${item?.phone_number}`,
                }))
                setListStaff(listData)
            }
        } catch (error) {
            console.error(error)
        } finally {
        }
    }

    const onFinish = async (values: any) => {
        try {
            setisLoading(true)
            const orders = selectedOrderList?.map((item: any) => ({
                display_order: values[`index_${item?.id}`],
                order_id: item?.id,
            }))
            const payload = { orders }

            const res = await assignOrderForInventoryStaff(values?.staff, payload)
            if (res?.status) {
                dispatch(resetOrders())
                message.success('Giao đơn hàng cho nhân viên kho thành công!')
                setIsModalOpen(false)
                getListData(params)
                setSelectedRows([])
                setListRowKeysSelected([])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setisLoading(false)
        }
    }

    React.useEffect(() => {
        getListStaff()
    }, [])

    return (
        <Modal
            okText="Lưu"
            title="Giao đơn cho nhân viên kho"
            destroyOnClose
            width={'90%'}
            centered
            maskClosable={false}
            visible={isModalOpen}
            onCancel={onCancel}
            footer={null}
        >
            <Spin spinning={isLoading}>
                <CustomForm form={form} onFinish={onFinish}>
                    <CustomRow>
                        <Form.Item
                            label="Chọn nhân viên kho"
                            name={'staff'}
                            rules={[{ required: true, message: 'Vui lòng chọn nhân viên kho!' }]}
                        >
                            <Select
                                className="select"
                                placeholder="Chọn nhân viên kho"
                                allowClear
                                showArrow
                                showSearch
                                options={listStaff}
                                filterOption={(input, option: any) => {
                                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                    return nonAccent.includes(nonAccentInput)
                                }}
                            />
                        </Form.Item>
                    </CustomRow>
                    <Table
                        id="top-table"
                        bordered
                        columns={columns}
                        dataSource={selectedOrderList}
                        scroll={{
                            x: 1200,
                            y: 'calc(100vh - 360px)',
                            scrollToFirstRowOnChange: true,
                        }}
                        locale={{
                            emptyText: 'Chưa có bản ghi nào!',
                        }}
                        pagination={false}
                    />
                    <Row justify="end" style={{ marginTop: 20, paddingTop: 10, borderTop: '1px solid #f0f0f0' }}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Lưu
                            </Button>
                        </Form.Item>
                    </Row>
                </CustomForm>
            </Spin>
        </Modal>
    )
}

const CustomForm = styled(Form)`
    .ant-form-item {
        margin-bottom: 0;
    }
`

const CustomRow = styled(Row)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .text {
        margin: 0;
        padding-right: 16px;
    }

    .select {
        width: 350px;
    }
`

export default AssignOrderForInventorStaff
