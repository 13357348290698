import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { WebSocket } from 'services/WebSocket'
import { useHistory } from 'react-router-dom'
import {
  POST_EVENT,
  ResponseSocketModel,
  withPostChannel,
} from 'utils/socketConstants'
import { CommentItem, DataPostInteface } from '../InterfaceComPost'
import _ from 'lodash'

import {
  requestGetListComment,
  requestLoadMoreSubComment,
} from '../CommunityPostApi'

export default function useWsListComment(
  parent_id?: number,
  post_id?: number,
  dataPost?: DataPostInteface | null,
  setDataPost?: any
) {
  const history = useHistory()
  const { userInfo: UserInstance } = useSelector(
    (state: any) => state.authReducer
  )
  const [isLoadingListComment, setLoadingListComment] = useState<boolean>(false)
  const [listComment, setListComment] = useState<Array<CommentItem>>([])

  const [param, setParam] = useState<any>({
    page: 1,
    limit: 5,
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 0,
    pageSize: 0,
  })

  useEffect(() => {
    getListComment()
  }, [post_id, param])

  const getListComment = async () => {
    try {
      setLoadingListComment(true)
      const res = await requestGetListComment({ ...param, post_id, parent_id })
      const formattedPaging = {
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      }
      setPaging(formattedPaging)

      const filteredData = res.data.map((item: CommentItem) => {
        item.Comments?.reverse()
        return item
      })
      setListComment([...listComment, ...filteredData])
    } catch (error) {
      console.log(error)
      history.goBack()
    } finally {
      setLoadingListComment(false)
    }
  }

  useEffect(() => {
    if (post_id && setDataPost && UserInstance) {
      initAction()
    }
  }, [post_id, setDataPost, UserInstance])

  const loadMoreSubComment = async (post_id: number, parent_id: number) => {
    if (listComment.length > 0) {
      try {
        const res = await requestGetListComment({ post_id, parent_id })
        res.data.reverse()
        const foundIndexComment = listComment.findIndex(
          (item: CommentItem) => item.id === parent_id
        )
        const listSubComment: Array<CommentItem> =
          listComment[foundIndexComment]?.Comments || []
        const newListSubComment = [...res.data, ...listSubComment]
        listComment[foundIndexComment].Comments = newListSubComment
        newListSubComment.splice(
          newListSubComment.length - 2,
          newListSubComment.length
        )
        setListComment([...listComment])
      } catch (error) {}
    }
  }

  const initAction = () => {
    WebSocket.socketClient?.on(
      withPostChannel(post_id),
      (res: ResponseSocketModel) => {
        switch (res.type_action) {
          case POST_EVENT.REACTION_POST:
            onActionReactionPost(res.data)
            break
          case POST_EVENT.UNREACTION_POST:
            onActionUnReactionPost(res.data)
            break
          case POST_EVENT.REACTION_COMMENT_POST:
            onActionReactionCommentPost(res.data)
            break
          case POST_EVENT.UNREACTION_COMMENT_POST:
            onActionUnReactionCommentPost(res.data)
            break
          case POST_EVENT.COMMENT:
            onActionComment(res.data)
            break
          case POST_EVENT.DELETE_COMMENT:
            onActionDeleteComment(res.data)
            break
        }
      }
    )
  }
  const onActionReactionPost = (data: any) => {
    if (setDataPost) {
      setDataPost((prevState: any) => {
        prevState.count_like = data?.count_like
        if (UserInstance.id === data?.user_id) {
          prevState.is_reaction = 1
        }
        return { ...prevState }
      })
    }
  }
  const onActionUnReactionPost = (data: any) => {
    if (dataPost) {
      setDataPost((prevState: any) => {
        prevState.count_like = data.count_like
        if (UserInstance.id === data.user_id) {
          dataPost.is_reaction = 0
        }
        return { ...prevState }
      })
    }
  }
  const onActionReactionCommentPost = (data: any) => {
    setListComment((prevState: Array<any>) => {
      if (!data.parent_id) {
        const foundIndexComment = prevState.findIndex(
          (item: CommentItem) => item.id === data.comment_id
        )
        if (foundIndexComment >= 0) {
          prevState[foundIndexComment].count_like = data.count_like
          if (UserInstance.id === data.user_id && data.id) {
            prevState[foundIndexComment].is_reaction = 1
          }
          //  else {
          //   prevState[foundIndexComment].is_reaction = 0
          // }
        }
        return [...prevState]
      } else {
        const foundIndexParentComment = prevState.findIndex(
          (item: CommentItem) => item.id === data.parent_id
        )
        if (foundIndexParentComment >= 0) {
          const foundIndexSubComment = prevState[
            foundIndexParentComment
          ].Comments.findIndex(
            (item: CommentItem) => item.id === data.comment_id
          )
          if (foundIndexSubComment >= 0) {
            prevState[foundIndexParentComment].Comments[
              foundIndexSubComment
            ].count_like = data.count_like
            if (UserInstance.id === data.user_id && data.id) {
              prevState[foundIndexParentComment].Comments[
                foundIndexSubComment
              ].is_reaction = 1
            }
            //  else {
            //   prevState[foundIndexParentComment].Comments[
            //     foundIndexSubComment
            //   ].is_reaction = 0
            // }
          }
        }
        return [...prevState]
      }
    })
  }
  const onActionUnReactionCommentPost = (data: any) => {
    setListComment((prevState: Array<any>) => {
      if (!data.parent_id) {
        const foundIndexComment = prevState.findIndex(
          (item: CommentItem) => item.id === data.comment_id
        )
        if (foundIndexComment >= 0) {
          prevState[foundIndexComment].count_like = data.count_like
          if (UserInstance.id === data.user_id && !data.id) {
            prevState[foundIndexComment].is_reaction = 0
          }
          //  else {
          //   prevState[foundIndexComment].is_reaction = 0
          // }
        }
        return [...prevState]
      } else {
        const foundIndexParentComment = prevState.findIndex(
          (item: CommentItem) => item.id === data.parent_id
        )
        if (foundIndexParentComment >= 0) {
          const foundIndexSubComment = prevState[
            foundIndexParentComment
          ].Comments.findIndex(
            (item: CommentItem) => item.id === data.comment_id
          )
          if (foundIndexSubComment >= 0) {
            prevState[foundIndexParentComment].Comments[
              foundIndexSubComment
            ].count_like = data.count_like
            if (UserInstance.id === data.user_id && !data.id) {
              prevState[foundIndexParentComment].Comments[
                foundIndexSubComment
              ].is_reaction = 0
            }
            //  else {
            //   listComprevStatement[foundIndexParentComment].Comments[
            //     foundIndexSubComment
            //   ].is_reaction = 0
            // }
          }
        }
        return [...prevState]
      }
    })
  }
  const onActionComment = (data: any) => {
    setListComment((prevState: Array<any>) => {
      if (!data.parent_id) return [data, ...prevState]
      const foundIndexComment = prevState.findIndex(
        (item: CommentItem) => item.id === data.parent_id
      )
      if (foundIndexComment >= 0) {
        if (_.isArray(prevState[foundIndexComment].Comments))
          prevState[foundIndexComment].Comments.push(data)
        else prevState[foundIndexComment].Comments = [data]
      }
      return [...prevState]
    })

    setDataPost((prevState: any) => ({
      ...prevState,
      count_comment: dataPost?.count_comment ? dataPost?.count_comment + 1 : 1,
    }))
  }
  const onActionDeleteComment = (data: any) => {
    console.log('DELETE_COMMENT', data)
  }

  return {
    isLoadingListComment,
    setLoadingListComment,
    listComment,
    setListComment,
    param,
    setParam,
    paging,
    setPaging,
    loadMoreSubComment,
  }
}
