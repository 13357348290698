import {
  Affix,
  Button,
  message,
  PageHeader,
  Space,
  Table,
  Typography,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { formatPrice, number } from 'utils/ruleForm'
import { Filter } from './components/Filter'
import 'antd/dist/antd.css'
import { getLiveReport } from './LiveReportApi'
import moment from 'moment'
import { msToTime } from 'utils/funcHelper'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useHistory } from 'react-router-dom'
import ExportCsv from 'utils/ExportCSV'
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

export default function ShopReport() {
  const heightWeb = useSelector(
    (state: any) => state.configReducer.dimension.height
  )
  const [isLoading, setIsLoading] = useState(false)
  const [listLiveReport, setListLiveReport] = useState([
    {
      key: 1,
      id: 0,
      // name: '',
      // total_livestream: 0,
      // count_reaction: 0,
      // count_comment: 0,
      // count_viewed: 0,
      // livestream_enable: 1,
      // avg_time_live: '',
      // status: 0,
    },
  ])
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const [params, setParams] = useState<any>({
    search: '',
    df_type_user_id: '',
    page: 1,
    limit: 24,
    status: null,
  })

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const [pagings, setPagings] = useState({
    pageSize: 1,
  })
  const [itemSelected, setItemSelected] = useState({ id: 0, rowIndex: 0 })
  const history = useHistory()

  const getData = async () => {
    setIsLoading(true)
    try {
      const res = await getLiveReport(params)
      console.log(res.data)
      const tableData = res.data.map((item: any, index: number) => {
        return { ...item, key: item.id, stt: index + 1 }
      })
      const formattedPaging = {
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      }
      setListLiveReport(tableData)
      setPaging(formattedPaging)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      setIsSearchLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [params])

  const { Text } = Typography

  const columns = [
    {
      width: 70,
      title: 'Stt',
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any, index: any) => (
        <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </td>
      ),
      ellipsis: true,
    },
    {
      title: 'Tên gian hàng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      width: '10%',
      title: 'Số live stream',
      dataIndex: 'total_livestream',
      key: 'total_livestream',
    },
    {
      title: 'Tổng thời lượng',
      dataIndex: 'total_time_live',
      key: 'total_time_live',
      render: (value: any) => (
        <td>{value == null ? '0' : formatPrice(value)}</td>
      ),
    },
    {
      title: 'Thời lượng trung bình',
      dataIndex: 'avg_time_live',
      key: 'avg_time_live',
    },
    {
      width: '10%',
      title: 'Lượt thích',
      dataIndex: 'count_reaction',
      key: 'count_reaction',
      render: (value: any) => (
        <td>{value == null ? '0' : value == 0 ? '0' : formatPrice(value)}</td>
      ),
    },
    {
      width: '10%',
      title: 'Lượt bình luận',
      dataIndex: 'count_comment',
      key: 'count_comment',
      render: (value: any) => (
        <td>{value == null ? '0' : value == 0 ? '0' : formatPrice(value)}</td>
      ),
    },
    {
      width: '10%',
      title: 'Lượt xem',
      dataIndex: 'count_viewed',
      key: 'count_viewed',
      render: (value: any) => (
        <td>{value == null ? '0' : value == 0 ? '0' : formatPrice(value)}</td>
      ),
    },
  ]

  const [isLoadingBtnExportData, setLoadingBtnExportData] = useState<boolean>(
    false
  )
  const [dataExport, setDataExport] = useState<any>([])
  // const [loadingExport, setLoadingExport] = useState<boolean>(false)
  const onExportDataToExcel = async (fn: any) => {
    // setLoadingBtnExportData(true)
    try {
      setLoadingBtnExportData(true)
      const dataListReportNotPagging: any = await getLiveReport(params)
      const dataExport = dataListReportNotPagging?.data?.map(
        (o: any, i: number) => {
          // Tên, Ngày Live, Thời gian, lượt thích bình luận
          return {
            STT: String(i + 1),
            'Tên gian hàng': o.name || '-',
            'Số live stream': String(o.total_livestream) || '0',
            'Tổng thời lượng': String(o.total_time_live) || '0',
            'Thời lượng trung bình': String(o.avg_time_live) || '0',
            'Lượt thích':
              o.count_reaction == null
                ? '0'
                : o.count_reaction == 0
                ? '0'
                : String(o.count_reaction),
            'Lượt bình luận':
              o.count_comment == null
                ? '0'
                : o.count_comment == 0
                ? '0'
                : String(o.count_comment),
            'Lượt xem':
              o.count_viewed == null
                ? '0'
                : o.count_viewed == 0
                ? '0'
                : String(o.count_viewed),
          }
        }
      )
      // const data: any = JSON.parse(JSON.stringify(dataExport))
      // const fileName: string = 'Danh sách sản phẩm'
      // const exportType: any = 'csv'
      // const fields: any = {}
      // exportFromJSON({ data, fileName, exportType, fields })
      setDataExport(dataExport)
      fn()
    } catch (error) {
      message.error(
        'Đã có lỗi trong quá trình export dữ liệu, vui lòng thử lại sau `!'
      )
    } finally {
      // setLoadingBtnExportData(false)
      setLoadingBtnExportData(false)
    }
  }

  return (
    <div>
      <div style={{ backgroundColor: 'white', margin: '5px 10px 15px' }}>
        <PageHeader
          title="Báo cáo livestream"
          extra={[
            <Space>
              <ExportCsv
                loading={isLoadingBtnExportData}
                onClick={fn => onExportDataToExcel(fn)}
                sheetName={['LiveStreamReport']}
                sheets={{
                  LiveStreamReport: ExportCsv.getSheets(dataExport),
                }}
                fileName="Báo cáo Livestream"
              >
                <DownloadOutlined />
                &nbsp; Export
              </ExportCsv>
            </Space>,
          ]}
        />
      </div>

      <div
        style={{
          backgroundColor: 'white',
          margin: '0px 10px 15px',
          padding: '10px 20px',
        }}
      >
        <Filter
          isSearchLoading={isSearchLoading}
          onSearchSubmit={(searchKey: string) => {
            setIsSearchLoading(true)
            setParams({ ...params, search: searchKey, page: 1 })
          }}
          onDateSubmit={(from_date: string, to_date: string) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
              page: 1,
            })
          }}
        />
      </div>

      <div
        style={{
          backgroundColor: 'white',
          margin: '0px 10px 15px',
          padding: '10px 20px',
        }}
      >
        <p>
          Kết quả lọc: <b>{formatPrice(paging.total)}</b>
        </p>
        `
        <Table
          className="table-expanded-custom-no-image"
          loading={isLoading}
          columns={columns}
          dataSource={listLiveReport}
          bordered
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            // y: 'calc(100vh - 300px)',
            y: `calc(${heightWeb}px - 440px)`,
          }}
          onRow={(record, rowIndex) => ({
            onClick: () => {
              history.push({
                // pathname: `${ADMIN_ROUTER_PATH.LIVE_STREAM_DETAIL}/${record.id}`,
                state: record,
              })
            },
            onMouseEnter: event => {
              setItemSelected({ id: record.id, rowIndex: Number(rowIndex) })
            },
          })}
          pagination={{
            ...paging,
            showSizeChanger: false,
            onChange: async (page, pageSize) => {
              setParams({ ...params, page })
            },
          }}
        />
      </div>
    </div>
  )
}
