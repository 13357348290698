import { ApiClient } from 'services/ApiService'
import { ICategoryPayload, IDeleteCatePayload, ICategory, IUpdateCatePayload, ResponseData } from './CategoryInterfaces'

export const getCategory = (payload: any): Promise<ResponseData<ICategory[]>> =>
    ApiClient.get('/admin/product_category', payload)

export const changeIndexOrder = (payload: IUpdateCatePayload): Promise<ResponseData<null>> => {
    return ApiClient.path(`/admin/product_category/${payload.id}/re_order`, payload.data)
}

export const activeCategory = (id: string): Promise<ResponseData<null>> =>
    ApiClient.path(`/admin/product_category/${id}/active`)

export const inactiveCategory = (id: string): Promise<ResponseData<null>> =>
    ApiClient.path(`/admin/product_category/${id}/inactive`)

// export const requestUploadImageCategory = (
//   payload: IChangeImagePayload
// ): Promise<ResponseData<null>> =>
//   ApiClient.post(`/files/upload/single/${payload.type}`, payload.data)

export const syncCategory = (id: number): Promise<ResponseData<{ type: string; id: number }>> => {
    return ApiClient.post(`/admin/kiotviet/${id}/sync/category`, {})
}
