import { IDataOrders, IOrderPayload } from 'features/admin/order-clone/Interfaces'
import { ApiClient } from 'services/ApiService'
import {
    DetailProductsPayload,
    IAllCate,
    IDataDetailproduct,
    IEditProduct,
    IListProduct,
    IListProductPayload,
    ListShopCatePayload,
    ResponseData,
    StatusProductPayload,
} from './ProductInterfaces'

// //Category
export const getCategory = (payload: any) => {
    return ApiClient.get('/admin/product_category', payload)
}

export const getOrder = (payload: IOrderPayload): Promise<ResponseData<IDataOrders[]>> => {
    return ApiClient.get('/admin/order', payload)
}

//Product
export const listProducts = (payload: any): Promise<ResponseData<IListProduct[]>> => {
    return ApiClient.get('/admin/product', payload)
}
export const exportExcel = (payload: any) =>
    ApiClient.post(
        `/admin/product/export_to_excel?search=${payload?.search}&status=${payload?.status}&kiotviet_id=${payload?.kiotviet_id}&category_id=${payload?.category_id}`
    )

export const activeCategory = (id: string): Promise<ResponseData<null>> =>
    ApiClient.path(`/admin/product_category/${id}/active`)

export const inactiveCategory = (id: string): Promise<ResponseData<null>> =>
    ApiClient.path(`/admin/product_category/${id}/inactive`)

export const allProducts = (payload: any) => {
    const paramExport: any = {}
    paramExport.category_id = parseInt(payload.category_id) || 0
    if (paramExport.category_id) {
        paramExport.children_category_id = parseInt(payload.children_category_id) || 0
    } else {
        paramExport.children_category_id = 0
    }
    if (payload.search) {
        paramExport.search = payload.search
    }
    if (payload.status) {
        paramExport.status = parseInt(payload.status)
    }
    if (payload.shop_id) {
        paramExport.shop_id = parseInt(payload.shop_id)
        if (payload.stock_id) {
            paramExport.stock_id = parseInt(payload.stock_id)
        }
    }
    return ApiClient.get('/admin/product/all', paramExport)
}

export const detailProducts = (id: string): Promise<ResponseData<IDataDetailproduct>> =>
    ApiClient.get(`/admin/product/${id}`)

export const getAllCate = (): Promise<ResponseData<IAllCate[]>> => ApiClient.get(`/admin/product_category/list`)

export const editProduct = (payload: IEditProduct) => ApiClient.path(`/admin/product/${payload.id}`, payload.body)

export const deleteProducts = (payload: any) => {
    return ApiClient.delete('/product', payload)
}
export const changeStatusProduct = (payload: StatusProductPayload) =>
    ApiClient.put(`/product/change-status/${payload.id}`, payload.body)

export const syncProduct = (payload: { id: string }) => ApiClient.post(`/admin/product/${payload.id}/sync`)

export const syncAllProduct = (kiotVietId: string) => ApiClient.post(`admin/kiotviet/${kiotVietId}/sync/product`)

export const updateMultipleProductsPrice = (payload: any) => ApiClient.put(`admin/product/price`, payload)
export const updateAllProductsPrice = (payload: any, params: any) =>
    ApiClient.put(
        `admin/product/update-price-all?category_id=${params?.category_id}&search=${params?.search}&status=${params?.status}&kiotviet_id=${params?.kiotviet_id}`,
        payload
    )

export const getCompletePriceJob = (payload: any) => {
    return ApiClient.get('admin/product/getCompletedPriceJob', payload)
}

export const getFailedPriceJob = (payload: any) => {
    return ApiClient.get('admin/product/getFailedPriceJob', payload)
}

export const retryUpdateJob = (payload: any) => {
    return ApiClient.post('admin/product/retry_job', payload)
}
