import { DatePicker } from 'antd'
import moment from 'moment'
const { RangePicker } = DatePicker

type HeaderProps = {
    fromDaytoDay: any
    dateOnSubmit: (from_date: string, to_date: string) => void
}

export const Header = ({ fromDaytoDay, dateOnSubmit }: HeaderProps) => {
    return (
        <RangePicker
            style={{ width: '300px' }}
            placeholder={['Từ ngày', 'Đến ngày']}
            className="rangerpicker-order"
            defaultValue={[moment(fromDaytoDay[0], 'YYYY-MM-DD'), moment(fromDaytoDay[1], 'YYYY-MM-DD')]}
            onChange={async (value, dateString) => {
                dateOnSubmit(dateString[0], dateString[1])
            }}
        />
    )
}
