import { ApiClient } from 'services/ApiService'

export const getTopic = (payload: any) => 
  ApiClient.get('admin/topic', payload)
export const createTopic = (payload: any) =>
  ApiClient.post('admin/topic', payload)
export const updateTopic = (payload: any) =>
  ApiClient.put(`admin/topic/${payload.id}`, payload.data)
export const changeStatusTopic = (id: number) =>
  ApiClient.put(`admin/topic/${id}/status`)
export const deleteTopic = (payload: any) =>
  ApiClient.delete('admin/topic', payload)
export const topicDetail = (id: number) => 
  ApiClient.get(`admin/topic/${id}`)
