import { Col, Row } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { useState, useEffect, memo } from 'react'
import { DatePicker } from 'antd'
import styled from 'styled-components'
const { RangePicker } = DatePicker

interface FilterChildrenCateProps {
  onSubmitKey: (value: string) => void
  onSubmitRangetime: (value: Array<string>) => void
}

const WapperFilter = styled.div`
  margin: 10px 0 12px 0;  
`

function FilterChildrenCate(props: FilterChildrenCateProps) {
  const [isLoadingSearch, setisLoadingSearch] = useState<boolean>(false)

  const handleSelectRangeTime = (moment: any, rangeTime: Array<string>) => {
    console.log(rangeTime)
    props.onSubmitRangetime(rangeTime)
  }

  return (
    <>
      <WapperFilter>
        <Row justify="start" gutter={[16, 16]}>
          <Col span={6}>
            <TypingAutoSearch
              onSearchSubmit={(value: string) =>
                props.onSubmitKey(value.trim())
              }
              isSearchLoading={isLoadingSearch}
              placeholder={'Tên danh mục...'}
            />
          </Col>
          <Col span={12}>
            <RangePicker onChange={handleSelectRangeTime} />
          </Col>
        </Row>
      </WapperFilter>
    </>
  )
}

export default memo(FilterChildrenCate)
