import { Checkbox, Form, InputNumber, Modal, Spin, message } from 'antd'
import ButtonForm from 'common/components/ModalButton'
import React from 'react'
import { applyDebt } from '../CustomerAPI'

interface IApplyDebtModal {
    visible: boolean
    setVisible: any
    loading?: boolean
    setIsLoading?: any
    getData: any
    cusId: any
    customerDetail: any
}

const ApplyDebtModal = (props: IApplyDebtModal) => {
    const { visible, setVisible, getData, cusId, customerDetail } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [isSelected, setIsSelected] = React.useState<boolean>(customerDetail?.is_apply_debit)

    const handleApplyDebt = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                max_debit_time: values.debt_order_time,
                max_debit: values.debt_limit,
                is_apply_debit: isSelected ? 1 : 0,
            }
            const res: any = await applyDebt(cusId, payload)
            if (res?.status) {
                message.success(isSelected ? 'Cập nhật công nợ thành công!' : 'Đã lưu lại bản ghi hạn mức!')
                setVisible(false)
                getData()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }
    return (
        <Modal
            title="Áp dụng công nợ"
            destroyOnClose
            maskClosable={false}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <Spin spinning={loading}>
                <Form
                    preserve={false}
                    onFinish={handleApplyDebt}
                    form={form}
                    initialValues={{
                        debt_order_time: customerDetail?.max_debit_time,
                        debt_limit: customerDetail?.max_debit,
                    }}
                >
                    <Form.Item name="is_apply_debt">
                        <Checkbox checked={isSelected} onChange={(e: any) => setIsSelected(e.target.checked)}>
                            Áp dụng công nợ
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="debt_limit"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập hạn mức công nợ!',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder="Nhập hạn mức công nợ"
                            min={1}
                            addonAfter="VNĐ"
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="debt_order_time"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập thời gian công nợ đơn hàng!',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder="Nhập thời gian công nợ đơn hàng"
                            min={1}
                            addonAfter="Ngày"
                        />
                    </Form.Item>
                    <ButtonForm isLoadingButton={loading} onCancel={() => setVisible(false)} text={'Lưu'} />
                </Form>
            </Spin>
        </Modal>
    )
}

export default ApplyDebtModal
