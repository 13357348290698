import { LineChartOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Button, PageHeader, Radio, Row, Select, Spin, Table, message } from 'antd'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import ButtonSave from 'common/components/Button/ButtonSave'
import Container from 'container/Container'
import React from 'react'
import styled from 'styled-components'
import { formatPrice } from 'utils/ruleForm'
import Filter from '../components/Filter'
import {
    exportExcelByBranch,
    getCountDataByBranch,
    getListBranchs,
    getListCategories,
    getReportList,
} from '../services'
import { useDebounce } from 'common/hooks/Debounce'
import moment from 'moment'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { toNonAccentVietnamese } from 'utils/Configs'
import { syncAllProduct } from 'features/admin/product_clone/Products/ProductAPI'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

const BranchReport = () => {
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN
    const [count, setCount] = React.useState<number>(0)
    const [search, setSearch] = React.useState<string>('')
    const searchDebounce = useDebounce(search, 300)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any[]>([])
    const [branchs, setBranchs] = React.useState<any[]>([])
    const [currentKiotVietID, setCurrentKiotVietID] = React.useState<any>(
        isShopAdmin ? userInfor?.admin?.kiotviet_id : ''
    )
    const [categoriesList, setCategoriesList] = React.useState<any[]>([])
    const [currentBranchId, setCurrentBranchId] = React.useState<any>()
    const [currentBrandName, setCurrentBrandName] = React.useState<string>()
    const [fromDate, setFromDate] = React.useState<string>(moment().subtract(7, 'day').format('YYYY-MM-DD'))
    const [toDate, setToDate] = React.useState<string>(moment().subtract(1, 'day').format('YYYY-MM-DD'))
    const [productCategory, setProductCategory] = React.useState<number>()
    const [isOverInventory, setIsOverInventory] = React.useState<string>()
    const [listKiotviet, setListKiotviet] = React.useState<IKiotviet[]>([])
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Sản phẩm</b>,
            dataIndex: 'name',
            render: (value: string, record: any) => {
                if (Number(record?.selected_branch_stock) / Number(record?.average_sale) > 1.2) {
                    return <span style={{ color: 'red' }}>{value}</span>
                } else {
                    return value
                }
            },
        },
        {
            title: <b>Nhóm hàng</b>,
            dataIndex: 'category_name',
        },
        {
            title: <b>Tồn khu vực</b>,
            dataIndex: 'stock',
            render: (value: number) => formatPrice(value),
        },
        {
            title: <b>Tồn trung tâm</b>,
            dataIndex: 'center_branch_stock',
            render: (value: number) => formatPrice(value),
        },
        {
            title: <b>Tồn kho {currentBrandName}</b>,
            dataIndex: 'selected_branch_stock',
            render: (value: number) => formatPrice(value),
        },
        {
            title: <b>Chênh lệch</b>,
            dataIndex: 'diference',
            render: (_: number, record: any) => {
                const value = Number(record?.selected_branch_stock) - Number(record?.center_branch_stock)
                if (value < 0) {
                    return <span style={{ color: 'red' }}>{value}</span>
                } else {
                    return formatPrice(value)
                }
            },
        },
        {
            title: <b>Chênh lệch vượt tồn</b>,
            dataIndex: 'diference',
            render: (_: number, record: any) => {
                const value = Number(record?.selected_branch_stock) - Number(record?.average_sale)
                if (value < 0) {
                    return <span style={{ color: 'red' }}>{value}</span>
                } else {
                    return formatPrice(value)
                }
            },
        },
        {
            title: <b>SL bán trung bình</b>,
            dataIndex: 'average_sale',
            render: (value: number) => formatPrice(value),
        },
        {
            title: <b>Gợi ý cân hàng</b>,
            dataIndex: 'suggest_import',
            render: (value: number) => {
                if (value < 0) {
                    return <span style={{ color: 'red' }}>{value}</span>
                } else {
                    return formatPrice(value)
                }
            },
        },
    ]

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res?.data) {
                setListKiotviet(res?.data)
                setCurrentKiotVietID(res?.data?.[0]?.id)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getDataCount = async () => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: currentKiotVietID,
                branch_id: currentBranchId,
                page: paging.current,
                limit: paging.pageSize,
                from_date: fromDate,
                to_date: toDate,
                category_id: productCategory,
                search: searchDebounce.trim(),
                is_over_inventory: isOverInventory,
            }
            const res = await getCountDataByBranch(payload)
            if (res?.status) {
                setCount(res?.data?.count)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getCategories = async () => {
        try {
            const payload = {
                page: 1,
                limit: 999,
                status: '1',
                kiotviet_id: currentKiotVietID,
            }
            const res = await getListCategories(payload)
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setCategoriesList(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getBranchsByKiotviet = async () => {
        try {
            const payload = {
                kiotviet_id: currentKiotVietID,
            }
            const res = await getListBranchs(payload)
            if (res?.data) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setBranchs(data)
                setCurrentBranchId(data?.[0]?.value)
                setCurrentBrandName(data?.[0].name)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getData = async () => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: currentKiotVietID,
                branch_id: currentBranchId,
                page: paging.current,
                limit: paging.pageSize,
                from_date: fromDate,
                to_date: toDate,
                category_id: productCategory,
                search: searchDebounce.trim(),
                is_over_inventory: isOverInventory,
            }
            const res = await getReportList(payload)
            if (res?.status) {
                setData(res?.data)
                const newPaging = {
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const syncProduct = async () => {
        try {
            await syncAllProduct(currentKiotVietID.toString())
            message.success('Đang đồng bộ vui lòng chờ một vài phút')
        } catch (error) {
            message.error('Đã có lỗi xảy ra vui lòng chờ một vài phút và thử lại')
        } finally {
        }
    }

    const exportExcel = async () => {
        try {
            const payload = {
                kiotviet_id: currentKiotVietID,
                branch_id: currentBranchId,
                from_date: fromDate,
                to_date: toDate,
                category_id: productCategory,
                search: searchDebounce.trim(),
                is_over_inventory: isOverInventory,
            }
            const res = await exportExcelByBranch(payload)
            if (res?.status) {
                message.success('Xuất excel file thành công!')
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        if (currentKiotVietID) {
            getBranchsByKiotviet()
        }
    }, [currentKiotVietID])

    React.useEffect(() => {
        getDataKiotviet()
    }, [])

    React.useEffect(() => {
        getCategories()
    }, [currentKiotVietID])

    React.useEffect(() => {
        getDataCount()

        if (paging.current === 1) {
            getData()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [currentBranchId, fromDate, toDate, productCategory, searchDebounce, isOverInventory])

    React.useEffect(() => {
        getData()
    }, [paging.current])

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        style={{ borderRadius: 8 }}
                        title="Báo cáo cân hàng"
                        extra={[
                            <ButtonSave
                                onClickButton={exportExcel}
                                text="Xuất excel"
                                icon={<VerticalAlignBottomOutlined />}
                            />,
                            <ButtonAdd
                                icon={<LineChartOutlined />}
                                onClickButton={syncProduct}
                                text="Cập nhật báo cáo"
                            />,
                        ]}
                    ></PageHeader>
                }
                filterComponent={
                    <Filter
                        setFromDate={setFromDate}
                        setToDate={setToDate}
                        search={search}
                        setSearch={setSearch}
                        categoriesList={categoriesList}
                        setProductCategory={setProductCategory}
                        setIsOverInventory={setIsOverInventory}
                    />
                }
                contentComponent={[
                    <>
                        <CustomRow>
                            {currentKiotVietID && (
                                <Radio.Group
                                    defaultValue={currentKiotVietID}
                                    onChange={(e: any) => {
                                        setCurrentKiotVietID(e.target.value)
                                    }}
                                >
                                    {listKiotviet &&
                                        listKiotviet.map((item, index) => (
                                            <Radio.Button
                                                key={index}
                                                value={item.id}
                                                disabled={isShopAdmin && item.id !== userInfor?.admin?.kiotviet_id}
                                            >
                                                {item.name ? item.name : '--'}
                                            </Radio.Button>
                                        ))}
                                </Radio.Group>
                            )}

                            <Select
                                allowClear
                                className="select"
                                placeholder="Chọn chi nhánh"
                                onChange={(value: any, option: any) => {
                                    setCurrentBranchId(value)
                                    setCurrentBrandName(option?.label)
                                }}
                                value={currentBranchId}
                                options={branchs}
                                showArrow
                                showSearch
                                filterOption={(input, option: any) => {
                                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                    return nonAccent.includes(nonAccentInput)
                                }}
                            />
                        </CustomRow>
                        <p>
                            Kết quả lọc: <b>{count}</b>
                        </p>
                        {currentBrandName && (
                            <CustomTable
                                id="top-table"
                                bordered
                                columns={columns}
                                dataSource={data}
                                scroll={{
                                    x: 800,
                                    scrollToFirstRowOnChange: true,
                                }}
                                locale={{
                                    emptyText: 'Chưa có bản ghi nào!',
                                }}
                                pagination={{
                                    ...paging,
                                    showSizeChanger: false,
                                    onChange: page => {
                                        setPaging({ ...paging, current: page })
                                    },
                                }}
                            />
                        )}
                    </>,
                ]}
            />
        </Spin>
    )
}

const CustomRow = styled(Row)`
    margin-bottom: 20px;

    .text {
        font-size: 12px;
        margin: 0;
    }

    .select {
        margin-left: 16px;
        width: 200px;
    }
`

const CustomTable = styled(Table)`
    margin-top: 20px;
`

export default BranchReport
