import { Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useEffect, useState, memo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { formatPrice } from 'utils/ruleForm'
import { detailProducts, getOrder } from '../ProductAPI'
import { IFormateOrder } from '../ProductInterfaces'
const { Text } = Typography

interface IProps {
    dataProduct: any
}

function Orders(props: IProps) {
    const history = useHistory()
    const [params, setparams] = useState({
        search: '',
        page: '',
        limit: '',
        product_id: props.dataProduct?.id,
        status: undefined,
        kioviet_id: '',
        user_id: '',
        create_from: '',
        create_to: '',
    })
    const [listOrder, setlistOrder] = useState<IFormateOrder[]>()

    const getDataDetail = async () => {
        const res = await getOrder(params)
        if (res.data) {
            const tableOrderByProduct = res.data.map((order, index) => {
                return { ...order, key: index, stt: index + 1 }
            })
            setlistOrder(tableOrderByProduct)
        }
    }

    useEffect(() => {
        getDataDetail()
    }, [])

    const columns: ColumnsType<IFormateOrder> = [
        {
            title: 'STT',
            dataIndex: 'stt',
        },
        {
            title: 'Mã đơn',
            dataIndex: 'code',
        },
        {
            title: 'Tên khách hàng',
            dataIndex: '',
            render: data => {
                return <Text>{data.user.full_name}</Text>
            },
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity_product',
        },
        {
            title: 'Tổng cộng',
            dataIndex: 'total_payment',
            render: total_payment => {
                return <Text>{total_payment ? `${formatPrice(total_payment)} đ` : '0 đ'}</Text>
            },
        },
    ]

    return (
        <>
            <Table
                dataSource={listOrder}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                    y: 'calc(100vh - 340px)',
                }}
                columns={columns}
                onRow={(record, rowIndex) => ({
                    onClick: () => {
                        history.push({
                            pathname: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${record.id}`,
                            state: {
                                id: record.id,
                                path: `${ADMIN_ROUTER_PATH.PRODUCT_DETAIL}/${props.dataProduct?.id}`,
                            },
                        })
                    },
                })}
            />
        </>
    )
}

export default memo(Orders)
