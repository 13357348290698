const images = {
  img_background: require('../assets/images/img_background.jpg'),
  img_logo: require('../assets/images/img_logo.png').default,
  img_ogo: require('../assets/images/app_logo.jpg').default,
  img_logo_blog: require('../assets/images/logo_blog.png').default,
  img_logo_google_play: require('../assets/images/download-google-play.png')
    .default,
  img_logo_ios: require('../assets/images/download-ios.png').default,
}
export default images
