import { Form, Input, message, Modal } from 'antd'
import ButtonForm from 'common/components/ModalButton'
import { useState, useEffect } from 'react'
import { changePassword } from '../CustomerAPI'
import { IFormateDataCustomers } from '../CustomerInterfaces'

interface IProps {
    cancel: () => void
    visible: boolean
    data?: IFormateDataCustomers
}

function ChangePasswordModal(props: IProps) {
    const [form] = Form.useForm()
    const [isLoading, setisLoading] = useState<boolean>(false)

    const handleFinish = async (values: any) => {
        setisLoading(true)
        try {
            if (props.data?.id) {
                const res = await changePassword({
                    id: props.data.id,
                    password: values.password,
                })
                if (res.data) {
                    message.success('Đặt mật khẩu thành công')
                    props.cancel()
                }
            }
        } catch (err) {
            console.log(err)
            message.error('Có lỗi xảy ra')
        } finally {
            setisLoading(false)
        }
    }

    return (
        <Modal
            title="Đổi mật khẩu"
            destroyOnClose
            maskClosable={false}
            visible={props.visible}
            onCancel={() => props.cancel()}
            footer={null}
        >
            <Form preserve={false} onFinish={handleFinish} form={form}>
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: 'Vui lòng nhập mật khẩu' },
                        {
                            min: 6,
                            message: 'Mật khẩu phải trên 6 ký tự',
                        },
                    ]}
                >
                    <Input.Password placeholder="Nhập mật khẩu" />
                </Form.Item>
                <Form.Item
                    name="retype_password"
                    rules={[
                        {
                            required: true,
                            message: 'Nhập lại mật khẩu',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error('Mật khẩu không khớp!'))
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Nhập lại mật khẩu" />
                </Form.Item>
                <ButtonForm isLoadingButton={isLoading} onCancel={() => props.cancel()} text={'Lưu'} />
            </Form>
        </Modal>
    )
}

export default ChangePasswordModal
