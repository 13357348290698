import React from 'react'
import R from 'utils/R'
function EmptyChatArea() {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'slategray',
      }}
    >
      <img style={{ width: '50%' }} src={R.images.img_logo} draggable="false" />
      <p style={{ fontSize: 18, userSelect: 'none' }}>
        Chào mừng đến với chat Hoa Thanh Tước!
      </p>
      <p style={{ fontSize: 14, userSelect: 'none' }}>
        Chọn một cuộc hội thoại để bắt đầu chat!
      </p>
    </div>
  )
}
export default EmptyChatArea
