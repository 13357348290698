import { DeleteOutlined } from '@ant-design/icons'
import { Button, Form, Popconfirm, Row, Select, Table, Typography, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import ButtonSave from 'common/components/Button/ButtonSave'
import { getCustomers } from 'features/admin/customer-clone/CustomerAPI'
import React from 'react'
import styled from 'styled-components'
import { renderVoucherStatus, toNonAccentVietnamese, uuid } from 'utils/Configs'
import { assignNewGift, updateAssignGift } from '../listGiftApi'
import SelectCustomer from './SelectCustomer'
import { useDebounce } from 'common/hooks/Debounce'

const { Paragraph } = Typography

interface IVoucherForCustomer {
    listAssignedGift: any[]
    data: any
    getData: () => Promise<void>
    getListVouchers: () => Promise<void>
}

const VoucherForCustomer = (props: IVoucherForCustomer) => {
    const { listAssignedGift, data, getListVouchers, getData } = props
    const [form] = useForm()
    const [customers, setCustomers] = React.useState<any[]>([])
    const [tableData, setTableData] = React.useState<any[]>([])

    const columns = [
        {
            title: <b>STT</b>,
            width: 70,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => index + 1,
        },
        {
            title: <b>Mã voucher</b>,
            width: 150,
            dataIndex: 'voucherCode',
            render: (value: any, record: any) => {
                return (
                    <CustomParagraph style={{ marginBottom: 0 }} copyable={{ tooltips: false }}>
                        {record.gift_user_code}
                    </CustomParagraph>
                )
            },
        },
        {
            title: <b>Khách hàng</b>,
            dataIndex: 'customer',
            render: (value: any, record: any) => {
                return (
                    <Form.Item
                        name={`data_${record?.id}`}
                        rules={[{ required: true, message: 'Vui lòng chọn khách hàng!' }]}
                    >
                        <SelectCustomer
                            updateAssignVoucher={updateAssignVoucher}
                            record={record}
                            customers={customers}
                            form={form}
                            getDataCustomer={getDataCustomer}
                        />
                    </Form.Item>
                )
            },
        },
        {
            title: <b>Trạng thái</b>,
            width: 200,
            dataIndex: 'status',
            render: (value: any) => renderVoucherStatus(value),
        },
        {
            title: <b>Thao tác</b>,
            width: 100,
            dataIndex: '',
            render: (value: any, record: any) => {
                return (
                    typeof record?.id === 'string' && (
                        <Button onClick={() => removeRow(record?.id)} style={{ color: 'red', border: 'none' }}>
                            <DeleteOutlined />
                        </Button>
                    )
                )
            },
        },
    ]

    const onAddRow = () => {
        setTableData((prev: any) => [
            ...prev,
            {
                id: uuid(),
                voucherCode: '',
                customer: undefined,
                status: 5,
            },
        ])
    }

    const removeRow = (id: any) => {
        const newList = tableData?.filter((item: any) => item?.id !== id)
        setTableData(newList)
    }

    const getDataCustomer = async (search: string) => {
        try {
            const payload = {
                page: 1,
                limit: '20',
                search,
                province_id: '',
                create_from: '',
                create_to: '',
                kiotviet_id: '',
            }
            const res = await getCustomers(payload)
            if (res.data) {
                const dataCustomer = res.data?.map((item: any) => {
                    return {
                        ...item,
                        value: item?.id,
                        label: item?.full_name,
                    }
                })
                setCustomers(dataCustomer)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const onFinish = async (values: any) => {
        const newObj = Object.keys(values)
            .filter(key => key.split('_')[1].includes('-'))
            .reduce((acc: any, key: any) => {
                acc[key] = values[key]
                return acc
            }, {})
        try {
            const payload = {
                user_ids: Object.values(newObj),
                gift_id: data?.id,
            }
            const res = await assignNewGift(payload)
            if (res?.status) {
                message.success('Gán voucher cho khách hàng thành công!')
                getListVouchers()
                getData()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const updateAssignVoucher = async (rowId: number, value: number) => {
        try {
            const payload = {
                user_id: value,
            }
            const res = await updateAssignGift(rowId, payload)
            if (res?.status) {
                message.success('Cập nhật gán voucher cho khách hàng thành công!')
                getListVouchers()
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        setTableData(listAssignedGift)
        const defaultValue = listAssignedGift?.map((item: any) => ({
            [`data_${item?.id}`]: item?.user_id,
        }))
        // Chuyển array thành object -> gán giá trị cho form
        const mergedObject = defaultValue.reduce((result, currentObject) => {
            return { ...result, ...currentObject }
        }, {})
        form.setFieldsValue(mergedObject)
    }, [listAssignedGift])

    return (
        <>
            <CustomForm form={form} onFinish={onFinish}>
                <CustomRow>
                    {tableData?.length < data?.quantity + data?.sold && (
                        <ButtonAdd text="Thêm mới" styleButton={{ marginRight: 20 }} onClickButton={onAddRow} />
                    )}
                    <Form.Item>
                        <Button style={{ background: 'green', color: 'white' }} htmlType="submit">
                            Lưu
                        </Button>
                    </Form.Item>
                </CustomRow>
                <Table
                    id="top-table"
                    style={{ width: '100%' }}
                    bordered
                    columns={columns}
                    dataSource={tableData}
                    scroll={{
                        x: 500,
                        scrollToFirstRowOnChange: true,
                    }}
                    locale={{
                        emptyText: 'Chưa có bản ghi nào!',
                    }}
                    pagination={false}
                />
            </CustomForm>
        </>
    )
}

const CustomParagraph = styled(Paragraph)`
    .ant-typography {
        margin-bottom: 0;
    }
`

const CustomRow = styled(Row)`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
`

const CustomForm = styled(Form)`
    .ant-form-item {
        margin-bottom: 0;
    }
`

export default VoucherForCustomer
