import Icon, { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Col, DatePicker, Input, Row, Select, Table, Tooltip } from 'antd'
import ButtonSave from 'common/components/Button/ButtonSave'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { renderDeliveryStatus, renderOrderStatus } from 'utils/Configs'
import { momentToStringDate } from 'utils/TimerHelper'
import { dateDiffInSeconds, formatTime, renderInventoryStatus } from 'utils/funcHelper'
import { formatPrice } from 'utils/ruleForm'

interface IWrapOrderReport {
    staffDetail: any
    pagingDetail: any
    searchDetail: string
    orderStatus: any
    wrapStatus: any
    receiveOrderFromDate: any
    receiveOrderToDate: any
    completeOrderFromDate: any
    completeOrderToDate: any
    onExportExcelByTab: any
    setPagingDetail: React.Dispatch<any>
    setReceiveOrderFromDate: React.Dispatch<React.SetStateAction<string | undefined>>
    setReceiveOrderToDate: React.Dispatch<React.SetStateAction<string | undefined>>
    setCompleteOrderFromDate: React.Dispatch<React.SetStateAction<string | undefined>>
    setCompleteOrderToDate: React.Dispatch<React.SetStateAction<string | undefined>>
    setWrapStatus: React.Dispatch<React.SetStateAction<number | undefined>>
    setOrderStatus: React.Dispatch<React.SetStateAction<string | undefined>>
    setSearchDetail: React.Dispatch<React.SetStateAction<string>>
}

const WrapOrderReport = (props: IWrapOrderReport) => {
    const {
        staffDetail,
        pagingDetail,
        searchDetail,
        orderStatus,
        wrapStatus,
        onExportExcelByTab,
        setPagingDetail,
        setReceiveOrderFromDate,
        setReceiveOrderToDate,
        setCompleteOrderFromDate,
        setCompleteOrderToDate,
        setWrapStatus,
        setOrderStatus,
        setSearchDetail,
    } = props

    const columns = [
        {
            title: <b>STT</b>,
            width: 100,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) =>
                (pagingDetail.current - 1) * pagingDetail.pageSize + index + 1,
        },
        {
            title: <b>Mã đơn hàng</b>,
            dataIndex: 'orderCode',
            render: (_: any, record: any) => record?.order_code,
        },
        {
            title: <b>Trạng thái đơn</b>,
            dataIndex: 'orderStatus',
            render: (_: number, record: any) => renderOrderStatus(record?.order_status),
        },
        {
            title: <b>Trạng thái kho</b>,
            dataIndex: 'status',
            render: (_: number, record: any) => renderInventoryStatus(record?.status),
        },
        {
            title: <b>Thời gian nhận đơn</b>,
            dataIndex: 'date',
            render: (_: any, record: any) =>
                record?.start_time ? momentToStringDate(record?.start_time, 'dateTime') : '---',
        },
        {
            title: <b>Thời gian hoàn thành</b>,
            dataIndex: 'date',
            render: (_: any, record: any) =>
                record?.end_time ? momentToStringDate(record?.end_time, 'dateTime') : '---',
        },
        {
            title: <b>Tổng thời gian giao đơn</b>,
            dataIndex: 'date',
            render: (_: any, record: any) => (record?.assign_time ? formatTime(record?.assign_time) : '---'),
        },
        {
            title: <b>Thời gian đóng hàng</b>,
            dataIndex: 'date',
            render: (_: any, record: any) =>
                record?.start_time && record?.end_time
                    ? dateDiffInSeconds(record?.start_time, record?.end_time)
                    : '---',
        },
    ]
    return (
        <Container>
            <Row>
                <Row gutter={[16, 16]} className="filter_block">
                    <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
                        <Input.Search
                            allowClear
                            className="search"
                            value={searchDetail}
                            placeholder="Mã đơn hàng"
                            addonAfter={<Icon type="close-circle-o" />}
                            onChange={(e: any) => setSearchDetail(e.target.value)}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
                        <Select
                            allowClear
                            value={orderStatus}
                            style={{ width: '100%' }}
                            placeholder="Trạng thái đơn hàng"
                            onChange={(value: string | undefined) => setOrderStatus(value)}
                        >
                            <Select.Option value={'wait_confirmation'}>Chờ xác nhận</Select.Option>
                            <Select.Option value={'inprogress'}>Đã xác nhận</Select.Option>
                            <Select.Option value={'completed'}>Hoàn thành</Select.Option>
                            <Select.Option value={'cancelled'}>Đã huỷ</Select.Option>
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
                        <Select
                            allowClear
                            value={wrapStatus}
                            style={{ width: '100%' }}
                            placeholder="Trạng thái kho"
                            onChange={(value: number | undefined) => setWrapStatus(value)}
                        >
                            <Select.Option value={0}>Chờ nhận đơn</Select.Option>
                            <Select.Option value={1}>Đang đóng hàng</Select.Option>
                            <Select.Option value={2}>Hoàn thành</Select.Option>
                        </Select>
                    </Col>

                    <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                        <Tooltip placement="top" title={'Ngày nhận đơn'}>
                            <DatePicker.RangePicker
                                style={{ width: '100%' }}
                                placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                format={'DD-MM-YYYY'}
                                // value={[receiveOrderFromDate, receiveOrderToDate]}
                                onChange={(value, dateString) => {
                                    setReceiveOrderFromDate(dateString[0].split('-').reverse().join('-'))
                                    setReceiveOrderToDate(dateString[1].split('-').reverse().join('-'))
                                }}
                            />
                        </Tooltip>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                        <Tooltip placement="top" title={'Ngày hoàn thành'}>
                            <DatePicker.RangePicker
                                style={{ width: '100%' }}
                                placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                format={'DD-MM-YYYY'}
                                // value={[completeOrderFromDate, completeOrderToDate]}
                                onChange={(value, dateString) => {
                                    setCompleteOrderFromDate(dateString[0].split('-').reverse().join('-'))
                                    setCompleteOrderToDate(dateString[1].split('-').reverse().join('-'))
                                }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <div className="button_block">
                    <ButtonSave
                        onClickButton={onExportExcelByTab}
                        text="Xuất excel"
                        icon={<VerticalAlignBottomOutlined />}
                    />
                    ,
                </div>
            </Row>
            <Row justify="space-between" style={{ marginTop: 20 }}>
                <p>
                    Tổng đơn:{' '}
                    <span>
                        <b>{staffDetail?.progress}</b>
                    </span>
                </p>
                <p>
                    Thời gian đóng đơn trung bình:{' '}
                    <span>
                        <b>{formatTime(Number(staffDetail?.average_packaging_time))}</b>
                    </span>
                </p>
            </Row>
            <Table
                bordered
                columns={columns}
                dataSource={staffDetail?.data}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: 'Chưa có bản ghi nào!',
                }}
                pagination={{
                    ...pagingDetail,
                    showSizeChanger: false,
                    onChange: page => {
                        setPagingDetail({ ...pagingDetail, current: page })
                    },
                }}
            />
        </Container>
    )
}

const Container = styled.div`
    flex: 1;

    .filter_block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 2;
    }

    .button_block {
        /* flex: 1; */
        text-align: right;
        margin-left: 100px;
    }
`

export default WrapOrderReport
