import { Avatar, List, Pagination, Input, Spin } from 'antd'
import { type } from 'os'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getPostByIdTopic, getToppicBlogs } from '../CommunityBlogsApi'
import { useHistory } from 'react-router-dom'
import images from 'utils/images'
const { Search } = Input

interface IState {
  idTopic?: number
}
type Props = {
  data: string
  media: Array<any>
}
type Pading = {
  page: number
  limit: number
  totalItemCount: number
}
const Descripsion = ({ data, media }: Props) => {
  const arrDescription = data.split('\n')
  return (
    <>
      <div className="post-list-desc">
        {arrDescription.map((item, index) => {
          if (index >= 3) {
            return
          }
          return (
            <span
              key={item}
              style={{ color: 'black', fontSize: 20 }}
              className="post-list-text"
            >
              {item}
            </span>
          )
        })}
      </div>
      <div>
        {media.map(item => {
          return (
            <img
              style={{
                width: 200,
                height: 170,
                borderRadius: 10,
                margin: 10,
              }}
              src={item.media_url}
            />
          )
        })}
      </div>
    </>
  )
}
const BlogsListPost = () => {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [defaultId, setDefaultId] = useState<number>(0)
  const [dataPost, setDataPost] = useState<Array<any>>([])
  const [pading, setPading] = useState<Pading>({
    page: 1,
    limit: 1,
    totalItemCount: 1,
  })
  const idTopic = (location.state as IState)?.idTopic

  const getIdDefault = async () => {
    const resDefaultId = await getToppicBlogs()
    setDefaultId(resDefaultId.data[0].id)
  }

  useEffect(() => {
    getIdDefault()
  }, [])

  const getDataPost = async () => {
    setLoading(true)
    try {
      const res = await getPostByIdTopic('/post', {
        topic_id: idTopic || defaultId,
        search: search,
      })
      setDataPost(res.data)
      setPading(res.paging)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getDataPost()
  }, [idTopic, search])

  const handleClick = (e: number) => {
    history.push(`/blogs/detail/${e}`)
  }

  const onSearch = (e: any) => {
    setSearch(e.target.value)
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Search
        className="Blogs-Search"
        placeholder="Tìm kiếm bài đăng ..."
        onChange={onSearch}
      />
      <Spin spinning={loading} style={{ minHeight: '100vh' }}>
        <List
          className="post-list"
          itemLayout="horizontal"
          dataSource={dataPost}
          renderItem={item => (
            <List.Item key={item.id} onClick={() => handleClick(item.id)}>
              <List.Item.Meta
                style={{ color: 'black' }}
                title={
                  <div>
                    <Avatar
                      src={images.img_ogo}
                      style={{
                        fontSize: 20,
                        marginLeft: 15,
                        display: 'inline-block',
                      }}
                    />
                    <p
                      style={{
                        fontSize: 20,
                        marginLeft: 20,
                        display: 'inline-block',
                        fontWeight: 600,
                      }}
                    >
                      {item.User.name}
                    </p>
                  </div>
                }
                description={
                  <Descripsion data={item.content} media={item.PostMedia} />
                }
              />
            </List.Item>
          )}
        />
      </Spin>
      <Pagination
        style={{ textAlign: 'center', marginBottom: 20 }}
        current={pading.page}
        total={pading.totalItemCount}
        pageSize={pading.limit}
        onChange={async (pageIndex, pageSize) => {
          await setPading({ ...pading, page: pageIndex })
        }}
      />
    </div>
  )
}

export default BlogsListPost
