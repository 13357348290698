import React, { useRef } from 'react'
import { Header, Container, SlideBar, EmptyChatArea, ChatArea } from './components'

function Chat() {
    const [isStartNewTopic, setIsStartNewTopic] = React.useState<boolean>(false)

    return (
        <Container
            headerComponent={Header}
            filterComponent={<SlideBar isStartNewTopic={isStartNewTopic} />}
            contentComponent={() => <ChatArea setIsStartNewTopic={setIsStartNewTopic} />}
            emptyContentComponent={EmptyChatArea}
        />
    )
}
export default Chat
