import { Button, Descriptions, Image, message, Spin, Typography } from 'antd'
import {
  IDataDetailproduct,
  IProductCustomAttributeOption,
} from '../ProductInterfaces'
import { detailProducts, syncProduct } from '../ProductAPI'
import { useState, useEffect, memo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './css/DetailStyle.css'
import { IS_ACTIVE, PRODUCT_CUSTOM_TYPE } from 'utils/constants'
import { notificationError, notificationSuccess } from 'utils/notification'
import { changeStatus } from '../../StatusSlice'
import ShowMoreText from 'react-show-more-text'
import styled from 'styled-components'
import { formatPrice } from 'utils/ruleForm'
import { toString } from 'utils/Configs'
import { CloudSyncOutlined } from '@ant-design/icons'
import SystemInfo from 'features/admin/config-clone/component/SystemInfo'

const { Text } = Typography

const DescText = styled.div``
interface IProps {}

function ProductInfo(props: IProps) {
  const dispatch = useDispatch()
  const statusProduct = useSelector((state: any) => state.statusReducer.status)
  const [isLoading, setisLoading] = useState(false)
  const [typeProduct, settypeProduct] =
    useState<IProductCustomAttributeOption[]>()
  const [productDetail, setProductDetail] = useState<IDataDetailproduct>()
  const param: { id: string } = useParams()

  const getProductDetail = async (id: string) => {
    try {
      const productInfo = await detailProducts(id)
      if (productInfo.data) {
        setProductDetail(productInfo.data)
        dispatch(changeStatus(productInfo.data.status))
      }
    } catch (err) {
      console.log(err)
      notificationError('Có lỗi xảy ra')
    }
  }

  useEffect(() => {
    if (param) getProductDetail(param.id)
  }, [])

  const TypeProductDesc = (typeProduct?: string) => {
    if (typeProduct) {
      switch (typeProduct) {
        case PRODUCT_CUSTOM_TYPE.IS_BEST_SELLING:
          return 'Hàng bán chạy'
        case PRODUCT_CUSTOM_TYPE.IS_NEW:
          return 'Hàng mới'
        case PRODUCT_CUSTOM_TYPE.IS_SALE_OFF:
          return 'Hàng giảm giá'
      }
    }
  }

  const syncData = async () => {
    try {
      setisLoading(true)
      await syncProduct(param)
      getProductDetail(param.id)
      notificationSuccess('Đồng bộ thành công')
    } catch (error) {
      console.log(error)
      notificationError('Có lỗi xảy ra')
    } finally {
      setisLoading(false)
    }
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <Descriptions
          title={
            <div>
              <p className="title-info">THÔNG TIN CHUNG</p>
              <Button onClick={syncData}>
                <CloudSyncOutlined />
                Đồng bộ
              </Button>
            </div>
          }
        >
          <Descriptions.Item label="Mã sản phẩm">
            {toString(productDetail?.code) || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="Danh mục" span={5}>
            {toString(productDetail?.category_tree?.name) || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="Tên sản phẩm">
            {toString(productDetail?.name) || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái sản phẩm" span={3}>
            {statusProduct == IS_ACTIVE.ACTIVE ? (
              <Text>Đang hoạt động</Text>
            ) : (
              <Text>Ngừng hoạt động</Text> || '---'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Giá bán">
            {toString(formatPrice(productDetail?.price) + ' đ') || '---'}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Thuộc tính" span={3}>
            {toString('---')}
          </Descriptions.Item> */}
          <Descriptions.Item label="Loại hàng" span={3}>
            {toString(TypeProductDesc(productDetail?.custom_type)) || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="Số khách quan tâm">
            {toString('---')}
          </Descriptions.Item>
          <Descriptions.Item label="Đơn vị tính (Mặc định)" span={3}>
            {toString(productDetail?.master_unit) || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="Tổng tồn">
            {toString(productDetail?.stock) || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="Đơn vị tính (Quy đổi)" span={3}>
            {toString(productDetail?.unit) || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="Mô tả">
            {productDetail?.description ? (
              <ShowMoreText
                lines={3}
                more="Xem thêm"
                less="Ẩn bớt"
                className="content-css"
                anchorClass="my-anchor-css-class"
                onClick={() => {}}
                expanded={false}
                width={300}
                truncatedEndingComponent={'... '}
              >
                <DescText
                  dangerouslySetInnerHTML={{
                    __html: `${productDetail?.description}`,
                  }}
                />
              </ShowMoreText>
            ) : (
              'Sản phẩm không có mô tả' || '---'
            )}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions title={<p className="title-info">THÔNG TIN HÌNH ẢNH</p>}>
          <Descriptions.Item
            span={3}
            label="Hình ảnh sản phẩm"
            labelStyle={{ fontWeight: 'bold' }}
          >
            {productDetail?.images.map((image, index) => (
              <div style={{ marginLeft: '10px' }}>
                <Image
                  key={index}
                  style={{
                    padding: '10px',
                    backgroundColor: 'white',
                    border: '1px solid #d3d3d3',
                  }}
                  height={130}
                  width={130}
                  src={image.src}
                />
              </div>
            ))}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="Video sản phẩm"
            labelStyle={{ fontWeight: 'bold' }}
          >
            {productDetail?.videos.length !== 0 ? (
              <>
                {productDetail?.videos.map((video, index) => (
                  <video
                    key={index}
                    controls
                    className="uploaded-pic img-thumbnail "
                    style={{ marginLeft: '14px' }}
                    width={310}
                    height={217.5}
                    src={video?.src}
                  />
                ))}
              </>
            ) : (
              'Sản phẩm không có video'
            )}
          </Descriptions.Item>
          {typeProduct?.length == 0 ? (
            <Descriptions.Item
              span={3}
              label="Phân loại"
              labelStyle={{ fontWeight: 'bold' }}
            >
              {typeProduct &&
                typeProduct.map(
                  (item: IProductCustomAttributeOption, index: number) => (
                    <div className="type-product">
                      <Image
                        key={index}
                        className="type-image"
                        src={item.media_url}
                      />
                    </div>
                  )
                )}
            </Descriptions.Item>
          ) : null}
        </Descriptions>
      </Spin>
    </>
  )
}

export default memo(ProductInfo)
