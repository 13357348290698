import React from 'react'
import Livestreams from './components/Livestreams'

function index() {
  return (
    <>
      <Livestreams />
    </>
  )
}

export default index
