import { ApiClient } from 'services/ApiService'

export const getShippers = (payload: any) => ApiClient.get(`/admin/shipper`, payload)

export const getShipperOverview = (payload: any) => ApiClient.get(`/admin/shipper/${payload?.id}/overview`, payload)

export const getShipperDeliveredOrder = (payload: any) =>
    ApiClient.get(`/admin/shipper/${payload?.id}/deliveryOrderReport`, payload)

export const getShipperRefundOrder = (payload: any) =>
    ApiClient.get(`/admin/shipper/${payload?.id}/refundOrderReport`, payload)

export const getShipperCODHistory = (payload: any) => ApiClient.get(`/admin/shipper/${payload?.id}/codHistory`, payload)

export const exportExcel = (payload: any) => ApiClient.get(`/admin/shipper/export_to_excel`, payload)

export const exportExcelDeliveryOrder = (id: any, payload: any) =>
    ApiClient.get(`/admin/shipper/${id}/deliveryOrderReport/exportExcel`, payload)

export const exportExcelRefundOrder = (id: any, payload: any) =>
    ApiClient.get(`/admin/shipper/${id}/refundOrderReport/exportExcel`, payload)

export const exportExcelCodHistory = (id: any, payload: any) =>
    ApiClient.get(`/admin/shipper/${id}/codHistory/exportExcel`, payload)

export const changeShipperStatus = (id: number) => ApiClient.put(`/admin/shipper/${id}/toggleStatus`)

export const deleteShipper = (id: number) => ApiClient.delete(`/admin/shipper/${id}/delete`)

export const addShipper = (payload: any) => ApiClient.post(`/admin/shipper`, payload)

export const updateShipper = (id: number, payload: any) => ApiClient.put(`/admin/shipper/${id}`, payload)

export const changePassword = (id: number, payload: any) =>
    ApiClient.put(`/admin/shipper/${id}/change_password`, payload)
