import { DeleteOutlined } from '@ant-design/icons'
import { Card, Col, Form, Input, InputNumber, PageHeader, Popconfirm, Row, Select, Spin, Table, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import ButtonSave from 'common/components/Button/ButtonSave'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Container from 'container/Container'
import { getCustomers } from 'features/admin/customer-clone/CustomerAPI'
import { getCategory } from 'features/admin/product_clone/Category/CategoryAPI'
import { listProducts } from 'features/admin/product_clone/Products/ProductAPI'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { uuid } from 'utils/Configs'
import AddEditAddressModal from '../components/AddEditAddressModal'
import CategorySelect from '../components/CategorySelect'
import ProductSelect from '../components/ProductSelect'
import { addAddress, createRefundOrder, getAddressBookList, getRefundOrderDetail, updaterefundOrder } from '../services'
import { useDebounce } from 'common/hooks/Debounce'

const AddEditRefundOrder = () => {
    const history = useHistory()
    const location: any = useLocation()
    const { orderId } = location?.state
    const [form] = useForm()
    const [search, setSearch] = React.useState<string>('')
    const searchDebounce = useDebounce(search, 300)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const [customerSelectedId, setCustomerSelectedId] = React.useState<number>()
    const [customerSelected, setCustomerSelected] = React.useState<any>()
    const [categories, setCategories] = React.useState<any>([])
    const [customers, setCustomers] = React.useState<any[]>([])
    const [addresses, setAddresses] = React.useState<any[]>([])
    const [products, setProducts] = React.useState<any>([])
    const [callback, setCallback] = React.useState<number>(1)
    const [tableData, setTableData] = React.useState<any[]>([])

    const addRow = () => {
        const newItem = {
            id: uuid(),
            category: undefined,
            product: undefined,
            quantity: 1,
            unit: '',
        }
        setTableData((prev: any) => [...prev, newItem])
    }

    const removeRow = (id: any) => {
        const newList = tableData?.filter((item: any) => item?.id !== id)
        setTableData(newList)
    }

    const columns = [
        {
            title: <b>STT</b>,
            width: 70,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => index + 1,
        },
        {
            title: <b>Danh mục</b>,
            dataIndex: 'category',
            width: 500,
            render: (value: any, record: any) => {
                return (
                    <CategorySelect
                        setCallback={setCallback}
                        form={form}
                        record={record}
                        categories={categories}
                        value={value}
                        disabled={!customerSelected}
                    />
                )
            },
        },
        {
            title: <b>Tên sản phẩm</b>,
            dataIndex: 'product',
            width: 500,
            render: (value: any, record: any) => {
                return <ProductSelect setCallback={setCallback} form={form} record={record} value={value} />
            },
        },
        {
            title: <b>Số lượng</b>,
            width: 200,
            dataIndex: 'quantity',
            render: (value: any, record: any) => {
                return (
                    <Form.Item
                        initialValue={value}
                        name={`quantity_${record?.id}`}
                        rules={[{ required: true, message: 'Vui lòng chọn sản phẩm!' }]}
                    >
                        <InputNumber min={1} style={{ width: '100%' }} />
                    </Form.Item>
                )
            },
        },
        {
            title: <b>Đơn vị tính</b>,
            width: 200,
            dataIndex: 'unit',
            render: (value: any, record: any) => {
                return (
                    <Form.Item initialValue={value} name={`unit_${record?.id}`}>
                        <Input
                            disabled
                            style={{ width: '100%', border: 'none', background: 'white', color: 'black' }}
                        />
                    </Form.Item>
                )
            },
        },
        {
            title: <b>Thao tác</b>,
            width: 200,
            dataIndex: 'unit',
            render: (_: any, record: any) => (
                <Popconfirm
                    title="Bạn chắc chắn muốn xoá sản phẩm này?"
                    onConfirm={() => removeRow(record?.id)}
                    onCancel={() => {}}
                    okText="Xoá"
                    cancelText="Huỷ"
                >
                    <DeleteOutlined style={{ color: 'red' }} />
                </Popconfirm>
            ),
        },
    ]

    const getCategories = async () => {
        try {
            const targetUser = customers?.find((item: any) => item?.id === customerSelected?.value)
            const payload = {
                page: 1,
                limit: 999,
                kiotviet_id: targetUser?.kiotviet_id,
            }
            const res = await getCategory(payload)
            if (res?.status) {
                const newList = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setCategories(newList)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getProducts = async () => {
        try {
            const payload = {
                page: 1,
                limit: 999,
            }
            const res = await listProducts(payload)
            if (res?.status) {
                const newList = res?.data?.map((item: any) => ({
                    ...item.variants[0],
                    value: item?.variants[0].id,
                    label: item?.name,
                    product_category_id: item?.category?.id,
                }))
                setProducts(newList)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getCustomersList = async () => {
        try {
            const params = {
                page: 1,
                limit: '20',
                search: searchDebounce,
                province_id: '',
                create_from: '',
                create_to: '',
                kiotviet_id: '',
                is_retail_cus: undefined,
            }
            const res = await getCustomers(params)
            if (res.data) {
                const dataCustomer = res.data?.map(customer => {
                    return {
                        ...customer,
                        value: customer?.id,
                        label: `${customer?.full_name} - ${customer?.phone_number}`,
                    }
                })
                setCustomers(dataCustomer)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const setAddressList = async () => {
        try {
            const res = await getAddressBookList(customerSelectedId)
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({
                    ...item,
                    value: item?.id,
                    label: `${item?.name} - ${item?.phone_number} - ${item?.address}`,
                }))
                setAddresses(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getDetail = async () => {
        try {
            setLoading(true)
            const res: any = await getRefundOrderDetail(orderId)
            if (res?.status) {
                const generalInfo = {
                    customer: res?.data?.user?.id,
                    phone: res?.data?.phone_number,
                    address: res?.data?.address_book_id,
                    note: res?.data?.note,
                }
                setCustomerSelectedId(res?.data?.user?.id)
                setCustomerSelected(res?.data?.user)
                const listItems = res?.data?.items?.map((item: any) => ({
                    [`category_${item?.id}`]: item?.product_category_id,
                    [`product_${item?.id}`]: item?.product_variant_id,
                    [`quantity_${item?.id}`]: item?.quantity,
                }))

                const listTableData = res?.data?.items?.map((item: any) => ({
                    id: item?.id,
                    category: item?.product_category_id,
                    product: item?.product_id,
                    quantity: item?.quantity,
                    unit: item?.product_unit || '---',
                }))

                const mergedObject = listItems.reduce((acc: any, obj: any) => {
                    Object.keys(obj).forEach(key => {
                        acc[key] = obj[key]
                    })
                    return acc
                }, generalInfo)

                setTableData(listTableData)

                form.setFieldsValue(mergedObject)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onFinish = async (values: any) => {
        try {
            setLoading(true)
            const filteredKeys = Object.keys(values).filter(key => {
                return key.includes('product') || key.includes('quantity')
            })
            let listItems: any = []
            let checkedList: any = []
            filteredKeys.forEach(key => {
                const [prefix, id] = key.split('_')
                if (!checkedList.includes(id)) {
                    checkedList.push(id)
                    const item = {
                        product_variant_id: values[key],
                        quantity: values[`quantity_${id}`],
                    }
                    listItems.push(item)
                }
            })
            const payload = {
                user_id: values?.customer,
                address_book_id: values?.address,
                phone_number: values?.phone,
                note: values?.note || undefined,
                items: listItems,
            }
            let res: any
            if (orderId) {
                res = await updaterefundOrder(orderId, payload)
            } else {
                res = await createRefundOrder(payload)
            }
            if (res?.status) {
                message.success(
                    orderId ? 'Cập nhật đơn hàng hoàn trả thành công!' : 'Thêm mới đơn hoàn trả thành công!'
                )
                history.push({
                    pathname: ADMIN_ROUTER_PATH.REFUND_ORDER,
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onAddEditAddress = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                is_default: values?.isDefault || false,
                phone_number: values?.phone,
                name: values?.name,
                address: values?.detailAddress,
                ward_id: values?.ward,
                district_id: values?.district,
                province_id: values?.province,
            }
            const res = await addAddress(customerSelectedId, payload)
            if (res?.status) {
                message.success('Thêm mới địa chỉ thành công!')
                setAddressList()
                setIsModalOpen(false)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getProducts()
    }, [])

    React.useEffect(() => {
        getCustomersList()
    }, [searchDebounce])

    React.useEffect(() => {
        if (customerSelected) {
            getCategories()
        }
    }, [customerSelected])

    React.useEffect(() => {
        if (orderId) {
            getDetail()
        }
    }, [orderId])

    React.useEffect(() => {
        if (customerSelectedId) {
            setAddressList()
        }
    }, [customerSelectedId])

    return (
        <Spin spinning={loading}>
            <CustomForm
                form={form}
                labelCol={{ span: 6 }}
                // wrapperCol={{ span: 20 }}
                initialValues={{}}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Container
                    header={
                        <PageHeader
                            style={{ borderRadius: 8 }}
                            title={orderId ? 'Cập nhật đơn hoàn trả' : 'Thêm mới đơn hoàn trả'}
                            onBack={() =>
                                history.push({
                                    pathname: ADMIN_ROUTER_PATH.REFUND_ORDER,
                                })
                            }
                            extra={[
                                <Form.Item>
                                    <ButtonSave htmlType="submit" text="Lưu" />
                                </Form.Item>,
                            ]}
                        ></PageHeader>
                    }
                    contentComponent={[
                        <>
                            <Card bordered={false} title={<b>Thông tin khách hàng</b>}>
                                <Row gutter={[64, 64]}>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item
                                            name={'customer'}
                                            label="Tên khách hàng"
                                            rules={[{ required: true, message: 'Vui lòng chọn khách hàng!' }]}
                                        >
                                            <Select
                                                placeholder="Chọn khách hàng"
                                                showArrow
                                                showSearch
                                                filterOption={false}
                                                searchValue={search}
                                                onSearch={(value: any) => setSearch(value)}
                                                onChange={(value: number | undefined, option: any) => {
                                                    if (value) {
                                                        setCustomerSelectedId(value)
                                                        setCustomerSelected(option)

                                                        form.setFieldsValue({
                                                            phone: option?.children.split('-')?.[1],
                                                            address: undefined,
                                                        })
                                                    }
                                                }}
                                            >
                                                {customers.map((item: any) => (
                                                    <Select.Option key={item.id} value={item.value}>
                                                        {item?.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item name={'phone'} label="Số điện thoại">
                                            <Input
                                                disabled
                                                onChange={(e: any) => {}}
                                                placeholder="Nhập số điện thoại"
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            {isModalOpen && (
                                <AddEditAddressModal
                                    handleOk={onAddEditAddress}
                                    setIsModalOpen={setIsModalOpen}
                                    isModalOpen={isModalOpen}
                                />
                            )}
                            <Card
                                bordered={false}
                                title={
                                    <Row
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <b>Thông tin lấy hàng</b>
                                        {customerSelectedId && (
                                            <ButtonAdd
                                                onClickButton={() => setIsModalOpen(true)}
                                                text="Thêm địa chỉ nhận hàng"
                                            />
                                        )}
                                    </Row>
                                }
                            >
                                <Row gutter={[64, 64]}>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item
                                            name={'address'}
                                            label="Địa chỉ nhận hàng"
                                            rules={[{ required: true, message: 'Vui lòng chọn địa chỉ nhận hàng!' }]}
                                        >
                                            <Select
                                                disabled={!customerSelectedId}
                                                placeholder="Chọn địa chỉ nhận hàng"
                                                allowClear
                                                showArrow
                                                showSearch
                                                options={addresses}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item name={'note'} label="Ghi chú">
                                            <Input.TextArea
                                                onChange={(e: any) => {}}
                                                placeholder="Nhập ghi chú"
                                                rows={5}
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            <Card bordered={false} title={<b>Chi tiết đơn hàng</b>}>
                                <ButtonAdd text="Thêm mới" styleButton={{ marginBottom: 10 }} onClickButton={addRow} />
                                {callback && (
                                    <Table
                                        id="top-table"
                                        bordered
                                        columns={columns}
                                        dataSource={tableData}
                                        scroll={{
                                            x: 800,
                                            scrollToFirstRowOnChange: true,
                                        }}
                                        locale={{
                                            emptyText: 'Chưa có bản ghi nào!',
                                        }}
                                        pagination={false}
                                    />
                                )}
                            </Card>
                        </>,
                    ]}
                />
            </CustomForm>
            ,
        </Spin>
    )
}

const CustomForm = styled(Form)`
    .ant-form-item-label > label {
        font-weight: 0;
    }

    .ant-form-item {
        margin-bottom: 0;
    }
`

export default AddEditRefundOrder
