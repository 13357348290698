import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Input, InputNumber, Modal, Select, Upload } from 'antd'
import ButtonForm from 'common/components/ModalButton'
import { useEffect, useState } from 'react'
import reactotron from 'ReactotronConfig'
import createFormDataImage from 'utils/createFormDataImage'
import { notificationError, notificationSuccess } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import {
  createGifts,
  getListTypeGift,
  updateGift,
  uploadImage,
} from '../listGiftApi'
type Props = {
  visible: boolean
  onCancel?: any
  data?: any
  getData: any
  isLoadingButton: boolean
}

function convertDataToFrom(data: any) {
  if (!data) {
    return {
      is_default: null,
      minus: null,
      name: null,
      pakage_category_id: null,
      price: null,
    }
  } else {
    data.price = formatPrice(data.price)
    data.max_discount_money = formatPrice(data.max_discount_money)
    data.quantity = formatPrice(data.quantity)
    return {
      data,
    }
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

function getBase64(img: any, callback: any) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const AddEditGift = ({
  visible,
  onCancel,
  data,
  getData,
  isLoadingButton,
}: Props) => {
  const [form] = Form.useForm()
  const initialValue = convertDataToFrom(data)
  const [upload, setUpload] = useState({
    loading: false,
    imageUrl: '',
  })
  const [listTypeGift, setListTypeGift] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState(false)
  const [displayDiscountPercent, setDisplayDiscountPercent] = useState(false)

  useEffect(() => {
    getTypeGift()
  }, [])
  useEffect(() => {
    if (data) {
      setUpload({
        ...upload,
        imageUrl: data.icon_url,
      })
      data.df_type_gift_id === 2
        ? setDisplayDiscountPercent(true)
        : setDisplayDiscountPercent(false)
    }
  }, [data])

  const getTypeGift = async () => {
    const res = await getListTypeGift()
    setListTypeGift(res.data)
  }
  const uploadButton = (
    <div>
      {upload.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const onFinish = async (values: any) => {
    try {
      setIsLoading(true)
      values.price = parseInt(values?.price.replaceAll(',', ''))
      values.quantity = parseInt(values?.quantity.replaceAll(',', ''))
      if (displayDiscountPercent) {
        values.max_discount_money = parseInt(
          values?.max_discount_money.replaceAll(',', '')
        )
      }

      // Upload image lên Server
      if (values.icon_url.fileList) {
        var resUploadImage = []
        var last_element = []

        if (values.icon_url.fileList.length > 1) {
          last_element = [values.icon_url.fileList.slice(-1)[0]]
        } else {
          last_element = values.icon_url.fileList
        }
        const dataImage = await createFormDataImage(last_element)
        const payloadImage = {
          type: 1,
          data: dataImage,
        }
        try {
          resUploadImage = await uploadImage(payloadImage)
        } catch (error) {
          // alert(error)
        }
      }
      let newData

      if (!data) {
        newData = {
          ...values,
          icon_url: resUploadImage.data.path,
        }
        try {
          const res = await createGifts(newData)
          if (res.code === 1) {
            setTimeout(() => {
              notificationSuccess('Thêm gói quà tặng thành công')
              setUpload({
                loading: false,
                imageUrl: '',
              })
              form.resetFields()
              onCancel()
              getData()
            }, 1000)
          }
        } catch (error) {
          notificationError('Thêm gói quà tặng thất bại')
        }
      } else {
        const { df_type_gift_id, voucher_code, ...rest } = values

        if (data.icon_url === values.icon_url) {
          newData = {
            ...rest,
            id: data.id,
          }

          newData = {
            data: { ...rest },
            id: data.id,
          }
        } else {
          newData = {
            id: data.id,
            data: { ...rest, icon_url: resUploadImage.data.path },
          }
        }

        const res = await updateGift(newData)
        if (res.code === 1) {
          setTimeout(() => {
            notificationSuccess('Cập nhật gói quà tặng thành công')
            setUpload({
              loading: false,
              imageUrl: '',
            })
            form.resetFields()
            onCancel()
            getData()
          }, 1000)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      notificationError(
        'Xảy ra lỗi! Bạn chỉ có thể upload ảnh có dạng JPG/PNG!'
      )
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      notificationError('Cho phép ảnh có dung lượng tối đa là 2MB')
    }
    return isJpgOrPng && isLt2M
  }
  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setUpload({
        imageUrl: '',
        loading: true,
      })
      return
    }

    if (info.file.status === 'done' || info.file.status === 'error') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) =>
        setUpload({
          imageUrl: imageUrl,
          loading: false,
        })
      )
    }
  }
  return (
    <Modal
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      maskClosable={false}
      footer={null}
      title={data ? 'Sửa quà tặng' : 'Thêm quà tặng '}
      visible={visible}
      width={620}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="add"
        labelAlign="left"
        onFinish={(values: any) => onFinish(values)}
        initialValues={initialValue.data}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Tên quà"
          rules={[
            {
              type: 'string',
              message: 'Nhập tên quà tặng',
            },
            {
              required: true,
              message: 'Vui lòng nhập tên quà tặng!',
              validator: (_, value) => {
                if (value.trim() === '') {
                  return Promise.reject()
                }
                return Promise.resolve()
              },
            },
            {
              min: 3,
              max: 50,
              message: 'Vui lòng nhập từ 3 đến 50 ký tự!',
            },
            // {
            //   message: 'Vui lòng không nhập kí tự đặc biệt',
            //   validator: (_, value) => {
            //     const reg = /[^a-z0-9A-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềếểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]/u
            //     if (!reg.test(value)) {
            //       return Promise.resolve()
            //     }
            //     return Promise.reject()
            //   },
            // },
          ]}
        >
          <Input
            placeholder="Nhập tên quà tặng"
            disabled={data?.count_purchased_gift > 0 ? true : false && !!data}
          />
        </Form.Item>

        <Form.Item
          name="price"
          label="Số điểm"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số điểm',
            },
            // {
            //   message: 'Vui lòng chỉ nhập số ',
            //   validator: (_, value) => {
            //     const value_price = value.replaceAll(',', '')
            //     const reg = /^\d+$/
            //     if (!reg.test(value_price)) {
            //       return Promise.reject()
            //     }
            //     return Promise.resolve()
            //   },
            // },
            // {
            //   message: 'Vui lòng nhập số điểm dưới 50 000',
            //   validator: (_, value) => {
            //     const value_price = parseInt(value.replaceAll(',', ''))
            //     if (value_price > 50000) {
            //       return Promise.reject()
            //     }
            //     return Promise.resolve()
            //   },
            // },
          ]}
        >
          <Input
            placeholder="Nhập số điểm"
            style={{ width: '100%' }}
            onChange={() => {
              form.setFieldsValue({
                price: formatPrice(form.getFieldValue('price')),
              })
            }}
            disabled={!!data}
          />
        </Form.Item>
        <Form.Item
          label="Loại quà"
          name="df_type_gift_id"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn loại quà!',
            },
          ]}
        >
          <Select
            disabled={data}
            placeholder="Chọn loại quà"
            defaultValue={data?.type_gift_name}
            onChange={(value: number) => {
              if (value === 2) {
                setDisplayDiscountPercent(true)
              } else {
                setDisplayDiscountPercent(false)
              }
            }}
          >
            {listTypeGift.map((item: any) => {
              return (
                <Select.Option disabled={!!data} value={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="quantity"
          label="Số lượng quà"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số lượng quà',
            },
            {
              message: `Số lượng quà phải lớn hơn lượng quà đã đổi (${data?.count_purchased_gift}) `,
              validator: (_, value) => {
                if (!data?.count_purchased_gift) return Promise.resolve()
                const value_price = value.replaceAll(',', '', ' ')
                if (parseInt(value_price) < data?.count_purchased_gift) {
                  return Promise.reject()
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <Input
            placeholder="Nhập số lượng quà"
            style={{ width: '100%' }}
            onChange={() => {
              form.setFieldsValue({
                quantity: formatPrice(form.getFieldValue('quantity')),
              })
            }}
          />
        </Form.Item>
        {displayDiscountPercent ? (
          <>
            <Form.Item
              name="voucher_code"
              label="Mã giảm giá"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'Vui lòng nhập mã giảm giá!',
                  validator: (_, value) => {
                    if (value.trim() === '') {
                      return Promise.reject()
                    }
                    return Promise.resolve()
                  },
                },
                {
                  min: 5,
                  max: 50,
                  message: 'Vui lòng nhập từ 5 đến 50 ký tự!',
                },
                {
                  message: 'Vui lòng không nhập khoảng trắng!',
                  validator: (_, value) => {
                    const reg = /\s/
                    if (!reg.test(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject()
                  },
                },
              ]}
            >
              <Input
                placeholder="Nhập mã giảm giá"
                disabled={!!data}

                // disabled={data?.count_purchased_gift > 0 ? true : false}
              />
            </Form.Item>

            <Form.Item
              name="discount_percent"
              label="Giảm giá"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập giảm giá',
                },
                // {
                //   validator: (_, value) => {
                //     const reg = /^\d+$/
                //     if (!reg.test(value)) {
                //       return Promise.reject(new Error('Vui lòng chỉ nhập số !'))
                //     }
                //     return Promise.resolve()
                //   },
                // },
                {
                  message: 'Vui lòng nhập giảm giá dưới 100%',
                  validator: (_, value) => {
                    if (value > 100) {
                      return Promise.reject()
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                placeholder="Nhập % giảm giá"
                type = 'number'
                disabled={!!data}
                controls={false}
              />
            </Form.Item>

            <Form.Item
              className="label-form-item"
              name="max_discount_money"
              label="Số tiền giảm tối đa (VND)"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập số tiền giảm tối đa!',
                },
              ]}
            >
              <Input
                placeholder="Nhập số tiền giảm tối đa"
                onChange={() => {
                  form.setFieldsValue({
                    max_discount_money: formatPrice(
                      form.getFieldValue('max_discount_money')
                    ),
                  })
                }}
                disabled={!!data}
              />
            </Form.Item>
          </>
        ) : (
          <></>
        )}

        <Form.Item
          name="icon_url"
          label="Hình ảnh"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn ảnh',
            },
          ]}
        >
          <Upload
            name="icon_url"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            disabled={!!data}
          >
            {upload.imageUrl ? (
              <img
                src={upload.imageUrl || data.icon_url}
                alt="avatar"
                style={{ width: '100%', objectFit: 'contain', height: '100%' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        {/* <Form.Item>
          <Row style={{ width: '100%' }}>
            <Col span="6" offset="10">
              <Button
                danger
                onClick={() => {
                  form.resetFields()
                  onCancel()
                }}
              >
                Huỷ
              </Button>
            </Col>
            <Col span="6" offset="2">
              <Button loading={isLoading} type="primary" htmlType="submit">
                {data ? 'Cập nhật' : 'Thêm gói quà tặng'}
              </Button>
            </Col>
          </Row>
        </Form.Item> */}
        <ButtonForm
          isLoadingButton={isLoadingButton}
          onCancel={onCancel}
          text={data ? 'Cập nhật' : 'Thêm gói quà tặng'}
        />
      </Form>
    </Modal>
  )
}
