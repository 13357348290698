import { useEffect, useRef, useState } from 'react'
import { Table, Tag, Typography } from 'antd'
import { getListGiftChanges } from '../giftChangeApi'
import { formatPrice } from 'utils/ruleForm'
import HeaderGiftChange from './HeaderGiftChange'
import { GIFT_STATUS } from 'utils/constants'
import { useSelector } from 'react-redux'
import { get_ss_hh_dd_mm } from 'utils/TimerHelper'
import styled from 'styled-components'
import { IParams } from '../GiftChangeInterface'
import { useDebounce } from 'common/hooks/Debounce'

const { Text } = Typography

const WapperTable = styled.div`
  background-color: white;
  margin: 0px 10px 15px;
  padding: 10px 20px;
`

function ListGiftChange() {
  const heightWeb = useSelector(
    (state: any) => state.configReducer.dimension.height
  )
  const [listGiftChange, setListGiftChange] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([])
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const [params, setParams] = useState<IParams>({
    search: '',
    status: undefined,
    page: 1,
    limit: 24,
    from_date: '',
    to_date: '',
    df_type_gift_id: undefined,
  })

  useEffect(() => {
    getData()
  }, [])
   const debounced = useDebounce(params, 300)
   useEffect(() => {
     getData()
   }, [debounced])
  const getData = async () => {
    setIsLoading(true)
    try {
      setCurrentSelected({ id: -1 })
      const res = await getListGiftChanges(params)
      const data_list = res.data.map((item: any) => {
        return {
          ...item,
          key: item.id,
          receiver_name: item.address?.name,
          receiver_phone: item.address?.phone,
        }
      })
      const formattedPaging = {
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      }
      setSelectedRowKeys([])
      setPaging(formattedPaging)
      setListGiftChange(data_list)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: async (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRowKeys(selectedRowKeys)
    },

    // getCheckboxProps: (record: any) => ({
    //   disabled: record.status !== 0, // Column configuration not to be checked
    // }),
  }
  const columns = [
    {
      width: 70,
      title: 'STT',
      dataIndex: '',
      render: (text: any, record: any, index: any) => (
        <Text style={{ textAlign: 'center', display: 'block' }} id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </Text>
      ),
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'User',
      width: 120,
      render: (value: any) => {
        return <Text>{value.full_name}</Text>
      },
    },
    {
      title: 'Tên quà tặng',
      dataIndex: 'Gift',
      width: 120,
      render: (value: any) => {
        return <Text>{value.name}</Text>
      },
    },
    {
      title: 'Số điểm',
      dataIndex: 'Gift',
      width: 120,
      render: (value: any) => {
        return <Text>{value.price ? formatPrice(value.price) : 0}</Text>
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 120,
      render: (value: any) => {
        switch (value) {
          case GIFT_STATUS.PENDING:
            return <Tag color="warning">Đang chờ</Tag>
          case GIFT_STATUS.CANCEL:
            return <Tag color="red">Từ chối</Tag>
          case GIFT_STATUS.USED:
            return <Tag color='cyan'>Đã sử dụng</Tag>
          case GIFT_STATUS.CONFIRMED:
            return <Tag color="blue">Đã xác nhận</Tag>
          case GIFT_STATUS.SENT_GIFT:
            return <Tag color="green">Đã gửi</Tag>
        }
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 120,
      render: (created_time: any) => {
        return <Text>{get_ss_hh_dd_mm(created_time)}</Text>
      },
    },
  ]
   
  return (
    <>
      <HeaderGiftChange
        selects={selectedRowKeys}
        isSearchLoading={isLoading}
        onSelected={selectedRowKeys}
        getData={getData}
        onSearchSubmit={(searchKey: string) => {
          setParams({ ...params, search: searchKey.trim() })
        }}
        onStatusSubmit={(statusKey: number) => {
          setParams({ ...params, status: statusKey })
        }}
        onTypeGift={(type_id: number) => {
          setParams({ ...params, df_type_gift_id: type_id })
        }}
        onDateSubmit={(from_date: string, to_date: string) => {
          setParams({
            ...params,
            from_date: from_date,
            to_date: to_date,
          })
        }}
      />

      <WapperTable>
        <p>
          Kết quả lọc: <Text>{formatPrice(paging.total)}</Text>
        </p>
        <Table
          scroll={{
            scrollToFirstRowOnChange: true,
            y: `calc(${heightWeb}px - 330px)`,
          }}
          rowSelection={rowSelection}
          bordered
          dataSource={listGiftChange}
          loading={isLoading}
          columns={columns}
          pagination={{
            ...paging,
            showSizeChanger: false,
            onChange: async (page, pageSize) => {
              setParams({ ...params, page })
            },
          }}
        />
      </WapperTable>
    </>
  )
}
export default ListGiftChange
