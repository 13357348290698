import './css/styles.css'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ChatHeader from './ChatHeader'
import { getDataMessageDetail } from '../ChatService'
import TypingChatArea from './TypingChatArea'
import ChatBody from './ChatBody'
import { ADMIN_ROUTER_PATH } from '../../../../common/config'
import { ChatItemParam, UserInfoState, TopicMessageState } from '../types'

interface IChatArea {
    setIsStartNewTopic: React.Dispatch<React.SetStateAction<boolean>>
}

function ChatArea(props: IChatArea) {
    const { setIsStartNewTopic } = props
    const history = useHistory()
    const { id } = useParams<ChatItemParam>()
    const { userInfo: UserInstance } = useSelector((state: any) => state.authReducer)
    const chattingUserState = useSelector((state: any) => state.messageNotReadReducer.chatingUserInfo)
    const [topicInfo, setTopicInfo] = useState<TopicMessageState | null>(null)
    const [selfInfo, setSelfInfo] = useState<any | null>(null)
    const [otherInfo, setOtherInfo] = useState<UserInfoState | null>(null)
    const [selfIsUser, setSeltIsUser] = useState<boolean>(false)
    const [isLoadingFirstTime, setIsLoadingFirstTime] = useState<boolean>(false)

    const getInfoTopicMessage = async () => {
        if (id) {
            try {
                setIsLoadingFirstTime(true)
                const res = await getDataMessageDetail(id)
                setTopicInfo(res.data)
            } catch (error) {
                console.log('err', error)
                history.replace(`${ADMIN_ROUTER_PATH.CHAT}`)
            } finally {
                setIsLoadingFirstTime(false)
            }
        }
    }

    const setUserChatInfo = () => {
        if (topicInfo) {
            setOtherInfo(topicInfo.User)
            setSeltIsUser(false)
        }
    }

    useEffect(() => {
        if (id !== 'default_id') {
            getInfoTopicMessage()
        } else {
            setOtherInfo(chattingUserState)
        }
    }, [id])

    useEffect(() => {
        if (id !== 'default_id') {
            setUserChatInfo()
        }
    }, [UserInstance, topicInfo])

    return (
        <div style={{ height: '100%' }}>
            <ChatHeader
                name={otherInfo?.name}
                phone={otherInfo?.phone_number}
                lastSendMessage={topicInfo?.time_last_send}
                avatar={otherInfo?.profile_picture_url}
                isLoading={isLoadingFirstTime}
            />
            <ChatBody
                id={id}
                selfIsUser={selfIsUser}
                selfInfo={selfInfo}
                otherInfo={otherInfo}
                isLoading={isLoadingFirstTime}
            />
            <TypingChatArea id={id} otherInfo={otherInfo} setIsStartNewTopic={setIsStartNewTopic} />
        </div>
    )
}
export default ChatArea
