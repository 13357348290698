import { ADMIN_ROLE } from './constants'

export const toString = (value: any) => {
    if (value) {
        return value
    } else {
        return '---'
    }
}

export const renderDeliveryStatus = (value: number) => {
    switch (value) {
        case 0:
            return 'Chờ lấy hàng'
        case 1:
            return 'Đang giao'
        case 2:
            return 'Đã giao'

        default:
            return '---'
    }
}
export const renderPaymentStatus = (value: string) => {
    switch (value) {
        case 'pending':
            return 'Chưa thanh toán'
        case 'completed':
            return 'Đã thanh toán'

        default:
            return '---'
    }
}

export const renderRefundStatus = (value: number | null) => {
    switch (value) {
        case null:
        case 0:
            return 'Chờ nhận đơn'
        case 1:
            return 'Đang lấy hàng'
        case 2:
            return 'Đã hoàn trả'

        default:
            return '---'
    }
}

export const renderVoucherStatus = (value: number) => {
    switch (value) {
        case 5:
            return 'Chưa lưu'
        case 1:
            return 'Đã lưu'
        case 3:
            return 'Đã sử dụng'

        default:
            return '---'
    }
}

export const renderRole = (value: number) => {
    switch (value) {
        case ADMIN_ROLE.ROOT_ADMIN:
            return 'Admin tổng'
        case ADMIN_ROLE.SHOP_ADMIN:
            return 'Admin gian hàng'
        case ADMIN_ROLE.CUSTOMER_MANAGER:
            return 'Quản lý khách hàng'
        case ADMIN_ROLE.CUSTOMER_CARE:
            return 'Chăm sóc khách hàng'
        case ADMIN_ROLE.REPORT_MANAGER:
            return 'Quản lý báo cáo'
        case ADMIN_ROLE.PRODUCT_MANAGER:
            return 'Quản lý sản phẩm'
        case ADMIN_ROLE.PREFERENTIAL_MANAGER:
            return 'Quản lý ưu đãi'
        case ADMIN_ROLE.SALE_MANAGER:
            return 'Trưởng phòng kinh doanh'
        case ADMIN_ROLE.INVERTORY_STAFF:
            return 'Quản lý kho'
        case ADMIN_ROLE.ACCOUNTANT:
            return 'Kế toán'
        case ADMIN_ROLE.SHIPPER_MANAGER:
            return 'Quản lý shipper'

        default:
            return '---'
    }
}

export const renderOrderStatus = (value: string) => {
    switch (value) {
        case 'wait_confirmation':
            return 'Chờ xác nhận'
        case 'inprogress':
            return 'Đã xác nhận'
        case 'completed':
            return 'Hoàn thành'
        case 'cancelled':
            return 'Đã huỷ'

        default:
            return '---'
    }
}

export function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

const MOBI = /((^(\+84|84|0|0084){1})(3)(2|3|4|5|6|7|8|9))+([0-9]{7})$/
const VINA = /((^(\+84|84|0|0084){1})(8)(2|3|4|5|6|8|9))+([0-9]{7})$/
const VIETTEL = /((^(\+84|84|0|0084){1})(9)(3|4|1|6|7|8|9|0))+([0-9]{7})$/
const SEVEN = /((^(\+84|84|0|0084){1})(7)(0|6|7|8|9))+([0-9]{7})$/
const FIVE = /((^(\+84|84|0|0084){1})(5)(9))+([0-9]{7})$/

export { MOBI, VINA, VIETTEL, SEVEN, FIVE }

export const toNonAccentVietnamese = (str: string = '') => {
    str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, 'A')
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, 'E')
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    str = str.replace(/I|Í|Ì|Ĩ|Ị/g, 'I')
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, 'O')
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, 'U')
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, 'Y')
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    str = str.replace(/Đ/g, 'D')
    str = str.replace(/đ/g, 'd')
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, '') // Â, Ê, Ă, Ơ, Ư
    return str
}
