import React from 'react'

function Header() {
  return (
    <div style={{ fontSize: 25 }}>
      <strong>Nhắn tin</strong>
    </div>
  )
}
export default Header
