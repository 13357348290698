import { Descriptions } from 'antd'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { toString } from 'utils/Configs'
import { IS_ACTIVE } from 'utils/constants'
import { getKiovietDetail } from '../ConfigAPI'
import { getData } from '../GetDataSlice'

interface IWapperStyle {
  padding?: boolean
}

const Wapper = styled.div<IWapperStyle>`
  background-color: white;
  border-radius: 5px;
  margin: 10px;
  padding: ${props => (props.padding ? '10px' : null)};
`

interface IProps {
  location: any
}

function ShopInfo(props: IProps) {
  const data = props.location?.state?.data
  const [kiovietDetail, setkiovietDetail] = useState<any>()

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const res = await getKiovietDetail(data.id)
    if (res.data) {
      setkiovietDetail(res.data)
    }
  }

  return (
    <>
      <Wapper>
        <Descriptions title="Thông tin gian hàng">
          <Descriptions.Item label="Tên gian hàng">
            {toString(kiovietDetail?.name)}
          </Descriptions.Item>
          <Descriptions.Item label="Secret_ID">
            {toString(kiovietDetail?.client_secret)}
          </Descriptions.Item>
          <Descriptions.Item label="Client_ID">
            {toString(kiovietDetail?.client_id)}
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            {kiovietDetail?.status == IS_ACTIVE.ACTIVE
              ? 'Đang đồng bộ'
              : 'Ngừng đồng bộ'}
          </Descriptions.Item>
          <Descriptions.Item label="Số sản phẩm">
            {toString(kiovietDetail?.['stats.product_count'])}
          </Descriptions.Item>
          <Descriptions.Item label="Số đơn hàng">
            {toString(kiovietDetail?.['stats.order_count'])}
          </Descriptions.Item>
          <Descriptions.Item label="Số khách hàng">
            {toString(kiovietDetail?.['stats.user_count'])}
          </Descriptions.Item>
          <Descriptions.Item label="Số lượt Livestream">
            {toString(kiovietDetail?.['stats.livestream_count'])}
          </Descriptions.Item>
        </Descriptions>
      </Wapper>
    </>
  )
}

export default ShopInfo
