import { getMessageNotReadAction } from 'features/admin/chat/slices/MessageNotReadSlice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WebSocket } from 'services/WebSocket'
import { ResponseSocketModel, withShopChannel, withUserChannel } from 'utils/socketConstants'

export const useListMessageNotRead = () => {
    const dispatch = useDispatch()
    const { listTopicMessageNotRead } = useSelector((state: any) => state.messageNotReadReducer)

    const { userInfo: UserInstance } = useSelector((state: any) => state.authReducer)

    const onUserHasNewMessage = () => {
        WebSocket.socketClient?.on(withUserChannel(UserInstance?.id), async (res: ResponseSocketModel) => {
            getCountMessageNotRead()
        })
    }
    const onShopHasNewMessage = () => {
        WebSocket.socketClient?.on(withShopChannel(UserInstance?.shop_id), async (res: ResponseSocketModel) => {
            getCountMessageNotRead()
            console.log('this case')
        })
    }

    const getCountMessageNotRead = () => {
        dispatch(getMessageNotReadAction())
    }

    useEffect(() => {
        getCountMessageNotRead()
        if (UserInstance?.id) {
            onUserHasNewMessage()
        }
        if (UserInstance?.shop_id) {
            onShopHasNewMessage()
        }
    }, [UserInstance])
    return { listTopicMessageNotRead }
}
