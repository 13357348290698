import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { PageHeader, Radio, Row, Select, Spin, Table, message } from 'antd'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import ButtonSave from 'common/components/Button/ButtonSave'
import { useDebounce } from 'common/hooks/Debounce'
import Container from 'container/Container'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import React from 'react'
import styled from 'styled-components'
import { toNonAccentVietnamese } from 'utils/Configs'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'
import Filter from '../components/Filter'
import ChangePasswordModal from 'features/admin/shipper/components/ChangePasswordModal'
import StaffInfo from '../components/StaffInfo'
import AddEditBusinessStaffModal from '../components/AddEditBusinessStaff'
import {
    addSaleStaff,
    changeSalePassword,
    changeSaleStaff,
    deleteSaleStaff,
    exportExcel,
    exportExcelDetail,
    getListCustomerGroup,
    getListCustomersByStaff,
    getListSaleStaff,
    getSaleStaffDetail,
    updateSaleStaff,
} from '../service'
import { getProvinces } from 'features/admin/customer-clone/CustomerAPI'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

const listCus = [
    {
        label: 'Nhóm 1',
        value: 1,
    },
    {
        label: 'Nhóm 2',
        value: 2,
    },
    {
        label: 'Nhóm 3',
        value: 3,
    },
]

const BusinessStaff = () => {
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isSaleManager = userInfor?.admin?.role === ADMIN_ROLE.SALE_MANAGER
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN
    const [listSaleStaff, setListSaleStaff] = React.useState<any[]>([])
    const [selectedGroupId, setSelectedGroupId] = React.useState<any>()
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const [isCPModalOpen, setIsCPModalOpen] = React.useState<boolean>(false)
    const [listKiotviet, setlistKiotviet] = React.useState<any[]>([])
    const [selectedKiotVietId, setSelectedKiotVietId] = React.useState<any>(
        isSaleManager || isShopAdmin ? userInfor?.admin?.kiotviet_id : ''
    )
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<number[]>([])
    const [currentTab, setCurrentTab] = React.useState<string>('1')
    const [staffOpenId, setStaffOpenId] = React.useState<any>()
    const [staffDetail, setStaffDetail] = React.useState<any>()
    const [currentRecord, setCurrentRecord] = React.useState<any>()
    const [isAdd, setIsAdd] = React.useState<boolean>()
    const [customerGroup, setCustomerGroup] = React.useState<any[]>()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [search, setSearch] = React.useState<string>('')
    const [toDate, setToDate] = React.useState<string>('')
    const [fromDate, setFromDate] = React.useState<string>('')
    const [provinces, setProvinces] = React.useState<any[]>([])

    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })
    const searchDebounce = useDebounce(search, 300)

    // Detail filter
    const [searchDetail, setSearchDetail] = React.useState<string>('')
    const [provinceId, setProvinceId] = React.useState<any>()
    const [toDateDetail, setToDateDetail] = React.useState<string>('')
    const [fromDateDetail, setFromDateDetail] = React.useState<string>('')
    const searchDetailDebounce = useDebounce(searchDetail, 300)
    const [pagingDetail, setPagingDetail] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Tên nhân viên kinh doanh</b>,
            dataIndex: 'full_name',
        },
        {
            title: <b>Nhóm khách hàng phụ trách</b>,
            dataIndex: 'customer_category',
            render: (_: any, record: any) => record?.Group?.name,
        },
        {
            title: <b>Số điện thoại</b>,
            dataIndex: 'phone_number',
        },
        {
            title: <b>Email</b>,
            dataIndex: 'email',
            render: (_: any, record: any) => record?.email,
        },
        {
            title: <b>Địa chỉ</b>,
            dataIndex: 'address',
            render: (_: any, record: any) => {
                return record?.province_id
                    ? provinces.find((item: any) => item?.id === Number(record?.province_id))?.name
                    : '---'
            },
        },
        {
            title: <b>Ngày tạo</b>,
            dataIndex: 'date',
            render: (_: any, record: any) => momentToStringDate(record?.created_at),
        },
    ]

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
                setlistKiotviet(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getListProvinces = async () => {
        try {
            const res: any = await getProvinces()
            if (res?.status) {
                setProvinces(res?.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getListStaff = async () => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: selectedKiotVietId,
                page: paging?.current,
                limit: paging?.pageSize,
                search: searchDebounce.trim(),
                from_date: fromDate,
                to_date: toDate,
                group_id: selectedGroupId,
            }
            const res = await getListSaleStaff(payload)
            if (res?.status) {
                const listData = res?.data?.map((item: any) => ({ ...item, key: item?.id }))
                setListSaleStaff(listData)
                const newPaging = {
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getDetailStaff = async () => {
        try {
            setLoading(true)
            let res: any
            const payload = {
                province_id: provinceId,
                search: searchDetailDebounce,
                page: pagingDetail.current,
                limit: pagingDetail.pageSize,
                from_date: fromDateDetail,
                to_date: toDateDetail,
            }
            if (currentTab === '1') {
                res = await getSaleStaffDetail(staffOpenId)
            } else {
                res = await getListCustomersByStaff(staffOpenId, payload)
            }

            if (res?.status) {
                setStaffDetail(res?.data)
                if (currentTab === '2') {
                    const newPaging = {
                        ...pagingDetail,
                        current: res?.paging?.page,
                        total: res?.paging?.totalItemCount,
                    }
                    setPagingDetail(newPaging)
                } else {
                    setCurrentRecord(res?.data)
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onDeleteStaff = async () => {
        try {
            setLoading(true)
            const res: any = await deleteSaleStaff(staffOpenId)
            if (res?.status) {
                message.success('Xoá nhân viên kinh doanh thành công!')
                getListStaff()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const changeStaffStatus = async () => {
        try {
            setLoading(true)
            const res: any = await changeSaleStaff(staffOpenId)
            if (res?.status) {
                message.success('Thay đổi trạng thái nhân viên thành công!')
                getListStaff()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleChangePassword = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                password: values.password,
            }
            const res: any = await changeSalePassword(staffOpenId, payload)
            if (res?.status) {
                message.success('Thay đổi mật khẩu thành công!')
                setIsCPModalOpen(false)
                getListStaff()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleAddUpdateStaff = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: values?.area,
                province_id: values?.province_id,
                gender: values?.gender,
                date_of_birth: values?.dob?.format('YYYY-MM-DD'),
                email: values?.email,
                phone_number: values?.phone,
                full_name: values?.name,
                group_id: values.customer_group_id,
            }
            let res: any
            if (currentRecord) {
                res = await updateSaleStaff(currentRecord?.id, payload)
            } else {
                res = await addSaleStaff(payload)
            }
            if (res?.status) {
                message.success(
                    currentRecord
                        ? 'Cập nhật nhân viên kinh doanh thành công!'
                        : 'Thêm mới nhân viên kinh doanh thành công!'
                )
                setIsModalOpen(false)
                getListStaff()

                if (currentRecord) {
                    getDetailStaff()
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onGetListCustomerGroup = async () => {
        try {
            const payload = {
                page: 1,
                limit: 999,
                kiotvietId: typeof selectedKiotVietId === 'string' ? -1 : selectedKiotVietId,
            }
            const res = await getListCustomerGroup(payload)
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setCustomerGroup(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onExportExcel = async () => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: selectedKiotVietId,
                search: searchDebounce.trim(),
                from_date: fromDate,
                to_date: toDate,
                group_id: selectedGroupId,
            }
            const res: any = await exportExcel(payload)
            if (res?.status) {
                message.success('Xuất file excel thành công!')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onExportExcelDetail = async () => {
        try {
            setLoading(true)
            const payload = {
                id: staffOpenId,
                province_id: provinceId,
                search: searchDetailDebounce,
                from_date: fromDateDetail,
                toDate: toDateDetail,
            }
            const res: any = await exportExcelDetail(payload)
            if (res?.status) {
                message.success('Xuất file excel thành công!')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleChangeStock = (idStock: any) => {
        setSelectedKiotVietId(idStock)
        setExpandedRowKeys([])
    }

    React.useEffect(() => {
        getDataKiotviet()
        getListProvinces()
    }, [])

    React.useEffect(() => {
        onGetListCustomerGroup()
    }, [selectedKiotVietId])

    React.useEffect(() => {
        getListStaff()
    }, [paging.current])

    React.useEffect(() => {
        setSearchDetail('')
        setProvinceId(undefined)
        setFromDateDetail('')
        setToDateDetail('')
        setPagingDetail({
            total: 0,
            current: 1,
            pageSize: 12,
        })

        if (pagingDetail.current === 1) {
            getDetailStaff()
        } else {
            setPagingDetail((prev: any) => ({ ...pagingDetail, current: 1 }))
        }
    }, [expandedRowKeys, currentTab])

    React.useEffect(() => {
        if (paging.current === 1) {
            getListStaff()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [searchDebounce, fromDate, toDate, selectedKiotVietId, selectedGroupId])

    React.useEffect(() => {
        if (pagingDetail.current === 1) {
            getDetailStaff()
        } else {
            setPagingDetail((prev: any) => ({ ...pagingDetail, current: 1 }))
        }
    }, [searchDetailDebounce, provinceId, fromDateDetail, toDateDetail])

    React.useEffect(() => {
        getDetailStaff()
    }, [staffOpenId, pagingDetail.current])

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        style={{ borderRadius: 8 }}
                        title="Danh sách Nhân viên kinh doanh"
                        extra={
                            !isSaleManager
                                ? [
                                      <ButtonSave
                                          onClickButton={onExportExcel}
                                          text="Xuất excel"
                                          icon={<VerticalAlignBottomOutlined />}
                                      />,
                                      <ButtonAdd
                                          text="Thêm mới"
                                          background="#47b2eb"
                                          onClickButton={() => {
                                              setIsAdd(true)
                                              setIsModalOpen(true)
                                          }}
                                      />,
                                  ]
                                : [
                                      <ButtonSave
                                          onClickButton={onExportExcel}
                                          text="Xuất excel"
                                          icon={<VerticalAlignBottomOutlined />}
                                      />,
                                  ]
                        }
                    ></PageHeader>
                }
                filterComponent={
                    <Filter search={search} setFromDate={setFromDate} setToDate={setToDate} setSearch={setSearch} />
                }
                contentComponent={[
                    <>
                        {(selectedKiotVietId || selectedKiotVietId?.length === 0) && (
                            <Radio.Group
                                value={selectedKiotVietId}
                                onChange={(e: any) => {
                                    handleChangeStock(e.target.value)
                                    setSelectedGroupId(undefined)
                                }}
                            >
                                <Radio.Button disabled={isSaleManager || isShopAdmin} value={''}>
                                    Tất cả
                                </Radio.Button>
                                {listKiotviet &&
                                    listKiotviet.map((region, index) => (
                                        <Radio.Button
                                            key={index}
                                            value={region.id}
                                            disabled={
                                                (isSaleManager && region.id !== userInfor?.admin?.kiotviet_id) ||
                                                (isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id)
                                            }
                                        >
                                            {region.name ? region.name : '--'}
                                        </Radio.Button>
                                    ))}
                            </Radio.Group>
                        )}
                        {isModalOpen && (
                            <AddEditBusinessStaffModal
                                currentRecord={currentRecord}
                                isAdd={isAdd}
                                handleOk={handleAddUpdateStaff}
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                listKiotviet={listKiotviet}
                                loading={loading}
                            />
                        )}
                        {isCPModalOpen && (
                            <ChangePasswordModal
                                visible={isCPModalOpen}
                                handleOk={handleChangePassword}
                                setIsModalOpen={setIsCPModalOpen}
                            />
                        )}
                        <Select
                            placeholder="Chọn nhóm khách hàng"
                            showArrow
                            showSearch
                            allowClear
                            value={selectedGroupId}
                            options={customerGroup}
                            style={{ marginLeft: 20 }}
                            filterOption={(input, option: any) => {
                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                return nonAccent.includes(nonAccentInput)
                            }}
                            onChange={(value: any) => setSelectedGroupId(value)}
                        />
                        <CustomRow>
                            <p>
                                Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                            </p>
                        </CustomRow>
                        <Table
                            id="top-table"
                            bordered
                            columns={columns}
                            dataSource={listSaleStaff}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: 'Chưa có bản ghi nào!',
                            }}
                            expandable={{
                                expandedRowRender: record => (
                                    <StaffInfo
                                        record={record}
                                        onDeleteStaff={onDeleteStaff}
                                        changeStaffStatus={changeStaffStatus}
                                        setIsModalOpen={setIsModalOpen}
                                        setIsCPModalOpen={setIsCPModalOpen}
                                        setCurrentTab={setCurrentTab}
                                        staffDetail={staffDetail}
                                        setSearchDetail={setSearchDetail}
                                        setProvinceId={setProvinceId}
                                        setToDateDetail={setToDateDetail}
                                        setFromDateDetail={setFromDateDetail}
                                        setPagingDetail={setPagingDetail}
                                        pagingDetail={pagingDetail}
                                        setIsAdd={setIsAdd}
                                        onExportExcelDetail={onExportExcelDetail}
                                        isSaleManager={isSaleManager}
                                    />
                                ),
                            }}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(expanded: boolean, record: any) => {
                                let keys = []
                                if (expanded) {
                                    keys.push(record.id)
                                }
                                setExpandedRowKeys(keys)
                                setStaffOpenId(record?.id)
                                setCurrentRecord(record)
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: page => {
                                    setPaging({ ...paging, current: page })
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    )
}

const CustomRow = styled(Row)`
    margin: 28px 0 0 0;

    .text {
        font-size: 12px;
        margin: 0;
    }
`

export default BusinessStaff
