/* eslint-disable react-hooks/exhaustive-deps */
import { Affix, Collapse, message, Space, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import PageHeader from 'common/components/pageHeader'
import { TableCutom } from 'common/components/table'
import { useDebounce } from 'common/hooks/Debounce'
import { requestGetUserInfo } from 'features/auth/AuthApi'
import 'moment/locale/vi'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { IS_ACTIVE, TEXT_ACTIVE } from 'utils/constants'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { AddEditTopicPost } from './components/AddEditTopicPost'
import { FilterTopic } from './components/Filter'
import { IPanigation, ITopicPayload } from './InterfaceTopic'
import { getTopic } from './TopicPostApi'
import TopicPostDetail from './TopicPostDetail'

const { Panel } = Collapse

const TopicPost = () => {
  const heightWeb = useSelector(
    (state: any) => state.configReducer.dimension.height
  )
  const [dataTopic, setDataTopic] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showAddAccount, setShowAddAccount] = useState<boolean>(false)
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [callback, setCallback] = useState(false)
  const [dataMe, setDataMe] = useState(false)
  const [params, setParams] = useState<ITopicPayload>({
    page: 1,
    limit: 12,
    search: undefined,
    status: undefined,
    from_date: '',
    to_date: '',
  })

  const [paging, setPaging] = useState<IPanigation>({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const handleCallback = () => {
    setCallback(!callback)
  }
  const debounced = useDebounce([params, callback], 900)

  useEffect(() => {
    getData()
    getDataMe()
  }, debounced)
  const getDataMe = async () => {
    const res = await requestGetUserInfo()
    setDataMe(res.data?.admin?.is_root)
  }
  const getData = async () => {
    setIsLoading(true)
    try {
      setCurrentSelected({ id: -1 })
      const res = await getTopic(params)
      const data_list = res.data.map((item: any) => {
        return { ...item, key: item.id }
      })
      const formattedPaging = {
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      }
      setPaging(formattedPaging)
      setDataTopic(data_list)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const columns: ColumnsType<any> = [
    {
      width: 70,
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (text, record, index) => (
        <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </td>
      ),
      ellipsis: true,
    },
    { title: 'Tên chủ đề', dataIndex: 'name', key: 'name' },
    {
      title: 'Thứ tự hiển thị ',
      dataIndex: 'order',
      align: 'center',
      key: 'order',
    },

    {
      width: '160px',
      title: 'Trạng thái',
      dataIndex: 'status',
      align: 'center',
      key: 'status',
      render: value => (
        <Tag color={value === IS_ACTIVE.ACTIVE ? 'green' : 'volcano'}>
          {value === IS_ACTIVE.ACTIVE
            ? TEXT_ACTIVE.ACTIVE
            : TEXT_ACTIVE.INACTIVE}
        </Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'create_at',
      key: 'create_at',
      align: 'center',
      render: value => {
        return <>{convertTimeStampToString(value)}</>
      },
    },
  ]

  return (
    <>
      <PageHeader
        title="Chủ đề"
        extra={[
          <Space>
            <FilterTopic
              dataMe={dataMe}
              setIsCreate={setShowAddAccount}
              isSearchLoading={isSearchLoading}
              onSearchSubmit={(searchKey: string) => {
                setParams({ ...params, search: searchKey.trim(), page: 1 })
              }}
              onStatusSubmit={(status: number) => {
                setParams({ ...params, status: status, page: 1 })
              }}
              onDateSubmit={(from_date: string, to_date: string) => {
                setParams({
                  ...params,
                  from_date: from_date,
                  to_date: to_date,
                  page: 1,
                })
              }}
            />
          </Space>,
        ]}
      />
      <TableCutom
        expand={(record: any) => {
          return (
            <TopicPostDetail
              dataMe={dataMe}
              data={record}
              getData={getData}
              handleCallback={handleCallback}
            />
          )
        }}
        isLoading={isLoading}
        columns={columns}
        clickRow={() => console.log('object')}
        data={dataTopic}
        paging={paging}
        changePaging={page => setParams({ ...params, page })}
      />
      {showAddAccount && (
        <AddEditTopicPost
          visible={showAddAccount}
          onCancel={() => {
            setShowAddAccount(false)
          }}
          isLoadingButton={isLoading}
          handleCallback={handleCallback}
        />
      )}
    </>
  )
}

export default TopicPost
