import { Layout } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import { setHeight } from 'common/ConfigSlice'
import Menu from 'common/components/Header'
import { SESSION_ID } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import LoginScreen from 'features/auth/Login'
import Blogs from 'features/blogs/Blogs'
import Cookie from 'js-cookie'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import { WebSocket } from 'services/WebSocket'
import history from 'utils/history'
import ToolSpamComment from '../features/tool-spam-comment/ToolSpamComment'
import Nav from './Nav'
import PrivateRoute from './PrivateRoute'

const { Sider } = Layout

interface Window {
    width: any
}

export default function AppNavigator() {
    const MainNavigator = () => {
        const dispatch = useDispatch()
        const cookie = Cookie.get(SESSION_ID)

        useEffect(() => {
            if (cookie) {
                dispatch(getUserInfoAction())
                WebSocket.init(cookie)
            }
            return () => {
                WebSocket.disconnect()
            }
        }, [cookie])

        useEffect(() => {
            dispatch(setHeight(document.body.scrollHeight))
        })
        return (
            <>
                <Layout style={{ minHeight: '100vh' }}>
                    <Sider trigger={null} breakpoint={'xl'} collapsible theme={'light'}>
                        <Menu />
                    </Sider>
                    <Layout>
                        <Header style={{ padding: 0, background: '#dddd', height: 50 }}></Header>
                        <Nav />
                    </Layout>
                </Layout>
            </>
        )
    }

    return (
        <Router history={history}>
            <Switch>
                <Route path={'/login'} exact component={LoginScreen} />
                <Route path={'/spam'} exact component={ToolSpamComment} />
                <Route path={'/blogs'} component={Blogs} />
                <PrivateRoute path={'/'} component={MainNavigator} />
            </Switch>
        </Router>
    )
}
