import { DatePicker, Form, Input, Modal, Radio, Select } from 'antd'
import ButtonForm from 'common/components/ModalButton'
import moment from 'moment'
import React from 'react'
import { getDetailCustomer, getProvinces } from '../CustomerAPI'
import { FIVE, MOBI, SEVEN, VIETTEL, VINA, toNonAccentVietnamese } from 'utils/Configs'

interface IUpdateCustomerModal {
    visible: boolean
    handleFinish: any
    data: any
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const UpdateCustomerModal = (props: IUpdateCustomerModal) => {
    const { visible, handleFinish, setVisible, data } = props
    const [form] = Form.useForm()
    const [isLoading, setisLoading] = React.useState<boolean>(false)
    const [provinces, setProvinces] = React.useState<any[]>([])

    const onCancel = () => {
        setVisible(false)
    }

    const getDataProvinces = async () => {
        try {
            const res = await getProvinces()
            if (res.data) {
                const newList = res?.data?.map((item: any) => ({ value: item?.id, label: item?.name }))
                setProvinces(newList)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getDefaultData = async () => {
        try {
            const res = await getDetailCustomer(data?.id)
            if (res?.status) {
                form.setFieldsValue({
                    name: res?.data?.full_name,
                    phone: res?.data?.phone_number,
                    gender: res?.data?.gender !== null ? res?.data?.gender : undefined,
                    dob: res?.data?.date_of_birth ? moment(res?.data?.date_of_birth) : null,
                    province: res?.data?.province_id,
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        getDataProvinces()
        getDefaultData()
    }, [])

    return (
        <Modal
            title="Cập nhật thông tin khách hàng"
            destroyOnClose
            width={'60%'}
            maskClosable={false}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} preserve={false} onFinish={handleFinish} form={form}>
                <Form.Item
                    name="name"
                    label="Tên khách hàng"
                    rules={[{ required: true, message: 'Vui lòng nhập tên khách hàng!' }]}
                >
                    <Input placeholder="Nhập tên khách hàng" allowClear />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Số điện thoại"
                    rules={[
                        { required: true, message: 'Vui lòng nhập số điện thoại!' },
                        // {
                        //     message: 'Số điện thoại không hợp lệ!',
                        //     validator: (_, value) => {
                        //         if (!value?.toString()?.trim()) return Promise.resolve()
                        //         if (
                        //             (!value?.toString()?.match(SEVEN) &&
                        //                 !value?.toString()?.match(MOBI) &&
                        //                 !value?.toString()?.match(VIETTEL) &&
                        //                 !value?.toString()?.match(VINA) &&
                        //                 !value?.toString()?.match(FIVE)) ||
                        //             isNaN(Number(value))
                        //         ) {
                        //             return Promise.reject()
                        //         }
                        //         return Promise.resolve()
                        //     },
                        // },
                    ]}
                >
                    <Input placeholder="Nhập số điện thoại" allowClear />
                </Form.Item>
                <Form.Item name="dob" label="Ngày sinh">
                    <DatePicker
                        style={{ width: '300px' }}
                        format={'DD-MM-YYYY'}
                        placeholder={'Chọn ngày sinh'}
                        className="rangerpicker-order"
                        disabledDate={(current: any) => {
                            return current && current > moment().endOf('day')
                        }}
                        onChange={async (value, dateString) => {}}
                    />
                </Form.Item>
                <Form.Item label="Giới tính" name="gender">
                    <Radio.Group>
                        <Radio value={'man'}>Nam</Radio>
                        <Radio value={'female'}>Nữ</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="province"
                    label="Tỉnh, thành phố"
                    rules={[{ required: true, message: 'Vui lòng chọn tỉnh, thành phố!' }]}
                >
                    <Select
                        placeholder="Chọn tỉnh, thành phố"
                        options={provinces}
                        showSearch
                        showArrow
                        filterOption={(input, option: any) => {
                            const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                            const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                            return nonAccent.includes(nonAccentInput)
                        }}
                    />
                </Form.Item>
                <ButtonForm isLoadingButton={isLoading} onCancel={onCancel} text={'Lưu'} />
            </Form>
        </Modal>
    )
}

export default UpdateCustomerModal
