import { Row, Descriptions, Spin, Empty } from 'antd'
import './css/Dashboash.css'
import {
  EyeOutlined,
  LikeOutlined,
  MessageOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons'
import { ICountLivestream } from './Model'
import { formatPrice } from 'utils/ruleForm'
type Props = {
  sumLiveStream?: any
}
export const ChartLive = ({ sumLiveStream }: Props) => {
  return (
    <Row style={{ padding: '15px 20px', width: '100%' }}>
      <div style={{ width: '50%' }}>
        <span style={{ fontWeight: 600, fontSize: '18px' }}>
          Tổng hợp live stream
        </span>
        <div className="divider"></div>
        <Descriptions style={{ paddingTop: '20px', paddingRight: '50px' }}>
          <Descriptions.Item label={<b>Số live stream</b>} span={3}>
            <span className="descriptions-live">
              {formatPrice(sumLiveStream?.count_livestreams)}
            </span>
            <div style={{ marginLeft: '20px' }}>
              <PlayCircleOutlined />
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={<b>Lượt xem</b>} span={3}>
            <span className="descriptions-live">
              {formatPrice(sumLiveStream?.count_viewes)}
            </span>
            <div style={{ marginLeft: '20px' }}>
              <EyeOutlined />
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={<b>Lượt bình luận</b>} span={3}>
            <span className="descriptions-live">
              {formatPrice(sumLiveStream?.count_comments)}
            </span>
            <div style={{ marginLeft: '20px' }}>
              <MessageOutlined />
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={<b>Lượt thích</b>} span={3}>
            <span className="descriptions-live">
              {formatPrice(sumLiveStream?.count_reactions)}
            </span>
            <div style={{ marginLeft: '20px' }}>
              <LikeOutlined />
            </div>
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div
        style={{
          width: '0.2%',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '15%',
            backgroundColor: 'white',
          }}
        ></div>
        <div
          style={{
            width: '100%',
            height: '85%',
            backgroundColor: 'rgb(220 223 228)',
          }}
        ></div>
      </div>
    </Row>
  )
}
