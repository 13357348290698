import { ApiClient } from 'services/ApiService'

export const getRefundOrderList = (payload: any) => ApiClient.get(`/admin/refund_order`, payload)

export const exportExcelRefundOrder = (payload: any) => ApiClient.get(`/admin/refund_order/exportExcel`, payload)

export const getRefundOrderDetail = (id: any) => ApiClient.get(`/admin/refund_order/${id}`)

export const deleteRefundOrder = (id: any) => ApiClient.delete(`/admin/refund_order/${id}`)

export const getAddressBookList = (id: any) => ApiClient.get(`/admin/user/${id}/addressBook`)

export const createRefundOrder = (payload: any) => ApiClient.post(`/admin/refund_order`, payload)

export const addAddress = (id?: number, payload?: any) => ApiClient.path(`/admin/user/${id}/address`, payload)

export const updaterefundOrder = (id: number, payload: any) => ApiClient.put(`/admin/refund_order/${id}`, payload)

export const updaterefundOrderNote = (id: any, payload: any) => ApiClient.put(`/admin/refund_order/${id}/note`, payload)

export const assignOrderForShipper = (id: any, payload: any) => ApiClient.post(`/admin/order_delivery/${id}`, payload)
