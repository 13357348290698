import { Checkbox, Radio, Row, Table, Tag, Tooltip, Typography, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import { IAuthState } from 'common/components/HeaderInterfaces'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ADMIN_ROLE, IS_ACTIVE } from 'utils/constants'
import { notificationError } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import {
    getCompletePriceJob,
    getFailedPriceJob,
    listProducts,
    retryUpdateJob,
    updateAllProductsPrice,
    updateMultipleProductsPrice,
} from './ProductAPI'
import { IFormatedTableProduct, IListProductPayload } from './ProductInterfaces'
import Filter from './component/Filter'
import UpdateProductPriceModal from './component/UpdateProductPriceModal'
import { resetProduct, setIsUpdateAllProduct, setMultipleProductIds, setOneProductId } from './ProductSlice'
import { RedoOutlined } from '@ant-design/icons'
import FaillProductModal from './FaillProductModal'

const { Text } = Typography

const WapperTable = styled.div`
    /* background-color: white;
  padding: 0 10px; */
`

function Products({ location }: any) {
    const authState: IAuthState = useSelector((state: any) => state.authReducer)
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN
    const history = useHistory()
    const dispatch = useDispatch()
    const selectedProductIds = useSelector((state: any) => state.productReducer.productIds)
    const isUpdateAllRedux = useSelector((state: any) => state.productReducer.isUpdateAll)
    const disSelectProductIds = useSelector((state: any) => state.productReducer.disSelectProductIds)
    const [regon, setRegion] = useState<any>('')
    const [selectedRowList, setSelectedRowList] = useState<Array<any>>([])
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [arrProducts, setarrProducts] = useState<IFormatedTableProduct[]>([])
    const [listKiotviet, setlistKiotviet] = useState<IKiotviet[]>()
    const [currentKiotVietID, setCurrentKiotVietID] = useState<any>(isShopAdmin ? userInfor?.admin?.kiotviet_id : '')
    const heightWeb = useSelector((state: any) => state.configReducer.dimension.height)
    const [isShowSynchButton, setIsShowSynchButton] = useState(true)
    const [listRowKeysSelected, setListRowKeysSelected] = useState<any[]>(selectedProductIds)
    const [selectedRows, setSelectedRows] = useState<any[]>([])
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [updateType, setUpdateType] = useState<number>(1)
    const [updatePercent, setUpdatePercent] = useState<number>(1)
    const [isUpdateAll, setIsUpdateAll] = useState<boolean>(isUpdateAllRedux)
    const [successUpdatedProductQuantity, setSuccessUpdatedProductQuantity] = useState<number>(0)
    const [failProductVisible, setFailproductVisible] = useState<boolean>(false)
    const [listFailProduct, setFailProduct] = useState<any[]>([])

    const [params, setParams] = useState<IListProductPayload>({
        limit: 12,
        page: location.state?.paging?.current ? location.state?.paging?.current : 1,
        category_id: location.state?.params?.category_id || '',
        search: location.state?.params?.search,
        status: location.state?.params?.status || '',
        kiotviet_id: location.state?.params?.kiotviet_id || '',
    })
    const [paging, setpaging] = useState({
        total: 0,
        current: location.state?.paging?.current ? location.state?.paging?.current : 1,
        pageSize: 0,
    })

    const [failPaging, setFailPaging] = useState({
        total: 0,
        current: 1,
        pageSize: 6,
    })

    const onGetCompletePriceJob = async () => {
        try {
            setisLoading(true)
            const payload = {
                page: 1,
                limit: 12,
            }
            const res: any = await getCompletePriceJob(payload)
            if (res?.status) {
                setSuccessUpdatedProductQuantity(res?.paging?.totalItemCount)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setisLoading(false)
        }
    }

    const onGetFailPriceJob = async () => {
        try {
            setisLoading(true)
            const payload = {
                page: failPaging.current,
                limit: failPaging.pageSize,
            }
            const res: any = await getFailedPriceJob(payload)
            if (res?.status) {
                setFailProduct(res?.data)
                setFailPaging({
                    total: res.paging?.totalItemCount,
                    current: res.paging?.page,
                    pageSize: res.paging?.limit,
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setisLoading(false)
        }
    }

    const onRetry = async () => {
        try {
            setisLoading(true)
            const payload = {
                job_name: 'price-single',
            }
            const res: any = await retryUpdateJob(payload)
            if (res?.status) {
                message.success('Đồng bộ lại thành công!')
                setFailproductVisible(false)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setisLoading(false)
        }
    }

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                setlistKiotviet(res.data)
            }
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra!')
        }
    }

    const getData = async () => {
        setisLoading(true)
        try {
            let res = await listProducts(params)
            if (res.data) {
                const dataTable = res.data.map((product, index) => ({
                    ...product,
                    key: product.id,
                    stt: index + 1,
                }))

                if (isUpdateAll) {
                    const actualDataTable = dataTable?.filter((item: any) => !disSelectProductIds.includes(item?.id))
                    const actualListKeys = actualDataTable?.map((item: any) => item?.id)

                    setSelectedRows(actualDataTable)
                    setListRowKeysSelected(actualListKeys)
                }

                setpaging({
                    total: res.paging?.totalItemCount,
                    current: res.paging?.page,
                    pageSize: res.paging?.limit,
                })
                setarrProducts(dataTable)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setisLoading(false)
        }
    }

    const handleSearchSubmit = (keyWord: string) => {
        setParams({
            ...params,
            search: keyWord,
            page: 1,
        })
    }
    const handleCategorySubmit = (cate: string) => {
        setParams({
            ...params,
            category_id: cate,
            page: 1,
        })
    }
    const handleStatusSubmit = (status: string) => {
        setParams({
            ...params,
            status: status,
            page: 1,
        })
    }

    const columns: ColumnsType<IFormatedTableProduct> = [
        Table.SELECTION_COLUMN,
        {
            width: 100,
            title: 'STT',
            dataIndex: 'stt',
            render: (text, record, index) => (
                <Text id={record.id.toString()}>{(paging.current - 1) * paging.pageSize + index + 1}</Text>
            ),
        },
        {
            width: 150,
            title: 'Mã sản phẩm',
            dataIndex: 'code',
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            render: name => (
                <Tooltip placement="topLeft" title={name}>
                    {name}
                </Tooltip>
            ),
        },
        {
            title: 'Giá bán (VNĐ)',
            dataIndex: 'price',
            render: price => {
                return <Text>{formatPrice(price)}</Text>
            },
        },
        {
            title: 'Giá bán lẻ (VNĐ)',
            dataIndex: 'retail_price',
            render: price => {
                return <Text>{formatPrice(price) || '---'}</Text>
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status =>
                status === IS_ACTIVE.ACTIVE ? (
                    <Tag color={'success'}>Đang hoạt động</Tag>
                ) : (
                    <Tag color={'volcano'}>Ngừng hoạt động</Tag>
                ),
        },
        {
            title: 'Tổng tồn',
            dataIndex: 'stock',
            render: totalStock => <td>{totalStock}</td>,
        },
    ]

    const handleChangeStock = (idStock: number) => {
        if (idStock) {
            setIsShowSynchButton(true)
        } else {
            setIsShowSynchButton(false)
        }
        setParams({ ...params, kiotviet_id: idStock, page: 1 })
        setCurrentKiotVietID(idStock)
        setRegion(idStock)
    }

    const onOpenModal = () => {
        if (selectedRows?.length === 0 && !isUpdateAll) {
            message.error('Vui lòng chọn ít nhất một sản phẩm!')
        } else {
            setIsModalOpen(true)
        }
    }

    const onUpdateProductPrice = async () => {
        try {
            setisLoading(true)

            if (!updatePercent) {
                message.error('Vui lòng % theo giá bán!')
                return
            }
            const payload = {
                type: updateType,
                percent: updatePercent,
                product_ids: !isUpdateAll ? selectedRows?.map((item: any) => item?.id) : undefined,
                kiotviet_id: undefined,
                exclude_product_ids: isUpdateAll ? disSelectProductIds : undefined,
            }

            let res: any
            if (!isUpdateAll) {
                res = await updateMultipleProductsPrice(payload)
            } else {
                res = await updateAllProductsPrice(payload, params)
            }

            if (res?.status) {
                message.success('Cập nhật giá bán sản phẩm thành công!')
                setListRowKeysSelected([])
                setSelectedRows([])
                setIsModalOpen(false)
                setUpdateType(1)
                setUpdatePercent(1)
                dispatch(resetProduct())
                setIsUpdateAll(false)
                if (!isUpdateAll) {
                    getData()
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setisLoading(false)
        }
    }

    const rowSelection = {
        selectedRowKeys: listRowKeysSelected,
        onSelect: (record: any, selected: any) => {
            if (selected) {
                setSelectedRows((prev: any) => [...prev, record])
                setListRowKeysSelected((prev: any) => [...prev, record?.id])
            } else {
                const newSelectedRows = selectedRows?.filter((item: any) => item?.id !== record?.id)
                setSelectedRows(newSelectedRows)

                const newListKeys = listRowKeysSelected?.filter((item: any) => item !== record?.id)
                setListRowKeysSelected(newListKeys)
            }
            dispatch(setOneProductId({ record, selected }))
        },

        onSelectAll: (selected: boolean, selectedRowList: any[], changeRows: any[]) => {
            if (selected) {
                setSelectedRows((prev: any) => [...prev, ...changeRows])
                const newListKeys = changeRows?.map((item: any) => item?.id)
                setListRowKeysSelected((prev: any) => [...prev, ...newListKeys])
                dispatch(setMultipleProductIds({ newListKeys, selected }))
            } else {
                const deselectedRowKeys = changeRows?.map((item: any) => item?.id)

                const newListKeys = listRowKeysSelected?.filter((item: any) => !deselectedRowKeys.includes(item))
                const newListRows = selectedRows?.filter((item: any) => newListKeys.includes(item?.id))

                setSelectedRows(newListRows)
                setListRowKeysSelected(newListKeys)
                if (isUpdateAll) {
                    dispatch(setMultipleProductIds({ newListKeys, selected, changeRows }))
                } else {
                    dispatch(setMultipleProductIds({ newListKeys, selected }))
                }
            }
        },
    }

    useEffect(() => {
        onGetCompletePriceJob()
    }, [])
    useEffect(() => {
        onGetFailPriceJob()
    }, [failPaging.current])

    useEffect(() => {
        getData()
    }, [params, isUpdateAll])

    useEffect(() => {
        getDataKiotviet()
        // if (authState?.userInfo?.admin?.kiotviet_id) {
        //     setRegion(authState.userInfo.admin?.kiotviet_id)
        // }
    }, [authState])

    return (
        <>
            {isModalOpen && (
                <UpdateProductPriceModal
                    isModalOpen={isModalOpen}
                    updateType={updateType}
                    updatePercent={updatePercent}
                    setUpdatePercent={setUpdatePercent}
                    setUpdateType={setUpdateType}
                    setIsModalOpen={setIsModalOpen}
                    onFinish={onUpdateProductPrice}
                    loading={isLoading}
                />
            )}
            <div className="filter">
                <Filter
                    visible={isShowSynchButton && regon !== undefined}
                    kiotVietId={currentKiotVietID}
                    onExport={selectedRowList}
                    region={regon}
                    onSearchSubmit={(keyWord: string) => handleSearchSubmit(keyWord)}
                    onCategorySubmit={(value: string) => handleCategorySubmit(value)}
                    onStatusSubmit={(value: string) => handleStatusSubmit(value)}
                    param={params}
                />
            </div>
            <div
                style={{
                    backgroundColor: 'white',
                    margin: '10px',
                    padding: 10,
                }}
            >
                <CustomRow>
                    <Radio.Group
                        style={{ padding: '10px' }}
                        value={regon}
                        onChange={(e: any) => {
                            handleChangeStock(e.target.value)
                            setpaging((prev: any) => ({ ...prev, current: 1 }))
                        }}
                    >
                        <Radio.Button disabled={isShopAdmin} value={''}>
                            Tất cả
                        </Radio.Button>
                        {listKiotviet &&
                            listKiotviet.map((region, index) => (
                                <Radio.Button
                                    key={index}
                                    value={region.id}
                                    disabled={isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id}
                                >
                                    {region.name ? region.name : '--'}
                                </Radio.Button>
                            ))}
                    </Radio.Group>
                    <div>
                        <ButtonAdd text="Cập nhật giá bán" onClickButton={onOpenModal} />
                        <ButtonAdd
                            onClickButton={() => {
                                onGetCompletePriceJob()
                                onGetFailPriceJob()
                                getData()
                            }}
                            text="Tải lại trang"
                            background="#e66fc8"
                            icon={<RedoOutlined />}
                            styleButton={{ marginLeft: 6 }}
                        />
                    </div>
                </CustomRow>
                <p style={{ padding: '15px 15px 0px' }}>
                    Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                </p>
                <CustomRow>
                    <Checkbox
                        checked={isUpdateAll}
                        onChange={(e: any) => {
                            setIsUpdateAll(e.target.checked)
                            dispatch(setIsUpdateAllProduct(e.target.checked))
                            if (e.target.checked) {
                            } else {
                                setListRowKeysSelected([])
                                setSelectedRows([])
                                dispatch(setMultipleProductIds({ newListKeys: [], status: false }))
                            }
                        }}
                    >
                        Chọn tất cả theo bộ lọc
                    </Checkbox>
                    {failProductVisible && (
                        <FaillProductModal
                            setFailPaging={setFailPaging}
                            failPaging={failPaging}
                            listFailProduct={listFailProduct}
                            visible={failProductVisible}
                            setVisible={setFailproductVisible}
                            onRetry={onRetry}
                        />
                    )}
                    <div className="refresh_block">
                        <p>
                            <span style={{ color: 'green' }}>
                                Thành công: {formatPrice(successUpdatedProductQuantity)}
                            </span>{' '}
                            -{' '}
                            <span
                                style={{ color: 'red', cursor: 'pointer' }}
                                onClick={() => setFailproductVisible(true)}
                            >
                                Thất bại: {formatPrice(failPaging.total)}
                            </span>
                        </p>
                    </div>
                </CustomRow>
                <br />
                <WapperTable>
                    <Table
                        dataSource={arrProducts}
                        bordered
                        scroll={
                            arrProducts?.length > 1
                                ? {
                                      x: 800,
                                      scrollToFirstRowOnChange: true,
                                      y: `calc(${heightWeb} - 300px)`,
                                  }
                                : {}
                        }
                        loading={isLoading}
                        columns={columns}
                        rowSelection={rowSelection}
                        onRow={(record, rowIndex) => ({
                            onClick: () => {
                                history.push({
                                    pathname: `${ADMIN_ROUTER_PATH.PRODUCT_DETAIL}/${record.id}`,
                                    state: {
                                        ...record,
                                        paging: paging,
                                        path: ADMIN_ROUTER_PATH.PRODUCT,
                                        params: params,
                                    },
                                })
                            },
                        })}
                        pagination={{
                            ...paging,
                            showSizeChanger: false,
                            onChange: async page => {
                                setParams({ ...params, page })
                            },
                        }}
                    />
                </WapperTable>
            </div>
        </>
    )
}

const CustomRow = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
    }

    .refresh_block {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export default memo(Products)
