import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Col,
  Comment,
  Descriptions,
  Empty,
  message,
  PageHeader,
  Row,
  Spin,
} from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { formatPrice } from 'utils/ruleForm'
import { convertTimeStampToString } from 'utils/TimerHelper'
import {
  getListComment,
  getLiveStreamDetail as getLivestreamDetail,
} from '../LivestreamAPI'
import { IListComment, ILivestreamDetail } from '../LivestreamInterfaces'

const WapperContent = styled.div<any>`
  background-color: white;
  padding: ${props => (props.p ? '15px' : '5px')};
  margin: 10px;
`
interface IProps {
  location: any
}

function LivestreamDetail(props: IProps) {
  const data = props.location?.state?.record
  const [listComment, setlistComment] = useState<IListComment[]>([])
  const history = useHistory()
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [livestreamDetail, setlivestreamDetail] = useState<ILivestreamDetail>()
  const [params, setparams] = useState<any>({
    livestream_id: data.id,
    q: undefined,
    page: 1,
    limit: 12,
  })

  const getDataLivestreamDetail = async () => {
    const res = await getLivestreamDetail(data.id)
    if (res.data) {
      setlivestreamDetail(res.data)
    }
  }

  useEffect(() => {
    getDataLivestreamDetail()
  }, [])

  const getDataComment = async () => {
    setisLoading(true)
    try {
      const res = await getListComment(params)
      if (res.data) {
        setlistComment(res.data)
      }
      if (res) {
      }
    } catch (err) {
      console.log(err)
      message.error('Có lỗi xảy ra')
    } finally {
      setisLoading(false)
    }
  }

  useEffect(() => {
    getDataComment()
  }, [params])

  return (
    <>
      <WapperContent>
        <PageHeader
          title="Chi tiết livestream"
          onBack={() => history.goBack()}
          extra={[
            <Button danger icon={<CloseCircleOutlined />}>
              Xoá
            </Button>,
            <Button type="primary" icon={<UploadOutlined />}>
              Export bình luận
            </Button>,
          ]}
        />
      </WapperContent>
      <WapperContent p>
        <Descriptions title="THÔNG TIN LIVESTREAM">
          <Descriptions.Item label="Lượt xem">
            {formatPrice(livestreamDetail?.count_viewed)}
          </Descriptions.Item>
          <Descriptions.Item label="Lượt thích">
            {formatPrice(livestreamDetail?.count_reaction)}
          </Descriptions.Item>
          <Descriptions.Item label="Bình luận">
            {formatPrice(livestreamDetail?.count_comment)}
          </Descriptions.Item>
          <Descriptions.Item label="Thời lượng">
            {livestreamDetail?.minutes_used_before_livestream &&
              convertTimeStampToString(
                livestreamDetail?.minutes_used_before_livestream
              )}
          </Descriptions.Item>
          <Descriptions.Item label="Ngày livestream">
            {livestreamDetail?.start_at &&
              convertTimeStampToString(livestreamDetail?.start_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Kết thúc">
            {livestreamDetail?.finish_at &&
              convertTimeStampToString(livestreamDetail?.finish_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Mô tả">shop</Descriptions.Item>
        </Descriptions>
      </WapperContent>
      <WapperContent p>
        <Row>
          <Col span={10}>
            <WapperContent p>
              <Empty children="" />
            </WapperContent>
          </Col>
          <Col span={12}>
            <Descriptions title="BÌNH LUẬN">
              <Descriptions.Item span={3}>
                <TypingAutoSearch
                  onSearchSubmit={(value: string) =>
                    setparams({ ...params, q: value })
                  }
                  placeholder="Tên khách hàng hoặc nội dung bình luận..."
                />
              </Descriptions.Item>
              <Descriptions.Item>
                <WapperContent>
                  {listComment.length > 0 ? (
                    <Spin spinning={isLoading}>
                      {listComment.map((item, index) => (
                        <Wapper>
                          <Comment
                            key={index}
                            author={item.User.full_name}
                            avatar={
                              <Avatar
                                src={item.User.avatar}
                                alt={item.User.full_name}
                              />
                            }
                            content={<p>{item.content}</p>}
                          />
                        </Wapper>
                      ))}
                    </Spin>
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={<span>Không có bình luận.</span>}
                    />
                  )}
                </WapperContent>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </WapperContent>
    </>
  )
}

export default LivestreamDetail

const Wapper = styled.div`
  margin-bottom: 20px;
`
