import { Checkbox, Input, Modal, Upload } from 'antd'
import UploadComponent from 'common/components/Upload'

interface ISendMessageModal {
    isModalOpen: boolean
    onFinish: () => void
    setMsgContent?: any
    setIsModalOpen: any
    setMsgImage?: any
    setIsSendAll?: any
}

const SendMessageModal = (props: ISendMessageModal) => {
    const { isModalOpen, onFinish, setIsModalOpen, setMsgContent, setMsgImage, setIsSendAll } = props

    const onCancel = () => {
        setIsModalOpen(false)
    }
    return (
        <Modal
            centered
            title="Gửi tin nhắn hàng loạt"
            okText="Gửi"
            visible={isModalOpen}
            onOk={onFinish}
            onCancel={onCancel}
        >
            <p>
                Nội dung tin nhắn <span style={{ color: 'red' }}>*</span>
            </p>
            <Input.TextArea
                onChange={(e: any) => setMsgContent(e.target.value)}
                placeholder="Nhập nội dung tin nhắn"
                allowClear
                rows={5}
            />
            <br />
            <br />
            <p>Hình ảnh</p>
            <UploadComponent
                isUploadServerWhenUploading
                uploadType="single"
                listType="picture-card"
                isShowFileList
                maxLength={1}
                onSuccessUpload={(result: any) => {
                    setMsgImage(result?.url)
                }}
            />
            <br />
            <br />
            <Checkbox onChange={(e: any) => setIsSendAll(e.target.checked)}>Gửi cho tất cả khách hàng</Checkbox>
        </Modal>
    )
}

export default SendMessageModal
