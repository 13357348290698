import { ApiClient } from 'services/ApiService'

export const getListNotification = (payload: any) =>
  ApiClient.get('/notification', payload)

export const getCountNotification = () =>
  ApiClient.get('/notification/count-noti')

//get notification shop
export const getListNotificationShop = (param: any) =>
  ApiClient.get('/notification/list-noti-shop', param)

export const getCountNotificationOrderShop = () =>
  ApiClient.get('/notification/count-noti-shop')

export const readNotification = (id: number) =>
  ApiClient.put(`/notification/${id}`)

export const requestMarkAllAsRead = () =>
  ApiClient.put(`/notification/mark-as-read-all`)
