import { ApiClient } from 'services/ApiService'

export const communityPostService = {
  uploadMiltipleImage: async (fmData: any) => {
    const headers = {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
    }
    return await ApiClient.postUploadFile('/files/upload/multiple/1', fmData, {
      headers,
    })
  },
}
export const communityPostVideoService = {
  uploadMiltipleVideo: async (fmData: any) => {
    const headers = {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
    }
    return await ApiClient.postUploadFile('/files/upload/multiple/2', fmData, {
      headers,
    })
  },
}