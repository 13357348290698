import { Col, Row, Avatar, Spin } from 'antd'
import { get_ss_hh_dd_mm } from 'utils/TimerHelper'
import { LoadingOutlined } from '@ant-design/icons'
import R from 'utils/R'

interface ChatHeaderProps {
    name?: string
    lastSendMessage?: string | number | Date | undefined
    avatar?: string | null
    isLoading: boolean
    phone?: number
}
function ChatHeader({ name, lastSendMessage, avatar, isLoading, phone }: ChatHeaderProps) {
    return (
        <Row
            align="middle"
            style={{
                height: '9%',
                borderBottom: '3px solid #C8C8C8',
            }}
        >
            {isLoading ? (
                <Spin style={{ marginLeft: 10 }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
            ) : (
                <>
                    <Col lg={2} md={3} xs={4}>
                        <Row justify="center" style={{ width: '100%', height: '100%' }}>
                            <Avatar
                                size={40}
                                src={avatar ? avatar : ''}
                                icon={<img src={R.images.img_ogo} />} // <UserOutlined />
                            />
                        </Row>
                    </Col>
                    <Col lg={22} md={21} xs={20}>
                        <Row>
                            <strong
                                style={{
                                    fontSize: 'large',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {' '}
                                {name}
                            </strong>
                            <span
                                style={{
                                    marginLeft: 10,
                                    fontSize: 'large',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    verticalAlign: 'middle',
                                }}
                            >
                                ({phone})
                            </span>
                        </Row>
                        <Row>
                            <span style={{ fontSize: 'small' }}>{get_ss_hh_dd_mm(lastSendMessage as string)}</span>
                        </Row>
                    </Col>
                </>
            )}
        </Row>
    )
}
export default ChatHeader
