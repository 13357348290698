import { DeleteFilled, EditOutlined } from '@ant-design/icons'
import { Button, Card, Popconfirm, Row, Table } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface IOrderDetail {
    data: any
    deleteOrder: () => Promise<void>
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const OrderDetail = (props: IOrderDetail) => {
    const { data, deleteOrder, setIsModalOpen } = props
    const history = useHistory()
    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => index + 1,
        },
        {
            title: <b>Tên sản phẩm</b>,
            dataIndex: 'product_name',
        },
        {
            title: <b>Số lượng</b>,
            dataIndex: 'quantity',
        },
        {
            title: <b>Đơn vị tính</b>,
            dataIndex: 'product_unit',
            render: (value: any) => value || '---',
        },
    ]
    return (
        <Card
            actions={[
                <Button
                    onClick={() => {
                        history.push({
                            pathname: ADMIN_ROUTER_PATH.ADD_EDIT_REFUND_ORDER,
                            state: {
                                orderId: data?.id,
                            },
                        })
                    }}
                    type="text"
                    size="large"
                    style={{ color: 'green' }}
                    icon={<EditOutlined />}
                    children={'Chỉnh sửa'}
                />,
                <Popconfirm
                    title={'Bạn có chắc chắn muốn XOÁ đơn hàng này?'}
                    onConfirm={deleteOrder}
                    okText={'Xoá'}
                    cancelText={'Đóng'}
                >
                    <Button
                        type="text"
                        size="large"
                        style={{ color: '#cc0000' }}
                        icon={<DeleteFilled />}
                        children={'Xoá'}
                    />
                </Popconfirm>,
            ]}
        >
            <CustomRow>
                <CustomCard title="Thông tin khách hàng" bordered={true}>
                    <Row className="row">
                        <span>
                            Tên: <b>{data?.user?.name}</b>
                        </span>
                        <span>
                            SĐT: <b>{data?.user?.phone_number}</b>
                        </span>
                    </Row>
                    <Row className="row">
                        <span>
                            Địa chỉ:{' '}
                            <b>
                                {data?.shipping_name} - {data?.phone_number} - {data?.shipping_address}
                            </b>
                        </span>
                    </Row>
                    <Row className="row">
                        <span>
                            Ghi chú: <b>{data?.note}</b>
                        </span>
                        <EditOutlined style={{ cursor: 'pointer' }} onClick={() => setIsModalOpen(true)} />
                    </Row>
                </CustomCard>
                <CustomCard title="Thông tin sản phẩm" bordered={true}>
                    <Table
                        id="top-table"
                        bordered
                        columns={columns}
                        dataSource={data?.items}
                        scroll={{
                            x: 400,
                            scrollToFirstRowOnChange: true,
                        }}
                        locale={{
                            emptyText: 'Chưa có bản ghi nào!',
                        }}
                        pagination={false}
                    />
                </CustomCard>
            </CustomRow>
        </Card>
    )
}

const CustomRow = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const CustomCard = styled(Card)`
    width: 49%;
    border-radius: 10px;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-wrap: wrap;
    }
`

export default OrderDetail
