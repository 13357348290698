import { ApiClient } from 'services/ApiService'
import {
    IConfig,
    IDefaultBranch,
    IKiovietDetail,
    ILinkKiotvietPayload,
    IListBranch,
    IListKiotviet,
    ResponseData,
} from './ConfigIntefaces'

export const getListKiotviet = (): Promise<ResponseData<IListKiotviet[]>> => {
    return ApiClient.get('/admin/kiotviet')
}

export const getNewKiotviet = (payload: ILinkKiotvietPayload) => {
    return ApiClient.post('/admin/kiotviet', payload)
}

export const getBranchKiotviet = (id: number) => {
    return ApiClient.path(`/admin/kiotviet/${id}/list_branch`)
}

export const setDefaultBranch = (payload: any) => {
    return ApiClient.put(`/admin/kiotviet/${payload.id}`, payload.body)
}

export const getConfiguration = (): Promise<ResponseData<IConfig>> => {
    return ApiClient.get('/admin/configuration')
}
export const setConfiguration = (payload: IConfig): Promise<ResponseData<IConfig[]>> => {
    return ApiClient.post('/admin/configuration', payload)
}

export const getListBranch = (id: number): Promise<ResponseData<IListBranch[]>> => {
    return ApiClient.get(`/admin/kiotviet/${id}/list_branch`, id)
}

export const getListCustomerGroup = (id: number): Promise<ResponseData<IListBranch[]>> => {
    return ApiClient.get(`/admin/kiotviet/${id}/list_customers_group`, id)
}

export const getListPrice = (id: number): Promise<ResponseData<IListBranch[]>> => {
    return ApiClient.get(`/admin/kiotviet/${id}/list_price_book`, id)
}

export const getKiovietDetail = (kiotviet_id: number): Promise<ResponseData<IKiovietDetail>> => {
    return ApiClient.get(`/admin/kiotviet/${kiotviet_id}`, kiotviet_id)
}
export const AddAccountLive = (payload: any) => {
    return ApiClient.post(`/admin/service/youtube/auth/&token=${payload}`)
}
export const requestCreateGoogleAccount = () => ApiClient.post('admin/service/youtube/auth')

export const getConfigLivestream = () => ApiClient.get(`/admin/service/youtube`)

export const getListKIndex = () => ApiClient.get(`/admin/configuration/k_coefficient`)

export const updateKIndex = (payload: any) => ApiClient.post(`/admin/configuration/k_coefficient`, payload)

export const getAppUseReport = () => ApiClient.get(`/admin/configuration/appUsageReport`)

export const autheYoutube = (payload: any) => ApiClient.post(`/admin/service/youtube/auth`, payload)
