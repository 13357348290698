import { Button, Descriptions, PageHeader, Tabs } from 'antd'
import LiveStream from './Livestreams'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Orders from './Orders'
import Products from './Products'
import ShopInfo from './ShopInfo'
import { ADMIN_ROUTER_PATH } from 'common/config'
const { TabPane } = Tabs

interface IWapperStyle {
  padding?: boolean
}

const Wapper = styled.div<IWapperStyle>`
  background-color: white;
  border-radius: 5px;
  margin: 10px;
  padding: ${props => (props.padding ? '10px' : null)};
`

function DetailConfig({ location }: any) {
  const history = useHistory()
  return (
    <>
      <Wapper>
        <PageHeader
          onBack={() =>
            history.push({
              pathname: ADMIN_ROUTER_PATH.CONFIG,
              state: {
                keyTab: location.state?.keyTab,
              },
            })
          }
          title="Chi tiết gian hàng"
          extra={[<Button type="primary">Ngừng hoạt động</Button>]}
        />
      </Wapper>
      <Wapper padding>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Thông tin gian hàng" key="1">
            <ShopInfo location={location} />
          </TabPane>
          {/* <TabPane tab="Sản phẩm" key="2">
            <Products />
          </TabPane>
          <TabPane tab="Đơn hàng" key="3">
            <Orders />
          </TabPane>
          <TabPane tab="Livestream" key="4">
            <LiveStream />
          </TabPane> */}
        </Tabs>
      </Wapper>
    </>
  )
}

export default DetailConfig
