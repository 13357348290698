import { Col, DatePicker, Row, Select } from 'antd'
import ButtonFixed from 'common/components/ButtonFixed'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import styled from 'styled-components'
import { IS_ACTIVE, TEXT_ACTIVE } from 'utils/constants'
import './css/Header.css'
const { Option } = Select
const { RangePicker } = DatePicker
const Container = styled.div`
    width: 100%;
    padding: 0.5rem;
    background-color: white;
    border-bottom: 1px solid #dddd;
`

interface IFilterProps {
    dataMe: boolean
    setIsCreate: any
    onSearchSubmit: (searchKey: string) => void
    isSearchLoading: boolean
    onStatusSubmit: (status: number) => void
    onDateSubmit: (from_date: string, to_date: string) => void
}

export const FilterTopic = ({ dataMe, setIsCreate, onDateSubmit, onStatusSubmit, onSearchSubmit }: IFilterProps) => {
    return (
        <Row gutter={[16, 16]} justify="end">
            <Col>
                <TypingAutoSearch
                    width={270}
                    placeholder="Tìm kiếm theo tên hoặc mô tả"
                    onSearchSubmit={(value: string) => onSearchSubmit(value)}
                />
            </Col>
            <Col>
                <Select
                    allowClear
                    style={{ width: '170px' }}
                    placeholder="Trạng thái"
                    onChange={(value: number) => {
                        onStatusSubmit(value)
                    }}
                >
                    <Option value={IS_ACTIVE.ACTIVE}>{TEXT_ACTIVE.ACTIVE}</Option>
                    <Option value={IS_ACTIVE.INACTIVE}>{TEXT_ACTIVE.INACTIVE}</Option>
                </Select>
            </Col>
            <Col>
                <RangePicker
                    placeholder={['Từ ngày', 'Đến ngày']}
                    onChange={(value, dateString) => {
                        onDateSubmit(dateString[0], dateString[1])
                    }}
                />
            </Col>
            <Col>{dataMe ? <ButtonFixed setIsCreate={setIsCreate} text="Thêm mới" type="add" /> : <></>}</Col>
        </Row>
    )
}
