import { CheckCircleOutlined, DollarOutlined, EditOutlined, YuqueOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Radio, Row, Spin, Switch, Table, Tabs, Typography, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import ButtonSave from 'common/components/Button/ButtonSave'
import { IAuthState } from 'common/components/HeaderInterfaces'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { ADMIN_ROLE, IS_ACTIVE } from 'utils/constants'
import { notificationError } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { changeStatus, getCustomers, sendMessage } from '../CustomerAPI'
import { IFormateDataCustomers, IFormatedPaging } from '../CustomerInterfaces'
import {
    resetSelectedCustomer,
    setIsUpdateAllCustomer,
    setOneSelectedCustomer,
    setSelectedCustomer,
} from '../CustomerSlice'
import ChangePasswordModal from './ChangePasswordModal'
import CustomerInfo from './DetailCustomer/CustomerInfo'
import DebtNoteHistory from './DetailCustomer/DebtNoteHistory'
import HistoryCoin from './DetailCustomer/HistoryCoin'
import HistoryPoints from './DetailCustomer/HistoryPoints'
import OrderInfo from './DetailCustomer/OrderInfo'
import Header from './Header'
import SendMessageModal from './SendMessageModal'
import CoinModal from './Topup'

const { Text } = Typography

const ButtonStyled = styled(Text)<any>`
    font-weight: 600;
    color: ${props => (props.color ? '#18a84f' : '#ce8d16')};
`

const TextStyled = styled(Text)<any>`
    font-weight: 600;
    padding-left: 10px;
    color: ${props => (props.color ? '#00abba' : '#f73152')};
`

interface IPropsSytle {
    padding?: any
}

export const WapperComponent = styled.div<IPropsSytle>`
    background-color: white;
    border-radius: 5px;
    margin: 7px;
    padding: ${(props: any) => (props.padding ? '15px' : null)};
`

function Customers({ location }: any) {
    const dispatch = useDispatch()
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isSaleManager = userInfor?.admin?.role === ADMIN_ROLE.SALE_MANAGER
    const isAccountant = userInfor?.admin?.role === ADMIN_ROLE.ACCOUNTANT
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN
    const selectedCustomers = useSelector((state: any) => state.customerReducer.data)
    const disSelecCustomerIds = useSelector((state: any) => state.customerReducer.disSelecCustomerIds)
    const isUpdateAllRedux = useSelector((state: any) => state.customerReducer.isUpdateAll)

    const [record, setRecord] = useState<IFormateDataCustomers>()
    const [isShowModal, setisShowModal] = useState<boolean>(false)
    const [isShowModalCoin, setIsShowModalCoin] = useState<boolean>(false)
    // const [tab, setTab] = useState<string>(location.state?.key)
    const [tab, setTab] = useState<string>('1')
    const [isStatus, setisStatus] = useState<boolean>(false)
    const [currentKiotVietID, setCurrentKiotVietID] = useState<string | undefined>(
        isAccountant || isSaleManager || isShopAdmin ? userInfor?.admin?.kiotviet_id : undefined
    )
    const [callback, setCallback] = useState(false)
    const [listKiotviet, setlistKiotviet] = useState<IKiotviet[]>()
    const authState: IAuthState = useSelector((state: any) => state.authReducer)
    const [currentSelected, setcurrentSelected] = useState<any>({ id: -1 })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [region, setRegion] = useState<string>(
        isSaleManager || isAccountant || isShopAdmin ? userInfor?.admin?.kiotviet_id : undefined
    )
    const [listRowKeysSelected, setListRowKeysSelected] = useState<any[]>(selectedCustomers)
    const [selectedRows, setSelectedRows] = useState<any[]>([])
    const [msgContent, setMsgContent] = useState<string>()
    const [msgImage, setMsgImage] = useState<string>()
    const [listCustomer, setlistCustomer] = useState<IFormateDataCustomers[]>([])
    const [isShowSynchButton, setIsShowSynchButton] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isSendAll, setIsSendAll] = useState<boolean>(isUpdateAllRedux)
    const [isApplyDebtModal, setIsApplyDebtModal] = useState<boolean>(isUpdateAllRedux)
    const [numberOfDebit, setNumberOfDebit] = useState<number>(0)

    const [defaultSearch, setDefaultSearch] = useState<string>(location?.state?.phone_number || '')

    const [params, setParams] = useState<any>({
        page: location.state?.page?.current,
        limit: '12',
        search: location?.state?.phone_number,
        province_id: '',
        create_from: '',
        create_to: '',
        kiotviet_id: isSaleManager || isAccountant || isShopAdmin ? currentKiotVietID : '',
        is_retail_cus: '0',
    })
    const [paging, setPaging] = useState<IFormatedPaging>({
        total: 0,
        current: 1,
        pageSize: 0,
    })

    const getNumberOfDebt = async () => {
        setIsLoading(true)
        try {
            const payload = {
                page: location.state?.page?.current,
                limit: '12',
                search: '',
                province_id: '',
                create_from: '',
                create_to: '',
                kiotviet_id: '',
                is_retail_cus: '0',
                is_debt: 1,
            }
            const res: any = await getCustomers(payload)
            if (res.status) {
                setNumberOfDebit(res.paging.totalItemCount)
            }
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }
    const getDataCustomer = async () => {
        setIsLoading(true)
        try {
            const res = await getCustomers(params)
            if (res.data) {
                const dataCustomer = res.data?.map((customer, index) => {
                    return {
                        ...customer,
                        stt: index + 1,
                        key: customer?.id,
                    }
                })

                if (isSendAll) {
                    const actualDataTable = dataCustomer?.filter((item: any) => !disSelecCustomerIds.includes(item?.id))
                    const actualListKeys = actualDataTable?.map((item: any) => item?.id)

                    setSelectedRows(actualDataTable)
                    setListRowKeysSelected(actualListKeys)
                }

                setlistCustomer(dataCustomer)
            }
            if (res.paging) {
                setPaging({
                    total: res.paging.totalItemCount,
                    current: res.paging.page,
                    pageSize: res.paging.limit,
                })
            }
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleCallback = () => {
        setCallback(!callback)
    }

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                setlistKiotviet(res.data)
            }
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra!')
        }
    }

    const handleChaneStatus = async (status: boolean, record: any) => {
        try {
            const res = await changeStatus({
                id: record.id,
                status: status ? IS_ACTIVE.ACTIVE : IS_ACTIVE.INACTIVE,
            })
            if (res.data) {
                getDataCustomer()
                message.success('Thay đổi trạng thái thành công')
                if (status) {
                    setRecord(record)
                }
            }
        } catch (err) {
            console.log(err)
            message.error('Có lỗi xảy ra')
        }
        setisStatus(status)
    }

    const handleClickActive = async (record: IFormateDataCustomers) => {
        console.log(record)
        try {
            const res = await changeStatus({
                id: record.id,
                livestream_enable:
                    record.livestream_enable == IS_ACTIVE.INACTIVE ? IS_ACTIVE.ACTIVE : IS_ACTIVE.INACTIVE,
            })
            if (res.data) {
                getDataCustomer()
                message.success('Thay đổi thành công')
            }
        } catch (err) {
            console.log(err)
        }
    }

    const onSendMessage = async () => {
        try {
            setIsLoading(false)

            if (!msgContent || msgContent.length === 0) {
                message.error('Vui lòng nhập nội dung tin nhắn!')
                return false
            }

            const payload = {
                content: msgContent,
                user_ids: selectedRows?.map((item: any) => item?.id),
                message_media_url: msgImage,
                is_to_all_users: isSendAll ? 1 : 0,
            }

            const res = await sendMessage(payload, params)

            if (res?.status) {
                message.success('Gửi tin nhắn thành công!')
                setMsgContent('')
                setIsModalOpen(false)
                setListRowKeysSelected([])
                dispatch(resetSelectedCustomer())
                setSelectedRows([])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const columns: ColumnsType<IFormateDataCustomers> = [
        Table.SELECTION_COLUMN,
        {
            title: 'STT',
            dataIndex: '',
            width: 70,
            render: (text, record, index) => (
                <Text id={record.id.toString()}>{(paging.current - 1) * paging.pageSize + index + 1}</Text>
            ),
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'full_name',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
        },
        {
            width: 130,
            title: 'Số điện thoại',
            dataIndex: 'phone_number',
        },
        {
            title: 'Tỉnh thành phố',
            dataIndex: 'province_id',
            render: (value: any, record: any) => record?.province?.name || '---',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: 'Công nợ',
            dataIndex: 'debt',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: value => {
                return <Text>{value ? `${formatPrice(value)} đ` : '0 đ'}</Text>
            },
            align: 'center',
        },
        {
            title: 'Ghi chú công nợ',
            dataIndex: 'debit_note',
            width: 250,
            ellipsis: {
                showTitle: false,
            },
            render: value => {
                return <Text>{value || '---'}</Text>
            },
            align: 'center',
        },
        {
            title: 'Xu',
            dataIndex: 'wallet',
            width: 100,
            ellipsis: {
                showTitle: false,
            },
            align: 'center',
            render: value => {
                return <Text>{value.coin ? `${formatPrice(value.coin)} xu` : '0 xu'}</Text>
            },
        },
        {
            title: 'Doanh số trên đơn hàng',
            dataIndex: 'turnover_of_order',
            width: 150,
            render: value => {
                return <Text>{value ? `${formatPrice(value)} đ` : '0 đ'}</Text>
            },
        },
        {
            title: 'Doanh số thực tế',
            dataIndex: 'turnover',
            width: 160,
            render: value => {
                return <Text>{value ? `${formatPrice(value)} đ` : '0 đ'}</Text>
            },
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_at',
            width: 120,
            render: created_at => {
                return <Text>{convertTimeStampToString(created_at)}</Text>
            },
        },
    ]

    useEffect(() => {
        getDataCustomer()
    }, [params, isSendAll])

    useEffect(() => {
        setParams({ ...params, search: location?.state?.phone_number })
        setDefaultSearch(location?.state?.phone_number || '')
    }, [location?.state?.phone_number])

    useEffect(() => {
        getDataKiotviet()
    }, [authState])

    useEffect(() => {
        getNumberOfDebt()
    }, [])

    return (
        <>
            {isShowModal && (
                <ChangePasswordModal data={record} visible={isShowModal} cancel={() => setisShowModal(false)} />
            )}

            <CoinModal
                visible={isShowModalCoin}
                cancel={() => setIsShowModalCoin(false)}
                data={record}
                handleCallback={handleCallback}
            />
            <Header
                title={'Khách hệ thống'}
                params={params}
                defaultSearch={defaultSearch}
                dataCustomer={listCustomer}
                onSubmidCity={(value: string) =>
                    setParams({
                        ...params,
                        province_id: value,
                        page: 1,
                    })
                }
                region={region}
                visible={isShowSynchButton && region !== undefined && region !== ''}
                kiotvietID={currentKiotVietID}
                onSubmitTime={(value: Array<string>) =>
                    setParams({
                        ...params,
                        create_from: value[0],
                        create_to: value[1],
                        page: 1,
                    })
                }
                onSubmitKeyword={(value: string) => {
                    setParams({
                        ...params,
                        search: value,
                        page: 1,
                    })
                    setcurrentSelected(null)
                }}
            />
            {isModalOpen && (
                <SendMessageModal
                    setIsModalOpen={setIsModalOpen}
                    setMsgContent={setMsgContent}
                    isModalOpen={isModalOpen}
                    onFinish={onSendMessage}
                    setMsgImage={setMsgImage}
                    setIsSendAll={setIsSendAll}
                />
            )}
            <WapperComponent padding>
                <CustomRow>
                    <Radio.Group
                        defaultValue={region}
                        onChange={(e: any) => {
                            if (e) {
                                setIsShowSynchButton(true)
                            } else {
                                setIsShowSynchButton(false)
                            }
                            setCurrentKiotVietID(e.target.value)
                            setcurrentSelected(null)
                            setRegion(e.target.value)
                            setListRowKeysSelected([])
                            setSelectedRows([])
                            dispatch(resetSelectedCustomer())
                            setParams({ ...params, page: '1', kiotviet_id: e.target.value })
                        }}
                    >
                        <Radio.Button disabled={isSaleManager || isAccountant || isShopAdmin} value={undefined}>
                            Tất cả
                        </Radio.Button>
                        {listKiotviet &&
                            listKiotviet.map((region, index) => (
                                <Radio.Button
                                    key={index}
                                    value={region.id}
                                    disabled={
                                        (isSaleManager && region.id !== userInfor?.admin?.kiotviet_id) ||
                                        (isAccountant && region.id !== userInfor?.admin?.kiotviet_id) ||
                                        (isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id)
                                    }
                                >
                                    {region.name ? region.name : '--'}
                                </Radio.Button>
                            ))}
                    </Radio.Group>
                    <div>
                        <Checkbox
                            onChange={(e: any) => {
                                if (e.target.checked) {
                                    setParams((prev: any) => ({ ...prev, is_debt: 1 }))
                                } else setParams((prev: any) => ({ ...prev, is_debt: undefined }))
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Công nợ cần thu ({numberOfDebit})
                        </Checkbox>
                        {!isAccountant && !isShopAdmin && (
                            <ButtonSave
                                text="Gửi tin nhắn"
                                icon={<YuqueOutlined />}
                                onClickButton={() => {
                                    if (selectedRows?.length === 0 && !isSendAll) {
                                        message.error('Vui lòng chọn ít nhất một khách hàng!!')
                                    } else {
                                        setIsModalOpen(true)
                                    }
                                }}
                            />
                        )}
                    </div>
                </CustomRow>
                <div style={{ padding: '20px 0 10px 10px' }}>
                    <Text>
                        Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                    </Text>
                </div>
                <CustomRow>
                    <Checkbox
                        checked={isSendAll}
                        onChange={(e: any) => {
                            setIsSendAll(e.target.checked)
                            dispatch(setIsUpdateAllCustomer(e.target.checked))
                            if (e.target.checked) {
                            } else {
                                setListRowKeysSelected([])
                                setSelectedRows([])
                                dispatch(setSelectedCustomer({ newListKeys: [], status: false }))
                            }
                        }}
                    >
                        Chọn tất cả theo bộ lọc
                    </Checkbox>
                </CustomRow>
                <br />
                <Spin spinning={isLoading}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={listCustomer}
                        rowSelection={{
                            selectedRowKeys: listRowKeysSelected,
                            onSelect: (record: any, selected: any) => {
                                if (selected) {
                                    setSelectedRows((prev: any) => [...prev, record])
                                    setListRowKeysSelected((prev: any) => [...prev, record?.id])
                                } else {
                                    const newSelectedRows = selectedRows?.filter((item: any) => item?.id !== record?.id)
                                    setSelectedRows(newSelectedRows)

                                    const newListKeys = listRowKeysSelected?.filter((item: any) => item !== record?.id)
                                    setListRowKeysSelected(newListKeys)
                                }
                                dispatch(setOneSelectedCustomer({ record, selected }))
                            },

                            onSelectAll: (selected: boolean, selectedRowList: any[], changeRows: any[]) => {
                                if (selected) {
                                    setSelectedRows((prev: any) => [...prev, ...changeRows])
                                    const newListKeys = changeRows?.map((item: any) => item?.id)
                                    setListRowKeysSelected((prev: any) => [...prev, ...newListKeys])
                                    dispatch(setSelectedCustomer({ newListKeys, selected }))
                                } else {
                                    const deselectedRowKeys = changeRows?.map((item: any) => item?.id)

                                    const newListKeys = listRowKeysSelected?.filter(
                                        (item: any) => !deselectedRowKeys.includes(item)
                                    )
                                    const newListRows = selectedRows?.filter((item: any) =>
                                        newListKeys.includes(item?.id)
                                    )

                                    setSelectedRows(newListRows)
                                    setListRowKeysSelected(newListKeys)
                                    if (isSendAll) {
                                        dispatch(
                                            setSelectedCustomer({ newListKeys: newListRows, selected, changeRows })
                                        )
                                    } else {
                                        dispatch(setSelectedCustomer({ newListKeys: newListRows, selected }))
                                    }
                                }
                            },
                        }}
                        expandedRowKeys={[currentSelected?.key]}
                        scroll={{
                            x: 1400,
                            scrollToFirstRowOnChange: true,
                        }}
                        expandable={{
                            expandedRowRender: record => {
                                return (
                                    <>
                                        {currentSelected && (
                                            <Card
                                                actions={
                                                    !isSaleManager
                                                        ? [
                                                              <>
                                                                  <Switch
                                                                      checked={record.status == IS_ACTIVE.ACTIVE}
                                                                      onChange={(status: boolean) => {
                                                                          handleChaneStatus(status, record)
                                                                      }}
                                                                  />
                                                                  {record.status ? (
                                                                      <TextStyled color>Đang hoạt động</TextStyled>
                                                                  ) : (
                                                                      <TextStyled>Ngừng hoạt động</TextStyled>
                                                                  )}
                                                              </>,
                                                              <ButtonStyled
                                                                  color
                                                                  onClick={() => {
                                                                      setRecord(record)
                                                                      setisShowModal(true)
                                                                  }}
                                                              >
                                                                  <EditOutlined style={{ paddingRight: '10px' }} />
                                                                  Đổi mật khẩu
                                                              </ButtonStyled>,
                                                              <Button
                                                                  style={{ color: '#4264de' }}
                                                                  type="text"
                                                                  onClick={() => {
                                                                      setRecord(record)
                                                                      setIsShowModalCoin(true)
                                                                  }}
                                                              >
                                                                  <DollarOutlined style={{ paddingRight: '10px' }} />
                                                                  Nạp xu
                                                              </Button>,
                                                              <ButtonStyled onClick={() => handleClickActive(record)}>
                                                                  <CheckCircleOutlined
                                                                      style={{ paddingRight: '10px' }}
                                                                  />
                                                                  {record.livestream_enable == 0
                                                                      ? 'Kích hoạt live'
                                                                      : 'Đã kích hoạt live'}
                                                              </ButtonStyled>,
                                                          ]
                                                        : []
                                                }
                                            >
                                                <Tabs
                                                    defaultActiveKey={tab}
                                                    tabBarExtraContent={
                                                        tab === '1' && !isSaleManager ? (
                                                            <ButtonAdd
                                                                text="Áp dụng công nợ"
                                                                styleButton={{ marginBottom: 10 }}
                                                                onClickButton={() => setIsApplyDebtModal(true)}
                                                            />
                                                        ) : undefined
                                                    }
                                                    onChange={(key: string) => {
                                                        setTab(key)
                                                    }}
                                                >
                                                    <Tabs.TabPane tab="Thông tin chung" key="1">
                                                        <CustomerInfo
                                                            isLoading={isLoading}
                                                            setIsLoading={setIsLoading}
                                                            isApplyDebtModal={isApplyDebtModal}
                                                            dataCustomer={record}
                                                            callback={callback}
                                                            setIsApplyDebtModal={setIsApplyDebtModal}
                                                        />
                                                    </Tabs.TabPane>
                                                    <Tabs.TabPane tab="Thông tin mua hàng" key="2">
                                                        <OrderInfo infoCustomer={record} />
                                                    </Tabs.TabPane>

                                                    <Tabs.TabPane tab="Lịch sử tích điểm" key="4">
                                                        <HistoryPoints dataCustomer={record} />
                                                    </Tabs.TabPane>
                                                    <Tabs.TabPane tab="Lịch sử xu" key="5">
                                                        <HistoryCoin dataCustomer={record} callback={callback} />
                                                    </Tabs.TabPane>
                                                    <Tabs.TabPane tab="Lịch sử ghi chú công nợ" key="6">
                                                        <DebtNoteHistory
                                                            dataCustomer={record}
                                                            isSaleManager={isSaleManager}
                                                        />
                                                    </Tabs.TabPane>
                                                </Tabs>
                                            </Card>
                                        )}
                                    </>
                                )
                            },

                            onExpand: (status: any, record: any) => {
                                if (currentSelected != record) setcurrentSelected(record)
                                else setcurrentSelected(null)
                            },
                        }}
                        pagination={{
                            ...paging,
                            showSizeChanger: false,
                            onChange: async (page, pageSize) => {
                                setParams({ ...params, page: page.toString() })
                            },
                        }}
                    />
                </Spin>
            </WapperComponent>
        </>
    )
}

const CustomRow = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export default Customers
