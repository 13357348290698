import Icon from '@ant-design/icons'
import { Col, DatePicker, Input, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

interface IFilter {
    setFromDate: React.Dispatch<React.SetStateAction<any>>
    setToDate: React.Dispatch<React.SetStateAction<any>>
}

const Filter = (props: IFilter) => {
    const { setFromDate, setToDate } = props
    return (
        <CustomRow gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                <DatePicker.RangePicker
                    style={{ width: '100%', marginBottom: 14 }}
                    placeholder={['Từ ngày', 'Đến ngày']}
                    className="rangerpicker-order"
                    format={'DD-MM-YYYY'}
                    defaultValue={[moment().startOf('month'), moment()]}
                    onChange={(value, dateString) => {
                        setFromDate(dateString[0].split('-').reverse().join('-'))
                        setToDate(dateString[1].split('-').reverse().join('-'))
                    }}
                />
            </Col>
        </CustomRow>
    )
}

const CustomRow = styled(Row)`
    .search {
        width: 100%;
        margin-bottom: 14px;
    }

    .role_block {
        width: 100%;
    }
`

export default Filter
