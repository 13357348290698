import { LineChartOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Button, PageHeader, Radio, Row, Spin, Table, message } from 'antd'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import ButtonSave from 'common/components/Button/ButtonSave'
import Container from 'container/Container'
import React from 'react'
import styled from 'styled-components'
import Filter from '../components/Filter'
import { formatPrice } from 'utils/ruleForm'
import {
    exportExcelByOrders,
    getCountDataByOrders,
    getListCategories,
    getReportWaitConfirmation,
} from '../../branch_report/services'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import { useDebounce } from 'common/hooks/Debounce'
import { syncAllProduct } from 'features/admin/product_clone/Products/ProductAPI'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

const TemporaryOrderReport = () => {
    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Sản phẩm</b>,
            dataIndex: 'name',
        },
        {
            title: <b>Nhóm hàng</b>,
            dataIndex: 'category_name',
        },
        {
            title: <b>Tổng tồn</b>,
            dataIndex: 'stock',
            render: (value: number) => formatPrice(value),
        },
        {
            title: <b>Số lượng đơn treo</b>,
            dataIndex: 'wait_confirmation_order',
            render: (value: number) => formatPrice(value),
        },
        {
            title: <b>Chênh lệch</b>,
            dataIndex: 'difference',
            render: (_: number, record: any) => {
                const value = Number(record.stock) - Number(record?.wait_confirmation_order)
                if (value < 0) {
                    return <span style={{ color: 'red' }}>{formatPrice(value)}</span>
                } else {
                    return formatPrice(value)
                }
            },
        },
    ]

    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN

    const [count, setCount] = React.useState<number>(0)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [search, setSearch] = React.useState<string>('')
    const searchDebounce = useDebounce(search, 300)
    const [listKiotviet, setListKiotviet] = React.useState<IKiotviet[]>([])
    const [currentKiotVietID, setCurrentKiotVietID] = React.useState<any>(
        isShopAdmin ? userInfor?.admin?.kiotviet_id : ''
    )
    const [data, setData] = React.useState<any[]>([])
    const [productCategory, setProductCategory] = React.useState<number>()
    const [categoriesList, setCategoriesList] = React.useState<any[]>([])
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res?.data) {
                setListKiotviet(res?.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getCategories = async () => {
        try {
            const payload = {
                page: 1,
                limit: 999,
                status: '1',
                kiotviet_id: currentKiotVietID,
            }
            const res = await getListCategories(payload)
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setCategoriesList(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getData = async () => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: currentKiotVietID,
                page: paging.current,
                limit: paging.pageSize,
                category_id: productCategory,
                search: searchDebounce.trim(),
            }
            const res = await getReportWaitConfirmation(payload)
            if (res?.status) {
                setData(res?.data)
                const newPaging = {
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getDataCount = async () => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: currentKiotVietID,
                page: paging.current,
                limit: paging.pageSize,
                category_id: productCategory,
                search: searchDebounce.trim(),
            }
            const res = await getCountDataByOrders(payload)
            if (res?.status) {
                setCount(res?.data?.count)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const exportExcel = async () => {
        try {
            const payload = {
                kiotviet_id: currentKiotVietID,
                category_id: productCategory,
                search: searchDebounce.trim(),
            }
            const res = await exportExcelByOrders(payload)
            if (res?.status) {
                message.success('Xuất excel file thành công!')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const syncProduct = async () => {
        try {
            await syncAllProduct(currentKiotVietID.toString())
            message.success('Đang đồng bộ vui lòng chờ một vài phút')
        } catch (error) {
            message.error('Đã có lỗi xảy ra vui lòng chờ một vài phút và thử lại')
        } finally {
        }
    }

    React.useEffect(() => {
        getDataKiotviet()
    }, [])

    React.useEffect(() => {
        getCategories()
    }, [currentKiotVietID])

    React.useEffect(() => {
        getDataCount()

        if (paging.current === 1) {
            getData()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [currentKiotVietID, productCategory, searchDebounce])

    React.useEffect(() => {
        getData()
    }, [paging.current])

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        style={{ borderRadius: 8 }}
                        title="Báo cáo nhập chợ"
                        extra={
                            currentKiotVietID > 0
                                ? [
                                      <ButtonSave
                                          onClickButton={exportExcel}
                                          text="Xuất excel"
                                          icon={<VerticalAlignBottomOutlined />}
                                      />,
                                      <ButtonAdd
                                          icon={<LineChartOutlined />}
                                          onClickButton={syncProduct}
                                          text="Cập nhật báo cáo"
                                      />,
                                  ]
                                : [
                                      <ButtonSave
                                          onClickButton={exportExcel}
                                          text="Xuất excel"
                                          icon={<VerticalAlignBottomOutlined />}
                                      />,
                                  ]
                        }
                    ></PageHeader>
                }
                filterComponent={
                    <Filter
                        search={search}
                        setSearch={setSearch}
                        categoriesList={categoriesList}
                        setProductCategory={setProductCategory}
                    />
                }
                contentComponent={[
                    <>
                        {(currentKiotVietID || currentKiotVietID.length === 0) && (
                            <Radio.Group
                                value={currentKiotVietID}
                                onChange={e => {
                                    setCurrentKiotVietID(e.target.value)
                                }}
                            >
                                <Radio.Button disabled={isShopAdmin} value={''}>
                                    Tất cả
                                </Radio.Button>
                                {listKiotviet &&
                                    listKiotviet.map((region, index) => (
                                        <Radio.Button
                                            key={index}
                                            value={region.id}
                                            disabled={isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id}
                                        >
                                            {region.name ? region.name : '--'}
                                        </Radio.Button>
                                    ))}
                            </Radio.Group>
                        )}
                        <p style={{ marginTop: 20 }}>
                            Kết quả lọc: <b>{count}</b>
                        </p>
                        <CustomTable
                            id="top-table"
                            bordered
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: 'Chưa có bản ghi nào!',
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: page => {
                                    setPaging({ ...paging, current: page })
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    )
}

const CustomRow = styled(Row)`
    .text {
        font-size: 12px;
        margin: 0;
    }
`

const CustomTable = styled(Table)`
    margin-top: 20px;
`

export default TemporaryOrderReport
