import { Avatar, Image } from 'antd'
import styled from 'styled-components'
import R from 'utils/R'
import { getFromDateToNow, momentToStringDate } from 'utils/TimerHelper'
import { MEDIA_TYPE } from 'utils/constants'
import { splitTextEndLine } from 'utils/funcHelper'

interface MessageProps {
    is_self: boolean
    avatar?: string | ''
    content?: string | ''
    message_media_url?: string | '' | null
    type_message_media?: number
    create_at: any
    user: any
}

const USER_ROLE = {
    CUSTOMER: 1,
    SHIPPER: 2,
    INVENTORY_STAFF: 3,
    SALE_STAFF: 4,
}

function MessageItem({
    is_self,
    avatar,
    content,
    message_media_url,
    create_at,
    type_message_media,
    user,
}: MessageProps) {
    // console.log('is_self', is_self)

    const renderHeader = () => {
        if (user?.user_role === 'user') {
            switch (user?.role) {
                case USER_ROLE.INVENTORY_STAFF:
                    return `${user.name} - ${user.branch_name}`
                case USER_ROLE.SALE_STAFF:
                    return `${user.name} - ${user.kiotviet_name}`
                case USER_ROLE.CUSTOMER:
                    return `${user.name}`

                default:
                    return 'Hệ thống'
            }
        }
    }

    return (
        <li className={is_self ? 'self' : 'other'}>
            <div className="avatar">
                <Avatar
                    size={40}
                    src={avatar}
                    icon={<img src={R.images.img_ogo} />} //<UserOutlined /> ||
                    draggable={false}
                />
            </div>
            <div className="msg">
                <p style={{ fontSize: 11, color: '#383737' }}>{renderHeader()}</p>
                {message_media_url &&
                    (type_message_media === MEDIA_TYPE.VIDEO ? (
                        <video controls>
                            <source src={message_media_url} type="video/mp4" />
                        </video>
                    ) : (
                        // <img src={message_media_url} draggable="false" />
                        <Image className="img" src={message_media_url} fallback={message_media_url} draggable="false" />
                    ))}
                {content &&
                    splitTextEndLine(content).map((text: string) => (
                        <p style={{ fontWeight: 500, fontSize: 16, wordWrap: 'break-word' }}>{text}</p>
                    ))}
                <span style={{ fontSize: 10, color: 'gray' }}>
                    {getFromDateToNow(create_at)} ({momentToStringDate(create_at, 'dateTime')})
                </span>
            </div>
        </li>
    )
}

const MessageContainer = styled.div`
    background-color: white;
    border-radius: 10px;
`

export default MessageItem
