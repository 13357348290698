import NewsList from './components/NewsList'

function index() {
  return (
    <>
      <NewsList />
    </>
  )
}

export default index
