import { Col, DatePicker, Divider, Row, Select, Spin } from 'antd'
import './css/Dashboash.css'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from 'chart.js'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ...registerables
)
type Props = {
  order: {
    count_cancelled: number
    count_completed: number
    count_inprogress: number
    count_wait_confirmation: number
  }
  data?: any
}
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '',
    },
  },
}
export const OrderDashboard = ({ data, order }: Props) => {
  return (
    <div className="order-dash">
      <span style={{ fontWeight: 600, fontSize: '18px' }}>Đơn hàng</span>
      <div className="divider"></div>
      <div>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={5}>
            <div
              style={{
                textAlign: 'center',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              <p style={{ minWidth: 100 }}>Chờ xác nhận</p>
              <div
                className="orderCard"
                style={{
                  borderColor: 'orange',
                  color: 'orange',
                  minWidth: 100,
                  flex: 1,
                }}
              >
                {order.count_wait_confirmation}
              </div>
            </div>
          </Col>
          <Col span={5}>
            <div
              style={{
                textAlign: 'center',
                flex: 1,
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              <p style={{ minWidth: 100 }}>Đang xử lý</p>
              <div
                className="orderCard"
                style={{
                  borderColor: 'rgb(90 90 241)',
                  color: 'rgb(90 90 241)',
                }}
              >
                {order.count_inprogress}
              </div>
            </div>
          </Col>
          <Col span={5}>
            <div style={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>
              <p style={{ minWidth: 100 }}>Hoàn thành</p>
              <div
                className="orderCard"
                style={{
                  borderColor: 'green',
                  color: 'green',
                }}
              >
                {order.count_completed}
              </div>
            </div>
          </Col>
          <Col span={5}>
            <div style={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>
              <p style={{ minWidth: 100 }}>Hủy/Từ chối</p>
              <div
                className="orderCard"
                style={{
                  borderColor: '#ff6347',
                  color: '#ff6347',
                }}
              >
                {order.count_cancelled}
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ paddingTop: '10px' }}>
          <Spin spinning={false} style={{ marginTop: 30 }} size="large">
            {Object.keys(data).length > 0 && (
              <Line options={options} height={150} data={data} />
            )}
          </Spin>
        </div>
      </div>
    </div>
  )
}
