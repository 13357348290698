import { Col, Row, Avatar, Tag, Image, Button } from 'antd'
import { HeartFilled, MessageOutlined, UserOutlined } from '@ant-design/icons'
import moment from 'moment'
import React, { useState, useEffect } from 'react'

import { lineBreakPost, splitTextEndLine } from 'utils/funcHelper'
import { PostMediaInterface, DataPostInteface } from '../InterfaceComPost'
import { postDetail, requestReactionPost } from '../CommunityPostApi'
import { useHistory } from 'react-router-dom'
import { ROLE } from 'utils/constants'
interface PropsPostContent {
  dataPost: DataPostInteface | null
  setDataPost: any
  onFocusInput: () => void
  post_id: any
  handleCallback: () => void
}

function PostContent({
  dataPost,
  setDataPost,
  onFocusInput,
  post_id,
  handleCallback,
}: PropsPostContent) {
  const history = useHistory()
  const reactionRequest = async () => {
    if (post_id) {
      try {
        const res = await requestReactionPost(post_id)
      } catch (error) {
        console.log(error)
        history.goBack()
      }
    }
  }
  return (
    <Col
      style={{
        width: '100%',
        borderBottom: '1px solid silver',
        marginLeft: '10px',
      }}
    >
      {dataPost?.Account?.role === ROLE.ADMIN ? (
        <Row style={{ width: '100%', paddingBottom: '15px' }}>
          <Col style={{ width: 70 }}>
            <Avatar
              size={54}
              icon={<UserOutlined />}
              src={dataPost?.Account?.Admin?.avatar}
            />
          </Col>
          <Col>
            <Row>
              <strong style={{ fontSize: 'large' }}>
                {dataPost
                  ? `${dataPost?.Account?.Admin?.full_name} 
                  `
                  : // (${dataPost?.User?.name})
                    'Tên người dùng.'}
              </strong>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row style={{ width: '100%', paddingBottom: '15px' }}>
          <Col style={{ width: 70 }}>
            <Avatar
              size={54}
              icon={<UserOutlined />}
              src={dataPost?.Account?.User?.avatar}
            />
          </Col>
          <Col>
            <Row>
              <strong style={{ fontSize: 'large' }}>
                {dataPost
                  ? `${dataPost?.Account?.User?.full_name} 
                  `
                  : // (${dataPost?.User?.name})
                    'Tên người dùng.'}
              </strong>
            </Row>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: 2 }} align="bottom">
        <span>
          {dataPost && moment(dataPost?.create_at).format('h:mm DD/MM/YYYY ')}
        </span>
        {dataPost && (
          <Tag color="#B47EDE" style={{ marginLeft: '10px' }}>
            <strong>{dataPost?.Topic?.name}</strong>
          </Tag>
        )}
      </Row>

      {dataPost?.content &&
        lineBreakPost(dataPost?.content).map((text: string) => {
          return (
            <span style={{ marginBottom: '1px' }}>
              {text} <br />
            </span>
          )
        })}
      <Row>
        {dataPost?.PostMedia?.map((item: PostMediaInterface) => {
          return (
            <Col
              span={5}
              style={{
                maxHeight: '145px',
                maxWidth: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
                overflow: 'hidden',
                marginTop: '15px',
              }}
            >
              {item.media_url.indexOf('mp4') === -1 &&
              item.media_url.indexOf('MP4') === -1 &&
              item.media_url.indexOf('MOV') === -1 ? (
                <Image src={item.media_url} />
              ) : (
                <video
                  controls
                  src={item.media_url}
                  className="uploaded-pic img-thumbnail "
                  width={200}
                  height={145}
                />
              )}
            </Col>
          )
        })}
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Col>
          <Button
            type="text"
            danger={dataPost?.is_reaction ? true : false}
            icon={<HeartFilled style={{ fontSize: 18 }} />}
            onClick={() => {
              handleCallback()
              reactionRequest()
            }}
          >
            <span style={{ fontSize: 18 }}>{dataPost?.count_like}</span>
          </Button>
        </Col>
        <Col>
          <Button
            type="text"
            icon={<MessageOutlined style={{ fontSize: 18 }} />}
            onClick={() => onFocusInput()}
          >
            <span style={{ fontSize: 18 }}>{dataPost?.count_comment}</span>
          </Button>
        </Col>
      </Row>
    </Col>
  )
}
export default PostContent
