import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  dimension: {
    height: 0,
  },
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setHeight: (state = initialState, action) => {
      // console.log('action - chiều cao web hiện tại: ', action.payload)
      state.dimension.height = action.payload
    },
  },
})

export const { setHeight } = configSlice.actions
export default configSlice.reducer
