import { DownloadOutlined, SaveOutlined } from '@ant-design/icons'
import { Col, DatePicker, message, Row, Select } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import React, { useState } from 'react'
import { REPORT_TYPE, STALL } from 'utils/constants'
import ExportCsv from 'utils/ExportCSV'
import { downloadFile, formatPrice } from 'utils/ruleForm'
import { exportExcel, getReport } from '../SalesReportApi'

const { Option } = Select
const { RangePicker } = DatePicker

type Props = {
  keyTabs: number
  listShop: Object[]
  onShopSubmit: (kiotviet_id: string) => void
  onSearchSubmit: (search: string) => void
  onDateSubmit: (from_dateKey: string, to_dateKey: string) => void
  paramsProduct: any
  paramsCustomer: any
  onTopicSubmit: (category_id: any) => void
  allCategory?: any
}

export default function Filter({
  keyTabs,
  listShop,
  onShopSubmit,
  onSearchSubmit,
  onDateSubmit,
  paramsProduct,
  paramsCustomer,
  onTopicSubmit,
  allCategory: allCategory,
}: Props) {
  const [isLoadingBtnExportData, setLoadingBtnExportData] =
    useState<boolean>(false)
  const [productExport, setProductExport] = useState<any>([])
  const onExportProduct = async () => {
    try {
      setLoadingBtnExportData(true)
      const dataListReportNotPagging = await exportExcel(paramsProduct)
    } catch (error) {
      message.error(
        'Đã có lỗi trong quá trình export dữ liệu, vui lòng thử lại sau!'
      )
    } finally {
      setLoadingBtnExportData(false)
    }
  }

  const [customerExport, setCustomerExport] = useState<any>([])
  const onExportCustomer = async () => {
    try {
      setLoadingBtnExportData(true)

      const dataListReportNotPagging = await exportExcel(paramsCustomer)
    } catch (error) {
      console.log(error)
      message.error(
        'Đã có lỗi trong quá trình export dữ liệu, vui lòng thử lại sau!'
      )
    } finally {
      setLoadingBtnExportData(false)
    }
  }
  return (
    <Row gutter={[16, 16]} justify="end">
      <Col span={10}>
        {keyTabs === 1 ? (
          <TypingAutoSearch
            onSearchSubmit={(searchKey: string) => {
              onSearchSubmit(searchKey.trim())
            }}
            isSearchLoading={false}
            placeholder="Nhập tên sản phẩm ..."
          />
        ) : (
          <TypingAutoSearch
            onSearchSubmit={(searchKey: string) => {
              onSearchSubmit(searchKey)
            }}
            isSearchLoading={false}
            placeholder="Nhập tên hoặc số điện thoại khách hàng ..."
          />
        )}
      </Col>
      {allCategory && (
        <Col>
          <Select
            style={{ width: '200px' }}
            allowClear
            placeholder="Chọn nhóm sản phẩm"
            onChange={(value: any) => {
              onTopicSubmit(value || 0)
            }}
          >
            {allCategory &&
              allCategory.map((category: any, index: number) => (
                <Option key={index} value={category.id}>
                  {category.name}
                </Option>
              ))}
          </Select>
        </Col>
      )}

      <Col span={5}>
        <RangePicker
          onChange={(value, dateString) => {
            onDateSubmit(dateString[0], dateString[1])
          }}
        />
      </Col>

      <Col span={4}>
        {keyTabs === 1 ? (
          <ExportCsv
            style={{ minWidth: '110px' }}
            loading={isLoadingBtnExportData}
            onClick={() => onExportProduct()}
            sheetName={['productSaleReport']}
            sheets={{
              productSaleReport: ExportCsv.getSheets(productExport),
            }}
            fileName="Báo cáo bán hàng theo sản phẩm"
          >
            <DownloadOutlined />
            &nbsp;Export
          </ExportCsv>
        ) : (
          <ExportCsv
            style={{ minWidth: '110px' }}
            loading={isLoadingBtnExportData}
            onClick={() => onExportCustomer()}
            sheetName={['customerSaleReport']}
            sheets={{
              customerSaleReport: ExportCsv.getSheets(customerExport),
            }}
            fileName="Báo cáo bán hàng theo khách hàng"
          >
            <DownloadOutlined />
            &nbsp;Export
          </ExportCsv>
        )}
      </Col>
    </Row>
  )
}
