import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { postDetail } from './CommunityPostApi'
import PostContent from './components/PostContent'
import PostListComment from './components/PostListComment'
import DetailPostHeader from './components/DetailPostHeader'
import ContainerDetailPost from './components/ContainerDetailPost'
import { Row } from 'antd'
import { DataPostInteface } from './InterfaceComPost'
import { useDebounce } from 'common/hooks/Debounce'

function DetailPost() {
  const history = useHistory()
  const params: any = useParams()
  const inputRef = useRef<any>(null)
  const [dataPost, setDataPost] = useState<DataPostInteface | null>(null)
  const [callback, setCallback] = useState<boolean>(false)

const debounced = useDebounce([params.id,callback], 300)
  useEffect(() => {
    if (params.id) {
        getDataPost()
    }
  }, debounced)
  const handleCallback = () => {
    setCallback(!callback)
  }
  const getDataPost = async () => {
    try {
      const res = await postDetail(parseInt(params.id))
      if (!res.data) {
        history.push({ pathname: '/ad-community-post' })
      }
      setDataPost(res.data[0])
    } catch (error) {
      history.push({ pathname: '/ad-community-post' })
    }
  }
  const handleFocusInput = () => inputRef.current && inputRef.current.focus()

  const ContentComponent = () => {
    return (
      <div style={{ padding: 10, width: '100%' }}>
        <Row style={{ width: '100%' }}>
          <PostContent
            handleCallback={handleCallback}
            post_id={params.id}
            onFocusInput={handleFocusInput}
            dataPost={dataPost}
            setDataPost={setDataPost}
          />
        </Row>
        <Row style={{ width: '100%' }}>
          <PostListComment
            dataPost={dataPost}
            setDataPost={setDataPost}
            onFocusInput={handleFocusInput}
            inputRef={inputRef}
            post_id={params.id}
            handleCallback={handleCallback}
          />
        </Row>
      </div>
    )
  }
  return (
    <ContainerDetailPost
      headerComponent={
        <DetailPostHeader
          df_type_user_id={dataPost?.user_id}
          post_id={params.id}
          data  ={dataPost}
        />
      }
      contentComponent={ContentComponent}
    />
  )
}
export default DetailPost
