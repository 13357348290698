import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { PageHeader, Radio, Row, Select, Spin, Table, message } from 'antd'
import ButtonSave from 'common/components/Button/ButtonSave'
import Container from 'container/Container'
import React from 'react'
import Filter from '../components/Filter'
import styled from 'styled-components'
import { formatPrice } from 'utils/ruleForm'
import { useDebounce } from 'common/hooks/Debounce'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { momentToStringDate } from 'utils/TimerHelper'
import { toNonAccentVietnamese } from 'utils/Configs'
import moment from 'moment'
import { exportWrapOrderReport, getWrapOrderReport } from '../service'
import { getListBranchs } from '../../branch_report/services'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'
import ExpandContent from '../components/ExpandContent'

const OrderWrapReport = () => {
    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Kho</b>,
            dataIndex: 'name',
        },
        {
            title: <b>Khu vực</b>,
            dataIndex: 'kiotviet_name',
        },
        {
            title: <b>Tổng đơn</b>,
            dataIndex: 'total_order',
        },
        {
            title: <b>Tổng số đơn vượt</b>,
            dataIndex: 'total_order_time_out',
        },
        {
            title: <b>Vượt quá 5 phút</b>,
            dataIndex: 'total_order_time_out_5_minutes',
        },
        {
            title: <b>Vượt quá 10 phút</b>,
            dataIndex: 'total_order_time_out_10_minutes',
        },
        {
            title: <b>Tỷ lệ (%)</b>,
            dataIndex: 'percentage',
        },
    ]
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isIventoryManager = userInfor?.admin?.role === ADMIN_ROLE.INVERTORY_STAFF
    const [loading, setLoading] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any[]>([
        {
            inventory: 'Kho Cầu Giấy',
            total: 10,
            total_over: 4,
            total_over_5: 1,
            total_over_10: 2,
            percentage: 12,
        },
    ])
    const [listKiotviet, setlistKiotviet] = React.useState<IKiotviet[]>()
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<number[]>([])
    const [selectedBranchId, setSelectedBranchId] = React.useState<any>(
        isIventoryManager ? userInfor?.admin?.branch_id : undefined
    )
    const [branches, setBranches] = React.useState<any[]>()
    const [selectedKiotVietId, setSelectedKiotVietId] = React.useState<any>(
        isIventoryManager ? userInfor?.admin?.kiotviet_id : ''
    )
    const [search, setSearch] = React.useState<string>('')
    const [fromDate, setFromDate] = React.useState<string>(moment().startOf('month').format('YYYY-MM-DD'))
    const [toDate, setToDate] = React.useState<string>(moment().format('YYYY-MM-DD'))
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const searchDebounce = useDebounce(search, 300)

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
                setlistKiotviet(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getData = async () => {
        try {
            setLoading(true)
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                branch_id: selectedBranchId,
                kiotviet_id: typeof selectedKiotVietId === 'string' ? undefined : selectedKiotVietId,
                from_date: fromDate,
                to_date: toDate,
            }
            const res = await getWrapOrderReport(payload)
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, key: item?.id }))
                setData(data)
                const newPaging = {
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const exportExcel = async () => {
        try {
            setLoading(true)
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                branch_id: selectedBranchId,
                kiotviet_id: typeof selectedKiotVietId === 'string' ? undefined : selectedKiotVietId,
                from_date: fromDate,
                to_date: toDate,
            }
            const res = await exportWrapOrderReport(payload)
            if (res?.status) {
                message.success('Xuất file excel thành công!')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getBranchsByKiotviet = async () => {
        try {
            const payload = {
                kiotviet_id: selectedKiotVietId,
            }
            const res = await getListBranchs(payload)
            if (res?.data) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                if (isIventoryManager) {
                    const newData = data?.filter((item: any) => item?.id === userInfor?.admin?.branch_id)
                    setBranches(newData)
                } else {
                    setBranches(data)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleChangeStock = (idStock: any) => {
        setSelectedKiotVietId(idStock)
    }

    React.useEffect(() => {
        getDataKiotviet()
    }, [])

    React.useEffect(() => {
        getData()
    }, [paging.current])

    React.useEffect(() => {
        if (paging.current === 1) {
            getData()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [searchDebounce, fromDate, toDate, selectedBranchId, selectedKiotVietId])

    React.useEffect(() => {
        if (selectedKiotVietId !== '') {
            getBranchsByKiotviet()
        }
    }, [selectedKiotVietId])

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        style={{ borderRadius: 8 }}
                        title="Báo cáo đóng hàng"
                        extra={[
                            <ButtonSave
                                onClickButton={exportExcel}
                                text="Xuất excel"
                                icon={<VerticalAlignBottomOutlined />}
                            />,
                        ]}
                    ></PageHeader>
                }
                filterComponent={<Filter setFromDate={setFromDate} setToDate={setToDate} />}
                contentComponent={[
                    <>
                        {(selectedKiotVietId || selectedKiotVietId?.length === 0) && (
                            <Radio.Group
                                value={selectedKiotVietId}
                                onChange={(e: any) => {
                                    handleChangeStock(e.target.value)
                                    setSelectedBranchId(undefined)
                                    setPaging((prev: any) => ({ ...prev, current: 1 }))
                                }}
                            >
                                <Radio.Button disabled={isIventoryManager} value={''}>
                                    Tất cả
                                </Radio.Button>
                                {listKiotviet &&
                                    listKiotviet.map((region, index) => (
                                        <Radio.Button
                                            key={index}
                                            value={region.id}
                                            disabled={isIventoryManager && region.id !== userInfor?.admin?.kiotviet_id}
                                        >
                                            {region.name ? region.name : '--'}
                                        </Radio.Button>
                                    ))}
                            </Radio.Group>
                        )}
                        {selectedKiotVietId !== '' && (
                            <Select
                                placeholder="Chọn chi nhánh"
                                showArrow
                                showSearch
                                allowClear={!isIventoryManager}
                                options={branches}
                                value={selectedBranchId}
                                onChange={(value: number) => setSelectedBranchId(value)}
                                style={{ marginLeft: 20 }}
                                filterOption={(input, option: any) => {
                                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                    return nonAccent.includes(nonAccentInput)
                                }}
                            />
                        )}

                        <CustomRow>
                            <p>
                                Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                            </p>
                        </CustomRow>
                        <Table
                            id="top-table"
                            bordered
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            expandable={{
                                expandedRowRender: record => <ExpandContent record={record} />,
                            }}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(expanded: boolean, record: any) => {
                                let keys = []
                                if (expanded) {
                                    keys.push(record.id)
                                }
                                setExpandedRowKeys(keys)
                            }}
                            locale={{
                                emptyText: 'Chưa có bản ghi nào!',
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    )
}

const CustomRow = styled(Row)`
    margin: 28px 0 0 0;

    .text {
        font-size: 12px;
        margin: 0;
    }
`

export default OrderWrapReport
