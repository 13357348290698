import { validHeadPhoneNumber } from "utils/funcHelper"

export const validPhoneNumber = (tmp: any, value: string) => {
    let trimValue: string = ''
    if (value) {
      trimValue = value.trim()
    }
    const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im // validate phone number
    let validPhone: boolean = true
    if (trimValue && trimValue.length < 10 && trimValue.includes('84')) {
      validPhone = false
    } else if (
      trimValue &&
      trimValue.length < 11 &&
      trimValue.includes('+84')
    ) {
      validPhone = false
    }

    if (
      trimValue === '' ||
      trimValue === null ||
      (reg.test(trimValue) && validHeadPhoneNumber(trimValue) && validPhone)
    ) {
      return Promise.resolve()
    }
    return Promise.reject()
  }

  export const validEmail = (tmp: any, value: string) => {
    let trimValue: string = ''
    let subString: Array<string> = []
    if (value) {
      trimValue = value.trim()
      subString = value.split('@')
    }
    const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // validate email
    const reg2 = /^[\x00-\x7F]*$/ // just ascii

    const reg3 = /([a-zA-Z])+([ -~])*/ // contains a letter
    let checkMail = false
    if (subString.length === 2 && reg3.test(subString[0])) {
      checkMail = true
    }
    if (
      !value ||
      value === '' ||
      // value.length > 20 ||
      (reg.test(trimValue) && reg2.test(trimValue) && checkMail)
    ) {
      return Promise.resolve()
    }
    return Promise.reject()
  }