import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import reactotron from 'ReactotronConfig'
import { getListNotification } from './NotificationApi'

const initialState: any = {
  dataMessenger: [],
}

const getNotification = createAsyncThunk(
  'get_notification',
  async (page: number, { rejectWithValue }) => {
    try {
      const res = await getListNotification({ page })
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotificationMessenger: (state = initialState, action) => {
      const newList = state.dataMessenger.filter(
        (item: any) => item.id !== action.payload.data.id
      )
      state.dataMessenger = [action.payload.data, ...newList]
    },

    deleteNotificationMessenger: (state, action) => {
      const newList = state.dataMessenger.filter(
        (item: any) => item.id !== action.payload
      )
      state.dataMessenger = [...newList]
    },
  },
  extraReducers: builder => {
    builder.addCase(getNotification.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getNotification.fulfilled, (state, action) => {
      reactotron.log!({state})
      state.isLoading = false
      // state.currentUser = action.payload
    })
  },
})

export const { addNotificationMessenger, deleteNotificationMessenger } =
  notificationSlice.actions
export default notificationSlice.reducer
