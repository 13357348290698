import {
  Button,
  Pagination,
  PageHeader,
  Table,
  Layout,
  Affix,
  Space,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { formatPrice, number } from 'utils/ruleForm'
import { Filter } from './components/Filter'
import { getShopReport } from './ShopReportApi'
import 'antd/dist/antd.css'
import ExportCsv from 'utils/ExportCSV'
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

const { Header, Footer, Sider, Content } = Layout

export default function ShopReport() {
  const heightWeb = useSelector(
    (state: any) => state.configReducer.dimension.height
  )
  const [isLoading, setIsLoading] = useState(false)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [totalProductOrder, setTotalProductOrder] = useState<number>(0)
  const [totalOrder, setTotalOrder] = useState<number>(0)
  const [totalLive, setTotalLive] = useState<number>(0)
  const [
    totalStreamMinutesAvailable,
    setTotalStreamMinutesAvailable,
  ] = useState<number>(0)
  const [totalPricePackage, setTotalPricePackage] = useState<number>(0)
  const [listShopReport, setListShopReport] = useState([])
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const [params, setParams] = useState<any>({
    search: '',
    df_type_user_id: '',
    page: 1,
    limit: 24,
    status: null,
  })

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const [pagings, setPagings] = useState({
    pageSize: 1,
    page: 1,
  })

  const getData = async () => {
    setIsLoading(true)
    try {
      const res = await getShopReport(params)
      console.log(res.data)

      const tableData = res.data.map((item: any, index: number) => {
        return { ...item, key: item.id, stt: index + 1 }
      })

      const formattedPaging = {
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      }
      setListShopReport(tableData)
      // const totalData = ()
      // setTotal(totalData)
      setPaging(formattedPaging)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      setIsSearchLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [params])

  const { Column } = Table
  const [top, setTop] = useState(10)
  const columns = [
    {
      width: 70,
      title: 'Stt',
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: 'Tên gian hàng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      width: '8%',
      title: 'Số sản phẩm',
      dataIndex: 'total_product',
      key: 'total_product',
    },
    {
      width: '8%',
      title: 'SL đã bán',
      dataIndex: 'total_product_order',
      key: 'total_product_order',
    },
    {
      width: '8%',
      title: 'Số đơn hàng',
      dataIndex: 'total_order',
      key: 'total_order',
    },
    {
      title: 'Doanh thu đơn hàng',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (value: any) => <td>{value == 0 ? 0 : formatPrice(value)} đ</td>,
    },
    {
      width: '10%',
      title: 'Số livestream',
      dataIndex: 'total_live',
      key: 'total_live',
      render: (value: any) => <td>{value == 0 ? 0 : formatPrice(value)}</td>,
    },
    {
      title: 'Thời gian live còn lại',
      dataIndex: 'stream_minutes_available',
      key: 'stream_minutes_available',
      render: (value: any) => (
        <td>{value == 0 ? 0 : formatPrice(value)} phút</td>
      ),
    },
    {
      title: 'Doanh thu gói dịch vụ',
      dataIndex: 'total_price_package',
      key: 'total_price_package',
      render: (value: any) => <td>{value == 0 ? 0 : formatPrice(value)} đ</td>,
    },
  ]

  const [isLoadingBtnExportData, setLoadingBtnExportData] = useState<boolean>(
    false
  )
  const [dataExport, setDataExport] = useState<any>([])
  const onExportDataToExcel = async (fn: any) => {
    try {
      setLoadingBtnExportData(true)
      const dataListReportNotPagging: any = await getShopReport(params)
      const dataExport = dataListReportNotPagging?.data?.map(
        (o: any, i: number) => {
          return {
            STT: i + 1,
            'Tên gian hàng': o.name || '',
            'Số sản phẩm': o.total_product || '',
            'SL đã bán': o.total_product_order || '',
            'Số đơn hàng': o.total_order || '',
            'Doanh thu đơn hàng':
              o.count_reaction == null ? '0' : o.count_reaction,
            'Số livestream':
              o.total_live == 0 ? '0' : formatPrice(o.total_live),
            'Thời gian live còn lại':
              o.stream_minutes_available == 0
                ? '0'
                : formatPrice(o.stream_minutes_available) + 'phút',
            'Doanh thu gói dịch vụ':
              o.total_price_package == 0
                ? '0'
                : formatPrice(o.total_price_package) + 'đ',
          }
        }
      )

      setDataExport(dataExport)
      fn()
    } catch (error) {
      message.error(
        'Đã có lỗi trong quá trình export dữ liệu, vui lòng thử lại sau!'
      )
    } finally {
      setLoadingBtnExportData(false)
    }
  }

  return (
    <div>
      <div style={{ backgroundColor: 'white', margin: '5px 10px 15px' }}>
        <PageHeader
          title="Báo cáo gian hàng"
          extra={[
            // <Button
            //   type="primary"
            //   onClick={() => {
            //     console.log('Export')
            //   }}
            // >
            //   Export
            // </Button>,
            <Space>
              <ExportCsv
                loading={isLoadingBtnExportData}
                onClick={fn => onExportDataToExcel(fn)}
                sheetName={['ShopReport']}
                sheets={{
                  ShopReport: ExportCsv.getSheets(dataExport),
                }}
                fileName="Báo cáo gian hàng"
              >
                <DownloadOutlined />
                &nbsp; Export
              </ExportCsv>
            </Space>,
          ]}
        />
      </div>

      <div
        style={{
          backgroundColor: 'white',
          margin: '0px 10px 15px',
          padding: '20px 20px',
        }}
      >
        <Filter
          isSearchLoading={isSearchLoading}
          onSearchSubmit={(searchKey: string) => {
            setIsSearchLoading(true)
            setParams({ ...params, search: searchKey, page: 1 })
          }}
        />
      </div>

      <div
        style={{
          backgroundColor: 'white',
          margin: '0px 10px 15px',
          padding: '10px 20px',
        }}
      >
        <p>
          Kết quả lọc: <b>{formatPrice(paging.total)}</b>
        </p>

        <Table
          className="table-expanded-custom-no-image"
          loading={isLoading}
          columns={columns}
          dataSource={listShopReport}
          bordered
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            // y: 'calc(100vh - 300px)',
            y: `calc(${heightWeb}px - 300px)`,
          }}
          summary={pageData => {
            let totalTotal_product_order = 0
            let totalTotal_order = 0
            let totalTotal_price = 0
            let totalTotal_live = 0
            let totalStream_minutes_available = 0
            let totalTotal_price_package = 0

            pageData.forEach(
              ({
                total_product_order,
                total_order,
                total_price,
                total_live,
                stream_minutes_available,
                total_price_package,
              }) => {
                totalTotal_product_order += parseInt(total_product_order)
                totalTotal_order += total_order
                totalTotal_price += parseInt(total_price)
                totalTotal_live += total_live
                totalStream_minutes_available += stream_minutes_available
                totalTotal_price_package += parseInt(total_price_package)
              }
            )

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={3} align="center">
                    <b>Tổng</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <b>{totalTotal_product_order}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <b>{totalTotal_order}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <b>{formatPrice(totalTotal_price)} đ</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <b>{formatPrice(totalTotal_live)}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <b>{formatPrice(totalStream_minutes_available)} phút</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <b>{formatPrice(totalTotal_price_package)} đ</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          }}
          sticky
          pagination={{
            ...paging,
            showSizeChanger: false,
            onChange: async (page, pageSize) => {
              setParams({ ...params, page })
            },
          }}
        />
      </div>
    </div>
  )
}
