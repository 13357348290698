import { Row, Button } from 'antd'
import {
  DollarCircleFilled,
  FileTextFilled,
  VideoCameraFilled,
  DropboxOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import history from 'utils/history'
import { ADMIN_ROUTER_PATH } from 'common/config'
import './css/Dashboash.css'
import NumberFormat from 'react-number-format'

type Props = {
  count_revenue: any
  count_purchased_gift: number
  count_livestream: number
  count_users: number
}
export default function Cards({
  count_revenue,
  count_purchased_gift,
  count_livestream,
  count_users,
}: Props) {
  return (
    <div>
      <Row>
        <Button
          style={{
            width: '19%',
            height: 'auto',
            borderTopColor: '#ea1212',
            borderTopWidth: '5px',
            borderRadius: '4%',
          }}
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.ORDER}`,
            })
          }}
        >
          <p className="titleDash">Doanh thu đơn hàng</p>
          <Row>
            <div style={{ width: '70%' }}></div>
            <div>
              <DollarCircleFilled
                style={{
                  fontSize: '380%',
                  color: 'lightgray',
                }}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              {/* <p>{count_turnover}</p> */}
              <NumberFormat
                value={count_revenue?.total_price}
                displayType={'text'}
                thousandSeparator={true}
                suffix={' đ'}
              />
            </div>
          </Row>
        </Button>
        <div style={{ width: '1.25%' }}></div>
        <Button
          style={{
            width: '19%',
            height: 'auto',
            borderTopColor: 'orange',
            borderTopWidth: '5px',
            borderRadius: '4%',
          }}
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.ORDER}`,
            })
          }}
        >
          <p className="titleDash">Doanh thu thực tế</p>
          <Row>
            <div style={{ width: '70%' }}></div>
            <div>
              <FileTextFilled
                style={{
                  fontSize: '380%',
                  color: 'lightgray',
                }}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              <NumberFormat
                value={count_revenue?.price}
                displayType={'text'}
                thousandSeparator={true}
                suffix={' đ'}
              />
            </div>
          </Row>
        </Button>
        <div style={{ width: '1.25%' }}></div>
        <Button
          style={{
            width: '19%',
            height: 'auto',
            borderTopColor: 'rgb(90 90 241)',
            borderTopWidth: '5px',
            borderRadius: '4%',
          }}
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.GIFT_CHANGE}`,
            })
          }}
        >
          <p className="titleDash">Yêu cầu đổi quà</p>
          <Row>
            <div style={{ width: '70%' }}></div>
            <div>
              <TeamOutlined
                style={{
                  fontSize: '380%',
                  color: 'lightgray',
                  // paddingLeft: '240%',
                }}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              <p>{count_purchased_gift}</p>
            </div>
          </Row>
        </Button>
        <div style={{ width: '1.25%' }}></div>
        <Button
          style={{
            width: '19%',
            height: 'auto',
            borderTopColor: '#05ae05',
            borderTopWidth: '5px',
            borderRadius: '4%',
          }}
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.LIVE_STREAM}`,
            })
          }}
        >
          <p className="titleDash">Live stream</p>
          <Row>
            <div style={{ width: '70%' }}></div>
            <div>
              <VideoCameraFilled
                style={{
                  fontSize: '380%',
                  color: 'lightgray',
                  // paddingLeft: '240%',
                }}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              <p>{count_livestream}</p>
            </div>
          </Row>
        </Button>
        <div style={{ width: '1.25%' }}></div>
        <Button
          style={{
            width: '19%',
            height: 'auto',
            borderTopColor: '#07e607',
            borderTopWidth: '5px',
            borderRadius: '4%',
          }}
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.CUSTOMER}`,
            })
          }}
        >
          <p className="titleDash">Khách hàng</p>
          <Row>
            <div style={{ width: '70%' }}></div>
            <div>
              <DropboxOutlined
                style={{
                  fontSize: '380%',
                  color: 'lightgray',
                  // paddingLeft: '240%',
                }}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              <p>{count_users}</p>
            </div>
          </Row>
        </Button>
      </Row>
    </div>
  )
}
