import { Button, Card, Image, Popconfirm, Row, Tag } from 'antd'
import { formatTimeStr } from 'antd/lib/statistic/utils'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { momentToStringDate } from 'utils/TimerHelper'
import logo from 'assets/images/img_logo.png'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { renderDepartmentName } from 'utils/funcHelper'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

interface IPolicyItem {
    item: any
    deletePolicyItem: any
}

const PolicyItem = (props: IPolicyItem) => {
    const { item, deletePolicyItem } = props
    const history = useHistory()
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isAccountant = userInfor?.admin?.role === ADMIN_ROLE.ACCOUNTANT

    const listContent = item?.content.split('\n')

    return (
        <CustomCard
            title={
                <div className="header_block">
                    <div className="avatar">
                        <img src={item?.admin?.avatar || logo} alt="avatar" />
                    </div>
                    <div className="name_block">
                        <p className="username">{item?.admin?.full_name}</p>
                        <p style={{ fontSize: 10 }}>{momentToStringDate(item?.createdAt, 'dateTime')}</p>
                    </div>
                </div>
            }
            extra={
                isAccountant ? (
                    <EditOutlined
                        style={{ marginRight: 10 }}
                        onClick={() => history.push(ADMIN_ROUTER_PATH.ADD_EDIT_POLICY, { policyId: item?.id })}
                    />
                ) : (
                    <>
                        <EditOutlined
                            style={{ marginRight: 10 }}
                            onClick={() => history.push(ADMIN_ROUTER_PATH.ADD_EDIT_POLICY, { policyId: item?.id })}
                        />
                        <Popconfirm
                            title={'Bạn có chắc chắn muốn XOÁ chính sách này?'}
                            onConfirm={deletePolicyItem}
                            okText={'Xoá'}
                            cancelText={'Đóng'}
                            placement="bottomLeft"
                        >
                            <DeleteOutlined style={{ color: 'red' }} />
                        </Popconfirm>
                    </>
                )
            }
            style={{ width: '100%' }}
        >
            <Row justify="start">
                {item?.is_apply_to_all_kiotviet === 1 ? (
                    <Tag color="red">Tất cả</Tag>
                ) : (
                    <div>
                        {item?.kiotviet?.map((item: any) => (
                            <Tag color="red">{item?.kiotviet?.name}</Tag>
                        ))}
                    </div>
                )}
                {item?.is_apply_to_all_department === 1 ? (
                    <Tag color="green">Tất cả</Tag>
                ) : (
                    <div>
                        {item?.department?.map((item: any) => (
                            <Tag color="green">{renderDepartmentName(item?.user_role)}</Tag>
                        ))}
                    </div>
                )}
            </Row>

            <p style={{ fontSize: 20, fontWeight: 600 }}>{item?.title}</p>
            {listContent?.map((item: any, index: number) => (
                <p key={index}>{item}</p>
            ))}

            <br />
            <br />
            {item?.image_url && <Image src={item?.image_url} className="image" />}
        </CustomCard>
    )
}

const CustomCard = styled(Card)`
    margin-bottom: 30px;
    cursor: pointer;

    p {
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 300;
        margin-top: 20px;
    }

    .image {
        border-radius: 10px;
        width: 300px;
        height: 180px;
        object-fit: cover;
    }

    .header_block {
        display: flex;
        align-items: center;
        width: 300px;
        background-color: white;

        .name_block {
            p {
                margin: 0;
                font-size: 16px;
            }
            .username {
                font-weight: 500;
            }
        }
        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 1000px;
            margin-right: 10px;
            box-shadow: 2px 2px 4px #e6e5e5;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 1000px;
            }
        }
    }
`

export default PolicyItem
