import Icon from '@ant-design/icons'
import { Col, DatePicker, Input, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface IFilter {
    search: string
    setFromDate: React.Dispatch<React.SetStateAction<string>>
    setToDate: React.Dispatch<React.SetStateAction<string>>
    setSearch: React.Dispatch<React.SetStateAction<string>>
}

const Filter = (props: IFilter) => {
    const { search, setSearch, setFromDate, setToDate } = props
    return (
        <CustomRow gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                <Input.Search
                    allowClear
                    className="search"
                    placeholder="Tên hoặc số điện thoại"
                    addonAfter={<Icon type="close-circle-o" />}
                    value={search}
                    onChange={(e: any) => {
                        setSearch(e.target.value)
                    }}
                />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    placeholder={['Từ ngày', 'Đến ngày']}
                    className="rangerpicker-order"
                    format={'DD-MM-YYYY'}
                    onChange={(value, dateString) => {
                        setFromDate(dateString[0].split('-').reverse().join('-'))
                        setToDate(dateString[1].split('-').reverse().join('-'))
                    }}
                />
            </Col>
        </CustomRow>
    )
}

const CustomRow = styled(Row)`
    .search {
        width: 100%;
        margin-bottom: 14px;
    }

    .role_block {
        width: 100%;
    }
`

export default Filter
