import { Input, Modal, Spin, message } from 'antd'
import React from 'react'
import styled from 'styled-components'
import UploadComponent from 'utils/UploadImage'

interface IEditNoteModal {
    isModalOpen: boolean
    setIsModalOpen: any
    data: any
    loading?: boolean
    updateOrderNote: (payload: any) => Promise<void>
}

const EditNoteModal = (props: IEditNoteModal) => {
    const { isModalOpen, setIsModalOpen, data, updateOrderNote, loading } = props
    const [note, setNote] = React.useState<string>(data?.note)
    const [images, setImages] = React.useState<any[]>([])
    const [fileList, setFileList] = React.useState<any[]>(
        data ? data?.medias?.map((item: any) => ({ id: item?.id, url: item?.media_url })) : []
    )
    const [deletedImages, setDeletedImages] = React.useState<number[]>([])

    const defaultImagesIds = data?.medias?.map((item: any) => item?.id)
    const defaultImagesUrls = data?.medias?.map((item: any) => item?.media_url)

    const onCancel = () => {
        setIsModalOpen(false)
    }

    const onFinish = () => {
        if (note?.length > 0) {
            if (!data) {
                updateOrderNote({ note, images })
            } else {
                const newImages = images?.filter((item: any) => !defaultImagesUrls.includes(item) && item)
                updateOrderNote({ note, images: newImages, delete_images: deletedImages })
            }
        } else {
            message.error('Vui lòng nhập ghi chú!')
        }
    }

    return (
        <Modal
            okText="Lưu"
            title={data ? 'Cập nhật ghi chú' : 'Thêm mới ghi chú'}
            destroyOnClose
            maskClosable={false}
            visible={isModalOpen}
            onCancel={onCancel}
            onOk={onFinish}
        >
            <Spin spinning={loading}>
                <p>
                    Nội dung ghi chú <span style={{ color: 'red' }}>*</span>
                </p>
                <Input.TextArea
                    status={note?.length === 0 ? 'error' : undefined}
                    placeholder="Nhập ghi chú"
                    rows={5}
                    allowClear
                    value={note}
                    onChange={(e: any) => setNote(e.target.value)}
                />
                <p style={{ marginTop: 30 }}>Thêm hình ảnh</p>
                <UploadComponent
                    uploadType="list"
                    maxLength={3}
                    listType="picture-card"
                    isUploadServerWhenUploading
                    onSuccessUpload={(res: any, fileList: any) => {
                        setImages((prev: any) => [...prev, res?.data?.url])
                        setFileList(fileList)
                    }}
                    onRemoveIndex={(deleteFile: any) => {
                        const listIds = fileList?.map((item: any) => item?.uid)
                        const deteleIndex = listIds?.indexOf(deleteFile?.uid)

                        const newFileList = fileList?.filter((item: any, index: number) => index !== deteleIndex)
                        const newImages = images?.filter((item: any, index: number) => index !== deteleIndex)

                        setFileList(newFileList)
                        setImages(newImages)

                        if (defaultImagesIds.includes(deleteFile.id)) {
                            setDeletedImages((prev: any) => [...prev, deleteFile.id])
                        }
                    }}
                    fileList={fileList}
                />
            </Spin>
        </Modal>
    )
}

export default EditNoteModal
