import { Checkbox, Input, InputNumber, Modal, Row, Select, Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface IUpdateProductPriceModal {
    isModalOpen: boolean
    onFinish: () => void
    updatePercent: number | undefined
    updateType: number
    setIsModalOpen: any
    setUpdatePercent: any
    setUpdateType: any
    loading?: boolean
}

const UpdateProductPriceModal = (props: IUpdateProductPriceModal) => {
    const {
        isModalOpen,
        onFinish,
        setIsModalOpen,
        setUpdatePercent,
        setUpdateType,
        updateType,
        updatePercent,
        loading,
    } = props

    const onCancel = () => {
        setIsModalOpen(false)
        setUpdatePercent(1)
    }
    return (
        <Modal
            centered
            title="Thiết lập giá bán mới"
            okText="Lưu"
            visible={isModalOpen}
            onOk={onFinish}
            onCancel={onCancel}
        >
            <Spin spinning={loading}>
                <CustomRow>
                    <p>Loại chênh lệch: </p>
                    <Select
                        value={updateType}
                        onChange={(value: number) => setUpdateType(value)}
                        defaultValue={1}
                        style={{ width: '60%' }}
                    >
                        <Select.Option value={1}>+</Select.Option>
                        <Select.Option value={0}>-</Select.Option>
                    </Select>
                </CustomRow>
                <CustomRow>
                    <p>(%) Theo giá bán: </p>
                    <InputNumber
                        value={updatePercent}
                        onChange={(value: any) => setUpdatePercent(value)}
                        style={{ width: '60%' }}
                        placeholder="Nhập %"
                        min={1}
                        max={100}
                    />
                </CustomRow>
            </Spin>
        </Modal>
    )
}

const CustomRow = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 14px;
`

export default UpdateProductPriceModal
