import { Spin } from 'antd'
import './Container.css'
import styled from 'styled-components'

type Props = {
    children?: any
    filterComponent?: any
    contentComponent?: any
    header?: any
    footer?: any
    contentStyle?: any
    loading?: boolean
}

const Container = ({
    // children,
    filterComponent,
    contentStyle,
    contentComponent,
    loading,
    header = () => {},
}: Props) => {
    return (
        <Spin spinning={false}>
            <StyledContainer>
                <div className="header_container">{header}</div>
                {filterComponent && <div className="content_filter_container">{filterComponent}</div>}
                {contentComponent?.map((item: any, index: number) => (
                    <div key={index} className={'content_container'} style={contentStyle}>
                        {item}
                    </div>
                ))}
            </StyledContainer>
        </Spin>
    )
}

const StyledContainer = styled.div`
    margin: 10px 10px 15px;
`

export default Container
