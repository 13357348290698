import React, { Component } from 'react'
import styled from 'styled-components'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

// example data export default [{col1: "value1", col2: "value2"}]
interface IProps {
    sheetName: string[]
    sheets: { [key: string]: any }
    fileName: string
    style?: React.CSSProperties
    className?: string
    onClick?: (fn: Function) => any
    loading?: boolean
    disabled?: boolean
}

interface IState {}

class ExportCsv extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {}
        this.exportToCSV = this.exportToCSV.bind(this)
    }

    private fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    private fileExtension = '.xlsx'

    public static getSheets(data: any[]): any {
        const x = XLSX.utils.json_to_sheet(data)
        // const a = XLSX.utils.sheet_add_aoa(
        //   x,
        //   [
        //     ['hàng đầu tiên sau dữ liệu', 1],
        //     ['hàng thứ hai sau dữ liệu', 2],
        //   ],
        //   { origin: -1 }
        // )
        return x
    }

    private async exportToCSV() {
        const wb: any = {
            title: 'hello',
            Sheets: this.props.sheets,
            SheetNames: this.props.sheetName,
        }

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], { type: this.fileType })

        FileSaver.saveAs(data, this.props.fileName + this.fileExtension)
    }

    render() {
        return (
            <Style
                style={
                    this.props.disabled ? { backgroundColor: 'gray', cursor: 'not-allowed' } : { ...this.props.style }
                }
                className={this.props.className}
                onClick={
                    this.props.disabled
                        ? undefined
                        : () => (this.props.onClick ? this.props.onClick(this.exportToCSV) : this.exportToCSV())
                }
            >
                {this.props.loading && (
                    <Spin
                        indicator={
                            <LoadingOutlined style={{ fontSize: 24, color: 'white', marginRight: '10px' }} spin />
                        }
                    />
                )}
                <span>{this.props.children}</span>
            </Style>
        )
    }
}

const Style = styled.div`
    background-color: #00abba;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    border-radius: 5px;
    height: 32px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    width: 120px;
    margin-right: 6px;

    i {
        margin-right: 8px;
    }
`

export default ExportCsv
