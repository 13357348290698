import { Form, FormInstance, FormItemProps } from 'antd'
import React, { useEffect, useRef } from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import SunEditorCore from 'suneditor/src/lib/core'
import plugins from 'suneditor/src/plugins'
import style from 'config/style'
import { ApiClient } from 'services/ApiService'

interface IProps extends FormItemProps {
    onChange?: (values?: string) => any
    placeholder?: string
    disable?: boolean
    form: FormInstance
    loading?: boolean
    height?: string
    setIsAllSpace?: any
}

const SunEditorComponent: React.FC<IProps> = ({
    onChange,
    placeholder,
    loading,
    disable,
    form,
    setIsAllSpace,
    height = '300px',
    ...rest
}) => {
    const buttonList = [
        ['undo', 'redo'],
        ['font', 'fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link' /** ,'math' */],
        ['fullScreen', 'showBlocks', 'codeView'],
        ['image', 'video', 'preview'],
    ]

    const formInstances = useRef<FormInstance>()
    const editor = useRef<SunEditorCore>()

    const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editor.current = sunEditor
    }

    useEffect(() => {
        formInstances.current = form
    }, [])

    useEffect(() => {
        loading &&
            editor?.current &&
            editor?.current?.setContents(formInstances?.current?.getFieldValue({ ...rest }.name as string))
    }, [loading, editor])

    // xử lý tải ảnh editor
    const handleImageUploadBefore = (files: any, info: any, uploadHandler: any) => {
        const fileUpload = files[0]
        const fmData = new FormData()
        const config = {
            headers: {
                Accept: 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
            },
        }
        fmData.append('file', fileUpload)
        ApiClient.postUploadFile('/files/upload/single/1', fmData, config).then((res: any) => {
            try {
                const response = {
                    result: [
                        {
                            url: `${res?.data?.url}`,
                            name: files[0].name,
                            size: files[0].size,
                        },
                    ],
                }
                uploadHandler(response)
            } catch (err: any) {
                uploadHandler(err.toString())
            }
        })
        return undefined
    }

    return (
        <Form.Item {...rest}>
            <SunEditor
                getSunEditorInstance={getSunEditorInstance}
                placeholder={placeholder}
                setAllPlugins={false}
                disable={disable}
                setOptions={{
                    height: height,
                    plugins: plugins,
                    buttonList: buttonList,
                }}
                setDefaultStyle={`font-family: Poppins, sans-serif; margin: 0; padding: 0; font-size: ${style.font.middle}; height: 500px;`}
                onChange={content => {
                    onChange && onChange(content)
                    formInstances.current?.setFieldsValue({
                        [{ ...rest }.name as string]: content,
                    })
                }}
                onImageUploadBefore={handleImageUploadBefore}
            />
        </Form.Item>
    )
}

export default SunEditorComponent
