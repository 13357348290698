import React from 'react'
import { Button, PageHeader, Popconfirm } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useHistory, useParams } from 'react-router-dom'
import { deletePost } from '../CommunityPostApi'
import { useSelector } from 'react-redux'
import { notificationSuccess } from 'utils/notification'
import { ADMIN_ROLE } from 'utils/constants'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { DataPostInteface } from '../InterfaceComPost'
interface IPost {
  df_type_user_id?: number
  post_id?: number
  data?: any
}
function DetailPostHeader(props: IPost) {
  const history = useHistory()
  const param: any = useParams()

  const userState = useSelector((state: any) => state.authReducer)?.userInfo
  return (
    <PageHeader
      style={{ width: '100%' }}
      title="Chi tiết bài đăng"
      onBack={() => {
        history.goBack()
      }}
      extra={[
        // props.df_type_user_id === ADMIN_ROLE.ADMIN ||
        // props.df_type_user_id === ADMIN_ROLE.ADMIN_EDITOR ? (
        <Button
          // type="primary"
          style={{
            backgroundColor: '#00abba',
            borderColor: '#00abba',
            height: '35px',
            fontWeight: 800,
            color: 'white',
          }}
          onClick={() => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.EDIT_POST}/${props?.data?.id}`,
              state: {
                data: props?.data,
                // getData : getData(),
              },
            })
          }}
        >
          <CheckCircleOutlined /> Sửa
        </Button>,
        // ) : (
        //   <></>
        // )
        <Popconfirm
          placement="bottomRight"
          title="Bạn có chắc chắn muốn xoá?"
          onConfirm={async () => {
            const res = await deletePost(param.id)
            if (res.code === 1) {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.COMMUNITY_POST}`,
              })
              notificationSuccess('Đã xóa bài đăng')
            }
          }}
          okText="Xoá"
          cancelText="Huỷ"
          okButtonProps={{
            danger: true,
            type: 'primary',
          }}
        >
          <Button type="primary" danger style={{ height: '35px' }}>
            <CloseCircleOutlined /> Xoá
          </Button>
        </Popconfirm>,
      ]}
    />
  )
}
export default DetailPostHeader
