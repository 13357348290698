import { Col, DatePicker, Divider, Input, Row, Select, Table, Tag } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { ADMIN_ROUTER_PATH } from 'common/config'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ORDER_STATUS, TAB_SHOP } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { getDetail } from '../ShopApi'

const Option = Select
const { RangePicker } = DatePicker
type Props = {
    id_shop: number
}

export default function OrderShop({ id_shop }: Props) {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<Array<any>>([])
    const [params, setParams] = useState<any>({
        id: id_shop,
        data: {
            type: TAB_SHOP.ORDER,
            search: '',
            status: '',
            category_id: '',
            stock_id: '',
            from_date: '',
            to_date: '',
            order_status: '',
            page: 1,
        },
    })
    useEffect(() => {
        sessionStorage.setItem('key', '3')
    })
    const [paging, setPaging] = useState({
        total: 0,
        current: 1,
        pageSize: 0,
    })

    const getListOrderShop = async () => {
        setIsLoading(true)
        try {
            console.log(params)
            const res = await getDetail(params)
            console.log(res.data)
            setDataTable(res.data)
            const formattedPaging = {
                total: res.paging.totalItemCount,
                current: res.paging.page,
                pageSize: res.paging.limit,
            }
            setPaging(formattedPaging)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
            setIsSearchLoading(false)
        }
    }

    useEffect(() => {
        getListOrderShop()
    }, [params])

    const columns = [
        {
            width: 70,
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text: any, record: any, index: any) => (
                <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
                    {(paging.current - 1) * paging.pageSize + index + 1}
                </td>
            ),
            ellipsis: true,
        },
        {
            title: 'Mã đơn',
            dataIndex: 'code',
        },
        {
            title: 'Khách hàng',
            dataIndex: 'User',
            key: 'User',
            render: (value: { id: number; name: string; phone: string }) => {
                return (
                    <>
                        <tr>{value.name}</tr>
                        <tr>({value.phone})</tr>
                    </>
                )
            },
        },
        {
            title: 'Người nhận hàng',
            dataIndex: 'UserAddress',
            key: 'UserAddress',
            render: (value: { id: number; name: string; phone: string }) => {
                return (
                    <>
                        <tr>{value.name}</tr>
                        <tr>({value.phone})</tr>
                    </>
                )
            },
        },
        {
            title: 'Số SP',
            dataIndex: 'total_amount',
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'total_price',
            render: (value: any) => {
                return <td>{formatPrice(value)} đ</td>
            },
        },
        {
            title: 'Trạng thái đơn hàng',
            dataIndex: 'status',
            render: (value: any) => {
                if (value === ORDER_STATUS.PENDING) return <Tag color="blue">Chờ xác nhận</Tag>
                if (value === ORDER_STATUS.INPROGRESS) return <Tag>Đã xác nhận</Tag>

                if (value === ORDER_STATUS.SUCCCESS) return <Tag color="green">Hoàn thành</Tag>
                if (value === ORDER_STATUS.CANCELED) return <Tag color="red">Huỷ</Tag>
            },
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'create_at',
            render: (value: any) => <td>{moment(value).format('DD-MM-YYYY')}</td>,
        },
    ]

    return (
        <>
            <div>
                <Row justify="end" gutter={[16, 16]} style={{ paddingRight: '15px' }}>
                    <Col span={8}>
                        <TypingAutoSearch
                            onSearchSubmit={(key: string) => {
                                setIsSearchLoading(true)
                                const data = { ...params.data, search: key.trim(), page: 1 }
                                setParams({ ...params, data })
                            }}
                            isSearchLoading={isSearchLoading}
                            placeholder="Mã đơn, tên khách hàng, SĐT khách hàng ..."
                        />
                    </Col>
                    <Col span={6}>
                        <Select
                            // allowClear
                            placeholder="TT đơn hàng"
                            style={{ width: '100%' }}
                            onChange={(value: any) => {
                                const data = { ...params.data, order_status: value, page: 1 }
                                setParams({ ...params, data })
                            }}
                        >
                            <Option value="">Tất cả</Option>
                            <Option value={ORDER_STATUS.PENDING}>Chờ xác nhận</Option>
                            <Option value={ORDER_STATUS.INPROGRESS}>Đang xử lý</Option>
                            <Option value={ORDER_STATUS.SUCCCESS}>Hoàn thành</Option>
                            <Option value={ORDER_STATUS.CANCELED}>Đã hủy</Option>
                        </Select>
                    </Col>
                    <Col span={6}>
                        <RangePicker
                            placeholder={['Từ ngày', 'Đến ngày']}
                            onChange={(dates: any, dateStrings: any[]) => {
                                const data = {
                                    ...params.data,
                                    from_date: dateStrings[0],
                                    to_date: dateStrings[1],
                                    page: 1,
                                }
                                setParams({ ...params, data })
                            }}
                        />
                    </Col>
                </Row>

                <Divider />

                <div
                    style={{
                        padding: '5px 10px',
                    }}
                >
                    <p>
                        Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                    </p>
                    <Table
                        className="table-expanded-custom-no-image"
                        scroll={{
                            x: 800,
                            scrollToFirstRowOnChange: true,
                            y: 'calc(100vh - 470px)',
                        }}
                        bordered
                        columns={columns}
                        dataSource={dataTable}
                        loading={isLoading}
                        onRow={(record, rowIndex) => ({
                            onDoubleClick: () => {
                                history.push({
                                    pathname: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${record.id}`,
                                    state: record,
                                })
                            },
                        })}
                        pagination={{
                            ...paging,
                            showSizeChanger: false,
                            onChange: async (page, pageSize) => {
                                const data = { ...params.data, page: page }
                                setParams({ ...params, data })
                            },
                        }}
                    />
                </div>
            </div>
        </>
    )
}
