import { ApiClient } from 'services/ApiService'

export const getListTypeGift = () => ApiClient.get('admin/gift/default/type-gift')
export const getListAssignGifts = (id: number) => ApiClient.get(`admin/gift/${id}/assignGift`)
export const getListGift = (payload: any) => ApiClient.get('/admin/gift', payload)
export const createGifts = (payload: any) => ApiClient.post('/admin/gift', payload)
export const updateGift = (payload: any) => ApiClient.put(`/admin/gift/${payload.id}`, payload.data)
export const changeStatusGift = (payload: any) => ApiClient.put(`/admin/gift/${payload}/status`)
export const deleteGift = (payload: any) => ApiClient.delete('/admin/gift', payload)
export const assignNewGift = (payload: any) => ApiClient.post('/admin/gift/assignGift', payload)
export const updateAssignGift = (id: any, payload: any) => ApiClient.put(`/admin/gift/${id}/assignToUser`, payload)
export const uploadImage = (payload: any) => ApiClient.post(`/files/upload/single/${payload.type}`, payload.data)
