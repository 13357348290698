import { EditOutlined } from '@ant-design/icons'
import { Card, Form, FormInstance, Input, Table } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { FIVE, MOBI, SEVEN, VIETTEL, VINA } from 'utils/Configs'

interface IHotlineByArea {
    data: any
    form: FormInstance<any>
}

const HotlineByArea = (props: IHotlineByArea) => {
    const { data } = props

    const columns = [
        {
            title: <b>Kênh liên hệ</b>,
            width: 300,
            dataIndex: 'contactWay',
        },
        {
            title: <b>Thông tin liên hệ</b>,
            width: 200,
            dataIndex: 'contactInfo',
            render: (value: any, record: any) => {
                return (
                    <Form.Item
                        style={{ marginBottom: 0 }}
                        initialValue={value}
                        name={`contactInfo_${record?.contactWay}_${record?.kiot_id}`}
                        rules={[
                            { required: true, message: 'Vui lòng nhập số điện thoại!' },
                            // {
                            //     message: 'Số điện thoại không hợp lệ!',
                            //     validator: (_, value) => {
                            //         if (!value?.toString()?.trim()) return Promise.resolve()
                            //         if (
                            //             (!value?.toString()?.match(SEVEN) &&
                            //                 !value?.toString()?.match(MOBI) &&
                            //                 !value?.toString()?.match(VIETTEL) &&
                            //                 !value?.toString()?.match(VINA) &&
                            //                 !value?.toString()?.match(FIVE)) ||
                            //             isNaN(Number(value))
                            //         ) {
                            //             return Promise.reject()
                            //         }
                            //         return Promise.resolve()
                            //     },
                            // },
                        ]}
                    >
                        <Input placeholder="Nhập số điện thoại" />
                    </Form.Item>
                )
            },
        },
    ]
    return (
        <Card title={<b>Hotline theo khu vực</b>} bordered>
            {data.contact_information.map((item: any) => (
                <CustomCard key={item} title={<b>{item?.kiotviet_name}</b>}>
                    <Table
                        id="top-table"
                        style={{ width: '100%' }}
                        bordered
                        columns={columns}
                        dataSource={[
                            {
                                contactWay: 'Zalo',
                                contactInfo: item?.zalo,
                                kiot_id: item?.kiotviet_id,
                            },
                            {
                                contactWay: 'Hotline',
                                contactInfo: item?.hotline,
                                kiot_id: item?.kiotviet_id,
                            },
                        ]}
                        scroll={{
                            x: 500,
                            scrollToFirstRowOnChange: true,
                        }}
                        locale={{
                            emptyText: 'Chưa có bản ghi nào!',
                        }}
                        pagination={false}
                    />
                </CustomCard>
            ))}
        </Card>
    )
}

const CustomCard = styled(Card)`
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #fbfbfb;
`

export default HotlineByArea
