import React from 'react'
import styled from 'styled-components'
import KIndexElement from './KIndexElement'
import { Button, Col, Form, Row, Spin, message } from 'antd'
import { getListKIndex, updateKIndex } from '../ConfigAPI'
import { useForm } from 'antd/lib/form/Form'
import { useSelector } from 'react-redux'

const KIndex = () => {
    const authState = useSelector((state: any) => state.authReducer)
    const kiotvietId = authState.userInfo.admin.kiotviet_id
    const [loading, setLoading] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any[]>([])
    const [form] = useForm()

    const onGetListKIndex = async () => {
        try {
            setLoading(true)
            const res: any = await getListKIndex()
            let finalData: any = []
            if (res?.status) {
                finalData = res?.data?.map((item: any) => {
                    const listBranchs = item?.branch?.map((branchItem: any) => ({
                        kiot_name: item?.name,
                        branchName: branchItem?.name,
                        kIndex: branchItem.kcoefficient || 0,
                        kiotviet_id: item?.id,
                        branch_id: branchItem?.id,
                    }))

                    return [
                        {
                            branchName: 'Toàn khu vực',
                            kiot_name: item?.name,
                            kIndex: item?.kcoefficient || 0,
                            kiotviet_id: item?.id,
                            branch_id: null,
                        },
                        ...listBranchs,
                    ]
                })

                console.log(finalData)

                if (!kiotvietId) {
                    setData(finalData)
                } else {
                    // lấy ra hệ số k theo kiotviet ID
                    const targetList = finalData?.filter((item: any) => item?.[0]?.kiotviet_id === kiotvietId)
                    setData(targetList)
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleSetKIndex = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                config: Object.entries(values).map(([key, value]) => {
                    const [_, kiotviet_id, branch_id] = key.split('_')
                    return {
                        value: value,
                        branch_id: Number(branch_id),
                        kiotviet_id: kiotviet_id === 'null' ? null : Number(kiotviet_id),
                    }
                }),
            }
            const res: any = await updateKIndex(payload)
            if (res?.status) {
                message.success('Cập nhật hệ số K thành công!')
                onGetListKIndex()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        onGetListKIndex()
    }, [])

    return (
        <Spin spinning={loading}>
            <Container>
                <Form form={form} onFinish={handleSetKIndex}>
                    <CustomRow>
                        <p>Cấu hình hệ số K theo khu vực và chi nhánh</p>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Lưu
                            </Button>
                        </Form.Item>
                    </CustomRow>
                    <Row className="custom_row" gutter={[32, 32]}>
                        {data.map((item: any, index: number) => (
                            <Col key={index} xs={24} sm={12} md={8} lg={12} xl={12} xxl={12}>
                                <KIndexElement item={item} />
                            </Col>
                        ))}
                    </Row>
                </Form>
            </Container>
        </Spin>
    )
}

const Container = styled.div`
    .custom_row {
        flex-wrap: wrap;
    }
`

const CustomRow = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export default KIndex
