import { Button, Col, DatePicker, Row, Select } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import React from 'react'
import styled from 'styled-components'
import { ADMIN_ROLE, STATUS } from 'utils/constants'
const { Option } = Select
const { RangePicker } = DatePicker

const Container = styled.div`
    width: 100%;
    padding: 0.5rem;
    background-color: white;
    border-bottom: 1px solid #dddd;
`

type HeaderProps = {
    // setIsCreate: any
    onSearchSubmit: (searchKey: string) => void
    onDateSubmit: (from_dateKey: string, to_dateKey: string) => void
    isSearchLoading: boolean
}

export const Filter = ({
    onSearchSubmit,
    isSearchLoading,
    onDateSubmit,
}: // checkedTable,
HeaderProps) => {
    return (
        <Row gutter={[16, 16]} justify="end">
            <Col span={12}>
                <TypingAutoSearch
                    onSearchSubmit={(key: string) => {
                        onSearchSubmit(key.trim())
                    }}
                    isSearchLoading={isSearchLoading}
                    placeholder="Tên gian hàng ..."
                />
            </Col>

            <Col span={6}>
                <RangePicker
                    placeholder={['Từ ngày', 'Đến ngày']}
                    onChange={(value, dateString) => {
                        onDateSubmit(dateString[0], dateString[1])
                    }}
                />
            </Col>
        </Row>
    )
}
