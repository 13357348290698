import { createSlice } from '@reduxjs/toolkit'

interface IinitialState {
    productIds: number[]
    isUpdateAll: boolean
    disSelectProductIds: number[]
}

const initialState: IinitialState = {
    productIds: [],
    isUpdateAll: false,
    disSelectProductIds: [],
}

export const productSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        // Chọn nhiều
        setMultipleProductIds: (state, action) => {
            const status = action?.payload?.selected
            const targetList = action?.payload?.newListKeys
            const changeRows = action?.payload?.changeRows

            // Tích chọn
            if (status) {
                state.productIds = [...state.productIds, ...targetList]

                // Nếu có item đã tồn tại trong list xoá -> Xoá
                if (state.isUpdateAll) {
                    const newListRemove = state.disSelectProductIds?.filter(
                        (item: any) => !state.productIds.includes(item)
                    )
                    state.disSelectProductIds = newListRemove
                }

                // Bỏ chọn
            } else {
                state.productIds = targetList

                if (state.isUpdateAll) {
                    const removeList = changeRows?.map((item: any) => item?.id)
                    state.disSelectProductIds = [...state.disSelectProductIds, ...removeList]
                }
            }
        },

        setOneProductId: (state, action) => {
            const status = action?.payload?.selected
            const targetItem = action?.payload?.record

            // Tích chọn
            if (status) {
                state.productIds = [...state.productIds, targetItem?.id]

                if (state.isUpdateAll) {
                    const newListRemove = state.disSelectProductIds?.filter((item: any) => item !== targetItem?.id)
                    state.disSelectProductIds = newListRemove
                }
            } else {
                const newList = state.productIds?.filter((item: any) => item !== targetItem?.id)
                state.productIds = newList

                if (state.isUpdateAll) {
                    state.disSelectProductIds = [...state.disSelectProductIds, targetItem.id]
                }
            }
        },

        setIsUpdateAllProduct: (state, action) => {
            state.isUpdateAll = action.payload
            if (!action.payload) {
                state.disSelectProductIds = []
            }
        },

        resetProduct: state => {
            state.productIds = []
            state.disSelectProductIds = []
            state.isUpdateAll = false
        },
    },
})

export const { setMultipleProductIds, resetProduct, setOneProductId, setIsUpdateAllProduct } = productSlice.actions
export default productSlice.reducer
