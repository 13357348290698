import { Card, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'

interface IStaffGeneralInfo {
    staffDetail: any
    provinces: any
}

const StaffGeneralInfo = (props: IStaffGeneralInfo) => {
    const { staffDetail, provinces } = props
    return (
        <>
            <CustomCard title="Thông tin nhân viên kho" bordered={false}>
                <Row className="row">
                    <span>
                        Tên: <b>{staffDetail?.full_name}</b>
                    </span>
                    <span>
                        SĐT: <b>{staffDetail?.phone_number}</b>
                    </span>
                </Row>
                <Row className="row">
                    <span>
                        Giới tính: <b>{!staffDetail?.gender ? '---' : staffDetail?.gender === 'man' ? 'Nam' : 'Nữ'}</b>
                    </span>
                    <span>
                        Ngày sinh: <b>{momentToStringDate(staffDetail?.date_of_birth) || '---'}</b>
                    </span>
                </Row>
                <Row className="row">
                    <span>
                        Địa chỉ:{' '}
                        <b>
                            {staffDetail?.province_id
                                ? provinces.find((item: any) => item?.id === Number(staffDetail?.province_id))?.name
                                : '---'}
                        </b>
                    </span>
                    <span>
                        Email: <b>{staffDetail?.email}</b>
                    </span>
                </Row>
            </CustomCard>
            <CustomCard title="Tổng quan" bordered={false}>
                <Row className="row">
                    <div className="block">
                        <p className="money" style={{ color: 'green' }}>
                            {formatPrice(staffDetail?.total_completed_orders)}
                        </p>
                        <p>Đơn hoàn thành</p>
                    </div>
                    <div className="block">
                        <p className="money" style={{ color: '#c8736b' }}>
                            {formatPrice(staffDetail?.total_assign_orders)}
                        </p>
                        <p>Tổng đơn</p>
                    </div>
                </Row>
            </CustomCard>
        </>
    )
}

const CustomCard = styled(Card)`
    width: 49%;
    border-radius: 10px;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 20px;
        flex-wrap: wrap;

        .block {
            text-align: center;
            flex: 1;

            .money {
                font-size: 24px;
                font-weight: 600;
                margin: 0;
            }
        }
    }
`

export default StaffGeneralInfo
