import { ApiClient } from 'services/ApiService'
import {
    CreateAccountType,
    IAccountPayload,
    IAccount,
    ResponseData,
    UpdateAccountType,
    ICreateAccount,
    IUpdateAccount,
    IKiotviet,
} from './AccountInterfaces'

export const getAccounts = (payload: IAccountPayload): Promise<ResponseData<IAccount[]>> => {
    return ApiClient.get('/admin/admin', payload)
}

export const getListKiotViet = (): Promise<ResponseData<IKiotviet[]>> => {
    return ApiClient.get('/admin/kiotviet')
}

export const getDetailAccount = (id: string): Promise<ResponseData<IAccount>> => {
    return ApiClient.get(`/admin/admin/${id}`)
}

export const createAccount = (payload: CreateAccountType): Promise<ResponseData<ICreateAccount>> =>
    ApiClient.post('/admin/admin', payload)

export const updateAccount = (payload: UpdateAccountType): Promise<ResponseData<IUpdateAccount>> => {
    return ApiClient.path(`/admin/admin/${payload.id}`, payload.body)
}

export const resetPassword = (id: string) => {
    ApiClient.path(`/admin/admin/${id}/reset-password`)
}

export const deleteAccount = (id: string): Promise<ResponseData<null>> => ApiClient.delete(`/admin/admin/${id}`)

export const activeAccount = (id: string): Promise<ResponseData<IUpdateAccount>> => {
    return ApiClient.path(`/admin/admin/${id}/active`)
}

export const inactiveAccount = (id: string): Promise<ResponseData<IUpdateAccount>> => {
    return ApiClient.path(`/admin/admin/${id}/inactive`)
}

export const uploadImage = (type: string) => {
    return ApiClient.post(`/files/upload/single/${type}`, { type })
}
