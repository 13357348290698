import { Empty, message, PageHeader, Radio, Row, Select, Spin, Table, Tag, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ORDER_STATUS, CREATALE_TYPE, ADMIN_ROLE } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { convertTimeStampToString, momentToStringDate } from 'utils/TimerHelper'
import OrderHeader from './components/OrderHeader'
import { IFormatedDataOrder, IListKioviet, IOrderPayload } from './Interfaces'
import { exportExcelOrder, getListKiotViet, getOrder } from './OrderAPI'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import { DownloadOutlined, FileAddOutlined, RedoOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import AssignOrderForShipper from '../refund-order/components/AssignOrderForShipper'
import { renderDeliveryStatus, renderPaymentStatus, toNonAccentVietnamese } from 'utils/Configs'
import {
    resetOrders,
    setIsExportingExcel,
    setMultipleOrderData,
    setMultipleOrderIds,
    setOneOrderData,
    setOneOrderIds,
} from './OrderSlice'
import AssignOrderForInventorStaff from './components/AssignOrderForInventoryStaff'
import React from 'react'
import { getListBranchs } from '../report/branch_report/services'
import { formatTime, renderInventoryStatus } from 'utils/funcHelper'
import ExportCsv from 'utils/ExportCSV'
import ButtonSave from 'common/components/Button/ButtonSave'
import { syncAllOrder } from '../customer-clone/CustomerAPI'
import { notificationError, notificationSuccess } from 'utils/notification'
import LocalStorage from 'utils/LocalStorage'
const { Text } = Typography

const { PENDING, INPROGRESS, SUCCCESS, CANCELED } = ORDER_STATUS

const WapperTable = styled.div`
    background-color: white;
    margin: 0px 10px 15px;
    padding: 10px 20px;
`

export const creatableDesc = (value?: string) => {
    switch (value) {
        case CREATALE_TYPE.ORDER_BY_CUSTOMER:
            return (
                <Tooltip placement="topLeft" title={'Đơn từ App HTT'}>
                    App HTT
                </Tooltip>
            )
        case CREATALE_TYPE.ORDER_BY_STAFF:
            return (
                <Tooltip placement="topLeft" title={'Kioviet'}>
                    Kiotviet
                </Tooltip>
            )
        default:
            return '--'
    }
}

function OrderManage({ location }: any) {
    const dispatch = useDispatch()
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isSaleManager = userInfor?.admin?.role === ADMIN_ROLE.SALE_MANAGER
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN
    const isExportingExcel = useSelector((state: any) => state.orderReducer.isExportingExcel)

    const [isExporting, setIsExporting] = React.useState<boolean>(false)

    const selectedOrderIds = useSelector((state: any) => state.orderReducer.orderIds)
    const selectedOrderRows = useSelector((state: any) => state.orderReducer.orderList)
    const authState = useSelector((state: any) => state.authReducer)
    const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false)
    const [isAssignInventoryModalOpen, setIsAssignInventoryModalOpen] = useState<boolean>(false)
    const [isAssignFor, setIsAssignFor] = React.useState<number>()

    const [listRegion, setlistRegion] = useState<IListKioviet[]>([])
    const [selectedBranchId, setSelectedBranchId] = React.useState<number>()
    const [branches, setBranches] = React.useState<any[]>([])

    const [selectRegion, setselectRegion] = useState<number>(
        isSaleManager || isShopAdmin ? userInfor?.admin?.kiotviet_id : undefined
    )
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [listRowKeysSelected, setListRowKeysSelected] = useState<any[]>(selectedOrderIds)
    const [selectedRows, setSelectedRows] = useState<any[]>(selectedOrderRows)
    const [params, setparams] = useState<any>({
        search: '',
        page: location.state?.paging?.current ? location.state?.paging?.current : '',
        limit: '12',
        status: undefined,
        user_id: '',
        creatable_type: undefined,
        create_from: '',
        create_to: '',
        branch_id: selectedBranchId,
        package_status: undefined,
        delivery_status: undefined,
        kiotviet_id: isSaleManager || isShopAdmin ? userInfor?.admin?.kiotviet_id : undefined,
    })

    const [paging, setPaging] = useState({
        total: 0,
        current: location.state?.paging?.current ? location.state?.paging?.current : 1,
        pageSize: 0,
    })

    const history = useHistory()
    const [listOrder, setListOrder] = useState<IFormatedDataOrder[]>()

    const handleSearchSubmit = (value: string) => {
        setparams({
            ...params,
            search: value,
            page: location.state?.paging?.current ? location.state?.paging?.current : 1,
        })
    }
    const handlePackageStatusSubmit = (value: number) => {
        setparams({
            ...params,
            package_status: value,
            page: location.state?.paging?.current ? location.state?.paging?.current : 1,
        })
    }
    const handleDeliveryStatusSubmit = (value: number) => {
        setparams({
            ...params,
            delivery_status: value,
            page: location.state?.paging?.current ? location.state?.paging?.current : 1,
        })
    }
    const handleStatusSubmit = (value: string) => {
        setparams({
            ...params,
            status: value,
            page: location.state?.paging?.current ? location.state?.paging?.current : 1,
        })
    }
    const handleTypeSubmit = (value: string) => {
        setparams({
            ...params,
            creatable_type: value,
            page: location.state?.paging?.current ? location.state?.paging?.current : 1,
        })
    }
    const handleDateSubmit = (startDate: string, endDate: string) => {
        setparams({
            ...params,
            create_from: startDate,
            create_to: endDate,
            page: location.state?.paging?.current ? location.state?.paging?.current : 1,
        })
    }

    const getListOrder = async (params: any) => {
        setisLoading(true)
        try {
            const res = await getOrder(params)
            if (res.data) {
                const tableData = res.data.map((item: any, index: number) => {
                    return { ...item, key: item.id, stt: index + 1 }
                })
                const formattedPaging = {
                    total: res.paging.totalItemCount,
                    current: res.paging.page,
                    pageSize: res.paging.limit,
                }
                setListOrder(tableData)
                setPaging(formattedPaging)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }

    const getBranchsByKiotviet = async () => {
        try {
            const payload = {
                kiotviet_id: selectRegion,
            }
            const res = await getListBranchs(payload)
            if (res?.data) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setBranches(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getListOrder(params)
    }, [params, selectedBranchId])

    React.useEffect(() => {
        if (selectRegion) {
            getBranchsByKiotviet()
        }
    }, [selectRegion])

    React.useEffect(() => {
        setIsExporting(isExportingExcel)
    }, [isExportingExcel])

    const columns: ColumnsType<IFormatedDataOrder> = [
        Table.SELECTION_COLUMN,

        {
            width: 65,
            title: 'STT',
            dataIndex: 'index',
            render: (text, record, index) => (
                <Text id={record.id.toString()}>{(paging.current - 1) * paging.pageSize + index + 1}</Text>
            ),
            ellipsis: true,
        },
        {
            title: 'Mã đơn',
            dataIndex: 'code',
            width: 110,
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: 'Khách hàng',
            dataIndex: 'user',
            width: 130,
            ellipsis: {
                showTitle: false,
            },
            render: user => (
                <Tooltip placement="topLeft" title={user?.full_name}>
                    {user?.full_name}
                </Tooltip>
            ),
        },
        {
            width: 75,
            title: 'Số sp',
            dataIndex: 'quantity_product',
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'total',
            width: 120,
            render: totalMoney => <td>{formatPrice(totalMoney)} đ</td>,
        },
        {
            title: 'Xu',
            dataIndex: 'use_coin',
            width: 120,
            render: use_coin => <td>{formatPrice(use_coin)} xu</td>,
        },
        {
            align: 'center',
            width: '130px',
            title: 'TT đơn hàng',
            dataIndex: 'status',
            render: statusOrder => renderOrderStatus(statusOrder),
        },
        {
            align: 'center',
            width: '130px',
            title: 'TT kho',
            dataIndex: 'package_status',
            render: (_: any, record: any) => renderInventoryStatus(record?.order_package_status),
        },
        {
            align: 'center',
            width: '130px',
            title: 'Nhân viên kho',
            dataIndex: 'inventory_staff_name',
            render: (value: any) => value || '---',
        },
        {
            align: 'center',
            width: '130px',
            title: 'Shipper',
            dataIndex: 'shipper_name',
            render: (value: any) => value || '---',
        },

        {
            align: 'center',
            width: '130px',
            title: 'Người giao đơn',
            dataIndex: 'admin_name',
            render: (value: any) => value || '---',
        },
        {
            align: 'center',
            width: '130px',
            title: 'Đã thanh toán',
            dataIndex: 'total_payment',
            render: (value: any, record: any) => formatPrice(record?.total_payment) || '---',
        },

        {
            align: 'center',
            width: '130px',
            title: 'TT thanh toán',
            dataIndex: 'payment_status',
            render: statusOrder => renderPaymentStatus(statusOrder),
        },

        {
            align: 'center',
            width: '130px',
            title: 'TT giao hàng',
            dataIndex: 'order_delivery_status',
            render: statusOrder => renderDeliveryStatus(statusOrder),
        },
        {
            align: 'center',
            width: '130px',
            title: 'Thời gian giao hàng',
            dataIndex: 'delivery_time',
            render: (value: any) => formatTime(value) || '---',
        },
        {
            align: 'center',
            width: '130px',
            title: 'Thời gian đóng hàng',
            dataIndex: 'package_time',
            render: (value: any) => formatTime(value) || '---',
        },
        {
            align: 'center',
            width: '130px',
            title: 'Tổng thời gian giao đơn',
            dataIndex: 'assign_time',
            render: (value: any) => formatTime(value) || '---',
        },

        {
            width: '130px',
            title: 'Nguồn đơn',
            dataIndex: 'creatable_type',
            render: creatable_type => creatableDesc(creatable_type),
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_at',
            width: 120,
            render: createdAt => <td>{momentToStringDate(createdAt, 'dateTime')}</td>,
        },
    ]

    const renderOrderStatus = (value: string) => {
        switch (value) {
            case PENDING:
                return <Tag color="warning">Chờ xác nhận</Tag>
            case INPROGRESS:
                return <Tag color="blue">Đã xác nhận</Tag>
            case SUCCCESS:
                return <Tag color="green">Hoàn thành</Tag>
            case CANCELED:
                return <Tag color="red">Huỷ</Tag>
            default:
                return ''
        }
    }

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                setlistRegion(res.data)
            }
        } catch (err) {
            console.log(err)
            message.error('Có lỗi xảy ra!')
        }
    }

    const onExportDataToExcel = async (fn: any) => {
        try {
            dispatch(setIsExportingExcel(true))
            LocalStorage.setIsExportingExcel('1')
            const payload = {
                ...params,
                page: undefined,
                limit: undefined,
            }
            const res = await exportExcelOrder(payload)
            if (res.data) {
                message.success('Quá trình export đang diễn ra!')
            }
        } catch (error) {
            console.error(error)
        } finally {
        }
    }

    const syncOrders = async () => {
        try {
            setisLoading(true)
            const res: any = await syncAllOrder(`${selectRegion}`)
            if (res?.status) {
                notificationSuccess(res?.msg)
            }
        } catch (error) {
            notificationError('Đã có lỗi xảy ra vui lòng chờ một vài phút và thử lại')
        } finally {
            setisLoading(false)
        }
    }

    useEffect(() => {
        getDataKiotviet()
    }, [])

    const handleDetailOrder = (record: any, rowIndex?: number) => {
        return {
            onClick: () => {
                history.push({
                    pathname: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${record.id}`,
                    state: {
                        id: record.id,
                        paging: paging,
                        path: ADMIN_ROUTER_PATH.ORDER,
                    },
                })
            },
        }
    }

    return (
        <Spin spinning={isLoading}>
            <div style={{ backgroundColor: 'white', margin: '5px 10px 15px' }}>
                <PageHeader
                    title="Đơn hàng"
                    extra={[
                        <OrderHeader
                            params={params}
                            isSearchLoading={isLoading}
                            onSearchSubmit={(searchKey: string) => handleSearchSubmit(searchKey)}
                            onStatusSubmit={(status: string) => {
                                handleStatusSubmit(status)
                            }}
                            handlePackageStatusSubmit={(status: number) => {
                                handlePackageStatusSubmit(status)
                            }}
                            handleDeliveryStatusSubmit={(status: number) => {
                                handleDeliveryStatusSubmit(status)
                            }}
                            onTypeSubmit={(creatable_type: string) => {
                                handleTypeSubmit(creatable_type)
                            }}
                            onDateSubmit={(startDate: string, endDate: string) => {
                                handleDateSubmit(startDate, endDate)
                            }}
                        />,
                    ]}
                />
            </div>

            <WapperTable>
                <CustomRow>
                    <div>
                        <Radio.Group
                            style={{ marginBottom: 20 }}
                            defaultValue={selectRegion}
                            onChange={(e: any) => {
                                setselectRegion(e.target.value)
                                setListRowKeysSelected([])
                                setSelectedRows([])
                                dispatch(resetOrders())
                                setparams({ ...params, kiotviet_id: e.target.value, page: '1', branch_id: undefined })
                                setSelectedBranchId(undefined)
                                setIsAssignFor(undefined)
                            }}
                        >
                            <Radio.Button disabled={isSaleManager || isShopAdmin} value={undefined}>
                                Tất cả
                            </Radio.Button>
                            {listRegion &&
                                listRegion.map((region, index) => (
                                    <Radio.Button
                                        key={index}
                                        value={region.id}
                                        disabled={
                                            (isSaleManager && region.id !== userInfor?.admin?.kiotviet_id) ||
                                            (isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id)
                                        }
                                    >
                                        {region.name ? region.name : '--'}
                                    </Radio.Button>
                                ))}
                        </Radio.Group>
                        {selectRegion && !isSaleManager && (
                            <Select
                                placeholder="Giao đơn cho"
                                showArrow
                                showSearch
                                value={isAssignFor}
                                onChange={(value: number) => {
                                    setIsAssignFor(value)
                                    setListRowKeysSelected([])
                                    setSelectedRows([])
                                    setSelectedBranchId(undefined)
                                    setparams({
                                        ...params,
                                        page: location.state?.paging?.current ? location.state?.paging?.current : 1,
                                        branch_id: undefined,
                                    })
                                    dispatch(setMultipleOrderIds({ newListKeys: [], selected: false }))
                                    dispatch(setMultipleOrderData({ changeRows: [], selected: false }))
                                }}
                                style={{ marginLeft: 20, width: 150 }}
                                filterOption={(input, option: any) => {
                                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                    return nonAccent.includes(nonAccentInput)
                                }}
                            >
                                <Select.Option value={1}>Shipper</Select.Option>
                                <Select.Option value={2}>Nhân viên kho</Select.Option>
                            </Select>
                        )}
                        {isAssignFor === 2 && (
                            <Select
                                placeholder="Chọn chi nhánh"
                                showArrow
                                showSearch
                                allowClear
                                options={branches}
                                value={selectedBranchId}
                                onChange={(value: number | undefined) => {
                                    setSelectedBranchId(value)
                                    setListRowKeysSelected([])
                                    setSelectedRows([])
                                    setparams({
                                        ...params,
                                        page: location.state?.paging?.current ? location.state?.paging?.current : 1,
                                        branch_id: value,
                                    })
                                    dispatch(setMultipleOrderIds({ newListKeys: [], selected: false }))
                                    dispatch(setMultipleOrderData({ changeRows: [], selected: false }))
                                }}
                                style={{ marginLeft: 20 }}
                                filterOption={(input, option: any) => {
                                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                    return nonAccent.includes(nonAccentInput)
                                }}
                            />
                        )}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <ExportCsv
                            disabled={isExporting}
                            onClick={fn => {
                                onExportDataToExcel(fn)
                            }}
                            sheetName={['OrderList']}
                            sheets={{
                                OrderList: ExportCsv.getSheets([]),
                            }}
                            fileName="Danh sách đơn hàng"
                        >
                            <DownloadOutlined />
                            &nbsp;Export
                        </ExportCsv>
                        {selectRegion && (
                            <ButtonSave
                                onClickButton={syncOrders}
                                text="Đồng bộ"
                                icon={<VerticalAlignBottomOutlined />}
                            />
                        )}
                        <ButtonAdd
                            onClickButton={() => getListOrder(params)}
                            text="Tải lại trang"
                            background="#e66fc8"
                            icon={<RedoOutlined />}
                            styleButton={{ marginLeft: 6 }}
                        />

                        {selectRegion && isAssignFor === 1 && (
                            <ButtonAdd
                                onClickButton={() => {
                                    if (!params.kiotviet_id) {
                                        message.error('Vui lòng chọn khu vực!')
                                    } else if (selectedRows.length === 0) {
                                        message.error('Vui lòng chọn ít nhất một đơn hàng!')
                                    } else {
                                        setIsAssignModalOpen(true)
                                    }
                                }}
                                text="Giao đơn cho shipper"
                                background="#e76767"
                                icon={<FileAddOutlined />}
                                styleButton={{ marginLeft: 6 }}
                            />
                        )}
                        {selectRegion && isAssignFor === 2 && selectedBranchId && (
                            <ButtonAdd
                                onClickButton={() => {
                                    if (!params.kiotviet_id) {
                                        message.error('Vui lòng chọn khu vực!')
                                    } else if (selectedRows.length === 0) {
                                        message.error('Vui lòng chọn ít nhất một đơn hàng!')
                                    } else {
                                        setIsAssignInventoryModalOpen(true)
                                    }
                                }}
                                text="Giao đơn cho kho"
                                background="#4b90be"
                                icon={<FileAddOutlined />}
                                styleButton={{ marginLeft: 6 }}
                            />
                        )}
                    </div>
                </CustomRow>
                <p style={{ marginTop: '25px' }}>
                    Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                </p>
                {isAssignModalOpen && (
                    <AssignOrderForShipper
                        setIsModalOpen={setIsAssignModalOpen}
                        isModalOpen={isAssignModalOpen}
                        listOrders={selectedRows}
                        isRefundOrder={false}
                        params={params}
                        isLoading={isLoading}
                        setisLoading={setisLoading}
                        getListData={getListOrder}
                        setSelectedRows={setSelectedRows}
                        setListRowKeysSelected={setListRowKeysSelected}
                    />
                )}
                {isAssignInventoryModalOpen && (
                    <AssignOrderForInventorStaff
                        setIsModalOpen={setIsAssignInventoryModalOpen}
                        isModalOpen={isAssignInventoryModalOpen}
                        listOrders={selectedRows}
                        isRefundOrder={false}
                        params={params}
                        isLoading={isLoading}
                        setisLoading={setisLoading}
                        getListData={getListOrder}
                        setSelectedRows={setSelectedRows}
                        setListRowKeysSelected={setListRowKeysSelected}
                    />
                )}
                <Table
                    // className="table-expanded-custom-no-image"
                    bordered
                    size="large"
                    columns={columns}
                    dataSource={listOrder}
                    loading={isLoading}
                    rowSelection={
                        selectRegion && isAssignFor
                            ? {
                                  selectedRowKeys: listRowKeysSelected,
                                  onSelect: (record: any, selected: any) => {
                                      if (selected) {
                                          setSelectedRows((prev: any) => [...prev, record])
                                          setListRowKeysSelected((prev: any) => [...prev, record?.id])
                                      } else {
                                          const newSelectedRows = selectedRows?.filter(
                                              (item: any) => item?.id !== record?.id
                                          )
                                          setSelectedRows(newSelectedRows)

                                          const newListKeys = listRowKeysSelected?.filter(
                                              (item: any) => item !== record?.id
                                          )
                                          setListRowKeysSelected(newListKeys)
                                      }
                                      dispatch(setOneOrderIds({ record, selected }))
                                      dispatch(setOneOrderData({ record, selected }))
                                  },

                                  onSelectAll: (selected: boolean, selectedRowList: any[], changeRows: any[]) => {
                                      if (selected) {
                                          setSelectedRows((prev: any) => [...prev, ...changeRows])
                                          const newListKeys = changeRows?.map((item: any) => item?.id)
                                          setListRowKeysSelected((prev: any) => [...prev, ...newListKeys])
                                          dispatch(setMultipleOrderIds({ newListKeys, selected }))
                                          dispatch(setMultipleOrderData({ changeRows, selected }))
                                      } else {
                                          const deselectedRowKeys = changeRows?.map((item: any) => item?.id)

                                          const newListKeys = listRowKeysSelected?.filter(
                                              (item: any) => !deselectedRowKeys.includes(item)
                                          )
                                          const newListRows = selectedRows?.filter((item: any) =>
                                              newListKeys.includes(item?.id)
                                          )

                                          setSelectedRows(newListRows)
                                          dispatch(setMultipleOrderData({ changeRows: newListRows, selected }))

                                          setListRowKeysSelected(newListKeys)
                                          dispatch(setMultipleOrderIds({ newListKeys, selected }))
                                      }
                                  },
                                  getCheckboxProps: (record: any) => ({
                                      disabled:
                                          isAssignFor === 1
                                              ? record.order_delivery_status === 1 ||
                                                record.order_delivery_status === 2 ||
                                                record.status === CANCELED ||
                                                record.order_package_status !== 2
                                              : record.order_package_status > 0 || record.status === CANCELED,
                                      name: record.name,
                                  }),
                              }
                            : undefined
                    }
                    scroll={{
                        x: 800,
                        scrollToFirstRowOnChange: true,
                    }}
                    onRow={(record: any, rowIndex?: number) => handleDetailOrder(record, rowIndex)}
                    locale={{
                        emptyText: (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có dữ liệu.</span>} />
                        ),
                    }}
                    pagination={{
                        ...paging,
                        showSizeChanger: false,
                        onChange: async page => {
                            setparams({ ...params, page: page.toString() })
                        },
                    }}
                />
            </WapperTable>
        </Spin>
    )
}

const CustomRow = styled(Row)`
    margin: 28px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text {
        font-size: 12px;
        margin: 0;
    }
`

export default memo(OrderManage)
