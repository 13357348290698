import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Descriptions,
  Image,
  message,
  PageHeader,
  Popconfirm,
} from 'antd'
import { useState, useEffect, memo } from 'react'
import { useHistory } from 'react-router-dom'
import reactotron from 'ReactotronConfig'
import styled from 'styled-components'
import { FLOWER_DELIVERY_STATUS } from 'utils/constants'
import { notificationError } from 'utils/notification'
import {
  changeStatusFlowerDelivery,
  getFlowerDelivery,
  getFlowerDeliveryDetail,
} from '../../FlowerDeliveryAPI'
import ModalReject from './ModalReject'

interface IWapperStyle {
  padding?: boolean
}

const Wapper = styled.div<IWapperStyle>`
  background-color: white;
  margin: 10px;
  border-radius: 10px;
  padding: ${props => (props.padding ? '15px' : null)};
`

function FlowerDeliveryDetail({ location }: any) {
  const history = useHistory()
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    getData()
  }, [location?.state?.id])

  const getData = async () => {
    try {
      const res = await getFlowerDeliveryDetail(location.state?.id)
      if (res.data) {
        setData(res.data)
      }
    } catch (err: any) {
      notificationError('Đã có lỗi xảy ra')
    }
  }

  const updateStatus = async (status: any, reject_note: string) => {
    try {
      await changeStatusFlowerDelivery({
        id: location.state?.id,
        data: {
          status,
          reject_note,
        },
      })
      message.success(`Cập nhật trạng thái thành công`)
      getData()
      setIsShowModal(false)
    } catch (error) {}
  }

  const statusString = (status: number) => {
    switch (status) {
      case FLOWER_DELIVERY_STATUS.REQUESTED:
        return 'Chờ xử lý'
      case FLOWER_DELIVERY_STATUS.REJECTED:
        return 'Từ chối'

      default:
        return 'Đã xác nhận'
    }
  }

  return (
    <>
      {data && (
        <div>
          <ModalReject
            showModal={isShowModal}
            confirm={(reject_note: string) => {
              updateStatus(FLOWER_DELIVERY_STATUS.REJECTED, reject_note)
            }}
            cancel={() => setIsShowModal(false)}
          />
          <Wapper>
            <PageHeader
              onBack={() => history.push('/ad-flower-delivery-request')}
              title="Chi tiết yêu cầu điện hoa"
              extra={
                data.status === FLOWER_DELIVERY_STATUS.REQUESTED && [
                  <Button danger onClick={() => setIsShowModal(true)}>
                    <CloseCircleOutlined />
                    Từ chối
                  </Button>,
                  <Popconfirm
                    placement="topRight"
                    title=" Phê duyệt cho yêu cầu điện hoa này?"
                    onConfirm={() =>
                      updateStatus(FLOWER_DELIVERY_STATUS.APPROVED, '')
                    }
                    onCancel={() => {}}
                    okText="Phê duyệt"
                    cancelText="Quay lại"
                  >
                    <Button type="primary">
                      <CheckCircleOutlined />
                      Phê duyệt
                    </Button>
                    ,
                  </Popconfirm>,
                ]
              }
            />
          </Wapper>

          <Wapper padding>
            <Descriptions title="Thông tin yêu cầu">
              <Descriptions.Item label="Tên khách hàng">
                {data.receiver_name}
              </Descriptions.Item>
              <Descriptions.Item label="Số điện thoại" span={5}>
                {data.phone_number}
              </Descriptions.Item>
              {data.province.name && (
                <Descriptions.Item label="Khu vực giao hàng">
                  {data.province.name}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Địa chỉ giao hàng" span={5}>
                {data.address}
              </Descriptions.Item>
              <Descriptions.Item label="Ghi chú">{data.note}</Descriptions.Item>
              <Descriptions.Item label="Trạng thái">
                {statusString(data.status)}
              </Descriptions.Item>
              {data.status === FLOWER_DELIVERY_STATUS.REJECTED && (
                <Descriptions.Item label="Lý do:">
                  {data.reject_note}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Wapper>

          <Wapper padding>
            <Descriptions title="Thông tin điện hoa">
              <Descriptions.Item label="Ảnh" span={5}>
                {data.images?.map((item: any, index: number) => (
                  <div className="type-product">
                    <Image
                      key={index}
                      className="type-image"
                      src={item.image_url}
                    />
                  </div>
                ))}
              </Descriptions.Item>
            </Descriptions>
          </Wapper>
        </div>
      )}
    </>
  )
}

export default memo(FlowerDeliveryDetail)
