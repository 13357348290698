import { PageHeader, Spin } from 'antd'
import Container from 'container/Container'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ExpandContent from '../components/ExpandContent'
import { getCancelOrderDetail } from '../service'
import { ADMIN_ROUTER_PATH } from 'common/config'

const OrderCancelReportDetail = () => {
    const location: any = useLocation()
    const history = useHistory()
    const targetId = location?.state?.id
    const [reportDetail, setReportDetail] = React.useState<any>()
    const [loading, setLoading] = React.useState<boolean>(false)

    const getDetail = async () => {
        try {
            setLoading(true)
            const res = await getCancelOrderDetail(targetId)
            if (res?.status) {
                setReportDetail(res?.data)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getDetail()
    }, [])

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        onBack={() => history.push(ADMIN_ROUTER_PATH.ORDER_CANCEL_REPORT)}
                        style={{ borderRadius: 8 }}
                        title={`Chi tiết báo cáo huỷ hàng - ${reportDetail?.code}`}
                    ></PageHeader>
                }
                contentComponent={[<ExpandContent item={reportDetail} />]}
            />
        </Spin>
    )
}

export default OrderCancelReportDetail
