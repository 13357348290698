import { Button, Col, DatePicker, PageHeader, Row, Select } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { useState, memo } from 'react'
import styled from 'styled-components'
import { FLOWER_DELIVERY_STATUS, TEXT_FLOWER_ACTIVE } from 'utils/constants'
const { Option } = Select
const { RangePicker } = DatePicker

interface IWapperHeaderStyle {
  padding?: boolean
}

export const WapperHeader = styled.div<IWapperHeaderStyle>`
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  padding: ${props => (props.padding ? '15px 10px' : null)};
`

interface IProps {
  onSubmitSearch: (value: string) => void
  onSubmitStatus: (status: number) => void
  onSubmitDateTime: (dateTime: Array<string>) => void
}

function Header(props: IProps) {
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [currentSelect, setcurrentSelect] = useState<string>()

  const handleSelectTime = (selectTime: any, timeString: Array<string>) => {
    props.onSubmitDateTime(timeString)
  }
  const handleSelectStatus = (status: any) => {
    props.onSubmitStatus(status)
  }

  return (
    <>
      <WapperHeader>
        <PageHeader
          title="Danh sách yêu cầu điện hoa"
          extra={[
            <Row gutter={[16, 16]} justify="start">
              <Col>
                <TypingAutoSearch
                  placeholder="Nhập tên khách hàng"
                  onSearchSubmit={(value: string) =>
                    props.onSubmitSearch(value)
                  }
                  isSearchLoading={isLoading}
                />
              </Col>
              <Col>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Trạng thái"
                  allowClear
                  value={currentSelect}
                  onChange={handleSelectStatus}
                >
                  <Option value={FLOWER_DELIVERY_STATUS.REQUESTED}>
                    {TEXT_FLOWER_ACTIVE.FLOWERWAIT}
                  </Option>
                  <Option value={FLOWER_DELIVERY_STATUS.APPROVED}>
                    {TEXT_FLOWER_ACTIVE.FLOWERCONFIRML}
                  </Option>
                  <Option value={FLOWER_DELIVERY_STATUS.REJECTED}>
                    {TEXT_FLOWER_ACTIVE.FLOWERCANCEL}
                  </Option>
                </Select>
              </Col>
              <Col>
                <RangePicker onChange={handleSelectTime} />
              </Col>
            </Row>,
          ]}
        />
      </WapperHeader>
    </>
  )
}

export default memo(Header)
