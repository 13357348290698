import { ApiClient } from 'services/ApiService'

export const getDataListMessage = (payload: any) => ApiClient.get('/message/topic', payload)

export const getDataMessageDetail = (topic_message_id: string) => ApiClient.get(`/message/${topic_message_id}/topic`)

export const requestGetListMessage = (topic_message_id: string, payload: any) =>
    ApiClient.get(`/message/${topic_message_id}`, payload)

export const requestSendMessage = (topic_message_id: string, payload: any) =>
    ApiClient.post(`/message/${topic_message_id}/message`, payload)

export const requestSendMessageToNewUser = (payload: any) => ApiClient.post(`/message/new_topic`, payload)

export const requestReadAMessage = (topic_message_id: number, message_id: number) =>
    ApiClient.put(`message/${topic_message_id}/read/${message_id}/message`)

export const requestReadAllMessage = (topic_message_id: number) => ApiClient.put(`/message/${topic_message_id}/read`)

export const requestCountMessageNotRead = () => ApiClient.get(`/message/message_not_read`)
/**
   {
    "user_id": 0,
    "shop_id": 0,
    "topic"?: "",
    "message_media"?: image or video formdata,
    "type_message_media"?: 0 | 1
  }
   */
