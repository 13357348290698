import PrivateRoute from './PrivateRoute'
import screenRouter from './routerType'

export default function Nav() {
  return (
    <>
      {screenRouter.map((val, index) => {
        if (val.exact === false) {
          return (
            <PrivateRoute
              key={index}
              path={`${val.path}`}
              component={val.component}
            />
          )
        }
        return (
          <PrivateRoute
            key={index}
            path={`${val.path}`}
            component={val.component}
            exact
          />
        )
      })}
    </>
  )
}
