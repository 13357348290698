import { PageHeader, Select, DatePicker, Row, Col, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useState, useEffect, memo } from 'react'
import styled from 'styled-components'
import { COIN } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { get_ss_hh_dd_mm } from 'utils/TimerHelper'
import { getWalletCoin } from '../../CustomerAPI'
import { ICoinHistory, IFormateDataCustomers, IFormatedListCoin, IWalletPayload } from '../../CustomerInterfaces'
const Option = Select
const Text = Typography

const { RangePicker } = DatePicker
const TextStyle = styled.p`
    color: red;
    margin: 17px 0 5px 10px;
    font-weight: 600;
    font-size: 15px;
`

const StyledBalance = styled.p`
    color: #0066ff;
    margin: 17px 0 5px 10px;
    font-weight: 600;
    font-size: 15px;
`
const StyledValueChange = styled.p<{ isAdd: boolean }>`
    color: ${p => (p.isAdd ? 'blue' : 'red')};
    margin: 17px 0 5px 10px;
    font-weight: 300;
    font-size: 15px;
`

// const Icon = styled.Image<Props>`
//   width: ${p => p.width};
//   height: ${p => p.height};
// `;

interface IProps {
    dataCustomer: IFormateDataCustomers
    callback: boolean
}

function HistoryCoin(props: IProps) {
    const [params, setparams] = useState<IWalletPayload>({
        user_id: props.dataCustomer.id,
        page: '',
        limit: '12',
        type: undefined,
        create_from: undefined,
        create_to: undefined,
    })
    const [paging, setPaging] = useState({
        total: 0,
        current: 1,
        pageSize: 0,
    })
    const [listCoin, setListCoin] = useState<IFormatedListCoin[]>([])
    const [coin, setCoin] = useState<ICoinHistory>()

    const columns: ColumnsType<IFormatedListCoin> = [
        {
            title: 'Nội dung',
            dataIndex: 'note',
            render: content => {
                return <Text>{content ? content : '...'}</Text>
            },
            align: 'center',
        },
        {
            title: 'Thời gian giao dịch',
            dataIndex: 'created_at',
            render: created_at => {
                return <Text>{get_ss_hh_dd_mm(created_at)}</Text>
            },
            align: 'center',
        },

        {
            title: 'Số tiền',
            dataIndex: 'value',
            render: (value: number, record: any) => {
                if (record.type !== 'add') {
                    value = -Math.abs(value)
                }
                return <StyledValueChange isAdd={record.type === 'add'}>{formatPrice(value)}</StyledValueChange>
            },
            align: 'center',
        },
        {
            title: 'Số dư cuối',
            dataIndex: 'current_balance',
            render: (value: number) => {
                return <StyledBalance>{formatPrice(value)}</StyledBalance>
            },
        },
    ]

    const getData = async (params: IWalletPayload) => {
        try {
            const res = await getWalletCoin(params)
            if (res.data) {
                const dataCoin = res.data?.list_coin_change.map((item, index) => {
                    return {
                        ...item,
                        index: index + 1,
                        key: index,
                    }
                })
                if (res.paging) {
                    setPaging({
                        current: res.paging?.page,
                        pageSize: res.paging?.limit,
                        total: res.paging?.totalItemCount,
                    })
                }
                setListCoin(dataCoin)
                setCoin(res.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getData(params)
    }, [params, props.callback])

    return (
        <>
            <PageHeader
                title="Lịch sử Xu"
                extra={[
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Select
                                allowClear
                                placeholder="Tác vụ"
                                style={{ width: '200px' }}
                                onChange={(type: string) => {
                                    setparams({
                                        ...params,
                                        type: type,
                                        page: 1,
                                    })
                                }}
                            >
                                <Option value={COIN.ADDCOIN}>Nạp vào</Option>
                                <Option value={COIN.REDUCECOIN}>Trừ đi</Option>
                            </Select>
                        </Col>
                        <Col>
                            <RangePicker
                                onChange={(selectTime: any, timeString: Array<string>) =>
                                    setparams({
                                        ...params,
                                        create_from: timeString[0],
                                        create_to: timeString[1],
                                    })
                                }
                            />
                        </Col>
                    </Row>,
                ]}
            />
            <TextStyle>
                Tổng xu hiện tại:
                {formatPrice(coin?.total_coin) ? ` ${formatPrice(coin?.total_coin)}` : ' 0'} xu
            </TextStyle>
            <Table
                size="small"
                bordered
                columns={columns}
                dataSource={listCoin}
                pagination={{
                    ...paging,
                    showSizeChanger: false,
                    onChange: async page => {
                        setparams({ ...params, page: page.toString() })
                    },
                }}
            />
        </>
    )
}

export default memo(HistoryCoin)
