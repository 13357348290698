import { Col, DatePicker, Input, Row, Select } from 'antd'
import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import moment from 'moment'
import { toNonAccentVietnamese } from 'utils/Configs'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

interface IFilter {
    setArea: React.Dispatch<any>
    setDepartment: React.Dispatch<any>
    setFromDate: React.Dispatch<any>
    setToDate: React.Dispatch<any>
    setSearch: React.Dispatch<any>
    listKiotviet?: any[]
    search: any
}

const Filter = (props: IFilter) => {
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isAccountant = userInfor?.admin?.role === ADMIN_ROLE.ACCOUNTANT
    const { setDepartment, setArea, setFromDate, setToDate, listKiotviet, setSearch, search } = props
    const [isSelectAllArea, setIsSelectAllArea] = React.useState<boolean>(false)
    const [isSelectAllDepartment, setIsSelectAllDepartment] = React.useState<boolean>(false)
    return (
        <CustomRow gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                <Input.Search
                    allowClear
                    style={{ marginBottom: 12 }}
                    className="search"
                    placeholder="Tên người tạo, nội dung chính sách"
                    addonAfter={<Icon type="close-circle-o" />}
                    value={search}
                    onChange={(e: any) => {
                        setSearch(e.target.value)
                    }}
                />
            </Col>

            {!isAccountant && (
                <>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                        <Select
                            allowClear
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Bộ phận áp dụng"
                            showArrow
                            showSearch
                            onChange={(value: string | undefined) => setDepartment(value)}
                            onSelect={(value: any) => {
                                if (value === -1) {
                                    setIsSelectAllDepartment(true)
                                }
                            }}
                            onDeselect={(value: any) => {
                                if (value === -1) {
                                    setIsSelectAllDepartment(false)
                                }
                            }}
                        >
                            <Select.Option value={-1}>Tất cả</Select.Option>
                            <Select.Option disabled={isSelectAllDepartment} value={2}>
                                Shipper
                            </Select.Option>
                            <Select.Option disabled={isSelectAllDepartment} value={3}>
                                Nhân viên kho
                            </Select.Option>
                            <Select.Option disabled={isSelectAllDepartment} value={4}>
                                Nhân viên kinh doanh
                            </Select.Option>
                            <Select.Option disabled={isSelectAllDepartment} value={6}>
                                Kế toán
                            </Select.Option>
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                        <Select
                            className="status_block"
                            allowClear
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Chọn khu vực"
                            onChange={(value: number | undefined) => setArea(value)}
                            showArrow
                            showSearch
                            filterOption={(input, option: any) => {
                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                return nonAccent.includes(nonAccentInput)
                            }}
                            onSelect={(value: any) => {
                                if (value === -1) {
                                    setIsSelectAllArea(true)
                                }
                            }}
                            onDeselect={(value: any) => {
                                if (value === -1) {
                                    setIsSelectAllArea(false)
                                }
                            }}
                        >
                            <Select.Option value={-1}>Tất cả</Select.Option>
                            {listKiotviet?.map((item: any) => (
                                <Select.Option key={item?.id} value={item?.value} disabled={isSelectAllArea}>
                                    {item?.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </>
            )}
            <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    placeholder={['Từ ngày', 'Đến ngày']}
                    className="rangerpicker-order"
                    format={'DD-MM-YYYY'}
                    onChange={async (value, dateString) => {
                        setFromDate(dateString[0].split('-').reverse().join('-'))
                        setToDate(dateString[1].split('-').reverse().join('-'))
                    }}
                />
            </Col>
        </CustomRow>
    )
}

const CustomRow = styled(Row)`
    .status_block {
        width: 100%;
        margin-bottom: 14px;
    }

    .role_block {
        width: 100%;
    }
`

export default Filter
