import { Button, DatePicker, Input, PageHeader, Select, Col, Row } from 'antd'
import ButtonFixed from 'common/components/ButtonFixed'
import styled from 'styled-components'
import { IS_GIFT_ACTIVE } from 'utils/constants'

const { Search } = Input
const { RangePicker } = DatePicker
const { Option } = Select

const WapperHeader = styled.div`
    background-color: white;
    margin: 10px;
`

type Props = {
    onShowAddGift: any
    onSearchSubmit: (searchKey: string) => void
    onStatusSubmit: (status: number) => void
    onTypeGift: (type_id: number) => void
    onDateSubmit: (from_date: string, to_date: string) => void
    isSearchLoading: boolean
}
function HeaderListGift({
    onShowAddGift,
    onSearchSubmit,
    onStatusSubmit,
    onTypeGift,
    onDateSubmit,
    isSearchLoading,
}: Props) {
    return (
        <>
            <WapperHeader>
                <PageHeader
                    title="Quà tặng "
                    extra={[
                        <Row gutter={[16, 16]} justify="end">
                            <Col>
                                <Search
                                    loading={isSearchLoading}
                                    placeholder="Tìm kiếm tên quà tặng"
                                    onChange={(e: any) => {
                                        onSearchSubmit(e.target.value)
                                    }}
                                />
                            </Col>
                            <Col>
                                <Select
                                    allowClear
                                    className="select-type_account"
                                    placeholder="Trạng thái"
                                    style={{ minWidth: '150px' }}
                                    onChange={(value: number) => {
                                        onStatusSubmit(value)
                                    }}
                                >
                                    <Option value={IS_GIFT_ACTIVE.ACTIVE}>Đang hoạt động</Option>
                                    <Option value={IS_GIFT_ACTIVE.INACTIVE}>Ngừng hoạt động</Option>
                                </Select>
                            </Col>
                            <Col>
                                <RangePicker
                                    placeholder={['Từ ngày', 'Đến ngày']}
                                    onChange={(value, dateString) => {
                                        onDateSubmit(dateString[0], dateString[1])
                                    }}
                                />
                            </Col>
                            <Col>
                                <ButtonFixed setIsCreate={onShowAddGift} text="Thêm mới" type="add" />
                            </Col>
                        </Row>,
                    ]}
                />
            </WapperHeader>
        </>
    )
}
export default HeaderListGift
