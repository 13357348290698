import { Col, Row, Select, DatePicker, Space } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import moment from 'moment'
import { memo, useEffect, useState } from 'react'
import { REGION } from 'utils/constants'
import { getProvinces } from '../CustomerAPI'
import { IDataCustomers, IAddress } from '../CustomerInterfaces'
const { Option } = Select
const { RangePicker } = DatePicker

interface IProps {
    onSubmitKeyword: (keyword: string) => void
    onSubmidCity: (city: string) => void
    onSubmitTime: (time: Array<string>) => void
    defaultSearch?: string
}

function Fillter(props: IProps) {
    const [listProvince, setlistProvince] = useState<IAddress[]>([])
    const disabledDate: any['disabledDate'] = (current: any) => {
        return current && current > moment().endOf('day')
    }

    const getDataProvinces = async () => {
        try {
            const res = await getProvinces()
            if (res.data) {
                setlistProvince(res.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getDataProvinces()
    }, [])

    return (
        <>
            <Col>
                <TypingAutoSearch
                    defaultSearch={props.defaultSearch}
                    placeholder="Tên hoặc Số điện thoại"
                    onSearchSubmit={(value: string) => {
                        props.onSubmitKeyword(value)
                    }}
                />
            </Col>
            <Col>
                <Select
                    style={{ width: '100%' }}
                    placeholder="Tỉnh/Thành phố"
                    allowClear
                    onChange={(value: string) => props.onSubmidCity(value || '')}
                >
                    {listProvince.map((province, index) => (
                        <Option value={province.id} key={index}>
                            {province.name}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col>
                <Space direction="vertical" size={12}>
                    <RangePicker
                        disabledDate={disabledDate}
                        onChange={(selectTime, timeString) => props.onSubmitTime(timeString)}
                    />
                </Space>
            </Col>
        </>
    )
}

export default memo(Fillter)
