import { BellOutlined, LockOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Badge, Button, Empty, Menu, Popover, Typography } from 'antd'
import imageLogo from 'assets/images/img_logo.png'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useHeaderAdmin } from 'common/hooks/useHeaderAdmin'
import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DF_NOTIFICATION } from 'utils/constants'
import history from 'utils/history'
import '../styles/Notification.css'
import { readNotification, requestMarkAllAsRead } from './NotificationApi'
import { useLocation } from 'react-router-dom'

const { SubMenu } = Menu

export default function HeaderAdmin({ handleClick }: any) {
    const { authState, listNotification, countNotification, getNotification } = useHeaderAdmin()

    const [isShowNotify, setShowNotify] = useState<boolean>(false)
    const location = useLocation()

    const markAllAsRead = async () => {
        try {
            await requestMarkAllAsRead()
            getNotification(false)
            setShowNotify(false)
        } catch (error) {}
    }

    const handleNotificationClick = async (notifyItem: any) => {
        let pathname: string = ''
        let state: any = {}

        switch (notifyItem.df_notification_id) {
            case DF_NOTIFICATION.FLOWER_DELIVERY:
                pathname = `${ADMIN_ROUTER_PATH.FLOWER_DELIVERY_DETAIL}/${notifyItem?.data?.flower_delivery_id}`
                state = {
                    id: notifyItem?.data?.flower_delivery_id,
                }
                break
            // Công nợ
            case DF_NOTIFICATION?.DEBT:
                pathname = `${ADMIN_ROUTER_PATH.CUSTOMER}`
                state = {
                    id: notifyItem?.data?.id,
                    phone_number: notifyItem?.data?.phone_number,
                }
                break
            // Nhận xét hàng hoá
            case DF_NOTIFICATION?.GOOD_COMMENT:
                pathname = `${ADMIN_ROUTER_PATH.COMMENT_GOOD_DETAIL}`
                state = {
                    id: notifyItem?.data?.id,
                }
                break
            // Báo cáo huỷ hàng
            case DF_NOTIFICATION?.CANCEL_REPORT:
                pathname = `${ADMIN_ROUTER_PATH.ORDER_CANCEL_REPORT_DETAIL}`
                state = {
                    id: notifyItem?.data?.id,
                }
                break

            case DF_NOTIFICATION.ORDER:
                pathname = `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${notifyItem?.data?.order_id}`
                state = {
                    id: notifyItem?.data?.order_id,
                    path: ADMIN_ROUTER_PATH.ORDER,
                }
                break
            case DF_NOTIFICATION?.COMMENT:
                pathname = `${ADMIN_ROUTER_PATH.COMMUNITY_POST}/${notifyItem?.data?.account_id}`
                state = {
                    id: notifyItem?.data?.account_id,
                    path: ADMIN_ROUTER_PATH.COMMUNITY_POST,
                }
                break

            case DF_NOTIFICATION.GIFT_CHANGE_REQUEST:
                pathname = `${ADMIN_ROUTER_PATH.GIFT_CHANGE}`
                state = {}
                break

            default:
                break
        }

        if (pathname)
            if (pathname === location?.pathname) {
                console.log(1111)

                history.replace({
                    pathname,
                    state,
                })
            } else {
                history.push({
                    pathname,
                    state,
                })
            }
    }

    const renderNotify = () => {
        if (listNotification?.length === 0) {
            return <Empty />
        }

        return (
            <ContainerNotiStyled>
                {listNotification.map(notifyItem => (
                    <WrapperNotiStyled
                        onClick={async () => {
                            await readNotification(notifyItem.id)
                            getNotification(false)
                            setShowNotify(false)
                            handleNotificationClick(notifyItem)
                        }}
                    >
                        {notifyItem.is_read ? (
                            <Typography.Text type="secondary">{notifyItem.content}</Typography.Text>
                        ) : (
                            <Typography.Text strong>{notifyItem.content}</Typography.Text>
                        )}
                        <TimeStyled>{moment(notifyItem.created_at).format('HH:mm DD/MM/YYYY')}</TimeStyled>
                        <div
                            style={{
                                height: '1px',
                                backgroundColor: '#dddd',
                                margin: '6px 0',
                            }}
                        />
                    </WrapperNotiStyled>
                ))}
                <Button
                    type="link"
                    onClick={async () => {
                        try {
                            await getNotification(true)
                        } catch (error) {}
                    }}
                >
                    Thông báo cũ hơn
                </Button>
            </ContainerNotiStyled>
        )
    }

    return (
        <>
            <img
                alt=""
                src={imageLogo}
                style={{
                    width: 40,
                    // height: 50,
                    padding: 3,
                    objectFit: 'contain',
                    marginTop: '7px',
                    marginBottom: '7px',
                    marginRight: 20,
                }}
            />
            <Typography.Title children="Quản trị viên" style={{ margin: 0, fontSize: '20px', marginTop: 8 }} />

            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                <Popover
                    placement="topRight"
                    content={renderNotify}
                    title={() => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                textAlign: 'center',
                            }}
                        >
                            <Typography.Text>Thông báo</Typography.Text>
                            <Button
                                type="link"
                                onClick={() => {
                                    markAllAsRead()
                                }}
                            >
                                Đọc tất cả
                            </Button>
                        </div>
                    )}
                    trigger="click"
                    visible={isShowNotify}
                    onVisibleChange={visible => setShowNotify(visible)}
                >
                    <Badge count={countNotification} offset={[-15, 3]}>
                        <BellOutlined
                            style={{
                                textAlign: 'center',
                                fontSize: '22px',
                                justifyContent: 'center',
                                padding: '0 16px',
                            }}
                        />
                    </Badge>
                </Popover>
                <Menu
                    key="MenuHeader"
                    style={{
                        lineHeight: '28px',
                        borderBottom: 0,
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                    }}
                    triggerSubMenuAction="click"
                    onClick={handleClick}
                    mode="horizontal"
                >
                    <SubMenu
                        key="sub15"
                        icon={<UserOutlined />}
                        title={authState?.userInfo?.admin?.full_name ? authState?.userInfo?.admin?.full_name : 'Refer'}
                    >
                        <Menu.Item key={'changePassword'} icon={<LockOutlined />}>
                            Đổi mật khẩu
                        </Menu.Item>
                        <Menu.Item key={'logout'} icon={<LogoutOutlined />}>
                            Đăng xuất
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </div>
        </>
    )
}

const ContainerNotiStyled = styled.div`
    max-width: 400px;
    max-height: 500px;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 0 10px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
        /* border: 8px solid transparent; */
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
    }

    &:hover {
        overflow-y: overlay;
    }
`

const WrapperNotiStyled = styled.div`
    cursor: pointer;
    .ant-typography strong {
        font-weight: 700;
        color: black;
    }
    &:hover .ant-typography strong {
        color: #1890ff !important;
    }
`

const TimeStyled = styled.div`
    font-size: 12px;
    font-weight: bold;
    margin-top: 6px;
    color: #777;
`
