import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SESSION_ID } from 'common/config'
import Cookie from 'js-cookie'
import { getListNotification, getCountNotification } from '../components/header/NotificationApi'
import io from 'socket.io-client'
import { WebSocket } from 'services/WebSocket'
import reactotron from 'ReactotronConfig'
import { message, notification } from 'antd'
import { notificationSuccess } from 'utils/notification'
import { SOCKET_EVENT } from 'utils/socketConstants'
import { getMessageNotReadAction } from 'features/admin/chat/slices/MessageNotReadSlice'
import { downloadFile } from 'utils/ruleForm'
import { environment } from 'config'
import { setIsExportingExcel } from 'features/admin/order-clone/OrderSlice'
import LocalStorage from 'utils/LocalStorage'

export const useHeaderAdmin = () => {
    const authState = useSelector((state: any) => state.authReducer)
    const [listNotification, setListNotification] = useState<Array<any>>([])
    const [countNotification, setCountNotification] = useState(0)
    const [loadMoreNotification, setLoadMoreNotification] = useState(false)
    const [pageNotiMore, setPageNotiMore] = useState<number>(0)
    const cookie = Cookie.get(SESSION_ID)
    // const [page, setPage] = useState(1)
    const page = useRef<number>(1)
    const dispatch = useDispatch()

    useEffect(() => {
        initSocketEvents()
        getNotification(false)
    }, [])

    const initSocketEvents = () => {
        const cookie = Cookie.get(SESSION_ID)
        if (!WebSocket.socketClient) {
            WebSocket.init(cookie!)
        }

        WebSocket.socketClient.onAny((event: any, data: any) => {
            switch (event) {
                case SOCKET_EVENT.FLOWER_DELIVERY:
                    notificationSuccess('Có yêu cầu điện hoa mới')
                    getNotification(false)
                    break
                case SOCKET_EVENT.NOTIFICATION:
                    notificationSuccess(data.data.content)
                    getNotification(false)
                    break
                case SOCKET_EVENT.REPORT:
                    message.success('Xuất file excel thành công!')
                    dispatch(setIsExportingExcel(false))
                    LocalStorage.setIsExportingExcel('2')
                    getNotification(false)
                    window.open(data?.data, '_blank')
                    break
                case SOCKET_EVENT.ASYNC:
                    // notificationSuccess('Đang cập nhật vui lòng chờ')
                    // getNotification(false)
                    break
                default:
                    break
            }

            if (event.includes('shop_id')) {
                const kiotvietID = Number(event.slice(8, event.length))
                //test thôi, sau viết lại
                dispatch(getMessageNotReadAction())
            }
        })
    }

    const getNotification = async (isLoadMore: boolean) => {
        if (isLoadMore) {
            page.current++
        } else {
            page.current = 1
        }
        try {
            const res = await getListNotification({ page: page.current })
            setPageNotiMore(Math.ceil(res.paging?.totalItemCount / res.paging?.limit))
            if (page.current > 1) {
                const new_list = listNotification.concat(res.data)
                setListNotification(new_list)
            } else {
                setListNotification(res.data)
            }

            const res_data = await getCountNotification()
            setCountNotification(res_data.data.count)
        } catch (error) {}
    }

    return {
        authState,
        listNotification,
        countNotification,
        loadMoreNotification,
        cookie,
        setLoadMoreNotification,
        getNotification,
        pageNotiMore,
    }
}
