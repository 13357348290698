import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { PageHeader, Radio, Row, Select, Spin, Table, message } from 'antd'
import ButtonSave from 'common/components/Button/ButtonSave'
import Container from 'container/Container'
import React from 'react'
import Filter from '../components/Filter'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { toNonAccentVietnamese } from 'utils/Configs'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import styled from 'styled-components'
import { formatPrice } from 'utils/ruleForm'
import { useDebounce } from 'common/hooks/Debounce'
import { momentToStringDate } from 'utils/TimerHelper'
import StaffInfo from '../components/StaffInfo'
import AddEditInventoryStaffModal from '../components/AddEditInventoryStaff'
import ChangePasswordModal from 'features/admin/shipper/components/ChangePasswordModal'
import {
    addInventoryStaff,
    changePassword,
    changeStatus,
    deleteStaff,
    exportExcel,
    exportExcelTab,
    getInventoryStaffList,
    getStaffGeneralInfo,
    getWrapOrderReport,
    updateInventoryStaff,
} from '../service'
import { getListBranchs } from 'features/admin/report/branch_report/services'
import { getProvinces } from 'features/admin/customer-clone/CustomerAPI'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

const InventoryStaff = () => {
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isIventoryManager = userInfor?.admin?.role === ADMIN_ROLE.INVERTORY_STAFF
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN

    const [branches, setBranches] = React.useState<any[]>([])
    const [listInventoryStaff, setListInventoryStaff] = React.useState<any[]>([])
    const [selectedBranchId, setSelectedBranchId] = React.useState<any>(
        isIventoryManager ? userInfor?.admin?.branch_id : undefined
    )
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const [isCPModalOpen, setIsCPModalOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [search, setSearch] = React.useState<string>('')
    const [toDate, setToDate] = React.useState<string>('')
    const [fromDate, setFromDate] = React.useState<string>('')
    const [selectedKiotVietId, setSelectedKiotVietId] = React.useState<any>(
        isIventoryManager || isShopAdmin ? userInfor?.admin?.kiotviet_id : ''
    )
    const [listKiotviet, setlistKiotviet] = React.useState<any[]>([])
    const [currentRecord, setCurrentRecord] = React.useState<any>()
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<number[]>([])
    const [staffOpenId, setStaffOpenId] = React.useState<any>()
    const [currentTab, setCurrentTab] = React.useState<string>('1')
    const [staffDetail, setStaffDetail] = React.useState<any>()
    const [provinces, setProvinces] = React.useState<any[]>([])
    const [isAdd, setIsAdd] = React.useState<boolean>()

    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })
    const searchDebounce = useDebounce(search, 300)

    // Detail filter
    const [searchDetail, setSearchDetail] = React.useState<string>('')
    const searchDetailDebounce = useDebounce(searchDetail, 300)
    const [orderStatus, setOrderStatus] = React.useState<string>()
    const [wrapStatus, setWrapStatus] = React.useState<number>()
    const [receiveOrderFromDate, setReceiveOrderFromDate] = React.useState<string>()
    const [receiveOrderToDate, setReceiveOrderToDate] = React.useState<string>()
    const [completeOrderFromDate, setCompleteOrderFromDate] = React.useState<string>()
    const [completeOrderToDate, setCompleteOrderToDate] = React.useState<string>()
    const [pagingDetail, setPagingDetail] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Tên nhân viên kho</b>,
            dataIndex: 'full_name',
        },
        {
            title: <b>Số điện thoại</b>,
            dataIndex: 'phone_number',
        },
        {
            title: <b>Chi nhánh</b>,
            dataIndex: 'branch_name',
        },
        {
            title: <b>Email</b>,
            dataIndex: 'email',
            render: (_: any, record: any) => record?.email || '---',
        },
        {
            title: <b>Địa chỉ</b>,
            dataIndex: 'address',
            render: (_: any, record: any) => {
                return record?.province_id
                    ? provinces.find((item: any) => item?.id === Number(record?.province_id))?.name
                    : '---'
            },
        },
        {
            title: <b>Ngày tạo</b>,
            dataIndex: 'date',
            render: (_: any, record: any) => (record?.created_at ? momentToStringDate(record?.created_at) : '---'),
        },
    ]

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
                setlistKiotviet(data)
                // setSelectedKiotVietId(data?.[0]?.value)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getBranchsByKiotviet = async () => {
        try {
            const payload = {
                kiotviet_id: selectedKiotVietId,
            }
            const res = await getListBranchs(payload)
            if (res?.data) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                if (isIventoryManager) {
                    const newData = data?.filter((item: any) => item?.id === userInfor?.admin?.branch_id)
                    setBranches(newData)
                } else {
                    setBranches(data)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getListStaff = async () => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: selectedKiotVietId,
                page: paging?.current,
                limit: paging?.limit,
                search: searchDebounce.trim(),
                create_from: fromDate,
                create_to: toDate,
                branch_id: selectedBranchId,
            }
            const res = await getInventoryStaffList(payload)
            if (res?.status) {
                const listData = res?.data?.map((item: any) => ({ ...item, key: item?.id }))
                setListInventoryStaff(listData)
                const newPaging = {
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getListProvinces = async () => {
        try {
            const res: any = await getProvinces()
            if (res?.status) {
                setProvinces(res?.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleAddUpdateStaff = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: values?.area,
                province_id: values?.province_id,
                gender: values?.gender,
                date_of_birth: values?.dob?.format('YYYY-MM-DD'),
                email: values?.email,
                phone_number: values?.phone,
                full_name: values?.name,
                branch_id: values.branch_id,
            }
            let res: any
            if (currentRecord) {
                res = await updateInventoryStaff(currentRecord?.id, payload)
            } else {
                res = await addInventoryStaff(payload)
            }
            if (res?.status) {
                message.success(currentRecord ? 'Cập nhật nhân viên kho thành công!' : 'Thêm nhân viên kho thành công!')
                setIsModalOpen(false)
                getListStaff()
                if (currentRecord) {
                    getStaffDetail()
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onDeleteStaff = async () => {
        try {
            setLoading(true)
            const res: any = await deleteStaff(staffOpenId)
            if (res?.status) {
                message.success('Xoá nhân viên kho thành công!')
                getListStaff()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getStaffDetail = async () => {
        try {
            setLoading(true)
            let res: any
            const payload = {
                id: staffOpenId,
                page: pagingDetail.current,
                limit: pagingDetail.pageSize,
                from_date: receiveOrderFromDate,
                to_date: receiveOrderToDate,
                from_complete_date: completeOrderFromDate,
                to_complete_date: completeOrderToDate,
                code: searchDetailDebounce,
                status: orderStatus,
                package_status: wrapStatus,
            }
            if (currentTab === '1') {
                res = await getStaffGeneralInfo(payload)
            } else {
                res = await getWrapOrderReport(payload)
            }
            if (res?.status) {
                setStaffDetail(res?.data)
                if (currentTab === '2') {
                    const newPaging = {
                        ...pagingDetail,
                        current: res?.paging?.page,
                        total: res?.paging?.totalItemCount,
                    }
                    setPagingDetail(newPaging)
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const changeStaffStatus = async () => {
        try {
            setLoading(true)
            const res: any = await changeStatus(staffOpenId)
            if (res?.status) {
                message.success('Thay đổi trạng thái nhân viên thành công!')
                getListStaff()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onExportExcel = async () => {
        try {
            setLoading(true)
            const payload = {
                kiotviet_id: selectedKiotVietId,
                page: paging?.current,
                limit: paging?.pageSize,
                search: searchDebounce.trim(),
                create_from: fromDate,
                create_to: toDate,
                branch_id: selectedBranchId,
            }
            const res: any = await exportExcel(payload)
            if (res?.status) {
                message.success('Xuất file excel thành công!')
                getListStaff()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onExportExcelByTab = async () => {
        try {
            setLoading(true)
            const payload = {
                from_date: receiveOrderFromDate,
                to_date: receiveOrderToDate,
                from_complete_date: completeOrderFromDate,
                to_complete_date: completeOrderToDate,
                code: searchDetailDebounce,
                status: orderStatus,
                package_status: wrapStatus,
            }
            const res: any = await exportExcelTab(staffOpenId, payload)
            if (res?.status) {
                message.success('Xuất file excel thành công!')
                getListStaff()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleChangePassword = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                password: values.password,
            }
            const res: any = await changePassword(staffOpenId, payload)
            if (res?.status) {
                message.success('Thay đổi mật khẩu thành công!')
                setIsCPModalOpen(false)
                getListStaff()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleChangeStock = (idStock: any) => {
        setSelectedKiotVietId(idStock)
        setExpandedRowKeys([])
    }

    React.useEffect(() => {
        getDataKiotviet()
        getListProvinces()
    }, [])

    React.useEffect(() => {
        getListStaff()
    }, [paging.current])

    React.useEffect(() => {
        if (paging.current === 1) {
            getListStaff()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [searchDebounce, fromDate, toDate, selectedKiotVietId, selectedBranchId])

    React.useEffect(() => {
        if (selectedKiotVietId !== '') {
            getBranchsByKiotviet()
        }
    }, [selectedKiotVietId])

    React.useEffect(() => {
        setSearchDetail('')
        setOrderStatus(undefined)
        setWrapStatus(undefined)
        setReceiveOrderFromDate('')
        setReceiveOrderToDate('')
        setCompleteOrderFromDate('')
        setCompleteOrderToDate('')
        setPagingDetail({
            total: 0,
            current: 1,
            pageSize: 12,
        })

        if (pagingDetail.current === 1) {
            getStaffDetail()
        } else {
            setPagingDetail((prev: any) => ({ ...pagingDetail, current: 1 }))
        }
    }, [expandedRowKeys, currentTab])

    React.useEffect(() => {
        getStaffDetail()
    }, [pagingDetail?.current])

    React.useEffect(() => {
        if (pagingDetail.current === 1) {
            getStaffDetail()
        } else {
            setPagingDetail((prev: any) => ({ ...pagingDetail, current: 1 }))
        }
    }, [
        searchDetailDebounce,
        orderStatus,
        wrapStatus,
        receiveOrderFromDate,
        receiveOrderToDate,
        completeOrderFromDate,
        completeOrderToDate,
    ])

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        style={{ borderRadius: 8 }}
                        title="Danh sách Nhân viên kho"
                        extra={
                            !isIventoryManager
                                ? [
                                      <ButtonSave
                                          onClickButton={onExportExcel}
                                          text="Xuất excel"
                                          icon={<VerticalAlignBottomOutlined />}
                                      />,
                                      <ButtonAdd
                                          text="Thêm mới"
                                          background="#47b2eb"
                                          onClickButton={() => {
                                              setIsAdd(true)
                                              setIsModalOpen(true)
                                          }}
                                      />,
                                  ]
                                : [
                                      <ButtonSave
                                          onClickButton={onExportExcel}
                                          text="Xuất excel"
                                          icon={<VerticalAlignBottomOutlined />}
                                      />,
                                  ]
                        }
                    ></PageHeader>
                }
                filterComponent={
                    <Filter search={search} setFromDate={setFromDate} setToDate={setToDate} setSearch={setSearch} />
                }
                contentComponent={[
                    <>
                        {(selectedKiotVietId || selectedKiotVietId?.length === 0) && (
                            <Radio.Group
                                value={selectedKiotVietId}
                                onChange={(e: any) => {
                                    handleChangeStock(e.target.value)
                                    setSelectedBranchId(undefined)
                                }}
                            >
                                <Radio.Button disabled={isIventoryManager || isShopAdmin} value={''}>
                                    Tất cả
                                </Radio.Button>
                                {listKiotviet &&
                                    listKiotviet.map((region, index) => (
                                        <Radio.Button
                                            key={index}
                                            value={region.id}
                                            disabled={
                                                (isIventoryManager && region.id !== userInfor?.admin?.kiotviet_id) ||
                                                (isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id)
                                            }
                                        >
                                            {region.name ? region.name : '--'}
                                        </Radio.Button>
                                    ))}
                            </Radio.Group>
                        )}
                        {isModalOpen && (
                            <AddEditInventoryStaffModal
                                currentRecord={staffDetail}
                                handleOk={handleAddUpdateStaff}
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                listKiotviet={listKiotviet}
                                isAdd={isAdd}
                                loading={loading}
                            />
                        )}
                        {isCPModalOpen && (
                            <ChangePasswordModal
                                visible={isCPModalOpen}
                                handleOk={handleChangePassword}
                                setIsModalOpen={setIsCPModalOpen}
                            />
                        )}
                        <Select
                            placeholder="Chọn chi nhánh"
                            showArrow
                            showSearch
                            allowClear={!isIventoryManager}
                            options={branches}
                            value={selectedBranchId}
                            onChange={(value: number) => setSelectedBranchId(value)}
                            style={{ marginLeft: 20 }}
                            filterOption={(input, option: any) => {
                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                return nonAccent.includes(nonAccentInput)
                            }}
                        />
                        <CustomRow>
                            <p>
                                Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                            </p>
                        </CustomRow>
                        <Table
                            id="top-table"
                            bordered
                            columns={columns}
                            dataSource={listInventoryStaff}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: 'Chưa có bản ghi nào!',
                            }}
                            expandable={{
                                expandedRowRender: record => (
                                    <StaffInfo
                                        record={record}
                                        currentTab={currentTab}
                                        staffDetail={staffDetail}
                                        pagingDetail={pagingDetail}
                                        searchDetail={searchDetail}
                                        orderStatus={orderStatus}
                                        wrapStatus={wrapStatus}
                                        receiveOrderFromDate={receiveOrderFromDate}
                                        receiveOrderToDate={receiveOrderToDate}
                                        completeOrderFromDate={completeOrderFromDate}
                                        completeOrderToDate={completeOrderToDate}
                                        isIventoryManager={isIventoryManager}
                                        provinces={provinces}
                                        setCurrentTab={setCurrentTab}
                                        setIsModalOpen={setIsModalOpen}
                                        setIsCPModalOpen={setIsCPModalOpen}
                                        deleteStaff={onDeleteStaff}
                                        setSearchDetail={setSearchDetail}
                                        setOrderStatus={setOrderStatus}
                                        setWrapStatus={setWrapStatus}
                                        setReceiveOrderFromDate={setReceiveOrderFromDate}
                                        setReceiveOrderToDate={setReceiveOrderToDate}
                                        setCompleteOrderFromDate={setCompleteOrderFromDate}
                                        setCompleteOrderToDate={setCompleteOrderToDate}
                                        setPagingDetail={setPagingDetail}
                                        changeStaffStatus={changeStaffStatus}
                                        setIsAdd={setIsAdd}
                                        onExportExcelByTab={onExportExcelByTab}
                                    />
                                ),
                            }}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(expanded: boolean, record: any) => {
                                let keys = []
                                if (expanded) {
                                    keys.push(record.id)
                                }
                                setExpandedRowKeys(keys)
                                setStaffOpenId(record?.id)
                                setCurrentRecord(record)
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: page => {
                                    setPaging({ ...paging, current: page })
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    )
}

const CustomRow = styled(Row)`
    margin: 28px 0 0 0;

    .text {
        font-size: 12px;
        margin: 0;
    }
`

export default InventoryStaff
