import Icon, { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Col, DatePicker, Input, Row, Select, Table } from 'antd'
import ButtonSave from 'common/components/Button/ButtonSave'
import { getProvinces } from 'features/admin/customer-clone/CustomerAPI'
import React from 'react'
import styled from 'styled-components'
import { renderDeliveryStatus, renderOrderStatus, toNonAccentVietnamese } from 'utils/Configs'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'

interface ICustomerTab {
    setSearchDetail: React.Dispatch<React.SetStateAction<string>>
    setProvinceId: React.Dispatch<any>
    setToDateDetail: React.Dispatch<React.SetStateAction<string>>
    setFromDateDetail: React.Dispatch<React.SetStateAction<string>>
    setPagingDetail: React.Dispatch<any>
    staffDetail: any
    pagingDetail: any
    onExportExcelDetail: () => Promise<void>
}

const CustomerTab = (props: ICustomerTab) => {
    const {
        setSearchDetail,
        setProvinceId,
        setToDateDetail,
        setFromDateDetail,
        setPagingDetail,
        onExportExcelDetail,
        staffDetail,
        pagingDetail,
    } = props
    const [cities, setCities] = React.useState<any[]>([])
    const columns = [
        {
            title: <b>STT</b>,
            width: 100,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) =>
                (pagingDetail.current - 1) * pagingDetail.pageSize + index + 1,
        },
        {
            title: <b>Tên khách hàng</b>,
            dataIndex: 'name',
        },
        {
            title: <b>Số điện thoại</b>,
            dataIndex: 'phone_number',
        },
        {
            title: <b>Tỉnh, thành phố</b>,
            dataIndex: 'province_id',
            render: (value: any) => (value ? cities?.find((item: any) => item?.id === value)?.name : '---'),
        },
        {
            title: <b>Công nợ</b>,
            dataIndex: 'debt',
        },
        {
            title: <b>Xu</b>,
            dataIndex: 'coin',
        },
        {
            title: <b>Doanh số trên đơn hàng</b>,
            dataIndex: 'total_revenue',
            render: (value: any) => (value ? formatPrice(Number(value)) : '---'),
        },
        {
            title: <b>Doanh số thực tế</b>,
            dataIndex: 'real_revenue',
            render: (value: any) => (value ? formatPrice(Number(value)) : '---'),
        },
        {
            title: <b>Ngày tạo</b>,
            dataIndex: 'createdAt',
            render: (value: any) => momentToStringDate(value),
        },
    ]

    const getListProvinces = async () => {
        try {
            const res: any = await getProvinces()
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setCities(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        getListProvinces()
    }, [])

    return (
        <Container>
            <Row>
                <Row gutter={[16, 16]} className="filter_block">
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Input.Search
                            allowClear
                            className="search"
                            // value={searchDetail}
                            placeholder="Tên, số điện thoại"
                            addonAfter={<Icon type="close-circle-o" />}
                            onChange={(e: any) => setSearchDetail(e.target.value)}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Select
                            placeholder="Tỉnh, thành phố"
                            showArrow
                            showSearch
                            allowClear
                            options={cities}
                            style={{ width: '100%' }}
                            filterOption={(input, option: any) => {
                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                return nonAccent.includes(nonAccentInput)
                            }}
                            onChange={(value: any) => setProvinceId(value)}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <DatePicker.RangePicker
                            style={{ width: '100%' }}
                            placeholder={['Từ ngày', 'Đến ngày']}
                            className="rangerpicker-order"
                            format={'DD-MM-YYYY'}
                            // value={toDateDetail ? [moment(fromDateDetail), moment(toDateDetail)] : undefined}
                            onChange={async (value, dateString) => {
                                setFromDateDetail(dateString[0].split('-').reverse().join('-'))
                                setToDateDetail(dateString[1].split('-').reverse().join('-'))
                            }}
                        />
                    </Col>
                </Row>
                <div className="button_block">
                    <ButtonSave
                        onClickButton={onExportExcelDetail}
                        text="Xuất excel"
                        icon={<VerticalAlignBottomOutlined />}
                    />
                </div>
            </Row>
            <Row justify="space-between" style={{ marginTop: 20 }}>
                <p>
                    Kết quả lọc:{' '}
                    <span>
                        <b>{formatPrice(pagingDetail?.total)}</b>
                    </span>
                </p>
            </Row>
            <Table
                bordered
                columns={columns}
                dataSource={staffDetail}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: 'Chưa có bản ghi nào!',
                }}
                pagination={{
                    ...pagingDetail,
                    showSizeChanger: false,
                    onChange: page => {
                        setPagingDetail({ ...pagingDetail, current: page })
                    },
                }}
            />
        </Container>
    )
}

const Container = styled.div`
    flex: 1;

    .filter_block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 3;
    }

    .button_block {
        flex: 1;
        text-align: right;
    }
`

export default CustomerTab
