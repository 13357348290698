import { Button, DatePicker, Form, Input, Modal, Radio, Row, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import ButtonForm from 'common/components/ModalButton'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import { getProvinces } from 'features/admin/customer-clone/CustomerAPI'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FIVE, MOBI, SEVEN, VIETTEL, VINA, toNonAccentVietnamese } from 'utils/Configs'
import { ADMIN_ROLE } from 'utils/constants'

interface IAddEditShipperModal {
    isModalOpen: boolean
    listKiotviet?: IKiotviet[]
    currentRecord: any
    handleOk: (values: any) => void
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddEditShipperModal = (props: IAddEditShipperModal) => {
    const [form] = useForm()
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN
    const { isModalOpen, handleOk, setIsModalOpen, listKiotviet, currentRecord } = props
    const [provinces, setProvinces] = React.useState<any[]>([])

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const newListKiotViet = isShopAdmin
        ? listKiotviet?.filter((item: any) => item?.id === userInfor?.admin?.kiotviet_id)
        : listKiotviet

    const getListProvinces = async () => {
        try {
            const res: any = await getProvinces()
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setProvinces(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        getListProvinces()
    }, [])

    return (
        <Modal
            title={currentRecord ? 'Cập nhật shipper' : 'Thêm mới shipper'}
            visible={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={'60%'}
        >
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={
                    currentRecord
                        ? {
                              name: currentRecord?.full_name,
                              phone: currentRecord?.phone_number,
                              email: currentRecord?.email,
                              dob: currentRecord?.date_of_birth ? moment(currentRecord?.date_of_birth) : undefined,
                              gender: currentRecord?.gender ? currentRecord?.gender : undefined,
                              address: currentRecord?.province_id,
                              deliveryArea: currentRecord?.kiotviet_id,
                          }
                        : { gender: 'man' }
                }
                onFinish={handleOk}
                autoComplete="off"
            >
                <Form.Item
                    label="Họ và tên"
                    name="name"
                    rules={[{ required: true, message: 'Vui lòng nhập họ và tên!' }]}
                >
                    <Input placeholder="Nhập họ và tên" allowClear />
                </Form.Item>

                <Form.Item
                    label="Số điện thoại"
                    name="phone"
                    rules={[
                        { required: true, message: 'Vui lòng nhập số điện thoại!' },
                        {
                            message: 'Số điện thoại không hợp lệ!',
                            validator: (_, value) => {
                                if (!value?.toString()?.trim()) return Promise.resolve()
                                if (
                                    // (!value?.toString()?.match(MOBI) &&
                                    //     !value?.toString()?.match(VIETTEL) &&
                                    //     !value?.toString()?.match(VINA) &&
                                    //     !value?.toString()?.match(SEVEN)) ||
                                    isNaN(Number(value))
                                ) {
                                    return Promise.reject()
                                }
                                return Promise.resolve()
                            },
                        },
                    ]}
                >
                    <Input placeholder="Nhập số điện thoại" allowClear />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: 'Vui lòng nhập email!' },
                        {
                            message: 'Email không hợp đúng định dạng!',
                            validator: (_, value) => {
                                if (!value?.toString()?.trim()) return Promise.resolve()
                                if (!isNaN(Number(value)) || value.length < 12) {
                                    return Promise.reject()
                                }
                                return Promise.resolve()
                            },
                        },
                    ]}
                >
                    <Input placeholder="Nhập email" allowClear />
                </Form.Item>

                <Form.Item
                    label="Khu vực giao hàng"
                    name="deliveryArea"
                    rules={[{ required: true, message: 'Vui lòng chọn khu vực giao hàng!' }]}
                >
                    <Select
                        placeholder="Chọn khu vực giao hàng"
                        showArrow
                        showSearch
                        allowClear
                        filterOption={(input, option: any) => {
                            const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                            const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                            return nonAccent.includes(nonAccentInput)
                        }}
                        options={newListKiotViet}
                    />
                </Form.Item>

                <Form.Item label="Ngày sinh" name="dob">
                    <DatePicker
                        disabledDate={(current: any) => current && current > moment().endOf('day')}
                        format={'DD-MM-YYYY'}
                        style={{ width: '100%' }}
                        placeholder="Chọn ngày sinh"
                    />
                </Form.Item>

                <Form.Item label="Giới tính" name="gender">
                    <Radio.Group>
                        <Radio value={'man'}>Nam</Radio>
                        <Radio value={'female'}>Nữ</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="Địa chỉ" name="address">
                    <Select
                        filterOption={(input, option: any) => {
                            const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                            const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                            return nonAccent.includes(nonAccentInput)
                        }}
                        placeholder="Chọn tỉnh/thành phố"
                        allowClear
                        showArrow
                        showSearch
                        options={provinces}
                    />
                </Form.Item>

                <CustomRow justify="end">
                    <ButtonForm onCancel={handleCancel} text={currentRecord ? 'Cập nhật' : 'Lưu'} />
                </CustomRow>
            </Form>
        </Modal>
    )
}

const CustomRow = styled(Row)`
    margin-top: 60px;

    .cancel_button {
        margin-right: 16px;
    }
`

export default AddEditShipperModal
