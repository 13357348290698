import { DeleteFilled, EditOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, Card, Popconfirm, Switch, Tabs } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { IS_ACTIVE } from 'utils/constants'
import StaffGeneralInfo from './StaffGeneralInfo'
import CustomerTab from './CustomerTab'
import { getProvinces } from 'features/admin/customer-clone/CustomerAPI'

interface IStaffInfo {
    record: any
    staffDetail: any
    onDeleteStaff: any
    changeStaffStatus: any
    pagingDetail: any
    isSaleManager: any
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setIsCPModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setCurrentTab: React.Dispatch<React.SetStateAction<string>>
    setSearchDetail: React.Dispatch<React.SetStateAction<string>>
    setProvinceId: React.Dispatch<any>
    setToDateDetail: React.Dispatch<React.SetStateAction<string>>
    setFromDateDetail: React.Dispatch<React.SetStateAction<string>>
    setPagingDetail: React.Dispatch<any>
    setIsAdd: React.Dispatch<any>
    onExportExcelDetail: () => Promise<void>
}

const StaffInfo = (props: IStaffInfo) => {
    const {
        record,
        setIsModalOpen,
        setIsCPModalOpen,
        setCurrentTab,
        staffDetail,
        onDeleteStaff,
        changeStaffStatus,
        setSearchDetail,
        setProvinceId,
        setToDateDetail,
        setFromDateDetail,
        setPagingDetail,
        onExportExcelDetail,
        isSaleManager,
        pagingDetail,
        setIsAdd,
    } = props
    const [provinces, setProvinces] = React.useState<any[]>([])

    const getListProvinces = async () => {
        try {
            const res: any = await getProvinces()
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setProvinces(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        getListProvinces()
    }, [])

    return (
        <Card
            actions={
                !isSaleManager
                    ? [
                          <Button
                              onClick={() => {
                                  setIsModalOpen(true)
                                  setIsAdd(false)
                              }}
                              type="text"
                              size="large"
                              style={{ color: 'green' }}
                              icon={<EditOutlined />}
                              children={'Chỉnh sửa'}
                          />,
                          <SwitchBlock>
                              <Switch checked={record.status == IS_ACTIVE.ACTIVE} onChange={changeStaffStatus} />
                              {record.status ? (
                                  <p style={{ color: 'green', margin: 0, marginLeft: 10 }}>Đang hoạt động</p>
                              ) : (
                                  <p style={{ color: 'red', margin: 0, marginLeft: 10 }}>Ngừng hoạt động</p>
                              )}
                          </SwitchBlock>,
                          <Button
                              type="text"
                              size="large"
                              style={{ color: '#e0a561' }}
                              icon={<RedoOutlined />}
                              children={'Đổi mật khẩu'}
                              onClick={() => setIsCPModalOpen(true)}
                          />,
                          <Popconfirm
                              title={'Bạn có chắc chắn muốn XOÁ nhân viên kinh doanh này?'}
                              onConfirm={onDeleteStaff}
                              okText={'Xoá'}
                              cancelText={'Đóng'}
                          >
                              <Button
                                  type="text"
                                  size="large"
                                  style={{ color: '#cc0000' }}
                                  icon={<DeleteFilled />}
                                  children={'Xoá'}
                              />
                          </Popconfirm>,
                      ]
                    : []
            }
            style={{ width: '100%', backgroundColor: '#f6f9ff' }}
        >
            <Tabs style={{ backgroundColor: '#f6f9ff' }} onChange={(value: string) => setCurrentTab(value)}>
                <CustomTabPane tab={<span style={{ margin: 10 }}>Thông tin chung</span>} key="1">
                    {typeof staffDetail === 'object' && (
                        <StaffGeneralInfo staffDetail={staffDetail} provinces={provinces} />
                    )}
                </CustomTabPane>
                <CustomTabPane tab={<span style={{ margin: 10 }}>Khách hàng</span>} key="2">
                    {staffDetail?.length >= 0 && (
                        <CustomerTab
                            setSearchDetail={setSearchDetail}
                            setProvinceId={setProvinceId}
                            setToDateDetail={setToDateDetail}
                            setFromDateDetail={setFromDateDetail}
                            setPagingDetail={setPagingDetail}
                            staffDetail={staffDetail}
                            pagingDetail={pagingDetail}
                            onExportExcelDetail={onExportExcelDetail}
                        />
                    )}
                </CustomTabPane>
            </Tabs>
        </Card>
    )
}

const CustomTabPane = styled(Tabs.TabPane)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const SwitchBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export default StaffInfo
