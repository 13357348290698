import Icon, { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Col, DatePicker, Input, Row, Select, Table } from 'antd'
import ButtonSave from 'common/components/Button/ButtonSave'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'

interface ICODHistory {
    shipperDetail: any
    pagingDetail: any
    searchDetail: string
    fromDateDetail: string | undefined
    toDateDetail: string | undefined
    setFromDateDetail: React.Dispatch<React.SetStateAction<string | undefined>>
    setToDateDetail: React.Dispatch<React.SetStateAction<string | undefined>>
    setPagingDetail: React.Dispatch<any>
    setSearchDetail: React.Dispatch<React.SetStateAction<string>>
    exportExcelByTab: (tabKey: string) => Promise<void>
}

const CODHistory = (props: ICODHistory) => {
    const {
        shipperDetail,
        pagingDetail,
        searchDetail,
        fromDateDetail,
        toDateDetail,
        setFromDateDetail,
        setToDateDetail,
        setPagingDetail,
        setSearchDetail,
        exportExcelByTab,
    } = props

    const columns = [
        {
            title: <b>STT</b>,
            width: 100,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) =>
                (pagingDetail.current - 1) * pagingDetail.pageSize + index + 1,
        },
        {
            title: <b>Mã đơn hàng</b>,
            dataIndex: 'orderCode',
            render: (_: any, record: any) => record?.order_code,
        },
        {
            title: <b>Tổng thu</b>,
            dataIndex: 'money',
            render: (_: number, record: any) => formatPrice(record?.collect_money),
        },
        {
            title: <b>Ngày thu</b>,
            dataIndex: 'date',
            render: (_: any, record: any) => momentToStringDate(record?.created_at),
        },
    ]

    return (
        <Container>
            <Row>
                <Row gutter={[16, 16]} className="filter_block">
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Input.Search
                            allowClear
                            className="search"
                            value={searchDetail}
                            placeholder="Mã đơn hàng"
                            addonAfter={<Icon type="close-circle-o" />}
                            onChange={(e: any) => setSearchDetail(e.target.value)}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <DatePicker.RangePicker
                            style={{ width: '100%' }}
                            placeholder={['Từ ngày', 'Đến ngày']}
                            className="rangerpicker-order"
                            format={'DD-MM-YYYY'}
                            value={fromDateDetail ? [moment(fromDateDetail), moment(toDateDetail)] : undefined}
                            onChange={async (value, dateString) => {
                                setFromDateDetail(dateString[0].split('-').reverse().join('-'))
                                setToDateDetail(dateString[1].split('-').reverse().join('-'))
                            }}
                        />
                    </Col>
                </Row>
                <div className="button_block">
                    <ButtonSave
                        onClickButton={() => exportExcelByTab('4')}
                        text="Xuất excel"
                        icon={<VerticalAlignBottomOutlined />}
                    />
                    ,
                </div>
            </Row>
            <Row style={{ marginTop: 20 }}>
                <p>
                    Tổng thu:{' '}
                    <span>
                        <b>{formatPrice(shipperDetail?.total_collected_moneny || 0)}</b>
                    </span>
                </p>
            </Row>
            <Table
                bordered
                columns={columns}
                dataSource={shipperDetail?.data}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: 'Chưa có bản ghi nào!',
                }}
                pagination={{
                    ...pagingDetail,
                    showSizeChanger: false,
                    onChange: page => {
                        setPagingDetail({ ...pagingDetail, current: page })
                    },
                }}
            />
        </Container>
    )
}

const Container = styled.div`
    flex: 1;

    .filter_block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 2;
    }

    .button_block {
        flex: 1;
        text-align: right;
    }
`

export default CODHistory
