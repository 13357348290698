import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import styled from 'styled-components'

const ContaiAction = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 14px;
`
interface IProps {
  actionType?: string
  onEdit: () => void
  onDelete: () => void
  onDetail?: () => void
}

function Acitons(props: IProps) {
  return (
    <ContaiAction>
      {props.actionType === 'detail' ? (
        <Tooltip title="Chi tiết">
          <InfoCircleOutlined
            onClick={() => {
              props.onDetail && props.onDetail()
            }}
            style={{ color: 'aqua' }}
          />
        </Tooltip>
      ) : null}
      <Tooltip title="Chỉnh sửa">
        <EditOutlined
          onClick={() => props.onEdit()}
          style={{ color: 'green' }}
        />
      </Tooltip>

      <Popconfirm
        title="Bạn chắc chắn muốn xoá?"
        onConfirm={() => props.onDelete()}
        onCancel={() => {}}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <DeleteOutlined style={{ color: 'red' }} />
      </Popconfirm>
    </ContaiAction>
  )
}

export default Acitons
