export const ADMIN_ROUTER_PATH = {
    HOME: '/ad',
    DASH_BOARD: '/ad-dash-board',
    ACCOUNTS: '/ad-accounts',
    SHOP: '/ad-shop',
    SHOP_DETAIL: '/ad-shop-detail',
    CUSTOMER: '/ad-customer',
    FLOWER_DELIVERY_LIST: '/ad-flower-delivery-list',
    FLOWER_DELIVERY_REQUEST: '/ad-flower-delivery-request',
    FLOWER_DELIVERY_DETAIL: '/ad-flower-delivery-detail',
    ORDER: '/ad-order',
    ORDER_DETAIL: '/ad-order-detail',
    LIVE_STREAM: '/ad-livestream',
    LIVE_STREAM_DETAIL: '/ad-livestream-detail',
    SERVICE_PACK: '/ad-service-pack',
    TOPIC_POST: '/ad-post-topic',
    COMMUNITY_POST: '/ad-community-post',
    NEWS: '/ad-news',
    NEWS_ADD: '/ad-news-add',
    NEWS_EDIT: '/ad-news-edit',
    GIFT_CHANGE: '/ad-gift-change',
    GIFT: '/ad-gift',
    SALES_REPORT: '/ad-sales-report',
    LIVE_STREAM_REPORT: '/ad-livestream-report',
    SHOP_REPORT: '/ad-shop-report',
    CONFIG: '/ad-config',
    CONFIG_DETAIL: '/ad-config-detail',
    ADD_POST: '/ad-add-post',
    EDIT_POST: '/ad-edit-post',
    PRODUCT: '/ad-product',
    PRODUCT_DETAIL: '/ad-product-detail',
    EDIT_PRODUCT: '/ad-product-edit',
    CATEGORY_PRODUCT: '/ad-category-product',
    DETAIL_DELT: '/ad-detail-delt',
    CHAT: '/chat',
    AREA_REPORT: '/area_report',
    BRANCH_REPORT: '/branch_report',
    TEMPORARY_ORDER_REPORT: '/temporary_order_report',
    SHIPPER: '/shipper',
    INDIVIDUAL_CUSTOMER: '/individual_customer',
    REFUND_ORDER: '/refund_order',
    ADD_EDIT_REFUND_ORDER: '/add_edit_refund_order',
    INVENTORY_STAFF: '/inventory_staff',
    BUSINESS_STAFF: '/business_staff',
    ORDER_CANCEL_REPORT: '/order_cancel_report',
    ORDER_WRAP_REPORT: '/order_wrap_report',
    COMMENT_GOOD: '/comment_good',
    POLICY: '/policy',
    ADD_EDIT_POLICY: '/add_edit_policy',
    ORDER_CANCEL_REPORT_DETAIL: '/order_cancel_report_detail',
    COMMENT_GOOD_DETAIL: '/comment_good_detail',
}

export const SESSION_ID = 'session_id'
