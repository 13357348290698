import { Button, Form, Input, InputNumber, message, Modal } from 'antd'
import { changeIndexOrder } from './CategoryAPI'
import {
  ICategory,
  IFormatedTableCate,
  IUpdateCatePayload,
} from './CategoryInterfaces'
import './component/css/Category.css'
import styled from 'styled-components'
import { notificationError } from 'utils/notification'
import { memo } from 'react'

const ButtonStyled = styled(Button)`
  margin: 30px 5px 0 5px;
`

interface EditChildrenCateProps {
  dataChildrenCate?: ICategory
  isShowEditChildCate: boolean
  onCancel: () => void
  getChildCate: () => void
  infoCate: IFormatedTableCate
}

function EditChildrenCate(props: EditChildrenCateProps) {
  const { isShowEditChildCate, dataChildrenCate, onCancel, infoCate } = props

  const onFinish = async (value: any) => {
    const valUpdate: IUpdateCatePayload = {
      id: (dataChildrenCate?.id)?.toString(),
      data: {
        order: value.order,
      },
    }
    try {
      await changeIndexOrder(valUpdate)
      props.getChildCate()
      message.success('Lưu thành công')
    } catch (err: any) {
      console.log(err)
      notificationError(err)
    } finally {
      onCancel()
    }
  }

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  }

  return (
    <>
      <Modal
        title="Sửa danh mục con"
        visible={isShowEditChildCate}
        onCancel={onCancel}
        footer={null}
        destroyOnClose
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          initialValues={{
            name_parent_cate: infoCate.name,
            name_children_cate: dataChildrenCate?.name,
            order: dataChildrenCate?.order,
          }}
          autoComplete="off"
        >
          <Form.Item
            label="Danh mục cha"
            name="name_parent_cate"
            rules={[{ required: true, message: 'Danh mục cha là bắt buộc' }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Danh mục con"
            name="name_children_cate"
            rules={[
              { required: true, message: 'Danh mục con là bắt buộc' },
              {
                type: 'string',
                message: 'Nhập tên danh mục',
              },
              {
                whitespace: true,
                message: 'Không được nhập khoảng trắng!',
              },
              {
                max: 50,
                message: 'Vui lòng không nhập quá 50 ký tự!',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="STT hiển thị"
            name="order"
            rules={[{ required: true, message: 'Số thứ tự hiển thị' }]}
          >
            <InputNumber min={1} type='number'/>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <ButtonStyled type="ghost" onClick={onCancel}>
              Huỷ
            </ButtonStyled>
            <ButtonStyled type="primary" htmlType="submit">
              Lưu
            </ButtonStyled>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default memo(EditChildrenCate)
