import Configs from './component/Configs'

function index({location}:any) {
  return (
    <>
      <Configs data={location}/>
    </>
  )
}

export default index
