import { Image, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { TableCutom } from 'common/components/table'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { formatPrice } from 'utils/ruleForm'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { getListLiveStream } from '../LivestreamAPI'
import {
  IListLivePayload,
  IListLiveStream,
  IPaging,
} from '../LivestreamInterfaces'
import Filters from './Filters'
const { Text } = Typography

const WapperContent = styled.div`
  background-color: white;
  padding: 5px;
  margin: 10px;
`

function Livestreams() {
  const history = useHistory()
  const [listLiveStream, setlistLiveStream] = useState<IListLiveStream[]>([])
  const [params, setparams] = useState<IListLivePayload>({
    page: 1,
    limit: 12,
    search: undefined,
    from_date: undefined,
    to_date: undefined,
  })
  const [paging, setPaging] = useState<IPaging>({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const columns: ColumnsType<IListLiveStream> = [
    {
      title: 'STT',
      width: 70,
      dataIndex: 'index',
      render: (text, record, index) => (
        <Text>{(paging.current - 1) * paging.pageSize + index + 1}</Text>
      ),
    },
    {
      title: 'Ảnh',
      dataIndex: 'cover_image_url',
      render: value => <Image preview={false} width={60} src={value} />,
    },
    { title: 'Mô tả', dataIndex: 'title', ellipsis: true },
    {
      title: 'Lượt xem',
      dataIndex: 'count_viewed',
      render: value => <Text>{formatPrice(value)}</Text>,
    },
    {
      title: 'Lượt thích',
      dataIndex: 'count_reaction',
      render: value => <Text>{formatPrice(value)}</Text>,
    },
    {
      title: 'Bình luận',
      dataIndex: 'count_comment',
      render: value => <Text>{formatPrice(value)}</Text>,
    },
    { title: 'Thời lượng', dataIndex: 'minutes_used_before_livestream' },
    {
      title: 'Ngày livestream',
      dataIndex: 'create_at',
      render: value => <Text>{convertTimeStampToString(value)}</Text>,
    },
  ]

  const getData = async () => {
    try {
      const res = await getListLiveStream(params)
      if (res.data) {
        setlistLiveStream(res.data)
      }
      setPaging({
        current: res.paging.page,
        pageSize: res.paging.limit,
        total: res.paging.totalItemCount,
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
  }, [params])

  return (
    <>
      <WapperContent>
        <Filters
          searchKey={(search: string) => setparams({ ...params, search })}
          dateTime={(timeString: Array<string>) =>
            setparams({
              ...params,
              from_date: timeString[0] || undefined,
              to_date: timeString[1] || undefined,
            })
          }
        />
      </WapperContent>
      <WapperContent>
        <TableCutom
          data={listLiveStream}
          columns={columns}
          clickRow={(record: IListLiveStream) => {
            history.push({
              pathname: `${ADMIN_ROUTER_PATH.LIVE_STREAM_DETAIL}/${record.id}`,
              state: {
                paging,
                record,
              },
            })
          }}
          paging={paging}
          changePaging={page => setparams({ ...params, page })}
        />
      </WapperContent>
    </>
  )
}

export default Livestreams
