import { ApiClient } from 'services/ApiService'

// Theo chi nhánh
export const getReportList = (payload: any) => ApiClient.get(`/admin/report/branch`, payload)
export const getListBranchs = (payload: any) => ApiClient.get(`/admin/branch`, payload)
export const getListCategories = (payload: any) => ApiClient.get('/admin/product_category', payload)
export const exportExcelByBranch = (payload: any) => ApiClient.get(`/admin/report/branch/exportToExcel`, payload)
export const getCountDataByBranch = (payload: any) => ApiClient.get(`/admin/report/branch_count`, payload)

// Theo khu vực
export const getReportListByArea = (payload: any) => ApiClient.get(`/admin/report/kiotviet`, payload)
export const exportExcelByArea = (payload: any) => ApiClient.get(`/admin/report/kiotviet/exportToExcel`, payload)
export const getCountData = (payload: any) => ApiClient.get(`/admin/report/kiotviet_count`, payload)

// Nhập chợ
export const getReportWaitConfirmation = (payload: any) => ApiClient.get(`/admin/report/waitConfirmationOrder`, payload)
export const exportExcelByOrders = (payload: any) =>
    ApiClient.get(`/admin/report/waitConfirmationOrder/exportToExcel`, payload)
export const getCountDataByOrders = (payload: any) =>
    ApiClient.get(`/admin/report/waitConfirmationOrder_count`, payload)
