import { Col, Row, Select } from 'antd'
import './css/Header.css'
import ButtonFixed from 'common/components/ButtonFixed'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { STATUS } from 'utils/constants'

const { Option } = Select

type Props = {
  CreateShop: any
  onSearchSubmit: (key: string) => void
  onStatusSubmit: (key: string) => void
  isSearchLoading: boolean
}

export default function Header({
  CreateShop,
  onSearchSubmit,
  onStatusSubmit,
  isSearchLoading,
}: Props) {
  return (
    <Row gutter={[16, 16]}>
      <Col className="input-search_account">
        <TypingAutoSearch
          onSearchSubmit={(key: string) => {
            onSearchSubmit(key.trim())
          }}
          isSearchLoading={isSearchLoading}
          placeholder="Tên hoặc số điện thoại ..."
        />
      </Col>
      <Col>
        <Select
          allowClear
          className="select-status_shop"
          placeholder="Trạng thái"
          style={{ minWidth: '200px' }}
          onChange={(value: string) => {
            onStatusSubmit(value)
          }}
        >
          <Option value="">Tất cả</Option>
          <Option value={`${STATUS.ACTIVE}`}>Đang hoạt động</Option>
          <Option value={`${STATUS.INACTIVE}`}>Ngừng hoạt động</Option>
        </Select>
      </Col>
      <Col>
        {/* <Button
          style={{
            backgroundColor: '#00abba',
            borderColor: '#00abba',
            color: 'white',
          }}
          onClick={() => {
            CreateShop()
          }}
        >
          <PlusCircleOutlined />
          {R.strings().action_create}
        </Button> */}
        <ButtonFixed setIsCreate={CreateShop} text={'Thêm mới'} type="add" />
      </Col>
    </Row>
  )
}
