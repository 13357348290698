import { message, Tag, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Acitons from 'common/Acitons'
import PageHeader from 'common/components/pageHeader'
import { TableCutom } from 'common/components/table'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useDebounce } from 'common/hooks/Debounce'
import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { IS_ACTIVE, IS_TYPE_NEWS, STATUS } from 'utils/constants'
import { notificationError, notificationSuccess } from 'utils/notification'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { deleteNews, getNewsList } from '../NewsAPI'
import { IParams } from '../NewsInterface'
import Filters from './Filters'

const { Text } = Typography

const WapperStyled = styled.div`
  background-color: white;
  padding: 10px;
  margin: 0px 10px 10px 10px;
`

function NewsList() {
  const history = useHistory()

  const [newsList, setNewsList] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([])
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })
  const [isTyping, setIsTyping] = useState(false)
  const timeOut = useRef<any>(null)
  const [params, setParams] = useState<IParams>({
    search: '',
    status: undefined,
    page: 1,
    limit: 24,
    from_date: '',
    to_date: '',
    df_type_news_id: undefined,
    is_banner:undefined,
    is_home:undefined,
  })
  const debounced = useDebounce(params, 300)
  useEffect(() => {
    getData()
  }, [debounced])
  const getData = async () => {
    setIsLoading(true)
    try {
      setCurrentSelected({ id: -1 })
      const res = await getNewsList(params)
      const data_list = res.data.map((item: any) => {
        return { ...item, key: item.id }
      })
      const formattedPaging = {
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      }
      setPaging(formattedPaging)
      setNewsList(data_list)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleEdit = (record: any,getData : any) => {
    history.push({
      pathname: `${ADMIN_ROUTER_PATH.NEWS_EDIT}/${record.id}`,
      state: {
        data: record,
        // getData : getData(),
      },
    })
  }

  const handleDelete = async (record: any) => {
    try {
      await deleteNews({ ids: [record.id] })
      notificationSuccess(`Xóa tin tức thành công`)
      getData()
    } catch (error) {
      notificationError('Đã có lỗi xảy ra, vui lòng thử lại sau')
    }
  }

  const columns: ColumnsType<any> = [
    {
      width: 100,
      title: 'STT',
      dataIndex: 'id',
      render: (text: any, record: any, index: any) => (
        <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </td>
      ),
      ellipsis: true,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      render: title_news => {
        return <Text>{title_news}</Text>
      },
    },
    {
      title: 'Phân loại',
      align: 'center',
      dataIndex: 'df_type_news_id',
      key: 'df_type_news_id',
      render: (value: any) => {
        switch (value) {
          case IS_TYPE_NEWS.INDRAF:
            return <Text>Lưu nháp</Text>
          case IS_TYPE_NEWS.DRAF:
            return <Text>Bài đăng</Text>
        }
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '140px',
      render: (value: any) => {
        switch (value) {
          case IS_ACTIVE.INACTIVE:
            return <Tag color="volcano">Ngừng hoạt động</Tag>
          case IS_ACTIVE.ACTIVE:
            return <Tag color="green">Đang hoạt động</Tag>
        }
      },
    },
    {
      title: 'Ngày tạo',
      align: 'center',
      dataIndex: 'created_at',
      render: created_at => {
        return <Text>{convertTimeStampToString(created_at)}</Text>
      },
    },
    {
      width: 120,
      title: 'Thao tác',
      dataIndex: '',
      render: record => {
        return (
          <Acitons
            onEdit={() => handleEdit(record,getData)}
            onDelete={() => handleDelete(record)}
          />
        )
      },
    },
  ]

  return (
    <>
      <PageHeader title="Quản lý tin tức" />
      <WapperStyled>
        <Filters
          isSearchLoading={isLoading}
          onSearchSubmit={(searchKey: string) => {
            setParams({ ...params, search: searchKey.trim(),page : 1 })
          }}
          onStatusSubmit={(statusKey: number) => {
            setParams({ ...params, status: statusKey, page: 1 })
          }}
          onBannerHome={(is_banner: any,is_home:any,) => {
            setParams({ ...params, is_banner: is_banner,is_home:is_home, page: 1 })
          }}
          onTypeSubmit={(type_id: number) => {
            setParams({ ...params, df_type_news_id: type_id, page: 1 })
          }}
          onDateSubmit={(from_date: string, to_date: string) => {
            setParams({
              ...params,
              from_date: from_date,
              to_date: to_date,
              page : 1
            })
          }}
        />
      </WapperStyled>
      <WapperStyled>
        <TableCutom
          // expand={(record: any) => (
          //   <DetailNews
          //     data={record}
          //     getData={getData}
          //     isLoadingButton={isLoading}
          //   />
          // )}
          isLoading={isLoading}
          columns={columns}
          clickRow={() => console.log('object')}
          data={newsList}
          paging={paging}
          changePaging={page => setParams({ ...params, page })}
        />
      </WapperStyled>
    </>
  )
}

export default NewsList
