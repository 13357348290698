import { Form, Input, message, Modal } from 'antd'
import ButtonForm from 'common/components/ModalButton'
import { useState, useEffect } from 'react'

interface IProps {
    visible: boolean
    handleOk: (values: any) => void
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function ChangePasswordModal(props: IProps) {
    const { visible, handleOk, setIsModalOpen } = props
    const [form] = Form.useForm()

    const onCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <Modal
            title="Đổi mật khẩu"
            destroyOnClose
            maskClosable={false}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Form preserve={false} onFinish={handleOk} form={form}>
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: 'Vui lòng nhập mật khẩu' },
                        {
                            min: 6,
                            message: 'Mật khẩu phải trên 6 ký tự',
                        },
                    ]}
                >
                    <Input.Password placeholder="Nhập mật khẩu" />
                </Form.Item>
                <Form.Item
                    name="retype_password"
                    rules={[
                        {
                            required: true,
                            message: 'Nhập lại mật khẩu',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error('Mật khẩu không khớp!'))
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Nhập lại mật khẩu" />
                </Form.Item>
                <ButtonForm onCancel={onCancel} text={'Lưu'} />
            </Form>
        </Modal>
    )
}

export default ChangePasswordModal
