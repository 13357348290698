import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Upload } from 'antd'
import { getDataOverviews } from 'features/admin/dashboard/dashboardApi'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ADMIN_ROLE, IS_ACTIVE, IS_ADMIN, REGION, STATUS } from 'utils/constants'
import { notificationError } from 'utils/notification'
import UploadComponent from 'utils/UploadImage'
import { createAccount, getListKiotViet } from '../AccountApi'
import { IAccount, IFieldVal, IKiotviet } from '../AccountInterfaces'
import { validEmail, validPhoneNumber } from '../modal'
import './css/EditAccount.css'
import { toNonAccentVietnamese } from 'utils/Configs'
import React from 'react'
import { getListBranchs } from 'features/admin/report/branch_report/services'
const { Option } = Select

interface Props {
    getListAccount?: any
    visible: boolean
    onCancel?: any
    data?: any
    onCreateNewAccount?: any
    onUpdateAccount?: any
    isLoadingButton?: boolean
    defaultLoading?: boolean
}

const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
}

const EditAccount = (props: Props) => {
    const { visible, onCancel, data, onUpdateAccount, getListAccount, defaultLoading } = props
    const [isShopAdmin, setIsShopAdmin] = useState<any>(data?.is_root)
    const [regionVal, setRegionVal] = useState<any>(data?.kiotviet_id)
    const [listKiotviet, setlistKiotviet] = useState<IKiotviet[]>()
    const [avatar, setavatar] = useState<string>()
    const [branches, setBranches] = React.useState<any[]>([])
    const [loading, setLoadidng] = React.useState<boolean>(false)
    const [isShowAreaSelect, setIsShowAreaSelect] = React.useState<boolean>(
        data
            ? data.role === ADMIN_ROLE.SHOP_ADMIN ||
              data?.role === ADMIN_ROLE.SALE_MANAGER ||
              data?.role === ADMIN_ROLE.INVERTORY_STAFF ||
              data?.role === ADMIN_ROLE.ACCOUNTANT
                ? true
                : false
            : false
    )
    const [isShowBranchSelect, setIsShowBranchSelect] = React.useState<boolean>(data ? true : false)
    const [form] = Form.useForm()

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                setlistKiotviet(res.data)
            }
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra!')
        }
    }

    const getBranchsByKiotviet = async () => {
        try {
            const payload = {
                kiotviet_id: regionVal,
            }
            const res = await getListBranchs(payload)
            if (res?.data) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setBranches(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleCreateAccount = async (data: any) => {
        try {
            setLoadidng(true)
            const res = await createAccount(data)
            if (res?.status) {
                getListAccount()
                onCancel()
                message.success('Thêm mới tài khoản thành công!')
            }
        } catch (err: any) {
            console.log(err)
        } finally {
            setLoadidng(false)
        }
    }

    const handleFinish = async (value: any) => {
        if (!data) {
            handleCreateAccount({
                ...value,
                role: value?.role,
                email: value.email ? value.email : undefined,
                avatar: avatar,
                status: true,
                is_root: value?.role === 1,
                kiotviet_id: regionVal ? regionVal : undefined,
                branch_id: value.branch_id ? value.branch_id : undefined,
            })
        } else {
            setLoadidng(true)
            onUpdateAccount({
                id: data.id,
                body: {
                    kiotviet_id: regionVal ? regionVal : undefined,
                    role: value?.role,
                    avatar: avatar,
                    status: value.status == IS_ACTIVE.ACTIVE,
                    is_root: value?.role === 1,
                    email: value.email ? value.email : undefined,
                    full_name: value.full_name,
                    branch_id: value.branch_id ? value.branch_id : undefined,
                },
            })
            setTimeout(() => {
                setLoadidng(false)
            }, 3000)
        }
    }

    useEffect(() => {
        if (data?.avatar) {
            setavatar(data.avatar)
        }
    }, [data?.avatar])

    useEffect(() => {
        getDataKiotviet()
    }, [])

    React.useEffect(() => {
        if (regionVal) {
            getBranchsByKiotviet()
        }
    }, [regionVal])

    return (
        <>
            <Modal
                maskClosable={false}
                onCancel={() => {
                    form.resetFields()
                    onCancel()
                }}
                footer={null}
                title={data ? 'Sửa tài khoản' : 'Thêm tài khoản'}
                visible={visible}
            >
                <Spin spinning={loading}>
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="register"
                        labelAlign="left"
                        onFinish={(value: IFieldVal) => handleFinish(value)}
                        initialValues={!data ? [] : { ...data, is_root: data.is_root ? 1 : 0 }}
                        scrollToFirstError
                    >
                        <Form.Item
                            name="full_name"
                            label="Họ tên"
                            rules={[
                                {
                                    type: 'string',
                                    message: 'Nhập họ tên!',
                                },
                                {
                                    required: true,
                                    message: 'Vui lòng nhập họ tên!',
                                },
                                {
                                    min: 3,
                                    max: 50,
                                    message: 'Vui lòng nhập từ 3 đến 50 ký tự!',
                                },
                            ]}
                        >
                            <Input type={'text'} placeholder="Nhập họ tên" />
                        </Form.Item>

                        <Form.Item
                            name="phone_number"
                            label="Số điện thoại"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập số điện thoại!',
                                },
                                {
                                    message: 'Định dạng số điện thoại không hợp lệ!',
                                    validator: (_, value) => validPhoneNumber(_, value),
                                },
                                {
                                    min: 9,
                                    max: 11,
                                    message: 'Số điện thoại phải là 10 số!',
                                },
                            ]}
                        >
                            {data ? (
                                <Input placeholder="Nhập số điện thoại" disabled />
                            ) : (
                                <Input placeholder="Nhập số điện thoại" />
                            )}
                        </Form.Item>

                        {data ? (
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        message: 'Định dạng email không hợp lệ!',
                                        validator: (_, value) => validEmail(_, value),
                                    },
                                    {
                                        max: 256,
                                        message: 'Email không quá 256 ký tự!',
                                    },
                                ]}
                            >
                                <Input placeholder="Nhập email" />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        message: 'Định dạng email không hợp lệ!',
                                        validator: (_, value) => validEmail(_, value),
                                    },
                                    {
                                        max: 256,
                                        message: 'Email không quá 256 ký tự!',
                                    },
                                ]}
                            >
                                <Input placeholder="Nhập email" />
                            </Form.Item>
                        )}

                        <Form.Item
                            label="Loại tài khoản"
                            name="role"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn loại tài khoản!',
                                },
                            ]}
                        >
                            <Select
                                disabled={!!data}
                                placeholder="Chọn loại tài khoản"
                                onChange={(role: number) => {
                                    if (
                                        role === ADMIN_ROLE.SHOP_ADMIN ||
                                        role === ADMIN_ROLE.SALE_MANAGER ||
                                        role === ADMIN_ROLE.INVERTORY_STAFF ||
                                        role === ADMIN_ROLE.ACCOUNTANT
                                    ) {
                                        setIsShowAreaSelect(true)
                                    } else {
                                        setIsShowAreaSelect(false)
                                    }

                                    if (role === ADMIN_ROLE.INVERTORY_STAFF) {
                                        setIsShowBranchSelect(true)
                                    } else {
                                        setIsShowBranchSelect(false)
                                    }
                                    setRegionVal(undefined)
                                    form.setFieldsValue({
                                        kiotviet_id: undefined,
                                        branch_id: undefined,
                                    })

                                    setIsShopAdmin(role == ADMIN_ROLE.SHOP_ADMIN)
                                }}
                                showArrow
                            >
                                <Select.Option value={ADMIN_ROLE.ROOT_ADMIN}>Admin tổng</Select.Option>
                                <Select.Option value={ADMIN_ROLE.CUSTOMER_MANAGER}>Quản lý khách hàng</Select.Option>
                                <Select.Option value={ADMIN_ROLE.CUSTOMER_CARE}>Chăm sóc khách hàng</Select.Option>
                                <Select.Option value={ADMIN_ROLE.REPORT_MANAGER}>Quản lý báo cáo</Select.Option>
                                <Select.Option value={ADMIN_ROLE.PRODUCT_MANAGER}>Quản lý sản phẩm</Select.Option>
                                <Select.Option value={ADMIN_ROLE.PREFERENTIAL_MANAGER}>Quản lý ưu đãi</Select.Option>
                                <Select.Option value={ADMIN_ROLE.SHIPPER_MANAGER}>Quản lý shipper</Select.Option>
                                <Select.Option value={ADMIN_ROLE.SHOP_ADMIN}>Admin gian hàng</Select.Option>
                                <Select.Option value={ADMIN_ROLE.SALE_MANAGER}>Trưởng phòng kinh doanh</Select.Option>
                                <Select.Option value={ADMIN_ROLE.INVERTORY_STAFF}>Quản lý kho</Select.Option>
                                <Select.Option value={ADMIN_ROLE.ACCOUNTANT}>Kế toán</Select.Option>
                            </Select>
                        </Form.Item>
                        {isShowAreaSelect && (
                            <Form.Item
                                label="Khu vực"
                                name="kiotviet_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng chọn khu vực!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Khu vực"
                                    value={regionVal}
                                    onChange={(region: number) => {
                                        setRegionVal(region)
                                    }}
                                >
                                    {listKiotviet &&
                                        listKiotviet.map((region, index) => (
                                            <Select.Option key={index} value={region.id}>
                                                {region.name ? region.name : '--'}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        )}
                        {((isShowBranchSelect && !data) || (data && data?.role === ADMIN_ROLE.INVERTORY_STAFF)) && (
                            <Form.Item
                                label="Chi nhánh"
                                name="branch_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng chọn chi nhánh!',
                                    },
                                ]}
                            >
                                <Select
                                    disabled={!regionVal}
                                    placeholder="Chi nhánh"
                                    onChange={(region: number) => {}}
                                    options={branches}
                                />
                            </Form.Item>
                        )}

                        {!data && (
                            <Form.Item
                                name="password"
                                label="Mật khẩu"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng nhập mật khẩu!',
                                    },
                                    {
                                        min: 6,
                                        message: 'Vui lòng nhập từ 6 ký tự!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password placeholder="Nhập mật khẩu" />
                            </Form.Item>
                        )}

                        {!data && (
                            <Form.Item
                                name="password_confirmation"
                                label="Xác nhận mật khẩu"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nhập lại mật khẩu!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error('Mật khẩu không khớp!'))
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Nhập lại mật khẩu" />
                            </Form.Item>
                        )}
                        {data ? (
                            <Form.Item name="status" label="Trạng thái">
                                <Select
                                    placeholder="Chọn trạng thái"
                                    defaultValue={!data ? IS_ACTIVE.ACTIVE : data?.status}
                                >
                                    <Select.Option value={IS_ACTIVE.ACTIVE}>Đang hoạt động</Select.Option>
                                    <Select.Option value={IS_ACTIVE.INACTIVE}>Ngừng hoạt động</Select.Option>
                                </Select>
                            </Form.Item>
                        ) : null}

                        <Form.Item label="Ảnh đại diện" name="avatar">
                            <UploadComponent
                                uploadType="single"
                                listType="picture-card"
                                isUploadServerWhenUploading
                                onSuccessUpload={(res: any) => {
                                    setavatar(res.data.url)
                                }}
                                fileList={
                                    avatar
                                        ? [
                                              {
                                                  id: '1',
                                                  url: avatar,
                                              },
                                          ]
                                        : []
                                }
                            />
                        </Form.Item>

                        <Row gutter={16} justify="end">
                            <Col>
                                <Button danger onClick={onCancel}>
                                    <CloseCircleOutlined />
                                    Huỷ
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit">
                                    <CheckCircleOutlined />
                                    Lưu
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default EditAccount
