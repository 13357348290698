import { Form, Input, InputNumber, message, Modal } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import ButtonForm from 'common/components/ModalButton'
import { useState, useEffect } from 'react'
import reactotron from 'ReactotronConfig'
import styled from 'styled-components'
import { getDetailCustomer, LoadCoin } from '../CustomerAPI'
import { ICustomerDetail } from '../CustomerInterfaces'

interface ICoinProps {
  cancel: () => void
  visible: boolean
  data?: ICustomerDetail
  handleCallback: () => void
}
function CoinModal(props: ICoinProps) {
  const [form] = Form.useForm()
  const [isLoading, setisLoading] = useState<boolean>(false)

  const handleFinish = async (values: any) => {
    setisLoading(true)
    try {
      if (props.data?.wallet?.user_id) {
        let newData
        newData = { data: values, id: props.data?.wallet?.user_id }
        const res = await LoadCoin(newData)
        message.success('Cập nhật số dư thành công')
        props.handleCallback()
        props.cancel()
      }
    } catch (err) {
      reactotron.log!(err)
      message.error('Có lỗi xảy ra')
    } finally {
      setisLoading(false)
    }
  }

  return (
    <Modal
      title="Nạp xu"
      destroyOnClose
      maskClosable={false}
      visible={props.visible}
      onCancel={() => props.cancel()}
      footer={null}
    >
      <Form
        name="add"
        preserve={false}
        onFinish={(values: any) => {
          handleFinish(values)
        }}
        form={form}
      >
        <Form.Item
          label="Nhập số xu"
          name="coin"
          rules={[{ required: true, message: 'Vui lòng số xu muốn nạp' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Nhập số xu cần nạp"
            controls={false}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          />
        </Form.Item>

        <Form.Item label="Nội dung" name="note">
          <TextAreaStyled placeholder="" />
          {/* <Input style={{ width: '100%' }} placeholder="Nhập nội dung nạp xu" /> */}
        </Form.Item>

        <ButtonForm
          isLoadingButton={isLoading}
          onCancel={() => props.cancel()}
          text={'Lưu'}
        />
      </Form>
    </Modal>
  )
}

const TextAreaStyled = styled(TextArea)`
  height: 100px !important;
`

export default CoinModal
