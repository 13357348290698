import { SESSION_ID } from 'common/config'
import { environment } from 'config'
import io, { Socket } from 'socket.io-client'
import Cookie from 'js-cookie'

export class WebSocket {
    public static socketClient: Socket
    private static currentToken: string

    public static async init(token: string) {
        if (!this.socketClient && this.currentToken !== token) {
            this.currentToken = token
            // this.socketClient = io(environment.ws_host as string, {
            this.socketClient = io('https://httapi.winds.vn' as string, {
                auth: { token: Cookie.get(SESSION_ID) },
                path: '/socket.io',
                transports: ['websocket'],
                // secure: true,
            })
        }
    }
    public static async disconnect() {
        if (this.socketClient) {
            this.socketClient.disconnect()
        }
    }
}
