import { Button, Col, DatePicker, Input, Pagination, Row, Select, Image, Tag, Divider, Typography } from 'antd'
import moment from 'moment'
import { HeartFilled, MessageOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react'
import { COMUNITY_POST_STATUS, ROLE, TOPIC_TYPE } from 'utils/constants'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Avatar from 'antd/lib/avatar/avatar'
import { UserOutlined } from '@ant-design/icons'
import { getAllTopic, listCommunityPost, requestReactionComment, requestReactionPost } from './CommunityPostApi'
import { useHistory } from 'react-router-dom'
import { Empty } from 'antd'
import { lineBreakPost, splitTextEndLine } from 'utils/funcHelper'
import { useSelector } from 'react-redux'
import PageHeaderStyled from 'common/components/pageHeader'
import styled from 'styled-components'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { IFormatedPaging, IListTopicPayload } from './InterfaceComPost'
import { get_ss_hh_dd_mm } from 'utils/TimerHelper'
import { useDebounce } from 'common/hooks/Debounce'
import { notificationError } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'

const { Option } = Select
const { RangePicker } = DatePicker
const { Search } = Input
const { Text } = Typography

const WapperBlock = styled.div`
    padding: 10px;
    margin: 0 10px;
    background-color: white;
`

const WapperMedia = styled(Col)`
    max-height: 145px;
    max-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 15px;
`

export default function CommunityPost({ location }: any) {
    const heightWeb = useSelector((state: any) => state.configReducer.dimension.height)
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
    const [dataPost, setDataPost] = useState<Array<any>>([])
    const [allTopic, setAllTopic] = useState<any>()
    const [callback, setCallback] = useState<boolean>(false)
    const [params, setparams] = useState<IListTopicPayload>({
        search: undefined,
        user_id: undefined,
        shop_id: undefined,
        topic_id: undefined,
        from_date: undefined,
        to_date: undefined,
        is_posted: undefined,
        page: 1,
    })
    const [paging, setPaging] = useState<IFormatedPaging>({
        total: 1,
        current: location.state?.paging?.current ? location.state?.paging?.current : 1,
        pageSize: 1,
    })
    const handleCallback = () => {
        setCallback(!callback)
    }
    const history = useHistory()
    const getTopic = async () => {
        try {
            const res = await getAllTopic()
            if (res.data) {
                setAllTopic(res.data)
            }
        } catch (err: any) {
            console.log(err)
        }
    }
    const debounced = useDebounce(params, 500)
    useEffect(() => {
        getListCommunityPost()
        getTopic()
    }, [debounced, callback])
    const getListCommunityPost = async () => {
        setIsSearchLoading(true)
        try {
            const res = await listCommunityPost(params)
            const data_list = res.data.map((item: any) => {
                return { ...item, key: item.id }
            })
            const formattedPaging = {
                total: res.paging.totalItemCount,
                current: res.paging.page,
                pageSize: res.paging.limit,
            }
            setPaging(formattedPaging)
            setDataPost(data_list)
        } catch (error) {
            console.log(error)
        } finally {
            setIsSearchLoading(false)
        }
    }
    const handleDetail = (value: any) => {
        history.push({
            pathname: `${ADMIN_ROUTER_PATH.COMMUNITY_POST}/${value}`,
        })
    }

    const reactionRequest = async (value: any) => {
        try {
            const res = await requestReactionPost(value)
            handleCallback()
        } catch (error) {
            console.log(error)
            history.goBack()
        }
    }
    return (
        <>
            <PageHeaderStyled
                title="Bài đăng cộng đồng"
                extra={[
                    <Button
                        type="primary"
                        onClick={() => {
                            history.push({
                                pathname: `${ADMIN_ROUTER_PATH.ADD_POST}`,
                            })
                        }}
                    >
                        <PlusCircleOutlined />
                        Thêm bài đăng
                    </Button>,
                ]}
            />

            <WapperBlock>
                <Row gutter={[16, 16]} justify="end">
                    <Col>
                        <TypingAutoSearch
                            width={280}
                            placeholder="Người đăng, nội dung bài đăng"
                            onSearchSubmit={(search: string) => {
                                setparams({ ...params, search })
                            }}
                        />
                    </Col>
                    <Col>
                        <Select
                            style={{ width: '130px' }}
                            allowClear
                            placeholder="Chọn chủ đề"
                            onChange={(value: any) => {
                                setparams({
                                    ...params,
                                    topic_id: value,
                                    page: 1,
                                })
                            }}
                        >
                            {allTopic &&
                                allTopic.map((topic: any, index: number) => (
                                    <Option key={index} value={topic.id}>
                                        {topic.name}
                                    </Option>
                                ))}
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            style={{ width: '185px' }}
                            allowClear
                            placeholder="Phân loại"
                            onChange={(status: any) => {
                                setparams({
                                    ...params,
                                    is_posted: status,
                                })
                            }}
                        >
                            <Option value={COMUNITY_POST_STATUS.POSTED}>Bài đăng</Option>
                            <Option value={COMUNITY_POST_STATUS.NOT_POSTED_YET}>Lưu nháp</Option>
                        </Select>
                    </Col>
                    <Col>
                        <RangePicker
                            placeholder={['Từ ngày', 'Đến ngày']}
                            onChange={(selectTime, dateString) => {
                                setparams({
                                    ...params,
                                    from_date: dateString[0],
                                    to_date: dateString[1],
                                })
                            }}
                        />
                    </Col>
                </Row>
            </WapperBlock>

            <WapperBlock>
                <p>
                    Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                </p>
                {dataPost.length === 0 ? (
                    <Empty description={<span>Chưa có bài đăng. Hãy là người đầu tiên đăng bài</span>} />
                ) : (
                    <>
                        <div
                            style={{
                                overflowY: 'scroll',
                                height: `calc(${heightWeb}px - 340px)`,
                            }}
                        >
                            {dataPost.map((item: any) => {
                                return (
                                    <div style={{ marginBottom: '25px' }}>
                                        <Row
                                            onClick={() => {
                                                handleDetail(item.id)
                                            }}
                                            style={{ paddingBottom: '15px' }}
                                        >
                                            <Col>
                                                <Avatar
                                                    size={64}
                                                    icon={<UserOutlined />}
                                                    src={
                                                        item?.Account?.Admin?.avatar
                                                            ? item?.Account?.Admin?.avatar
                                                            : item?.Account?.User?.avatar
                                                    }
                                                />
                                            </Col>
                                            <Col style={{ marginLeft: '15px' }}>
                                                {item?.Account?.role === ROLE.ADMIN ? (
                                                    <b> {item?.Account?.Admin?.full_name}</b>
                                                ) : (
                                                    <b>{item?.Account?.User?.full_name}</b>
                                                )}

                                                <Row>
                                                    <p>
                                                        {' ' +
                                                            moment(
                                                                item.schedule ? item.schedule : item.create_at
                                                            ).fromNow() +
                                                            ' '}
                                                    </p>
                                                    &nbsp;
                                                    <p>{item.is_posted ? '( Bài đăng )' : '( Lưu nháp )'}</p>
                                                    <Tag color="#B47EDE" style={{ marginLeft: '10px', height: '25px' }}>
                                                        {item.Topic.name}
                                                    </Tag>
                                                </Row>
                                            </Col>

                                            <p style={{ paddingLeft: '43%' }}>
                                                Ngày đăng:
                                                <b>
                                                    {item.schedule
                                                        ? ' ' + get_ss_hh_dd_mm(item.schedule)
                                                        : ' ' + get_ss_hh_dd_mm(item.create_at)}
                                                </b>
                                            </p>
                                        </Row>
                                        <div style={{ paddingBottom: '5px' }}>
                                            {item.content &&
                                                lineBreakPost(item.content).map((text: string) => (
                                                    <Text>
                                                        <span>
                                                            {text} <br />
                                                        </span>
                                                    </Text>
                                                ))}
                                            <Row
                                                onClick={() => {
                                                    handleDetail(item.id)
                                                }}
                                            >
                                                {item.PostMedia.map((item: any) => {
                                                    return (
                                                        <WapperMedia span={5}>
                                                            {item.media_url.indexOf('mp4') === -1 &&
                                                            item.media_url.indexOf('MP4') === -1 &&
                                                            item.media_url.indexOf('MOV') === -1 ? (
                                                                <Image
                                                                    src={item.media_url}
                                                                    onClick={e => e.stopPropagation()}
                                                                />
                                                            ) : (
                                                                <video
                                                                    controls
                                                                    src={item.media_url}
                                                                    className="uploaded-pic img-thumbnail "
                                                                    width={200}
                                                                    height={145}
                                                                />
                                                            )}
                                                        </WapperMedia>
                                                    )
                                                })}
                                            </Row>
                                            <Row style={{ marginBottom: 5 }}>
                                                <Col>
                                                    <Button
                                                        type="text"
                                                        danger={item?.is_reaction ? true : false}
                                                        icon={<HeartFilled style={{ fontSize: 18 }} />}
                                                        onClick={() => {
                                                            reactionRequest(item.id)
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 18 }}>{item?.count_like}</span>
                                                    </Button>
                                                </Col>
                                                <Col
                                                    onClick={() => {
                                                        handleDetail(item.id)
                                                    }}
                                                >
                                                    <Button
                                                        type="text"
                                                        // danger={item?.is_reaction ? true : false}
                                                        icon={<MessageOutlined style={{ fontSize: 18 }} />}
                                                    >
                                                        <span style={{ fontSize: 18 }}>{item?.count_comment}</span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                        {dataPost.length === 1 ? (
                                            <></>
                                        ) : (
                                            <Divider style={{ backgroundColor: '#A4A4A4' }} />
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <Divider style={{ margin: '5px 0px' }} />
                            <Pagination
                                size="small"
                                showSizeChanger={false}
                                defaultCurrent={paging.current}
                                total={paging.total}
                                pageSize={paging.pageSize}
                                onChange={async (page, pageSize) => {
                                    setparams({ ...params, page })
                                }}
                            />
                        </div>
                    </>
                )}
            </WapperBlock>
        </>
    )
}
