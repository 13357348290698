import Icon, { EditFilled } from '@ant-design/icons'
import { message, Switch, Table, Tag, Tooltip } from 'antd'
import { useState, useEffect, memo } from 'react'
import { IS_ACTIVE, TEXT_ACTIVE } from 'utils/constants'
import { activeCategory, getCategory } from './CategoryAPI'
import moment from 'moment'
import './component/css/Category.css'
import {
  ICategoryPayload,
  ICategory,
  IFormatedChildrenCate,
  IFormatedTableCate,
} from './CategoryInterfaces'
import EditChildrenCate from './EditChildrenCate'
import { ColumnsType } from 'antd/lib/table'
import FilterChildrenCate from './FilterChildrenCate'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { notificationError } from 'utils/notification'
import { inactiveCategory } from '../Products/ProductAPI'
interface ChildrenCategoryProps {
  infoCategory: IFormatedTableCate
}

function ChildrenCategory(props: ChildrenCategoryProps) {
  const infoCategory = props.infoCategory
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [childrenCates, setchildrenCates] = useState<IFormatedChildrenCate[]>()
  const [childrenCate, setchildrenCate] = useState<ICategory>()
  const [params, setparams] = useState<ICategoryPayload>({
    search: '',
    create_from: '',
    create_to: '',
    status: '',
    page: 1,
    limit: 24,
    parent_id: infoCategory.id.toString(),
    kiotviet_id: '',
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const handleChangeStatusChild = async (
    isStatus: boolean,
    record: IFormatedChildrenCate
  ) => {
    try {
      if (!isStatus && record.id) {
        await inactiveCategory(record.id.toString())
      } else {
        await activeCategory(record.id.toString())
      }
      getDataChildrenCate(params)
      message.success('Thay đổi trạng thái thành công')
    } catch (err: any) {
      notificationError(err)
    }
  }

  const getDataChildrenCate = async (params: ICategoryPayload) => {
    try {
      const res = await getCategory(params)
      if (res.data) {
        const dataChildrenCate = res.data?.map((item, index) => {
          return { ...item, key: index }
        })
        setchildrenCates(dataChildrenCate.reverse())
      }
      if (res.paging) {
        setPaging({
          total: res.paging.totalItemCount,
          current: res.paging.page,
          pageSize: res.paging.limit,
        })
      }
    } catch (err: any) {
      notificationError(err)
    }
  }

  useEffect(() => {
    console.log('run...')
    getDataChildrenCate(params)
  }, [params])

  const renderManipulation = (record: IFormatedChildrenCate) => {
    return (
      <td style={{ display: 'flex', alignItems: 'center' }}>
        <Switch
          checked={record.status == IS_ACTIVE.ACTIVE}
          onChange={(isStatus: boolean) => {
            handleChangeStatusChild(isStatus, record)
          }}
        />
        <EditFilled
          className="edit-children"
          onClick={() => {
            setchildrenCate(record)
            setIsModalVisible(true)
          }}
        />
      </td>
    )
  }

  const renderStatus = (status: number) => {
    return (
      <td>
        {status == IS_ACTIVE.INACTIVE ? (
          <Tag color="volcano">{TEXT_ACTIVE.INACTIVE}</Tag>
        ) : (
          <Tag color="success">{TEXT_ACTIVE.ACTIVE}</Tag>
        )}
      </td>
    )
  }

  const columns: ColumnsType<IFormatedChildrenCate> = [
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      ellipsis: {
        showTitle: false,
      },
      render: name => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: number) => renderStatus(status),
    },
    {
      title: 'Thứ tự hiển thị',
      dataIndex: 'order',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      render: (time: string) => {
        return <td>{convertTimeStampToString(time)}</td>
      },
    },
    {
      title: 'Chức năng',
      dataIndex: '',
      render: (record: IFormatedChildrenCate) => renderManipulation(record),
    },
  ]

  return (
    <>
      <EditChildrenCate
        infoCate={infoCategory}
        onCancel={() => setIsModalVisible(false)}
        dataChildrenCate={childrenCate}
        isShowEditChildCate={isModalVisible}
        getChildCate={() => {
          getDataChildrenCate(params)
        }}
      />
      <FilterChildrenCate
        onSubmitKey={(value: string) => setparams({ ...params, search: value })}
        onSubmitRangetime={(rangeTime: Array<string>) =>
          setparams({
            ...params,
            create_from: rangeTime[0],
            create_to: rangeTime[1],
          })
        }
      />
      <Table
        size="middle"
        dataSource={childrenCates}
        columns={columns}
        pagination={{
          ...paging,
          showSizeChanger: false,
          onChange: async page => {
            setparams({ ...params, page })
          },
        }}
      />
    </>
  )
}

export default memo(ChildrenCategory)
