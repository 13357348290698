import { DeleteFilled, EditOutlined, UndoOutlined } from '@ant-design/icons'
import { Button, Card, Descriptions, Popconfirm, Spin, Switch, message } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { renderRole } from 'utils/Configs'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { IS_ACTIVE } from 'utils/constants'
import { notificationError } from 'utils/notification'
import { activeAccount, getDetailAccount, inactiveAccount, updateAccount } from './AccountApi'
import { IAccount, IFormatedAccount } from './AccountInterfaces'
import EditAccount from './components/AddEditAcount'
import './components/css/Header.css'

const WapperInfo = styled.div`
    margin-left: 2rem;
`

const ButtonDetail = styled.span`
    text-decoration: underline;
    cursor: pointer;
    color: #1785df;
    padding-left: 10px;
`

type IStatusText = {
    primary: boolean
}

const StatusText = styled.span<IStatusText>`
    padding-left: 10px;
    color: ${(props: any) => (props.primary ? '#00abba' : '#707575')};
`

type Props = {
    data: any
    getData: any
    onDeleteAccount: any
    onResetPassword: any
}

function AccountDetail({ data, getData, onDeleteAccount: handleAccount, onResetPassword }: Props) {
    const [showEditAccount, setShowEditAccount] = useState(false)
    const [detailAccount, setdetailAccount] = useState<IAccount>()
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [isStatusAcc, setisStatusAcc] = useState<boolean>(data.status == IS_ACTIVE.ACTIVE)

    const getDataDetail = async () => {
        try {
            const res = await getDetailAccount(data.id.toString())
            if (res.data) {
                setdetailAccount(res.data)
            }
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra!')
        }
    }

    useEffect(() => {
        getDataDetail()
    }, [data.id])

    const ContentView = (data?: any) => {
        return (
            <WapperInfo>
                <Descriptions size="default" column={3}>
                    <Descriptions.Item label="Tên người dùng">{data?.full_name}</Descriptions.Item>
                    <Descriptions.Item label="Email" span={3}>
                        {data?.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="Số điện thoại">{data?.phone_number}</Descriptions.Item>
                    <Descriptions.Item label="Ngày tạo" span={3}>
                        {convertTimeStampToString(data?.created_at)}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Số lượng KH quản lý">{data?.total_user}</Descriptions.Item> */}
                    <Descriptions.Item label="Vai trò">{renderRole(data?.role)}</Descriptions.Item>
                </Descriptions>
            </WapperInfo>
        )
    }

    const onChangeStatus = async (checked: boolean) => {
        setisStatusAcc(checked)
        try {
            if (checked) {
                const res = await activeAccount(data.id.toString())
            } else {
                const res = await inactiveAccount(data.id.toString())
            }
            message.success(`Cập nhật trạng thái thành công`)
            getData()
        } catch (error) {
            console.log(error)
            notificationError('Có lỗi xảy ra')
        }
    }

    const handleUpdateAccount = async (data: any) => {
        setisLoading(true)
        try {
            const res = await updateAccount(data)
            setisStatusAcc(res.data?.status == IS_ACTIVE.ACTIVE)
            getData()
            getDataDetail()
            message.success('Cập nhật tài khoản thành công!')
            setShowEditAccount(false)
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra!')
        } finally {
            setisLoading(false)
        }
    }

    return (
        <>
            {showEditAccount && (
                <EditAccount
                    data={detailAccount}
                    visible={showEditAccount}
                    onCancel={() => {
                        setShowEditAccount(false)
                    }}
                    onUpdateAccount={(data: any) => handleUpdateAccount(data)}
                    isLoadingButton={false}
                    defaultLoading={isLoading}
                />
            )}

            <Card
                style={{
                    backgroundColor: '#f6f9ff',
                    borderColor: '#1890ff',
                    borderTop: 'none',
                }}
                actions={[
                    <>
                        <Switch checked={isStatusAcc} onChange={onChangeStatus} />
                        <StatusText primary={data.status == IS_ACTIVE.ACTIVE}>
                            {data.status == IS_ACTIVE.ACTIVE ? 'Đang hoạt động' : 'Ngừng hoạt động'}
                        </StatusText>
                    </>,
                    <Button
                        onClick={() => {
                            setShowEditAccount(true)
                        }}
                        type="text"
                        size="large"
                        icon={<EditOutlined />}
                        style={{ color: '#1890ff' }}
                    >
                        Chỉnh sửa
                    </Button>,

                    <Popconfirm
                        title={'Bạn chắc chắn muốn đặt lại mật khẩu tài khoản này'}
                        onConfirm={async () => {
                            try {
                                onResetPassword(data.id)
                            } catch (error) {
                            } finally {
                            }
                        }}
                        okText="Đặt lại mật khẩu"
                        cancelText="Quay lại"
                        okButtonProps={{
                            type: 'primary',
                        }}
                    >
                        <Button type="text" size="large" icon={<UndoOutlined />}>
                            Đặt lại mật khẩu
                        </Button>
                    </Popconfirm>,

                    <Popconfirm
                        title={'Bạn chắc chắn muốn xoá tài khoản này'}
                        onConfirm={async () => {
                            handleAccount(data.id)
                        }}
                        okText="Xoá"
                        cancelText="Quay lại"
                        okButtonProps={{
                            danger: true,
                            type: 'primary',
                        }}
                    >
                        <Button type="text" size="large" icon={<DeleteFilled />} style={{ color: 'red' }}>
                            Xoá tài khoản
                        </Button>
                    </Popconfirm>,
                ]}
            >
                <Spin spinning={isLoading}>{ContentView(detailAccount)}</Spin>
            </Card>
        </>
    )
}

export default AccountDetail
