import { Card, Image, Row, Table } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface IExpandContent {
    item: any
}

const ExpandContent = (props: IExpandContent) => {
    const { item } = props

    const listImages = item?.medias?.filter((item: any) => item?.type === 1)
    const listVideos = item?.medias?.filter((item: any) => item?.type === 2)
    const [paging, setPaging] = React.useState<any>({
        total: item?.items?.length,
        current: 1,
        pageSize: 12,
    })

    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Danh mục</b>,
            dataIndex: 'product_category',
        },
        {
            title: <b>Tên sản phẩm</b>,
            dataIndex: 'product_name',
        },
        {
            title: <b>Mã sản phẩm</b>,
            dataIndex: 'product_code',
        },

        {
            title: <b>Số lượng sản phẩm</b>,
            dataIndex: 'quantity',
        },
        {
            title: <b>Tình trạng</b>,
            dataIndex: 'product_status_txt',
        },
    ]
    return (
        <CustomCard title={<b>Thông tin chung</b>} bordered={false}>
            <div className="block">
                <p className="label">Danh sách sản phẩm</p>
                <Table
                    id="top-table"
                    bordered
                    columns={columns}
                    dataSource={item?.items}
                    scroll={{
                        x: 800,
                        scrollToFirstRowOnChange: true,
                    }}
                    locale={{
                        emptyText: 'Chưa có bản ghi nào!',
                    }}
                    pagination={{
                        ...paging,
                        onChange: async page => {
                            setPaging({ ...paging, current: page })
                        },
                    }}
                />
            </div>
            <div className="block">
                <p className="label">Hình ảnh</p>
                <div className="image_block">
                    {listImages?.map((item: any) => (
                        <div key={item?.id} className="image">
                            <Image
                                className="image_content"
                                height={100}
                                width={100}
                                alt="flower"
                                src={item?.media_url}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="block">
                <p className="label">Video</p>
                <iframe
                    width="400"
                    height="200"
                    src={listVideos?.[0]?.media_url}
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
        </CustomCard>
    )
}

const CustomCard = styled(Card)`
    width: 100%;
    border-radius: 10px;

    .block {
        width: 100%;
        margin-bottom: 20px;
        .label {
            font-weight: 700;
            text-decoration: underline;
        }

        .image_block {
            display: flex;

            .image {
                width: 100px;
                height: 100px;
                margin-right: 14px;

                .image_content {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    object-fit: cover;
                }
            }

            .description {
                font-size: 12px;
                color: gray;
            }
        }
    }
`

export default ExpandContent
