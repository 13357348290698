import { Descriptions, PageHeader, Row, Table, Typography } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { getData } from 'features/admin/config-clone/GetDataSlice'
import { ColumnsType } from 'rc-table/lib/interface'
import { memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { PAYMENT_METHOD } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import {
  convertStringToTimeStamp,
  convertTimeStampToString,
} from 'utils/TimerHelper'
import { getDetailDebit } from '../../CustomerAPI'
import {
  IDetailDebit,
  IDetailDebitPayload,
  IFormatedDebitHistory,
  IFormatedPaging,
  IPaging,
} from '../../CustomerInterfaces'
const { Text } = Typography

interface IWapperContentStyle {
  padding?: boolean
}

const Header = styled(PageHeader)`
  background-color: white;
  padding: 10px;
  margin: 10px;
`
const WapperContent = styled.div<IWapperContentStyle>`
  background-color: white;
  padding: ${props => (props.padding ? '15px' : '10px')};
  margin: 0 10px 10px 10px;
`

const DetailDelt = ({ location }: any) => {
  const history = useHistory()
  const [debitHistory, setdebitHistory] = useState<IFormatedDebitHistory[]>([])
  const [detailDebit, setdetailDebit] = useState<IDetailDebit>()
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [params, setparams] = useState<IDetailDebitPayload>({
    id: !location.state?.data?.id ? location.state?.data?.id : 2,
    page: '',
    limit: '12',
  })
  const [paging, setPaging] = useState<IFormatedPaging>({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const getData = async () => {
    setisLoading(true)
    try {
      const res = await getDetailDebit(params)
      if (res.data) {
        const dataTable = res.data.debit_history.map((debitHistory, index) => {
          return {
            ...debitHistory,
            key: index,
            index: index + 1,
          }
        })
        setdebitHistory(dataTable)
        setdetailDebit(res.data)
      }
      if (res.paging) {
        setPaging({
          total: res.paging.totalItemCount,
          current: res.paging.page,
          pageSize: res.paging.limit,
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setisLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [params])

  const columns = [
    {
      title: 'STT',
      render: (text: string, record: IFormatedDebitHistory, index: number) => (
        <Text id={record.id.toString()}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </Text>
      ),
    },
    {
      title: 'Ngày thanh toán',
      dataIndex: 'created_at',
      render: (created_at: Date) => {
        return <Text>{convertTimeStampToString(created_at)}</Text>
      },
    },
    {
      title: 'Giá trị thanh toán',
      dataIndex: 'value',
      render: (value: number) => {
        return <Text>{formatPrice(value)} đ</Text>
      },
    },
  ]

  const paymentMethodDesc = (method?: string) => {
    switch (method) {
      case PAYMENT_METHOD.COD:
        return <Text>Tiền mặt</Text>
      case PAYMENT_METHOD.BANKING:
        return <Text>Chuyển khoản</Text>
      case PAYMENT_METHOD.COIN:
        return <Text>Xu</Text>
      case PAYMENT_METHOD.DEBIT:
        return <Text>Công nợ</Text>
      case '':
        return '--'
    }
  }

  return (
    <>
      <Header
        title="Chi tiết công nợ"
        onBack={() =>
          history.push({
            pathname: ADMIN_ROUTER_PATH.CUSTOMER,
            state: {
              key: location.state?.key,
              page: location.state?.page,
            },
          })
        }
      />
      <WapperContent padding>
        <Descriptions title="Thông tin đơn hàng">
          <Descriptions.Item label="Mã đơn hàng">
            {detailDebit?.order?.code || '--'}
          </Descriptions.Item>
          <Descriptions.Item label="Tổng tiền" span={3}>
            {formatPrice(detailDebit?.order?.total_payment) + ' đ' || '--'}
          </Descriptions.Item>
          <Descriptions.Item label="Sản phẩm">
            {detailDebit?.order?.total_item + ' sản phẩm' || '--'}
          </Descriptions.Item>
          <Descriptions.Item label="Tổng tiền giảm (Voucher)" span={3}>
            {detailDebit?.order?.total_discount || '--'}
          </Descriptions.Item>
          <Descriptions.Item label="Hình thức thanh toán">
            {paymentMethodDesc(detailDebit?.order?.payment_method)}
          </Descriptions.Item>
          <Descriptions.Item label="Tổng tiền giảm (Điểm tích luỹ)" span={3}>
            {detailDebit?.order?.use_point || '--'}
          </Descriptions.Item>
          <Descriptions.Item label="Thời gian đặt hàng">
            {convertTimeStampToString(detailDebit?.order?.created_at) || '--'}
          </Descriptions.Item>
          <Descriptions.Item label="Tổng tiền thanh toán" span={3}>
            {formatPrice(detailDebit?.order?.total_payment) + ' đ' || '--'}
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái thanh toán">
            {detailDebit?.order?.payment_status == 'inprogress'
              ? 'Đã thanh toán'
              : 'Chưa thanh toán'}
          </Descriptions.Item>
          <Descriptions.Item label="Công nợ còn lại">
            {formatPrice(detailDebit?.order?.debit_remaining) + ' đ' || '--'}
          </Descriptions.Item>
        </Descriptions>
      </WapperContent>
      <WapperContent>
        <Table
          bordered
          loading={isLoading}
          columns={columns}
          dataSource={debitHistory}
          pagination={{
            ...paging,
            onChange: async (page, pageSize) => {
              setparams({ ...params, page: page.toString() })
            },
          }}
        />
      </WapperContent>
    </>
  )
}
export default memo(DetailDelt)
