import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from 'antd'
import ButtonForm from 'common/components/ModalButton'
import { useState } from 'react'
import createFormDataImage from 'utils/createFormDataImage'
import './css/AddEditTopicPost.css'
import { Upload } from 'antd'
import { uploadImage } from 'features/admin/gift/listGift/listGiftApi'
import { createTopic, updateTopic } from '../TopicPostApi'
import { notificationError, notificationSuccess } from 'utils/notification'
import { IS_ACTIVE } from 'utils/constants'
import TextArea from 'antd/lib/input/TextArea'
import styled from 'styled-components'
// import ImgCrop from 'antd-img-crop'
const { Option } = Select

type TopicProps = {
  visible: boolean
  onCancel?: any
  data?: any
  isLoadingButton: boolean
  getData?: any
  handleCallback?: any
}

function convertDataToFrom(data: any) {
  if (!data) {
    return {
      is_default: null,
      minus: null,
      name: null,
      pakage_category_id: null,
      price: null,
    }
  } else {
    return {
      data,
    }
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

export const AddEditTopicPost = ({
  visible,
  onCancel,
  data,
  isLoadingButton,
  handleCallback,
}: TopicProps) => {
  const [form] = Form.useForm()
  const { TextArea } = Input
  const [isLoading, setIsLoading] = useState(false)

  const initialValue = convertDataToFrom(data)
  const onFinish = async (values: any) => {
    try {
      setIsLoading(true)
      if (values?.icon_url?.fileList) {
        var resUploadImage = []
        var last_element = []

        if (values.icon_url.fileList.length > 1) {
          last_element = [values.icon_url.fileList.slice(-1)[0]]
        } else {
          last_element = values.icon_url.fileList
        }
        const dataImage = await createFormDataImage(last_element)
        const payloadImage = {
          type: 1,
          data: dataImage,
        }
        try {
          resUploadImage = await uploadImage(payloadImage)
        } catch (error) {
          // alert(error)
        }
      }
      let newData

      values.description = values.description.trim()
      if (!data) {
        newData = {
          ...values,
          icon_url: resUploadImage?.data?.path,
        }
        try {
          const res = await createTopic(newData)
          notificationSuccess('Thêm chủ đề thành công')
          setUpload({
            loading: false,
            imageUrl: '',
          })
          form.resetFields()
          handleCallback()
          onCancel()
        } catch (error) {
          notificationError('Thêm chủ đề thất bại')
          console.log(error)
        }
      } else {
        const { ...rest } = values
        if (data?.icon_url === values?.icon_url) {
          if (values.icon_url === null) {
            const { icon_url, ...rest } = values
            
            newData = {
              data: { ...rest },
              id: data.id,
            }
          } else {
           
            newData = {
              data: { ...rest },
              id: data.id,
            }
          }
        } else {
          newData = {
            id: data.id,
            data: {
              ...rest,
              icon_url: resUploadImage?.data?.path,
            },
          }
        }
        try {
          const res = await updateTopic(newData)
          notificationSuccess('Cập nhật chủ đề thành công')
          setUpload({
            loading: false,
            imageUrl: '',
          })
          form.resetFields()
          handleCallback()
          onCancel()
        } catch (error) {
          console.log(error)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const [upload, setUpload] = useState({
    loading: false,
    imageUrl: data?.icon_url || '',
  })

  function getBase64(img: any, callback: any) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('Xảy ra lỗi! Bạn chỉ có thể upload ảnh có dạng JPG/PNG!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Cho phép ảnh có dung lượng tối đa là 2MB')
    }
    return isJpgOrPng && isLt2M
  }
  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setUpload({
        imageUrl: '',
        loading: true,
      })
      return
    }

    if (info.file.status === 'done' || info.file.status === 'error') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) =>
        setUpload({
          imageUrl: imageUrl,
          loading: false,
        })
      )
    }
  }

  const uploadButton = (
    <div>
      {upload.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  return (
    <Modal
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      maskClosable={false}
      footer={null}
      title={data ? 'Sửa chủ đề ' : 'Thêm chủ đề '}
      visible={visible}
      destroyOnClose
    >
      <Form
        {...formItemLayout}
        form={form}
        name="add"
        labelAlign="left"
        onFinish={(values: any) => onFinish(values)}
        initialValues={initialValue.data}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Tên chủ đề"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tên chủ đề!',
            },
            {
              whitespace: true,
              message: 'Vui lòng không nhập khoảng trắng!',
            },
            {
              min: 3,
              max: 50,
              message: 'Vui lòng nhập từ 3 đến 50 ký tự!',
            },
          ]}
        >
          <Input
            placeholder="Nhập tên chủ đề"
            onBlur={() => {
              form.setFieldsValue({
                name: form.getFieldValue('name')?.trim(),
              })
            }}
          />
        </Form.Item>

        <Form.Item
          name="order"
          label="STT hiển thị"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập STT hiển thị',
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Nhập STT hiển thị"
            min={0}
            parser={(value: any) =>
              value ? value.replace(/\$\s?|(,*)/g, '') : ''
            }
            controls={false}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
          />
        </Form.Item>
        {data ? (
          <Form.Item label="Trạng thái" name={'status'}>
            <Select placeholder="Trạng thái">
              <Option value={IS_ACTIVE.ACTIVE}>Đang hoạt động</Option>
              <Option value={IS_ACTIVE.INACTIVE}>Ngừng hoạt động</Option>
            </Select>
          </Form.Item>
        ) : (
          <> </>
        )}
        <Form.Item
          label="Icon danh mục"
          name="icon_url"
          rules={[
            {
              required: false,
              message: 'Vui lòng chọn icon!',
            },
          ]}
        >
          <Upload
            name="icon_url"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {upload.imageUrl ? (
              <img
                src={upload.imageUrl === '' ? data?.icon_url : upload.imageUrl}
                alt="avatar"
                style={{ width: '100%', objectFit: 'contain', height: '100%' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name={'description'}
          label="Mô tả"
          rules={[
            {
              type: 'string',
              required: true,
              message: 'Vui lòng nhập mô tả!',
              validator: (_, value) => {
                if (value.trim() === '') {
                  return Promise.reject()
                }
                return Promise.resolve()
              },
            },
            {
              min: 3,
              max: 500,
              message: 'Vui lòng nhập từ 3 đến 500 ký tự!',
            },
          ]}
        >
          <TextAreaStyled placeholder="Nhập mô tả" />
        </Form.Item>
        <ButtonForm
          isLoadingButton={isLoadingButton}
          onCancel={onCancel}
          text={data ? 'Cập nhật' : 'Lưu'}
        />
      </Form>
    </Modal>
  )
}
const TextAreaStyled = styled(TextArea)`
  height: 150px !important;
`
