import { DeleteFilled, EditOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Descriptions,
  Popconfirm,
  Spin,
  Switch,
  Typography,
} from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CHANGE_STATUS, IS_ACTIVE, STATUS, TEXT_ACTIVE } from 'utils/constants'
import { AddEditTopicPost } from './components/AddEditTopicPost'
import {
  changeStatusTopic,
  deleteTopic,
  topicDetail,
  updateTopic,
} from './TopicPostApi'
import { notificationError, notificationSuccess } from 'utils/notification'
import styled from 'styled-components'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'
const { Text } = Typography

const CardStyled = styled(Card)`
  background-color: #f6f9ff;
  border-color: #1890ff;
  border-top: none;
`

interface IProps {
  dataMe: boolean
  handleCallback: () => void
  data: any
  getData: any
}
function TopicPostDetail({ data, getData, handleCallback, dataMe }: IProps) {
  const [showEditAccount, setShowEditAccount] = useState(false)
  const [detail, setDetail] = useState({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const onChangeStatus = async () => {
    try {
      setIsLoading(true)
      await changeStatusTopic(data.id)
      notificationSuccess(`Cập nhật trạng thái thành công`)
      getData()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteTopic = async () => {
    try {
      await deleteTopic({ id: [data.id] })
      notificationSuccess(`Xóa chủ đề thành công`)
      getData()
    } catch (error) {
      notificationError('Đã có lỗi xảy ra, vui lòng thử lại sau')
    }
  }

  const getDetail = async () => {
    setIsLoading(true)
    try {
      const response = await topicDetail(data.id)
      setDetail(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getDetail()
  }, [])

  const ContentView = () => {
    return (
      <Spin spinning={isLoading}>
        <Descriptions size="default" column={3} title="Thông tin chủ đề">
          <Descriptions.Item label="Tên chủ đề">{data.name}</Descriptions.Item>
          <Descriptions.Item label="Thứ tự hiển thị">
            {data.order}
          </Descriptions.Item>
          <Descriptions.Item label="Số người quan tâm">
            {formatPrice(data.count_care)}
          </Descriptions.Item>

          <Descriptions.Item label="Trạng thái">
            {data?.status ? TEXT_ACTIVE.ACTIVE : TEXT_ACTIVE.INACTIVE || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="Ngày tạo">
            {moment(data.create_at).format('DD-MM-YYYY') || '---'}
          </Descriptions.Item>
          <Descriptions.Item label="Số bài viết">
            {formatPrice(data.count_post)}
          </Descriptions.Item>

          <Descriptions.Item label="Mô tả">
            {data.description || '---'}
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    )
  }
  return (
    <div>
      {showEditAccount && (
        <AddEditTopicPost
          handleCallback={handleCallback}
          data={data}
          visible={showEditAccount}
          onCancel={() => {
            setShowEditAccount(false)
          }}
          isLoadingButton={false}
          getData={getData}
        />
      )}

      <CardStyled
        actions={[
          <>
            {dataMe && (
              <>
                <Switch
                  checked={data.status == IS_ACTIVE.ACTIVE}
                  onChange={onChangeStatus}
                />
                ,
                {data.status ? (
                  <TextStyled color>Đang hoạt động</TextStyled>
                ) : (
                  <TextStyled>Ngừng hoạt động</TextStyled>
                )}
              </>
            )}
          </>,

          <>
            {dataMe && (
              <Button
                onClick={() => {
                  setShowEditAccount(true)
                }}
                type="text"
                size="large"
                icon={<EditOutlined />}
                style={{ color: '#1890ff' }}
              >
                Chỉnh sửa
              </Button>
            )}
          </>,
          <>
            {dataMe && (
              <Popconfirm
                title={'Bạn chắc chắn muốn xoá chủ đề này'}
                onConfirm={handleDeleteTopic}
                okText="Xoá"
                cancelText="Quay lại"
                okButtonProps={{
                  danger: true,
                  type: 'primary',
                }}
              >
                <Button
                  type="text"
                  size="large"
                  icon={<DeleteFilled />}
                  style={{ color: 'red' }}
                >
                  Xoá chủ đề
                </Button>
              </Popconfirm>
            )}
          </>,
        ]}
      >
        {ContentView()}
      </CardStyled>
    </div>
  )
}
const TextStyled = styled(Text)<any>`
  font-weight: 600;
  padding-left: 10px;
  color: ${props => (props.color ? '#00abba' : '#f73152')};
`
export default TopicPostDetail
