import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import LoadingProgress from 'common/components/LoadingProgress'
import { SESSION_ID } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import history from 'utils/history'
import R from 'utils/R'
import { requestLogin } from './AuthApi'
import './authStyle.css'
import imageBackground from 'assets/images/img_background.jpg'
import imageLogo from 'assets/images/img_logo.png'

function Login(props: any) {
    const [isLoading, setLoading] = useState(false)
    // const [isActiveModalWarn, setIsActiveModalWarn] = useState<boolean>(false)
    // const [isActiveCheckbox, setIsActiveCheckbox] = useState<boolean>(false)
    // const [shopId, setShopId] = useState<number>(0)
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const onFinish = async (values: any) => {
        try {
            setLoading(true)
            const resLogin = await requestLogin({
                password: values.password,
                phone_number: values.username.trim(),
            })

            if (resLogin.data.df_type_user_id === 3) {
                message.error('Vui lòng không đăng nhập tài khoản khách hàng trên web quản trị!')
                setLoading(false)
            } else {
                Cookie.set(SESSION_ID, resLogin.data.token, {
                    expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
                })
                dispatch(getUserInfoAction())
                if (resLogin.data.shop_id == null) {
                    history.replace('/ad-dash-board')
                } else {
                    history.replace('/dash-board')
                }
                // window.location.reload()
            }
            //check đồng bộ
            // if (resLogin.data.Shop) {
            //   setShopId(resLogin.data.id)
            //   if (resLogin.data.Shop.pancake_shop_id) {
            //     history.replace('/')
            //   } else {
            //     const check = arrShopWarn.indexOf(resLogin.data.id)
            //     if (check !== -1) {
            //       setLoading(false)
            //       history.replace('/')
            //     } else {
            //       setLoading(false)
            //       setIsActiveModalWarn(true)
            //     }
            //   }
            // } else {
            //   history.replace('/')
            // }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <div className="login">
            <div className="login_image">
                <img
                    src={imageBackground}
                    alt="logo"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        filter: 'blur(2px)',
                        backgroundColor: 'white',
                    }}
                />
            </div>
            <div className="login_image" style={{ backgroundColor: 'black', opacity: 0.5 }}></div>

            <div className="container_login">
                <img
                    alt=""
                    src={imageLogo}
                    style={{
                        width: '80%',
                        height: 'auto',
                        margin: '20px 0',
                    }}
                />
                <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true, username: null, password: null }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            { required: true, message: R.strings().please_enter_account },
                            // { pattern: REG_PHONE, message: 'Số điện thoại không hợp lệ' },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder={R.strings().account}
                        />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: R.strings().please_enter_pass }]}>
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder={R.strings().password}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            style={{
                                maxWidth: 200,
                                minWidth: 150,
                            }}
                        >
                            Đăng nhập
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            {isLoading && <LoadingProgress />}
        </div>
    )
}

export default Login
