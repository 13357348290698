import Icon from '@ant-design/icons'
import { Col, DatePicker, Input, Row, Table } from 'antd'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import React, { memo } from 'react'
import styled from 'styled-components'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'
import AddNoteModal from './AddNoteModal'
import { getDebtNote } from '../../CustomerAPI'
import { useDebounce } from 'common/hooks/Debounce'
import { IFormatedPaging } from '../../CustomerInterfaces'

interface IDebtNoteHistory {
    dataCustomer: any
    isSaleManager: boolean
}

const DebtNoteHistory = (props: IDebtNoteHistory) => {
    const { dataCustomer, isSaleManager } = props
    const columns = [
        {
            title: <b>STT</b>,
            width: 100,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Nội dung</b>,
            dataIndex: 'note',
        },
        {
            title: <b>Tên người tạo</b>,
            dataIndex: 'name',
            render: (_: any, record: any) => record?.admin?.full_name,
        },
        {
            title: <b>Số điện thoại</b>,
            dataIndex: 'phone_number',
            render: (_: any, record: any) => record?.admin?.phone_number,
        },

        {
            title: <b>Thời gian tạo</b>,
            dataIndex: 'time',
            render: (_: any, record: any) => momentToStringDate(record?.createdAt),
        },
    ]

    const [visibe, setVisible] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [search, setSearch] = React.useState<string>('')
    const [fromDate, setFromDate] = React.useState<string>('')
    const [toDate, setToDate] = React.useState<string>('')
    const [data, setData] = React.useState<any[]>([])

    const searchDebounce = useDebounce(search, 300)

    const [paging, setPaging] = React.useState<IFormatedPaging>({
        total: 0,
        current: 1,
        pageSize: 0,
    })

    const getData = async () => {
        try {
            setLoading(true)
            const payload = {
                search: searchDebounce,
                from_date: fromDate,
                to_date: toDate,
                page: paging.current,
                limit: paging.pageSize,
            }
            const res: any = await getDebtNote(dataCustomer?.id, payload)
            if (res?.status) {
                setData(res?.data)
                const newPaging = {
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getData()
    }, [paging.current])

    React.useEffect(() => {
        if (paging.current === 1) {
            getData()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [searchDebounce, fromDate, toDate, dataCustomer?.id])

    return (
        <Container>
            {visibe && (
                <AddNoteModal getData={getData} dataCustomer={dataCustomer} visible={visibe} setVisible={setVisible} />
            )}
            <Row className="row">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Input.Search
                            allowClear
                            className="search"
                            style={{ width: '100%' }}
                            value={search}
                            placeholder="Tên người tạo, số điện thoại"
                            addonAfter={<Icon type="close-circle-o" />}
                            onChange={(e: any) => setSearch(e.target.value)}
                        />
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <DatePicker.RangePicker
                            style={{ width: '100%' }}
                            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                            format={'DD-MM-YYYY'}
                            onChange={(value, dateString) => {
                                setFromDate(dateString[0].split('-').reverse().join('-'))
                                setToDate(dateString[1].split('-').reverse().join('-'))
                            }}
                        />
                    </Col>
                </Row>
                {!isSaleManager && (
                    <div className="button_block">
                        <ButtonAdd onClickButton={() => setVisible(true)} text="Thêm ghi chú" />,
                    </div>
                )}
            </Row>
            <Row justify="space-between" style={{ marginTop: 20 }}>
                <p>
                    Kết quả lọc:{' '}
                    <span>
                        <b>{formatPrice(paging.total)}</b>
                    </span>
                </p>
            </Row>
            <Table
                bordered
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 800,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: 'Chưa có bản ghi nào!',
                }}
                // pagination={{
                //     ...pagingDetail,
                //     showSizeChanger: false,
                //     onChange: page => {
                //         setPagingDetail({ ...pagingDetail, current: page })
                //     },
                // }}
            />
        </Container>
    )
}

const Container = styled.div`
    width: 100%;

    .row {
        width: 100%;
        justify-content: space-between;
    }
`

export default memo(DebtNoteHistory)
