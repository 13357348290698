import { Col, DatePicker, Divider, Row, Select, Table, Tag, Empty } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { ADMIN_ROUTER_PATH,  } from 'common/config'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  PRODUCT_STATUS,
  PRODUCT_STOCK_STATUS,
  STATUS,
  TAB_SHOP,
} from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { getDetail, getCategory } from '../ShopApi'

const Option = Select
const { RangePicker } = DatePicker

type Props = {
  id_shop: number
}

export default function ProductShop({ id_shop }: Props) {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState<Array<any>>([])
  const [categoryList, setCategoryList] = useState<Array<any>>([])
  const [categorySubList, setCategorySubList] = useState([])
  const [stockList, setStockList] = useState<Array<any>>([])
  const [selectedCategorySubId, setSelectedCategorySubId] = useState<string>()
  const [params, setParams] = useState<any>({
    id: id_shop,
    data: {
      type: TAB_SHOP.PRODUCT,
      search: '',
      status: null,
      category_id: 0,
      children_category_id: 0,
      stock_id: null,
      from_date: null,
      to_date: null,
      order_status: null,
      page: 1,
    },
  })
  useEffect(() => {
    sessionStorage.setItem('key', '2')
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const [paramsCategory, setParamsCategory] = useState({ page: 1, search: '' })
  const [paramsCategorySub, setParamsCategorySub] = useState({
    page: 1,
    search: '',
    parent_id: '',
  })

  const getListProductShop = async () => {
    setIsLoading(true)
    try {
      console.log('params: ', params)
      const res = await getDetail(params)
      setDataTable(res.data.rows)
      setStockList(res.data.listStock)
      setPaging({
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      setIsSearchLoading(false)
    }
  }

  useEffect(() => {
    getListProductShop()
  }, [params])

  //lấy danh sách danh mục cha
  const getCategoryList = async () => {
    setIsLoading(true)
    try {
      const response = await getCategory(paramsCategory)

      setCategoryList(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCategoryList()
  }, [paramsCategory])

  //lấy danh sách danh mục con
  const getCategorySubList = async () => {
    try {
      if (paramsCategorySub.parent_id) {
        const response = await getCategory(paramsCategorySub)
        setCategorySubList(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCategorySubList()
  }, [paramsCategorySub])

  const columns = [
    {
      width: 70,
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any, index: any) => (
        <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </td>
      ),
      ellipsis: true,
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'code',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
    },
    {
      title: 'Danh mục ',
      dataIndex: 'Category',
      render: (value: any) => (
        <td>
          {value.parent_category?.name}--{value.name}
        </td>
      ),
    },
    {
      title: 'Trạng thái hàng',
      dataIndex: 'stock_status',
      render: (value: any) => {
        return (
          <Tag color={value === 1 ? 'green' : 'volcano'}>
            {value === PRODUCT_STOCK_STATUS.AVAILABLE ? 'CÒN HÀNG' : 'HẾT HÀNG'}
          </Tag>
        )
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value: any) => {
        return (
          <Tag color={value === 1 ? 'green' : 'volcano'}>
            {value === PRODUCT_STATUS.AVAILABLE ? 'HOẠT ĐỘNG' : 'TẠM DỪNG'}
          </Tag>
        )
      },
    },
    {
      width: 100,
      title: 'Đã bán',
      dataIndex: 'total_amount',
    },
    {
      title: 'Doanh số',
      dataIndex: 'total_price',
      render: (value: any) => (
        <td>{value !== '0' ? formatPrice(value) : 0} đ</td>
      ),
    },
  ]

  return (
    <>
      <div>
        <Row gutter={[16, 16]} justify="end" style={{ paddingRight: '15px' }}>
          <Col span={7}>
            <TypingAutoSearch
              onSearchSubmit={(key: string) => {
                setIsSearchLoading(true)
                const data = { ...params.data, search: key.trim(), page: 1 }
                setParams({ ...params, data })
              }}
              isSearchLoading={isSearchLoading}
              placeholder="Tên hoặc mã sản phẩm ..."
            />
          </Col>

          <Col span={4}>
            <Select
              style={{ width: '100%' }}
              allowClear
              optionFilterProp="children"
              loading={isLoading}
              placeholder="DM sản phẩm"
              className="select-shop-category"
              showSearch
              onSearch={(value: string) =>
                setParamsCategory({ ...paramsCategory, search: value, page: 1 })
              }
              onClear={() => {
                setCategorySubList([])
                setSelectedCategorySubId(undefined)
                const data = {
                  ...params.data,
                  category_id: '0',
                  children_category_id: '0',
                }
                console.log('data filter:', data)
                setParams({ ...params, data })
              }}
              onChange={(value: string) => {
                setParamsCategorySub({
                  ...paramsCategorySub,
                  parent_id: value,
                  page: 1,
                })
                const data = {
                  ...params.data,
                  category_id: value,
                  children_category_id: 0,
                  page: 1,
                }
                setParams({ ...params, data })
                setSelectedCategorySubId(undefined)
              }}
            >
              {categoryList.map((item: any, index: number) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col span={4}>
            <Select
              style={{ width: '100%' }}
              allowClear
              optionFilterProp="children"
              loading={isLoading}
              placeholder="DM con sản phẩm"
              className="select-shop-category"
              showSearch
              value={selectedCategorySubId}
              onChange={(value: string) => {
                setSelectedCategorySubId(value)
                const data = {
                  ...params.data,
                  children_category_id: value,
                  page: 1,
                }
                setParams({ ...params, data })
              }}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>Vui lòng chọn danh mục sản phẩm!</span>}
                />
              }
            >
              {categorySubList.map((item: any, index: number) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col span={3}>
            <Select
              placeholder="Trạng thái"
              style={{ width: '100%' }}
              onChange={(value: any) => {
                const data = { ...params.data, status: value, page: 1 }
                setParams({ ...params, data })
              }}
            >
              <Option value="">Tất cả</Option>
              <Option value={STATUS.INACTIVE}>Ngừng hoạt động</Option>
              <Option value={STATUS.ACTIVE}>Đang hoạt động</Option>
            </Select>
          </Col>

          <Col span={4}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Kho hàng"
              style={{ width: '100%' }}
              onChange={(value: any) => {
                const data = { ...params.data, stock_id: value, page: 1 }
                setParams({ ...params, data })
              }}
            >
              {stockList.map((item: any, index: number) => {
                return (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>
        </Row>

        <Divider />

        <div
          style={{
            padding: '5px 10px',
          }}
        >
          <p>
            Kết quả lọc: <b>{formatPrice(paging.total)}</b>
          </p>
          <Table
            className="table-expanded-custom-no-image"
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
              y: 'calc(100vh - 460px)',
            }}
            bordered
            columns={columns}
            dataSource={dataTable}
            loading={isLoading}
            onRow={(record, rowIndex) => ({
              onDoubleClick: () => {
                history.push({
                  pathname: `${ADMIN_ROUTER_PATH.PRODUCT_DETAIL}/${record.id}`,
                  state: record,
                })
              },
            })}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                const data = { ...params.data, page: page }
                setParams({ ...params, data })
              },
            }}
          />
        </div>
      </div>
    </>
  )
}
