import { Button, Col, DatePicker, Input, message, PageHeader, Popconfirm, Row, Select } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { GIFT_STATUS } from 'utils/constants'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { CancelGift, requesGift } from '../giftChangeApi'
import { notificationError, notificationSuccess } from 'utils/notification'
import ModalReject from 'features/admin/flower_delivery/flower_delivery_request/components/ModalReject'
type Props = {
    selects: any
    isSearchLoading: boolean
    onSelected: Array<number>
    getData: any
    data?: any
    onSearchSubmit: (searchKey: string) => void
    onStatusSubmit: (status: number) => void
    onTypeGift: (type_id: number) => void
    onDateSubmit: (from_date: string, to_date: string) => void
}

const { Search } = Input
const { RangePicker } = DatePicker
const { Option } = Select

function HeaderGiftChange({
    selects,
    isSearchLoading,
    onSelected,
    getData,
    data,
    onSearchSubmit,
    onStatusSubmit,
    onDateSubmit,
}: Props) {
    const [isLoading, setIsLoading] = useState({
        refuse: false,
        accept: false,
    })
    const [isShowModal, setIsShowModal] = useState<boolean>(false)

    const handleSubmit = async (record: any) => {
        try {
            const res = await requesGift({ purchase_gifts_id: record })
            const totalConfirm = res?.data?.updated?.length
            const reject = res.data.not_found?.length
            if (totalConfirm > 0) {
                notificationSuccess(`Duyệt thành công  ${totalConfirm} yêu cầu`)
            }
            if (reject > 0) {
                notificationError(`Duyệt thất bại ${reject} yêu cầu`)
            }
            getData()
        } catch (error) {
            console.log(error)
        }
    }
    const updateStatus = async (status: any, reject_note: string) => {
        try {
            const res = await CancelGift({
                purchase_gifts_id: status,
                reject_note: reject_note,
            })
            const totalConfirm = res.data.updated
            const reject = res.data.not_found
            if (reject.length > 0) {
                notificationError(`Từ chối thất bại ${reject.length}  yêu cầu`)
            }
            if (totalConfirm.length > 0) {
                notificationSuccess(`Từ chối thành công ${totalConfirm.length} yêu cầu`)
            }
            getData()
            setIsShowModal(false)
        } catch (error) {}
    }
    return (
        <div>
            <div
                style={{
                    backgroundColor: 'white',
                    margin: '10px 10px',
                }}
            >
                <ModalReject
                    showModal={isShowModal}
                    confirm={(reject_note: string) => {
                        updateStatus(selects, reject_note)
                    }}
                    cancel={() => setIsShowModal(false)}
                />
                <PageHeader
                    title="Yêu cầu đổi quà "
                    extra={[
                        <Row gutter={[16, 16]}>
                            <Col>
                                <TypingAutoSearch
                                    placeholder="Tìm kiếm tên khách hàng"
                                    onSearchSubmit={(value: string) => {
                                        onSearchSubmit(value)
                                    }}
                                />
                            </Col>
                            <Col>
                                <Select
                                    allowClear
                                    className="select-type_account"
                                    placeholder="Trạng thái"
                                    style={{ minWidth: '130px' }}
                                    onChange={(value: number) => {
                                        onStatusSubmit(value)
                                    }}
                                >
                                    <Option value={GIFT_STATUS.PENDING}>Đang chờ</Option>
                                    <Option value={GIFT_STATUS.CONFIRMED}>Đã xác nhận</Option>
                                    <Option value={GIFT_STATUS.SENT_GIFT}>Đã gửi</Option>
                                    <Option value={GIFT_STATUS.USED}>Đã sử dụng</Option>
                                    <Option value={GIFT_STATUS.CANCEL}>Từ chối</Option>
                                </Select>
                            </Col>
                            <Col>
                                <RangePicker
                                    placeholder={['Từ ngày', 'Đến ngày']}
                                    onChange={(selectTime, dateString) => {
                                        onDateSubmit(dateString[0], dateString[1])
                                    }}
                                />
                            </Col>
                            <Col>
                                {selects.length !== 0 ? (
                                    <Button danger onClick={() => setIsShowModal(true)}>
                                        <CloseCircleOutlined /> Từ chối
                                    </Button>
                                ) : null}
                            </Col>
                            <Col>
                                {selects.length !== 0
                                    ? [
                                          <Popconfirm
                                              placement="topRight"
                                              title="Phê duyệt cho yêu cầu này?"
                                              onConfirm={() => handleSubmit(selects)}
                                              onCancel={() => {}}
                                              okText="Phê duyệt"
                                              cancelText="Quay lại"
                                          >
                                              <Button type="primary">
                                                  <CheckCircleOutlined />
                                                  Phê duyệt
                                              </Button>
                                              ,
                                          </Popconfirm>,
                                      ]
                                    : null}
                            </Col>
                        </Row>,
                    ]}
                />
            </div>
        </div>
    )
}
export default HeaderGiftChange
