import { DatePicker, Form, Input, Modal, Radio, Row, Select, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import ButtonForm from 'common/components/ModalButton'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import { getProvinces } from 'features/admin/customer-clone/CustomerAPI'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { toNonAccentVietnamese } from 'utils/Configs'
import { getListCustomerGroup } from '../service'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

interface IAddEditBusinessStaffModal {
    isModalOpen: boolean
    listKiotviet?: IKiotviet[]
    currentRecord: any
    isAdd: boolean | undefined
    handleOk: (values: any) => void
    loading: boolean
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddEditBusinessStaffModal = (props: IAddEditBusinessStaffModal) => {
    const [form] = useForm()
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN
    const { isModalOpen, handleOk, setIsModalOpen, listKiotviet, currentRecord, isAdd, loading } = props

    const [provinces, setProvinces] = React.useState<any[]>([])
    const [customerGroup, setCustomerGroup] = React.useState<any[]>([])
    const [areaId, setAreaId] = React.useState<number>(currentRecord?.kiotviet_id)

    const newListKiotViet = isShopAdmin
        ? listKiotviet?.filter((item: any) => item?.id === userInfor?.admin?.kiotviet_id)
        : listKiotviet

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const getListProvinces = async () => {
        try {
            const res: any = await getProvinces()
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setProvinces(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onGetListCustomerGroup = async (selectedKiotVietId: number) => {
        try {
            const payload = {
                page: 1,
                limit: 999,
                kiotvietId: selectedKiotVietId,
            }
            const res = await getListCustomerGroup(payload)
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setCustomerGroup(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        getListProvinces()
    }, [])

    React.useEffect(() => {
        if (areaId) {
            onGetListCustomerGroup(areaId)
        }
    }, [areaId])

    return (
        <Modal
            title={!isAdd ? 'Cập nhật nhân viên kinh doanh' : 'Thêm mới nhân viên kinh doanh'}
            visible={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={'60%'}
        >
            <Spin spinning={loading}>
                <Form
                    form={form}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={
                        !isAdd
                            ? {
                                  name: currentRecord?.full_name,
                                  phone: currentRecord?.phone_number,
                                  email: currentRecord?.email,
                                  dob: currentRecord?.date_of_birth ? moment(currentRecord?.date_of_birth) : undefined,
                                  gender: currentRecord?.gender ? currentRecord?.gender : undefined,
                                  province_id: Number(currentRecord?.province_id) || undefined,
                                  area: currentRecord?.kiotviet_id,
                                  customer_group_id: currentRecord?.Group?.id,
                              }
                            : { gender: 'man' }
                    }
                    onFinish={handleOk}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Họ và tên"
                        name="name"
                        rules={[
                            { required: true, message: 'Vui lòng nhập họ và tên!' },
                            { max: 50, type: 'string', message: 'Vui lòng không nhập quá 50 ký tự!' },
                        ]}
                    >
                        <Input placeholder="Nhập họ và tên" allowClear />
                    </Form.Item>

                    <Form.Item
                        label="Số điện thoại"
                        name="phone"
                        rules={[
                            { required: true, message: 'Vui lòng nhập số điện thoại!' },
                            {
                                message: 'Số điện thoại không hợp lệ!',
                                validator: (_, value) => {
                                    if (!value?.toString()?.trim()) return Promise.resolve()
                                    if (isNaN(Number(value))) {
                                        return Promise.reject()
                                    }
                                    if (value.length !== 10) {
                                        return Promise.reject()
                                    }
                                    return Promise.resolve()
                                },
                            },
                        ]}
                    >
                        <Input placeholder="Nhập số điện thoại" allowClear />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                message: 'Email không hợp đúng định dạng!',
                                validator: (_, value) => {
                                    if (!value?.toString()?.trim()) return Promise.resolve()
                                    if (!isNaN(Number(value)) || value.length < 12) {
                                        return Promise.reject()
                                    }
                                    return Promise.resolve()
                                },
                            },
                        ]}
                    >
                        <Input placeholder="Nhập email" allowClear />
                    </Form.Item>

                    <Form.Item
                        label="Khu vực"
                        name="area"
                        rules={[{ required: true, message: 'Vui lòng chọn khu vực!' }]}
                    >
                        <Select
                            placeholder="Chọn địa chỉ"
                            showArrow
                            showSearch
                            filterOption={(input, option: any) => {
                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                return nonAccent.includes(nonAccentInput)
                            }}
                            options={newListKiotViet}
                            onChange={(value: number) => {
                                setAreaId(value)
                                form.setFieldValue('customer_group_id', undefined)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Nhóm khách hàng"
                        name="customer_group_id"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng chọn nhóm khách hàng!',
                            },
                        ]}
                    >
                        <Select
                            disabled={!areaId}
                            placeholder="Nhóm khách hàng"
                            onChange={(region: number) => {}}
                            options={customerGroup}
                        />
                    </Form.Item>

                    <Form.Item label="Ngày sinh" name="dob">
                        <DatePicker
                            disabledDate={(current: any) => current && current > moment().endOf('day')}
                            format={'DD-MM-YYYY'}
                            style={{ width: '100%' }}
                            placeholder="Chọn ngày sinh"
                        />
                    </Form.Item>

                    <Form.Item label="Giới tính" name="gender">
                        <Radio.Group>
                            <Radio value={'man'}>Nam</Radio>
                            <Radio value={'female'}>Nữ</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Địa chỉ" name="province_id">
                        <Select
                            filterOption={(input, option: any) => {
                                const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                return nonAccent.includes(nonAccentInput)
                            }}
                            placeholder="Chọn tỉnh/thành phố"
                            allowClear
                            showArrow
                            showSearch
                            options={provinces}
                        />
                    </Form.Item>

                    <CustomRow justify="end">
                        <ButtonForm onCancel={handleCancel} text={'Lưu'} />
                    </CustomRow>
                </Form>
            </Spin>
        </Modal>
    )
}

const CustomRow = styled(Row)`
    margin-top: 60px;

    .cancel_button {
        margin-right: 16px;
    }
`

export default AddEditBusinessStaffModal
