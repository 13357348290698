import { Col, DatePicker, Input, Row, Select } from 'antd'
import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import moment from 'moment'
import { toNonAccentVietnamese } from 'utils/Configs'

interface IFilter {
    search: string
    categoriesList: any[]
    setSearch: React.Dispatch<React.SetStateAction<string>>
    setProductCategory: React.Dispatch<React.SetStateAction<number | undefined>>
}

const Filter = (props: IFilter) => {
    const { search, setSearch, setProductCategory, categoriesList } = props
    return (
        <CustomRow gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                <Input.Search
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Tên hoặc mã sản phẩm"
                    addonAfter={<Icon type="close-circle-o" />}
                    value={search}
                    onChange={(e: any) => {
                        setSearch(e.target.value)
                    }}
                />
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                <Select
                    className="status_block"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Nhóm sản phẩm"
                    onChange={(value: number | undefined) => setProductCategory(value)}
                    options={categoriesList}
                    showArrow
                    showSearch
                    filterOption={(input, option: any) => {
                        const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                        const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                        return nonAccent.includes(nonAccentInput)
                    }}
                />
            </Col>
        </CustomRow>
    )
}

const CustomRow = styled(Row)`
    .status_block {
        width: 100%;
        margin-bottom: 14px;
    }

    .role_block {
        width: 100%;
    }
`

export default Filter
