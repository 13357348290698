import { Empty, Modal, Pagination, Row, Spin, Tabs, message } from 'antd'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import React from 'react'
import NoteItem from './NoteItem'
import { deleteNote, getListNotes } from '../CustomerAPI'
import styled from 'styled-components'

interface ICustomerNoteModal {
    isVisible: boolean
    setIsVisible: any
    setIsEditModalOpen?: any
    userId?: number
    setActiveTab?: any
    activeTab?: any
    getNotes?: any
    paging?: any
    setPaging?: any
    notes?: any
    loading?: boolean
    setLoading?: any
    setSelectedNote?: any
    isFromOrderDetail?: boolean
}

const CustomerNoteModal = (props: ICustomerNoteModal) => {
    const {
        paging,
        setPaging,
        isVisible,
        setIsVisible,
        setIsEditModalOpen,
        userId,
        setSelectedNote,
        setActiveTab,
        activeTab,
        getNotes,
        notes,
        loading,
        setLoading,
        isFromOrderDetail,
    } = props

    const removeNote = async (noteId: number) => {
        try {
            setLoading(true)
            const res = await deleteNote(noteId)
            if (res?.status) {
                message.success('Xoá ghi chú khách hàng thành công!')
                getNotes()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onChangeTab = (tab: string) => {
        setActiveTab(tab)
        setPaging({ ...paging, current: 1 })
    }

    React.useEffect(() => {
        if (userId) {
            getNotes()
        }
    }, [userId, activeTab])

    return (
        <Modal
            title="Ghi chú khách hàng"
            width={'70%'}
            maskClosable={false}
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            footer={null}
        >
            <Spin spinning={loading}>
                <CustomTab
                    activeKey={activeTab}
                    onChange={onChangeTab}
                    tabBarExtraContent={
                        activeTab === '1' && !isFromOrderDetail ? (
                            <ButtonAdd
                                text="Thêm ghi chú"
                                onClickButton={() => {
                                    setIsEditModalOpen(true)
                                    setSelectedNote(undefined)
                                }}
                                styleButton={{ marginBottom: 10 }}
                            />
                        ) : undefined
                    }
                >
                    <CustomTab.TabPane tab="Admin" key="1">
                        {notes?.length > 0 ? (
                            <>
                                {notes
                                    .slice(
                                        paging.current * paging.pageSize - paging.pageSize,
                                        paging.current * paging.pageSize
                                    )
                                    ?.map((item: any) => (
                                        <NoteItem
                                            key={item?.id}
                                            item={item}
                                            setIsEditModalOpen={setIsEditModalOpen}
                                            isShowEdit={true}
                                            removeNote={() => removeNote(item?.id)}
                                            setSelectedNote={setSelectedNote}
                                            isFromOrderDetail={isFromOrderDetail}
                                        />
                                    ))}
                                <Row justify="end" style={{ marginBottom: 30 }}>
                                    <Pagination
                                        {...paging}
                                        onChange={(page: number) =>
                                            setPaging((prev: any) => ({ ...prev, current: page }))
                                        }
                                    />
                                </Row>
                            </>
                        ) : (
                            <Empty />
                        )}
                    </CustomTab.TabPane>
                    <CustomTab.TabPane tab="Nhân viên kinh doanh" key="2">
                        {notes?.length > 0 ? (
                            <>
                                {notes
                                    .slice(
                                        paging.current * paging.pageSize - paging.pageSize,
                                        paging.current * paging.pageSize
                                    )
                                    ?.map((item: any) => (
                                        <NoteItem
                                            key={item?.id}
                                            item={item}
                                            setIsEditModalOpen={setIsEditModalOpen}
                                            isShowEdit={false}
                                            removeNote={() => removeNote(item?.id)}
                                            setSelectedNote={setSelectedNote}
                                            isFromOrderDetail={isFromOrderDetail}
                                        />
                                    ))}
                                <Row justify="end" style={{ marginBottom: 30 }}>
                                    <Pagination
                                        {...paging}
                                        onChange={(page: number) =>
                                            setPaging((prev: any) => ({ ...prev, current: page }))
                                        }
                                    />
                                </Row>
                            </>
                        ) : (
                            <Empty />
                        )}
                    </CustomTab.TabPane>
                </CustomTab>
            </Spin>
        </Modal>
    )
}

const CustomTab = styled(Tabs)`
    .ant-tabs-content-holder {
        overflow: auto;
        max-height: 500px;
    }
`

export default CustomerNoteModal
