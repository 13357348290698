import {
  CheckCircleOutlined,
  CloudSyncOutlined,
  EditOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons'
import { Button, message, PageHeader, Popconfirm } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useState, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { notificationError } from 'utils/notification'
import { changeStatus } from 'features/admin/product_clone/StatusSlice'

import { editProduct } from '../ProductAPI'

interface IProps {
  onLocation: any
}

function HeaderDetail(props: IProps) {
  const location = props.onLocation
  const history = useHistory()
  const dispatch = useDispatch()
  const statusProduct = useSelector((state: any) => state.statusReducer?.status)

  const handleChangeStatus = async (status: number) => {
    try {
      const res = await editProduct({
        id: location.state?.id,
        body: {
          status,
        },
      })
      dispatch(changeStatus(status))
      message.success('Thay đổi thành công')
    } catch (error) {
      console.log(error)
      notificationError('Có lỗi xảy ra')
    }
  }

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Chi tiết sản phẩm"
        onBack={() =>
          history.push({
            pathname: location.state?.path,
            state: {
              paging: location.state?.paging,
              id: location.state?.order_id,
              paramsFilter: location.state?.paramsFilter,
            },
          })
        }
        style={{
          backgroundColor: 'white',
          margin: '10px',
        }}
        extra={[
          <Button
            onClick={() => {
              history.push({
                pathname: `${ADMIN_ROUTER_PATH.EDIT_PRODUCT}/${location.state?.id}`,
              })
            }}
          >
            <EditOutlined />
            Sửa sản phẩm
          </Button>,
          statusProduct ? (
            <Button
              danger
              onClick={() => {
                handleChangeStatus(0)
              }}
            >
              <PauseCircleOutlined />
              Ngừng hoạt động
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                handleChangeStatus(1)
              }}
            >
              <CheckCircleOutlined />
              Hoạt động
            </Button>
          ),
        ]}
      ></PageHeader>
    </div>
  )
}

export default memo(HeaderDetail)
