import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IStatusAccountState {
  status: number
}

const initialState: IStatusAccountState = {
  status: 0,
}

export const StatusReducer = createSlice({
  name: 'status',
  initialState,
  reducers: {
    changeStatus: (state,action) => {
      state.status = action.payload
    }
  },
})

export const {
  changeStatus
} = StatusReducer.actions
export default StatusReducer.reducer
