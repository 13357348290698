import { Checkbox, Col, Form, Input, Row, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import SunEditorComponent from 'common/components/editor'
import ButtonForm from 'common/components/ModalButton'
import PageHeader from 'common/components/pageHeader'
import UploadComponent from 'common/components/Upload'
import { ADMIN_ROUTER_PATH } from 'common/config'
import style from 'config/style'
import { getData } from 'features/admin/config-clone/GetDataSlice'
import { uniqueId } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { IS_ACTIVE, IS_CHECKED, IS_TYPE_NEWS } from 'utils/constants'
import { notificationError, notificationSuccess } from 'utils/notification'
import '../Css/StyleAddEdit.css'
import { createNews, updateNews } from '../NewsAPI'

const { Option } = Select
const WapperForm = styled.div`
  padding: 10px;
  background-color: white;
  margin: 0 10px 10px 10px;
`

const FormLayoutStyled = styled.div`
  width: 100%;
  padding: 20px 15px;
  border: ${style.border};
`
interface IPropsNews {
  location?: any
  getData?: any
}

const NewsAddEdit = (props: IPropsNews) => {
  const history = useHistory()
  const [form] = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isAllSpace, setIsAllSpace] = useState(false)
  const dispatch = useDispatch()

  const [upload, setUpload] = useState({
    loading: false,
    imageUrl: '',
  })

  const onCancel = () =>
    history.push({
      pathname: ADMIN_ROUTER_PATH.NEWS,
      state: { data: '' },
    })

  useEffect(() => {
    if (props.location?.state?.data) {
      setUpload({
        ...upload,
        imageUrl: props.location?.state?.data.image_url,
      })
    }
  }, [props.location?.state?.data])

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true)
      values.is_banner
        ? (values.is_banner = IS_CHECKED.SHOW)
        : (values.is_banner = IS_CHECKED.HIDE)
      values.is_home
        ? (values.is_home = IS_CHECKED.SHOW)
        : (values.is_home = IS_CHECKED.HIDE)
      let newData
      if (!props.location?.state?.data) {
        newData = {
          ...values,
          image_url: values.image_url,
        }

        try {
          const res = await createNews(newData)
          notificationSuccess('Thêm tin tức thành công')
          setUpload({
            loading: false,
            imageUrl: '',
          })
          form.resetFields()
          onCancel()
          props.location?.state?.getData()
        } catch (error) {
          notificationError('Thêm tin tức thất bại')
        }
      } else {
        newData = {
          id: props.location?.state?.data.id,
          data: { ...values, image_url: values.image_url },
        }
        const res = await updateNews(newData)
        notificationSuccess('Cập nhật tin tức thành công')
        setUpload({
          loading: false,
          imageUrl: '',
        })
        form.resetFields()
        onCancel()
        // props.location?.state?.getData()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <PageHeader
        onBack={() =>
          history.push({
            pathname: ADMIN_ROUTER_PATH.NEWS,
            state: { data: '' },
          })
        }
        title={
          props.location?.state?.data ? 'Chỉnh sửa tin tức' : 'Thêm mới tin tức'
        }
      />

      <WapperForm>
        <FormLayoutStyled>
          <Form
            onFinish={(values: any) => {
              onFinish(values)
            }}
            form={form}
            initialValues={
              props.location?.state?.data ? props.location?.state?.data : null
            }
          >
            <Row justify="space-between">
              <Col xxl={10} xl={10} md={9} xs={24}>
                <Form.Item
                  label="Tiêu đề"
                  name={'title'}
                  wrapperCol={style.layoutModal.wrapperCol}
                  labelCol={style.layoutModal.labelCol}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập tên tiêu đề',
                      validator: (_, value) => {
                        if (value.trim() === '') {
                          return Promise.reject()
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <Input placeholder="Tiêu đề" />
                </Form.Item>

                {props.location?.state?.data ? (
                  <Form.Item
                    label="Trạng thái"
                    name={'status'}
                    wrapperCol={style.layoutModal.wrapperCol}
                    labelCol={style.layoutModal.labelCol}
                  >
                    <Select placeholder="Trạng thái">
                      <Option value={IS_ACTIVE.ACTIVE}>Đang hoạt động</Option>
                      <Option value={IS_ACTIVE.INACTIVE}>
                        Ngừng hoạt động
                      </Option>
                    </Select>
                  </Form.Item>
                ) : (
                  <> </>
                )}

                <Form.Item
                  name="image_url"
                  label="Hình ảnh"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <UploadComponent
                    isUploadServerWhenUploading
                    uploadType="list"
                    listType="picture-card"
                    maxLength={1}
                    onSuccessUpload={(file: any) => {
                      // setFile(file?.url)
                      form.setFieldsValue({ image_url: file?.url })
                    }}
                    isShowFileList
                    initialFile={
                      props.location?.state?.data.image_url && [
                        {
                          url: props.location?.state?.data.image_url,
                          uid: uniqueId(),
                          name: 'avatar',
                        },
                      ]
                    }
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name={'is_banner'}
                  valuePropName="checked"
                  wrapperCol={style.layoutModal.wrapperCol}
                  labelCol={style.layoutModal.labelCol}
                >
                  <Checkbox>
                    <strong style={{ fontSize: '15px' }}>Banner</strong>
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xxl={10} xl={10} md={9} xs={24}>
                <Form.Item
                  label="Phân loại"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn phân loại',
                    },
                  ]}
                  name={'df_type_news_id'}
                  wrapperCol={style.layoutModal.wrapperCol}
                  labelCol={style.layoutModal.labelCol}
                >
                  <Select
                    placeholder="Phân loại"
                    disabled={
                      props.location?.state?.data.df_type_news_id ===
                      IS_TYPE_NEWS.DRAF
                    }
                  >
                    <Option value={IS_TYPE_NEWS.DRAF}>Bài đăng</Option>
                    <Option value={IS_TYPE_NEWS.INDRAF}>Lưu nháp</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name={'description'}
                  label="Mô tả"
                  rules={[
                    {
                      type: 'string',
                      message: 'Nhập mô tả',
                    },
                    {
                      message: 'Vui lòng nhập mô tả!',
                      validator: (_, value) => {
                        if (value.trim() === '') {
                          return Promise.reject()
                        }
                        return Promise.resolve()
                      },
                    },
                    {
                      min: 3,
                      max: 500,
                      message: 'Vui lòng nhập từ 3 đến 500 ký tự!',
                    },
                  ]}
                >
                  <TextAreaStyled placeholder="Nhập mô tả" />
                </Form.Item>
                <Form.Item
                  label=""
                  name={'is_home'}
                  valuePropName="checked"
                  wrapperCol={style.layoutModal.wrapperCol}
                  labelCol={style.layoutModal.labelCol}
                >
                  <Checkbox>
                    <strong style={{ fontSize: '15px' }}>
                      Hiển thị ngoài trang chủ
                    </strong>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name={'content'}>
              <SunEditorComponent
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng viết nội dung',
                    validator: (_, value) => {
                      if (value.trim() === '') {
                        return Promise.reject()
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
                label={'Nội dung'}
                name={'content'}
                form={form}
                loading={true}
                setIsAllSpace={setIsAllSpace}
              />
            </Form.Item>
            <ButtonForm onCancel={onCancel} text="Lưu" />
          </Form>
        </FormLayoutStyled>
      </WapperForm>
    </>
  )
}

export default NewsAddEdit
const TextAreaStyled = styled(TextArea)`
  height: 100px !important;
`
