import { ADMIN_ROUTER_PATH } from 'common/config'
import AdConfig from 'features/admin/config-clone'
import AdDetailConfig from 'features/admin/config-clone/component/DetailConfig'
import index from 'features/admin/customer-clone/index'
import AdDashBoard from 'features/admin/dashboard/'
import AdGiftChange from 'features/admin/gift/giftChange'
import AdGift from 'features/admin/gift/listGift'
import AdLiveStream from 'features/admin/live-stream'
import AdLiveStreamDetail from 'features/admin/live-stream/components/LivestreamDetail'
import AdCommunityPost from 'features/admin/post/communityPost'
import AddEditPost from 'features/admin/post/communityPost/components/AddEditPost'
import DetailPost from 'features/admin/post/communityPost/DetailPost'
import AdTopicPost from 'features/admin/post/topicPost/TopicPosts'
import AdLiveStreamReport from 'features/admin/report/liveStreamReport'
import AdSaleReport from 'features/admin/report/salesReport'
import AdShopReport from 'features/admin/report/shopReport'
import AdService from 'features/admin/service/Services'
import AdShop from 'features/admin/shop'
import AdShopDetail from 'features/admin/shop/ShopDetail'
import OrderMange from 'features/admin/order-clone/Orders'
import OrderDetailClone from 'features/admin/order-clone/OrderDetail'
import AdProductClone from 'features/admin/product_clone/Products/Products'
import AdProductDetailClone from 'features/admin/product_clone/Products/ProductDetail'
import AdCategoryProductClone from 'features/admin/product_clone/Category/Categories'
import EditProduct from 'features/admin/product_clone/Products/component/EditProduct'
import AdAccount from 'features/admin/account/Accounts'
// import AdFlowerDeliveryList from 'features/admin/flower_delivery/flower_delivery_list/index'
import AdFlowerDeliveryRequest from 'features/admin/flower_delivery/flower_delivery_request/index'
import AdFlowerDeliveryDetail from 'features/admin/flower_delivery/flower_delivery_request/components/FlowerDeliveryDetail'
import AdDetailDelt from 'features/admin/customer-clone/component/DetailCustomer/DetailDelt'
import AdNews from 'features/admin/post/news'
import AdNewsAddEdit from 'features/admin/post/news/components/AddEditNews'
import AdChat from 'features/admin/chat'
import AreaReport from 'features/admin/report/area_report/page'
import BranchReport from 'features/admin/report/branch_report/page'
import TemporaryOrderReport from 'features/admin/report/temporary_order_report/page'
import ShipperManage from 'features/admin/shipper/page'
import IndividualCustomer from 'features/admin/customer-clone/component/IndividualCustomer'
import RefundOrder from 'features/admin/refund-order/page'
import AddEditRefundOrder from 'features/admin/refund-order/page/AddEditRefundOrder'
import InventoryStaff from 'features/admin/inventory_staff/page'
import BusinessStaff from 'features/admin/business_staff/page'
import OrderCancelReport from 'features/admin/report/order_cancel_report/page'
import OrderWrapReport from 'features/admin/report/order_wrap_report/page'
import CommentGood from 'features/admin/comment_good/page'
import PolicyPage from 'features/admin/policy/page'
import AddEditPolicy from 'features/admin/policy/page/AddEditPolicy'
import OrderCancelReportDetail from 'features/admin/report/order_cancel_report/page/OrderCancelReportDetail'
import CommentGoodDetail from 'features/admin/comment_good/page/CommentGoodDetail'
interface RouterProps {
    path: string
    component: React.FC | any
    param?: any
    exact?: boolean
}

const adminRouter: Array<RouterProps> = [
    {
        path: ADMIN_ROUTER_PATH.FLOWER_DELIVERY_DETAIL + '/:id',
        component: AdFlowerDeliveryDetail,
    },
    {
        path: ADMIN_ROUTER_PATH.FLOWER_DELIVERY_REQUEST,
        component: AdFlowerDeliveryRequest,
    },
    {
        path: ADMIN_ROUTER_PATH.ORDER_CANCEL_REPORT_DETAIL,
        component: OrderCancelReportDetail,
    },
    {
        path: ADMIN_ROUTER_PATH.COMMENT_GOOD_DETAIL,
        component: CommentGoodDetail,
    },
    {
        path: ADMIN_ROUTER_PATH.POLICY,
        component: PolicyPage,
    },
    {
        path: ADMIN_ROUTER_PATH.ADD_EDIT_POLICY,
        component: AddEditPolicy,
    },
    {
        path: ADMIN_ROUTER_PATH.COMMENT_GOOD,
        component: CommentGood,
    },
    {
        path: ADMIN_ROUTER_PATH.ORDER_WRAP_REPORT,
        component: OrderWrapReport,
    },
    {
        path: ADMIN_ROUTER_PATH.ORDER_CANCEL_REPORT,
        component: OrderCancelReport,
    },
    {
        path: ADMIN_ROUTER_PATH.BUSINESS_STAFF,
        component: BusinessStaff,
    },
    {
        path: ADMIN_ROUTER_PATH.DASH_BOARD,
        component: AdDashBoard,
    },
    {
        path: ADMIN_ROUTER_PATH.INVENTORY_STAFF,
        component: InventoryStaff,
    },
    {
        path: ADMIN_ROUTER_PATH.SHOP,
        component: AdShop,
    },
    {
        path: ADMIN_ROUTER_PATH.REFUND_ORDER,
        component: RefundOrder,
    },
    {
        path: ADMIN_ROUTER_PATH.SHOP_DETAIL + '/:id',
        component: AdShopDetail,
    },
    {
        path: ADMIN_ROUTER_PATH.PRODUCT,
        component: AdProductClone,
    },
    {
        path: ADMIN_ROUTER_PATH.ADD_EDIT_REFUND_ORDER,
        component: AddEditRefundOrder,
    },
    {
        path: ADMIN_ROUTER_PATH.EDIT_PRODUCT + '/:id',
        component: EditProduct,
    },
    {
        path: ADMIN_ROUTER_PATH.PRODUCT_DETAIL + '/:id',
        component: AdProductDetailClone,
    },
    {
        path: ADMIN_ROUTER_PATH.CATEGORY_PRODUCT,
        component: AdCategoryProductClone,
    },
    {
        path: ADMIN_ROUTER_PATH.CUSTOMER,
        component: index,
    },
    {
        path: ADMIN_ROUTER_PATH.ORDER,
        component: OrderMange,
    },
    {
        path: ADMIN_ROUTER_PATH.ORDER_DETAIL + '/:id',
        component: OrderDetailClone,
    },
    {
        path: ADMIN_ROUTER_PATH.LIVE_STREAM,
        component: AdLiveStream,
    },
    {
        path: ADMIN_ROUTER_PATH.LIVE_STREAM_DETAIL + '/:id',
        component: AdLiveStreamDetail,
    },
    // {
    //   path: ADMIN_ROUTER_PATH.SERVICE_PACK,
    //   component: AdService,
    // },
    {
        path: ADMIN_ROUTER_PATH.TOPIC_POST,
        component: AdTopicPost,
    },
    {
        path: ADMIN_ROUTER_PATH.COMMUNITY_POST,
        component: AdCommunityPost,
    },
    {
        path: ADMIN_ROUTER_PATH.NEWS,
        component: AdNews,
    },
    {
        path: ADMIN_ROUTER_PATH.NEWS_EDIT + '/:id',
        component: AdNewsAddEdit,
    },
    {
        path: ADMIN_ROUTER_PATH.NEWS_ADD,
        component: AdNewsAddEdit,
    },
    {
        path: ADMIN_ROUTER_PATH.COMMUNITY_POST + '/:id',
        component: DetailPost,
    },
    {
        path: ADMIN_ROUTER_PATH.GIFT_CHANGE,
        component: AdGiftChange,
    },
    {
        path: ADMIN_ROUTER_PATH.GIFT_CHANGE + '/:key/:id',
        component: AdGiftChange,
    },
    {
        path: ADMIN_ROUTER_PATH.GIFT,
        component: AdGift,
    },
    {
        path: ADMIN_ROUTER_PATH.SALES_REPORT,
        component: AdSaleReport,
    },
    {
        path: ADMIN_ROUTER_PATH.AREA_REPORT,
        component: AreaReport,
    },
    {
        path: ADMIN_ROUTER_PATH.BRANCH_REPORT,
        component: BranchReport,
    },
    {
        path: ADMIN_ROUTER_PATH.TEMPORARY_ORDER_REPORT,
        component: TemporaryOrderReport,
    },
    {
        path: ADMIN_ROUTER_PATH.SHIPPER,
        component: ShipperManage,
    },
    {
        path: ADMIN_ROUTER_PATH.INDIVIDUAL_CUSTOMER,
        component: IndividualCustomer,
    },
    {
        path: ADMIN_ROUTER_PATH.CHAT,
        component: AdChat,
    },
    {
        path: ADMIN_ROUTER_PATH.CHAT + '/:id',
        component: AdChat,
    },
    {
        path: ADMIN_ROUTER_PATH.LIVE_STREAM_REPORT,
        component: AdLiveStreamReport,
    },
    {
        path: ADMIN_ROUTER_PATH.SHOP_REPORT,
        component: AdShopReport,
    },
    {
        path: ADMIN_ROUTER_PATH.CONFIG,
        component: AdConfig,
    },
    {
        path: ADMIN_ROUTER_PATH.ACCOUNTS,
        component: AdAccount,
    },
    {
        path: ADMIN_ROUTER_PATH.CONFIG_DETAIL + '/:id',
        component: AdDetailConfig,
    },
    {
        path: ADMIN_ROUTER_PATH.ADD_POST,
        component: AddEditPost,
    },
    {
        path: ADMIN_ROUTER_PATH.EDIT_POST + '/:id',
        component: AddEditPost,
    },

    {
        path: ADMIN_ROUTER_PATH.DETAIL_DELT + '/:id',
        component: AdDetailDelt,
    },
]

export default adminRouter
