import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Descriptions, Divider, Row, Select, Spin, Switch, Table } from 'antd'
import ButtonFixed from 'common/components/ButtonFixed'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { PAKAGE_CATEGORY } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { addPakage, changeStatusLiveStream, getHistoryPakageList, getPakageCategory } from '../ShopApi'
import AddServicePack from './AddServicePack'

const Option = Select
const { RangePicker } = DatePicker

type Props = {
    id_shop: any
    onChangeStatus: any
    shopInfor: any
    setMinusAvailable: (minus: number) => void
}

export default function StallInfo({ id_shop, onChangeStatus, shopInfor, setMinusAvailable }: Props) {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [servicePakage, setServicePakage] = useState<Array<any>>([])
    const [isLoading, setIsLoading] = useState(false)
    const [listPakageCategory, setListPakageCategory] = useState<Array<any>>([])
    const [params, setParams] = useState({
        shop_id: id_shop,
        pakage_category_id: null,
        from_date: '',
        to_date: '',
        page: 1,
    })

    useEffect(() => {
        sessionStorage.setItem('key', '1')
    })

    const [paging, setPaging] = useState({
        total: 0,
        current: 1,
        pageSize: 0,
    })

    const [paramPackage, setParamPackage] = useState({ page: 1, search: '' })
    const [isLoadingSearchPackage, setIsLoadingSearchPackage] = useState<boolean>(false)

    const getDataPakageCategory = async () => {
        setIsLoadingSearchPackage(true)
        try {
            const res = await getPakageCategory(paramPackage)
            setListPakageCategory(res.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoadingSearchPackage(false)
        }
    }

    useEffect(() => {
        getData()
        getDataPakageCategory()
    }, [params])

    const getData = async () => {
        setIsLoading(true)
        try {
            let res = await getHistoryPakageList(params)
            const dataPakage = res.data.map((item: any, index: any) => {
                return { ...item, key: item.id }
            })
            const formattedPaging = {
                total: res.paging.totalItemCount,
                current: res.paging.page,
                pageSize: res.paging.limit,
            }
            setServicePakage(dataPakage)
            setPaging(formattedPaging)
            onChangeStatus(shopInfor.status)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }
    const CreateServicePack = () => {
        setIsModalVisible(true)
    }
    const onChangeLiveStreamStatus = async (checked: any, e: any) => {
        e.stopPropagation()
        setIsLoading(true)
        try {
            let data = await changeStatusLiveStream(shopInfor.id)
            shopInfor.livestream_enable = data.data.livestream_enable
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }
    const columns = [
        {
            width: 70,
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text: any, record: any, index: any) => (
                <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
                    {(paging.current - 1) * paging.pageSize + index + 1}
                </td>
            ),
            ellipsis: true,
        },
        {
            title: 'Tên gói',
            dataIndex: 'name',
        },
        {
            title: 'Loại gói',
            dataIndex: 'pakage_category_name',
        },
        {
            title: 'Số phút live stream',
            dataIndex: 'minus',
        },
        {
            title: 'Giá',
            dataIndex: 'price',
            render: (value: any) => {
                return <td>{value ? formatPrice(value) : 0} đ</td>
            },
        },
        {
            title: 'Số phút còn lại',
            dataIndex: 'current_minutes',
        },
        {
            title: 'Ngày mua',
            dataIndex: 'create_at',
            render: (value: any) => {
                return <td>{moment(value).format('HH:mm DD-MM-YYYY')}</td>
            },
        },
    ]

    return (
        <>
            <div>
                <Spin spinning={isLoading}>
                    <Descriptions title="Thông tin gian hàng">
                        <Descriptions.Item label="Tên gian hàng">{shopInfor.name}</Descriptions.Item>
                        <Descriptions.Item label="Số điện thoại">{shopInfor.phone}</Descriptions.Item>
                        <Descriptions.Item label="Email">{shopInfor.email}</Descriptions.Item>
                        <Descriptions.Item label="Trạng thái">
                            {shopInfor.status === 1 ? 'Đang hoạt động' : 'Ngưng hoạt động'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Thời gian còn lại">
                            {shopInfor.stream_minutes_available + ' phút'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Live Stream">
                            {shopInfor.livestream_enable === 1 ? (
                                <Switch
                                    checked
                                    onChange={onChangeLiveStreamStatus}
                                    style={{ backgroundColor: '#00abba' }}
                                />
                            ) : (
                                <Switch onChange={onChangeLiveStreamStatus} />
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </div>
            <Divider />
            <div>
                <Descriptions title="Lịch sử dùng gói dịch vụ"></Descriptions>

                <Row
                    gutter={[16, 16]}
                    justify="end"
                    style={{
                        paddingRight: '20px',
                        paddingBottom: '10px',
                    }}
                >
                    <Col span={6}>
                        <Select
                            allowClear
                            optionFilterProp="children"
                            loading={isLoadingSearchPackage}
                            placeholder="Loại gói"
                            style={{ width: '100%' }}
                            onChange={(value: any) => {
                                setParams({ ...params, pakage_category_id: value })
                            }}
                            showSearch
                            onSearch={(value: string) => setParamPackage({ ...paramPackage, search: value })}
                        >
                            {listPakageCategory.map((item: any, index: number) => {
                                return (
                                    <Option key={index} value={item.id}>
                                        {item.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Col>
                    <Col span={4}>
                        <RangePicker
                            placeholder={['Từ ngày', 'Đến ngày']}
                            onChange={(dates, dateStrings) => {
                                setParams({
                                    ...params,
                                    from_date: dateStrings[0],
                                    to_date: dateStrings[1],
                                })
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        {/* <Button
              style={{
                minWidth: '125px',
                backgroundColor: '#00abba',
                borderColor: '#00abba',
                color: 'white',
              }}
              onClick={() => {
                CreateServicePack()
              }}
            >
              <PlusCircleOutlined />
              Thêm gói
            </Button> */}
                        <ButtonFixed setIsCreate={CreateServicePack} type="add" text="Thêm gói" />
                    </Col>
                </Row>

                <div
                    style={{
                        padding: '10px 20px',
                    }}
                >
                    <Table
                        className="table-expanded-custom-no-image"
                        scroll={{
                            x: 800,
                            scrollToFirstRowOnChange: true,
                            // y: 'calc(100vh - 620px)',
                        }}
                        bordered
                        columns={columns}
                        dataSource={servicePakage}
                        loading={isLoading}
                        pagination={{
                            ...paging,
                            showSizeChanger: false,
                            onChange: async (page, pageSize) => {
                                setParams({ ...params, page })
                            },
                        }}
                    />
                </div>
            </div>
            <AddServicePack
                visible={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false)
                }}
                shopInfo={shopInfor}
                listPakageCategory={listPakageCategory}
                isLoadingButton={isLoading}
                addNewPackage={async (payload: any, resetFields: any) => {
                    setIsLoading(true)
                    try {
                        const res = await addPakage(payload)
                        console.log(res.data)
                        setServicePakage([{ ...res.data, create_at: new Date() }, ...servicePakage])
                        if (res.data.pakage_category_id === PAKAGE_CATEGORY.ADD_PAKAGE) {
                            setMinusAvailable(shopInfor.stream_minutes_available + res.data.minus)
                        } else {
                            if (shopInfor.stream_minutes_available < res.data.minus) {
                                setMinusAvailable(0)
                            } else {
                                setMinusAvailable(shopInfor.stream_minutes_available - res.data.minus)
                            }
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        setIsLoading(false)
                        resetFields()
                        setIsModalVisible(false)
                    }
                }}
            />
        </>
    )
}
