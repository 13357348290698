import { DatePicker, PageHeader, Table, Tag, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { IDataOrders, IOrderPayload } from 'features/admin/order-clone/Interfaces'
import { IFormateOrder } from 'features/admin/product_clone/Products/ProductInterfaces'
import moment from 'moment'
import { useState, useEffect, memo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ORDER_STATUS, REGION } from 'utils/constants'
import { notificationError } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { getOrderByCustomer } from '../../CustomerAPI'
import { IFormatedPaging } from '../../CustomerInterfaces'
const { RangePicker } = DatePicker
const { Text } = Typography

interface IProps {
    infoCustomer: any
}

function OrderInfo(props: IProps) {
    const history = useHistory()
    const [listOrderByCustom, setlistOrderByCustom] = useState<IFormateOrder[]>()
    const [params, setparams] = useState<IOrderPayload>({
        page: '',
        limit: '12',
        user_id: props.infoCustomer.id,
        create_from: '',
        create_to: '',
    })

    const [paging, setPaging] = useState<IFormatedPaging>({
        total: 0,
        current: 1,
        pageSize: 0,
    })

    const handleGetTime = (selectTime: any, timeString: Array<string>) => {
        setparams({
            ...params,
            create_from: timeString[0],
            create_to: timeString[1],
        })
    }

    const getDataOrder = async () => {
        try {
            const res = await getOrderByCustomer(params)
            if (res.data) {
                const dataOrder = res.data.map((order, index) => {
                    return {
                        ...order,
                        key: index,
                        stt: index + 1,
                    }
                })
                setlistOrderByCustom(dataOrder)
            }
            if (res.paging) {
                setPaging({
                    total: res.paging?.totalItemCount,
                    current: res.paging?.page,
                    pageSize: res.paging?.limit,
                })
            }
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra!')
        }
    }

    useEffect(() => {
        getDataOrder()
    }, [params])

    const orderStatusDesc = (status: string) => {
        switch (status) {
            case ORDER_STATUS.PENDING:
                return <Tag color={'orange'}>Chờ xác nhận</Tag>
            case ORDER_STATUS.INPROGRESS:
                return <Tag color={'blue'}>Đang xử lý</Tag>
            case ORDER_STATUS.SUCCCESS:
                return <Tag color={'green'}>Hoàn thành</Tag>
            case ORDER_STATUS.CANCELED:
                return <Tag color={'red'}>Huỷ</Tag>
        }
    }

    const columns: ColumnsType<IFormateOrder> = [
        {
            title: 'STT',
            dataIndex: 'stt',
            render: (text, record, index) => (
                <Text id={record.id.toString()}>{(paging.current - 1) * paging.pageSize + index + 1}</Text>
            ),
        },
        {
            title: 'Mã đơn',
            dataIndex: 'code',
        },
        {
            title: 'Số SP',
            dataIndex: 'quantity_product',
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'total',
            render: total => {
                return <Text>{formatPrice(total)} đ</Text>
            },
        },
        {
            title: 'Đã thanh toán',
            dataIndex: 'total_payment',
            render: total => {
                return <Text>{formatPrice(total)} đ</Text>
            },
        },
        {
            title: 'Trạng thái đơn hàng',
            dataIndex: 'status',
            render: status => orderStatusDesc(status),
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_at',
            render: created_at => <Text>{convertTimeStampToString(created_at)}</Text>,
        },
    ]

    const disabledDate: any['disabledDate'] = (current: any) => {
        return current && current > moment().endOf('day')
    }

    return (
        <>
            <PageHeader
                title="Lịch sử mua hàng"
                extra={[<RangePicker disabledDate={disabledDate} onChange={handleGetTime} />]}
            />
            <Table
                size="middle"
                columns={columns}
                dataSource={listOrderByCustom}
                pagination={{
                    ...paging,
                    onChange: async (page, pageSize) => {
                        setparams({ ...params, page: page.toString() })
                    },
                }}
                // onRow={(record: any, rowIndex?: number) => {
                //   return {
                //     onClick: () => {
                //       history.push(`${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${record.id}`)
                //     },
                //   }
                // }}
            />
        </>
    )
}

export default memo(OrderInfo)
