import React, { useEffect, useState } from 'react'
import {
  Button,
  Form,
  Modal,
  Select,
  Popover,
  Menu,
  Descriptions,
  Affix,
} from 'antd'
import { getPakage } from '../ShopApi'
import { InfoCircleOutlined } from '@ant-design/icons'
import { formatPrice } from 'utils/ruleForm'
import styled from 'styled-components'
import ButtonForm from 'common/components/ModalButton'

const StyledPop = styled(Popover)`
  position: absolute;
`

const Option = Select

type Props = {
  visible: boolean
  isLoadingButton: boolean
  onCancel: any
  shopInfo: any
  listPakageCategory: any
  addNewPackage: any
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

export default function AddServicePack({
  visible,
  isLoadingButton,
  onCancel,
  shopInfo,
  listPakageCategory,
  addNewPackage,
}: Props) {
  const [form] = Form.useForm()
  const [listPakage, setListPakage] = useState<Array<any>>([])

  // const drop = () => {
  //   return (
  //     <div>
  //       <p>Số phút: {listPakageCategory.minus} phút</p>
  //       <p>Giá: {listPakageCategory.price} đ</p>
  //     </div>
  //   )
  // }

  // const drop1 = (item: any, index: number) => {
  //   ;<Descriptions>
  //     <Option>Số phút: {listPakageCategory.minus} phút</Option>
  //     <Option>Giá: {listPakageCategory.price} đ</Option>
  //   </Descriptions>
  // }

  // const drop = listPakage.map((item: any, index: number) => {
  //   return (
  //     <Descriptions>
  //       <Option key={index} value={item.id}>
  //         Số phút: {item.minus} phút
  //       </Option>
  //       <Menu.Item key={index}>Giá: {item.price} đ</Menu.Item>
  //       {/* <p>Giá: {listPakageCategory.price} đ</p> */}
  //     </Descriptions>
  //   )
  // })
  // console.log(drop)

  const handleChangePakageCategory = async (value: any) => {
    const res = await getPakage({
      pakage_category_id: value,
      status: 1,
    })
    setListPakage(res.data)
    form.resetFields(['pakage_id'])
  }

  const onFinish = async (values: any) => {
    //kiểm tra nếu là gói trừ phút thì
    // if (values.pakage_category_id === 2) {
    //   const pakage = listPakage.find((item: any) => {
    //     if (values.pakage_id === item.id) {
    //       return item
    //     }
    //   })
    //   //kiểm tra xem số phút trừ của gói lớn hơn số phút còn lại --> lỗi
    //   if (pakage.minus > shopInfo.stream_minutes_available) {
    //     message.error('Không thể chọn gói nhiều hơn số phút còn lại!')
    //   } else {
    //     const payload = {
    //       pakage_id: values.pakage_id,
    //       shop_id: shopInfo.id,
    //     }
    //     addNewPackage(payload, form.resetFields)
    //   }
    // } else {
    const payload = {
      pakage_id: values.pakage_id,
      shop_id: shopInfo.id,
    }
    addNewPackage(payload, form.resetFields)
    // }
  }

  return (
    <Modal
      title="Thêm gói dịch vụ gian hàng"
      visible={visible}
      maskClosable={false}
      footer={null}
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
    >
      <Form
        {...formItemLayout}
        labelAlign="left"
        form={form}
        onFinish={(values: any) => onFinish(values)}
        scrollToFirstError
      >
        <Form.Item label="Gian hàng" name="stall_name">
          {shopInfo.name}
        </Form.Item>

        <Form.Item
          label="Loại gói"
          name="pakage_category_id"
          rules={[{ required: true, message: 'Vui lòng chọn loại gói!' }]}
        >
          <Select
            placeholder="Loại gói"
            // style={{ width: '100%', marginLeft: '20px' }}
            onChange={handleChangePakageCategory}
          >
            {listPakageCategory.map((item: any, index: number) => {
              return (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Gói dịch vụ"
          name="pakage_id"
          rules={[{ required: true, message: 'Vui lòng chọn gói dịch vụ!' }]}
        >
          <Select
            placeholder="Gói dịch vụ"
            // style={{ width: '100%', marginLeft: '20px' }}
          >
            {listPakage.map((item: any, index: number) => {
              return (
                <Option key={index} value={item.id} dropdownStyle={{}}>
                  <StyledPop
                    content={
                      <div style={{}}>
                        <p>Số phút: {item.minus} phút</p>
                        <p>Giá: {formatPrice(item.price)} đ</p>
                      </div>
                    }
                    placement="rightBottom"
                    // trigger="click"
                  >
                    <p
                      style={{
                        width: '95%',
                      }}
                    >
                      {item.name}
                    </p>
                  </StyledPop>
                </Option>
              )
            })}
          </Select>
        </Form.Item>

        {/* <Form.Item {...tailFormItemLayout}>
          <Button
            danger
            onClick={() => {
              form.resetFields()
              onCancel()
            }}
            style={{ marginRight: '20px' }}
          >
            Huỷ
          </Button>
          <Button type="primary" loading={isLoading} htmlType="submit">
            Lưu
          </Button>
        </Form.Item> */}
        <ButtonForm
          isLoadingButton={isLoadingButton}
          onCancel={onCancel}
          text="Lưu"
        />
      </Form>
    </Modal>
  )
}
