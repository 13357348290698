import {
    AppstoreOutlined,
    AreaChartOutlined,
    DatabaseOutlined,
    DeploymentUnitOutlined,
    DollarOutlined,
    DotChartOutlined,
    EditOutlined,
    FileDoneOutlined,
    FileTextOutlined,
    FontSizeOutlined,
    HomeOutlined,
    InboxOutlined,
    MessageOutlined,
    SettingOutlined,
    TeamOutlined,
    UndoOutlined,
    UserOutlined,
    VideoCameraOutlined,
    YuqueOutlined,
} from '@ant-design/icons'
import { Badge, Menu } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useListMessageNotRead } from 'features/admin/chat/hooks/useListMessageNotRead'
import { useSelector } from 'react-redux'
import R from 'utils/R'
import { ADMIN_ROLE } from 'utils/constants'

const { SubMenu } = Menu

type Props = {
    handleClick: any
    handleGetCurrentRouter: any
}

export default function AdminMenu({ handleClick, handleGetCurrentRouter }: Props) {
    const { listTopicMessageNotRead } = useListMessageNotRead()
    const authState = useSelector((state: any) => state.authReducer?.userInfo?.admin)
    const userRole = authState?.role

    return (
        <Menu onClick={handleClick} mode="inline" selectedKeys={[handleGetCurrentRouter()]}>
            <Menu.Item
                key={ADMIN_ROUTER_PATH.DASH_BOARD}
                icon={<HomeOutlined />}
                children={R.strings().title_header_dashboard}
            />

            {/*  sản phẩm */}
            {(userRole === ADMIN_ROLE.ROOT_ADMIN ||
                userRole === ADMIN_ROLE.SHOP_ADMIN ||
                userRole === ADMIN_ROLE.PRODUCT_MANAGER) && (
                <SubMenu key="product" icon={<InboxOutlined />} title={R.strings().title_sidebar_product}>
                    <Menu.Item key={ADMIN_ROUTER_PATH.PRODUCT}>{R.strings().title_sidebar_product}</Menu.Item>

                    <Menu.Item key={ADMIN_ROUTER_PATH.CATEGORY_PRODUCT}>
                        {R.strings().title_sidebar_category_product}
                    </Menu.Item>
                </SubMenu>
            )}

            {/*  điện hoa */}
            {(userRole === ADMIN_ROLE.ROOT_ADMIN || userRole === ADMIN_ROLE.SHOP_ADMIN) && (
                <Menu.Item key={ADMIN_ROUTER_PATH.FLOWER_DELIVERY_REQUEST} icon={<DeploymentUnitOutlined />}>
                    {R.strings().title_flower_delivery_request}
                </Menu.Item>
            )}

            {/*  khách hàng */}
            {(userRole === ADMIN_ROLE.ROOT_ADMIN || userRole === ADMIN_ROLE.CUSTOMER_MANAGER) && (
                <>
                    <SubMenu key="customer" icon={<TeamOutlined />} title={R.strings().title_header_customer}>
                        <Menu.Item key={ADMIN_ROUTER_PATH.CUSTOMER}>Khách hệ thống</Menu.Item>
                        <Menu.Item key={ADMIN_ROUTER_PATH.INDIVIDUAL_CUSTOMER}>Khách lẻ</Menu.Item>
                    </SubMenu>
                    {/* Bài đăng */}
                    <SubMenu key="post" icon={<FontSizeOutlined />} title={R.strings().title_header_post}>
                        <Menu.Item key={ADMIN_ROUTER_PATH.TOPIC_POST}>{R.strings().title_header_topic_post}</Menu.Item>

                        <Menu.Item key={ADMIN_ROUTER_PATH.COMMUNITY_POST}>
                            {R.strings().title_header_community_post}
                        </Menu.Item>

                        <Menu.Item key={ADMIN_ROUTER_PATH.NEWS}>{R.strings().title_header_news}</Menu.Item>
                    </SubMenu>
                </>
            )}

            {/* Khách hàng */}
            {(userRole === ADMIN_ROLE.SALE_MANAGER ||
                userRole === ADMIN_ROLE.ACCOUNTANT ||
                userRole === ADMIN_ROLE.SHOP_ADMIN) && (
                <SubMenu key="customer" icon={<TeamOutlined />} title={R.strings().title_header_customer}>
                    <Menu.Item key={ADMIN_ROUTER_PATH.CUSTOMER}>Khách hệ thống</Menu.Item>
                    <Menu.Item key={ADMIN_ROUTER_PATH.INDIVIDUAL_CUSTOMER}>Khách lẻ</Menu.Item>
                </SubMenu>
            )}

            {/*  shipper */}
            {(userRole === ADMIN_ROLE.ROOT_ADMIN ||
                userRole === ADMIN_ROLE.SHOP_ADMIN ||
                userRole === ADMIN_ROLE.SHIPPER_MANAGER) && (
                <>
                    <Menu.Item key={ADMIN_ROUTER_PATH.ORDER} icon={<FileTextOutlined />}>
                        {R.strings().title_header_order_clone}
                    </Menu.Item>
                    <Menu.Item key={ADMIN_ROUTER_PATH.REFUND_ORDER} icon={<UndoOutlined />}>
                        Đơn hoàn trả
                    </Menu.Item>
                    <Menu.Item key={ADMIN_ROUTER_PATH.SHIPPER} icon={<YuqueOutlined />}>
                        Shipper
                    </Menu.Item>
                </>
            )}
            {(userRole === ADMIN_ROLE.ROOT_ADMIN ||
                userRole === ADMIN_ROLE.INVERTORY_STAFF ||
                userRole === ADMIN_ROLE.SHOP_ADMIN) && (
                <Menu.Item key={ADMIN_ROUTER_PATH.INVENTORY_STAFF} icon={<DatabaseOutlined />}>
                    Nhân viên kho
                </Menu.Item>
            )}
            {(userRole === ADMIN_ROLE.ROOT_ADMIN ||
                userRole === ADMIN_ROLE.SALE_MANAGER ||
                userRole === ADMIN_ROLE.SHOP_ADMIN) && (
                <Menu.Item key={ADMIN_ROUTER_PATH.BUSINESS_STAFF} icon={<DollarOutlined />}>
                    Nhân viên kinh doanh
                </Menu.Item>
            )}
            {(userRole === ADMIN_ROLE.ACCOUNTANT || userRole === ADMIN_ROLE.SALE_MANAGER) && (
                <Menu.Item key={ADMIN_ROUTER_PATH.ORDER} icon={<FileTextOutlined />}>
                    {R.strings().title_header_order_clone}
                </Menu.Item>
            )}
            {(userRole === ADMIN_ROLE.ROOT_ADMIN || userRole === ADMIN_ROLE.ACCOUNTANT) && (
                <Menu.Item key={ADMIN_ROUTER_PATH.POLICY} icon={<FileDoneOutlined />}>
                    Quy định quy chế
                </Menu.Item>
            )}

            {/* Livestream */}
            {userRole === ADMIN_ROLE.ROOT_ADMIN ||
                (userRole === ADMIN_ROLE.SHOP_ADMIN && (
                    <Menu.Item key={ADMIN_ROUTER_PATH.LIVE_STREAM} icon={<VideoCameraOutlined />}>
                        {R.strings().title_header_live_stream}
                    </Menu.Item>
                ))}

            {/* Chăm sóc khách hàng */}
            {(userRole === ADMIN_ROLE.ROOT_ADMIN || userRole === ADMIN_ROLE.CUSTOMER_CARE) && (
                <>
                    <Menu.Item
                        key={ADMIN_ROUTER_PATH.CHAT}
                        icon={
                            <Badge size="small" count={listTopicMessageNotRead?.length}>
                                <MessageOutlined />
                            </Badge>
                        }
                    >
                        {R.strings().title_header_chat}
                    </Menu.Item>
                </>
            )}

            {(userRole === ADMIN_ROLE.ROOT_ADMIN ||
                userRole === ADMIN_ROLE.PREFERENTIAL_MANAGER ||
                userRole === ADMIN_ROLE.SHOP_ADMIN) && (
                <SubMenu key="point" icon={<AppstoreOutlined />} title={R.strings().title_header_point}>
                    <Menu.Item key={ADMIN_ROUTER_PATH.GIFT_CHANGE}>{R.strings().title_header_gift_change}</Menu.Item>

                    <Menu.Item key={ADMIN_ROUTER_PATH.GIFT}>{R.strings().title_header_gift}</Menu.Item>
                </SubMenu>
            )}

            {(userRole === ADMIN_ROLE.ROOT_ADMIN || userRole === ADMIN_ROLE.REPORT_MANAGER) && (
                <>
                    <Menu.Item key={ADMIN_ROUTER_PATH.SALES_REPORT} icon={<AreaChartOutlined />}>
                        {R.strings().title_header_sales_report}
                    </Menu.Item>

                    <SubMenu key="report" icon={<DotChartOutlined />} title={'Báo cáo'}>
                        <Menu.Item key={ADMIN_ROUTER_PATH.AREA_REPORT}>{'Khu vực'}</Menu.Item>
                        <Menu.Item key={ADMIN_ROUTER_PATH.BRANCH_REPORT}>{'Cân hàng'}</Menu.Item>
                        <Menu.Item key={ADMIN_ROUTER_PATH.TEMPORARY_ORDER_REPORT}>{'Nhập chợ'}</Menu.Item>
                        <Menu.Item key={ADMIN_ROUTER_PATH.ORDER_CANCEL_REPORT}>Huỷ hàng</Menu.Item>
                        <Menu.Item key={ADMIN_ROUTER_PATH.ORDER_WRAP_REPORT}>Đóng hàng</Menu.Item>
                    </SubMenu>
                </>
            )}
            {userRole === ADMIN_ROLE.SHOP_ADMIN && (
                <>
                    <Menu.Item key={ADMIN_ROUTER_PATH.SALES_REPORT} icon={<AreaChartOutlined />}>
                        {R.strings().title_header_sales_report}
                    </Menu.Item>

                    <SubMenu key="report" icon={<DotChartOutlined />} title={'Báo cáo'}>
                        <Menu.Item key={ADMIN_ROUTER_PATH.AREA_REPORT}>{'Khu vực'}</Menu.Item>
                        <Menu.Item key={ADMIN_ROUTER_PATH.BRANCH_REPORT}>{'Cân hàng'}</Menu.Item>
                        <Menu.Item key={ADMIN_ROUTER_PATH.TEMPORARY_ORDER_REPORT}>{'Nhập chợ'}</Menu.Item>
                        <Menu.Item key={ADMIN_ROUTER_PATH.ORDER_CANCEL_REPORT}>Huỷ hàng</Menu.Item>
                    </SubMenu>
                </>
            )}

            {userRole === ADMIN_ROLE.SALE_MANAGER && (
                <Menu.Item key={ADMIN_ROUTER_PATH.SALES_REPORT} icon={<AreaChartOutlined />}>
                    {R.strings().title_header_sales_report}
                </Menu.Item>
            )}

            {userRole === ADMIN_ROLE.INVERTORY_STAFF && (
                <>
                    <Menu.Item key={ADMIN_ROUTER_PATH.COMMENT_GOOD} icon={<EditOutlined />}>
                        Nhận xét hàng hoá
                    </Menu.Item>
                    <SubMenu key="report" icon={<DotChartOutlined />} title={'Báo cáo'}>
                        <Menu.Item key={ADMIN_ROUTER_PATH.ORDER_CANCEL_REPORT}>Huỷ hàng</Menu.Item>
                        <Menu.Item key={ADMIN_ROUTER_PATH.ORDER_WRAP_REPORT}>Đóng hàng</Menu.Item>
                    </SubMenu>
                </>
            )}
            {(userRole === ADMIN_ROLE.ROOT_ADMIN || userRole === ADMIN_ROLE.SHOP_ADMIN) && (
                <Menu.Item key={ADMIN_ROUTER_PATH.COMMENT_GOOD} icon={<EditOutlined />}>
                    Nhận xét hàng hoá
                </Menu.Item>
            )}

            {userRole === ADMIN_ROLE.ROOT_ADMIN && (
                <>
                    <Menu.Item key={ADMIN_ROUTER_PATH.CONFIG} icon={<SettingOutlined />}>
                        {R.strings().title_header_config}
                    </Menu.Item>

                    <Menu.Item key={ADMIN_ROUTER_PATH.ACCOUNTS} icon={<UserOutlined />}>
                        {R.strings().account_clone}
                    </Menu.Item>
                </>
            )}
            {userRole === ADMIN_ROLE.SHOP_ADMIN && (
                <Menu.Item key={ADMIN_ROUTER_PATH.CONFIG} icon={<SettingOutlined />}>
                    {R.strings().title_header_config}
                </Menu.Item>
            )}
        </Menu>
    )
}
