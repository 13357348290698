import { ApiClient } from 'services/ApiService'

export const listCommunityPost = (payload: any) =>
  ApiClient.get('/admin/post', payload)

export const postDetail = (id: number) => ApiClient.get(`/admin/post/${id}`)

export const createCommunityPost = (payload: any) =>
  ApiClient.post('/admin/post', payload)

export const requestGetListComment = (payload: any) =>
  ApiClient.get('/post/comments', payload)

export const requestReactionPost = (post_id: number) =>
  ApiClient.put(`/post/${post_id}/reaction`)

export const requestReactionComment = (post_id: number, comment_id: number) =>
  ApiClient.post(`/post/${post_id}/reaction/${comment_id}/comment`, {})

export const requestComment = (
  post_id: number,
  commentData: { content: string; parent_id: number | null }
) => ApiClient.post(`post/${post_id}/comment`, commentData)

export const requestLoadMoreSubComment = (
  comment_id: number,
  payload: { last_comment_id: number; amount?: number }
) => ApiClient.get(`comment/${comment_id}/more`, payload)

export const updatePostDetail = (payload: any, id: number) =>
  ApiClient.put(`/admin/post/${id}`, payload)

export const deletePost = (id: number) => ApiClient.delete(`/admin/post/${id}`)
export const getAllTopic = () => ApiClient.get(`admin/topic/active`)
