import { Form, Input, message, Modal, Select } from 'antd'
import ButtonForm from 'common/components/ModalButton'
import { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toNonAccentVietnamese } from 'utils/Configs'
import { getListBranch, getListCustomerGroup, getListPrice, getNewKiotviet, setDefaultBranch } from '../ConfigAPI'
import { getData } from '../GetDataSlice'
interface IProps {
    isModalVisible: boolean
    cancel: () => void
}

function AddShop(props: IProps) {
    const dispatch = useDispatch()
    const [isShowSelectBranch, setisShowSelectBranch] = useState<boolean>(false)
    const [dataShop, setdataShop] = useState<any>()
    const [listStock, setlistStock] = useState<any[]>()
    const [listCustomerGroup, setListCustomerGroup] = useState<any[]>()
    const [listPrice, setListPrice] = useState<any[]>()
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [brandName, setBrandName] = useState<string>()
    const [customerGroupName, setCustomerGroupName] = useState<string>()
    const [priceName, setPriceName] = useState<string>()

    const handleFinish = async (value: any) => {
        setisLoading(true)
        try {
            const res = await getNewKiotviet(value)
            setdataShop(res.data)
            if (res.data) {
                getAllBranch(res.data.id)
                getAllCustomerGroup(res?.data?.id)
                getAllPrice(res?.data?.id)
            } else {
                message.warning(res.message)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setisLoading(false)
        }
    }

    const getAllBranch = async (id: number) => {
        try {
            const res = await getListBranch(id)
            if (res.data) {
                const newList = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.branchName }))
                setlistStock(newList)
                setisShowSelectBranch(true)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getAllCustomerGroup = async (id: number) => {
        try {
            const res = await getListCustomerGroup(id)
            if (res.data) {
                const newList = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setListCustomerGroup(newList)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getAllPrice = async (id: number) => {
        try {
            const res = await getListPrice(id)
            if (res.data) {
                const newList = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setListPrice(newList)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleSetDafaultShop = async (value: any) => {
        setisLoading(true)
        try {
            const res = await setDefaultBranch({
                id: dataShop.id,
                body: {
                    default_branch_id: value.branchName,
                    default_branch_name: brandName,
                    kiotviet_retail_cus_name: customerGroupName,
                    kiotviet_retail_cus_id: value.customer,
                    kiotviet_retail_pricebook_name: priceName,
                    kiotviet_retail_pricebook_id: value.price,
                    status: 1,
                },
            })
            if ((res.msg = 'Thành Công')) {
                props.cancel()
                dispatch(getData())
                message.success('Đồng bộ thành công')
            }
        } catch (err) {
            console.log(err)
        } finally {
            setisLoading(false)
        }
    }

    return (
        <>
            {isShowSelectBranch ? (
                <Modal
                    maskClosable={false}
                    destroyOnClose
                    width={'60%'}
                    title="Lựa chọn chi nhánh"
                    visible={isShowSelectBranch}
                    onCancel={props.cancel}
                    footer={null}
                >
                    <Form
                        name="basic"
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={(value: any) => handleSetDafaultShop(value)}
                    >
                        <Form.Item
                            label="Chi nhánh"
                            name="branchName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Chi nhánh không được bỏ trống!',
                                },
                            ]}
                        >
                            <Select
                                showArrow
                                showSearch
                                filterOption={(input, option: any) => {
                                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                    return nonAccent.includes(nonAccentInput)
                                }}
                                style={{ width: '100%' }}
                                placeholder="Chọn chi nhánh"
                                options={listStock}
                                onChange={(value: any, option: any) => {
                                    setBrandName(option?.branchName)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Nhóm khách hàng"
                            name="customer"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn nhóm khách hàng!',
                                },
                            ]}
                        >
                            <Select
                                showArrow
                                showSearch
                                filterOption={(input, option: any) => {
                                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                    return nonAccent.includes(nonAccentInput)
                                }}
                                style={{ width: '100%' }}
                                placeholder="Chọn nhóm khách hàng"
                                options={listCustomerGroup}
                                onChange={(value: any, option: any) => setCustomerGroupName(option?.name)}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Giá bán"
                            name="price"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn giá bán!',
                                },
                            ]}
                        >
                            <Select
                                showArrow
                                showSearch
                                filterOption={(input, option: any) => {
                                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                    return nonAccent.includes(nonAccentInput)
                                }}
                                style={{ width: '100%' }}
                                placeholder="Chọn giá bán"
                                options={listPrice}
                                onChange={(value: any, option: any) => setPriceName(option?.name)}
                            />
                        </Form.Item>
                        <ButtonForm text="Lưu" isLoadingButton={isLoading} onCancel={props.cancel} />
                    </Form>
                </Modal>
            ) : null}

            <Modal
                maskClosable={false}
                destroyOnClose
                title="Thêm gian hàng"
                visible={props.isModalVisible}
                onCancel={props.cancel}
                footer={null}
            >
                <Form
                    name="basic"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(value: any) => handleFinish(value)}
                >
                    <Form.Item
                        label="Tên gian hàng"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Tên gian hàng không được bỏ trống!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Tên kết nối"
                        name="retailer"
                        rules={[{ required: true, message: 'Tên kết nối không được bỏ trống!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Client id"
                        name="client_id"
                        rules={[{ required: true, message: 'Client id không được bỏ trống!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Mã bảo mật"
                        name="client_secret"
                        rules={[{ required: true, message: 'Mã bảo mật không được bỏ trống!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <ButtonForm text="Lưu" isLoadingButton={isLoading} onCancel={props.cancel} />
                </Form>
            </Modal>
        </>
    )
}

export default memo(AddShop)
