import { PageHeader, Select, DatePicker, Row, Col, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { getData } from 'features/admin/config-clone/GetDataSlice'
import { useState, useEffect, memo } from 'react'
import styled from 'styled-components'
import { ACTIONS } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { get_ss_hh_dd_mm } from 'utils/TimerHelper'
import { getWalletPoint } from '../../CustomerAPI'
import { IFormateDataCustomers, IFormatedListPoint, IPointHistory, IWalletPayload } from '../../CustomerInterfaces'
const Option = Select
const Text = Typography

const { RangePicker } = DatePicker
const TextStyle = styled.p`
    color: red;
    margin: 17px 0 5px 10px;
    font-weight: 600;
    font-size: 15px;
`
const StyledValueChange = styled.p<{ isAdd: boolean }>`
    color: ${p => (p.isAdd ? 'blue' : 'red')};
    margin: 17px 0 5px 10px;
    font-weight: 300;
    font-size: 15px;
`
const StyledBalance = styled.p`
    color: #0066ff;
    margin: 17px 0 5px 10px;
    font-weight: 600;
    font-size: 15px;
`
interface IProps {
    dataCustomer: IFormateDataCustomers
}

function HistoryPoints(props: IProps) {
    const [params, setparams] = useState<IWalletPayload>({
        user_id: props.dataCustomer.id,
        page: '',
        limit: '12',
        type: undefined,
        create_from: undefined,
        create_to: undefined,
    })
    const [paging, setPaging] = useState({
        total: 0,
        current: 1,
        pageSize: 0,
    })
    const [listPoint, setlistPoint] = useState<IFormatedListPoint[]>([])
    const [wallet, setwallet] = useState<IPointHistory>()

    const columns: ColumnsType<IFormatedListPoint> = [
        {
            title: 'Nội dung',
            dataIndex: 'note',
            render: content => {
                return <Text>{content ? content : '...'}</Text>
            },
            align: 'center',
        },
        {
            title: 'Thời gian giao dịch',
            dataIndex: 'created_at',
            render: created_at => {
                return <Text>{get_ss_hh_dd_mm(created_at)}</Text>
            },
            align: 'center',
        },

        {
            title: 'Số điểm',
            dataIndex: 'value',
            render: (value: number, record: any) => {
                if (record.type !== 'add') {
                    value = -Math.abs(value)
                }
                return <StyledValueChange isAdd={record.type === 'add'}>{formatPrice(value)}</StyledValueChange>
            },
            align: 'center',
        },
        {
            title: 'Số dư cuối',
            dataIndex: 'current_balance',
            render: (value: number) => {
                return <StyledBalance>{formatPrice(value)}</StyledBalance>
            },
            align: 'center',
        },
    ]

    const getData = async (params: IWalletPayload) => {
        try {
            const res = await getWalletPoint(params)
            if (res.data) {
                const dataPoint = res.data.list_point_change.map((item, index) => {
                    return {
                        ...item,
                        index: index + 1,
                        key: index,
                    }
                })
                if (res.paging) {
                    setPaging({
                        current: res.paging?.page,
                        pageSize: res.paging?.limit,
                        total: res.paging?.totalItemCount,
                    })
                }
                setlistPoint(dataPoint)
                setwallet(res.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getData(params)
    }, [params])

    return (
        <>
            <PageHeader
                title="Lịch sử tích điểm"
                extra={[
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Select
                                allowClear
                                placeholder="Tác vụ"
                                style={{ width: '200px' }}
                                onChange={(status: string) => {
                                    setparams({
                                        ...params,
                                        type: status,
                                        page: 1,
                                    })
                                }}
                            >
                                <Option value={ACTIONS.ORDER_COMPLETE}>Hoàn thành đơn hàng</Option>
                                <Option value={ACTIONS.ORDER_PAYMENT}>Thanh toán đơn hàng</Option>
                                <Option value={ACTIONS.INVATE}>Giới thiệu ứng dụng</Option>
                                <Option value={ACTIONS.JOIN_WITH_INVATE}>Đăng ký với mã giới thiệu</Option>
                                <Option value={ACTIONS.DEPOSIT}>Nạp tiền từ VNPAY</Option>
                                <Option value={ACTIONS.GIFT_EXCHANGE}>Đổi quà</Option>
                            </Select>
                        </Col>
                        <Col>
                            <RangePicker
                                onChange={(selectTime: any, timeString: Array<string>) =>
                                    setparams({
                                        ...params,
                                        create_from: timeString[0],
                                        create_to: timeString[1],
                                    })
                                }
                            />
                        </Col>
                    </Row>,
                ]}
            />
            <TextStyle>
                Tổng điểm hiện tại:
                {formatPrice(wallet?.total_point) ? ` ${formatPrice(wallet?.total_point)}` : ' 0'} điểm
            </TextStyle>
            <Table
                size="small"
                bordered
                columns={columns}
                dataSource={listPoint}
                pagination={{
                    ...paging,
                    showSizeChanger: false,
                    onChange: async page => {
                        setparams({ ...params, page: page.toString() })
                    },
                }}
            />
        </>
    )
}

export default memo(HistoryPoints)
