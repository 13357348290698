import { Col, Row, Select } from 'antd'
import { ADMIN_ROLE, IS_ACTIVE, IS_ADMIN, STATUS, TEXT_ACTIVE } from 'utils/constants'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import ButtonFixed from 'common/components/ButtonFixed'
import './css/Header.css'
import { memo } from 'react'
const { Option } = Select

type HeaderProps = {
    setIsCreate: any
    onSearchSubmit: (searchKey: string) => void
    onStatusSubmit: (statusKey: string) => void
    onRoleSubmit: (roleKey: any) => any
    isLoading: boolean
}

function Header(props: HeaderProps) {
    const isCreate = props.setIsCreate
    const onSearchSubmit = props.onSearchSubmit
    const onStatusSubmit = props.onStatusSubmit
    const onRoleSubmit = props.onRoleSubmit
    const isLoading = props.isLoading
    return (
        <>
            <Row gutter={[16, 16]} justify="end">
                <Col span={6} style={{ minWidth: '260px' }}>
                    <TypingAutoSearch
                        onSearchSubmit={(value: string) => {
                            onSearchSubmit(value.trim())
                        }}
                        isSearchLoading={isLoading}
                        placeholder="Tên hoặc số điện thoại ..."
                    />
                </Col>

                <Col span={4}>
                    <Select
                        allowClear
                        placeholder="Trạng thái"
                        style={{ minWidth: '150px' }}
                        onChange={(value: string) => {
                            onStatusSubmit(value)
                        }}
                    >
                        <Option value={''}>Tất cả</Option>
                        <Option value={IS_ACTIVE.ACTIVE}>{TEXT_ACTIVE.ACTIVE}</Option>
                        <Option value={IS_ACTIVE.INACTIVE}>{TEXT_ACTIVE.INACTIVE}</Option>
                    </Select>
                </Col>

                <Col span={4}>
                    <Select
                        allowClear
                        placeholder="Loại tài khoản"
                        style={{ minWidth: '200px' }}
                        onChange={(value: number) => {
                            if (value == -1) {
                                onRoleSubmit(undefined)
                            } else {
                                onRoleSubmit(value)
                            }
                        }}
                    >
                        <Option value={-1}>Tất cả</Option>
                        <Select.Option value={ADMIN_ROLE.ROOT_ADMIN}>Admin tổng</Select.Option>
                        <Select.Option value={ADMIN_ROLE.CUSTOMER_MANAGER}>Quản lý khách hàng</Select.Option>
                        <Select.Option value={ADMIN_ROLE.CUSTOMER_CARE}>Chăm sóc khách hàng</Select.Option>
                        <Select.Option value={ADMIN_ROLE.REPORT_MANAGER}>Quản lý báo cáo</Select.Option>
                        <Select.Option value={ADMIN_ROLE.PRODUCT_MANAGER}>Quản lý sản phẩm</Select.Option>
                        <Select.Option value={ADMIN_ROLE.PREFERENTIAL_MANAGER}>Quản lý ưu đãi</Select.Option>
                        <Select.Option value={ADMIN_ROLE.SHIPPER_MANAGER}>Quản lý shipper</Select.Option>
                        <Select.Option value={ADMIN_ROLE.SHOP_ADMIN}>Admin gian hàng</Select.Option>
                        <Select.Option value={ADMIN_ROLE.ACCOUNTANT}>Kế toán</Select.Option>
                        <Select.Option value={ADMIN_ROLE.INVERTORY_STAFF}>Quản lý kho</Select.Option>
                        <Select.Option value={ADMIN_ROLE.SALE_MANAGER}>Trưởng phòng kinh doanh</Select.Option>
                    </Select>
                </Col>

                <ButtonFixed setIsCreate={isCreate} text="Thêm mới" type="add" />
            </Row>
        </>
    )
}

export default memo(Header)
