import { PageHeader, Row, Space, Divider, Card, Col, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Cards from './components/Cards'
import { ChartLive } from './components/ChartLive'
import { Header } from './components/Header'
import { ListLive } from './components/ListLiveDash'
import { OrderDashboard } from './components/OrderDashboard'
import moment from 'moment'
import { getDataOverviews } from './dashboardApi'
import { useDebounce } from 'common/hooks/Debounce'
import { ICountLivestream } from './components/Model'

export default function DashBoard() {
    const userState = useSelector((state: any) => state.authReducer)?.userInfo
    const [fromDaytoDay, setFromDaytoDay] = useState([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [dataCard, setDataCard] = useState<any>({
        count_revenue: undefined,
        count_purchased_gift: 0,
        count_livestream: 0,
        count_users: 0,
    })
    const [order, setOrder] = useState({
        count_cancelled: 0,
        count_completed: 0,
        count_inprogress: 0,
        count_wait_confirmation: 0,
    })
    const [dataDashboardOrder, setDataDashboardOrder] = useState<any>({})
    const [sumLiveStream, setSumLiveStream] = useState<any>()
    const [stateLoading, setStateLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [fromDaytoDay])

    const getData = async () => {
        setStateLoading(true)
        try {
            const res = await getDataOverviews({
                from_date: fromDaytoDay[0],
                to_date: fromDaytoDay[1],
            })
            setDataCard({
                count_revenue: res.data.count_revenue,
                count_purchased_gift: res.data.count_purchased_gift,
                count_livestream: res.data?.count_livestream,
                count_users: res.data?.count_users,
            })
            setOrder(res.data.order)
            setDataDashboardOrder({
                ...res?.data?.chart_order,
                datasets: res?.data?.chart_order?.datasets?.map((item: any) => {
                    delete item?.type
                    return { ...item }
                }),
            })
            setSumLiveStream(res.data.Tklivestream)
        } catch (error) {
            console.log(error)
        } finally {
            setStateLoading(false)
        }
    }

    return (
        <div>
            <div style={{ margin: '10px' }}>
                <PageHeader
                    style={{ backgroundColor: 'white', marginBottom: '15px' }}
                    title="Tổng quan"
                    extra={[
                        <Space>
                            <Header
                                fromDaytoDay={fromDaytoDay}
                                dateOnSubmit={(x: string, y: string) => {
                                    setFromDaytoDay([x, y])
                                }}
                            />
                        </Space>,
                    ]}
                />
                <Spin spinning={stateLoading}>
                    <div style={{ marginBottom: '15px' }}>
                        <Cards
                            count_revenue={dataCard?.count_revenue}
                            count_purchased_gift={dataCard?.count_purchased_gift}
                            count_livestream={dataCard?.count_livestream}
                            count_users={dataCard?.count_users}
                        />
                    </div>
                    <Row style={{ marginBottom: '15px' }}>
                        <OrderDashboard data={dataDashboardOrder} order={order} />
                    </Row>
                </Spin>
                <div
                    style={{
                        marginBottom: '15px',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                    }}
                >
                    <ChartLive sumLiveStream={sumLiveStream} />
                </div>
            </div>
        </div>
    )
}
