import { message, Table } from 'antd'
import { useState } from 'react'
import reactotron from 'ReactotronConfig'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { IProps } from './interface'
import { formatPrice } from 'utils/ruleForm'
const WapperStyled = styled.div`
  background-color: white;
  margin: 0px 10px 15px;
  padding: 10px 20px;
`

export function TableCutom({
  clickRow: click,
  columns,
  data,
  paging,
  isLoading,
  changePaging,
  expand,
}: IProps) {
  const heightWeb = useSelector(
    (state: any) => state.configReducer.dimension.height
  )
  const [currentSelected, setCurrentSelected] = useState({ id: -1 })

  return (
    <>
      <WapperStyled>
        <p>
          Kết quả lọc: <b>{formatPrice(paging.total)}</b>
        </p>
        <Table
          className="table-expanded-custom-no-image"
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            y: `calc(${heightWeb}px - 330px)`,
          }}
          bordered
          dataSource={data}
          loading={isLoading}
          columns={columns}
          expandedRowKeys={[currentSelected.id]}
          onRow={(record, rowIndex) => ({
            onClick: () => {
              click(record)
              // if (currentSelected !== r) setCurrentSelected(r)
              // else setCurrentSelected({ id: -1 })
            },
          })}
          expandable={{
            expandedRowRender: expand,
            onExpand: (status: any, r: any) => {
              if (currentSelected !== r) setCurrentSelected(r)
              else setCurrentSelected({ id: -1 })
            },
          }}
          pagination={
            paging && changePaging
              ? {
                  ...paging,
                  onChange: async (page, pageSize) => {
                    changePaging(page)
                  },
                  showSizeChanger: false,
                }
              : false
          }
        />
      </WapperStyled>
    </>
  )
}

export default Table
