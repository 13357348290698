import { ApiClient } from 'services/ApiService'

export const getListSaleStaff = (payload: any) => ApiClient.get(`/admin/sale-staff`, payload)

export const getListCustomersByStaff = (id: any, payload: any) =>
    ApiClient.get(`/admin/sale-staff/${id}/list_customer`, payload)

export const getSaleStaffDetail = (id: any) => ApiClient.get(`/admin/sale-staff/${id}`)

export const deleteSaleStaff = (id: any) => ApiClient.delete(`/admin/sale-staff/${id}`)

export const changeSaleStaff = (id: any) => ApiClient.put(`/admin/sale-staff/${id}/toggle_status`)

export const changeSalePassword = (id: any, payload: any) =>
    ApiClient.put(`/admin/sale-staff/${id}/change_password`, payload)

export const addSaleStaff = (payload: any) => ApiClient.post(`/admin/sale-staff`, payload)

export const getListCustomerGroup = (payload: any) => ApiClient.get(`/admin/group`, payload)

export const updateSaleStaff = (id: any, payload: any) => ApiClient.put(`/admin/sale-staff/${id}`, payload)
export const exportExcel = (payload: any) => ApiClient.get(`/admin/sale-staff/export-excel`, payload)
export const exportExcelDetail = (payload: any) =>
    ApiClient.get(`/admin/sale-staff/${payload.id}/export_list_customer`, payload)
