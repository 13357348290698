import { createSlice } from '@reduxjs/toolkit'

interface IinitialState {
    orderIds: number[]
    orderList: any[]
}

const initialState: IinitialState = {
    orderIds: [],
    orderList: [],
}

export const refundOrderSlice = createSlice({
    name: 'refundOrderSlice',
    initialState,
    reducers: {
        // -- Đơn hoàn trả

        // Chọn nhiều
        setMultipleRefOrderIds: (state, action) => {
            const status = action?.payload?.selected
            const targetList = action?.payload?.newListKeys

            // Tích chọn
            if (status) {
                state.orderIds = [...state.orderIds, ...targetList]
            } else {
                state.orderIds = targetList
            }
        },
        setMultipleRefOrderData: (state, action) => {
            const status = action?.payload?.selected
            const targetList = action?.payload?.changeRows

            // Tích chọn
            if (status) {
                state.orderList = [...state.orderList, ...targetList]
            } else {
                state.orderList = targetList
            }
        },

        // Chọn 1
        setOneRefOrderId: (state, action) => {
            const status = action?.payload?.selected
            const targetItem = action?.payload?.record

            // Tích chọn
            if (status) {
                state.orderIds = [...state.orderIds, targetItem?.id]
            } else {
                const newList = state.orderIds?.filter((item: any) => item !== targetItem?.id)
                state.orderIds = newList
            }
        },
        setOneRefOrderData: (state, action) => {
            const status = action?.payload?.selected
            const targetItem = action?.payload?.record

            // Tích chọn
            if (status) {
                state.orderList = [...state.orderList, targetItem]
            } else {
                const newList = state.orderList?.filter((item: any) => item?.id !== targetItem?.id)
                state.orderList = newList
            }
        },

        resetRefOrders: state => {
            state.orderIds = []
            state.orderList = []
        },
    },
})

export const { setOneRefOrderData, setMultipleRefOrderIds, setOneRefOrderId, resetRefOrders, setMultipleRefOrderData } =
    refundOrderSlice.actions
export default refundOrderSlice.reducer
