import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Button } from 'antd'
import Icon, {
  CloseCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  background-color: white;
`
type ButtonFixedProps = {
  setIsCreate: any
  text?: any
  icon?: any
  // style?: any
  type?: any
}

const ButtonFixed = ({ text, setIsCreate, type }: ButtonFixedProps) => {
  return (
    <Button
      // style={{
      //   minWidth: '70px',
      //   backgroundColor: '#00abba',
      //   borderColor: '#00abba',
      //   color: 'white',
      // }}
      style={
        type === 'add'
          ? {
              minWidth: '70px',
              backgroundColor: '#00abba',
              borderColor: '#00abba',
              color: 'white',
            }
          : type === 'delete'
          ? {
              minWidth: '70px',
              backgroundColor: '#ff4d4f',
              borderColor: '#ff4d4f',
              color: 'white',
            }
          : {}
      }
      onClick={() => {
        setIsCreate(true)
      }}
    >
      {type === 'add' ? (
        <PlusCircleOutlined />
      ) : type === 'delete' ? (
        <CloseCircleOutlined />
      ) : (
        ''
      )}
      {text}
    </Button>
  )
}

export default ButtonFixed
