import {
    DollarOutlined,
    EditOutlined,
    EnvironmentOutlined,
    GiftOutlined,
    PhoneOutlined,
    TransactionOutlined,
    UserOutlined,
} from '@ant-design/icons'
import { Col, Descriptions, Row, Tag, Typography, message } from 'antd'
import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { notificationError } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { convertTimeStampToString } from 'utils/TimerHelper'
import {
    addCustomerNote,
    applyDebt,
    getDetailCustomer,
    getListNotes,
    updateCusType,
    updateCusrNote,
    updateCustomerNote,
} from '../../CustomerAPI'
import { ICustomerDetail, IFormateDataCustomers } from '../../CustomerInterfaces'
import EditNoteModal from 'features/admin/refund-order/components/EditNoteModal'
import CustomerNoteModal from '../CustomerNoteModal'
import UpdateCustomerTypeModal from '../UpdateCustomerTypeModal'
import ApplyDebtModal from '../ApplyDebtModal'

const { Text } = Typography

interface ITextStyled {
    blue?: boolean
    green?: boolean
    red?: boolean
    t?: boolean
}

const TitleStyle = styled.p`
    margin-bottom: 0;
    padding-bottom: 6px;
`

const TextStyled = styled.p<ITextStyled>`
    font-weight: bolder;
    margin-bottom: 5px;
    font-size: ${props => (props.t ? '1.1em' : '1.5em')};
    color: ${props => (props.green ? 'green' : null)};
    color: ${props => (props.blue ? 'blue' : null)};
    color: ${props => (props.red ? 'red' : null)};
`

const WapperContent = styled.div`
    margin-top: 20px;
    text-align: center;
`

interface IProps {
    dataCustomer: IFormateDataCustomers
    callback: boolean
    isApplyDebtModal?: boolean
    isLoading?: boolean
    setIsApplyDebtModal?: any
    setIsLoading?: any
    isIndividual?: boolean
}

function CustomerInfo(props: IProps) {
    const { isApplyDebtModal, isLoading, setIsApplyDebtModal, setIsLoading, isIndividual } = props
    const dataCustomerDetail = props.dataCustomer
    const [customerDetail, setcustomerDetail] = useState<any>()
    const [isCustomerNoteVisible, setIsCustomerNoteVisible] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<string>('1')
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedNote, setSelectedNote] = useState<any>()

    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
    const [isEditTypeModalOpen, setIsEditTypeModalOpen] = useState<boolean>(false)
    const [notes, setNotes] = useState<any[]>([])
    const [paging, setPaging] = useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const getData = async () => {
        try {
            const res = await getDetailCustomer(dataCustomerDetail.id.toString())
            if (res.data) {
                setcustomerDetail(res.data)
            }
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra')
        }
    }

    const updateOrderNote = async (payload: any) => {
        try {
            setLoading(true)
            let res: any
            if (!selectedNote) {
                res = await addCustomerNote(customerDetail?.id, payload)
            } else {
                res = await updateCusrNote(selectedNote?.id, payload)
            }
            if (res?.status) {
                message.success(
                    !selectedNote
                        ? 'Thêm mới ghi chú khách hàng thành công!'
                        : 'Cập nhật ghi chú khách hàng thành công!'
                )
                setIsEditModalOpen(false)
                getNotes()
                getData()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const updateCustomerType = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                classification_id: values.type ? values.type : null,
            }
            const res = await updateCusType(customerDetail?.id, payload)
            if (res?.status) {
                message.success('Cập nhật loại khách hàng thành công!')
                setIsEditTypeModalOpen(false)
                getNotes()
                getData()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getNotes = async () => {
        try {
            setLoading(true)
            const payload = {
                id: dataCustomerDetail.id,
                type: Number(activeTab),
                page: 1,
                limit: 999,
            }
            const res = await getListNotes(payload)
            if (res?.status) {
                setNotes(res?.data)
                const newPaging = {
                    ...paging,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [props.callback])

    useEffect(() => {
        getNotes()
    }, [])

    return (
        <>
            {isEditModalOpen && (
                <EditNoteModal
                    setIsModalOpen={setIsEditModalOpen}
                    isModalOpen={isEditModalOpen}
                    data={selectedNote}
                    updateOrderNote={updateOrderNote}
                    loading={loading}
                />
            )}
            {isApplyDebtModal && (
                <ApplyDebtModal
                    customerDetail={customerDetail}
                    setVisible={setIsApplyDebtModal}
                    visible={isApplyDebtModal}
                    loading={isLoading}
                    setIsLoading={setIsLoading}
                    getData={getData}
                    cusId={dataCustomerDetail?.id}
                />
            )}
            {isEditTypeModalOpen && (
                <UpdateCustomerTypeModal
                    setVisible={setIsEditTypeModalOpen}
                    visible={isEditTypeModalOpen}
                    loading={loading}
                    handleFinish={updateCustomerType}
                    value={customerDetail?.customer_classification?.id}
                />
            )}
            {isCustomerNoteVisible && (
                <CustomerNoteModal
                    isVisible={isCustomerNoteVisible}
                    setIsVisible={setIsCustomerNoteVisible}
                    setIsEditModalOpen={setIsEditModalOpen}
                    userId={dataCustomerDetail.id}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    paging={paging}
                    setPaging={setPaging}
                    getNotes={getNotes}
                    notes={notes}
                    loading={loading}
                    setLoading={setLoading}
                    setSelectedNote={setSelectedNote}
                />
            )}
            <Row gutter={[16, 16]}>
                <Col xxl={12} xl={12} md={12}>
                    <Descriptions title={<TitleStyle>Thông tin KH</TitleStyle>}>
                        <Descriptions.Item label={<UserOutlined />}>
                            {customerDetail?.full_name || '---'}
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label={<PhoneOutlined />}>
                            {customerDetail?.phone_number || '---'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<GiftOutlined />}>
                            {customerDetail?.date_of_birth
                                ? convertTimeStampToString(customerDetail?.date_of_birth)
                                : '---'}
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label={<EnvironmentOutlined />}>
                            {customerDetail?.province?.name ? customerDetail?.province?.name : '---'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={3}
                            label={
                                <div>
                                    <TransactionOutlined /> Điểm tích luỹ
                                </div>
                            }
                        >
                            {customerDetail?.wallet?.point ? formatPrice(customerDetail?.wallet?.point) : '0'} điểm
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={3}
                            label={
                                <div>
                                    <DollarOutlined /> Xu
                                </div>
                            }
                        >
                            {customerDetail?.wallet?.coin ? formatPrice(customerDetail?.wallet?.coin) : '0 '}
                            {'  '}
                            xu
                        </Descriptions.Item>
                        {!isIndividual && (
                            <>
                                <Descriptions.Item span={3} label={'Số ngày nợ'}>
                                    {customerDetail?.debt_days ? `${customerDetail?.debt_days} ngày` : '---'}
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={'Trạng thái công nợ'}>
                                    {customerDetail?.is_apply_debit === 1 ? (
                                        <Tag color="green">Đang áp dụng</Tag>
                                    ) : (
                                        <Tag color="red">Không áp dụng</Tag>
                                    )}
                                </Descriptions.Item>
                                {customerDetail?.is_apply_debit && (
                                    <>
                                        <Descriptions.Item span={3} label={'Hạn mức công nợ'}>
                                            {formatPrice(customerDetail?.max_debit)} VNĐ
                                        </Descriptions.Item>
                                        <Descriptions.Item span={3} label={'Thời gian công nợ đơn hàng'}>
                                            {customerDetail?.max_debit_time} ngày
                                        </Descriptions.Item>
                                    </>
                                )}
                            </>
                        )}

                        <Descriptions.Item span={3} label={'Phân loại khách hàng'}>
                            {customerDetail?.customer_classification?.name}
                            <EditOutlined
                                style={{ cursor: 'pointer', marginLeft: 10, marginTop: 4 }}
                                onClick={() => setIsEditTypeModalOpen(true)}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    <div style={{ display: 'flex', marginBottom: 20 }}>
                        <p style={{ fontWeight: 400, margin: 0 }}>Thông tin ghi chú về khách hàng:</p>
                        <EditOutlined
                            style={{ cursor: 'pointer', marginLeft: 10, marginTop: 4 }}
                            onClick={() => setIsCustomerNoteVisible(true)}
                        />
                    </div>
                    {notes.slice(0, 3)?.map((item: any, index: number) => (
                        <p key={item?.id}>
                            {index + 1},{' '}
                            {item?.note.split('\n')?.map((smallItem: any) => (
                                <>
                                    <b style={{ marginTop: -2 }} key={smallItem}>
                                        {smallItem}
                                    </b>
                                    <br />
                                </>
                            ))}
                        </p>
                    ))}
                    {notes?.length > 3 && (
                        <p
                            onClick={() => setIsCustomerNoteVisible(true)}
                            style={{ fontSize: 12, color: '#3280cf', cursor: 'pointer' }}
                        >
                            Xem thêm
                        </p>
                    )}
                </Col>
                <Col xxl={12} xl={12} md={12}>
                    <TextStyled t>Tổng quan bán hàng</TextStyled>
                    <Row gutter={[16, 16]} justify="space-around">
                        <Col>
                            <WapperContent>
                                <TextStyled red>
                                    {customerDetail?.turnover_of_order
                                        ? formatPrice(customerDetail?.turnover_of_order)
                                        : 0}
                                </TextStyled>
                                <Text>Doanh số trên đơn hàng</Text>
                            </WapperContent>
                        </Col>
                        <Col>
                            <WapperContent>
                                <TextStyled green>
                                    {customerDetail?.debt ? formatPrice(customerDetail?.debt) : 0}
                                </TextStyled>
                                <Text>Công nợ hiện tại</Text>
                            </WapperContent>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} justify="space-around">
                        <Col>
                            <WapperContent>
                                <TextStyled blue>
                                    {customerDetail?.total_product_bought ? customerDetail?.total_product_bought : 0}
                                </TextStyled>
                                <Text>Sản phẩm đã mua</Text>
                            </WapperContent>
                        </Col>
                        <Col>
                            <WapperContent>
                                <TextStyled blue>
                                    {customerDetail?.total_order ? customerDetail?.total_order : 0}
                                </TextStyled>
                                <Text>Tổng đơn hàng</Text>
                            </WapperContent>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default memo(CustomerInfo)
