import Customers from './component/Customers'

function index({location}:any) {
  return (
    <>
      <Customers location={location} />
    </>
  )
}

export default index
