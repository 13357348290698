import { Form, FormInstance, Select } from 'antd'
import React from 'react'
import { toNonAccentVietnamese } from 'utils/Configs'

interface ICategorySelect {
    record: any
    value: any
    categories: any[]
    form: FormInstance<any>
    setCallback: any
    disabled: boolean
}

const CategorySelect = (props: ICategorySelect) => {
    const { record, value, categories, form, setCallback, disabled } = props

    return (
        <Form.Item
            style={{ width: '100%' }}
            initialValue={value}
            name={`category_${record?.id}`}
            rules={[{ required: true, message: 'Vui lòng chọn danh mục sản phẩm!' }]}
        >
            <Select
                placeholder="Chọn danh mục sản phẩm"
                style={{ width: '100%' }}
                disabled={disabled}
                showArrow
                showSearch
                onChange={(value: any) => {
                    form.setFieldValue(`product_${record?.id}`, undefined)
                    form.setFieldValue(`quantity_${record?.id}`, 1)
                    form.setFieldValue(`unit_${record?.id}`, '---')
                    setCallback((prev: number) => prev + 1)
                }}
                options={categories}
                filterOption={(input, option: any) => {
                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                    return nonAccent.includes(nonAccentInput)
                }}
            />
        </Form.Item>
    )
}

export default CategorySelect
