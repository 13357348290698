import AuthReducer from './auth/AuthSlice'
import NotificationReducer from 'common/components/header/NotificationSlice'
import ConfigReducer from 'common/ConfigSlice'
import StatusReducer from './admin/product_clone/StatusSlice'
import GetDataSlice from './admin/config-clone/GetDataSlice'
import MessageNotReadReducer from 'features/admin/chat/slices/MessageNotReadSlice'
import CustomerSlice from './admin/customer-clone/CustomerSlice'
import RefundOrderSlice from './admin/refund-order/services/RefundOrderSlice'
import OrderSlice from './admin/order-clone/OrderSlice'
import ProducSlice from './admin/product_clone/Products/ProductSlice'

const rootReducer = {
    authReducer: AuthReducer,
    notificationReducer: NotificationReducer,
    configReducer: ConfigReducer,
    statusReducer: StatusReducer,
    getDataReducer: GetDataSlice,
    customerReducer: CustomerSlice,
    refundOrderReducer: RefundOrderSlice,
    orderReducer: OrderSlice,
    productReducer: ProducSlice,
    messageNotReadReducer: MessageNotReadReducer,
}

export default rootReducer
