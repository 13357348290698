import { Button, message, PageHeader, Space, Tabs } from 'antd'
import React, { useState } from 'react'
import ExportCsv from 'utils/ExportCSV'
import { formatPrice } from 'utils/ruleForm'
import ByCustomer from './components/ByCustomer'
import ByProduct from './components/ByProduct'
import './components/style.css'
import { getReport } from './SalesReportApi'

const { TabPane } = Tabs

export default function SalesReport() {
  const [keyTabs, setKeyTabs] = useState(1)
  const [params, setParams] = useState<any>()
  const handleTabs = (key: any) => {
    setKeyTabs(parseInt(key))
  }
  return (
    <>
      <div style={{ backgroundColor: 'white', margin: '5px 10px 15px' }}>
        <PageHeader title="Báo cáo bán hàng" />
      </div>

      <div style={{ margin: '5px 10px 15px' }}>
        <Tabs
          style={{
            padding: '0 10px',
            backgroundColor: 'white',
          }}
          onChange={handleTabs}
        >
          <TabPane tab="Báo cáo bán hàng theo sản phẩm" key="1">
            <ByProduct keyTabs={keyTabs} />
          </TabPane>
          <TabPane tab="Báo cáo bán hàng theo khách hàng" key="2">
            <ByCustomer keyTabs={keyTabs} />
          </TabPane>
        </Tabs>
      </div>
    </>
  )
}
