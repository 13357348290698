import { createSlice } from '@reduxjs/toolkit'
import LocalStorage from 'utils/LocalStorage'

interface IinitialState {
    orderIds: number[]
    orderList: any[]
    isExportingExcel: boolean
}

const initialState: IinitialState = {
    orderIds: [],
    orderList: [],
    isExportingExcel: LocalStorage.getIsExportingExcel() === '1', // 1 - có, 2 - không
}

export const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
        // Xử lý khi xuất excel
        setIsExportingExcel: (state, action) => {
            state.isExportingExcel = action.payload
        },

        // Tích chọn nhiều
        setMultipleOrderIds: (state, action) => {
            const status = action?.payload?.selected
            const targetList = action?.payload?.newListKeys

            // Tích chọn
            if (status) {
                state.orderIds = [...state.orderIds, ...targetList]
            } else {
                state.orderIds = targetList
            }
        },

        setMultipleOrderData: (state, action) => {
            const status = action?.payload?.selected
            const targetList = action?.payload?.changeRows

            // Tích chọn
            if (status) {
                state.orderList = [...state.orderList, ...targetList]
            } else {
                state.orderList = targetList
            }
        },

        // Tích chọn 1
        setOneOrderIds: (state, action) => {
            const status = action?.payload?.selected
            const targetItem = action?.payload?.record

            // Tích chọn
            if (status) {
                state.orderIds = [...state.orderIds, targetItem?.id]
            } else {
                const newList = state.orderIds?.filter((item: any) => item !== targetItem?.id)
                state.orderIds = newList
            }
        },
        setOneOrderData: (state, action) => {
            const status = action?.payload?.selected
            const targetItem = action?.payload?.record

            // Tích chọn
            if (status) {
                state.orderList = [...state.orderList, targetItem]
            } else {
                const newList = state.orderList?.filter((item: any) => item?.id !== targetItem?.id)
                state.orderList = newList
            }
        },

        resetOrders: state => {
            state.orderIds = []
            state.orderList = []
        },
    },
})

export const {
    setOneOrderData,
    setMultipleOrderIds,
    setOneOrderIds,
    resetOrders,
    setMultipleOrderData,
    setIsExportingExcel,
} = orderSlice.actions
export default orderSlice.reducer
