import { createGlobalStyle } from 'styled-components'

const Styles = createGlobalStyle`
    .ant-descriptions-item-content,
    .ant-menu-submenu,
    .ant-menu-item,
    .ant-collapse-header,
    .ant-table-thead { 
       font-size: 15px; 
       font-weight:700
       }

    body,
    html,
    a {
        font-family:'Quicksand','Ubuntu', sans-serif;
    }

    .ant-table-expanded-row  .ant-table-cell{
        padding: 12px ;
        font-size: 13px;
    }
    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #eeeeeeee;
        overflow-x: hidden;
    }

    a:hover {
        color: #000;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family:'Quicksand','Ubuntu', sans-serif;
        color: #0a1f44;
        font-size: 2.575rem;
        line-height: 3.0625rem;
        font-weight: 600;
        @media only screen and (max-width: 414px) {
          font-size: 1.625rem;
        }
    }

    p {
        font-weight: 500;
    }

    //css table có ảnh
    .table-expanded-custom .ant-table-row  .ant-table-cell{
        padding-top: 7px;
        padding-bottom: 3px;
    }

    .ant-table-cell {
        font-weight:500;

    }
    .table-expanded-custom .ant-table-expanded-row  .ant-table-cell{
        padding: 0
    }
    .antd-table-custom .ant-table-expanded-row  .ant-table-cell{
        padding: 0
    }

    .ant-tabs-content-holder {
        padding: 10px;
    }

    .table-expanded-custom-padding .ant-table-expanded-row  .ant-table-cell{
        padding: 0
    }
    
    //css table không ảnh
    .table-expanded-custom-no-image .ant-table-row  .ant-table-cell{
        padding-top: 12px;
        padding-bottom: 8px;
    }
    .table-expanded-custom-no-image .ant-table-expanded-row  .ant-table-cell{
        padding: 0
    }
    .antd-table-custom .ant-table-expanded-row  .ant-table-cell{
        padding: 0
    }

    .table-expanded-custom-no-image-padding .ant-table-expanded-row  .ant-table-cell{
        padding: 0
    }
    .ant-upload-list-item {
        margin-top: 0px
    }
`
export default Styles
