import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Input, Row, Select } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { IS_ACTIVE, IS_BANNER_HOME, IS_CHECKED, IS_TYPE_NEWS } from 'utils/constants'
const { RangePicker } = DatePicker
const { Option } = Select
const { Search } = Input

interface IProps {
    onSearchSubmit: (searchKey: string) => void
    onTypeSubmit: (type_id: number) => void
    onBannerHome: (type_id: any, is_home: any) => void
    onStatusSubmit: (status: number) => void
    onDateSubmit: (from_date: string, to_date: string) => void
    isSearchLoading: boolean
}

function Filters({
    onTypeSubmit,
    onSearchSubmit,
    onStatusSubmit,
    onDateSubmit,
    onBannerHome,
    isSearchLoading,
}: IProps) {
    const history = useHistory()

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col>
                    <TypingAutoSearch
                        width={260}
                        placeholder="Nhập tiêu đề bài viết"
                        onSearchSubmit={(value: string) => onSearchSubmit(value)}
                    />
                </Col>
                <Col>
                    <Select
                        allowClear
                        placeholder="Phân loại tin tức"
                        onChange={(value: number) => {
                            onTypeSubmit(value)
                        }}
                    >
                        <Option value={IS_TYPE_NEWS.DRAF}>Bài đăng</Option>
                        <Option value={IS_TYPE_NEWS.INDRAF}>Lưu nháp</Option>
                    </Select>
                </Col>
                <Col>
                    <Select
                        allowClear
                        placeholder="Trạng thái tin tức"
                        onChange={(value: number) => {
                            onStatusSubmit(value)
                        }}
                    >
                        <Option value={IS_ACTIVE.ACTIVE}>Đang hoạt động</Option>
                        <Option value={IS_ACTIVE.INACTIVE}>Ngừng hoạt động</Option>
                    </Select>
                </Col>
                <Col>
                    <Select
                        allowClear
                        showSearch
                        style={{
                            width: 180,
                        }}
                        placeholder="Hiển thị bài viết"
                        optionFilterProp="children"
                        onChange={(value: number | undefined) => {
                            switch (value) {
                                case undefined:
                                    return onBannerHome(undefined, undefined)
                                case IS_BANNER_HOME.BANNER:
                                    return onBannerHome(IS_CHECKED.SHOW, IS_CHECKED.HIDE)
                                case IS_BANNER_HOME.HOME:
                                    return onBannerHome(IS_CHECKED.HIDE, IS_CHECKED.SHOW)
                                case IS_BANNER_HOME.BANNERHOME:
                                    return onBannerHome(IS_CHECKED.SHOW, IS_CHECKED.SHOW)
                            }
                        }}
                    >
                        <Option value={IS_BANNER_HOME.BANNERHOME}>Tất cả</Option>
                        <Option value={IS_BANNER_HOME.BANNER}>Banner</Option>
                        <Option value={IS_BANNER_HOME.HOME}>Home</Option>
                    </Select>
                </Col>
                <Col span={8}>
                    <RangePicker
                        placeholder={['Từ ngày', 'Đến ngày']}
                        onChange={(value, dateString) => {
                            onDateSubmit(dateString[0], dateString[1])
                        }}
                    />
                </Col>
                <Col>
                    <Button type="primary" onClick={() => history.push({ pathname: ADMIN_ROUTER_PATH.NEWS_ADD })}>
                        <PlusCircleOutlined />
                        Thêm mới
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default Filters
