import { ApiClient } from 'services/ApiService'

export const getReport = (payload: any) =>
  ApiClient.get('/admin/report/report-sell', payload)

export const getAllCategory = () => ApiClient.get(`admin/product_category/list`)

export const exportExcel = (payload: any) =>
  ApiClient.post(
    `/admin/report/export_to_excel?type=${payload?.type}&search=${payload?.search}&from_date =${payload?.from_date}&to_date=${payload?.to_date}&kiotviet_id=${payload?.kiotviet_id}&category_id=${payload?.category_id}`
  )
