import { PageHeader, Table, Tag, Collapse, Image, message, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ADMIN_ROLE, IS_ACTIVE, TEXT_ACTIVE } from 'utils/constants'
import type { ColumnsType } from 'antd/lib/table'
import Header from './components/Header'
import reactotron from 'ReactotronConfig'
import { deleteAccount, getAccounts, resetPassword } from './AccountApi'
import { IAccountPayload, IFormatedAccount } from './AccountInterfaces'
import AccountDetail from './AccountDetail'
import EditAccount from './components/AddEditAcount'
import styled from 'styled-components'
import Text from 'antd/lib/typography/Text'
import { notificationError } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { renderRole } from 'utils/Configs'

const WapperListAccount = styled.div`
    background-color: white;
    margin: 0px 10px 15px;
    padding: 10px 20px;
`

function AdAccount() {
    const heightWeb = useSelector((state: any) => state.configReducer.dimension.height)
    const [params, setParams] = useState<IAccountPayload>({
        page: '',
        limit: '12',
        search: '',
    })
    const [listAccounts, setlistAccounts] = useState<IFormatedAccount[]>([])
    const [currentSelected, setCurrentSelected] = useState<any>({ id: -1 })
    const [showAddAccount, setShowAddAccount] = useState<boolean>(false)
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [paging, setPaging] = useState({
        total: 0,
        current: 1,
        pageSize: 0,
    })

    useEffect(() => {
        getData()
    }, [params])

    const getData = async () => {
        setisLoading(true)
        try {
            const res = await getAccounts(params)
            if (res.data) {
                const tableAccount = res.data.map((account, index) => {
                    return { ...account, key: index, stt: index + 1 }
                })
                setlistAccounts(tableAccount)
            }
            if (res.paging) {
                setPaging({
                    total: res.paging.totalItemCount,
                    current: res.paging.page,
                    pageSize: res.paging.limit,
                })
            }
        } catch (err) {
            console.log(err)
            message.error('Có lỗi xảy ra')
        } finally {
            setisLoading(false)
        }
    }

    const renderStatus = (status: number) => {
        return (
            <Tag color={status === IS_ACTIVE.ACTIVE ? 'success' : 'volcano'}>
                {status === IS_ACTIVE.ACTIVE ? TEXT_ACTIVE.ACTIVE : TEXT_ACTIVE.INACTIVE}
            </Tag>
        )
    }

    const columns: ColumnsType<IFormatedAccount> = [
        {
            width: 70,
            title: 'STT',
            dataIndex: '',
            render: (text: any, record: IFormatedAccount, index: any) => (
                <Text id={record.id.toString()}>{(paging.current - 1) * paging.pageSize + index + 1}</Text>
            ),
        },
        {
            title: 'Họ tên',
            dataIndex: 'full_name',
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone_number',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: 'Loại tài khoản',
            dataIndex: 'role',
            render(value: number) {
                return <Text>{renderRole(value)}</Text>
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderStatus(status),
        },
    ]

    const handleSearchSubmit = (searchKey: string) => {
        setParams({ ...params, search: searchKey, page: '1' })
    }

    const handleStatusSubmit = (statusKey: string) => {
        setParams({
            ...params,
            status: statusKey === '' ? undefined : statusKey,
            page: '1',
        })
    }
    const handleRoleSubmit = (roleKey: any) => {
        setParams({ ...params, role: roleKey, page: '1' })
    }

    const handleDeleteAccount = async (id: number, record: IFormatedAccount) => {
        try {
            const res = await deleteAccount(id.toString())
            getData()
            setCurrentSelected({ id: -1 })
            message.success('Đã xoá tài khoản thành công')
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra')
        }
    }

    const handleResetPassword = async (id: number, record: IFormatedAccount) => {
        try {
            await resetPassword(id.toString())
            message.success(`Đặt lại mật khẩu thành công`)
            getData()
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra')
        }
    }

    return (
        <>
            <PageHeader
                title="Tài khoản"
                style={{ backgroundColor: 'white', margin: '5px 10px 15px' }}
                extra={[
                    <Header
                        isLoading={isLoading}
                        setIsCreate={setShowAddAccount}
                        onSearchSubmit={(searchKey: string) => handleSearchSubmit(searchKey)}
                        onStatusSubmit={(statusKey: string) => handleStatusSubmit(statusKey)}
                        onRoleSubmit={(roleKey: any) => handleRoleSubmit(roleKey)}
                    />,
                ]}
            />
            <div className="list-content">
                <WapperListAccount>
                    <p>
                        Kết quả lọc:<b>{formatPrice(paging.total)}</b>
                    </p>

                    <Table
                        className="table-expanded-custom"
                        scroll={
                            listAccounts?.length > 1
                                ? {
                                      x: 800,
                                      scrollToFirstRowOnChange: true,
                                      y: `calc(${heightWeb}px - 330px)`,
                                  }
                                : {}
                        }
                        size={'middle'}
                        bordered
                        dataSource={listAccounts}
                        columns={columns}
                        expandedRowKeys={[currentSelected.key]}
                        expandable={{
                            expandedRowRender: record => (
                                <AccountDetail
                                    onDeleteAccount={(id: any) => handleDeleteAccount(id, record)}
                                    onResetPassword={(id: any) => handleResetPassword(id, record)}
                                    data={record}
                                    getData={getData}
                                />
                            ),
                            onExpand: (status: any, r: any) => {
                                if (currentSelected !== r) setCurrentSelected(r)
                                else setCurrentSelected({ id: -1 })
                            },
                        }}
                        pagination={{
                            ...paging,
                            showSizeChanger: false,
                            onChange: async page => {
                                setParams({ ...params, page: page.toString() })
                            },
                        }}
                    />
                </WapperListAccount>
                {showAddAccount && (
                    <EditAccount
                        getListAccount={() => getData()}
                        visible={showAddAccount}
                        onCancel={() => {
                            setShowAddAccount(false)
                        }}
                        isLoadingButton={false}
                    />
                )}
            </div>
        </>
    )
}

export default AdAccount
