import { Image, Modal, Upload, message } from 'antd'
import type { UploadProps } from 'antd/es/upload'
import { UploadFile, UploadListType } from 'antd/lib/upload/interface'
import axios from 'axios'
import { SESSION_ID } from 'common/config'
import Cookies from 'js-cookie'
import React, { ReactNode } from 'react'
import { FaPlus } from 'react-icons/fa'
import styled from 'styled-components'
type uploadType = 'single' | 'list'
interface IProps {
    onSuccessUpload: (file: any, fileList: any) => void
    isUploadServerWhenUploading?: boolean
    isShowFileList?: boolean
    children?: ReactNode
    uploadType?: uploadType
    accept?: string
    listType?: UploadListType
    maxLength?: number
    fileList?: any
    initialFile?: any
    isUrl?: boolean
    index?: number
    onRemoveIndex?: any
}

const UploadComponent: React.FC<IProps> = ({
    accept = 'image/*',
    listType = 'text',
    uploadType = 'single',
    isShowFileList = true,
    isUploadServerWhenUploading = false,
    onSuccessUpload,
    children,
    maxLength = 5,
    fileList = [],
    isUrl,
    index,
    onRemoveIndex,
}) => {
    const [files, setFiles] = React.useState<UploadFile[]>([])
    const [progress, setProgress] = React.useState(0)
    const [visiblePreview, setVisiblePreview] = React.useState(false)
    const [previewOpen, setPreviewOpen] = React.useState(false)
    const [previewImage, setPreviewImage] = React.useState('')
    const [previewTitle, setPreviewTitle] = React.useState('')

    const uploadImage = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options
        if (isUploadServerWhenUploading) {
            const fmData = new FormData()
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    token: Cookies.get(SESSION_ID),
                },
                onUploadProgress: (event: any) => {
                    const percent = Math.floor((event.loaded / event.total) * 100)
                    setProgress(percent)
                    if (percent === 100) {
                        setTimeout(() => setProgress(0), 1000)
                    }
                    onProgress({ percent: (event.loaded / event.total) * 100 })
                },
            }
            fmData.append('file', file)
            try {
                const res: any = await axios.post(
                    'https://httapi.winds.vn/api/v1/files/upload/single/1',
                    fmData,
                    config
                )
                console.log(res)

                if (res.data.status) {
                    if (isUrl) {
                        onSuccessUpload(res?.data?.data?.url, files)
                    } else {
                        onSuccessUpload(res.data, files)
                    }
                    onSuccess('Ok')
                } else {
                    message.error(res?.data?.message)

                    file.status = 'error'
                    const error = new Error('Some error')
                    if (uploadType === 'single') {
                        setFiles([file])
                    } else {
                        setFiles(f => [...f.filter(_f => _f.status !== 'uploading'), file])
                    }
                    onError({ error })
                }
            } catch (err) {
                file.status = 'error'
                const error = new Error('Some error')
                if (uploadType === 'single') {
                    setFiles([file])
                } else {
                    setFiles(f => [...f.filter(_f => _f.status !== 'uploading'), file])
                }
                onError({ error })
            }
        } else {
            setTimeout(() => onSuccess('ok'), 1000)
        }
    }

    React.useEffect(() => {
        if (fileList && fileList.length > 0) {
            setFiles(fileList)
        }
    }, [fileList])

    const handleOnChange: UploadProps['onChange'] = ({ file, fileList, event }) => {
        if (file.status !== 'error') {
            setFiles(fileList)
        }
        !isUploadServerWhenUploading && file.status === 'done' && onSuccessUpload(file, fileList)
    }

    const handleRemove = async (file: any) => {
        if (maxLength === 1) {
            onSuccessUpload(null, null)
        } else {
            onRemoveIndex(file)
        }
    }

    const handleCancel = () => setPreviewOpen(false)

    const handlePreviewIamge = async (file: UploadFile) => {
        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    }

    return (
        <>
            <UploadStyled
                accept={accept}
                customRequest={uploadImage}
                onChange={handleOnChange}
                listType={listType}
                fileList={isShowFileList ? files : []}
                className="image-upload-grid"
                onPreview={handlePreviewIamge}
                onRemove={handleRemove}
            >
                {files.length >= maxLength ? null : uploadType === 'single' && files.length >= 1 ? null : listType ===
                  'text' ? (
                    children
                ) : (
                    <FaPlus style={{ color: 'gray' }} />
                )}
            </UploadStyled>
            <Modal
                style={{ height: 500 }}
                width={550}
                visible={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: 500, height: 500, objectFit: 'contain' }} src={previewImage} />
            </Modal>
            {/* {listType !== 'text' && (
                <Image.PreviewGroup
                    preview={{
                        visible: visiblePreview,
                        onVisibleChange: visible => setVisiblePreview(visible),
                    }}
                >
                    {files.map((file: UploadFile) => {
                        return (
                            <Image
                                key={file.uid}
                                src={file?.thumbUrl || file.url}
                                width={0}
                                style={{ display: 'none' }}
                            />
                        )
                    })}
                </Image.PreviewGroup>
            )} */}
        </>
    )
}

const UploadStyled = styled(Upload)`
    & img {
        /* object-fit: none !important; */
    }
    & .ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload-list-picture .ant-upload-list-item {
        padding: 2px;
    }
`

export default UploadComponent
