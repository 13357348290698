import { Form, Modal, Select } from 'antd'
import ButtonForm from 'common/components/ModalButton'
import React from 'react'
import { customerTypes } from '../CustomerAPI'

interface IUpdateCustomerTypeModal {
    visible: boolean
    setVisible: any
    handleFinish: any
    loading: boolean
    value: any
}

const UpdateCustomerTypeModal = (props: IUpdateCustomerTypeModal) => {
    const { visible, setVisible, handleFinish, loading, value } = props
    const [listTypes, setListTypes] = React.useState<any[]>([])
    const [form] = Form.useForm()

    const getListType = async () => {
        try {
            const res = await customerTypes()
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setListTypes(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        getListType()
    }, [])

    return (
        <Modal
            title="Cập nhật loại khách hàng"
            destroyOnClose
            maskClosable={false}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <Form preserve={false} onFinish={handleFinish} form={form} initialValues={{ type: value }}>
                <Form.Item name="type">
                    <Select
                        allowClear
                        options={listTypes}
                        style={{ width: '100%' }}
                        placeholder="Chọn loại khách hàng"
                    />
                </Form.Item>

                <ButtonForm isLoadingButton={loading} onCancel={() => setVisible(false)} text={'Lưu'} />
            </Form>
        </Modal>
    )
}

export default UpdateCustomerTypeModal
