import { ArrowsAltOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Card, Col, Image, Popconfirm, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { momentToStringDate } from 'utils/TimerHelper'

interface INoteItem {
    isShowEdit: boolean
    setIsEditModalOpen: any
    item: any
    removeNote: any
    setSelectedNote: any
    isFromOrderDetail?: boolean
}

const NoteItem = (props: INoteItem) => {
    const { isShowEdit, setIsEditModalOpen, item, removeNote, setSelectedNote, isFromOrderDetail } = props
    const listNotes = item?.note?.split('\n')

    return (
        <CustomCard
            title={<b>{momentToStringDate(item?.createdAt, 'dateTime')}</b>}
            extra={
                !isFromOrderDetail ? (
                    <ButtonBlocks>
                        {isShowEdit && (
                            <Button
                                onClick={() => {
                                    setIsEditModalOpen(true)
                                    setSelectedNote(item)
                                }}
                                className="button"
                                icon={<EditOutlined />}
                            />
                        )}
                        <Popconfirm
                            placement="right"
                            title="Bạn có chắc chắn muốn XOÁ ghi chú này?"
                            onConfirm={removeNote}
                            okText="Xoá"
                            cancelText="Đóng"
                            okButtonProps={{
                                type: 'default',
                                danger: true,
                            }}
                            style={{ background: 'red' }}
                        >
                            <a style={{ color: 'red' }} href="#">
                                <DeleteOutlined />
                            </a>
                        </Popconfirm>
                    </ButtonBlocks>
                ) : undefined
            }
        >
            <Row gutter={[64, 64]}>
                <Col xs={24} sm={12} md={12} lg={16} xl={16} xxl={16}>
                    {listNotes?.map((item: any) => (
                        <p key={item}>{item}</p>
                    ))}
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <div className="image_block">
                        {item?.medias?.map((item: any, index: number) => (
                            <div className="image_block">
                                <Image
                                    preview={{
                                        mask: <ArrowsAltOutlined />,
                                    }}
                                    key={index}
                                    alt="image"
                                    src={item?.media_url}
                                />
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </CustomCard>
    )
}

const CustomCard = styled(Card)`
    margin-bottom: 20px;

    p {
        text-align: justify;
    }

    .image_block {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;

        .image_block {
            width: 60px;
            height: 60px;
            border-radius: 10px;
        }

        img {
            width: 100%;
            border-radius: 10px;

            height: 100%;
            object-fit: cover;
            cursor: pointer;
        }
    }
`

const ButtonBlocks = styled.div`
    display: flex;

    a {
        margin-top: 6px;
    }

    .button {
        border: none;
        margin-right: 6px;
        padding: 0;
    }
`

export default NoteItem
