import { ApiClient } from 'services/ApiService'
import { IDataOrders, IOrderPayload } from '../order-clone/Interfaces'
import {
    ICustomerPayload,
    IDataCustomers,
    IDetailDebit,
    IDetailDebitPayload,
    IListDebit,
    IListDebitPayload,
    IAddress,
    ResponseData,
    IWalletPayload,
    IWallet,
    IPointHistory,
    ICoinHistory,
} from './CustomerInterfaces'

export const getCustomers = (payload: ICustomerPayload): Promise<ResponseData<IDataCustomers[]>> =>
    ApiClient.get('/admin/user', payload)

export const getDetailCustomer = (id: string): Promise<ResponseData<IDataCustomers>> =>
    ApiClient.get(`/admin/user/${id}`)

export const getOrderByCustomer = (payload: IOrderPayload): Promise<ResponseData<IDataOrders[]>> =>
    ApiClient.get(`/admin/order/`, payload)

export const getListDebit = (payload: IListDebitPayload): Promise<ResponseData<IListDebit>> =>
    ApiClient.get(`/admin/debit`, payload)

export const getDetailDebit = (payload: IDetailDebitPayload): Promise<ResponseData<IDetailDebit>> =>
    ApiClient.get(`admin/debit/${payload.id}/history`, payload)

export const getProvinces = (): Promise<ResponseData<IAddress[]>> => ApiClient.get(`address/provinces`)

export const getDistricts = (province_id?: number): Promise<ResponseData<IAddress[]>> =>
    ApiClient.get(`address/districts`, { province_id })

export const getWards = (district_id?: number): Promise<ResponseData<IAddress[]>> =>
    ApiClient.get(`address/wards`, { district_id })

export const getWalletPoint = (payload: IWalletPayload): Promise<ResponseData<IPointHistory>> =>
    ApiClient.get(`admin/wallet_change/point`, payload)

export const getWalletCoin = (payload: IWalletPayload): Promise<ResponseData<ICoinHistory>> =>
    ApiClient.get(`admin/wallet_change/coin`, payload)

export const changeStatus = (payload: {
    id: number
    status?: number
    livestream_enable?: number
}): Promise<ResponseData<any>> =>
    ApiClient.path(`admin/user/${payload.id}`, {
        status: payload.status,
        livestream_enable: payload.livestream_enable,
    })

export const changePassword = (payload: { id: number; password: number }): Promise<ResponseData<any>> =>
    ApiClient.put(`admin/user/${payload.id}/password`, {
        password: payload.password,
    })

export const LoadCoin = (payload: any) => ApiClient.post(`admin/wallet/${payload?.id}/coin_up`, payload.data)
export const syncAllCustomer = (kiotVietId: string | undefined) =>
    ApiClient.post(`admin/kiotviet/${kiotVietId}/sync/user`)
export const syncAllOrder = (kiotVietId: string | undefined) =>
    ApiClient.post(`admin/kiotviet/${kiotVietId}/sync/order`)

export const exportExcel = (payload: any) =>
    ApiClient.post(
        `/admin/user/export_to_excel?province_id=${payload?.province_id}&search=${payload?.search}&create_from=${payload?.create_from}&create_to=${payload?.create_to}&kiotviet_id=${payload?.kiotviet_id}&is_retail_cus=${payload?.is_retail_cus}`
    )

export const sendMessage = (payload: any, params: any) =>
    ApiClient.post(
        `/message/toListUsers?province_id=${params?.province_id}&search=${params?.search}&create_from=${params?.create_from}&create_to=${params?.create_to}&kiotviet_id=${params?.kiotviet_id}&is_retail_cus=${params?.is_retail_cus}`,
        payload
    )

export const deleteCustomer = (id: any) => ApiClient.delete(`/admin/user/${id}`)

export const customerTypes = () => ApiClient.get(`/customer-classification`)

export const updateCustomerInfo = (id: any, payload: any) => ApiClient.path(`/admin/user/${id}/info`, payload)

export const updateCustomerNote = (id: any, payload: any) => ApiClient.path(`/admin/user/${id}/note`, payload)

export const getListNotes = (payload: any) => ApiClient.get(`/admin/user/${payload.id}/noteHistory`, payload)
export const deleteNote = (id: any) => ApiClient.delete(`/admin/user/${id}/noteHistory`)
export const addCustomerNote = (id: any, payload: any) => ApiClient.path(`/admin/user/${id}/note`, payload)
export const updateCusrNote = (id: any, payload: any) => ApiClient.put(`/admin/user/${id}/noteHistory`, payload)

export const applyDebt = (id: any, payload: any) => ApiClient.post(`/admin/debit/${id}`, payload)

export const addDebtNote = (id: any, payload: any) => ApiClient.post(`/admin/debit/${id}/noteDebit`, payload)
export const getDebtNote = (id: any, payload: any) => ApiClient.get(`/admin/user/${id}/debitNoteHistory`, payload)

export const updateCusType = (id: any, payload: any) =>
    ApiClient.path(`/admin/user/${id}/customerClassification`, payload)
