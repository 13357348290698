import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd'
import { useState, useEffect, memo } from 'react'
import './component/css/Category.css'
import { IFormatedTableCate } from './CategoryInterfaces'
import ButtonForm from 'common/components/ModalButton'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'

interface IProps {
  ShowEditCate: boolean
  onCancel: () => void
  onSave: (value: number) => void
  infoCate: IFormatedTableCate
}

function EditCate(props: IProps) {
  const isShowEditCate = props.ShowEditCate
  const onCancel = props.onCancel
  const onSave = props.onSave
  const infoCate = props.infoCate

  return (
    <>
      <Modal
        title="Sửa danh mục"
        visible={isShowEditCate}
        onCancel={() => onCancel()}
        footer={null}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ name_cate: infoCate.name, stt: infoCate.order }}
          onFinish={(value: any) => onSave(value.stt)}
          autoComplete="off"
        >
          <Form.Item
            label="Tên danh mục"
            name="name_cate"
            rules={[{ required: true, message: 'Tên danh mục là bắt buộc' }]}
          >
            <Input type={'text'} disabled />
          </Form.Item>
          <Form.Item
            label="Thứ tự hiển thị"
            name="stt"
            rules={[{ required: true, message: 'Số thứ tự là bắt buộc' }]}
          >
            <InputNumber  style = {{width : '100%'}}min={1} type="number" />
          </Form.Item>
          <ButtonForm
            isLoadingButton={false}
            onCancel={props.onCancel}
            text="Lưu"
          />
        </Form>
      </Modal>
    </>
  )
}

export default EditCate
