import { LineChartOutlined } from '@ant-design/icons'
import { PageHeader, Pagination, Row, Spin, message } from 'antd'
import ButtonAdd from 'common/components/Button/ButtonAdd'
import Container from 'container/Container'
import React from 'react'
import Filter from '../components/Filter'
import { formatPrice } from 'utils/ruleForm'
import PolicyItem from '../components/PolicyItem'
import { deletePolicy, getListPolicy } from '../service'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { useHistory } from 'react-router-dom'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useDebounce } from 'common/hooks/Debounce'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

const PolicyPage = () => {
    const history = useHistory()
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isAccountant = userInfor?.admin?.role === ADMIN_ROLE.ACCOUNTANT
    const [loading, setLoading] = React.useState<boolean>(false)
    const [search, setSearch] = React.useState<string>('')
    const searchDebounce = useDebounce(search, 300)
    const [area, setArea] = React.useState<any>() // Kiotviet
    const [department, setDepartment] = React.useState<any>() // Bộ phận áp dụng
    const [fromDate, setFromDate] = React.useState<any>()
    const [toDate, setToDate] = React.useState<any>()
    const [listKiotviet, setlistKiotviet] = React.useState<any[]>([])
    const [policies, setPolicies] = React.useState<any[]>([])
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const getPolicies = async () => {
        try {
            setLoading(true)
            const payload = {
                page: paging.current,
                limit: paging.pageSize,
                from_date: fromDate,
                to_date: toDate,
                role_ids: isAccountant
                    ? '6'
                    : department && department?.length > 0 && !department.includes(-1)
                    ? department.join(',')
                    : undefined,
                kiotviet_ids: area && area?.length > 0 && !area.includes(-1) ? area.join(',') : undefined,
                is_apply_to_all_kiotviet: (area && area.includes(-1)) || isAccountant ? 1 : undefined,
                is_apply_to_all_department: department && department.includes(-1) ? 1 : undefined,
                search: searchDebounce,
            }
            const res = await getListPolicy(payload)
            if (res?.status) {
                setPolicies(res?.data)
                const newPaging = {
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
                setlistKiotviet(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const deletePolicyItem = async (policyId: any) => {
        try {
            setLoading(true)
            const res: any = await deletePolicy(policyId)
            if (res?.status) {
                message.success('Xoá chính sách thành công!')
                getPolicies()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getDataKiotviet()
    }, [])

    React.useEffect(() => {
        if (paging.current === 1) {
            getPolicies()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [area, department, fromDate, toDate, searchDebounce, isAccountant])

    React.useEffect(() => {
        getPolicies()
    }, [paging.current])

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        style={{ borderRadius: 8 }}
                        title="Quy chế chính sách"
                        extra={
                            isAccountant
                                ? []
                                : [
                                      <ButtonAdd
                                          onClickButton={() => history.push(ADMIN_ROUTER_PATH.ADD_EDIT_POLICY)}
                                          text="Thêm mới"
                                      />,
                                  ]
                        }
                    ></PageHeader>
                }
                filterComponent={
                    <Filter
                        setFromDate={setFromDate}
                        setToDate={setToDate}
                        setArea={setArea}
                        setDepartment={setDepartment}
                        listKiotviet={listKiotviet}
                        search={search}
                        setSearch={setSearch}
                    />
                }
                contentComponent={[
                    <>
                        <p>
                            Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                        </p>
                        <div className="">
                            {policies?.map((item: any) => (
                                <PolicyItem
                                    item={item}
                                    key={item?.id}
                                    deletePolicyItem={() => deletePolicyItem(item?.id)}
                                />
                            ))}
                        </div>
                        <Row justify="end">
                            <Pagination {...paging} />;
                        </Row>
                    </>,
                ]}
            />
        </Spin>
    )
}

export default PolicyPage
