import { Spin, Table } from 'antd'
import React from 'react'
import { formatPrice } from 'utils/ruleForm'
import { getAppUseReport } from '../ConfigAPI'

const AppUsingReport = () => {
    const [data, setData] = React.useState<any>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [columns, setColumns] = React.useState<any[]>([])
    const [total, setTotal] = React.useState<number>(0)

    const getData = async () => {
        try {
            setLoading(true)
            const res: any = await getAppUseReport()
            if (res?.status) {
                const formatData = res?.data?.kiotviets?.map((item: any) => ({
                    ...item,
                    [`kiot_${item?.id}`]: item?.count_users,
                }))
                const newArray = formatData
                    .filter((obj: any) => {
                        return Object.keys(obj).some(key => key.includes('kiot'))
                    })
                    .reduce((acc: any, obj: any) => {
                        Object.keys(obj).forEach(key => {
                            if (key.includes('kiot')) {
                                acc[key] = obj[key]
                            }
                        })
                        return acc
                    }, {})
                setData([newArray])
                setTotal(res?.data?.total)
                const listColumns = res?.data?.kiotviets?.map((item: any) => ({
                    title: <b>{item?.name}</b>,
                    dataIndex: `kiot_${item?.id}`,
                }))
                setColumns(listColumns)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getData()
    }, [])

    return (
        <Spin spinning={loading}>
            <span>
                Tổng hệ thống: <b>{formatPrice(total)}</b>
            </span>
            <Table
                style={{ marginTop: 10 }}
                bordered
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 500,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: 'Chưa có bản ghi nào!',
                }}
                pagination={false}
            />
        </Spin>
    )
}

export default AppUsingReport
