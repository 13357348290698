import { useState, useEffect, memo } from 'react'
import { Button, Col, PageHeader, Row, Select, message } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { allProducts, exportExcel, getAllCate, getCategory, listProducts, syncAllProduct } from '../ProductAPI'
import { IS_ACTIVE, PRODUCT_EXPORT, STATUS } from 'utils/constants'
import ExportCsv from 'utils/ExportCSV'
import { DownloadOutlined, SwapOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { notificationError, notificationSuccess } from 'utils/notification'
import { IAllCate, IListProduct, IListProductPayload } from '../ProductInterfaces'
import { IProduct } from 'features/admin/order-clone/Interfaces'
const { Option } = Select

type props = {
    onExport: IListProduct[]
    onSearchSubmit: (searchKey: string) => void
    onCategorySubmit: (cate: string) => void
    onStatusSubmit: (status: string) => void
    kiotVietId: string
    visible: boolean
    region: any
    param: any
}

function Filter(props: props) {
    const { onSearchSubmit, onCategorySubmit, onStatusSubmit, onExport, kiotVietId, visible, region, param } = props
    const [isSearchLoading, setisSearchLoading] = useState(false)
    const [isSyncLoading, setIsSyncLoading] = useState(false)
    const [paramsExport, setParamsExport] = useState({
        limit: 24,
        page: 1,
        category_id: '',
        search: '',
        status: '',
        kiotviet_id: '',
    })
    const [allCate, setallCate] = useState<IAllCate[]>()
    const [dataExport, setdataExport] = useState<any>([])

    const getAllCategory = async () => {
        try {
            const res = await getCategory({
                kiotviet_id: param?.kiotviet_id,
                page: 1,
                limit: 1000,
            })
            if (res.data) {
                setallCate(res.data)
            }
        } catch (err: any) {
            console.log(err)
            notificationError(err)
        }
    }

    useEffect(() => {
        getAllCategory()
    }, [param?.kiotviet_id])

    const syncProduct = async () => {
        try {
            setIsSyncLoading(true)
            const res = await syncAllProduct(region.toString())
            if (res?.status) {
                notificationSuccess('Đang đồng bộ vui lòng chờ một vài phút')
            }
        } catch (error) {
            notificationError('Đã có lỗi xảy ra vui lòng chờ một vài phút và thử lại')
        } finally {
            setIsSyncLoading(false)
        }
    }

    const handleExportData = async () => {
        try {
            const payload = {
                ...param,
                page: undefined,
                limit: undefined,
            }
            const res = await exportExcel(payload)
            if (res?.status) {
                message.success('Xuất excel thành công!')
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <PageHeader
                ghost={false}
                title="Sản phẩm"
                style={{
                    backgroundColor: 'white',
                    margin: '10px',
                }}
                extra={[
                    <Row gutter={[16, 16]} justify="start">
                        <Col>
                            <TypingAutoSearch
                                onSearchSubmit={(searchKey: string) => {
                                    onSearchSubmit(searchKey.trim())
                                }}
                                isSearchLoading={isSearchLoading}
                                placeholder="Tên hoặc mã sản phẩm ..."
                            />
                        </Col>
                        <Col>
                            <Select
                                allowClear
                                showSearch
                                style={{
                                    width: 180,
                                }}
                                placeholder="DM sản phẩm"
                                optionFilterProp="children"
                                onChange={(option: string) => {
                                    onCategorySubmit(option || '')
                                }}
                            >
                                {allCate &&
                                    allCate.map((cate: any, index: number) => (
                                        <Option key={index} value={cate.id}>
                                            {cate.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col>
                            <Select
                                style={{
                                    width: 150,
                                }}
                                placeholder="Trạng thái"
                                optionFilterProp="children"
                                onChange={(option: any) => {
                                    onStatusSubmit(option)
                                    setParamsExport({ ...paramsExport, status: option })
                                }}
                            >
                                <Option value="">Tất cả</Option>
                                <Option value={STATUS.ACTIVE}>Đang hoạt động</Option>
                                <Option value={STATUS.INACTIVE}>Ngừng hoạt động</Option>
                            </Select>
                        </Col>
                        <Col>
                            <ExportCsv
                                onClick={() => handleExportData()}
                                sheetName={['ProductList']}
                                sheets={{
                                    ProductList: ExportCsv.getSheets(dataExport),
                                }}
                                fileName="Danh sách sản phẩm"
                            >
                                <DownloadOutlined />
                                &nbsp; Export
                            </ExportCsv>
                        </Col>
                        {param?.kiotviet_id !== '' && (
                            <Col>
                                <Button
                                    loading={isSyncLoading}
                                    type="primary"
                                    shape="round"
                                    icon={<SwapOutlined />}
                                    onClick={syncProduct}
                                >
                                    Đồng bộ
                                </Button>
                            </Col>
                        )}
                    </Row>,
                ]}
            ></PageHeader>
        </>
    )
}

export default memo(Filter)
