import { Row, Col, Button } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const ButtonSave = styled(Button)`
  font-weight: 800;
  border-radius: 5px;
  background-color: #00abba;
  border-color: #00abba;
  height: 35px;
`

const ButtonCancel = styled(Button)`
  font-weight: 800;
  border-radius: 5px;
  height: 35px;
`

type ButtonBottomModalProps = {
  isLoadingButton?: boolean
  onCancel?: any
  text: string
}

const ButtonForm = ({
  isLoadingButton,
  onCancel,
  text,
}: ButtonBottomModalProps) => {
  return (
    <Row gutter={16} justify="end">
      <Col>
        <ButtonCancel
          disabled={isLoadingButton}
          danger
          onClick={() => {
            onCancel()
          }}
        >
          <CloseCircleOutlined />
          Huỷ
        </ButtonCancel>
      </Col>
      <Col>
        <ButtonSave type="primary" loading={isLoadingButton} htmlType="submit">
          <CheckCircleOutlined />
          {text}
        </ButtonSave>
      </Col>
    </Row>
  )
}

export default ButtonForm
