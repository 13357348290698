import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { PageHeader, Radio, Row, Select, Spin, Table, message } from 'antd'
import ButtonSave from 'common/components/Button/ButtonSave'
import Container from 'container/Container'
import React from 'react'
import Filter from '../components/Filter'
import styled from 'styled-components'
import { formatPrice } from 'utils/ruleForm'
import { useDebounce } from 'common/hooks/Debounce'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { momentToStringDate } from 'utils/TimerHelper'
import { toNonAccentVietnamese } from 'utils/Configs'
import ExpandContent from '../components/ExpandContent'
import { getListBranchs } from '../../branch_report/services'
import { exportExcel, getCancelOrderDetail, getCancelOrderReports } from '../service'
import { useHistory, useLocation } from 'react-router-dom'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE } from 'utils/constants'

const OrderCancelReport = () => {
    const columns = [
        {
            title: <b>STT</b>,
            dataIndex: 'stt',
            render: (value: any, record: any, index: number) => (paging.current - 1) * paging.pageSize + index + 1,
        },
        {
            title: <b>Mã báo cáo</b>,
            dataIndex: 'code',
        },
        {
            title: <b>Nhân viên kho</b>,
            dataIndex: 'staff',
            render: (_: any, record: any) => record?.inventory_staff?.name,
        },
        {
            title: <b>Số điện thoại</b>,
            dataIndex: 'phone',
            render: (_: any, record: any) => record?.inventory_staff?.phone_number,
        },
        {
            title: <b>Số sản phẩm</b>,
            dataIndex: 'count_product',
        },
        {
            title: <b>Chi nhánh</b>,
            dataIndex: 'branch_name',
            render: (_: any, record: any) => record?.branch?.name,
        },
        {
            title: <b>Ngày tạo</b>,
            dataIndex: 'date',
            render: (_: any, record: any) => momentToStringDate(record?.created_at, 'dateTime'),
        },
    ]

    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isIventoryManager = userInfor?.admin?.role === ADMIN_ROLE.INVERTORY_STAFF
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN

    const history = useHistory()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any[]>()
    const [listKiotviet, setlistKiotviet] = React.useState<IKiotviet[]>()
    const [branches, setBranches] = React.useState<any[]>([])
    const [selectedBranchId, setSelectedBranchId] = React.useState<any>(
        isIventoryManager ? userInfor?.admin?.branch_id : undefined
    )
    const [selectedKiotVietId, setSelectedKiotVietId] = React.useState<any>(
        isIventoryManager || isShopAdmin ? userInfor?.admin?.kiotviet_id : undefined
    )
    const [search, setSearch] = React.useState<string>('')
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<number[]>([])
    const [fromDate, setFromDate] = React.useState<string>('')
    const [toDate, setToDate] = React.useState<string>('')
    const [paging, setPaging] = React.useState<any>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const searchDebounce = useDebounce(search, 300)
    const [reportDetail, setReportDetail] = React.useState<any>()

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                const data = res?.data?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
                setlistKiotviet(data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getBranchsByKiotviet = async () => {
        try {
            const payload = {
                kiotviet_id: selectedKiotVietId,
            }
            const res = await getListBranchs(payload)
            if (res?.data) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                if (isIventoryManager) {
                    const newData = data?.filter((item: any) => item?.id === userInfor?.admin?.branch_id)
                    setBranches(newData)
                } else {
                    setBranches(data)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleChangeStock = (idStock: any) => {
        setSelectedKiotVietId(idStock)
    }

    const getListReports = async () => {
        try {
            setLoading(true)
            const payload = {
                search: searchDebounce,
                page: paging.current,
                limit: paging.limit,
                from_date: fromDate,
                to_date: toDate,
                kiotviet_id: selectedKiotVietId,
                branch_id: selectedBranchId,
            }
            const res = await getCancelOrderReports(payload)
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, key: item?.id }))
                setData(data)
                const newPaging = {
                    ...paging,
                    current: res?.paging?.page,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getDetail = async () => {
        try {
            setLoading(true)
            const res = await getCancelOrderDetail(expandedRowKeys[0])
            if (res?.status) {
                setReportDetail(res?.data)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onExportExcel = async () => {
        try {
            setLoading(true)
            const payload = {
                search: searchDebounce,
                page: paging.current,
                limit: paging.limit,
                from_date: fromDate,
                to_date: toDate,
                kiotviet_id: selectedKiotVietId,
                branch_id: selectedBranchId,
            }
            const res = await exportExcel(payload)
            if (res?.status) {
                message.success('Xuất file excel thành công!')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if (selectedKiotVietId) {
            getBranchsByKiotviet()
        }
    }, [selectedKiotVietId])

    React.useEffect(() => {
        if (paging.current) {
            getListReports()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [searchDebounce, fromDate, toDate, selectedBranchId, selectedKiotVietId])

    React.useEffect(() => {
        getListReports()
    }, [paging.current])

    React.useEffect(() => {
        getDataKiotviet()
    }, [])

    React.useEffect(() => {
        if (expandedRowKeys?.length > 0) {
            getDetail()
        }
    }, [expandedRowKeys])

    return (
        <Spin spinning={loading}>
            <Container
                header={
                    <PageHeader
                        style={{ borderRadius: 8 }}
                        title="Báo cáo huỷ hàng"
                        extra={[
                            <ButtonSave
                                onClickButton={onExportExcel}
                                text="Xuất excel"
                                icon={<VerticalAlignBottomOutlined />}
                            />,
                        ]}
                    ></PageHeader>
                }
                filterComponent={
                    <Filter search={search} setFromDate={setFromDate} setToDate={setToDate} setSearch={setSearch} />
                }
                contentComponent={[
                    <>
                        <Radio.Group
                            value={selectedKiotVietId}
                            onChange={(e: any) => {
                                handleChangeStock(e.target.value)
                                setSelectedBranchId(undefined)
                                setPaging((prev: any) => ({ ...prev, current: 1 }))
                            }}
                        >
                            <Radio.Button disabled={isIventoryManager || isShopAdmin} value={undefined}>
                                Tất cả
                            </Radio.Button>
                            {listKiotviet &&
                                listKiotviet.map((region, index) => (
                                    <Radio.Button
                                        key={index}
                                        value={region.id}
                                        disabled={
                                            (isIventoryManager && region.id !== userInfor?.admin?.kiotviet_id) ||
                                            (isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id)
                                        }
                                    >
                                        {region.name ? region.name : '--'}
                                    </Radio.Button>
                                ))}
                        </Radio.Group>
                        {selectedKiotVietId && (
                            <Select
                                placeholder="Chọn chi nhánh"
                                showArrow
                                showSearch
                                value={selectedBranchId}
                                allowClear={!isIventoryManager}
                                options={branches}
                                onChange={(value: number | undefined) => {
                                    setSelectedBranchId(value)
                                }}
                                style={{ marginLeft: 20 }}
                                filterOption={(input, option: any) => {
                                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                    return nonAccent.includes(nonAccentInput)
                                }}
                            />
                        )}

                        <CustomRow>
                            <p>
                                Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                            </p>
                        </CustomRow>
                        <Table
                            id="top-table"
                            bordered
                            columns={columns}
                            dataSource={data}
                            scroll={{
                                x: 800,
                                scrollToFirstRowOnChange: true,
                            }}
                            locale={{
                                emptyText: 'Chưa có bản ghi nào!',
                            }}
                            expandable={{
                                expandedRowRender: record => <ExpandContent item={reportDetail} />,
                            }}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        history.push({
                                            pathname: ADMIN_ROUTER_PATH.ORDER_CANCEL_REPORT_DETAIL,
                                            state: { id: record?.id },
                                        })
                                    },
                                }
                            }}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(expanded: boolean, record: any) => {
                                let keys = []
                                if (expanded) {
                                    keys.push(record.id)
                                }
                                setExpandedRowKeys(keys)
                            }}
                            pagination={{
                                ...paging,
                                showSizeChanger: false,
                                onChange: page => {
                                    setPaging({ ...paging, current: page })
                                },
                            }}
                        />
                    </>,
                ]}
            />
        </Spin>
    )
}

const CustomRow = styled(Row)`
    margin: 28px 0 0 0;

    .text {
        font-size: 12px;
        margin: 0;
    }
`

export default OrderCancelReport
