import { ApiClient } from 'services/ApiService'

export const getInventoryStaffList = (payload: any) => ApiClient.get(`/admin/inventory-staff`, payload)

export const getStaffGeneralInfo = (payload: any) => ApiClient.get(`/admin/inventory-staff/${payload.id}/overview`)

export const getWrapOrderReport = (payload: any) =>
    ApiClient.get(`/admin/inventory-staff/${payload.id}/orderPackageReport`, payload)

export const addInventoryStaff = (payload: any) => ApiClient.post(`/admin/inventory-staff`, payload)

export const assignOrderForInventoryStaff = (staff_id: any, payload: any) =>
    ApiClient.post(`/admin/inventory-staff/asignOrder/${staff_id}`, payload)

export const updateInventoryStaff = (id: number, payload: any) => ApiClient.put(`/admin/inventory-staff/${id}`, payload)

export const deleteStaff = (id: any) => ApiClient.delete(`/admin/inventory-staff/${id}/delete`)

export const changeStatus = (id: any) => ApiClient.put(`/admin/inventory-staff/${id}/toggleStatus`)

export const exportExcel = (payload: any) => ApiClient.get(`/admin/inventory-staff/exportExcel`, payload)

export const exportExcelTab = (id: number, payload: any) =>
    ApiClient.get(`/admin/inventory-staff/${id}/exportExcelOrderPackageReport`, payload)

export const changePassword = (id: any, payload: any) =>
    ApiClient.put(`/admin/inventory-staff/${id}/change_password`, payload)
