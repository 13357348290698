import { useEffect, useState, useMemo } from 'react'
import './css/styles.css'
import { Col, Row, Avatar } from 'antd'
import { getFromDateToNow, get_ss_hh_dd_mm } from 'utils/TimerHelper'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { splitTextEndLine } from 'utils/funcHelper'
import { IS_READ } from 'utils/constants'
import { UserInfoState } from '../types'
import R from 'utils/R'
import { deleteNotificationMessenger } from 'common/components/header/NotificationSlice'
import { readATopicMessage, setChattingUser } from '../slices/MessageNotReadSlice'
import reactotron from 'ReactotronConfig'

export interface TopicChatItemProps {
    id: any
    Message: any
    Shop: any
    User: any
    time_last_send: Date
    index: number
    count_message_not_read: boolean
    mode: number
    onReadMessage: any
    item?: any
}

function TopicChatItem({
    id,
    Message,
    Shop,
    User,
    time_last_send,
    count_message_not_read,
    onReadMessage,
    item,
}: TopicChatItemProps) {
    const dispatch = useDispatch()
    const history = useHistory()
    const { userInfo } = useSelector((state: any) => state.authReducer)
    const [userDisplay, setUserDisplay] = useState<UserInfoState | null>(null)
    const split_pathname: Array<string> = history?.location?.pathname?.split('/')
    const topic_message_id: string | undefined = split_pathname[split_pathname.length - 1]

    let message: string = Message?.content || ''

    if (message) {
        const arrMgs: Array<string> = splitTextEndLine(message)
        message = ''
        arrMgs.forEach((e: string, i) => (message += i !== arrMgs.length - 1 ? e + ' ' : e))
    }

    const mgs = useMemo(() => {
        let message: string = Message?.content || ''
        if (message) {
            const arrMgs: Array<string> = splitTextEndLine(message)
            message = ''
            arrMgs.forEach((e: string, i) => (message += i !== arrMgs.length - 1 ? e + ' ' : e))
        }

        // Tin nhắn cuối là mình nhắn
        return Message && userInfo?.admin?.account_id === Message?.account_id
            ? message
                ? `Bạn: ${message}`
                : 'Bạn: Đã gửi một media.'
            : // Tin nhắn người khách nhắn: nếu message có -> message, nếu message_media_url -> Hình ảnh
            message
            ? message
            : Message?.message_media_url
            ? 'Hình ảnh'
            : 'Đơn hàng DH...'
    }, [userInfo, Message])

    const is_read_mgs =
        Message?.is_read === IS_READ.READ ||
        userInfo?.admin?.id === Message?.user_id ||
        userInfo?.shop_id === Message?.shop_id

    useEffect(() => {
        if (userInfo && User) {
            setUserDisplay(User)
        }
    }, [userInfo, User, Shop])

    return (
        <Row
            className={
                topic_message_id && parseInt(topic_message_id) === id ? 'chat-item active-chat-item' : 'chat-item'
            }
            onClick={() => {
                onReadMessage()
                dispatch(setChattingUser(User))
                dispatch(deleteNotificationMessenger(id))
                // dispatch(readATopicMessage({ topic_message_id: id }))
                history.replace(`${ADMIN_ROUTER_PATH.CHAT}/${id}`)
            }}
        >
            <Col lg={4} md={8} xs={24}>
                <Row justify="center">
                    <div
                        style={{
                            position: 'relative',
                        }}
                    >
                        {count_message_not_read && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: 10,
                                    height: 10,
                                    zIndex: 1,
                                    borderRadius: '50%',
                                    border: 2,
                                    backgroundColor: '#be0000',
                                    color: 'white',
                                    fontSize: 'x-small',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    verticalAlign: 'top',
                                }}
                            />
                        )}

                        <Avatar
                            size={40}
                            src={User?.avatar}
                            icon={<img src={R.images.img_ogo} />}
                            style={{ border: '2px solid #f2f1f0' }}
                        />
                    </div>
                </Row>
            </Col>
            <Col lg={13} md={14} xs={0} style={{ height: '100%', marginLeft: 6 }}>
                <Row align="middle" style={{ height: '100%' }}>
                    <Col span={20} style={{ height: '80%' }}>
                        <Row>
                            <strong
                                style={{
                                    fontSize: 15,
                                    whiteSpace: 'nowrap',
                                    width: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {userDisplay?.name}{' '}
                                <p style={{ fontSize: 10, fontWeight: 300 }}>({userDisplay?.phone_number})</p>
                            </strong>
                        </Row>
                        <Row>
                            {is_read_mgs ? (
                                <p
                                    style={{
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        color: '#515151',
                                        fontSize: 12,
                                        fontWeight: '500',
                                    }}
                                >
                                    {mgs}
                                </p>
                            ) : (
                                <p
                                    style={{
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        color: '#515151',
                                        fontSize: 14,
                                    }}
                                >
                                    <strong>{mgs}</strong>
                                </p>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col lg={4} md={0} xs={0} style={{ height: '80%' }}>
                <p>
                    {Message?.create_at || time_last_send ? (
                        <span style={{ fontSize: 11, color: 'gray' }}>
                            {Message?.create_at ? get_ss_hh_dd_mm(Message?.create_at) : get_ss_hh_dd_mm(time_last_send)}
                        </span>
                    ) : (
                        <span style={{ fontSize: 11, color: 'green' }}>Mới</span>
                    )}
                </p>
            </Col>
        </Row>
    )
}
export default TopicChatItem
