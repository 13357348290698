import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { Table, Tag, Image } from 'antd'
import { getListGift } from '../listGiftApi'
import { formatPrice } from 'utils/ruleForm'
import HeaderListGift from './HeaderListGift'
import { IS_GIFT_ACTIVE } from 'utils/constants'
import reactotron from 'ReactotronConfig'
import DetailGift from './DetailGift'
import { AddEditGift } from './AddEditGift'
import { useSelector } from 'react-redux'
import { useDebounce } from 'common/hooks/Debounce'
function ListGift() {
    const heightWeb = useSelector((state: any) => state.configReducer.dimension.height)
    const [paging, setPaging] = useState({
        total: 0,
        current: 1,
        pageSize: 12,
    })
    const [listGift, setListGift] = useState<Array<any>>([])
    const [isLoading, setIsLoading] = useState(false)
    const [currentSelected, setCurrentSelected] = useState({ id: -1 })

    const [params, setParams] = useState<any>({
        search: '',
        status: undefined,
        type_gift: undefined,
        limit: paging.pageSize,
        from_date: '',
        to_date: '',
    })

    const [showEditGift, setShowEditGift] = useState(false)

    const getData = async () => {
        try {
            setIsLoading(true)
            setCurrentSelected({ id: -1 })
            const res = await getListGift({ ...params, page: paging.current })
            const data_list = res.data.map((item: any) => {
                return { ...item, key: item.id }
            })
            const formattedPaging = {
                ...paging,
                total: res.paging.totalItemCount,
                current: res.paging.page,
            }
            setPaging(formattedPaging)
            setListGift(data_list)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (paging.current === 1) {
            getData()
        } else {
            setPaging((prev: any) => ({ ...prev, current: 1 }))
        }
    }, [params])

    useEffect(() => {
        getData()
    }, [paging.current])

    const columns = [
        {
            width: 70,
            title: 'STT',
            render: (text: any, record: any, index: any) => (
                <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
                    {(paging.current - 1) * paging.pageSize + index + 1}
                </td>
            ),
            ellipsis: true,
        },
        {
            title: 'Tên quà tặng',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Hình ảnh',
            dataIndex: 'icon_url',
            key: 'icon_url',
            width: '100px',
            render: (value: any) => {
                return (
                    <td className="icon-eye-antd-custom">
                        <Image src={value} style={{ objectFit: 'cover', width: '35px', height: '35px' }} />
                    </td>
                )
            },
        },
        {
            title: 'Số điểm',
            dataIndex: 'price',
            key: 'price',
            render: (value: any) => {
                return <>{value === 0 ? 0 : formatPrice(value)}</>
            },
        },
        {
            title: 'Loại quà',
            dataIndex: 'type_gift_name',
            key: 'type_gift_name',
        },
        {
            title: 'Giảm giá',
            dataIndex: 'discount_percent',
            key: 'discount_percent',
            render: (value: any) => {
                return <>{value === null ? '0%' : value + '%'}</>
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            width: '150px',
            render: (value: any) => {
                switch (value) {
                    case IS_GIFT_ACTIVE.INACTIVE:
                        return <Tag color="volcano">Ngừng hoạt động</Tag>
                    case IS_GIFT_ACTIVE.ACTIVE:
                        return <Tag color="green">Đang hoạt động</Tag>
                }
            },
        },
        {
            title: 'Đã đổi',
            dataIndex: 'count_purchased_gift',
            key: 'count_purchased_gift',
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'create_at',
            key: 'create_at',
            render: (value: any) => {
                return <>{moment(value).format('DD-MM-YYYY')}</>
            },
        },
    ]

    return (
        <div>
            <HeaderListGift
                onShowAddGift={() => {
                    setShowEditGift(true)
                }}
                onSearchSubmit={(searchKey: string) => {
                    setParams({ ...params, search: searchKey.trim() })
                }}
                onStatusSubmit={(statusKey: number) => {
                    setParams({ ...params, status: statusKey })
                }}
                onTypeGift={(type_id: number) => {
                    setParams({ ...params, df_type_gift_id: type_id })
                }}
                onDateSubmit={(from_date: string, to_date: string) => {
                    setParams({
                        ...params,
                        from_date: from_date,
                        to_date: to_date,
                    })
                }}
                isSearchLoading={isLoading}
            />
            <div>
                <div
                    style={{
                        backgroundColor: 'white',
                        margin: '0px 10px 15px',
                        padding: '10px 20px',
                    }}
                >
                    <p>
                        Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                    </p>
                    <Table
                        scroll={{
                            x: 800,
                            scrollToFirstRowOnChange: true,
                            // y: 'calc(100vh - 420px)',
                            y: `calc(${heightWeb}px - 330px)`,
                        }}
                        bordered
                        dataSource={listGift}
                        loading={isLoading}
                        columns={columns}
                        expandedRowKeys={[currentSelected.id]}
                        onRow={r => ({
                            onClick: () => {
                                if (currentSelected !== r) setCurrentSelected(r)
                                else setCurrentSelected({ id: -1 })
                            },
                        })}
                        expandable={{
                            expandedRowRender: (record: any) => (
                                <DetailGift data={record} getData={getData} isLoadingButton={isLoading} />
                            ),
                            onExpand: (status: any, r: any) => {
                                if (currentSelected !== r) setCurrentSelected(r)
                                else setCurrentSelected({ id: -1 })
                            },
                        }}
                        pagination={{
                            ...paging,
                            onChange: page => {
                                setPaging((prev: any) => ({ ...prev, current: page }))
                            },
                        }}
                    />
                </div>
            </div>
            {showEditGift ? (
                <AddEditGift
                    isLoadingButton={isLoading}
                    visible={showEditGift}
                    onCancel={() => {
                        setShowEditGift(false)
                    }}
                    getData={getData}
                />
            ) : (
                <></>
            )}
        </div>
    )
}
export default ListGift
