import { Card, Image, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface IExpandContent {
    item: any
}

const ExpandContent = (props: IExpandContent) => {
    const { item } = props

    const listImages = item?.medias?.filter((item: any) => item?.type === 1)
    const listVideos = item?.medias?.filter((item: any) => item?.type === 2)

    return (
        <CustomCard title={<b>Thông tin chung</b>} bordered={false}>
            <div className="block">
                <p className="label">Mô tả</p>
                <p className="description">{item?.description}</p>
            </div>
            <div className="block">
                <p className="label">Hình ảnh</p>
                <div className="image_block">
                    {listImages?.map((item: any) => (
                        <div key={item?.id} className="image">
                            <Image height={100} width={100} className="image_content" alt="flower" src={item?.src} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="block">
                <p className="label">Video</p>
                <iframe width="400" height="200" src={listVideos?.[0]?.src} frameBorder="0" allowFullScreen></iframe>
            </div>
        </CustomCard>
    )
}

const CustomCard = styled(Card)`
    width: 100%;
    border-radius: 10px;

    .block {
        width: 100%;
        margin-bottom: 20px;
        .label {
            font-weight: 700;
            text-decoration: underline;
        }

        .image_block {
            display: flex;

            .image {
                width: 100px;
                height: 100px !important;
                margin-right: 14px;

                .image_content {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    object-fit: cover;
                }
            }

            .description {
                font-size: 12px;
                color: gray;
            }
        }
    }
`

export default ExpandContent
