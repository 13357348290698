import { Checkbox, Form, Input, Modal, Row, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import ButtonForm from 'common/components/ModalButton'
import { AnyARecord } from 'dns'
import { getDistricts, getProvinces, getWards } from 'features/admin/customer-clone/CustomerAPI'
import React from 'react'
import styled from 'styled-components'
import { toNonAccentVietnamese } from 'utils/Configs'

interface IAddEditAddressModal {
    isModalOpen: boolean
    setIsModalOpen: any
    handleOk: (values: any) => void
}

const AddEditAddressModal = (props: IAddEditAddressModal) => {
    const { isModalOpen, setIsModalOpen, handleOk } = props

    const [form] = useForm()
    const [provinces, setProvinces] = React.useState<any[]>([])
    const [districts, setDistricts] = React.useState<any[]>([])
    const [wards, setWards] = React.useState<any[]>([])
    const [provinceId, setProvinceId] = React.useState<number>()
    const [districtId, setDistrictId] = React.useState<number>()
    const [wardId, setWradId] = React.useState<number>()

    const onCancel = () => {
        setIsModalOpen(false)
    }

    const getListProvinces = async () => {
        try {
            const res: any = await getProvinces()
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setProvinces(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getListDistricts = async () => {
        try {
            const res: any = await getDistricts(provinceId)
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setDistricts(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getListWards = async () => {
        try {
            const res: any = await getWards(districtId)
            if (res?.status) {
                const data = res?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.name }))
                setWards(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        getListProvinces()
    }, [])

    React.useEffect(() => {
        getListDistricts()
    }, [provinceId])

    React.useEffect(() => {
        getListWards()
    }, [districtId])

    return (
        <Modal
            okText="Lưu"
            title="Thêm mới địa chỉ"
            destroyOnClose
            width={'50%'}
            footer={null}
            maskClosable={false}
            visible={isModalOpen}
            onCancel={onCancel}
        >
            <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleOk} autoComplete="off">
                <Form.Item
                    label="Họ và tên"
                    name="name"
                    rules={[{ required: true, message: 'Vui lòng nhập họ và tên!' }]}
                >
                    <Input placeholder="Nhập họ và tên" allowClear />
                </Form.Item>

                <Form.Item
                    label="Số điện thoại"
                    name="phone"
                    rules={[
                        { required: true, message: 'Vui lòng nhập số điện thoại!' },
                        {
                            message: 'Số điện thoại không hợp lệ!',
                            validator: (_, value) => {
                                if (!value?.toString()?.trim()) return Promise.resolve()
                                if (isNaN(Number(value))) {
                                    return Promise.reject()
                                }
                                return Promise.resolve()
                            },
                        },
                    ]}
                >
                    <Input placeholder="Nhập số điện thoại" allowClear />
                </Form.Item>

                <Form.Item
                    label="Tỉnh/ Thành phố"
                    name="province"
                    rules={[{ required: true, message: 'Vui lòng chọn tỉnh, thành phố!' }]}
                >
                    <Select
                        filterOption={(input, option: any) => {
                            const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                            const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                            return nonAccent.includes(nonAccentInput)
                        }}
                        onChange={(value: number) => {
                            setProvinceId(value)
                            setDistrictId(undefined)
                            setWradId(undefined)

                            form.setFieldsValue({
                                district: undefined,
                                ward: undefined,
                            })
                        }}
                        placeholder="Chọn tỉnh/thành phố"
                        showArrow
                        showSearch
                        options={provinces}
                    />
                </Form.Item>
                <Form.Item
                    label="Quận/ Huyện"
                    name="district"
                    rules={[{ required: true, message: 'Vui lòng chọn quận, huyện!' }]}
                >
                    <Select
                        filterOption={(input, option: any) => {
                            const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                            const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                            return nonAccent.includes(nonAccentInput)
                        }}
                        onChange={(value: number | undefined) => {
                            setDistrictId(value)
                            setWradId(undefined)

                            form.setFieldsValue({
                                ward: undefined,
                            })
                        }}
                        disabled={!provinceId}
                        placeholder="Chọn quận/ huyện"
                        showArrow
                        showSearch
                        options={districts}
                    />
                </Form.Item>
                <Form.Item
                    label="Phường/ Xã"
                    name="ward"
                    rules={[{ required: true, message: 'Vui lòng chọn phường xã!' }]}
                >
                    <Select
                        filterOption={(input, option: any) => {
                            const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                            const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                            return nonAccent.includes(nonAccentInput)
                        }}
                        disabled={!provinceId || !districtId}
                        placeholder="Chọn phường/ xã"
                        showArrow
                        showSearch
                        options={wards}
                    />
                </Form.Item>
                <Form.Item
                    label="Địa chỉ cụ thể"
                    name="detailAddress"
                    rules={[{ required: true, message: 'Vui lòng nhập địa chỉ cụ thể!' }]}
                >
                    <Input placeholder="Nhập địa chỉ cụ thể" allowClear />
                </Form.Item>
                <Form.Item name="isDefault" valuePropName="checked">
                    <Checkbox>Đặt làm địa chỉ mặc định</Checkbox>
                </Form.Item>

                <CustomRow justify="end">
                    <ButtonForm text={'Lưu'} />
                </CustomRow>
            </Form>
        </Modal>
    )
}

const CustomRow = styled(Row)`
    margin-top: 60px;

    .cancel_button {
        margin-right: 16px;
    }
`

export default AddEditAddressModal
