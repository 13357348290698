import {
    ArrowsAltOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    VerticalAlignBottomOutlined,
} from '@ant-design/icons'
import { Col, Descriptions, Empty, Image, PageHeader, Popconfirm, Rate, Row, Table, Tag, Timeline, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import ButtonSave from 'common/components/Button/ButtonSave'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { renderDeliveryStatus } from 'utils/Configs'
import { momentToStringDate } from 'utils/TimerHelper'
import { ADMIN_ROLE, ORDER_STATUS, PAYMENT_METHOD, TRANSPORT_STATUS } from 'utils/constants'
import { renderInventoryStatus, renderOrderStatus } from 'utils/funcHelper'
import { notificationError } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { IFormatedDetailOrder } from './Interfaces'
import { getOrderDetail, removeComment, syncOrder, toggleComment } from './OrderAPI'
import AddNoteShipperManagerModal from './components/AddNoteShipperManagerModal'
import './components/css/OrderDetail.css'
import { useSelector } from 'react-redux'
import CustomerNoteModal from '../customer-clone/component/CustomerNoteModal'
import { getListNotes } from '../customer-clone/CustomerAPI'
const { PENDING, INPROGRESS, SUCCCESS, CANCELED } = ORDER_STATUS
const { COD, BANKING, COIN, DEBIT } = PAYMENT_METHOD

const WapperOrderInfo = styled.div`
    margin: 25px 10px;
    background-color: white;
    border-radius: 8px;
`

function OrderDetail({ location }: any) {
    const [orderDetail, setorderDetail] = useState<any>()
    const [isNoteModalVisible, setIsNoteModalVisible] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(false)
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isShipperManager = userInfor?.admin?.role === ADMIN_ROLE.SHIPPER_MANAGER
    const [productsOrder, setproductsOrder] = useState<IFormatedDetailOrder[]>()
    const [activeTab, setActiveTab] = useState<string>('1')
    const history = useHistory()
    const [notes, setNotes] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [paging, setPaging] = useState<any>({
        total: 0,
        current: 1,
        pageSize: 6,
    })

    const columns: ColumnsType<IFormatedDetailOrder> = [
        {
            width: 70,
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'product_name',
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
        },
        {
            title: 'Đơn vị tính',
            dataIndex: 'product_unit',
        },
        {
            title: 'Giá bán',
            dataIndex: 'price',
            render: price => {
                return <Text>{formatPrice(price)} đ</Text>
            },
        },
        {
            title: 'Thành tiền',
            dataIndex: '',
            render: data => {
                return <Text>{formatPrice(data.price * data.quantity)} đ</Text>
            },
        },
        {
            title: 'Đánh giá',
            dataIndex: 'customer_rating',
            render: value => {
                return <Rate value={+value} disabled style={{ fontSize: '12px' }} count={5} />
            },
        },
        {
            title: 'Nội dung đánh giá',
            dataIndex: 'rating_content',
        },
        {
            title: 'Thao tác',
            width: 200,
            dataIndex: 'unit',
            render: (_: any, record: any) => (
                <CustomRow justify="center">
                    <a
                        onClick={() => {
                            history.push({
                                pathname: `${ADMIN_ROUTER_PATH.PRODUCT_DETAIL}/${record.product_id}`,
                                state: {
                                    ...record,
                                    path: `${ADMIN_ROUTER_PATH.ORDER_DETAIL}/${orderDetail?.id}`,
                                },
                            })
                        }}
                    >
                        <EditOutlined />
                    </a>
                    <Popconfirm
                        placement="topLeft"
                        title={
                            record?.rating?.status === 1
                                ? 'Bạn chắc chắn muốn ẢN đánh giá sản phẩm này?'
                                : 'Bạn chắc chắn muốn MỞ đánh giá sản phẩm này?'
                        }
                        onConfirm={() => onToggleComment(record?.rating?.id)}
                        okText={record?.rating?.status === 1 ? 'Ẩn' : 'Mở'}
                        cancelText="Huỷ"
                    >
                        {record?.rating?.status === 1 ? (
                            <EyeOutlined style={{ marginLeft: 20 }} />
                        ) : (
                            <EyeInvisibleOutlined style={{ marginLeft: 20 }} />
                        )}
                    </Popconfirm>
                    <Popconfirm
                        placement="topLeft"
                        title="Bạn chắc chắn muốn XOÁ đánh giá sản phẩm này?"
                        onConfirm={() => onRemoveComment(record?.rating?.id)}
                        okText="Xoá"
                        cancelText="Huỷ"
                    >
                        <DeleteOutlined style={{ color: 'red', marginLeft: 20 }} />
                    </Popconfirm>
                </CustomRow>
            ),
        },
    ]

    const getData = async () => {
        try {
            const res = await getOrderDetail(location.state?.id)
            if (res.data) {
                const dataTable = res.data.items.map((product, index) => ({
                    ...product,
                    stt: index + 1,
                    key: index,
                }))
                setorderDetail(res.data)
                setproductsOrder(dataTable)
            }
        } catch (err: any) {
            console.log(err)
            notificationError(err)
        }
    }

    const onToggleComment = async (id: number) => {
        try {
            const res: any = await toggleComment(id)
            if (res?.status) {
                if (!res?.data?.status) {
                    message.success('Ẩn đánh giá thành công!')
                } else {
                    message.success('Mở đánh giá thành công!')
                }
                getData()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onRemoveComment = async (id: number) => {
        try {
            const res: any = await removeComment(id)
            if (res?.status) {
                message.success('Xoá đánh giá thành công!')
                getData()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onSyncOrder = async (id: number) => {
        try {
            const res: any = await syncOrder(id)
            if (res?.status) {
                message.success('Đồng bộ đơn hàng thành công!')
                getData()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const orderStatusDesc = (status?: string) => {
        switch (status) {
            case PENDING:
                return <Tag color={'warning'}>Chờ xác nhận</Tag>
            case INPROGRESS:
                return <Tag color={'blue'}>Đã xác nhân</Tag>
            case SUCCCESS:
                return <Tag color={'success'}>Hoàn thành</Tag>
            case CANCELED:
                return <Tag color={'volcano'}>Huỷ</Tag>
            default:
                return ''
        }
    }

    useEffect(() => {
        getData()
    }, [location?.state?.id])

    const paymentMethodDesc = (method?: string) => {
        switch (method) {
            case COD:
                return <Text>Tiền mặt</Text>
            case BANKING:
                return <Text>Chuyển khoản</Text>
            case COIN:
                return <Text>Xu</Text>
            case DEBIT:
                return <Text>Công nợ</Text>
            case '':
                return ''
        }
    }

    const handleAddNewLineToString = (noteString: string) => {
        const splitString = noteString.split('----')
        const cleanString = splitString.filter((item: string) => item)
        let stringReturn = ''
        for (let i = 0; i < cleanString.length; i++) {
            stringReturn += '---- ' + cleanString[i] + '<br />'
        }

        return stringReturn
    }

    const getNotes = async () => {
        try {
            setLoading(true)
            const payload = {
                id: orderDetail?.user?.id,
                type: Number(activeTab),
                page: 1,
                limit: 999,
            }
            const res = await getListNotes(payload)
            if (res?.status) {
                setNotes(res?.data)
                const newPaging = {
                    ...paging,
                    total: res?.paging?.totalItemCount,
                }
                setPaging(newPaging)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getNotes()
    }, [])

    return (
        <>
            <PageHeader
                style={{ backgroundColor: 'white', margin: '5px 10px 15px' }}
                className="site-page-header"
                onBack={() =>
                    history.push({
                        pathname: location.state?.path ? location.state?.path : ADMIN_ROUTER_PATH.ORDER,
                        state: { currentTab: '3', paging: location.state?.paging },
                    })
                }
                title={orderDetail?.code ? `Đơn hàng ${orderDetail?.code}` : `Đơn hàng`}
                extra={
                    <ButtonSave
                        onClickButton={() => onSyncOrder(location.state?.id)}
                        text="Đồng bộ"
                        icon={<VerticalAlignBottomOutlined />}
                    />
                }
            />
            {visible && (
                <AddNoteShipperManagerModal
                    orderDetail={orderDetail}
                    getData={getData}
                    visible={visible}
                    setVisible={setVisible}
                />
            )}
            <Row>
                {isNoteModalVisible && (
                    <CustomerNoteModal
                        isFromOrderDetail={true}
                        isVisible={isNoteModalVisible}
                        setIsVisible={setIsNoteModalVisible}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        paging={paging}
                        setPaging={setPaging}
                        getNotes={getNotes}
                        notes={notes}
                        loading={loading}
                        setLoading={setLoading}
                        userId={orderDetail?.user?.id}
                    />
                )}
                <Col xxl={12} xl={12} lg={12}>
                    <div className="wapper-info-order">
                        <Descriptions title="Thông tin khách hàng" style={{ padding: '20px' }}>
                            <Descriptions.Item span={3} label="Tên khách hàng">
                                {orderDetail?.user?.full_name ? orderDetail?.user?.full_name : '--'}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="Số điện thoại">
                                {orderDetail?.user?.phone_number ? orderDetail?.user?.phone_number : '--'}
                            </Descriptions.Item>
                        </Descriptions>
                        <div className="line"></div>
                        <Descriptions title="Thông tin Người nhận hàng" style={{ padding: '20px' }}>
                            <Descriptions.Item label="Tên người nhận" span={3}>
                                {orderDetail?.shipping_name ? orderDetail?.shipping_name : '--'}
                            </Descriptions.Item>

                            <Descriptions.Item label="Số điện thoại" span={3}>
                                {orderDetail?.shipping_phone_number ? orderDetail?.shipping_phone_number : '--'}
                            </Descriptions.Item>

                            <Descriptions.Item label="Địa chỉ chi tiết">
                                {orderDetail?.shipping_address ? `${orderDetail?.shipping_address}` : '--'}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </Col>

                <Col xxl={12} xl={12} lg={12}>
                    <div className="wapper-info-order">
                        <Descriptions title="Lịch sử đơn hàng" style={{ padding: '20px' }} />
                        {orderDetail?.order_status_history?.length === 0 ? (
                            <Empty />
                        ) : (
                            <Timeline mode="left">
                                {orderDetail?.order_status_history?.map((item: any) => (
                                    <Timeline.Item label={renderOrderStatus(item?.status)}>
                                        {momentToStringDate(item?.createdAt)}
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        )}
                    </div>
                </Col>
            </Row>
            <WapperOrderInfo>
                <Row>
                    <Col span={12}>
                        <Descriptions title="Thông tin đơn hàng" style={{ padding: '20px' }}>
                            <Descriptions.Item label="Mã đơn hàng" span={3}>
                                {orderDetail?.code}
                            </Descriptions.Item>
                            <Descriptions.Item label="Sản phẩm " span={3}>
                                {orderDetail?.items.length} sản phẩm
                            </Descriptions.Item>
                            <Descriptions.Item label="Hình thức thanh toán" span={3}>
                                {paymentMethodDesc(orderDetail?.payment_method)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Trạng thái thanh toán" span={3}>
                                {orderDetail?.payment_status == 'inprogress' ? 'Đã thanh toán' : 'Chưa thanh toán'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Trạng thái vận chuyển" span={3}>
                                {/* {orderDetail?.order_history?.length === 0
                                    ? '---'
                                    : renderTransportStatus(
                                          orderDetail?.order_history[orderDetail?.order_history?.length - 1]?.status
                                      )} */}
                                {renderDeliveryStatus(orderDetail?.order_delivery_status)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Trạng thái kho" span={3}>
                                {renderInventoryStatus(orderDetail?.order_package_status)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Ghi chú đơn hàng" span={3}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: orderDetail?.note ? handleAddNewLineToString(orderDetail?.note) : '...',
                                    }}
                                />
                            </Descriptions.Item>

                            <Descriptions.Item label="Ghi chú shipper" span={3}>
                                {orderDetail?.order_delivery?.length === 0 ? (
                                    ''
                                ) : (
                                    <div>
                                        {orderDetail?.order_delivery?.[0]?.delivery_note}
                                        <div
                                            style={{
                                                width: 180,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginTop: 10,
                                            }}
                                        >
                                            {orderDetail?.order_delivery?.[0]?.order_delivery_media?.map(
                                                (item: any) => (
                                                    <CustomImage
                                                        preview={{
                                                            mask: <ArrowsAltOutlined />,
                                                        }}
                                                        key={item?.id}
                                                        width={50}
                                                        height={50}
                                                        src={item?.src}
                                                        style={{
                                                            objectFit: 'cover',
                                                            borderRadius: 10,
                                                            marginRight: 10,
                                                        }}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Ghi chú nhân viên kho" span={3}>
                                {orderDetail?.order_package?.length === 0 ? (
                                    '----'
                                ) : (
                                    <div>
                                        {orderDetail?.order_package?.[0]?.inventory_note}
                                        <div
                                            style={{
                                                width: 180,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginTop: 10,
                                            }}
                                        >
                                            {orderDetail?.order_package?.[0]?.medias?.map((item: any) => (
                                                <CustomImage
                                                    preview={{
                                                        mask: <ArrowsAltOutlined />,
                                                    }}
                                                    key={item?.id}
                                                    width={50}
                                                    height={50}
                                                    src={item?.src}
                                                    style={{
                                                        objectFit: 'cover',
                                                        marginRight: '20px',
                                                        borderRadius: 10,
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </Descriptions.Item>
                        </Descriptions>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>
                                Ghi chú quản lý shipper:{' '}
                                {isShipperManager && (
                                    <EditOutlined
                                        style={{ cursor: 'pointer', marginLeft: 10 }}
                                        onClick={() => setVisible(true)}
                                    />
                                )}
                            </p>
                            {orderDetail?.ship_manager_note?.split('\n')?.map((item: any) => (
                                <p style={{ marginTop: -2 }} key={item}>
                                    <b> {item}</b>
                                </p>
                            ))}
                        </div>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Ghi chú khách hàng:</p>
                            {orderDetail?.user?.notes?.map((item: any, index: number) => (
                                <p style={{ marginBottom: 6 }}>
                                    <b>
                                        {index + 1}, {item?.note}
                                    </b>
                                </p>
                            ))}
                            <p
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => setIsNoteModalVisible(true)}
                            >
                                Xem thêm
                            </p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <Descriptions extra={orderStatusDesc(orderDetail?.status)} style={{ padding: '20px' }}>
                            <Descriptions.Item label="Khu vực mua hàng" span={3}>
                                {`${orderDetail?.kiotviet?.default_branch_name}- ${orderDetail?.kiotviet?.name}`}
                            </Descriptions.Item>
                            <Descriptions.Item label="Tổng tiền" span={3}>
                                {orderDetail?.total_order ? formatPrice(orderDetail?.total_order) : 0}đ
                            </Descriptions.Item>
                            <Descriptions.Item label="Sử dụng xu" span={3}>
                                {orderDetail?.use_coin ? formatPrice(orderDetail?.use_coin) : 0} xu
                            </Descriptions.Item>
                            <Descriptions.Item label="Sử dụng điểm" span={3}>
                                {orderDetail?.use_point ? formatPrice(orderDetail?.use_point) : 0}đ
                            </Descriptions.Item>
                            <Descriptions.Item label="Tổng số tiền được giảm giá" span={3}>
                                {orderDetail?.total_discount ? formatPrice(orderDetail?.total_discount) : 0}đ
                            </Descriptions.Item>
                            <Descriptions.Item label="Thành tiền thanh toán" span={3}>
                                {orderDetail?.total ? formatPrice(orderDetail?.total) : 0}đ
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </WapperOrderInfo>

            <Row style={{ background: 'white', margin: '0 10px', borderRadius: 10 }}>
                <Descriptions title="Danh sách sản phẩm" style={{ padding: '10px 20px' }} />
                <Table
                    style={{ padding: ' 0 10px 10px 10px' }}
                    bordered
                    size="small"
                    dataSource={productsOrder}
                    columns={columns}
                    scroll={{
                        x: 1200,
                        scrollToFirstRowOnChange: true,
                        y: 'calc(100vh - 500px)',
                    }}
                    // onRow={(record, index) => ({
                    //     onClick: () => {},
                    // })}
                />
            </Row>
        </>
    )
}

const CustomRow = styled(Row)`
    display: flex;
    flex-direction: row;
    z-index: 100;
`

const CustomImage = styled(Image)`
    .ant-image {
        margin-right: 10px;
    }
`

export default OrderDetail
