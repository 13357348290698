import { ApiClient } from 'services/ApiService'

export const NewsDetail = (payload: any) =>
  ApiClient.get(`admin/news/${payload.id}`)
export const getNewsList = (payload: any) =>
  ApiClient.get('/admin/news', payload)
export const createNews = (payload: any) =>
  ApiClient.post('/admin/news', payload)
export const updateNews = (payload: any) =>
  ApiClient.put(`/admin/news/${payload.id}`, payload.data)
export const changeStatusNews = (payload: any) =>
  ApiClient.put(`/admin/news/${payload}/status`)
export const deleteNews = (payload: any) =>
  ApiClient.delete('/admin/news', payload)
