import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Row,
  Select,
  Upload,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import ButtonForm from 'common/components/ModalButton'
import ContentScreen from 'common/components/contentScreen'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { IDataPush } from '../InterfaceComPost'
import { PlusOutlined } from '@ant-design/icons'
import PageHeader from 'common/components/pageHeader'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { notificationError, notificationSuccess } from 'utils/notification'
import {
  createCommunityPost,
  getAllTopic,
  updatePostDetail,
} from '../CommunityPostApi'
import UploadComponent from 'utils/UploadImage'
import { uniqueId } from 'lodash'
import UploadVideoComponent from 'utils/UploadVideo'
import UploadList from 'common/components/UploadList'
import { communityPostService, communityPostVideoService } from '../service'
import UploadVideo from 'utils/UploadVideo'
const { Option } = Select

const SelectStyled = styled(Select)`
  width: 220px !important;
`
const DatePickerStyled = styled(DatePicker)`
  width: 220px !important;
`
const TextAreaStyled = styled(TextArea)`
  height: 250px !important;
`
const NewImageButton = styled(Button)`
  margin-top: 5px;
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
`

const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

interface IProps {
  location?: any
}
interface ImageProps {
  data: any
}
function AddEditPost(props: IProps) {
  const history = useHistory()
  const [form] = Form.useForm()
  const param: any = useParams()
  const [allTopic, setAllTopic] = useState<any>()
  const [previewImage, setPreviewImage] = useState<string>('')
  const [isShowModalPreview, setShowModalPreview] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteFile, setDeleteFile] = useState<Array<any>>([])
  const [listImages, setListImages] = useState<Array<any>>()
  const [imageUpload, setImageUpload] = useState<any>()
  const [videoUpload, setVideoUpload] = useState<any>()

  const [listVideo, setListVideo] = useState<Array<any>>()
  const getTopic = async () => {
    try {
      const res = await getAllTopic()
      if (res.data) {
        setAllTopic(res.data)
      }
    } catch (err: any) {
      console.log(err)
    }
  }
  useEffect(() => {
    getTopic()
  }, [])

  useEffect(() => {
    if (props.location?.state?.data) {
      setUpload({
        ...upload,
        imageUrl: props.location?.state?.data.images,
        videoUrl: props.location?.state?.data?.video,
      })
      setListVideo(
        props.location?.state?.data?.PostMedia.filter((item: any) =>
          item.media_url.includes('video')
        ).map((item: any) => {
          return {
            uid: item.id,
            name: item.media_url,
            url: item.media_url,
          }
        })
      )
      setListImages(
        props.location?.state?.data?.PostMedia.filter((item: any) =>
          item.media_url.includes('image')
        ).map((item: any) => {
          return {
            uid: item.id,
            name: item.media_url,
            url: item.media_url,
          }
        })
      )
    }
  }, [props.location?.state?.data])
  const onCancel = () =>
    history.push({
      pathname: ADMIN_ROUTER_PATH.COMMUNITY_POST,
      state: { data: '' },
    })
  const [upload, setUpload] = useState({
    loading: false,
    imageUrl: '',
    videoUrl: '',
  })
  const onFinish = async (values: any) => {
    // upload multiple file to server
    const formData = new FormData()
    const formVideoData = new FormData()
    // khi tải file từ máy lên
    const filterImagesLocal = imageUpload?.filter(
      (item: any) => item?.originFileObj
    )
    // file đã upload trong trường hợp sửa
    const filterImagesNotLocal = imageUpload?.filter(
      (item: any) => !item?.originFileObj
    )
    const filterVideosLocal = videoUpload?.filter(
      (item: any) => item?.originFileObj
    )
    const filterVideosNotLocal = videoUpload?.filter(
      (item: any) => !item?.originFileObj
    )
    if (filterImagesLocal && filterImagesLocal.length > 0) {
      filterImagesLocal.forEach((item: any) => {
        formData.append('file', item.originFileObj)
      })
    }
    if (filterVideosLocal && filterVideosLocal.length > 0) {
      filterVideosLocal.forEach((item: any) => {
        formVideoData.append('file', item.originFileObj)
      })
    }
    const resImages: any = await communityPostService.uploadMiltipleImage(
      formData
    )
    const resVideos: any = await communityPostVideoService.uploadMiltipleVideo(
      formVideoData
    )
    try {
      setIsLoading(true)
      let newData
      if (!props.location?.state?.data) {
        newData = {
          ...values,
          images: resImages?.data?.map((item: any) => item.url),
          video: resVideos?.data[0].url,
        }
        try {
          const res = await createCommunityPost(newData)
          notificationSuccess('Thêm bài đăng thành công')
          setUpload({
            loading: false,
            imageUrl: '',
            videoUrl: '',
          })
          form.resetFields()
          onCancel()
          props.location?.state?.getListCommunityPost()
        } catch (error) {
          notificationError('Thêm bài đăng thất bại')
        }
      } else {
        newData = {
          ...values,
          images: resImages?.data?.map((item: any) => item.url),
          video: resVideos?.data[0]?.url,
          image_delete: deleteFile,
        }
        try {
          const res = await updatePostDetail(
            newData,
            props.location?.state?.data.id
          )
          notificationSuccess('Cập nhật bài đăng thành công')
          setUpload({
            loading: false,
            imageUrl: '',
            videoUrl: '',
          })
          form.resetFields()
          onCancel()
          props.location?.state?.getListCommunityPost()
        } catch (error) {
          console.log(error)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <PageHeader
        title={param.id ? 'Sửa bài đăng' : 'Thêm bài đăng'}
        onBack={() =>
          history.push({ pathname: ADMIN_ROUTER_PATH.COMMUNITY_POST })
        }
      />
      <ContentScreen>
        <Form
          form={form}
          onFinish={(values?: any) => onFinish(values)}
          initialValues={
            props.location?.state?.data ? props.location?.state?.data : null
          }
        >
          <Row gutter={[16, 16]}>
            <Col span={10}>
              <Form.Item
                label="Chủ đề"
                name="topic_id"
                rules={[{ required: true, message: 'Vui lòng chọn chủ đề' }]}
              >
                <SelectStyled placeholder="Chọn chủ đề">
                  {allTopic &&
                    allTopic.map((topic: any, index: number) => (
                      <Option key={index} value={topic.id}>
                        {topic.name}
                      </Option>
                    ))}
                </SelectStyled>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Phân loại"
                name="is_posted"
                rules={[{ required: true, message: 'Vui lòng chọn phân loại' }]}
              >
                <SelectStyled placeholder="Chọn phân loại">
                  <Option value={1}>Bài đăng</Option>
                  <Option value={0}>Lưu nháp</Option>
                </SelectStyled>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Ảnh"
            name="images"
            rules={[{ required: false, message: 'Vui lòng chọn ảnh' }]}
          >
            <Row>
              <UploadList
                initialFile={listImages}
                isUploadServerWhenUploading
                onSuccessUpload={(res: any) => {
                  setImageUpload(res)
                }}
                onRemove={(file: any) => {
                  deleteFile.push(file.uid)
                }}
              />
            </Row>
          </Form.Item>

          <Form.Item
            label="Video"
            name="video"
            rules={[{ required: false, message: 'Vui lòng chọn video' }]}
          >
            <Row>
              <UploadVideo
                initialFile={listVideo}
                isUploadServerWhenUploading
                onSuccessUpload={(res: any) => setVideoUpload(res)}
                onRemove={(file: any) => {
                  deleteFile.push(file.uid)
                }}
              />
            </Row>
          </Form.Item>
          <Form.Item
            label="Nội dung"
            name="content"
            rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}
          >
            <TextAreaStyled placeholder="Nội dung bài đăng..." />
          </Form.Item>
          <ButtonForm onCancel={onCancel} text="Lưu" />
        </Form>
        <Modal
          visible={isShowModalPreview}
          footer={null}
          onCancel={() => {
            setShowModalPreview(false)
          }}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </ContentScreen>
    </>
  )
}

export default AddEditPost
