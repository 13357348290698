import { Form, FormInstance, Select } from 'antd'
import { listProducts } from 'features/admin/product_clone/Products/ProductAPI'
import React from 'react'
import { toNonAccentVietnamese } from 'utils/Configs'

interface IProductSelect {
    record: any
    value: any
    form: FormInstance<any>
    setCallback: any
}

const ProductSelect = (props: IProductSelect) => {
    const { record, value, form, setCallback } = props
    const targetCategoryId = form.getFieldValue(`category_${record?.id}`)
    const [products, setProducts] = React.useState<any>([])

    const getProducts = async () => {
        try {
            const payload = {
                page: 1,
                limit: 999,
                category_id: targetCategoryId,
            }
            const res = await listProducts(payload)
            if (res?.status) {
                const newList = res?.data?.map((item: any) => ({
                    ...item.variants[0],
                    value: item?.variants[0].id,
                    label: item?.name,
                    unit: item?.unit,
                    product_category_id: item?.category?.id,
                }))
                setProducts(newList)
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        if (targetCategoryId) {
            getProducts()
        }
    }, [targetCategoryId])

    return (
        <Form.Item
            initialValue={value}
            name={`product_${record?.id}`}
            style={{ width: '100%' }}
            rules={[{ required: true, message: 'Vui lòng chọn sản phẩm!' }]}
        >
            <Select
                placeholder="Chọn sản phẩm"
                style={{ width: '100%' }}
                options={products}
                disabled={!targetCategoryId}
                onChange={(value: any, option: any) => {
                    form.setFieldValue(`unit_${record?.id}`, option.unit)
                    setCallback((prev: number) => prev + 1)
                }}
                showArrow
                showSearch
                filterOption={(input, option: any) => {
                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                    return nonAccent.includes(nonAccentInput)
                }}
            />
        </Form.Item>
    )
}

export default ProductSelect
