import { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import HeaderDetail from './component/HeaderDetail'
import ProductInfo from './component/ProductInfo'
import './component/css/DetailStyle.css'
import SaleInfo from './component/SaleInfo'
import Orders from './component/Orders'
const { TabPane } = Tabs

const onChangeTabs = (key: string) => {}

function ProductDetail({ location }: any) {
  const [tabKey, settabKey] = useState<string>()
  return (
    <>
      <HeaderDetail onLocation={location} />

      <div className="wapper-content">
        <Tabs
          defaultActiveKey={
            location.state?.currentTab ? location.state.currentTab : '1'
          }
          onChange={onChangeTabs}
        >
          <TabPane tab="Thông tin sản phẩm" key="1">
            <ProductInfo />
          </TabPane>
          {/* <TabPane tab="Khách hàng mua sản phẩm" key="2">
            <SaleInfo/>
          </TabPane> */}
          <TabPane tab="Danh sách đơn hàng" key="3">
            <Orders dataProduct={location.state} />
          </TabPane>
        </Tabs>
      </div>
    </>
  )
}

export default ProductDetail
