import { Card, Form, InputNumber, Table } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface IKIndexElement {
    item: any
}

const KIndexElement = (props: IKIndexElement) => {
    const { item } = props

    const columns = [
        {
            title: <b>Tên chi nhánh</b>,
            width: 200,
            dataIndex: 'branchName',
        },
        {
            title: <b>Hệ số K</b>,
            width: 100,
            dataIndex: 'kIndex',
            render: (value: any, record: any) => {
                return (
                    <Form.Item
                        name={`kIndex_${record?.kiotviet_id}_${record?.branch_id}`}
                        style={{ marginBottom: 0 }}
                        initialValue={value}
                        rules={[
                            { required: true, message: 'Vui lòng nhập hệ số k!' },
                            { min: 0, type: 'number', message: 'Giá trị tối thiểu bằng 0' },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder="Nhập hệ số k" />
                    </Form.Item>
                )
            },
        },
    ]

    React.useEffect(() => {}, [])

    return (
        <CustomCard title={<b>{item?.[0]?.kiot_name}</b>} bordered>
            <Table
                id="top-table"
                style={{ width: '100%' }}
                bordered
                columns={columns}
                dataSource={item}
                scroll={{
                    x: 500,
                    scrollToFirstRowOnChange: true,
                }}
                locale={{
                    emptyText: 'Chưa có bản ghi nào!',
                }}
                pagination={false}
            />
        </CustomCard>
    )
}

const CustomCard = styled(Card)`
    background-color: #fffdfd;
    border-radius: 10px;
`

export default KIndexElement
