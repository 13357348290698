import { useState, useEffect, memo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, DatePicker, Input, Select, Space } from 'antd'
import { PageHeader } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { IS_ACTIVE } from 'utils/constants'
import moment from 'moment'
const { RangePicker } = DatePicker
const { Option } = Select
interface HeaderProps {
    onSubmitKey: (value: string) => void
    onSubmitDatetime: (dateTime: Array<string>) => void
    onSubmitStatus: (status: string) => void
    onSyncNow: () => void
    visible: boolean
    isSearchLoading: boolean
}

function HeaderCate(props: HeaderProps) {
    const isLoadingSearch = props.isSearchLoading
    const history = useHistory()

    const handleGoBack = () => {
        history.goBack()
    }

    const handleSelectTime = (value: any, dateString: Array<string>) => {
        props.onSubmitDatetime(dateString)
    }

    const handleSelectStatus = (value: string) => {
        props.onSubmitStatus(value)
    }

    return (
        <>
            <PageHeader
                style={{
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    margin: '10px 5px',
                }}
                className="site-page-header"
                title="Danh mục sản phẩm"
                extra={[
                    <Space direction="horizontal">
                        <TypingAutoSearch
                            onSearchSubmit={(value: string) => props.onSubmitKey(value.trim())}
                            isSearchLoading={isLoadingSearch}
                            placeholder={'Tên danh mục...'}
                        />
                        <Select
                            style={{ width: 150 }}
                            onChange={handleSelectStatus}
                            allowClear
                            placeholder="Trạng thái"
                        >
                            <Option value={''}>Tất cả</Option>
                            <Option value={IS_ACTIVE.ACTIVE}>Đang hoạt động</Option>
                            <Option value={IS_ACTIVE.INACTIVE}>Ngừng hoạt động</Option>
                        </Select>
                        <RangePicker onChange={handleSelectTime} />
                        {props.visible ? (
                            <Button onClick={() => props.onSyncNow()} type="primary">
                                Đồng bộ ngay
                            </Button>
                        ) : null}
                    </Space>,
                ]}
            ></PageHeader>
        </>
    )
}

export default HeaderCate
