import { CheckCircleOutlined, DollarOutlined, EditOutlined, RedoOutlined, YuqueOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Popconfirm, Radio, Row, Spin, Switch, Table, Tabs, Typography, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import ButtonSave from 'common/components/Button/ButtonSave'
import { IAuthState } from 'common/components/HeaderInterfaces'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { ADMIN_ROLE, IS_ACTIVE } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { changeStatus, deleteCustomer, getCustomers, sendMessage, updateCustomerInfo } from '../CustomerAPI'
import { ICustomerPayload, IFormateDataCustomers, IFormatedPaging } from '../CustomerInterfaces'
import { WapperComponent } from './Customers'
import CustomerInfo from './DetailCustomer/CustomerInfo'
import HistoryCoin from './DetailCustomer/HistoryCoin'
import HistoryPoints from './DetailCustomer/HistoryPoints'
import OrderInfo from './DetailCustomer/OrderInfo'
import Header from './Header'
import SendMessageModal from './SendMessageModal'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { useDebounce } from 'common/hooks/Debounce'
import ChangePasswordModal from './ChangePasswordModal'
import UpdateCustomerModal from './UpdateCustomerModal'
import {
    resetSelectedIndiCustomer,
    setIsUpdateAllCustomerIndi,
    setOneSelectedIndiCustomer,
    setSelectedIndiCustomer,
} from '../CustomerSlice'
import CoinModal from './Topup'
const { Text } = Typography
const { TabPane } = Tabs

const IndividualCustomer = () => {
    const columns: ColumnsType<IFormateDataCustomers> = [
        Table.SELECTION_COLUMN,
        {
            title: 'STT',
            dataIndex: '',
            width: 70,
            render: (text, record, index) => (
                <Text id={record.id.toString()}>{(paging.current - 1) * paging.pageSize + index + 1}</Text>
            ),
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'full_name',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
        },
        {
            width: 130,
            title: 'Số điện thoại',
            dataIndex: 'phone_number',
        },
        {
            title: 'Tỉnh thành phố',
            dataIndex: 'province_id',
            render: (value: any, record: any) => record?.province?.name || '---',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: 'Công nợ',
            dataIndex: 'debt',
            width: 100,
            ellipsis: {
                showTitle: false,
            },
            render: (value: any) => {
                return <Text>{value ? `${formatPrice(value)} đ` : '0 đ'}</Text>
            },
            align: 'center',
        },
        {
            title: 'Xu',
            dataIndex: 'wallet',
            width: 100,
            ellipsis: {
                showTitle: false,
            },
            align: 'center',
            render: (value: any) => {
                return <Text>{value.coin ? `${formatPrice(value.coin)} xu` : '0 xu'}</Text>
            },
        },
        {
            title: 'Doanh số trên đơn hàng',
            dataIndex: 'turnover_of_order',
            width: 150,
            render: (value: any) => {
                return <Text>{value ? `${formatPrice(value)} đ` : '0 đ'}</Text>
            },
        },
        {
            title: 'Doanh số thực tế',
            dataIndex: 'turnover',
            width: 160,
            render: (value: any) => {
                return <Text>{value ? `${formatPrice(value)} đ` : '0 đ'}</Text>
            },
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_at',
            width: 120,
            render: (value: any) => {
                return <Text>{convertTimeStampToString(value)}</Text>
            },
        },
    ]

    const location: any = useLocation()
    const dispatch = useDispatch()

    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isSaleManager = userInfor?.admin?.role === ADMIN_ROLE.SALE_MANAGER

    const disSelecCustomerIds = useSelector((state: any) => state.customerReducer.disSelecCustomerIds)
    const isUpdateAllRedux = useSelector((state: any) => state.customerReducer.isUpdateAll)
    const isAccountant = userInfor?.admin?.role === ADMIN_ROLE.ACCOUNTANT
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN
    const authState: IAuthState = useSelector((state: any) => state.authReducer)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [record, setRecord] = React.useState<IFormateDataCustomers>()
    const [listCustomer, setListCustomer] = React.useState<any[]>([])
    const [listKiotviet, setlistKiotviet] = React.useState<IKiotviet[]>([])
    const [callback, setCallback] = React.useState(false)
    const [listRowKeysSelected, setListRowKeysSelected] = React.useState<any[]>([])
    const [selectedRows, setSelectedRows] = React.useState<any[]>([])
    const [currentSelected, setcurrentSelected] = React.useState<any>({ id: -1 })
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const [isUpdateCusModalOpen, setIsUpdateCusModalOpen] = React.useState<boolean>(false)
    const [isShowPasswordModal, setIsShowPasswordModal] = React.useState<boolean>(false)
    const [region, setRegion] = React.useState<string>(
        isSaleManager || isAccountant || isShopAdmin ? userInfor?.admin?.kiotviet_id : undefined
    )
    const [msgContent, setMsgContent] = React.useState<string>()
    const [isSendAll, setIsSendAll] = React.useState<boolean>(isUpdateAllRedux)
    const [isShowModalCoin, setIsShowModalCoin] = React.useState<boolean>(false)
    const [msgImage, setMsgImage] = React.useState<string>()
    const [params, setParams] = React.useState<ICustomerPayload>({
        page: location.state?.page?.current,
        limit: '12',
        search: '',
        province_id: '',
        create_from: '',
        create_to: '',
        kiotviet_id: isSaleManager || isAccountant || isShopAdmin ? region : '',
        is_retail_cus: '1',
    })

    const [isShowSynchButton, setIsShowSynchButton] = React.useState(true)
    const [currentKiotVietID, setCurrentKiotVietID] = React.useState<string | undefined>(
        isSaleManager ? userInfor?.admin?.kiotviet_id : undefined
    )

    const [paging, setPaging] = React.useState<IFormatedPaging>({
        total: 0,
        current: 1,
        pageSize: 12,
    })

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                setlistKiotviet(res.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getDataCustomer = async () => {
        setIsLoading(true)
        try {
            const res = await getCustomers(params)
            if (res.data) {
                const dataCustomer = res.data?.map((customer, index) => {
                    return {
                        ...customer,
                        stt: index + 1,
                        key: customer?.id,
                    }
                })

                if (isSendAll) {
                    const actualDataTable = dataCustomer?.filter((item: any) => !disSelecCustomerIds.includes(item?.id))
                    const actualListKeys = actualDataTable?.map((item: any) => item?.id)

                    setSelectedRows(actualDataTable)
                    setListRowKeysSelected(actualListKeys)
                }

                setListCustomer(dataCustomer)
            }
            if (res.paging) {
                setPaging({
                    total: res.paging.totalItemCount,
                    current: res.paging.page,
                    pageSize: res.paging.limit,
                })
            }
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const onSendMessage = async () => {
        try {
            setIsLoading(false)

            if (!msgContent || msgContent.length === 0) {
                message.error('Vui lòng nhập nội dung tin nhắn!')
                return false
            }

            const payload = {
                content: msgContent,
                user_ids: selectedRows?.map((item: any) => item?.id),
                message_media_url: msgImage,
            }

            const res = await sendMessage(payload, params)
            if (res?.status) {
                message.success('Gửi tin nhắn thành công!')
                setMsgContent('')
                setIsModalOpen(false)
                setListRowKeysSelected([])
                setSelectedRows([])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleChaneStatus = async (status: boolean, record: any) => {
        try {
            const res = await changeStatus({
                id: record.id,
                status: status ? IS_ACTIVE.ACTIVE : IS_ACTIVE.INACTIVE,
            })
            if (res.status) {
                getDataCustomer()
                message.success('Thay đổi trạng thái thành công!')
            }
        } catch (err) {
            console.log(err)
            message.error('Có lỗi xảy ra')
        }
    }

    const onUpdateCustomer = async (values: any) => {
        try {
            setIsLoading(true)
            const payload = {
                full_name: values?.name,
                phone_number: values?.phone,
                date_of_birth: values?.dob?.format('YYYY-MM-DD'),
                gender: values?.gender,
                province_id: values?.province,
            }
            const res = await updateCustomerInfo(currentSelected.id, payload)
            if (res?.status) {
                message.success('Cập nhật thông tin khách hàng thành công!')
                setIsUpdateCusModalOpen(false)
                getDataCustomer()
                setCallback(prev => !prev)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onDeleteCustomer = async () => {
        try {
            setIsLoading(true)
            const res = await deleteCustomer(currentSelected.id)
            if (res?.status) {
                message.success('Xoá khách hàng thành công!')
                getDataCustomer()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCallback = () => {
        setCallback(!callback)
    }

    React.useEffect(() => {
        getDataCustomer()
    }, [params, isSendAll])

    React.useEffect(() => {
        getDataKiotviet()
        // if (authState?.userInfo?.admin?.kiotviet_id) {
        //     setRegion(authState.userInfo.admin?.kiotviet_id)
        // }
    }, [authState])

    return (
        <>
            <CoinModal
                visible={isShowModalCoin}
                cancel={() => setIsShowModalCoin(false)}
                data={record}
                handleCallback={handleCallback}
            />
            <Header
                title={'Khách lẻ'}
                params={params}
                dataCustomer={listCustomer}
                onSubmidCity={(value: string) =>
                    setParams({
                        ...params,
                        province_id: value,
                        page: 1,
                    })
                }
                region={region}
                visible={isShowSynchButton && region !== undefined}
                kiotvietID={currentKiotVietID}
                onSubmitTime={(value: Array<string>) =>
                    setParams({
                        ...params,
                        create_from: value[0],
                        create_to: value[1],
                        page: 1,
                    })
                }
                onSubmitKeyword={(value: string) => {
                    setParams({
                        ...params,
                        search: value,
                        page: 1,
                    })
                    setcurrentSelected(null)
                }}
            />
            {isModalOpen && (
                <SendMessageModal
                    setMsgContent={setMsgContent}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    onFinish={onSendMessage}
                    setMsgImage={setMsgImage}
                    setIsSendAll={setIsSendAll}
                />
            )}
            {isUpdateCusModalOpen && (
                <UpdateCustomerModal
                    handleFinish={onUpdateCustomer}
                    visible={isUpdateCusModalOpen}
                    setVisible={setIsUpdateCusModalOpen}
                    data={currentSelected}
                />
            )}
            {isShowPasswordModal && (
                <ChangePasswordModal
                    data={record}
                    visible={isShowPasswordModal}
                    cancel={() => setIsShowPasswordModal(false)}
                />
            )}
            <WapperComponent padding>
                <CustomRow>
                    <Radio.Group
                        defaultValue={region}
                        onChange={(e: any) => {
                            if (e) {
                                setIsShowSynchButton(true)
                            } else {
                                setIsShowSynchButton(false)
                            }
                            setCurrentKiotVietID(e.target.value)
                            setcurrentSelected(null)
                            setRegion(e.target.value)
                            setParams({ ...params, page: '1', kiotviet_id: e.target.value })
                            setListRowKeysSelected([])
                            setSelectedRows([])
                            dispatch(resetSelectedIndiCustomer())
                        }}
                    >
                        <Radio.Button disabled={isSaleManager || isAccountant || isShopAdmin} value={undefined}>
                            Tất cả
                        </Radio.Button>
                        {listKiotviet &&
                            listKiotviet.map((region, index) => (
                                <Radio.Button
                                    key={index}
                                    value={region.id}
                                    disabled={
                                        (isSaleManager && region.id !== userInfor?.admin?.kiotviet_id) ||
                                        (isAccountant && region.id !== userInfor?.admin?.kiotviet_id) ||
                                        (isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id)
                                    }
                                >
                                    {region.name ? region.name : '--'}
                                </Radio.Button>
                            ))}
                    </Radio.Group>
                    {!isAccountant && !isShopAdmin && (
                        <ButtonSave
                            text="Gửi tin nhắn"
                            icon={<YuqueOutlined />}
                            onClickButton={() => {
                                if (selectedRows?.length === 0 && !isSendAll) {
                                    message.error('Vui lòng chọn ít nhất một khách hàng!')
                                } else setIsModalOpen(true)
                            }}
                        />
                    )}
                </CustomRow>
                <div style={{ padding: '20px 0 10px 10px' }}>
                    <Text>
                        Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                    </Text>
                </div>
                <CustomRow>
                    <Checkbox
                        checked={isSendAll}
                        onChange={(e: any) => {
                            setIsSendAll(e.target.checked)
                            dispatch(setIsUpdateAllCustomerIndi(e.target.checked))
                            if (e.target.checked) {
                            } else {
                                setListRowKeysSelected([])
                                setSelectedRows([])
                                dispatch(setSelectedIndiCustomer({ newListKeys: [], status: false }))
                            }
                        }}
                    >
                        Chọn tất cả theo bộ lọc
                    </Checkbox>
                </CustomRow>
                <br />
                <Spin spinning={isLoading}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={listCustomer}
                        expandedRowKeys={[currentSelected?.key]}
                        scroll={{
                            x: 800,
                            scrollToFirstRowOnChange: true,
                        }}
                        rowSelection={{
                            selectedRowKeys: listRowKeysSelected,

                            onSelect: (record: any, selected: any) => {
                                if (selected) {
                                    setSelectedRows((prev: any) => [...prev, record])
                                    setListRowKeysSelected((prev: any) => [...prev, record?.id])
                                } else {
                                    const newSelectedRows = selectedRows?.filter((item: any) => item?.id !== record?.id)
                                    setSelectedRows(newSelectedRows)

                                    const newListKeys = listRowKeysSelected?.filter((item: any) => item !== record?.id)
                                    setListRowKeysSelected(newListKeys)
                                }
                                dispatch(setOneSelectedIndiCustomer({ record, selected }))
                            },

                            onSelectAll: (selected: boolean, selectedRowList: any[], changeRows: any[]) => {
                                if (selected) {
                                    setSelectedRows((prev: any) => [...prev, ...changeRows])
                                    const newListKeys = changeRows?.map((item: any) => item?.id)
                                    setListRowKeysSelected((prev: any) => [...prev, ...newListKeys])
                                    dispatch(setSelectedIndiCustomer({ newListKeys, selected }))
                                } else {
                                    const deselectedRowKeys = changeRows?.map((item: any) => item?.id)

                                    const newListKeys = listRowKeysSelected?.filter(
                                        (item: any) => !deselectedRowKeys.includes(item)
                                    )
                                    const newListRows = selectedRows?.filter((item: any) =>
                                        newListKeys.includes(item?.id)
                                    )

                                    setSelectedRows(newListRows)
                                    setListRowKeysSelected(newListKeys)

                                    if (isSendAll) {
                                        dispatch(
                                            setSelectedIndiCustomer({ newListKeys: newListRows, selected, changeRows })
                                        )
                                    } else {
                                        dispatch(setSelectedIndiCustomer({ newListKeys: newListRows, selected }))
                                    }
                                }
                            },
                        }}
                        expandable={{
                            expandedRowRender: record => {
                                return (
                                    <>
                                        {currentSelected && (
                                            <Card
                                                actions={
                                                    !isSaleManager
                                                        ? [
                                                              <Button
                                                                  style={{ color: 'green', border: 'none' }}
                                                                  type="text"
                                                                  onClick={() => setIsUpdateCusModalOpen(true)}
                                                              >
                                                                  <EditOutlined style={{ paddingRight: '10px' }} />
                                                                  Chỉnh sửa
                                                              </Button>,
                                                              <>
                                                                  <Switch
                                                                      checked={record.status == IS_ACTIVE.ACTIVE}
                                                                      onChange={(status: boolean) =>
                                                                          handleChaneStatus(status, record)
                                                                      }
                                                                  />
                                                                  {record.status ? (
                                                                      <TextStyled color>Đang hoạt động</TextStyled>
                                                                  ) : (
                                                                      <TextStyled>Ngừng hoạt động</TextStyled>
                                                                  )}
                                                              </>,
                                                              <Button
                                                                  style={{ color: '#4264de' }}
                                                                  type="text"
                                                                  onClick={() => {
                                                                      setRecord(record)
                                                                      setIsShowModalCoin(true)
                                                                  }}
                                                              >
                                                                  <DollarOutlined style={{ paddingRight: '10px' }} />
                                                                  Nạp xu
                                                              </Button>,
                                                              <Button
                                                                  style={{ color: 'orange', border: 'none' }}
                                                                  onClick={() => {
                                                                      setRecord(record)
                                                                      setIsShowPasswordModal(true)
                                                                  }}
                                                              >
                                                                  <RedoOutlined style={{ paddingRight: '10px' }} />
                                                                  Đổi mật khẩu
                                                              </Button>,
                                                              <Popconfirm
                                                                  title={'Bạn chắc chắn muốn xoá khách hàng này?'}
                                                                  onConfirm={onDeleteCustomer}
                                                                  okText="Xoá"
                                                                  cancelText="Huỷ"
                                                                  okButtonProps={{
                                                                      type: 'primary',
                                                                  }}
                                                              >
                                                                  <Button style={{ color: 'red', border: 'none' }}>
                                                                      <CheckCircleOutlined
                                                                          style={{ paddingRight: '10px' }}
                                                                      />
                                                                      Xoá
                                                                  </Button>
                                                              </Popconfirm>,
                                                          ]
                                                        : []
                                                }
                                            >
                                                <Tabs onChange={(key: string) => {}}>
                                                    <TabPane tab="Thông tin chung" key="1">
                                                        <CustomerInfo
                                                            isIndividual={true}
                                                            dataCustomer={record}
                                                            callback={callback}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Thông tin mua hàng" key="2">
                                                        <OrderInfo infoCustomer={record} />
                                                    </TabPane>

                                                    <TabPane tab="Lịch sử tích điểm" key="4">
                                                        <HistoryPoints dataCustomer={record} />
                                                    </TabPane>
                                                    <TabPane tab="Lịch sử xu" key="5">
                                                        <HistoryCoin dataCustomer={record} callback={callback} />
                                                    </TabPane>
                                                </Tabs>
                                            </Card>
                                        )}
                                    </>
                                )
                            },

                            onExpand: (status: any, record: any) => {
                                if (currentSelected != record) setcurrentSelected(record)
                                else setcurrentSelected(null)
                            },
                        }}
                        pagination={{
                            ...paging,
                            showSizeChanger: false,
                            onChange: async (page, pageSize) => {
                                setParams({ ...params, page: page.toString() })
                            },
                        }}
                    />
                </Spin>
            </WapperComponent>
        </>
    )
}

const TextStyled = styled(Text)<any>`
    font-weight: 600;
    padding-left: 10px;
    color: ${props => (props.color ? '#00abba' : '#f73152')};
`

const CustomRow = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export default IndividualCustomer
