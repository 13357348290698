import reactotron from 'ReactotronConfig'
import { Radio, Table } from 'antd'
import { IAuthState } from 'common/components/HeaderInterfaces'
import { SESSION_ID } from 'common/config'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import Cookie from 'js-cookie'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ADMIN_ROLE, REPORT_TYPE } from 'utils/constants'
import { notificationError } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { getReport } from '../SalesReportApi'
import Filter from './Filter'

type Props = {
    keyTabs: number
}

export default function ByCustomer({ keyTabs }: Props) {
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isSaleManager = userInfor?.admin?.role === ADMIN_ROLE.SALE_MANAGER
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN
    const heightWeb = useSelector((state: any) => state.configReducer.dimension.height)
    const [region, setRegion] = useState<any>(isSaleManager || isShopAdmin ? userInfor?.admin?.kiotviet_id : undefined)
    const [listKiotviet, setListKiotviet] = useState<any>()
    const authState: IAuthState = useSelector((state: any) => state.authReducer)
    const [dataTable, setDataTable] = useState([])
    const [listShop, setListShop] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const currentDate = new Date(Date.now())
    const current_date = currentDate.toISOString().split('T')[0]

    const [paramsCustomer, setParamsCustomer] = useState({
        search: '',
        type: REPORT_TYPE.CUSTOMER,
        from_date: '1970-01-01',
        to_date: current_date,
        kiotviet_id: 0,
        page: 1,
        limit: 12,
        category_id: 0,
    })
    const cookie = Cookie.get(SESSION_ID)

    const [paging, setPaging] = useState({
        total: 0,
        current: 1,
        pageSize: 0,
    })
    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                setListKiotviet(res.data)
            }
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra!')
        }
    }
    // const initSocketEvents = () => {
    //   const cookie = Cookie.get(SESSION_ID)
    //   if (!WebSocket.socketClient) WebSocket.init(cookie!)
    //   WebSocket.socketClient.onAny((event: any, data: any) => {
    //     reactotron.log!('socket data', { event, data })
    //     switch (event) {
    //       case '':
    //         notificationSuccess('Có yêu cầu điện hoa mới')
    //         break
    //       case '':
    //         notificationSuccess(data.data.content)
    //         break

    //       default:
    //         break
    //     }

    //     if (event.includes('shop_id')) {
    //       const kiotvietID = Number(event.slice(8, event.length))
    //       //test thôi, sau viết lại
    //       dispatch(getMessageNotReadAction())
    //     }
    //   })
    // }
    useEffect(() => {
        getDataKiotviet()
    }, [authState])
    const handleChangeStock = (idStock: any) => {
        setParamsCustomer({ ...paramsCustomer, kiotviet_id: idStock, page: 1 })
        reactotron.log!({ idStock })
        setRegion(idStock)
    }
    const getData = async () => {
        setIsLoading(true)
        try {
            const response = await getReport(paramsCustomer)
            setDataTable(response.data)
            setListShop(response.data.listShop)
            setPaging({
                total: response.paging.totalItemCount,
                current: response.paging.page,
                pageSize: response.paging.limit,
            })
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getData()
        // initSocketEvents()
    }, [paramsCustomer])
    const columns = [
        {
            width: 70,
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text: any, record: any, index: any) => (
                <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
                    {(paging.current - 1) * paging.pageSize + index + 1}
                </td>
            ),
            ellipsis: true,
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'full_name',
            key: 'name',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone_number',
            key: 'phone',
        },
        {
            title: 'SL đã bán',
            width: 110,
            dataIndex: 'total_purchase',
            key: 'total_amount',
            render: (value: string) => <td>{value != '0' ? formatPrice(value) : 0} </td>,
        },
        {
            title: 'Số đơn hàng',
            width: 130,
            dataIndex: 'total_order',
            key: 'total_order',
            render: (value: string) => <td>{value != '0' ? formatPrice(value) : 0} </td>,
        },
        {
            title: 'Tổng tiền thực tế',
            dataIndex: 'total_payment',
            key: 'total_price',
            render: (value: string) => <td>{value != '0' ? formatPrice(value) : 0} đ</td>,
        },
    ]
    return (
        <div>
            <div style={{ margin: '0 10px 15px' }}>
                <Filter
                    paramsCustomer={paramsCustomer}
                    paramsProduct={paramsCustomer}
                    keyTabs={keyTabs}
                    listShop={listShop}
                    onShopSubmit={(shop_id: any) => {
                        setParamsCustomer({
                            ...paramsCustomer,
                            kiotviet_id: shop_id,
                            page: 1,
                        })
                    }}
                    onTopicSubmit={(category_id: any) => {
                        setParamsCustomer({
                            ...paramsCustomer,
                            category_id: category_id,
                            page: 1,
                        })
                    }}
                    onSearchSubmit={(search: string) => {
                        setParamsCustomer({ ...paramsCustomer, search: search, page: 1 })
                    }}
                    onDateSubmit={(from_date: string, to_date: string) => {
                        setParamsCustomer({
                            ...paramsCustomer,
                            from_date: from_date,
                            to_date: to_date,
                            page: 1,
                        })
                    }}
                />
            </div>
            <div>
                <Radio.Group
                    style={{ padding: '10px' }}
                    value={region}
                    onChange={(e: any) => handleChangeStock(e.target.value)}
                >
                    <Radio.Button disabled={isSaleManager || isShopAdmin} value={undefined}>
                        Tất cả
                    </Radio.Button>
                    {listKiotviet &&
                        listKiotviet.map((region: any, index: number) => (
                            <Radio.Button
                                key={index}
                                value={region.id}
                                disabled={
                                    (isSaleManager && region.id !== userInfor?.admin?.kiotviet_id) ||
                                    (isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id)
                                }
                            >
                                {region.name ? region.name : '--'}
                            </Radio.Button>
                        ))}
                </Radio.Group>
                <p style={{ padding: '15px 15px 0px' }}></p>
                <p>
                    Kết quả lọc: <b>{formatPrice(paging.total)}</b>
                </p>
                <Table
                    className="table-expanded-custom-no-image"
                    columns={columns}
                    bordered
                    loading={isLoading}
                    dataSource={dataTable}
                    scroll={{
                        x: 800,
                        scrollToFirstRowOnChange: true,
                        // y: 'calc(100vh - 300px)',
                        y: `calc(${heightWeb}px - 440px)`,
                    }}
                    pagination={{
                        ...paging,
                        showSizeChanger: false,
                        onChange: async (page, pageSize) => {
                            setParamsCustomer({ ...paramsCustomer, page: page })
                        },
                    }}
                />
            </div>
        </div>
    )
}
