import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Upload,
} from 'antd'
import ButtonForm from 'common/components/ModalButton'
import React, { useEffect, useState } from 'react'
import createFormDataImage from 'utils/createFormDataImage'
import { validHeadPhoneNumber } from 'utils/funcHelper'
import { requestUploadImage } from '../ShopApi'

type Props = {
  visible: boolean
  onCancel: any
  reloadShopList: any
  addShop: any
  isLoadingButton: boolean
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

function getBase64(img: any, callback: any) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('Xảy ra lỗi! Bạn chỉ có thể upload ảnh có dạng JPG/PNG!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Cho phép ảnh có dung lượng tối đa là 2MB')
  }
  return isJpgOrPng && isLt2M
}

export default function AddShop({
  visible,
  onCancel,
  addShop,
  isLoadingButton,
}: Props) {
  const [form] = Form.useForm()
  const [showPancakeKey, setShowPancakeKey] = useState<boolean>(false)

  const [upload, setUpload] = useState({
    loading: false,
    imageUrl: '',
  })

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setUpload({
        imageUrl: '',
        loading: true,
      })
      return
    }

    if (info.file.status === 'done' || info.file.status === 'error') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) =>
        setUpload({
          imageUrl: imageUrl,
          loading: false,
        })
      )
    }
  }

  const uploadButton = (
    <div>
      {upload.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const onFinish = async (values: any) => {
    // Upload image lên Server
    if (values.profile_picture_url.fileList) {
      var resUploadImage = []
      var last_element = []

      if (values.profile_picture_url.fileList.length > 1) {
        last_element = [values.profile_picture_url.fileList.slice(-1)[0]]
      } else {
        last_element = values.profile_picture_url.fileList
      }
      const dataImage = await createFormDataImage(last_element)
      const payloadImage = {
        type: 0,
        data: dataImage,
      }
      resUploadImage = await requestUploadImage(payloadImage)
      console.log(resUploadImage)
      form.resetFields()
    }

    delete values.confirm
    let newData = {
      ...values,
      name: values.name.trim(),
      email: values.email.trim(),
      profile_picture_url: resUploadImage.data.path,
    }
    console.log(newData)
    if (newData.pancake_shop_id && newData.pancake_shop_key) {
      addShop(newData, form.resetFields)
    } else {
      Modal.confirm({
        title: 'Cảnh báo!',
        content: (
          <>
            Bạn có chắc chắn không đồng bộ Pancake!
            <br />
            <br />
            Hệ thống sẽ không đồng bộ các thông tin của gian hàng này với
            Pancake!
          </>
        ),
        okText: 'Đồng ý',
        cancelText: 'Quay lại',
        onOk() {
          addShop(newData, form.resetFields)
        },
      })
    }
  }
  useEffect(() => {
    if (visible === false) {
      setUpload({
        imageUrl: '',
        loading: false,
      })
    }
  })
  return (
    <Modal
      title="Thêm gian hàng"
      visible={visible}
      maskClosable={false}
      footer={null}
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
    >
      <Form
        {...formItemLayout}
        labelAlign="left"
        form={form}
        onFinish={(values: any) => onFinish(values)}
        scrollToFirstError
      >
        <Form.Item
          label="Tên gian hàng"
          name="name"
          rules={[
            {
              type: 'string',
              message: 'Nhập tên gian hàng',
            },
            {
              required: true,
              message: 'Vui lòng nhập tên gian hàng!',
            },
            {
              min: 1,
              max: 50,
              message: 'Vui lòng nhập từ 1 đến 50 ký tự!',
            },
            {
              whitespace: true,
              message: 'Vui lòng không nhập khoảng trắng!',
            },
            // {
            //   message: 'Vui lòng không nhập kí tự đặc biệt',
            //   validator: (_, value) => {
            //     // const reg = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
            //     const reg = /[^a-z0-9A-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậếýắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]/u
            //     if (!reg.test(value)) {
            //       return Promise.resolve()
            //     }
            //     return Promise.reject()
            //   },
            // },
          ]}
        >
          <Input placeholder="Nhập tên gian hàng" />
        </Form.Item>

        <Form.Item
          label="Họ tên"
          name="name_user"
          rules={[
            {
              type: 'string',
              message: 'Nhập họ tên',
            },
            {
              required: true,
              message: 'Vui lòng nhập họ tên!',
            },
            {
              min: 1,
              max: 50,
              message: 'Vui lòng nhập từ 1 đến 50 ký tự!',
            },
            {
              whitespace: true,
              message: 'Vui lòng không nhập khoảng trắng!',
            },
            // {
            //   message: 'Vui lòng không nhập kí tự đặc biệt',
            //   validator: (_, value) => {
            //     // const reg = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
            //     const reg = /[^a-z0-9A-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầếýẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]/u
            //     if (!reg.test(value)) {
            //       return Promise.resolve()
            //     }
            //     return Promise.reject()
            //   },
            // },
          ]}
        >
          <Input placeholder="Nhập họ tên" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Số điện thoại"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số điện thoại',
            },
            {
              message: 'Vui lòng nhập số điện thoại',
              validator: (_, value) => {
                let trimValue: string = ''
                if (value) {
                  trimValue = value.trim()
                }
                const reg =
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im // validate phone number
                let validPhone: boolean = true
                if (
                  trimValue &&
                  trimValue.length < 10 &&
                  trimValue.includes('84')
                ) {
                  validPhone = false
                } else if (
                  trimValue &&
                  trimValue.length < 11 &&
                  trimValue.includes('+84')
                ) {
                  validPhone = false
                }

                if (
                  trimValue === '' ||
                  trimValue === null ||
                  (reg.test(trimValue) &&
                    validHeadPhoneNumber(trimValue) &&
                    validPhone)
                ) {
                  return Promise.resolve()
                }
                return Promise.reject()
              },
            },
          ]}
        >
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập email!',
            },
            {
              message: 'Định dạng email không hợp lệ',
              validator: (_, value) => {
                let trimValue: string = ''
                let subString: Array<string> = []
                if (value) {
                  trimValue = value.trim()
                  subString = value.split('@')
                }
                const reg =
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // validate email
                const reg2 = /^[\x00-\x7F]*$/ // just ascii

                const reg3 = /([a-zA-Z])+([ -~])*/ // contains a letter
                let checkMail = false
                if (subString.length === 2 && reg3.test(subString[0])) {
                  checkMail = true
                }
                if (
                  !value ||
                  value === '' ||
                  // value.length > 20 ||
                  (reg.test(trimValue) && reg2.test(trimValue) && checkMail)
                ) {
                  return Promise.resolve()
                }
                return Promise.reject()
              },
            },
          ]}
        >
          <Input placeholder="Nhập email" />
        </Form.Item>

        <Form.Item label={<p style={{ fontWeight: 600 }}>Đồng bộ Pancake</p>}>
          <Checkbox
            onChange={() => {
              setShowPancakeKey(!showPancakeKey)
              form.resetFields(['pancake_shop_key', 'pancake_shop_id'])
            }}
            checked={showPancakeKey}
          >
            {/* {showPancakeKey ? 'Có' : 'Không'} */}
          </Checkbox>
        </Form.Item>

        {showPancakeKey ? (
          <div>
            <Form.Item name="pancake_shop_key" label="Pancake shop key">
              <Input placeholder="Nhập Pancake shop key" />
            </Form.Item>

            <Form.Item name="pancake_shop_id" label="Pancake shop id">
              <Input placeholder="Nhập Pancake shop id" />
            </Form.Item>
          </div>
        ) : (
          <></>
        )}

        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu!',
            },
            {
              min: 6,
              max: 20,
              message: 'Vui lòng nhập từ 6 đến 20 ký tự!',
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Nhập mật khẩu" />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Nhập lại mật khẩu"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Nhập lại mật khẩu!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Mật khẩu không khớp!'))
              },
            }),
          ]}
        >
          <Input.Password placeholder="Nhập lại mật khẩu" />
        </Form.Item>

        <Form.Item
          label="Ảnh đại diện"
          name="profile_picture_url"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn ảnh đại diện!',
            },
          ]}
        >
          <Upload
            name="profile_picture_url"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {upload.imageUrl ? (
              <img
                src={upload.imageUrl}
                alt="avatar"
                style={{ width: '100%', objectFit: 'contain', height: '100%' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <ButtonForm
          isLoadingButton={isLoadingButton}
          onCancel={onCancel}
          text="Lưu"
        />
        {/* </Form.Item> */}
      </Form>
    </Modal>
  )
}
