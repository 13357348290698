import { Button, Form, InputNumber, Modal, Row, Select, Spin, Table, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { getShippers } from 'features/admin/shipper/services'
import React from 'react'
import styled from 'styled-components'
import { toNonAccentVietnamese } from 'utils/Configs'
import { momentToStringDate } from 'utils/TimerHelper'
import { formatPrice } from 'utils/ruleForm'
import { assignOrderForShipper } from '../services'
import { creatableDesc } from 'features/admin/order-clone/Orders'
import { useDispatch, useSelector } from 'react-redux'
import { resetRefOrders } from '../services/RefundOrderSlice'
import { resetOrders } from 'features/admin/order-clone/OrderSlice'

interface IAssignOrderForShipper {
    isModalOpen: boolean
    setIsModalOpen: any
    listOrders: any[]
    isRefundOrder: boolean
    params?: any
    isLoading?: boolean
    setisLoading: React.Dispatch<React.SetStateAction<boolean>>
    getListData: (params?: any) => Promise<void>
    setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>
    setListRowKeysSelected: React.Dispatch<React.SetStateAction<any[]>>
}

const AssignOrderForShipper = (props: IAssignOrderForShipper) => {
    const {
        isModalOpen,
        setIsModalOpen,
        params,
        isRefundOrder,
        isLoading,
        getListData,
        setisLoading,
        setSelectedRows,
        setListRowKeysSelected,
    } = props

    const selectedRefOrderList = useSelector((state: any) => state.refundOrderReducer.orderList)
    const selectedOrderList = useSelector((state: any) => state.orderReducer.orderList)

    const dispatch = useDispatch()
    const [form] = useForm()
    const [listShippers, setListShippers] = React.useState<any>([])
    const [currentPage, setCurrentPage] = React.useState<number>(1)

    const columns = !isRefundOrder
        ? [
              {
                  title: <b>STT</b>,
                  width: 70,
                  dataIndex: 'stt',
                  render: (value: any, record: any, index: number) => (currentPage - 1) * 10 + index + 1,
              },
              {
                  title: <b>Mã đơn</b>,
                  dataIndex: 'code',
              },
              {
                  title: <b>Khách hàng</b>,
                  dataIndex: 'customer',
                  render: (_: any, record: any) => record?.shipping_name,
              },
              {
                  title: <b>Số SP</b>,
                  dataIndex: 'quantity_product',
              },
              {
                  title: <b>Tổng tiền</b>,
                  dataIndex: 'total_order',
                  render: (value: any) => formatPrice(value),
              },
              {
                  title: <b>Nguồn đơn</b>,
                  dataIndex: 'creatable_type',
                  render: (creatable_type: any) => creatableDesc(creatable_type),
              },
              {
                  title: <b>Ngày tạo</b>,
                  width: 120,
                  dataIndex: 'date',
                  render: (_: any, record: any) => momentToStringDate(record?.created_at),
              },
              {
                  title: <b>Ngày giao</b>,
                  width: 120,
                  dataIndex: 'delivery_date',
                  render: (value: any) => momentToStringDate(value),
              },
              {
                  title: <b>Thu COD</b>,
                  dataIndex: 'cod',
                  render: (_: any, record: any) => (
                      <Form.Item
                          name={`cod_${record?.id}`}
                          rules={[{ min: 1, type: 'number', message: 'Giá trị tối thiểu là 1!' }]}
                          initialValue={
                              !record?.order_delivery_collect_money ? undefined : record?.order_delivery_collect_money
                          }
                      >
                          <InputNumber
                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              min={1}
                              style={{ width: '100%' }}
                              placeholder="Nhập COD"
                          />
                      </Form.Item>
                  ),
              },
              {
                  title: <b>Thứ tự giao đơn</b>,
                  dataIndex: 'index',
                  width: 150,
                  render: (_: any, record: any) => (
                      <Form.Item
                          name={`index_${record?.id}`}
                          initialValue={
                              record?.order_delivery_display_order === 1 || !record?.order_delivery_display_order
                                  ? 1
                                  : record?.order_delivery_display_order
                          }
                          rules={[
                              { required: true, message: 'Vui lòng nhập thứ tự giao đơn!' },
                              { min: 1, type: 'number', message: 'Giá trị tối thiểu là 1!' },
                          ]}
                      >
                          <InputNumber
                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              min={1}
                              style={{ width: '100%' }}
                              placeholder="Nhập thứ tự"
                          />
                      </Form.Item>
                  ),
              },
          ]
        : [
              {
                  title: <b>STT</b>,
                  width: 70,
                  dataIndex: 'stt',
                  render: (value: any, record: any, index: number) => (currentPage - 1) * 12 + index + 1,
              },
              {
                  title: <b>Mã đơn</b>,
                  dataIndex: 'code',
              },
              {
                  title: <b>Khách hàng</b>,
                  dataIndex: 'customer',
                  render: (_: any, record: any) => record?.shipping_name,
              },
              {
                  title: <b>Số điện thoại</b>,
                  dataIndex: 'phone_number',
              },
              {
                  title: <b>Số SP</b>,
                  dataIndex: 'quantity_product',
              },
              {
                  title: <b>Ngày tạo</b>,
                  width: 120,
                  dataIndex: 'date',
                  render: (_: any, record: any) => momentToStringDate(record?.created_at),
              },
              {
                  title: <b>Ngày giao</b>,
                  width: 120,
                  dataIndex: 'delivery_date',
                  render: (value: any) => momentToStringDate(value),
              },
              {
                  title: <b>Thứ tự giao đơn</b>,
                  dataIndex: 'index',
                  width: 300,
                  render: (_: any, record: any) => {
                      return (
                          <Form.Item
                              name={`index_${record?.id}`}
                              initialValue={
                                  record?.order_delivery_display_order === 1 || !record?.order_delivery_display_order
                                      ? 1
                                      : record?.order_delivery_display_order
                              }
                              rules={[
                                  { required: true, message: 'Vui lòng nhập thứ tự giao đơn!' },
                                  { min: 1, type: 'number', message: 'Giá trị tối thiểu là 1!' },
                              ]}
                          >
                              <InputNumber style={{ width: '100%' }} placeholder="Nhập thứ tự" />
                          </Form.Item>
                      )
                  },
              },
          ]

    const onCancel = () => {
        setIsModalOpen(false)
    }

    const getListShippers = async () => {
        try {
            const payload = {
                page: 1,
                limit: 999,
                kiotviet_id: isRefundOrder
                    ? selectedRefOrderList?.[0]?.kiotviet_id
                    : selectedOrderList?.[0]?.kiotviet_id,
            }
            const res: any = await getShippers(payload)
            if (res?.status) {
                const listData = res?.data
                    ?.filter((item: any) => item?.status)
                    ?.map((item: any) => ({
                        ...item,
                        value: item?.id,
                        label: `${item?.full_name} - ${item?.phone_number}`,
                    }))
                setListShippers(listData)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onFinish = async (values: any) => {
        try {
            setisLoading(true)
            let payload: any
            if (isRefundOrder) {
                const orders = selectedRefOrderList?.map((item: any) => ({
                    display_order: values[`index_${item?.id}`],
                    refund_order_id: item?.id,
                }))
                payload = { orders }
            } else {
                const orders = selectedOrderList?.map((item: any) => ({
                    display_order: values[`index_${item?.id}`],
                    order_id: item?.id,
                    collect_money: values[`cod_${item?.id}`],
                }))

                payload = { orders }
            }

            const res = await assignOrderForShipper(values?.shipper, payload)
            if (res?.status) {
                if (isRefundOrder) {
                    dispatch(resetRefOrders())
                } else {
                    dispatch(resetOrders())
                }
                message.success('Giao đơn hàng cho shipper thành công!')
                setIsModalOpen(false)
                getListData(params)
                setSelectedRows([])
                setListRowKeysSelected([])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setisLoading(false)
        }
    }

    React.useEffect(() => {
        getListShippers()
    }, [])

    return (
        <Modal
            okText="Lưu"
            title="Giao đơn cho shipper"
            destroyOnClose
            width={'90%'}
            centered
            maskClosable={false}
            visible={isModalOpen}
            onCancel={onCancel}
            footer={null}
        >
            <Spin spinning={isLoading}>
                <CustomForm form={form} onFinish={onFinish}>
                    <CustomRow>
                        <Form.Item
                            label="Chọn shipper"
                            name={'shipper'}
                            rules={[{ required: true, message: 'Vui lòng chọn shipper!' }]}
                        >
                            <Select
                                className="select"
                                placeholder="Chọn shipper"
                                allowClear
                                showArrow
                                showSearch
                                options={listShippers}
                                filterOption={(input, option: any) => {
                                    const nonAccent = toNonAccentVietnamese(option?.label).toLowerCase()
                                    const nonAccentInput = toNonAccentVietnamese(input).toLowerCase()
                                    return nonAccent.includes(nonAccentInput)
                                }}
                            />
                        </Form.Item>
                    </CustomRow>
                    <Table
                        id="top-table"
                        bordered
                        columns={columns}
                        dataSource={isRefundOrder ? selectedRefOrderList : selectedOrderList}
                        scroll={{
                            x: 1200,
                            y: 'calc(100vh - 360px)',
                            scrollToFirstRowOnChange: true,
                        }}
                        locale={{
                            emptyText: 'Chưa có bản ghi nào!',
                        }}
                        pagination={false}
                        // pagination={{
                        //     onChange: (page: number) => {
                        //         setCurrentPage(page)
                        //     },
                        // }}
                    />
                    <Row justify="end" style={{ marginTop: 20, paddingTop: 10, borderTop: '1px solid #f0f0f0' }}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Lưu
                            </Button>
                        </Form.Item>
                    </Row>
                </CustomForm>
            </Spin>
        </Modal>
    )
}

const CustomForm = styled(Form)`
    .ant-form-item {
        margin-bottom: 0;
    }
`

const CustomRow = styled(Row)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .text {
        margin: 0;
        padding-right: 16px;
    }

    .select {
        width: 350px;
    }
`

export default AssignOrderForShipper
