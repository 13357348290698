import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'

type ButtonFixedProps = {
    onClickButton?: Function
    text?: string
    icon?: any
    loading?: boolean
    size?: any
    htmlType?: any
    styleButton?: any
    isDisable?: boolean
    isDanger?: boolean
    background?: string
    type?: 'text' | 'primary' | 'link' | 'ghost' | 'default' | 'dashed' | undefined
}

const ButtonAdd = ({
    text,
    onClickButton,
    icon,
    loading,
    size,
    htmlType,
    styleButton,
    isDisable,
    type = 'primary',
    background = '#52b4e9',
}: ButtonFixedProps) => {
    return (
        <Button
            danger
            disabled={isDisable && isDisable}
            type={type}
            style={
                !styleButton
                    ? {
                          borderRadius: '6px',
                          color: type ? 'white' : 'white',
                          background: type ? background : 'white',
                          border: 'none',
                      }
                    : {
                          borderRadius: '6px',
                          color: type ? 'white' : 'white',
                          background: type ? background : 'white',
                          border: 'none',
                          ...styleButton,
                      }
            }
            htmlType={htmlType ? htmlType : 'button'}
            onClick={() => {
                onClickButton && onClickButton()
            }}
            size={size ? size : 'middle'}
        >
            <b>
                {loading ? <LoadingOutlined /> : icon ? icon : <PlusCircleOutlined />}
                &nbsp;
                {text}
            </b>
        </Button>
    )
}

export default ButtonAdd
