import { Form, Input, Modal, Spin, message } from 'antd'
import ButtonForm from 'common/components/ModalButton'
import React from 'react'
import { addShipperManagerNote } from '../OrderAPI'

interface IAddNoteShipperManagerModal {
    visible: boolean
    setVisible: any
    orderDetail: any
    getData: any
}

const AddNoteShipperManagerModal = (props: IAddNoteShipperManagerModal) => {
    const { visible, setVisible, orderDetail, getData } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = React.useState<boolean>(false)

    const onSubmit = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                note: values?.note,
            }
            const res: any = await addShipperManagerNote(orderDetail?.id, payload)
            if (res?.status) {
                message.success('Thêm mới ghi chú thành công!')
                setVisible(false)
                getData()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal title={'Thêm ghi chú'} visible={visible} onCancel={() => setVisible(false)} footer={null} width={'60%'}>
            <Spin spinning={loading}>
                <Form
                    preserve={false}
                    onFinish={onSubmit}
                    form={form}
                    initialValues={{ note: orderDetail?.ship_manager_note }}
                >
                    <Form.Item name="note">
                        <Input.TextArea placeholder="Nhập ghi chú" rows={5} allowClear />
                    </Form.Item>

                    <ButtonForm onCancel={() => setVisible(false)} text={'Lưu'} />
                </Form>
            </Spin>
        </Modal>
    )
}

export default AddNoteShipperManagerModal
