import { Button, Card, Col, DatePicker, Form, Input, InputNumber, message, Row, Spin, TimePicker } from 'antd'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import UploadComponent from 'common/components/Upload'
import moment from 'moment'
import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { STATUSLIVE } from 'utils/constants'
import Cookie from 'js-cookie'
import { SESSION_ID } from 'common/config'

import { uniqueId } from 'lodash'
import {
    autheYoutube,
    getConfigLivestream,
    getConfiguration,
    requestCreateGoogleAccount,
    setConfiguration,
} from '../ConfigAPI'
import { IConfig } from '../ConfigIntefaces'
import HotlineByArea from './HotlineByArea'
const { RangePicker } = DatePicker

const FieldCol = {
    label: {
        span: 10,
    },
    input: {
        span: 24,
    },
}

const WapperCard = styled(Card)`
    margin-bottom: 20px;
    width: 100%;

    .form_item {
        margin-bottom: 16px;
    }
`

function SystemInfo() {
    const [form] = useForm()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [data, setData] = useState<IConfig | undefined>(undefined)
    const [dataConfigLivestream, setDataConfigLivestream] = useState<any>({})

    const handleSetConfiguration = async (values: any) => {
        const {
            flag_user_register_enabled,

            ...rest
        } = { ...data }

        const contactList = data?.contact_information?.map((item: any) => {
            return {
                hotline: values[`contactInfo_Hotline_${item?.kiotviet_id}`],
                zalo: values[`contactInfo_Zalo_${item?.kiotviet_id}`],
                kiotviet_id: item?.kiotviet_id,
            }
        })

        const submitData = {
            ...rest,
            ...values,
            contact_information: contactList,
            pre_order_from_time: values.pre_order_from_time.unix(),
            pre_order_end_time: values.pre_order_end_time.unix(),
            percent_retail_price: values.percent_retail_price,
            transfer_money_information: {
                bank_account_number: values.transfer_money_information_bank_account_number,
                bank_account_name: values.transfer_money_information_bank_account_name,
                bank_qr_code: values.transfer_money_information_bank_qr_code,
                bank_name: values.transfer_money_information_bank_name,
            },
            transfer_money_information_bank_account_name: undefined,
            transfer_money_information_bank_account_number: undefined,
            transfer_money_information_bank_name: undefined,
            transfer_money_information_bank_qr_code: undefined,
            kiotviet_retail_customer_name: undefined,
            kiotviet_retail_price_list_name: undefined,
        }
        const finalSubmitData = Object.entries(submitData).reduce((acc: any, [key, value]) => {
            if (!key.includes('contactInfo')) {
                acc[key] = value
            }
            return acc
        }, {})
        setIsLoading(true)
        try {
            await setConfiguration(finalSubmitData)
            message.success('Chỉnh sửa thông tin cấu hình thành công!')
            getData()
        } catch (err) {
        } finally {
            setIsLoading(false)
        }
    }

    const onAuthenYoutube = async () => {
        try {
            const payload = {
                token: Cookie.get(SESSION_ID),
            }
            const res = await autheYoutube(payload)
            if (res?.status) {
                window.open(res?.data?.url)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getDataConfig = async () => {
        try {
            const data_config = await getConfigLivestream()
            setDataConfigLivestream(data_config.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getData = async () => {
        setIsLoading(true)
        try {
            const res = await getConfiguration()
            if (res.data) {
                setData(res.data)
            }
        } catch (error) {
        } finally {
            setIsLoading(false)
        }
    }

    const validData = () => {
        if (!data) return data
        const formattedData = {
            ...data,
            pre_order_from_time: moment(data.pre_order_from_time * 1000),
            pre_order_end_time: moment(data.pre_order_end_time * 1000),
            transfer_money_information_bank_account_number: data.transfer_money_information.bank_account_number,
            transfer_money_information_bank_account_name: data.transfer_money_information.bank_account_name,
            transfer_money_information_bank_name: data.transfer_money_information.bank_name,
            transfer_money_information_bank_qr_code: data.transfer_money_information.bank_qr_code,
            percent_retail_price: data.percent_retail_price,
        }
        return formattedData
    }

    useEffect(() => {
        getData()
        getDataConfig()
        return () => {}
    }, [])
    return (
        <>
            <Spin spinning={isLoading}>
                {data && (
                    <Form form={form} onFinish={handleSetConfiguration} initialValues={validData()}>
                        <FormContainer>
                            <div className="left_column">
                                <Row gutter={[16, 16]}>
                                    <Col xxl={24} xl={24} md={24}>
                                        <WapperCard title={<b>Mua hàng</b>} bordered>
                                            <Form.Item
                                                className="form_item"
                                                name={'reward_point_percent_per_order'}
                                                label="% tích điểm"
                                                labelCol={FieldCol.label}
                                                wrapperCol={FieldCol.input}
                                                rules={[
                                                    {
                                                        message: 'Vui lòng nhập % tích điểm!',
                                                        validator: (_, value) => {
                                                            if (value?.length === 0 || !value) {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                    {
                                                        message: 'Vui lòng nhập % tích điểm dưới 100%',
                                                        validator: (_, value) => {
                                                            if (value > 100) {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputStyled
                                                    min={0}
                                                    type="number"
                                                    parser={(value: any) =>
                                                        value ? value.replace(/\$\s?|(,*)/g, '') : ''
                                                    }
                                                    addonAfter={'%'}
                                                    controls={false}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="form_item"
                                                name={'reward_point_cap_per_order'}
                                                label="Tối đa/ đơn"
                                                labelCol={FieldCol.label}
                                                wrapperCol={FieldCol.input}
                                                rules={[
                                                    {
                                                        message: 'Vui lòng nhập giá trị tối đa/đơn!',
                                                        validator: (_, value) => {
                                                            if (value?.length === 0 || !value) {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                    {
                                                        message: 'Vui lòng nhập giá trị tối đa/đơn dưới 100000000',
                                                        validator: (_, value) => {
                                                            if (value > 100000000) {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputStyled
                                                    min={0}
                                                    parser={(value: any) =>
                                                        value ? value.replace(/\$\s?|(,*)/g, '') : ''
                                                    }
                                                    controls={false}
                                                    style={{ width: '180px' }}
                                                    formatter={value =>
                                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                    }
                                                    onKeyPress={event => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="form_item"
                                                name={'reward_point_per_review'}
                                                label="Đánh giá đơn hàng"
                                                labelCol={FieldCol.label}
                                                wrapperCol={FieldCol.input}
                                                rules={[
                                                    {
                                                        validator: (_, value) => {
                                                            if (value?.length === 0 || !value) {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                        message: 'Vui lòng nhập đánh giá đơn hàng',
                                                    },
                                                    {
                                                        message: 'Vui lòng nhập đánh giá đơn hàng dưới 1000000',
                                                        validator: (_, value) => {
                                                            if (value > 1000000) {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputStyled
                                                    min={0}
                                                    formatter={value =>
                                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                    }
                                                    parser={(value: any) =>
                                                        value ? value.replace(/\$\s?|(,*)/g, '') : ''
                                                    }
                                                    controls={false}
                                                    onKeyPress={event => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="form_item"
                                                name={'percent_retail_price'}
                                                label="(%) Giá bán lẻ"
                                                labelCol={FieldCol.label}
                                                wrapperCol={FieldCol.input}
                                                rules={[
                                                    {
                                                        validator: (_, value) => {
                                                            if (value?.length === 0 || !value) {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                        message: 'Vui lòng nhập % giá bán lẻ',
                                                    },
                                                ]}
                                            >
                                                <InputStyled
                                                    min={0}
                                                    formatter={value =>
                                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                    }
                                                    parser={(value: any) =>
                                                        value ? value.replace(/\$\s?|(,*)/g, '') : ''
                                                    }
                                                    controls={false}
                                                    onKeyPress={event => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </WapperCard>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xxl={24} xl={24} md={24}>
                                        <WapperCard title={<b>Thời gian mua hàng âm</b>} bordered>
                                            <Form.Item
                                                className="form_item"
                                                name={'pre_order_from_time'}
                                                label="Bắt đầu lúc:"
                                                labelCol={FieldCol.label}
                                                wrapperCol={FieldCol.input}
                                            >
                                                <TimePicker
                                                    defaultValue={moment(data.pre_order_from_time)}
                                                    name={'start_time'}
                                                    placeholder={'Chọn thời gian'}
                                                    format={'HH:mm'}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                className="form_item"
                                                name={'pre_order_end_time'}
                                                label="Kết thúc lúc:"
                                                labelCol={FieldCol.label}
                                                wrapperCol={FieldCol.input}
                                            >
                                                <TimePicker
                                                    defaultValue={moment(data.pre_order_end_time)}
                                                    name={'end_time'}
                                                    placeholder={'Chọn thời gian'}
                                                    format={'HH:mm'}
                                                />
                                            </Form.Item>
                                        </WapperCard>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xxl={24} xl={24} md={24}>
                                        <WapperCard title={<b>Tin nhắn</b>} bordered>
                                            <Form.Item
                                                className="form_item"
                                                name={'sample_message'}
                                                label="Tin nhắn mẫu"
                                                labelCol={FieldCol.label}
                                                // wrapperCol={FieldCol.input}
                                                rules={[
                                                    {
                                                        type: 'string',
                                                        message: 'Nhập tin nhắn mẫu',
                                                    },
                                                    {
                                                        message: 'Vui lòng nhập tin nhắn mẫu!',
                                                        validator: (_, value) => {
                                                            if (value.trim() === '') {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                    {
                                                        min: 3,
                                                        max: 500,
                                                        message: 'Vui lòng nhập từ 3 đến 500 ký tự!',
                                                    },
                                                ]}
                                            >
                                                <TextAreaStyled placeholder="Vd: Vui lòng liên hệ 098*** để được hỗ trợ nhanh nhất" />
                                            </Form.Item>
                                        </WapperCard>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xxl={24} xl={24} md={24}>
                                        <WapperCard title={<b>Hướng dẫn</b>} bordered>
                                            <Form.Item
                                                className="form_item"
                                                name={'coin_up_guidance'}
                                                label="Hướng dẫn nạp xu"
                                                labelCol={FieldCol.label}
                                                rules={[
                                                    {
                                                        type: 'string',
                                                        message: 'Nhập hướng dẫn nạp xu',
                                                    },
                                                    {
                                                        message: 'Vui lòng nhập hướng dẫn nạp xu!',
                                                        validator: (_, value) => {
                                                            if (value.trim() === '') {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                    {
                                                        min: 3,
                                                        max: 500,
                                                        message: 'Vui lòng nhập từ 3 đến 500 ký tự!',
                                                    },
                                                ]}
                                            >
                                                <TextAreaStyled placeholder="" />
                                            </Form.Item>
                                        </WapperCard>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xxl={24} xl={24} md={24}>
                                        <WapperCard title={<b>Tài khoản ngân hàng</b>} bordered>
                                            <Form.Item
                                                className="form_item"
                                                name={'transfer_money_information_bank_account_name'}
                                                label="Tên tài khoản"
                                                labelCol={FieldCol.label}
                                                rules={[
                                                    {
                                                        type: 'string',
                                                        message: 'Nhập tên tài khoản',
                                                    },
                                                    {
                                                        message: 'Vui lòng nhập tên tài khoản!',
                                                        validator: (_, value) => {
                                                            if (value.trim() === '') {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                    {
                                                        min: 3,
                                                        max: 500,
                                                        message: 'Vui lòng nhập từ 3 đến 50 ký tự!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="" />
                                            </Form.Item>
                                            <Form.Item
                                                className="form_item"
                                                name={'transfer_money_information_bank_account_number'}
                                                label="Số tài khoản"
                                                labelCol={FieldCol.label}
                                                rules={[
                                                    {
                                                        type: 'string',
                                                        message: 'Nhập số tài khoản',
                                                    },
                                                    {
                                                        message: 'Vui lòng nhập số tài khoản!',
                                                        validator: (_, value) => {
                                                            if (value.trim() === '') {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                    {
                                                        min: 3,
                                                        max: 20,
                                                        message: 'Vui lòng nhập từ 3 đến 20 ký tự!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="" />
                                            </Form.Item>
                                            <Form.Item
                                                className="form_item"
                                                name={'transfer_money_information_bank_name'}
                                                label="Tên ngân hàng"
                                                labelCol={FieldCol.label}
                                                rules={[
                                                    {
                                                        type: 'string',
                                                        message: 'Nhập tên ngân hàng',
                                                    },
                                                    {
                                                        message: 'Vui lòng nhập tên ngân hàng!',
                                                        validator: (_, value) => {
                                                            if (value.trim() === '') {
                                                                return Promise.reject()
                                                            }
                                                            return Promise.resolve()
                                                        },
                                                    },
                                                    {
                                                        min: 3,
                                                        max: 500,
                                                        message: 'Vui lòng nhập từ 3 đến 50 ký tự!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="" />
                                            </Form.Item>
                                            <Form.Item
                                                className="form_item"
                                                name="transfer_money_information_bank_qr_code"
                                                label="QR CODE"
                                            >
                                                <UploadComponent
                                                    isUploadServerWhenUploading
                                                    uploadType="list"
                                                    listType="picture-card"
                                                    maxLength={1}
                                                    onSuccessUpload={(file: any) => {
                                                        // setFile(file?.url)
                                                        form.setFieldsValue({
                                                            transfer_money_information_bank_qr_code: file?.url,
                                                        })
                                                    }}
                                                    isShowFileList
                                                    initialFile={
                                                        data.transfer_money_information.bank_qr_code && [
                                                            {
                                                                url: data.transfer_money_information?.bank_qr_code,
                                                                uid: uniqueId(),
                                                                name: 'QR code',
                                                            },
                                                        ]
                                                    }
                                                />
                                            </Form.Item>
                                        </WapperCard>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xxl={24} xl={24} md={24}>
                                        <WapperCard
                                            title={<b>Cấu hình tài khoản live</b>}
                                            extra={
                                                <Button
                                                    type="primary"
                                                    onClick={async () => {
                                                        const res = await requestCreateGoogleAccount()
                                                        window.open(res.data.url)
                                                        window.focus()
                                                    }}
                                                >
                                                    Đăng nhập
                                                </Button>
                                            }
                                            bordered
                                        >
                                            <Row style={{ width: '100%' }}>
                                                <Col span={8}>
                                                    <label style={{ fontWeight: 'bold' }}>Email: </label>
                                                    {dataConfigLivestream?.email ? dataConfigLivestream?.email : ' ---'}
                                                </Col>

                                                <Col span={8}>
                                                    <label style={{ fontWeight: 'bold' }}>Trạng thái: </label>{' '}
                                                    {dataConfigLivestream.status
                                                        ? dataConfigLivestream?.status === STATUSLIVE.SUCCESS
                                                            ? 'Đã kết nối'
                                                            : 'Ngưng kết nối'
                                                        : ' ---'}
                                                </Col>
                                                <Col span={8}>
                                                    <label style={{ fontWeight: 'bold' }}>Ngày tạo: </label>
                                                    {dataConfigLivestream?.created_at
                                                        ? moment(dataConfigLivestream?.created_at).format('DD/MM/YYYY')
                                                        : ' ---'}
                                                </Col>
                                            </Row>
                                        </WapperCard>
                                    </Col>
                                </Row>
                            </div>
                            <div className="right_column">
                                <HotlineByArea form={form} data={data} />
                            </div>
                        </FormContainer>
                        {/* <Card title={<b>Xác thực tài khoản livestream Youtube</b>} bordered>
                            <Button htmlType="submit" type="primary" onClick={onAuthenYoutube}>
                                Kích hoạt
                            </Button>
                        </Card> */}
                        <Button htmlType="submit" type="primary" style={{ float: 'right', marginTop: 20 }}>
                            Lưu
                        </Button>
                    </Form>
                )}
            </Spin>
        </>
    )
}

const TextAreaStyled = styled(TextArea)`
    height: 100px !important;
`

const InputStyled = styled(InputNumber)`
    width: 180px;
`

const CustomRow = styled(Row)`
    margin-bottom: 16px;
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: row;

    .left_column {
        flex: 1;
        margin-right: 20px;
    }

    .right_column {
        flex: 1;
    }
`

export default memo(SystemInfo)
