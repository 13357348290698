import { Affix, PageHeader as Header } from 'antd'
import styled from 'styled-components'
import style from 'config/style'
import { IPropPageHeader } from './interface'

const PageHeader = ({
  extra,
  onBack,
  subTitle,
  ghost,
  tags,
  title,
  children,
  fixed,
}: IPropPageHeader) => {
  const Children = children
  return (
    <PageHeaderStyled
      ghost={ghost}
      onBack={onBack}
      title={title}
      subTitle={subTitle}
      tags={tags}
      extra={extra}
    >
      {children ? <Children /> : null}
    </PageHeaderStyled>
  )
}

export default PageHeader

const PageHeaderStyled = styled(Header)`
  margin: 5px 10px 10px 10px;
`
