import { PlusOutlined } from '@ant-design/icons'
import { Modal, Upload } from 'antd'
import type { RcFile, UploadProps } from 'antd/es/upload'
import type { UploadFile } from 'antd/es/upload/interface'
import React, { useState } from 'react'
import { ApiClient } from 'services/ApiService'

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

const UploadList = ({
  accept = 'image/*',
  isUploadServerWhenUploading = false,
  onSuccessUpload,
  initialFile,
  onRemove,
}: {
  accept?: string
  isUploadServerWhenUploading?: boolean
  onSuccessUpload?: any
  initialFile?: any
  onRemove?: any
}) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const firstLoad = React.useRef(false)

  const [progress, setProgress] = React.useState(0)

  const handleCancel = () => setPreviewOpen(false)

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    )
  }

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options

    if (isUploadServerWhenUploading) {
      const fmData = new FormData()
      const config = {
        headers: {
          Accept: 'multipart/form-data',
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (event: any) => {
          const percent = Math.floor((event.loaded / event.total) * 100)
          setProgress(percent)
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000)
          }
          onProgress({ percent: (event.loaded / event.total) * 100 })
        },
      }
      //   fmData.append('file', file)
      try {
        // const res: any = await ApiClient.postUploadFile(
        //   '/files/upload/single/1',
        //   fmData,
        //   config
        // )

        onSuccessUpload(fileList)
        onSuccess('ok')
      } catch (err) {
        file.status = 'error'
        const error = new Error('Some error')
        setFileList(f => [...f.filter(_f => _f.status !== 'uploading'), file])
        onError({ error })
      }
    } else {
      setTimeout(() => onSuccess('ok'), 1000)
    }
  }

  const handleChange: UploadProps['onChange'] = ({
    file,
    fileList: newFileList,
  }) => {
    setFileList(newFileList)

    if (file.status === 'removed') {
      onSuccessUpload(newFileList)
    }
  }

  React.useEffect(() => {
    if (firstLoad?.current) return
    if (initialFile) {
      setFileList(initialFile)
      firstLoad.current = true
    }
  }, [initialFile])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Tải lên</div>
    </div>
  )

  return (
    <>
      <Upload
        accept={accept}
        customRequest={uploadImage}
        onChange={handleChange}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={(file: any) => onRemove && onRemove(file)}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

export default UploadList
