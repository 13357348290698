import { DownloadOutlined } from '@ant-design/icons'
import { Col, DatePicker, message, Row, Select } from 'antd'
import TypingAutoSearch from 'common/components/TypingAutoSearch'
import { Typography } from 'antd'
import { memo, useEffect, useState } from 'react'
import moment from 'moment'
import { REGION, ORDER_STATUS, TRANSPORT_STATUS, ORDER_EXPORT, PAYMENT_STATUS, CREATALE_TYPE } from 'utils/constants'
import ExportCsv from 'utils/ExportCSV'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { IDataOrders, IOrderPayload } from '../Interfaces'
import { exportExcelOrder, getOrder } from '../OrderAPI'
const { Option } = Select
const { RangePicker } = DatePicker

type HeaderProps = {
    onSearchSubmit: (searchKey: string) => void // keyword nhập vào
    onStatusSubmit: (orderStatus: string) => void // Trạng thái đơn hàng
    onDateSubmit: (createFrom: string, createTo: string) => void // Chọn khoảng ngày
    onTypeSubmit: (creatable_type: string) => void // Chọn gian hàng
    isSearchLoading: boolean
    params: IOrderPayload // value từ các input
    handlePackageStatusSubmit: any
    handleDeliveryStatusSubmit: any
}
const { Text } = Typography
const { PENDING, SUCCCESS, CANCELED, INPROGRESS } = ORDER_STATUS

function OrderHeader({
    onSearchSubmit,
    onStatusSubmit,
    onDateSubmit,
    onTypeSubmit,
    isSearchLoading,
    params,
    handlePackageStatusSubmit,
    handleDeliveryStatusSubmit,
}: HeaderProps) {
    const [dataExport, setDataExport] = useState<any>([])
    const [isBtnLoading, setisBtnLoading] = useState<boolean>(false)

    const orderStatusDesc = (status?: string) => {
        switch (status) {
            case PENDING:
                return 'Chờ xác nhận'
            case SUCCCESS:
                return 'Hoàn thành'
            case CANCELED:
                return 'Hủy'
            default:
                return ''
        }
    }

    const renderTransportStatus = (status: number) => {
        switch (status) {
            case TRANSPORT_STATUS.PENDDING:
                return <Text> Chờ xử lý</Text>
            case TRANSPORT_STATUS.TRANSPORTING:
                return <Text> Đang giao hàng</Text>
            case TRANSPORT_STATUS.TRANSPORT_SUCCESS:
                return <Text> Giao thành công</Text>
            case TRANSPORT_STATUS.IN_TRANSFER:
                return <Text> Đang chuyển hoàn</Text>
            case TRANSPORT_STATUS.COMPLETE_TRANSFER:
                return <Text> Đã chuyển hoàn</Text>
            case TRANSPORT_STATUS.CANCELED_TRANSPORT:
                return <Text> Đã hủy</Text>
            case TRANSPORT_STATUS.PICKING:
                return <Text> Đang lấy hàng</Text>
            case TRANSPORT_STATUS.WAIT_GET:
                return <Text> Chờ lấy lại</Text>
            case TRANSPORT_STATUS.TOOK:
                return <Text> Đã lấy hàng</Text>
            case TRANSPORT_STATUS.WAIT_HANDOVER:
                return <Text> Chờ giao lại</Text>
            case TRANSPORT_STATUS.WAIT_RETURN:
                return <Text> Chờ chuyển hoàn</Text>
            case TRANSPORT_STATUS.WAIT_REFUND:
                return <Text> Chờ chuyển hoàn lại</Text>
        }
    }

    const onExportDataToExcel = async (fn: any) => {
        try {
            const payload = {
                ...params,
                page: undefined,
                limit: undefined,
            }
            const res = await exportExcelOrder(payload)
            if (res.data) {
                message.success('Xuất excel thành công!')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const disabledDate: any['disabledDate'] = (current: any) => {
        return current && current > moment().endOf('day')
    }

    return (
        <Row gutter={[16, 16]} justify="space-between" style={{ flex: 1 }}>
            <Col span={4}>
                <TypingAutoSearch
                    onSearchSubmit={(searchKey: string) => {
                        onSearchSubmit(searchKey.trim())
                    }}
                    isSearchLoading={isSearchLoading}
                    placeholder="Nhập mã đơn hàng, tên khách hàng, SĐT ..."
                />
            </Col>

            <Col span={4}>
                <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="TT đơn hàng"
                    onChange={(orderStatus: string) => {
                        onStatusSubmit(orderStatus)
                    }}
                >
                    <Option value={PENDING}>Chờ xác nhận</Option>
                    <Option value={INPROGRESS}>Đã xác nhận</Option>
                    <Option value={SUCCCESS}>Hoàn thành</Option>
                    <Option value={CANCELED}>Huỷ</Option>
                </Select>
            </Col>
            <Col span={45}>
                <Select
                    allowClear
                    style={{ width: 200 }}
                    placeholder="TT kho"
                    onChange={(orderStatus: string) => {
                        handlePackageStatusSubmit(orderStatus)
                    }}
                >
                    <Option value={3}>Chưa có đơn</Option>
                    <Option value={0}>Chờ nhận đơn</Option>
                    <Option value={1}>Đang đóng hàng</Option>
                    <Option value={2}>Hoàn thành</Option>
                </Select>
            </Col>
            <Col span={5}>
                <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="TT giao hàng"
                    onChange={(orderStatus: string) => {
                        handleDeliveryStatusSubmit(orderStatus)
                    }}
                >
                    <Option value={0}>Chờ nhận đơn</Option>
                    <Option value={1}>Đang giao đơn</Option>
                    <Option value={2}>Đã giao</Option>
                </Select>
            </Col>

            <Col span={5}>
                <Select
                    allowClear
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    loading={false}
                    placeholder="Nguồn đơn"
                    onChange={(creatableType: string) => {
                        onTypeSubmit(creatableType)
                    }}
                >
                    <Option value={CREATALE_TYPE.ORDER_BY_CUSTOMER}>Đơn từ App HTT</Option>
                    <Option value={CREATALE_TYPE.ORDER_BY_STAFF}>Đơn từ Kiotviet</Option>
                </Select>
            </Col>

            <Col span={5}>
                <RangePicker
                    disabledDate={disabledDate}
                    style={{ width: '100%' }}
                    placeholder={['Từ ngày', 'Đến ngày']}
                    onChange={(value, dateString) => {
                        onDateSubmit(dateString[0], dateString[1])
                    }}
                />
            </Col>

            {/* <Col span={4}>
                <ExportCsv
                    onClick={fn => {
                        onExportDataToExcel(fn)
                    }}
                    sheetName={['OrderList']}
                    sheets={{
                        OrderList: ExportCsv.getSheets(dataExport),
                    }}
                    fileName="Danh sách đơn hàng"
                >
                    <DownloadOutlined />
                    &nbsp;Export
                </ExportCsv>
            </Col> */}
        </Row>
    )
}

export default memo(OrderHeader)
