import React, { useEffect, useRef, useState } from 'react'
import {
  checkHasAccountAPI,
  commentAPI,
  joinLiveAPI,
  loginAPI,
  reactionAPI,
  registerAPI,
} from './ToolSpamCommentAPI'
import io, { Socket } from 'socket.io-client'
import { notification } from 'antd'
import { environment } from 'config'

const DEFINE_STATUS = {
  DISCONNECT: 0,
  CONNECTED: 1,
}

const DEFINE_STATUS_HAS_ACCOUNT = {
  HAS_ACCOUNT: 1,
  NOT_HAS_ACCOUNT: 0,
}

const TollSpamCommentChild: React.FC<{
  index: number
  isReaction: boolean
}> = ({ index, isReaction }) => {
  const params = new URLSearchParams(window.location.search)

  const timeIntervalComment: number = Number(
    params.get('time-interval-comment') as string
  )
  const timeIntervalReaction: number = Number(
    params.get('time-interval-reaction') as string
  )
  const phoneNumber: string = String(
    Number(params.get('phone-number') as string) + index
  )
  const liveStreamId: number = Number(params.get('livestream-id') as string)
  const password = '123456'
  const socketRef = useRef<Socket>()
  const linkSocket: string =
    params.get('link-socket') || (environment.ws_host_test_spam as string)

  const [userName, setUserName] = useState<string>()
  const [status, setStatus] = useState<number>()

  const onSpam = async () => {
    // get token
    const loginOrRegister = async (): Promise<{ token: string }> => {
      const checkHasAccount = async (): Promise<number | null> => {
        const resCheckAccount: any = await checkHasAccountAPI(phoneNumber)
        if (resCheckAccount) {
          if (resCheckAccount.body.data && resCheckAccount.body.data.phone) {
            return new Promise(resolve =>
              resolve(DEFINE_STATUS_HAS_ACCOUNT.NOT_HAS_ACCOUNT)
            )
          } else
            return new Promise(resolve =>
              resolve(DEFINE_STATUS_HAS_ACCOUNT.HAS_ACCOUNT)
            )
        } else return new Promise(resolve => resolve(null))
      }

      const resultCheckHasAccount: any = await checkHasAccount()

      if (resultCheckHasAccount === DEFINE_STATUS_HAS_ACCOUNT.HAS_ACCOUNT) {
        const onLogin = async (): Promise<{ token: string }> => {
          const resLogin = await loginAPI(phoneNumber, password)
          if (resLogin.body.status === 1) {
            notification.open({
              type: 'success',
              message: 'Login success',
            })
            setUserName(resLogin.body.data.userName)
            return new Promise(resolve =>
              resolve({ token: resLogin.body.data.token })
            )
          } else return new Promise(resolve => resolve({ token: '' }))
        }
        return onLogin()
      } else {
        const onRegister = async (): Promise<{ token: string }> => {
          const formData = new FormData()
          formData.append('name', phoneNumber)
          formData.append('phone', phoneNumber)
          formData.append('email', phoneNumber + '@gmail.com')
          formData.append('password', password)

          const resRegister = await registerAPI(formData)

          if (resRegister.body.status === 1) {
            setUserName(resRegister.body.data.phone)
            notification.open({
              type: 'success',
              message: 'Register success',
            })
            return new Promise(resolve =>
              resolve({ token: resRegister.body.data.token })
            )
          } else {
            return new Promise(resolve => resolve({ token: '' }))
          }
        }
        return onRegister()
      }
    }

    const resLoginOrRegister = await loginOrRegister()

    // join live and spam
    if (resLoginOrRegister) {
      if (resLoginOrRegister.token) {
        const { token } = resLoginOrRegister

        const resJoinLive = await joinLiveAPI(liveStreamId, token)

        if (resJoinLive.body.status === 1) {
          notification.open({
            type: 'success',
            message: 'Join live success.',
          })

          setStatus(DEFINE_STATUS.CONNECTED)

          try {
            socketRef.current = io(linkSocket, {
              auth: { token },
              secure: true,
              reconnection: true,
              reconnectionDelay: 500,
              transports: ['websocket'],
            })

            // socketRef.current?.emit(
            //   'subscribe_livestream_channel',
            //   liveStreamId
            // )
            socketRef.current?.on('connect', function () {
              console.log('connect')
              socketRef.current?.emit(
                'subscribe_livestream_channel',
                liveStreamId
              )
            })
          } catch (e) {
            console.error(e)
          }

          setInterval(async () => {
            const resCommentApi = await commentAPI(
              liveStreamId,
              phoneNumber + index,
              token
            )
            if (resCommentApi) {
            }
          }, timeIntervalComment)

          if (isReaction) {
            const randomIntFromInterval = (min: number, max: number) => {
              return Math.floor(Math.random() * (max - min + 1) + min)
            }
            setInterval(async () => {
              const resReactionApi = await reactionAPI(
                liveStreamId,
                randomIntFromInterval(1, 3),
                token
              )
              if (resReactionApi) {
              }
            }, timeIntervalReaction)
          }
        }
      }
    }
  }

  useEffect(() => {
    onSpam()
  }, [])

  return (
    <div style={{ fontWeight: 'bold' }}>
      {index}_{userName}{' '}
      {status ? (
        <span style={{ color: 'green' }}>Connected</span>
      ) : (
        <span style={{ color: 'gray' }}>Disconnet</span>
      )}
    </div>
  )
}

const ToolSpamComment: React.FC = () => {
  const params = new URLSearchParams(window.location.search)
  const countUser: number = Number(params.get('count-user') as string)
  const countUserReaction: number = Number(
    params.get('count-user-reaction') as string
  )

  const convertNumberToArr = (): any[] => {
    let rs: any[] = []
    for (let i = 0; i < countUser; i++) {
      rs.push(i)
    }
    return rs
  }

  return (
    <div>
      {convertNumberToArr().map((value, index) => (
        <TollSpamCommentChild
          index={index}
          key={index}
          isReaction={
            countUserReaction && index < countUserReaction ? true : false
          }
        />
      ))}
    </div>
  )
}

export default ToolSpamComment
