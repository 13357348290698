import { Button, message, PageHeader, Popconfirm, Spin, Tabs, Tag } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ShopInfo from './components/ShopInfo'
import ProductShop from './components/ProductShop'
import OrderShop from './components/OrderShop'
import LiveStreamShop from './components/LiveStreamShop'
import { deleteShop, changeStatusShop, getShopDetail } from './ShopApi'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons'

const { TabPane } = Tabs

type Props = { location: any; setKey: any; key: string }

export default function ShopDetail({ location, setKey, key }: Props) {
  const history = useHistory()
  const params: any = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [statusShop, setStatusShop] = useState(0)
  const [defaultkey, setDefaultKey] = useState('1')
  // console.log('defaultkey', defaultkey)
  let keyDefault = '1'
  const keys = sessionStorage.getItem('key')
  if (keys != undefined) {
    keyDefault = keys
    // console.log('key', keys)
  }

  const [shopInfor, setShopInfor] = useState({
    create_at: '',
    delete_at: null,
    id: 0,
    is_active: 1,
    livestream_enable: 1,
    name: '',
    phone: '',
    profile_picture_url: '',
    status: 1,
    stream_minutes_available: 0,
    update_at: '',
    email: '',
  })

  const getData = async () => {
    setIsLoading(true)
    try {
      const res = await getShopDetail(params.id)
      setShopInfor(res.data)
    } catch (error) {
      console.log(error)
      // message.error(`${error.message}`)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const getStatus = (status: number) => {
    setStatusShop(status)
  }

  const onDeleteShop = async (id: any) => {
    setIsLoading(true)
    try {
      const payload = { id }
      await deleteShop(payload)
      message.success('Xóa gian hàng thành công!')
      history.push({
        pathname: `${ADMIN_ROUTER_PATH.SHOP}`,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeShopStatus = async () => {
    setIsLoading(true)
    try {
      let data = await changeStatusShop(params.id)
      setStatusShop(data?.data?.status)
      getData()
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const setMinusAvailable = (minus: number) => {
    setShopInfor({ ...shopInfor, stream_minutes_available: minus })
  }

  return (
    <Spin size="large" spinning={isLoading}>
      <div style={{ backgroundColor: 'white', margin: '5px 10px 15px' }}>
        <PageHeader
          title={shopInfor.name}
          // tags={
          //   shopInfor.status === 1 ? (
          //     <Tag color="green">HOẠT ĐỘNG</Tag>
          //   ) : (
          //     <Tag color="red">Ngừng hoạt động</Tag>
          //   )
          // }
          onBack={() => {
            history.push(ADMIN_ROUTER_PATH.SHOP)
          }}
          extra={[
            <Popconfirm
              placement="bottomRight"
              title="Bạn có chắc chắn muốn xoá?"
              onConfirm={() => {
                onDeleteShop(params.id)
              }}
              okText="Xoá"
              cancelText="Huỷ"
              okButtonProps={{
                danger: true,
                type: 'primary',
              }}
            >
              <Button danger>
                <CloseCircleOutlined />
                Xoá
              </Button>
            </Popconfirm>,
            shopInfor.status ? (
              <Popconfirm
                placement="bottomRight"
                title="Bạn có chắc chắn muốn ngừng hoạt động?"
                onConfirm={() => {
                  onChangeShopStatus()
                }}
                okText="Ngừng hoạt động"
                cancelText="Huỷ"
                okButtonProps={{
                  danger: true,
                  type: 'primary',
                }}
              >
                <Button
                  type="primary"
                  danger
                  //  onClick={onChangeShopStatus}
                >
                  <PauseCircleOutlined />
                  Ngừng hoạt động
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                placement="bottomRight"
                title="Bạn có chắc chắn muốn bật hoạt động?"
                onConfirm={() => {
                  onChangeShopStatus()
                }}
                okText="Bật hoạt động"
                cancelText="Huỷ"
                okButtonProps={{
                  type: 'primary',
                  style: {
                    borderColor: '#00abba',
                    backgroundColor: '#00abba',
                    color: 'white',
                  },
                }}
              >
                <Button
                  style={{
                    borderColor: '#00abba',
                    backgroundColor: '#00abba',
                    color: 'white',
                  }}
                  //  onClick={onChangeShopStatus}
                >
                  <CheckCircleOutlined />
                  Hoạt động
                </Button>
              </Popconfirm>
            ),
          ]}
        />
      </div>
      <div style={{ backgroundColor: 'white', margin: '5px 10px 15px' }}>
        <Tabs style={{ padding: '0 10px' }} defaultActiveKey={keyDefault}>
          <TabPane
            key="1"
            tab="Thông tin gian hàng"
            style={{ backgroundColor: '#fff' }}
          >
            <ShopInfo
              id_shop={params.id}
              onChangeStatus={getStatus}
              shopInfor={shopInfor}
              setMinusAvailable={setMinusAvailable}
            />
          </TabPane>
          <TabPane tab="Sản phẩm" key="2" style={{ backgroundColor: '#fff' }}>
            <ProductShop id_shop={params.id} />
          </TabPane>
          <TabPane tab="Đơn hàng" key="3" style={{ backgroundColor: '#fff' }}>
            <OrderShop id_shop={params.id} />
          </TabPane>
          <TabPane
            tab="Live Stream"
            key="4"
            style={{ backgroundColor: '#fff' }}
          >
            <LiveStreamShop id_shop={params.id} />
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  )
}
