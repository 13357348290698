import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IinitialState {
  getData: boolean
}

const initialState: IinitialState = {
  getData: true,
}

export const getDataReducer = createSlice({
  name: 'getData',
  initialState,
  reducers: {
    getData: state => {
      state.getData = !state.getData
    },
  },
})

export const { getData } = getDataReducer.actions
export default getDataReducer.reducer
