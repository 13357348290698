import { Modal, Table, Tag, Tooltip } from 'antd'
import React from 'react'
import { IS_ACTIVE } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'

interface IFaillProductModal {
    visible: boolean
    setVisible: any
    setFailPaging: any
    failPaging: any
    listFailProduct: any[]
    onRetry: () => Promise<void>
}

const FaillProductModal = (props: IFaillProductModal) => {
    const { visible, setVisible, failPaging, setFailPaging, listFailProduct, onRetry } = props

    const columns = [
        Table.SELECTION_COLUMN,
        {
            width: 100,
            title: 'STT',
            dataIndex: 'stt',
            render: (text: any, record: any, index: any) => (
                <p>{(failPaging.current - 1) * failPaging.pageSize + index + 1}</p>
            ),
        },
        {
            width: 150,
            title: 'Mã sản phẩm',
            dataIndex: 'code',
            render: (_: any, record: any) => record?.product?.code,
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            render: (name: any, record: any) => (
                <Tooltip placement="topLeft" title={name}>
                    {record?.product?.name}
                </Tooltip>
            ),
        },
        // {
        //     title: 'Danh mục',
        //     dataIndex: 'Category',
        //     render: (tmp: any, record: any) => (
        //         <Tooltip placement="topLeft" title={record?.category?.name}>
        //             {record.category?.name}
        //         </Tooltip>
        //     ),
        // },
        {
            title: 'Giá bán (VNĐ)',
            dataIndex: 'price',
            render: (price: any, record: any) => {
                return <p>{formatPrice(record?.product?.price)}</p>
            },
        },
        // {
        //     title: 'Giá bán lẻ (VNĐ)',
        //     dataIndex: 'retail_price',
        //     render: (price: any) => {
        //         return <p>{formatPrice(price) || '---'}</p>
        //     },
        // },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            render: (status: any, record: any) =>
                record?.product?.status === IS_ACTIVE.ACTIVE ? (
                    <Tag color={'success'}>Đang hoạt động</Tag>
                ) : (
                    <Tag color={'volcano'}>Ngừng hoạt động</Tag>
                ),
        },
        {
            title: 'Chi tiết lỗi',
            dataIndex: 'error_message',
            render: (totalStock: any, record: any) => record?.error_message || '---',
        },
    ]

    return (
        <Modal
            title="Danh sách cập nhật giá thất bại"
            visible={visible}
            onOk={onRetry}
            onCancel={() => setVisible(false)}
            okText="Cập nhật lại"
            width={1100}
        >
            <Table
                dataSource={listFailProduct}
                bordered
                scroll={
                    listFailProduct?.length > 1
                        ? {
                              x: 800,
                              scrollToFirstRowOnChange: true,
                          }
                        : {}
                }
                columns={columns}
                // rowSelection={rowSelection}
                // onRow={(record, rowIndex) => ({
                //     onClick: () => {
                //         history.push({
                //             pathname: `${ADMIN_ROUTER_PATH.PRODUCT_DETAIL}/${record.id}`,
                //             state: {
                //                 ...record,
                //                 paging: paging,
                //                 path: ADMIN_ROUTER_PATH.PRODUCT,
                //                 params: params,
                //             },
                //         })
                //     },
                // })}
                pagination={{
                    ...failPaging,
                    showSizeChanger: false,
                    onChange: async page => {
                        setFailPaging({ ...failPaging, current: page })
                    },
                }}
            />
        </Modal>
    )
}

export default FaillProductModal
