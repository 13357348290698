import { Button, Col, message, notification, PageHeader, Row } from 'antd'
import { memo, useState } from 'react'
import Fillter from './Fillter'
import styled from 'styled-components'
import { IDataCustomers } from '../CustomerInterfaces'
import ExportCsv from 'utils/ExportCSV'
import { DownloadOutlined, SwapOutlined } from '@ant-design/icons'
import { notificationError, notificationSuccess } from 'utils/notification'
import { exportExcel, getCustomers, syncAllCustomer } from '../CustomerAPI'
import { CUSTOMER_EXPORT } from 'utils/constants'
import { convertTimeStampToString } from 'utils/TimerHelper'

interface IPropsSytle {
    padding?: any
}
const WapperComponent = styled.div<IPropsSytle>`
    background-color: white;
    border-radius: 5px;
    margin: 7px;
    padding: ${(props: any) => (props.padding ? '15px' : null)};
`

interface IProps {
    dataCustomer: IDataCustomers[]
    onSubmitKeyword: (keyword: string) => void
    onSubmidCity: (city: string) => void
    onSubmitTime: (time: Array<string>) => void
    defaultSearch?: string
    kiotvietID: string | undefined
    visible: boolean
    region: any
    params: any
    title: string
}

function Header(props: IProps) {
    const [dataExport, setdataExport] = useState<any>([])
    const [isSyncLoading, setIsSyncLoading] = useState(false)
    const syncCustomer = async () => {
        try {
            setIsSyncLoading(true)
            await syncAllCustomer(props.region)
            notificationSuccess('Đang đồng bộ vui lòng chờ một vài phút')
        } catch (error) {
            notificationError('Đã có lỗi xảy ra vui lòng chờ một vài phút và thử lại')
        } finally {
            setIsSyncLoading(false)
        }
    }

    const handleExport = async () => {
        try {
            const payload = {
                ...props.params,
                page: undefined,
                limit: undefined,
                search: props.params?.search ? props.params?.search : '',
            }
            const res = await exportExcel(payload)
            if (res?.status) {
                message.success('Xuất file excel thành công!')
            }
        } catch (err) {
            console.log('error: ', err)
        }
    }

    return (
        <>
            <WapperComponent>
                <PageHeader
                    title={props.title}
                    extra={[
                        <Row gutter={[16, 16]}>
                            <Fillter
                                defaultSearch={props.defaultSearch}
                                onSubmitKeyword={(value: string) => props.onSubmitKeyword(value)}
                                onSubmidCity={(value: string) => props.onSubmidCity(value)}
                                onSubmitTime={(value: Array<string>) => props.onSubmitTime(value)}
                            />
                            <Col>
                                <ExportCsv
                                    sheetName={['CustomerList']}
                                    fileName="Danh sách khách hàng"
                                    sheets={{
                                        CustomerList: ExportCsv.getSheets(dataExport),
                                    }}
                                    onClick={() => handleExport()}
                                >
                                    <DownloadOutlined /> &nbsp; Export
                                </ExportCsv>
                            </Col>
                            {props.region !== '' && (
                                <Col>
                                    <Button
                                        loading={isSyncLoading}
                                        type="primary"
                                        shape="round"
                                        icon={<SwapOutlined />}
                                        onClick={syncCustomer}
                                    >
                                        Đồng bộ
                                    </Button>
                                </Col>
                            )}
                        </Row>,
                    ]}
                ></PageHeader>
            </WapperComponent>
        </>
    )
}

export default memo(Header)
