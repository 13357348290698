import { Button, message, PageHeader, Radio, Table, Tabs, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useEffect, useState, memo } from 'react'
import { getCategory, syncCategory } from './CategoryAPI'
import ChildrenCategory from './ChildrenCategory'
import InfoCategory from './InfoCategory'
import { ICategoryPayload, IFormatedTableCate } from './CategoryInterfaces'
import { useSelector } from 'react-redux'
import { ADMIN_ROLE, IS_ACTIVE, REGION } from 'utils/constants'
import HeaderCate from './HeaderCate'
import { convertTimeStampToString } from 'utils/TimerHelper'
import { CFB } from 'xlsx'
import { notificationError } from 'utils/notification'
import { IAuthState } from 'common/components/HeaderInterfaces'
import { getListKiotViet } from 'features/admin/account/AccountApi'
import { IKiotviet } from 'features/admin/account/AccountInterfaces'
import styled from 'styled-components'
import { IFormatedPaging } from 'features/admin/customer-clone/CustomerInterfaces'
const { TabPane } = Tabs

const RadioGroupStyled = styled(Radio.Group)`
    padding: 10px;
`

function CategoryProducts({ location }: any) {
    const authState: IAuthState = useSelector((state: any) => state.authReducer)
    const userInfor = useSelector((state: any) => state.authReducer.userInfo)
    const isShopAdmin = userInfor?.admin?.role === ADMIN_ROLE.SHOP_ADMIN
    const [region, setRegion] = useState<number>(isShopAdmin ? userInfor?.admin?.kiotviet_id : undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [listCategory, setListCategory] = useState<IFormatedTableCate[]>([])
    const [listKiotviet, setListKiotviet] = useState<IKiotviet[]>()
    const [currentSelected, setCurrentSelected] = useState<any>({ id: -1 })
    const [isShowButton, setIsShowButton] = useState<boolean>(true)
    const [paging, setPaging] = useState<IFormatedPaging>({
        total: 0,
        current: 1,
        pageSize: 0,
    })

    const heightWeb = useSelector((state: any) => state.configReducer.dimension.height)
    const [params, setParams] = useState<ICategoryPayload>({
        page: location.state?.page?.current,
        search: '',
        create_from: '',
        create_to: '',
        status: '',
        limit: 12,
        parent_id: '',
        kiotviet_id: '',
    })

    const getDataCategory = async (params: ICategoryPayload) => {
        setIsLoading(true)
        try {
            const res = await getCategory(params)
            if (res.data) {
                const dataTableCate = res.data.map((category, index) => {
                    // setRegion(category.kiotviet_id)
                    return {
                        ...category,
                        stt: index + 1,
                        key: index,
                    }
                })
                setListCategory(dataTableCate.reverse())
                if (res.paging) {
                    setPaging({
                        total: res.paging.totalItemCount,
                        current: res.paging.page,
                        pageSize: res.paging.limit,
                    })
                }
            }
        } catch (err: any) {
            console.log(err)
            notificationError(err)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getDataCategory(params)
    }, [params])

    const getDataKiotviet = async () => {
        try {
            const res = await getListKiotViet()
            if (res.data) {
                setListKiotviet(res.data)
            }
        } catch (err) {
            console.log(err)
            notificationError('Có lỗi xảy ra!')
        }
    }

    useEffect(() => {
        getDataKiotviet()
        // if (authState?.userInfo?.admin?.kiotviet_id) {
        //     setRegion(authState.userInfo.admin?.kiotviet_id)
        // }
    }, [authState])

    const handleOnSubmitKey = (value: string) => {
        setParams({ ...params, search: value })
        setParams({ ...params, search: value })
    }

    const renderStatus = (status: number) => {
        return (
            <td>
                {status == IS_ACTIVE.INACTIVE ? (
                    <Tag color="volcano">Ngừng hoạt động</Tag>
                ) : (
                    <Tag color="success">Đang hoạt động</Tag>
                )}
            </td>
        )
    }

    const renderCreateAt = (created_at: Date) => {
        return <td>{convertTimeStampToString(created_at)}</td>
    }

    const columns: ColumnsType<IFormatedTableCate> = [
        {
            title: 'Tên danh mục',
            dataIndex: 'name',
            ellipsis: {
                showTitle: false,
            },
            render: name => (
                <Tooltip placement="topLeft" title={name}>
                    {name}
                </Tooltip>
            ),
        },
        {
            title: 'Danh mục con',
            dataIndex: 'total_child',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            render: (status: number) => renderStatus(status),
        },
        {
            title: 'Thứ tự hiển thị',
            dataIndex: 'order',
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_at',
            render: (created_at: Date) => renderCreateAt(created_at),
        },
    ]

    const showInfoCate = (record: IFormatedTableCate) => {
        return (
            <Tabs defaultActiveKey="1" style={{ margin: '0 20px 0 50px' }}>
                <TabPane tab="Thông tin danh mục" key="1">
                    <InfoCategory infoCategory={record} getData={getDataCategory} />
                </TabPane>
                <TabPane tab="Danh mục con" key="2">
                    <ChildrenCategory infoCategory={record} />
                </TabPane>
            </Tabs>
        )
    }

    const handleChangeStock = (selectRegion: string) => {
        if (selectRegion) {
            setIsShowButton(true)
        } else {
            setIsShowButton(false)
        }
        setParams({ ...params, page: 1, kiotviet_id: selectRegion })
        setRegion(parseInt(selectRegion))
    }

    const handleSyncNow = async () => {
        setIsLoading(true)
        try {
            if (region) {
                const res = await syncCategory(region)
                if (res.data) {
                    message.success('Đồng bộ danh mục thành công')
                }
            }
        } catch (err) {
            console.log(err)
            message.error('Có lỗi xảy ra')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <HeaderCate
                visible={isShowButton && region !== undefined}
                onSyncNow={() => handleSyncNow()}
                onSubmitKey={handleOnSubmitKey}
                onSubmitDatetime={(dateTime: Array<string>) => {
                    setParams({
                        ...params,
                        create_from: dateTime[0],
                        create_to: dateTime[1],
                    })
                }}
                onSubmitStatus={(status: string) => {
                    setParams({ ...params, status: status })
                }}
                isSearchLoading={isLoading}
            />
            <div
                className="list-products"
                style={{
                    backgroundColor: 'white',
                    margin: '10px',
                }}
            >
                {/* {authState?.userInfo?.admin?.is_root ? (
          <RadioGroupStyled
            defaultValue={region}
            onChange={(e: any) => handleChangeStock(e.target.value)}
          >
            <Radio.Button value={undefined}>Tất cả</Radio.Button>
            {listKiotviet &&
              listKiotviet.map((region, index) => (
                <Radio.Button key={index} value={region.id}>
                  {region.name ? region.name : '--'}
                </Radio.Button>
              ))}
          </RadioGroupStyled>
        ) : null} */}
                <RadioGroupStyled defaultValue={region} onChange={(e: any) => handleChangeStock(e.target.value)}>
                    <Radio.Button disabled={isShopAdmin} value={undefined}>
                        Tất cả
                    </Radio.Button>
                    {listKiotviet &&
                        listKiotviet.map((region, index) => (
                            <Radio.Button
                                key={index}
                                value={region.id}
                                disabled={isShopAdmin && region.id !== userInfor?.admin?.kiotviet_id}
                            >
                                {region.name ? region.name : '--'}
                            </Radio.Button>
                        ))}
                </RadioGroupStyled>
                <Table
                    loading={isLoading}
                    style={{ margin: '0 5px' }}
                    dataSource={listCategory}
                    bordered
                    scroll={
                        listCategory?.length > 1
                            ? {
                                  x: 800,
                                  scrollToFirstRowOnChange: true,
                                  y: `calc(${heightWeb} - 300px)`,
                              }
                            : {}
                    }
                    expandedRowKeys={[currentSelected.key]}
                    columns={columns}
                    expandable={{
                        expandedRowRender: record => showInfoCate(record),
                        onExpand: (status: any, record) => {
                            if (currentSelected != record) setCurrentSelected(record)
                            else setCurrentSelected({ id: -1 })
                        },
                    }}
                    pagination={{
                        ...paging,
                        showSizeChanger: false,
                        onChange: async (page, pageSize) => {
                            setParams({ ...params, page: page })
                        },
                    }}
                />
            </div>
        </>
    )
}

export default memo(CategoryProducts)
