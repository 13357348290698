import { ApiClient } from 'services/ApiService'
import {
    ConfirmListOrder,
    ConfirmOrderPayload,
    IDataOrderDetail,
    IDataOrders,
    IListKioviet,
    IOrderPayload,
    ResponseData,
} from './Interfaces'

export const getOrder = (payload: IOrderPayload): Promise<ResponseData<IDataOrders[]>> => {
    return ApiClient.get('/admin/order', payload)
}

export const exportExcelOrder = (payload: any): Promise<ResponseData<IDataOrders[]>> => {
    return ApiClient.get('/admin/order/export_excel', payload)
}

export const getOrderDetail = (payload: string): Promise<ResponseData<IDataOrderDetail>> => {
    return ApiClient.get(`/admin/order/${payload}`)
}

export const confirmListOrder = (payload: ConfirmListOrder) => {
    return ApiClient.put('/order/confirm-list-order', payload)
}

export const confirmOrder = (payload: ConfirmOrderPayload) => {
    return ApiClient.put('/order/confirm-order', payload)
}

export const getListKiotViet = (): Promise<ResponseData<IListKioviet[]>> => {
    return ApiClient.get('/admin/kiotviet')
}

export const toggleComment = (id: number) => {
    return ApiClient.post(`/admin/rating/${id}/toggleStatus`)
}

export const syncOrder = (id: number) => {
    return ApiClient.post(`/admin/order/${id}/sync`)
}

export const removeComment = (id: number) => {
    return ApiClient.delete(`/admin/rating/${id}`)
}

export const addShipperManagerNote = (id: number, payload: any) => {
    return ApiClient.post(`/admin/order/${id}/ship_manager_note`, payload)
}
